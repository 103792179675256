import Images from "../../Data/Images/Images";
import { IShareOption } from "../../Interfaces/IShareOptions";
export const linkBuilder = (link: string, obj: any) => {
  let query: any = link.split("?").pop()
  let newLink = Object.entries(obj).reduce((prev: any, [key, value]) => {
    query.includes(`${key}=`) && prev.push(`${key}=${value}`);
    return prev;
  }, []).join("&");
  return link.split("?")[0] +"?"+  newLink //+ linkShareFormat.link;
};
export const linkShareFormat = {
  title: "",
  text: "",
  quote:"",
  subject: "",
  body: "",
  summary: "",
  url: "",
  u: "",
  link: "",
};
export const ShareOptions: IShareOption[] = [
  {
    name: "Whatsapp",
    image: Images.whatsapp_blue_fill,
    redirectTo: decodeURI(`https://wa.me/?text=`),
    color: "#4166A7",
  },
  {
    name: "Mail",
    image: Images.mail_blue_fill,
    redirectTo: decodeURI(`mailto:?subject=&body=`),
    color: "darkblue",
  },
  {
    name: "Twitter",
    image: Images.twitter_blue_fill,
    redirectTo: `https://twitter.com/intent/tweet?url=`,
    color: "#009CE8",
  },
  {
    name: "Facebook",
    image: Images.facebook_blue_fill,
    redirectTo: decodeURI(`https://www.facebook.com/sharer/sharer.php?u=`),
    color: "#4166A7",
  },
  {
    name: "Linkedin",
    image: Images.linkedinHeader,
    redirectTo: decodeURI(
      `https://www.linkedin.com/sharing/share-offsite/?url=`
    ),
    color: "#005EB7",
  },
];
