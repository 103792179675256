import { t } from "i18next";
import { Fragment, useEffect, useRef } from "react";
import { IFullSizedTable } from "../../../Interfaces/IUmbraco";
import i18n from "../../../Services/i18n";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import { replaceRichtextBoldWithFont } from "../../../Utils/utils";
import Title from "../Title";

const FullSizedTable: React.FC<IFullSizedTable> = (props) => {
  const { title, table } = props;
  const deviceMode = useDeviceMode();
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (tableRef.current) {
      const tableElements = tableRef.current.getElementsByTagName("table");
      if (tableElements) {
        const table = tableElements[0];
        table.setAttribute("summary", title.text);
        const trElements = table.getElementsByTagName("tr");
        if (trElements.length > 0) {
          for (let i = 0; i < trElements.length; i++) {
            const tdElements = trElements[i].getElementsByTagName("td");
            if (tdElements.length > 0) {
              for (let j = 0; j < tdElements.length; j++) {
                const tdAlign = (tdElements[j] as any).align;
                if (!tdAlign) {
                  if (i18n.dir() === "rtl") {
                    tdElements[j].setAttribute("align", "right");
                    tdElements[j].style.padding = "0 1rem";
                  } else {
                    tdElements[j].setAttribute("align", "left");
                    tdElements[j].style.padding = "0 1rem";
                  }
                }
                // tdElements[j].setAttribute("tabIndex", "0");
              }
            }
          }
        }

        const tbodyElement = table.getElementsByTagName("tbody");
        if (tbodyElement.length > 0) {
          const firstRow = (tbodyElement[0] as HTMLElement).children[0];
          if (firstRow) {
            const tdElements = firstRow.children;
            for (let i = 0; i < tdElements.length; i++) {
              const tdValue = tdElements[i].innerHTML;
              const th = document.createElement("th");
              tdElements[i].replaceWith(th);
              tdElements[i].innerHTML = tdValue;
              // tdElements[i].setAttribute("tabIndex", "0");
            }
          }
        }
      }
      const links = tableRef.current.getElementsByTagName("a");
      if (links && links.length > 0) {
        for (let i = 0; i < links.length; i++) {
          const linkType = links[i].href.split(".");
          const fileName = links[i].innerText;
          let isDownloadLink = false;
          switch (linkType[linkType.length - 1]) {
            case "pdf":
            case "xlsx":
            case "xls":
            case "docx":
            case "doc":
            case "svg":
              isDownloadLink = true;
              break;
            default:
              isDownloadLink = false;
              break;
          }
          links[i].setAttribute(
            "aria-label",
            isDownloadLink
              ? `${t("DownloadFileWithType", {
                  fileName: fileName,
                  fileType: linkType[linkType.length - 1],
                })} `
              : `${t("OpenLink")} ${linkType[linkType.length - 1]}`
          );
          links[i].setAttribute("role", "button");
        }
      }
    }
  }, []);

  return (
    <Fragment>
      <Title {...title} />
      <div
        style={
          deviceMode !== StyleMode.desktop
            ? {
                overflow: "auto",
                maxWidth: "100%",
                maxHeight: "40rem",
              }
            : {}
        }
        ref={tableRef}
        dangerouslySetInnerHTML={{
          __html: replaceRichtextBoldWithFont(table),
        }}
      ></div>
    </Fragment>
  );
};

export default FullSizedTable;
