import Images from "../../Data/Images/Images";
import { IFormsContainer } from "../../Interfaces/IForms";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import FormNavigation from "./FormNavigation";

const FormsContainer: React.FC<IFormsContainer> = (props) => {
  const { type } = props;

  return (
    <div style={styles.container}>
      <FormNavigation type={type} />
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    direction: "rtl",
    height: "100%",
    
    backgroundImage: `url(${Images.railLogoFormBackGround})`,
    overflowY:"scroll",
    overflowX:"hidden" ,
    backgroundAttachment:"fixed",
  },
};

export default FormsContainer;
