import colors from "../../../Assets/Colors/Colors.json";
import { ICareer } from "../../../Interfaces/ICareers";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { RemoveHtmlTags } from "../../../Utils/helpers";
import { replaceRichtextBoldWithFont } from "../../../Utils/utils";
import fonts from "../../../Assets/Fonts/Fonts.json"
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../Services/DeviceService";

const Career: React.FC<ICareer> = (props) => {
  const {
    title,
    jobNumber,
    titleNumber,
    jobComments,
    buttonText,
    jobDescription,
    description,
    onClick,
  } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "17rem",
      }}
    >
      <div style={styles.cardContainer}>
        <div
          style={{
            padding: ".5rem 2rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <h3 style={styles.title} tabIndex={!IsMobile() ? 0 : -1}>
            {title}
          </h3>
          <span style={styles.careerNumber}>
            {titleNumber} {jobNumber}
          </span>
          <div
            dangerouslySetInnerHTML={{
              __html: replaceRichtextBoldWithFont(description),
            }}
            style={styles.description}
            tabIndex={!IsMobile() ? 0 : -1}
            aria-label={`${titleNumber}: ${jobNumber} ${RemoveHtmlTags(
              description
            )}`}
          ></div>
        </div>
      </div>
      <div style={styles.button} onClick={onClick} role={`button`} tabIndex={!IsMobile() ? 0 : -1}>
        {buttonText}
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  cardContainer: {
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.15)",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  title: {
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.UmbracoElementTitle,
    lineHeight: "1.625rem",
    color: colors.darkBlue,
  },
  careerNumber: {
    fontFamily: fonts.FbReformaLight,
    fontSize: fontSize.Text,
    marginTop: "-2rem",
  },
  description: {
    fontFamily: fonts.FbReformaRegular,
    lineHeight: "1.625rem",
    fontSize: fontSize.Text,
    maxWidth: "30rem",
  },
  button: {
    backgroundColor: colors.darkBlue,
    borderBottomRightRadius: "10px",
    borderBottomLeftRadius: "10px",
    height: "2.75rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: fontSize.Text,
    fontFamily: fonts.FbReformaMedium,
    color: colors.white,
    cursor: "pointer",
  },
};

export default Career;
