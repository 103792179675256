import { useEffect, useState } from "react";
import colors from "../Assets/Colors/Colors.json";
import fonts from "../Assets/Fonts/Fonts.json";
import Breadcrumb from "../Components/UmbracoElements/Breadcrumb";
import Config from "../Configuration/server";
import Images from "../Data/Images/Images";
import { StyleSheet } from "../Interfaces/IStyleSheet";
import { StyleMode } from "../Utils/consts";
import { SiteData, treeSite, xmlSiteMap } from "../Utils/sitemap";
import useDeviceMode from "../Utils/useWindowDimensions";
import store from "store2";
import { IStation } from "../Interfaces/IStation";
import fontSize from "../Assets/Fonts/FontsSizes.json";
import i18n from "../Services/i18n";
import { IUpdatesContent } from "../Interfaces/IUmbraco";
import { GetRegularAndPermanentUpdates } from "../Utils/updates";
import { ITree } from "../Interfaces/ITree";
import { GetStations } from "../Services/GlobalService";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { UmbracoStoreState } from "../Redux/Slices/umbracoSlice";

const SiteMap: React.FC = () => {
  const deviceMode = useDeviceMode();
  const umbracoStore = (
    useSelector((state: any) => state.UmbracoStoreReducer) as UmbracoStoreState
  ).umbracoStore;

  const SiteMapXml = () => {
    const line = `${xmlSiteMap}`;
    // const test = new DOMParser().parseFromString(line,`text/html`)
    const xmlBlob = new Blob([line], { type: "text/plain" });
    return (
      <>
        <div style={styles.container}>
          <a
            href={window.URL.createObjectURL(xmlBlob)}
            download={`IsraelRailSitemap_${Config.SITE_VERSION}.xml`}
            style={styles.fileWrapper}
          >
            <img src={Images.xmlIcon} width={"50"} height={`auto`} alt={``} />
            <div>
              <span style={styles.filename}>{`${t("sitemap")}`}</span>
            </div>
          </a>
        </div>
      </>
    );
  };

  const HtmlSiteMap = () => {
    const [_updates, _setUpdates] = useState<IUpdatesContent[]>([]);
    const [_stationList, _setStationList] = useState<IStation[]>([]);
    const [_newTreeSite, _setNewTreeSite] = useState<ITree[]>([]);

    useEffect(() => {
      let isMounted = true;
      (async () => {
        const updates = await GetRegularAndPermanentUpdates();

        const allStations = store.session.get(
          `${Config.BASE_LOCALSTORAGE_NAME}stations`
        );
        if (allStations !== `` && allStations !== undefined)
          _setStationList(allStations[i18n.language]);
        else {
          let currntStations: IStation[] | undefined = undefined;
          let station = await GetStations();
          switch (i18n.language) {
            case `he`:
              currntStations = station?.he;
              break;
            case `en`:
              currntStations = station?.en;
              break;
            case `ru`:
              currntStations = station?.ru;
              break;
            case `ar`:
              currntStations = station?.ar;
              break;
          }
          if (currntStations) _setStationList(currntStations);
        }
        if (isMounted) {
          _setUpdates(updates.updates);
        }
      })();

      return () => {
        isMounted = false;
      };
    }, []);

    useEffect(() => {
      let publishPagesIds: string[] = [];
      let publishIds: string[] = [];
      let publishNames: string[] = [];

      const pagesDictionary: { [pageId: string]: string } = {};
      SiteData.map((category) => {
        category.pages.map((page, index) => {
          if (!!!page.isNotPublishedPage) {
            publishPagesIds.push(page.pageId);
            if (category.id !== "Updates") {
              pagesDictionary[page.pageId] = page.title.text;
            }
          }
        });
      });

      let regularPermUpdates: IUpdatesContent[] = [];
      let specialUpdates: IUpdatesContent[] = [];
      if (umbracoStore.updates) {
        regularPermUpdates = [...umbracoStore.updates.updates]
          .filter((update) => update.updateType !== "Special")
          .filter(
            (update) =>
              new Date(update.endDate) > new Date() &&
              new Date(update.startDate) < new Date()
          );
        specialUpdates = [...umbracoStore.updates.updates]
          .filter((update) => update.updateType === "Special")
          .filter(
            (update) =>
              new Date(update.endDate) > new Date() &&
              new Date(update.startDate) < new Date()
          );
      }

      let newTreeSite: ITree[] = [];

      treeSite.map((category) => {
        let publishPages: string[] = [];
        publishIds = [];
        publishNames = [];

        if (category.category !== `Errors` && category.category !== `Updates`) {
          category.pages.map((pageName, index) => {
            publishPagesIds.forEach((PublishedPageId, key) => {
              if (PublishedPageId === category.ids[index]) {
                publishPages.push(pageName);
                publishIds.push(category.ids[index]);
                publishNames.push(pagesDictionary[PublishedPageId]);
              }
            });
          });
          newTreeSite.push({
            category: category.category,
            ids: publishIds,
            pages: publishNames,
          } as ITree);
        }
        if (category.category === "Updates") {
          regularPermUpdates.forEach((currentUpdate) => {
            if (!pagesDictionary[currentUpdate.updatePage.pageId]) {
              publishPages.push(currentUpdate.updatePage.title.text);
              publishIds.push(currentUpdate.updatePage.pageId);
              publishNames.push(currentUpdate.updatePage.title.text);
            }
          });
          newTreeSite.push({
            category: category.category,
            ids: publishIds,
            pages: publishNames,
          } as ITree);
        }
        if (category.category === "Updates") {
          let specialUpdatesPublishedIds: string[] = [];
          let specialUpdatesPublishedNames: string[] = [];

          specialUpdates.forEach((currentUpdate) => {
            if (!pagesDictionary[currentUpdate.updatePage.pageId]) {
              specialUpdatesPublishedIds.push(currentUpdate.updatePage.pageId);
              specialUpdatesPublishedNames.push(currentUpdate.updatePage.title.text);
            }
          });
          // newTreeSite.push({
          //   category: "Special Updates",
          //   ids: specialUpdatesPublishedIds,
          //   pages: specialUpdatesPublishedNames,
          // } as ITree);
        }
      });

      _setNewTreeSite(newTreeSite);
    }, [_updates]);

    return (
      <>
        {_newTreeSite?.map((category, index) => {
         
          return (
            category.ids.length>0&&
            <div
              key={category.category + index}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "2rem 0",
                alignItems: "flex-start",
              }}
            >
              <div style={styles.categoryContainer}>
                <h3 style={styles.categoryWrapper}>
                  <span style={styles.categoryText}>
                    {`${t('sitemap_category_'+category.category)}`}
                  </span>
                </h3>
              </div>
              {category.ids.map((page, index) => {
                return (
                  <div
                    key={page + index}
                    style={{ ...styles.container, marginTop: "0.5rem" }}
                  >
                    <a href={`${Config.DOMAIN}?page=${page}`}>
                     <span>
                      <span
                        style={styles.filename}
                      >{`${category.pages[index]} `} </span> 
                      <span className="sitemaplink">{`- ${page}`}</span>
                      </span>
                    </a>
                  </div>
                );
              })}
            </div>
          );
        })}
        <div
          key={`stations`}
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "2rem 0",
            alignItems: "flex-start",
          }}
        >
          <div style={styles.categoryContainer}>
            <h3 style={styles.categoryWrapper}>
              <span style={styles.categoryText}>{`${t(`Stations`)}`}</span>
            </h3>
          </div>
          {_stationList?.map((station, index) => {
            return (
              <div
                key={station.stationId + index}
                style={{ ...styles.container, marginTop: "0.5rem" }}
              >
                <a
                  href={`${Config.DOMAIN}?page=stationinfo&stationname=${station.stationId}`}
                >
                  <span
                    style={styles.filename}
                  >{`${station.stationId} - ${station.stationName}`}</span>
                </a>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  useEffect(() => {}, []);

  return (
    <>
      <div
        style={{
          ...styles.mainContainer,
          paddingTop: deviceMode === StyleMode.desktop ? "3rem" : "",
        }}
      >
        <div
          style={{
            ...styles.cover,
            background: `30% 30% / cover url(${Images.coverCloseImage})`,
            height: "40vh",
            minHeight: "17.18rem",
          }}
        >
          <div className="cover-color-blur">
            <div style={styles.coverElements} className="container">
              <div style={styles.breadcrumb}>
                <Breadcrumb
                  items={[{ text: `${t("sitemap")}`, link: "sitemap" }]}
                />
              </div>
              <h1 style={styles.title}> {`${t("sitemap")}`}</h1>
            </div>
          </div>
        </div>
        <div style={styles.bodyBorderMobile}>
          <div style={styles.mainCardMobile}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "3rem",
                padding: "5rem 0",
                alignItems: `flex-start`,
              }}
            >
              <HtmlSiteMap />
              <SiteMapXml />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  categoryContainer: {
    maxWidth: `76rem`,
    width: `100y%`,
  },
  categoryWrapper: {
    display: `flex`,
    fontSize: `1.5625rem`,
    fontFamily: `FbReforma-Medium,HelveticaNeue-Bold`,
    fontWeight: `${i18n.language === "ar" ? `600` : ``}`,
    color: colors.darkBlue,
  },
  categoryText: {
    borderBottom: `1px solid ${colors.koromiko}`,
    borderBottomWidth: ".2rem",
    padding: `0.2rem 0px`,
  },
  fileWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "1rem",
    cursor: "pointer",
  },
  filename: {
    fontSize: fontSize.UmbracoElementTitle,
    fontFamily: fonts.FbReformaRegular,
  },
  mainContainer: {
    alignItems: "center",
    flexDirection: "column",
    zIndex: "10",
  },
  cover: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "40vh",
    minHeight: "17.18rem",
    position: "relative",
  },
  title: {
    color: colors.white,
    fontSize: fontSize.PageTitle,
    lineHeight: "3rem",
    letterSpacing: "0.05625rem",
    textShadow: `2px 2px ${colors.black}`,
    fontFamily: fonts.FbReformaMedium,
    paddingTop: "3.25rem",
  },
  breadcrumb: {
    display: "flex",
    color: colors.white,
    fontSize: fontSize.Text,
    letterSpacing: "0.05625rem",
    fontFamily: fonts.FbReformaRegular,
    position: "absolute",
    paddingTop: "2rem",
  },
  coverElements: {
    gap: "3rem",
    width: "80%",
    maxWidth: "76rem",
    margin: "auto",
    lineHeight: "2.5rem",
    padding: "2rem",
  },
  bodyBorder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transform: "translateY(-7.8rem)",
  },
  bodyBorderMobile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  mainCardMobile: {
    width: "80%",
    marginTop: "1%",
    minHeight: "40rem",
  },
};

export default SiteMap;
