import { t } from "i18next";
import colors from "../../../../../../Assets/Colors/Colors.json";
import fonts from "../../../../../../Assets/Fonts/Fonts.json";
import Images from "../../../../../../Data/Images/Images";
import { FormatDateYYYYMMDD_Dots, FormatHoursMinutes } from "../../../../../../Services/DateService";
import { StyleMode } from "../../../../../../Utils/consts";
import { IAuctionsSystemMeetings } from "../../../../Interfaces_tenders/IAuctionsSystemDetails";
import { StyleSheet } from "../../../../Interfaces_tenders/IStyleSheet";

const MeetingCard: React.FC<IAuctionsSystemMeetings> = (props) => {
  const { meetingsId, meetingDate, registretionLink, location } = props;
  return (
    <div
      tabIndex={0}
      style={{
        ...styles.mainWrapper,
        border: `.1px solid ${colors.pastelGray}`,
        width: StyleMode.desktop ? "100%" : "90%",
      }}
    >
      <div style={styles.header}>
        {
          <div style={styles.title}>
            {t("MeetingOffer")} {meetingsId}
          </div>
        }
      </div>
      <div style={styles.body}>
        <div style={styles.timeWrapper}>
          <div style={styles.dateItem}>
            <img src={Images.datePicker}></img>
            <div style={styles.datePublishContainer}>
              <div>{t("MeetingDate")}</div>
              <div>{FormatDateYYYYMMDD_Dots(new Date(meetingDate))}</div>
              {` - `}
              <div>{FormatHoursMinutes(meetingDate?.toString())}</div>
            </div>
          </div>

          {location && (
            <div style={styles.meetingInfoCon}>
              <div style={styles.meetingInfoTitle}>{t("MeetingInfo")}</div>
              <div style={styles.meetingInfoDescription}>{location}</div>
            </div>
          )}

          {registretionLink && registretionLink.length > 0 ? (
            <div
              tabIndex={0}
              style={styles.requestClarification}
              onClick={() => {
                window.open(`${registretionLink}`, "_blank");
              }}
            >
              {t("MeetingLinkUrl")}
            </div>
          ) : (
            <div
              tabIndex={0}
              style={{ ...styles.meetingInfoTitle, fontWeight: `600` }}
            >
              {t("LinkNotRequierd")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.white,
    maxWidth: "60rem",

    borderRadius: "0.625rem",
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.15)",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `.1rem solid ${colors.pastelGray}`,
    padding: ".5rem",
  },
  title: {
    color: colors.gray,
    fontFamily: fonts.FbReformaRegular,
    fontSize: "1rem",
  },
  body: {
    display: "flex",
    alignItems: "center",
    padding: "1rem",
    gap: "15rem",
  },
  timeWrapper: {
    display: "flex",
    gap: "1rem",
    flexDirection: "column",
    width: "100%",
  },
  
  dateItem: {
    display: "flex",
    alignItems: "center",
    /*justifyContent: "center",*/
    color: colors.darkBlue,
    fontSize: "1.2rem",
    gap: "1rem",
    /* flex: 1,*/
  },
  datePublishContainer: {
    display: "flex",
    //flexDirection: "column",
    alignItems: "center",
    gap: "1rem",
  },
  meetingInfo: {
    display: "flex",
    /* alignItems: "center",*/
    /*  justifyContent: "center",*/
    color: colors.darkBlue,
    fontSize: "1.2rem",
    flex: 1,
  },
  icon: {
    backgroundRepeat: "no-repeat",
    width: "2.5rem",
    height: "2.5rem",
  },

  meetingInfoDescription: {
    flex: 3,
    maxWidth:"100%",
    overflow:"auto"
  },
  meetingInfoTitle: {
    color: colors.darkBlue,
    fontSize: "1.2rem",
    gap: "1rem",
  },
  requestClarification: {
    backgroundColor: colors.darkBlue,
    color: colors.white,
    fontSize: "1rem",
    borderRadius: "16rem",
    justifyContent: "center",
    paddingTop: ".5rem",
    alignItems: "center",
    width: "10rem",
    minHeight: "1.8rem",
    textAlign: "center",
    cursor: "pointer",
  },
};

export default MeetingCard;
