import { IMedia } from "../../Interfaces/IBaseElement";
import { ILinkOpenType } from "../../Interfaces/IMenu";
import Card from "../Card";
import fonts from "../../Assets/Fonts/Fonts.json";

export interface IIconAndText {
  icon?: IMedia;
  title: string;
  link?: string;
  style?: React.CSSProperties;
  shapeBox?: string;
  onClick?: (e: IIconAndText) => void;
  mode: string[];
  openMode: string;
  openType: ILinkOpenType[`Type`];
  serviceName: string;
}
const IconAndText: React.FC<IIconAndText> = (props) => {
  const { icon, title, style, link, shapeBox, onClick } = props;
  return (
    <Card
      onClick={() => onClick && onClick(props)}
      style={{
        cursor: "pointer",
        borderRadius: shapeBox === "circle" ? "50rem" : "",
        aspectRatio: "1 / 1",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        maxWidth: "10rem",
        gap: "1rem",
        padding: "1rem",
        fontFamily:fonts.FbReformaRegular,
        ...style,
      }}
    >
      <div style={{ flex: 1, display: "flex", alignItems: "flex-end" }}>
        <img src={icon?._url} style={{ aspectRatio: "1/1", height: "3rem", objectFit: "contain" }} />
      </div>
      <p
        style={{
          color: "#00458A",
          fontSize: "1rem",
          flex: 1,
          letterSpacing: "0.6px",
          textAlign: "center",
        }}
      >
        {title}
      </p>
    </Card>
  );
};

export default IconAndText;
