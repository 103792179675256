import { useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Translations } from "../../Services/GlobalService";
import i18n from "../../Services/i18n";
import { ITrainPricies } from "./FromToStation";
export interface IProfileData {
  id?: number;
  profile_ID: number;
  discount_Rate: number;
  ticketType: 1 | 2 |3
}
export interface IProfileApi {
  profile_ID: number;
  heb_Profile_Desc: string;
  eng_Desc: string;
  arb_Desc: string;
  rus_Desc: string;
}

export interface IProfileDataLongData {
  id?: number;
  profile_ID: number;
  discount_Rate: number;
  profileName: string;
  discountSingle: number;
  discountDaily: number;
  discountMonthly: number;
  discountYearly: number;
  active: boolean;
  profileNameArab: string;
  profileNameEnglish: string;
  profileNameRussian: string;
}
interface IProfilesCapsule {
  data?: ITrainPricies[];
  onChange: (e: ITrainPricies) => void;
}
const ProfilesCapsule: React.FC<IProfilesCapsule> = (props) => {
  const { data = [] } = props;
  let [visible, setVisible] = useState(false);
  let [selected, setSelected] = useState(0);

  const Translation = Translations[
    i18n.language as `he` | `en` | `ar` | `ru`
  ] as any;
;
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        zIndex: 1,
        minWidth: "16rem",
      }}
    >
      <div
        tabIndex={0}
        // onFocus={() => setVisible(true)}
        onClick={() => setVisible(!visible)}
        onBlur={() => {
          setTimeout(() => {
            setVisible(false);
          }, 200);
        }}
        style={{
          cursor: "pointer",
          boxShadow: "0 5px 11px 0 rgba(0,0,0,0.21)",
          borderRadius: "2rem",
          backgroundColor: "white",
          padding: "0.7rem",
          alignItems: "center",
          justifyContent: "center",
          gap: "0.5rem",
          display: "flex",
        }}
      >
        {
          Translation[
            `PassengerProfile${
              data.find((item: ITrainPricies) => item.profileId === selected)?.profileId
                
            }`
          ]
        }
        <MdKeyboardArrowDown />
      </div>
      {visible && (
        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: "calc(100% + 0.5rem)",
            boxShadow: "0 5px 11px 0 rgba(0,0,0,0.21)",
            backgroundColor: "white",
            borderRadius: "2rem",
            padding: "1rem",
          }}
        >
          <div className={"custom-list"}>
            {data.map((item: ITrainPricies) => (
              <div
                key={item.profileId}
                className={`list-key ${
                  selected === item.profileId ? "active" : ""
                }`}
                onClick={() => {
                  setSelected(item.profileId);
                  props.onChange(item);
                  setVisible(false);
                }}
              >
                {Translation[`PassengerProfile${item.profileId}`]}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};


export { ProfilesCapsule };
