import { IRichTextEditor } from "../../Interfaces_tenders/IUmbraco";
import { StyleSheet } from "../../Interfaces_tenders/IStyleSheet";

const RichTextEditor: React.FC<IRichTextEditor> = (props) => {
  const { text } = props;

  const addListStyle = (text: string) => {
    return text.replaceAll("<li", `<li style="padding-inline-start: 1rem"`);
  };

  return (
    <div style={styles.richTextEditorContainer}>
      <div dangerouslySetInnerHTML={{ __html: addListStyle(text) }}></div>
    </div>
  );
};

const styles: StyleSheet = {
  richTextEditorContainer: {
    maxWidth: "76rem",
    width: "80%",
    lineHeight: "1.5rem",
    fontSize: "1.4rem",
  },
};

export default RichTextEditor;
