import { t } from "i18next";
import { Fragment, useState } from "react";
import { GiCargoCrane, GiRailRoad } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import colors from "../../../../Assets/Colors/Colors.json";
import fonts from "../../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../../Assets/Fonts/FontsSizes.json";
import Config from "../../../../Configuration/server";
import Images from "../../../../Data/Images/Images";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import { ITendersSearchResults } from "../../../../Interfaces/IUmbraco";
import { FormatDateYYYYMMDD_Dots } from "../../../../Services/DateService";
import { IsMobile } from "../../../../Services/DeviceService";
import { DownloadFilesAsZipFile } from "../../../../Services/FilesToZipService";
import i18n from "../../../../Services/i18n";
import { StyleMode } from "../../../../Utils/consts";
import useDeviceMode from "../../../../Utils/useWindowDimensions";
import CustomModal from "../../../CustomModal";
import FileViewer from "../../../FileViewer/FileViewer";

const SearchResultsTenderAuctionItem: React.FC<ITendersSearchResults> = (
  props
) => {
  const {
    BiddingDate,
    Category,
    ClarifyingDate,
    Description,
    Documentation,
    Meetings,
    Id,
    PublishDate,
    ReferentMail,
    ReferentName,
    TenderName,
    TenderNumber,
    Type,
    UpdateDate,
    WinningAmount,
    WinningDate,
    WinningSupplier,
    term = "",
  } = props;
  const navigate = useNavigate();
  const deviceMode = useDeviceMode();
  const [_isModalOpen, _setIsModalOpen] = useState(false);

  const getStep = () => {
    if (WinningSupplier) {
      return "AuctionResults";
    }
    if (new Date() > new Date(BiddingDate)) {
      return "ClosedAuctions";
    }

    return "OpenAuctions";
  };

  const umbracoFiles = Documentation.map((document) => {
    return {
      filename: document.DocDisplay,
      document: {
        _url: `${Config.TENDERS_FILES}${document.DocName}`,
      },
    };
  });

  const currentStep = getStep();

  const auctionType =
    Category === 9
      ? "generalauctions"
      : Category === 10
        ? "cargoauctions"
        : "generalauctions";
  const link = decodeURI(
    `${auctionType}&step=${currentStep}${currentStep !== "AuctionResults" ? `&auctionId=${Id}` : ""
    }`
  );
  const fullLink = `${window.location.href.split("/?")[0]}/?page=${link}`;

  const openModal = () => {
    if (Documentation[0].DocName === null) {
      return;
    }
    if (Documentation[0].DocName.includes(".rar")) {
      window.open(
        `${Config.TENDERS_FILES}${Documentation[0].DocName}`,
        "_blank"
      );
    } else {
      if (deviceMode === StyleMode.desktop) {
        _setIsModalOpen(true);
      } else {
        DownloadFilesAsZipFile(
          [
            {
              filename: TenderName,
              document: {
                _url:
                  `${Config.TENDERS_FILES}${Documentation[0].DocName}` || "",
              },
            },
          ],
          TenderName
        );
      }
    }
  };

  const redirectToPage = (page?: string) => {
    page && navigate(`?page=${page}`);
  };

  const getFileIcon = () => {
    if (Documentation[0].DocName) {
      if (Documentation[0].DocName.includes(".pdf")) {
        return Images.pdfIcon;
      }
      if (Documentation[0].DocName.includes(".xls")) {
        return Images.xlsIcon;
      }
      if (Documentation[0].DocName.includes(".doc")) {
        return Images.wordFile;
      }
      if (Documentation[0].DocName.includes(".rar")) {
        return Images.rarIcon;
      }
    }
    return Images.pdfIcon;
  };

  return (
    <Fragment>
      <div style={styles.searchResultItem}>
        {deviceMode === StyleMode.desktop && (
          <div style={styles.iconContainer}>
            {auctionType === "generalauctions" ? (
              <GiRailRoad size={40} color="rgb(0, 69, 138)" />
            ) : auctionType === "cargoauctions" ? (
              <GiCargoCrane size={40} color="rgb(0, 69, 138)" />
            ) : (
              <GiRailRoad size={40} color="rgb(0, 69, 138)" />
            )}
          </div>
        )}
        <div style={styles.body}>
          <div>
            <span
              style={styles.title}
              role={"link"}
              tabIndex={!IsMobile() ? 0 : -1}
              onClick={() =>
                deviceMode === StyleMode.desktop && redirectToPage(link)
              }
            >
              {TenderName}
            </span>
          </div>
          {deviceMode === StyleMode.desktop && (
            <div style={styles.link}>{fullLink}</div>
          )}
          <div>
            {t("TenderNumber", {
              tenderNumber: TenderNumber,
            })}
          </div>
          <Fragment>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1rem",
                }}
              >
                <img src={Images.person} width={"20"} height={"20"} alt={``} />
                <div
                  style={{
                    ...styles.content,
                    maxWidth:
                      deviceMode === StyleMode.desktop ? "56rem" : "30rem",
                  }}
                >
                  {currentStep !== "AuctionResults"
                    ? t("Referent", {
                      referentEmail: ReferentMail,
                    })
                    : WinningSupplier
                      ? t("WinningSupplier", {
                        winningSupplier: WinningSupplier.split(",").join(" , "),
                      })
                      : ""}
                </div>
              </div>
              {Documentation && Documentation.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1rem",
                  }}
                >
                  <img
                    src={Images.fileIcon}
                    width={"20"}
                    height={"20"}
                    alt={``}
                  />
                  <div
                    style={{
                      ...styles.content,
                      maxWidth:
                        deviceMode === StyleMode.desktop ? "56rem" : "30rem",
                    }}
                  >
                    {t("filesCount", {
                      filesCount: Documentation.length,
                    })}
                  </div>
                </div>
              )}
            </div>
            <div style={styles.itemsSideLineContainer}>
              <div style={styles.itemSideLine}>
                <div style={styles.dateTitle}>{t("PublishDate")}</div>
                <div style={styles.dateFormat}>
                  {FormatDateYYYYMMDD_Dots(new Date(PublishDate))}
                </div>
              </div>
              <div style={styles.itemSideLine}>
                <div style={styles.dateTitle}>
                  {t(
                    currentStep !== "AuctionResults"
                      ? "BiddingDate"
                      : "WinningDate"
                  )}
                </div>
                <div style={styles.dateFormat}>
                  {FormatDateYYYYMMDD_Dots(
                    new Date(
                      currentStep !== "AuctionResults"
                        ? BiddingDate
                        : WinningDate
                    )
                  )}
                </div>
              </div>
              {Documentation && Documentation.length > 0 && (
                <div style={styles.itemSideLine}>
                  <div style={styles.dateTitle}>{t("Document")}</div>
                  <div
                    style={{ display: "flex", gap: "2rem", flexWrap: "wrap" }}
                  >
                    {Documentation.map((document, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: ".25rem",
                        }}
                        onClick={openModal}
                      >
                        <img
                          src={getFileIcon()}
                          width={25}
                          alt={``}
                          height={`auto`}
                        />
                        <div
                          style={styles.fileName}
                          tabIndex={!IsMobile() ? 0 : -1}
                        >
                          {document.DocDisplay}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </Fragment>
        </div>
      </div>
      <CustomModal visible={_isModalOpen}
        onClose={() => _setIsModalOpen(false)}
        showCloseButton="internal" typeStyle={"rectangle"}
        innerStyle={{ width: "85%", maxHeight: "100%", padding: 0, height: "50rem",lineHeight: 0,
        borderRadius: "0.8rem",
          overflow:"hidden" }}
        contentStyle={{ padding: 0 }}
      >

        {Documentation && Documentation.length > 0 && (
          <FileViewer
            files={umbracoFiles}
            title={
              (TenderName.length < 30
                ? TenderName
                : `${TenderName.substring(0, 30)}`) || "Document"
            }
            curFileName={""}
          />
        )}
      </CustomModal>
    </Fragment>
  );
};

const styles: StyleSheet = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },
  icon: {
    backgroundRepeat: "no-repeat",
    width: "5rem",
    height: "5rem",
  },
  title: {
    lineHeight: "1.75rem",
    letterSpacing: "0.039375rem",
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.UmbracoElementTitle,
    maxWidth: "55rem",
    cursor: "pointer",
  },
  link: {
    color: colors.nero,
    fontSize: fontSize.Text,
    letterSpacing: "0.029375rem",
  },
  iconText: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaRegular,
  },
  content: {
    fontFamily: fonts.FbReformaMedium,
    lineHeight: "1.6875rem",
    fontSize: fontSize.Text,
    letterSpacing: "0.038125rem",
  },
  searchResultItem: {
    display: "flex",
    gap: "2rem",
    paddingBottom: "1rem",
    paddingTop: "1.5rem",
  },
  iconContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "2.5rem",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    maxWidth: "56rem",
    width: "100%",
  },
  itemsSideLineContainer: {
    display: "flex",
    alignItems: "center",
    paddingInline: "2rem",
    flexWrap: "wrap",
    gap: "5rem",
    flex: 1,
  },
  itemSideLine: {
    borderRight: i18n.dir() === "rtl" ? `.1rem solid ${colors.black}` : "",
    borderLeft: i18n.dir() === "ltr" ? `.1rem solid ${colors.black}` : "",
    padding: "0 1rem",
  },
  dateTitle: {
    fontFamily: fonts.FbReformaRegular,
    color: colors.pasterlGrayDark,
  },
  fileName: {
    cursor: "pointer",
  }
};

export default SearchResultsTenderAuctionItem;
