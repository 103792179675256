import { t } from "i18next";
import colors from "../../Assets/Colors/Colors.json";
import Images from "../../Data/Images/Images";
import { IFooter } from "../../Interfaces/IFooter";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import i18n from "../../Services/i18n";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";
import FooterItem from "./FooterItem";
import FooterItemMobile from "./Mobile/FooterItemMobile";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../Services/DeviceService";
import { useSelector } from "react-redux";
import { SystemParamsState } from "../../Redux/Slices/systemParamsSlice";

const Footer: React.FC<IFooter> = (props) => {
  const { leftFooter, upperRightFooter, bottomRightFooter, websiteVersion } =
    props;
  const deviceMode = useDeviceMode();
  const systemParams = (
    useSelector((state: any) => state.SystemParamsReducer) as SystemParamsState
  ).systemParams;
  const systemParamsDictionary = systemParams.reduce(
    (result: { [key: string]: string }, current) => {
      result[current.key] = current.value;
      return result;
    },
    {}
  );

  

  return (
    <footer
      className="footer"
      id="footer"
      style={{
        position: "relative",
        width: "100%",
      }}
    >
      {deviceMode === StyleMode.desktop && (
        <div
          style={{
            ...styles.footerContainer,
            paddingBottom: deviceMode === StyleMode.desktop ? "1rem" : "2rem",
            padding: deviceMode === StyleMode.desktop ? "5rem" : "",
          }}
        >
          <div style={styles.rightFooters}>
            <div
              style={{
                ...styles.footerLine,
                left: i18n.dir() === "rtl" ? "-3rem" : "",
                right: i18n.dir() === "ltr" ? "-3rem" : "",
              }}
            ></div>
            <div style={styles.upperRightFooter}>
              {upperRightFooter?.map((item, index) => (
                <FooterItem key={item.label + index} {...item} />
              ))}
            </div>
            <div style={styles.bottomRightFooter}>
              {bottomRightFooter?.map((item, index) => (
                <FooterItem
                  key={item.label + index}
                  {...item}
                  isColumn={false}
                />
              ))}
            </div>
            <div
              style={{
                ...styles.uxpertContainer,
                flexDirection: i18n.dir() === "rtl" ? "row" : "row-reverse",
              }}
              aria-label={"Designed By UXPERT"}
              tabIndex={!IsMobile() ? 0 : -1}
              role={"link"}
              onClick={() => {
                window.open(`https://www.uxpert.com/`, "_blank");
              }}
            >
              <img
                src={Images.uxpertLogo}
                width={"60"}
                height={`auto`}
                alt={"UXPERT"}
              />
              <span style={styles.uxpertText}>Designed By</span>
            </div>
            {systemParamsDictionary["sitemap_display"] &&
              systemParamsDictionary["sitemap_display"] === "true" && (
                <div>
                  <div
                    style={{
                      position: "absolute",
                      backgroundColor: colors.koromiko,
                      width: "1.8125rem",
                      height: "2px",
                    }}
                  ></div>
                  <h3>
                    <a
                      style={{
                        paddingTop: "2rem",
                        fontFamily: fonts.FbReformaRegular,
                        fontSize: fontSize.UmbracoElementTitle,
                        color: colors.white,
                      }}
                      href={`/?page=sitemap`}
                    >
                      {`${t("sitemap")}`}
                    </a>
                  </h3>
                </div>
              )}
            {systemParamsDictionary["sitemap_display"] === "false" && (
                <div
                  style={{visibility:"hidden"}}
                >
                <div
                  style={{
                    position: "absolute",
                    backgroundColor: colors.koromiko,
                    width: "1.8125rem",
                    height: "2px",
                  }}
                ></div>
                <h3>
                  <a
                    style={{
                      paddingTop: "2rem",
                      fontFamily: fonts.FbReformaRegular,
                      fontSize: fontSize.UmbracoElementTitle,
                      color: colors.white,
                    }}
                    href={`/?page=sitemap`}
                  >
                    {`${t("sitemap")}`}
                  </a>
                </h3>
              </div>
            ) }
          </div>
          <div
            style={{
              ...styles.leftFooters,
              left: i18n.dir() === "rtl" ? "-2rem" : "",
              right: i18n.dir() === "ltr" ? "-2rem" : "",
            }}
          >
            <div style={styles.footerType}>
              {leftFooter?.map((item, index) => (
                <FooterItem key={item.label + index} {...item} />
              ))}
            </div>
          </div>
        </div>
      )}
      {deviceMode !== StyleMode.desktop && (
        <div
          style={{
            ...styles.footerContainer,
            flexDirection: "column",
            gap: "2rem",
            paddingTop: "1rem",
            alignItems: "center",
            paddingBottom: "3rem",
            maxWidth: "100vw",
            overflowY: "auto",
          }}
        >
          {upperRightFooter?.map((item, index) => (
            <FooterItemMobile
              {...item}
              key={item.label + index}
              isAccordion={false}
              itemsDirection={"column"}
            />
          ))}
          {leftFooter?.map((item, index) => (
            <FooterItemMobile
              {...item}
              key={item.label + index}
              isAccordion={true}
              itemsDirection={"column"}
            />
          ))}
          {bottomRightFooter?.map((item, index) => (
            <FooterItemMobile
              {...item}
              key={item.label + index}
              isAccordion={false}
              itemsDirection={"row"}
              isSubMenuGappedCenter={true}
            />
          ))}
          <div
            style={{
              ...styles.uxpertContainer,
              flexDirection: i18n.dir() === "rtl" ? "row" : "row-reverse",
              padding: "1rem 2rem",
              borderRadius: "2rem",
            }}
            aria-label={"Designed By UXPERT"}
            role={"link"}
            onClick={() => {
              window.open(`https://www.uxpert.com/`, "_blank");
            }}
          >
            <img
              src={Images.uxpertLogo}
              width={"60"}
              height={`auto`}
              alt={"UXPERT"}
            />
            <span
              style={{
                ...styles.uxpertText,
                fontSize: fontSize.UmbracoElementTitle,
              }}
            >
              Designed By
            </span>
          </div>
          {systemParamsDictionary["sitemap_display"] &&
            systemParamsDictionary["sitemap_display"] === "true" && (
              <div
                style={{
                  display: "flex",
                  width: "80%",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <h3>
                  <a
                    style={{
                      paddingTop: "2rem",
                      fontFamily: fonts.FbReformaRegular,
                      fontSize: fontSize.UmbracoElementTitle,
                      color: colors.white,
                    }}
                    href={`/?page=sitemap`}
                  >
                    {`${t("sitemap")}`}
                  </a>
                </h3>
              </div>
            )}
        </div>
      )}
    </footer>
  );
};

const styles: StyleSheet = {
  footerContainer: {
    display: "flex",
    // width: "100%",
    backgroundColor: colors.darkBlue,
    // paddingTop: "5rem",
    // gap: "5rem",
    // justifyContent: "center",
  },
  rightFooters: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    position: "relative",
  },
  bottomRightFooter: {
    display: "flex",
  },
  footerType: {
    display: "flex",
    gap: "3rem",
    flexWrap: "wrap",
  },
  uxpertContainer: {
    display: "flex",
    backgroundColor: colors.white,
    justifyContent: "center",
    alignItems: "center",
    gap: ".5rem",
    borderRadius: ".5rem",
    cursor: `pointer`,
  },
  uxpertText: {
    fontSize: fontSize.Text,
    fontFamily: "HelveticaNeue",
    fontWeight: "400",
  },
  footerLine: {
    position: "absolute",
    height: "90%",
    width: "1px",
    backgroundColor: colors.white,
  },
  websiteVersion: {
    color: colors.white,
    fontSize: fontSize.Paragraph,
    fontFamily: fonts.FbReformaRegular,
  },
  leftFooters: {
    position: "relative",
    padding: "0 2rem",
  },
};

export default Footer;
