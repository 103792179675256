import {
  ISearchResultsCategory,
  ISearchResultsPage,
} from "../Interfaces/ISearchResultsItems";
import { ICategoryPages } from "../Interfaces/ITree";

export const GetSearchedItems = (
  inputValue: string,
  categories: ICategoryPages[]
) => {
  const results: (ISearchResultsCategory | ISearchResultsPage)[] = [];
  for (let i = 0; i < categories.length; i++) {
    if (categories[i].name.toLowerCase().includes(inputValue.toLowerCase())) {
      results.push({
        id: categories[i].id,
        title: categories[i].name,
        searchResultType: "Category",
        pages: categories[i].pages,
      });
      results.push(
        ...categories[i].pages.map((page) => {
          return {
            categoryId: categories[i].id,
            categoryName: categories[i].name,
            id: page.id,
            title: page.title.text,
            summary: page.summary,
            searchResultType: "Page",
          };
        })
      );
    } else {
      const pages = categories[i].pages
        .filter((page) => page.contentTypeAlias === "staticPage")
        .filter(
          (page) =>
            page.title.text.includes(inputValue) ||
            page.summary.includes(inputValue)
        )
        .map((page) => {
          return {
            categoryId: categories[i].id,
            categoryName: categories[i].name,
            id: page.id,
            title: page.title.text,
            summary: page.summary,
            searchResultType: "Page",
          };
        });
      results.push(...pages);
    }
  }
  return results;
};

export const GetFilteredPages = (categories: ISearchResultsCategory[]) => {
  return categories
    .map((category) => category.pages.map((page) => page))
    .flat();
};
