import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "store2";
import Config from "../../Configuration/server";
import { ICookiesManager } from "../../Interfaces/ICookiesModal";
import {
  CookiesSettings,
  setIsCookiesModalVisible,
  setIsCookiesSettingsOpen,
} from "../../Redux/Slices/cookiesSettingsSlice";
import useDeviceMode from "../../Utils/useWindowDimensions";
import CookiesModal from "./CookiesModal";
import CookiesSettingsModal from "./CookiesSettingsModal";

const CookiesManager: React.FC<ICookiesManager> = (props) => {
  const { checkForCookies, cookies } = props;
  //const [_isCookiesModalVisible, _setIsCookiesModalVisible] = useState(false);
  const [_isCheckForCookies, _setIsCheckForCookies] = useState(false);

  const dispath = useDispatch();
  const deviceMode = useDeviceMode();

  const { isCookiesSettingsOpen, isCookiesModalVisible } = useSelector(
    (state: any) => state.CookiesSettingsSlice
  ) as CookiesSettings;

  useEffect(() => {
    store.session.set("isCookiesSettingsOpen", false);
    dispath(setIsCookiesSettingsOpen(false));
    const cookieString = document.cookie;
    const cookieName = `${Config.BASE_LOCALSTORAGE_NAME}_Cookies_Approve`;
    const cookie = cookieString
      .split("; ")
      .find((cookie) => cookie.includes(cookieName));
    if (cookie) {
      const result = cookie.split("=")[1];
      if (result === "true" || result === "false") {
        dispath(setIsCookiesModalVisible(false));
      } else {
        dispath(setIsCookiesModalVisible(true));
       
      }
    } else {
      dispath(setIsCookiesModalVisible(true));
    }
  }, []);

  useEffect(() => {
    _setIsCheckForCookies(checkForCookies === "true");
  }, [checkForCookies]);

  const handleCookies = (result: "Accept" | "Refuse" | "Skip") => {
    dispath(setIsCookiesModalVisible(false));
    const date = new Date();
    const days = 180;
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const cookieName = `${Config.BASE_LOCALSTORAGE_NAME}_Cookies_Approve`;
    const googleAnalyticsCookieName = `${Config.BASE_LOCALSTORAGE_NAME}_Google_Analytics_Enabled`;

    const expires = "expires=" + date.toUTCString();
    switch (result) {
      case "Accept":
        document.cookie = `${cookieName}=true;${expires}`;
        document.cookie = `${googleAnalyticsCookieName}=true;${expires}`;
        break;
      case "Refuse":
        document.cookie = `${cookieName}=false`;
        document.cookie = `${googleAnalyticsCookieName}=true`;
        break;
      default:
        document.cookie = `${googleAnalyticsCookieName}=true`;
        break;
    }
  };

  const handleCloseSettings = () => {
    store.session.set("isCookiesSettingsOpen", false);
    dispath(setIsCookiesSettingsOpen(false));
  };

  const handleSettingsButtonsClick = (selectedSettings: {
    cookiesToggleValue: boolean;
    googleAnalyticsValue: boolean;
    previewValue?: boolean;
  }) => {
    const date = new Date();
    const days = 180;
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = "expires=" + date.toUTCString();
    const cookieName = `${Config.BASE_LOCALSTORAGE_NAME}_Cookies_Approve`;
    const googleAnalyticsCookieName = `${Config.BASE_LOCALSTORAGE_NAME}_Google_Analytics_Enabled`;
    document.cookie = `${cookieName}=${selectedSettings.cookiesToggleValue};${expires}`;
    document.cookie = `${googleAnalyticsCookieName}=${selectedSettings.googleAnalyticsValue};${expires}`;

    if (selectedSettings.cookiesToggleValue) {
      dispath(setIsCookiesModalVisible(false));
    }
    handlePreview(selectedSettings.previewValue);
    handleCloseSettings();
  };

  const handlePreview = (isPreview: boolean = false) => {
    const currentPreview = store.get(`isPreview`);
    if (isPreview !== (currentPreview === `true`)) {
      if (isPreview) {
        store.set(`isPreview`, `true`);
        window.location.reload();
      } else {
        store.set(`isPreview`, `false`);
        window.location.reload();
      }
    }
  };

  const handleOpenSettings = () => {
    store.session.set("isCookiesSettingsOpen", true);
    dispath(setIsCookiesSettingsOpen(true));
  };

  return (
    <>

      {_isCheckForCookies &&
        isCookiesModalVisible && (
          <CookiesModal
            cookies={cookies}
            onClick={handleCookies}
            onOpenSettings={handleOpenSettings}
          />
        )}
      {_isCheckForCookies &&
        isCookiesSettingsOpen &&
        (
          <CookiesSettingsModal
            cookies={cookies}
            onClose={handleCloseSettings}
            onButtonClick={handleSettingsButtonsClick}
          />
        )}
    </>
  );
};

export default CookiesManager;
