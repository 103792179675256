import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { timeString } from "../../Services/DateService";
import Images from "../../Data/Images/Images";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import {
  IOperatorDetails,
  ITransitLineAccordionItem,
} from "../../Interfaces/ITransitTransport";
import i18n from "../../Services/i18n";
import TransitTag from "./TransitTag";
import font from "../../Assets/Fonts/Fonts.json";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";
import { t } from "i18next";

const TransitLineAccordionItem: React.FC<ITransitLineAccordionItem> = (
  props
) => {
  const {
    data,
    isOpen,
    onClick,
    currentId,
    transitLinesLength,
    locationDescriptionHe,
    locationNumber,
  } = props;

  const deviceMode = useDeviceMode();

  const noExpandingNeeded = data.length < 2;

  const expandedHandler = () => !noExpandingNeeded && onClick && onClick();

  return (
    <div>
      <div
        style={{
          ...styles.container,
          paddingLeft: i18n.dir() === "ltr" ? "1.5rem" : "",
          paddingRight: i18n.dir() === "rtl" ? "1.5rem" : "",
          cursor: noExpandingNeeded ? "default" : "pointer",
        }}
        onClick={expandedHandler}
      >
        <div style={styles.lineAgentName}>
          <TransitTag
            transitAgencyName={data[0].opertaorRef}
            transitAgencyColor={data[0].transitAgencyColor}
            transitAgencyIcon={data[0].transitAgencyIcon}
            lineNumber={data[0].line}
          />
        </div>
        <div style={styles.lineDestinationName}>
          {locationDescriptionHe !== ""
            ? locationDescriptionHe
            : locationNumber}
        </div>
        <div
          style={{
            ...styles.expectedArrivalFirst,
            flex: deviceMode === StyleMode.desktop ? "1" : "1.1",
          }}
        >
          <div
            style={{
              ...styles.expectedArrivalFirstTime,
              cursor: noExpandingNeeded ? "default" : "pointer",
            }}
          >
            {timeString(data[0].expectedArrivalTime)}
          </div>
          {!noExpandingNeeded && (
            <img
              style={{
                rotate:
                  i18n.dir() === "ltr"
                    ? isOpen === currentId
                      ? "0deg"
                      : "270deg"
                    : isOpen === currentId
                    ? "0deg"
                    : "90deg",
                color: colors.black,
                fontFamily: font.FbReformaMedium,
              }}
              src={Images.arrowDown}
              alt="arrow"
            />
          )}
        </div>
      </div>

      {isOpen === currentId ? (
        <div
          style={{
            ...styles.dropdownContainer,
            animationName: "growHeight",
            animationDuration: "1.5s",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              ...styles.dropdownHeader,
              paddingLeft: i18n.dir() === "ltr" ? "1.5rem" : "",
              paddingRight: i18n.dir() === "rtl" ? "1.5rem" : "",
            }}
          >
            {t("TheNextBuses")} {/*({data[0].opertaorRef})*/}
          </div>
          <div style={styles.expectedArrivalTimeContainer}>
            {data.length > 1 &&
              data.slice(1).map((line: IOperatorDetails, index: number) => (
                <div style={styles.expectedArrivalTimeTag} key={index}>
                  {line.expectedArrivalTime &&
                    timeString(line.expectedArrivalTime)}
                </div>
              ))}
          </div>
        </div>
      ) : null}
      {transitLinesLength - 1 !== currentId && (
        <div style={styles.border}></div>
      )}
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    height: "5.5rem",
  },

  border: {
    width: "95%",
    height: ".08rem",
    background: colors.borderLightGrey,
    margin: "auto",
  },

  lineDestinationName: {
    flex: "3",
  },

  lineAgentName: {
    minWidth: "2rem",
    display: "flex",
    justifyContent: "center",
    flex: "1",
  },

  question: {
    color: colors.nero,
    fontSize: fontSize.Paragraph,
    lineHeight: "1.75rem",
    fontFamily: fonts.FbReformaRegular,
    flex: "14",
  },
  answer: {
    flex: "17",
    fontFamily: fonts.FbReformaLight,
    color: colors.nero,
  },
  blankLink: {
    color: colors.darkBlue,
    fontSize: fontSize.FooterItem,
    cursor: "pointer",
    fontFamily: font.FbReformaMedium,
  },

  expectedArrivalTimeTag: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.ghostWhite,
    borderRadius: "1rem",
    height: "2.4rem",
    width: "4.5rem",
    paddingRight: "1rem",
    paddingLeft: "1rem",
    cursor: "default",
  },
  expectedArrivalFirst: {
    display: "flex",
    flex: "1.1",
  },

  expectedArrivalTimeContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    gap: "1rem",
    flexWrap: "wrap",
  },

  dropdownHeader: {
    fontFamily: font.FbReformaMedium,
  },

  dropdownContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    paddingBottom: "2rem",
  },
  expectedArrivalFirstTime: {
    display: "flex",
    alignSelf: "center",
    justifySelf: "center",
  },
};

export default TransitLineAccordionItem;
