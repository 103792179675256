import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import { ITitleAndDescription } from "../../Interfaces/IBaseElement";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { replaceRichtextBoldWithFont } from "../../Utils/utils";
import fontSize from "../../Assets/Fonts/FontsSizes.json";

const SideTitleAndDescription: React.FC<ITitleAndDescription> = (props) => {
  const { title, description } = props;


  return (
    <div className="side-title">
      <div
        style={{
          maxWidth: "15rem",
          lineHeight: "3.125rem",
        }}
      >
        <span style={styles.accurecyIndexTitle}>{title}</span>
      </div>
      <div
        style={styles.accurecyIndexDescription}
        dangerouslySetInnerHTML={{ __html: replaceRichtextBoldWithFont(description) }}
      ></div>
    </div>
  );
};

const styles: StyleSheet = {
  accurecyIndexTitle: {
    fontFamily: fonts.FbReformaMedium,
    letterSpacing: "0.08375",
    color: colors.darkBlue,
    fontSize: fontSize.PageTitle,
  },
  accurecyIndexDescription: {
    fontFamily: fonts.FbReformaLight,
    letterSpacing: "0.038125rem",
    color: colors.nero,
    fontSize: fontSize.Paragraph,
    maxWidth: "19rem",
    paddingBottom: "1rem",
  },
};

export default SideTitleAndDescription;
