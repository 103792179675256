import { IAllElement } from "../../Interfaces/IUmbraco";
import Fines from "../../Screens/Fines";
import BaseElement from "./BaseElement";
import Button from "./Careers/Button/Button";
import CareerButtons from "./Careers/CareerButtons/CareerButtons";
import Careers from "./Careers/Careers";
import CounterAndTitles from "./CounterAndTitles";
import CustomerServices from "./CustomerServices/CustomerServices";
import CustomerServicesHeadline from "./CustomerServices/CustomerServicesHeadline";
import Description from "./Description";
import DirectorateMembers from "./DirectorateMembers/DirectorateMembers";
import DynamicPart from "./DynamicPart/DynamicPart";
import FileElement from "./FileElement/FileElement";
import FilesElements from "./FileElement/FilesElements";
import FullSizedTable from "./FullSizedTable/FullSizedTable";
import Gallery from "./Gallery/Gallery";
import TwoItemsGallery from "./Gallery/GalleryTypes/TwoItemsGallery";
import LinkableGalleries from "./Gallery/LinkableGallery/LinkableGalleries";
import HotJobs from "./HotJobs";
import Iframe from "./Iframe";
import Image from "./Image";
import Row from "./ImageAndLink/Row";
import ImageAndText from "./ImageAndText";
import LineText from "./LineText";
import Article from "./Magazine/Article";
import ArticleSlices from "./Magazine/ArticleSlices";
import MagazineCategories from "./Magazine/Categories/MagazineCategories";
import LatestArticles from "./Magazine/LatestArticles";
import SelectedArticles from "./Magazine/SelectedArticles/SelectedArticles";
import PodcastsMain from "./Podcasts/PodcastsMain";
import QaAndIcons from "./QaAndIcons";
import QuestionsAndAnswers from "./QuestionsAndAnswers/QuestionsAndAnswers";
import QuestionsAndAnswersCategories from "./QuestionsAndAnswers/QuestionsAndAnswersCategories";
import QuestionsAndAnswersExtended from "./QuestionsAndAnswersExtended/QuestionsAndAnswersExtended";
import RichTextEditor from "./RichTextEditor";
import ServicesCustomerOptions from "./ServicesCustomerOptions";
import SideTitleAndDescription from "./SideTitleAndDescription";
import SocialCards from "./SocialCards";
import Steps from "./Steps/Steps";
import TableMain from "./Table/TableMain";
import TableUppgradedMain from "./Table/TableUppgradedMain";
import Title from "./Title";
import TitleAndDescriptionRichTextTitle from "./TitleAndDescriptionRichTextTitle";
import TitleSubjectAndDetails from "./TitleSubjectAndDetails";
import TwoSidesLayout from "./TwoSidesLayout/TwoSidesLayout";
import VideoAndText from "./VideoAndText";
import WhatHappendedHere from "./WhatHappendedHere";
import WhyWorkHere from "./WhyWorkHere";
import WorkerTalk from "./WorkerTalk/WorkerTalk";
import WorkersInTrain from "./WorkersInTrain";
import YoutubeStandalone from "./YoutubeStandalone/YoutubeStandalone";

const AllElements: React.FC<IAllElement & any> = (props) => {
  const Components = components[props.elementName];
  if (!Components) return null;
  return <Components {...props} />;
};

const components: IAllElement & any = {
  link: BaseElement,
  article: Article,
  questionsAndAnswers: QuestionsAndAnswers,
  questionsAndAnswersExtended: QuestionsAndAnswersExtended,
  gallery: Gallery,
  twoItemsGallery: TwoItemsGallery,
  imageAndText: ImageAndText,
  videoAndText: VideoAndText,
  richTextEditor: RichTextEditor,
  table: TableMain,
  steps: Steps,
  stepsUnlimit: Steps,
  directorateMembers: DirectorateMembers,
  customerServicesHeadLine: CustomerServicesHeadline,
  customerServices: CustomerServices,
  sideTitleAndDescription: SideTitleAndDescription,
  titleAndDescriptionRichTextTitle: TitleAndDescriptionRichTextTitle,
  dynamicPart: DynamicPart,
  comeWorkWithUs: TwoSidesLayout,
  title: Title,
  text: Title,
  description: Description,
  image_file: Image,
  imageSrc: Image,
  button: Button,
  careerButtons: CareerButtons,
  careers: Careers,
  workersTalk: WorkerTalk,
  fines: Fines,
  podcasts: PodcastsMain,
  qAandIcons: QaAndIcons,
  linkableGalleries: LinkableGalleries,
  youtubeGalleryItem: YoutubeStandalone,
  fileElement: FileElement,
  filesElements: FilesElements,
  counterAndTitlesArray: CounterAndTitles,
  lineText: LineText,
  hotJobs: HotJobs,
  whyWorkHere: WhyWorkHere,
  whatHappendedHere: WhatHappendedHere,
  workersInTrain: WorkersInTrain,
  fullSizedTable: FullSizedTable,
  tableUppgraded: TableUppgradedMain,
  row: Row,
  latestArticles: LatestArticles,
  magazineCategories: MagazineCategories,
  articleSlice: ArticleSlices,
  selectedArticles: SelectedArticles,
  servicesCustomerOptions: ServicesCustomerOptions,
  questionsAndAnswersAndCategories: QuestionsAndAnswersCategories,
  socialCards: SocialCards,
  titleSubjectAndDetails: TitleSubjectAndDetails,
  iframe: Iframe,
};

export default AllElements;
export { components };

