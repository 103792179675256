import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ISystemParam } from "../../Interfaces/IConfig";

export interface SystemParamsState {
  systemParams: ISystemParam[];
}

const initialState: SystemParamsState = {
  systemParams: [],
};

export const systemParamsSlice = createSlice({
  name: "systemParams",
  initialState,
  reducers: {
    setSystemParams: (state, action: PayloadAction<ISystemParam[]>) => {
      state.systemParams = action.payload;
    },
  },
});

export const { setSystemParams } = systemParamsSlice.actions;

export default systemParamsSlice.reducer;
