import { combineReducers } from "redux";
import { AuctionsErrorStatus, AuctionsErrorStatusUpdateClientNewTenders, AuctionsReducer } from "./AuctionsReducer";
import { ScheduleReducer } from "./ScheduleReducer";
import { TreeReducer } from "./TreeReducer";

const RootReducer = combineReducers({
  ScheduleReducer,
/*  PageReducer,*/
  TreeReducer,
  AuctionsReducer,
 // AuctionsTypeReducer,
  AuctionsErrorStatus,
  AuctionsErrorStatusUpdateClientNewTenders,
});

export default RootReducer;
