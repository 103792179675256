import { t } from "i18next";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import store from "store2";
import colors from "../../Assets/Colors/Colors.json";
import Config from "../../Configuration/server";
import Images from "../../Data/Images/Images";
import { IStation } from "../../Interfaces/IStation";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { PageState, setPage } from "../../Redux/Slices/pageSlice";
import fonts from "../../Assets/Fonts/Fonts.json";
import {
  ScheduleState,
  setDestinationStation,
  setOriginStation,
} from "../../Redux/Slices/scheduleSlice";
import i18n from "../../Services/i18n";
import { GetStationsDictionary } from "../../Utils/helpers";
import { removeSpecialCharacters } from "../../Utils/utils";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../Services/DeviceService";
import { Scale } from "../../Utils/scale";


const FromWhereMobile: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const schedule = useSelector(
    (state: any) => state.ScheduleReducer
  ) as ScheduleState;
  const currentPage = (
    useSelector((state: any) => state.PageReducer) as PageState
  ).page;

  const allStations = store.session.get(
    `${Config.BASE_LOCALSTORAGE_NAME}stations`
  );
  const stations: IStation[] = allStations && allStations[i18n.language];
  const dictStations = stations && GetStationsDictionary(stations);

  const originStationAria =
    schedule.fromStation &&
    removeSpecialCharacters(
      GetStationsDictionary(allStations[i18n.language])[schedule.fromStation]
        .stationName
    );

  const destinationStationAria =
    schedule.toStation &&
    removeSpecialCharacters(
      GetStationsDictionary(allStations[i18n.language])[schedule.toStation]
        .stationName
    );

  const handleSwitchStations = () => {
    if (schedule.fromStation && schedule.toStation) {
      const originStation = dictStations[schedule.fromStation].stationId;
      const destinationStation = dictStations[schedule.toStation].stationId;
      dispatch(setOriginStation(destinationStation));
      dispatch(setDestinationStation(originStation));
    }
  };

  const handleSwitchClick = () => {
    handleSwitchStations();
    if (currentPage === "routePlanSearchResults".toLowerCase()) {
      navigate(
        `?page=routePlanSearchResults&fromStation=${schedule.toStation}&toStation=${schedule.fromStation}&date=${schedule.date}&hours=${schedule.hours}&minutes=${schedule.minutes}&scheduleType=${schedule.scheduleType}`
      );
    }
  };

  const handleOriginStationClick = () => {
    dispatch(setPage("routePlan"));
    navigate(`?page=routePlan&step=origin&fromStation=${schedule.fromStation}`);
  };

  const handleDestinationStationClick = () => {
    dispatch(setPage("routePlan"));
    navigate(
      `?page=routePlan&step=destination&fromStation=${schedule.fromStation}&toStation=${schedule.toStation}`
    );
  };

  return (
    <div style={styles.wrapper}>
      {stations.length > 0 && schedule.fromStation && schedule.toStation ? (
        <div style={styles.mainContainer}>
          <div style={styles.column}>
            <div style={styles.row}>
              <img
                src={Images.originWhite}
                style={styles.originIcon}
                width={`auto`}
                height={`auto`}
                alt={``}
              />
              <div style={styles.stationName}>
                <span
                  role="button"
                  onClick={handleOriginStationClick}
                  tabIndex={!IsMobile() ? 0 : -1}
                  aria-label={t("RedirectToOriginPageWithStation", {
                    stationName: dictStations[schedule.fromStation].stationName,
                  })}
                >
                  {dictStations[schedule.fromStation].stationName}
                </span>
              </div>
            </div>
            <div style={styles.row}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  
                  padding:i18n.dir() === "rtl"?"":"0 2px"
                }}
              >
                <img src={Images.dotsNew} style={styles.dots} alt={``} />
              </div>
              <div style={styles.line}></div>
            </div>
            <div style={styles.row}>
              <img
                src={Images.destinationWhite}
                style={styles.destinationIcon}
                width={`auto`}
                height={`auto`}
                alt={``}
              />
              <div style={styles.stationName}>
                <span
                  role="button"
                  onClick={handleDestinationStationClick}
                  tabIndex={!IsMobile() ? 0 : -1}
                  aria-label={t("RedirectToDestinationPageWithStation", {
                    stationName: dictStations[schedule.toStation].stationName,
                  })}
                >
                  {dictStations[schedule.toStation].stationName}
                </span>
              </div>
            </div>
          </div>
          <img
            role={"button"}
            onClick={handleSwitchClick}
            src={Images.switchMob}
            style={styles.switchIcon}
            className={"switchIcon-firefox"}
            width={`auto`}
            height={`auto`}
            alt={`switch direction`}
            aria-label={`${t("CurrentRoute")} ${t("From-station-to", {
              origin: originStationAria,
              destination: destinationStationAria,
            })}
            
            ${t("ChangeStationsAria", {
              fromStation: destinationStationAria,
              toStation: originStationAria,
            })}`}
            tabIndex={!IsMobile() ? 0 : -1}
          />
        </div>
      ) : null}
    </div>
  );
};

const styles: StyleSheet = {
  wrapper: {
    position: "absolute",
    // top: "25%",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    width: "60%",
  },
  row: {
    display: "flex",
    alignItems: "center",
    //padding:"0px,10px"
     gap: ".5rem",
  },
  line: {
    width: "100%",
    height: "1px",
    backgroundColor: colors.white,
  },
  stationName: {
    padding: "0px 10px",
    alignSelf: "start",
    position: "relative",
    // marginBottom: "-.5rem",
    // marginTop: "-.25rem",
    fontSize: fontSize.FooterItem,
    lineHeight: fontSize.FooterItem,
    width: "24rem",
    // maxWidth:"35rem",
    whiteSpace: "pre",
    color: colors.white,
    fontFamily:fonts.FbReformaMedium,
    overflow:"hidden",
    textOverflow:"ellipsis"
  },
  originIcon: {
    transform: `scale(${Math.min(Scale(1.8,window.innerWidth),1.8)})`,
  },
  destinationIcon: {
    transform: `scale(${Math.min(Scale(1.8,window.innerWidth),1.8)})`,
  },
  switchIcon: {
    zIndex: "10",
  },
  dots: {
    transform:`scale(${Math.min(Scale(1.8,window.innerWidth),1.2)})`,
    // backgroundColor:"GrayText",

  },
};

export default FromWhereMobile;
