import { forwardRef, useImperativeHandle, useState } from "react";
import { StyleSheet } from "../../../Interfaces_tenders/IStyleSheet";
import colors from "../../../../../Assets/Colors/Colors.json";
import Images from "../../../../../Data/Images/Images";
import { IInputTextForm } from "../../../Interfaces_tenders/IForm";
import fonts from "../../../../../Assets/Fonts/Fonts.json";
import useDeviceMode from "../../../../../Utils/useWindowDimensions";
import { StyleMode } from "../../../../../Utils/consts";



const InputTextSearchForm: React.FC<IInputTextForm> = forwardRef(
  (props, ref) => {
    const { placeholder, onChange, maxWidth, inputRef } = props;
    const [_isFocus, _setIsFocus] = useState(false);
    const deviceMode = useDeviceMode();

    useImperativeHandle(ref, () => ({
      clearText() {
        if (inputRef?.current) {
          inputRef.current.value = "";
          inputRef.current.focus();
        }
      },
    }));

    return (
      <div
        style={{
          ...styles.container,
          border: `0.0625rem solid ${
            _isFocus ? colors.darkBlue : colors.pastelGray
          }`,
          maxWidth: maxWidth ? maxWidth : "18.6rem",
        }}
      >
        <img src={Images.search} alt="" style={{ scale:deviceMode === StyleMode.mobile?"0.7":"unset"}} />
        <input
          ref={inputRef}
          type={"text"}
          placeholder={placeholder}
          style={styles.input}
          onClick={() => _setIsFocus(true)}
          onBlur={() => _setIsFocus(false)}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
    );
  }
);

const styles: StyleSheet = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "2.5rem",
    borderRadius: "1.25rem",
    gap: ".7rem",
    padding: "0 .5rem",
    position: "relative",
  },
  input: {
    width: "80%",
    fontSize: "1.125rem",
    color: colors.pasterlGrayDark,
    letterSpacing: "0.043125rem",
    fontFamily: fonts.FbReformaRegular,
  },

};

export default InputTextSearchForm;
