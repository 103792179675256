import colors from "../../../../Assets/Colors/Colors.json";
import { useLocation, useNavigate } from "react-router-dom";
import i18n from "../../../../Services/i18n";
import LanguageSelection from "../LanguageSelection/LanguageSelection";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useDeviceMode from "../../../../Utils/useWindowDimensions";
import closeIcon from "../../../../Assets/Icons/close_no_background.svg";
import { IHeaderContent } from "../../Interfaces_tenders/IUmbraco";
import { StyleMode } from "../../../../Utils/consts";
import clearIcon from "../../../../Assets/Icons/close.svg";
import { scheduleActions } from "../../Redux/Actions/Schedule";
import { bindActionCreators } from "redux";
import Store from "../../Redux/Store/Store";
import { setErrorStatus } from "../../Redux/Actions/Auctions/AuctionsActions";
import fonts from "../../../../Assets/Fonts/Fonts.json"

const Header: React.FC = () => {
  const deviceMode = useDeviceMode();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPage = useSelector((state: any) => state.PageReducer);

  const [_isSearchVisible, _setIsSearchVisible] = useState(false);
  const [_headerContant, _setHeaderContent] = useState<IHeaderContent>();

  const { setOriginStation, setDestinationStation } = bindActionCreators(
    scheduleActions,
    dispatch
  );

  const inputRef = useRef<HTMLInputElement>(null);

  // useEffect(() => {
  //   (async () => {
  //     const headerContent = await GetHeader(i18n.language);
  //     _setHeaderContent(headerContent);
  //   })();
  // }, []);

  useEffect(() => {
    handleCloseSearch();
  }, [location]);

  useEffect(() => {
    if (_isSearchVisible) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [_isSearchVisible]);

  useEffect(() => {
    handleCloseSearch();
  }, [location.search]);


  
  const handleLogoClick = () => {
    Store.dispatch(setErrorStatus(false));
    setOriginStation(undefined);
    setDestinationStation(undefined);
    navigate("/?page=main&auctionsExtrenal=true");
    
  };

  const handleOpenSearch = () => {
    _setIsSearchVisible(true);
  };

  const handleCloseSearch = () => {
    _setIsSearchVisible(false);
  };

  const handleSearch = () => {
    const searchBoxText = inputRef.current?.value as string;
    if (searchBoxText?.length > 0) {
      _setIsSearchVisible(false);
      navigate(`?page=searchResults&queryText=${searchBoxText}`);
    }
  };

  const handleClearInput = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      inputRef.current.focus();
    }
  };

  return currentPage !== "routePlan".toLowerCase() ? (
    <div style={styles.mainHeaderWrapper} className="header">
      <div
        style={{
          ...styles.menuContainer,
          backgroundColor:
            currentPage === "" ? colors.whiteTransparent : colors.white,
        }}
      >
        <Fragment>
          <div
            style={{
              ...styles.logo,
            }}
            role={"button"}
            onClick={handleLogoClick}
          ></div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <LanguageSelection
              languages={_headerContant?.languages || []}
        
            />
            {(deviceMode === StyleMode.mobile ||
              deviceMode === StyleMode.tablet) && (
              <div
                style={{
                  paddingTop: ".8rem",
                }}
              ></div>
            )}
          </div>
        </Fragment>
      </div>
      {_isSearchVisible && (
        <div style={styles.searchContainer}>
          <div style={styles.searchBoxContainer}>
            <div
              style={{
                ...styles.siteSearchIcon,
                padding: "0 .5rem",
                cursor: "default",
              }}
            ></div>
            <input
              type={"text"}
              style={styles.searchBox}
              placeholder={_headerContant?.searchPlaceholder}
              ref={inputRef}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
            />
            <div
              style={styles.siteClearSearchIcon}
              onClick={handleClearInput}
            ></div>
          </div>
          <button style={styles.searchButton} onClick={handleSearch}>
            {_headerContant?.searchButton}
          </button>
        </div>
      )}
    </div>
  ) : null;
};

const styles: any = {
  mainHeaderWrapper: {
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    top: "0",
    left: "0",
    zIndex: "99999",
    width: "100%",
  } as React.CSSProperties,
  menuContainer: {
    height: "3rem",
    width: "100%",
    backgroundColor: colors.whiteTransparent,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // boxShadow: "0px -1px 5px 0px black",
    zIndex: "20",
  } as React.CSSProperties,
  menuItemsContainer: {
    display: "flex",
    marginInlineStart: "5rem",
    marginInlineEnd: "5rem",
    gap: "25px",
    flex: 2,
    color: colors.darkBlue,
  } as React.CSSProperties,
  logo: {
    backgroundRepeat: "no-repeat",
    paddingInlineStart: "1rem",
    paddingInlineEnd: "1rem",
    cursor: "pointer",
    width: "10rem",
    height: "1.4rem",
  } as React.CSSProperties,
  menuUtils: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
  } as React.CSSProperties,
  line: {
    boxSizing: "border-box",
    height: "1.5rem",
    width: "0.0063125rem",
    border: `0.01875rem solid ${colors.koromiko}`,
  } as React.CSSProperties,
  siteSearchIcon: {
    backgroundRepeat: "no-repeat",
    width: "1.5rem",
    height: "1.2rem",
    cursor: "pointer",
    transform: "scale(.8)",
    marginInlineStart: ".5rem",
  } as React.CSSProperties,
  siteClearSearchIcon: {
    backgroundRepeat: "no-repeat",
    width: "2rem",
    height: "2.5rem",
    cursor: "pointer",
    transform: "scale(0.8)",
    margin: "0 .8rem",
  } as React.CSSProperties,
  siteSearchCloseIcon: {
    backgroundRepeat: "no-repeat",
    width: "1.2em",
    height: "1.2rem",
  } as React.CSSProperties,
  closeSearchContainer: {
    display: "flex",
    fontSize: "1rem",
    fontFamily: fonts.FbReformaMedium,
    color: colors.darkBlue,
    cursor: "pointer",
  } as React.CSSProperties,
  searchContainer: {
    display: "flex",
    backgroundColor: colors.whiteTransparent,
    height: "12rem",
    alignItems: "center",
    justifyContent: "center",
    gap: "2.5rem",
  } as React.CSSProperties,
  searchBoxContainer: {
    display: "flex",
    backgroundColor: colors.white,
    height: "2.5rem",
    borderRadius: "1.25rem",
    border: `1px solid ${colors.pastelGray}`,
    maxWidth: "69rem",
    width: "90%",
    alignItems: "center",
    gap: "1rem",
  } as React.CSSProperties,
  searchBox: {
    flex: "1",
    fontSize: "1.1rem",
    fontFamily: fonts.FbReformaRegular,
    color: colors.pasterlGrayDark,
    letterSpacing: "0.043125rem",
  } as React.CSSProperties,
  searchButton: {
    width: "10rem",
    height: "2.5rem",
    backgroundColor: colors.darkBlue,
    border: `1px solid ${colors.darkBlue}`,
    borderRadius: "1.25rem",
    color: colors.white,
    fontFamily: fonts.FbReformaMedium,
    fontSize: "1rem",
    letterSpacing: "0.029375rem",
    cursor: "pointer",
  } as React.CSSProperties,
};

export default Header;
