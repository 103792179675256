import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { t } from "i18next";

const PisTrainCardsHeaders: React.FC = () => {
  return (
    <div>
      <div style={styles.container}>
        <div style={styles.trainNumberContainer}>
          <span>{t("PisTrainNumberHeader")}</span>
        </div>
        <div style={styles.destinationStation}>
          <span>{t("PisDestinationStationHeader")}</span>
        </div>
        <span style={styles.arrivalTime}>{t("PisTrainTimeHeader")}</span>
        <div style={styles.platformContainer}>
          <span style={styles.platform}>{t("PisPlatformHeader")}</span>
        </div>
        <div style={styles.intermediateStations}>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    minWidth: "52.5rem",
    maxWidth: "52.5rem",
    width: "100%",
    minHeight: "5rem",
    backgroundColor: colors.mostLightGray,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.Paragraph,
  },
  trainNumberContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: ".5rem",
    flex: 2,
  },
  destinationStation: {
    display: "flex",
    alignItems: "center",
    gap: ".5rem",
    flex: 4,
  },
  arrivalTime: {
    flex: 0,
  },
  platformContainer: {
    flex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  platform: {
    display: "flex",
    boxSizing: "border-box",
    borderRadius: "0.1875rem",
    padding: "0 .5rem",
  },
  intermediateStations: {
    display: "flex",
    alignItems: "center",
    gap: ".5rem",
    color: colors.darkBlue,
    flex: 2,
  },
};

export default PisTrainCardsHeaders;
