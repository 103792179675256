import colors from "../../../Assets/Colors/Colors.json";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import CustomSelect from "../CustomFileds/CustomSelect";
import { ChangeEvent, useEffect } from "react";
import { IFormReferenceDetails } from "../../../Interfaces/IForms";
import Title from "../../UmbracoElements/Title";
import FormsDB from "../db.json";
import FormAccessibilityOrCleaningIssueScreen from "../Screens/FormAccessibilityOrCleaningIssueScreen";
import FormAppealRequirementPaymentScreen from "../Screens/FormAppealRequirementPaymentScreen";
import FormCheckEntranceStationScreen from "../Screens/FormCheckEntranceStationScreen";
import FormClaimRailsWorkerOrThanksAppreciationScreen from "../Screens/FormClaimRailsWorkerOrThanksAppreciationScreen";
import FormClaimTicketPurchaseScreen from "../Screens/FormClaimTicketPurchaseScreen";
import FormCompensationRequestScreen from "../Screens/FormCompensationRequestScreen";
import FormInquiryAboutWebAppScreen from "../Screens/FormInquiryAboutWebAppScreen";

const FormReferenceDetails: React.FC<IFormReferenceDetails> = (props) => {
  const { formik } = props;

  const deviceMode = useDeviceMode();

  useEffect(() => {
    formik.setFieldValue(
      "FormAccessibilityOrCleaningIssueScreen.currentSubject",
      formik.values.FormReferenceDetails.serviceSubject
    );
    formik.setFieldValue(
      "FormAppealRequirementPaymentScreen.currentSubject",
      formik.values.FormReferenceDetails.serviceSubject
    );
    formik.setFieldValue(
      "FormCheckEntranceStationScreen.currentSubject",
      formik.values.FormReferenceDetails.serviceSubject
    );
    formik.setFieldValue(
      "FormClaimRailsWorkerOrThanksAppreciationScreen.currentSubject",
      formik.values.FormReferenceDetails.serviceSubject
    );
    formik.setFieldValue(
      "FormClaimTicketPurchaseScreen.currentSubject",
      formik.values.FormReferenceDetails.serviceSubject
    );
    formik.setFieldValue(
      "FormCompensationRequestScreen.currentSubject",
      formik.values.FormReferenceDetails.serviceSubject
    );
    formik.setFieldValue(
      "FormInquiryAboutWebAppScreen.currentSubject",
      formik.values.FormReferenceDetails.serviceSubject
    );

  
  }, [formik.values]);

  
  return (
    <div style={styles.container}>
      <Title text="פרטי הפניה" />

      <div style={styles.form}>
        <CustomSelect
          label="נושא הפניה"
          name="FormReferenceDetails.serviceSubject"
          value={formik.values.FormReferenceDetails?.serviceSubject}
          placeholder="serviceSubject"
          onChange={(val: ChangeEvent<HTMLSelectElement>) => {
            formik.setFieldValue(
              "FormReferenceDetails.serviceSubject",
              val.target.value
            );
          }}
          errors={formik.errors.FormReferenceDetails?.serviceSubject}
          touched={formik.touched.FormReferenceDetails?.serviceSubject+''}
          selectstyle={{
            flex: "unset",
            width: deviceMode === StyleMode.mobile ? "100%" : "48%",
          }}
        >
          <option value="">יש לבחור את נושא הפניה</option>
          {FormsDB.seviceValues.map((service, index) => (
            <option key={index} value={service.serviceKey}>
              {service.serviceValue}
            </option>
          ))}
        </CustomSelect>
      </div>

      {formik.values.FormReferenceDetails.serviceSubject ===
        "accessibilityIssue" && (
        <FormAccessibilityOrCleaningIssueScreen
          formik={formik}
          serviceValue="accessibilityIssue"
        />
      )}
      {formik.values.FormReferenceDetails.serviceSubject ===
        "appealRequirementPayment" && (
        <FormAppealRequirementPaymentScreen
          formik={formik}
          serviceValue="appealRequirementPayment"
        />
      )}
      {formik.values.FormReferenceDetails.serviceSubject ===
        "checkEntranceStation" && (
        <FormCheckEntranceStationScreen
          formik={formik}
          serviceValue="checkEntranceStation"
        />
      )}
      {formik.values.FormReferenceDetails.serviceSubject ===
        "claimRailsWorker" && (
        <FormClaimRailsWorkerOrThanksAppreciationScreen
          formik={formik}
          serviceValue="claimRailsWorker"
        />
      )}
      {formik.values.FormReferenceDetails.serviceSubject ===
        "claimTicketPurchase" && (
        <FormClaimTicketPurchaseScreen
          formik={formik}
          serviceValue="claimTicketPurchase"
        />
      )}

      {formik.values.FormReferenceDetails.serviceSubject ===
        "cleaningIssue" && (
        <FormAccessibilityOrCleaningIssueScreen
          formik={formik}
          serviceValue="cleaningIssue"
        />
      )}
      {formik.values.FormReferenceDetails.serviceSubject ===
        "compensationRequest" && (
        <FormCompensationRequestScreen
          formik={formik}
          serviceValue="compensationRequest"
        />
      )}
      {formik.values.FormReferenceDetails.serviceSubject ===
        "inquiryAboutWebApp" && (
        <FormInquiryAboutWebAppScreen
          formik={formik}
          serviceValue="inquiryAboutWebApp"
        />
      )}
      {formik.values.FormReferenceDetails.serviceSubject ===
        "thanksAppreciation" && (
        <FormClaimRailsWorkerOrThanksAppreciationScreen
          formik={formik}
          serviceValue="thanksAppreciation"
        />
      )}
    
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    maxWidth: "60%",
    margin: "auto",
    paddingBottom: "2rem",
  },
  form: { display: "flex", flexWrap: "wrap", columnGap: "2rem" },
  button: {
    width: "100%",
    padding: "0.65rem 0.5rem",
    fontSize: "1rem",
    color: colors.white,
    border: "2px solid",
    backgroundColor: colors.blue,
    borderRadius: "10px",
    outline: "none",
    margin: "1rem 0",
    fontWeight: "bold",
    cursor: "pointer",
  },
};

export default FormReferenceDetails;
