import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface SomethingWentWrongState {
  somethingWentWrong: boolean;
}

const initialState: SomethingWentWrongState = {
  somethingWentWrong: false,
};

export const umbracoStoreSlice = createSlice({
  name: "somethingWentWrong",
  initialState,
  reducers: {
    setSomethingWentWrong: (state:SomethingWentWrongState, action: PayloadAction<boolean>) => {
      state.somethingWentWrong = action.payload;
    },
  },
});

export const { setSomethingWentWrong } = umbracoStoreSlice.actions;

export default umbracoStoreSlice.reducer;
