import colors from "../../../../Assets/Colors/Colors.json";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import ArticleTitle from "./ArticleTitle";
import CreationDetails from "./CreationDetails";
import Tags from "./Tags";

type IArticleTitleAndText = {
    tags: string[],
    title: string,
    onClick?: () => void,
    showBorderLine?: boolean
    anchor?: string
    author: string
    readingTime: string
    creationDate: Date,
    textWapperStyle?: React.CSSProperties,
    tagsStyle?: React.CSSProperties,
    maxWidth?: number | string
}
const ArticleTitleAndText: React.FC<IArticleTitleAndText> = (props) => {
    const { showBorderLine, tags, anchor, author, creationDate, readingTime, tagsStyle,
        title, onClick, textWapperStyle, maxWidth = "30rem" } = props;
    const borderBottom = showBorderLine ? `1px solid ${colors.pastelGray}` : "unset";
    const container = { ...styles.container, borderBottom, maxWidth, paddingBottom: showBorderLine ? "1.5rem" : "0" }

    return (<div style={container} anchor-id={anchor}>
        <Tags tags={tags} style={tagsStyle} />
        <div style={textWapperStyle}>
            <ArticleTitle title={title} onClick={onClick} />
            <CreationDetails author={author} creationDate={creationDate} readingTime={readingTime} />
        </div>
    </div>);
};
const styles: StyleSheet = {
    container: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        boxSizing: "border-box",
    }
};

export default ArticleTitleAndText;
