import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import Images from "../../../Data/Images/Images";
import { IRoutePlanSearchResultsMoreActions } from "../../../Interfaces/IRoutePlanSearchResults";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { setPage } from "../../../Redux/Slices/pageSlice";
import {
  ScheduleState,
  setDestinationStation,
  setOriginStation,
} from "../../../Redux/Slices/scheduleSlice";

const RoutePlanSearchResultsMoreActions: React.FC<
  IRoutePlanSearchResultsMoreActions
> = (props) => {
  const { fromStation, toStation } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const schedule = useSelector(
    (state: any) => state.ScheduleReducer
  ) as ScheduleState;

  const handleSwitchStations = () => {
    if (schedule.fromStation && schedule.toStation) {
      const originStation = fromStation;
      const destinationStation = toStation;
      dispatch(setOriginStation(destinationStation));
      dispatch(setDestinationStation(originStation));
    }
  };

  const redirectToStationPage = (stationId: number) => {
    navigate(`?page=stationinfo&stationname=${stationId}`);
  };

  const handleSwitchClick = () => {
    handleSwitchStations();
    dispatch(setPage("routePlan"));
    navigate(
      `?page=routePlan&step=when&fromStation=${schedule.toStation}&toStation=${schedule.fromStation}&date=${schedule.date}&hours=${schedule.hours}&minutes=${schedule.minutes}&scheduleType=${schedule.scheduleType}`
    );
  };

  return (
    <div style={styles.container}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          position: "relative",
          paddingInline: "3rem",
          boxSizing: 'border-box'
        }}
      >
        <div style={{ ...styles.item }} onClick={handleSwitchClick}>
          <img src={Images.return} width={`auto`} height={`auto`} alt={``} />
          <div onClick={handleSwitchClick}>{t("SearchRouteBack")}</div>
        </div>
        <div style={styles.item} onClick={() => redirectToStationPage(fromStation)}>
          <img
            src={Images.info_mobile}
            width={`auto`}
            height={`auto`}
            alt={``}
          />
          <span onClick={() => redirectToStationPage(fromStation)}>
            {t("InfoAboutOriginStation")}
          </span>
        </div>
        <div style={{ ...styles.item }} onClick={() => redirectToStationPage(toStation)}>
          <img
            src={Images.info_mobile}
            width={`auto`}
            height={`auto`}
            alt={``}
          />
          <span onClick={() => redirectToStationPage(toStation)}>
            {t("InfoAboutDestinationStation")}
          </span>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  item: {
    display: "flex",
    alignItems: "center",
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.FooterItem,
    lineHeight: "4rem",
    cursor: "pointer",
    gap: "1rem",
    position: "relative",
    width: "100%",
    borderTop: `1.5px solid ${colors.pastelGray}`
    // paddingInlineStart: "3rem",
  },
  actionsHeaderContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    transform: "translateY(-2.1rem)",
  },
  actionsHeaderTitle: {
    color: colors.darkBlue,
  },
  line: {
    position: "absolute",
    top: "0",
    width: "65%",
    height: "0.0625rem",
    backgroundColor: colors.pastelGray,
  },
};

export default RoutePlanSearchResultsMoreActions;
