import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { ISplittedTitle } from "../../../Interfaces/IUmbraco";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";

const SplittedTitle: React.FC<ISplittedTitle> = (props) => {
  const { topText, bottomText, style } = props;
  return (
    <h2
      style={{
        width: "15rem",
      }}
      // aria-label={`${RemoveHtmlTags(topText)} ${RemoveHtmlTags(
      //   bottomText
      // )} heading level 2`}
    >
      <div
        style={{
          ...styles.splittedTitle,
          height: "3rem",
        }}
        dangerouslySetInnerHTML={{ __html: topText }}
      ></div>
      <div
        style={{
          ...styles.splittedTitle,
          fontFamily: fonts.FbReformaMedium,
          ...style,
        }}
        dangerouslySetInnerHTML={{ __html: bottomText }}
      ></div>
    </h2>
  );
};

const styles: StyleSheet = {
  splittedTitle: {
    fontFamily: fonts.FbReformaRegular,
    color: colors.darkBlue,
    fontSize: fontSize.PageTitle,
    height: "5rem",
  },
};

export default SplittedTitle;
