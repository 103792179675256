import { useState } from "react";
import { ITab } from "../../Interfaces/ITabs";
import { ISEOStation } from "../../Interfaces/IUmbraco";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";
import TransitTransportPage from "../ComplimentaryTransport/TransitTransportPage";
import StationInfoDetails from "./StationInfoDetails";
import StationInfoEnvironment from "./StationInfoEnvironment";
import StationInfoEnvironmentMobile from "./StationInfoEnvironmentMobile";
import StationInfoParking from "./StationInfoParking";
interface IStationInfo {
  info:any,
  _isSelected: string,
   seoObject?:ISEOStation
}
const StationInfo: React.FC<IStationInfo> = (props) => {
  const { info, _isSelected ,seoObject} = props;
  const [_tabs, _setTabs] = useState<ITab[]>([]);
  const [_currentStation, _setCurrentStation] = useState<any>({});
  const [_Details, _setDetails] = useState<any>({});
  const [_searchObejct, _setSearchObejct] = useState({});
  const [_loding, _setLoding] = useState(true);
  const deviceMode = useDeviceMode();
  const [_type, _setType] = useState(
    deviceMode === StyleMode.mobile ? "mobile" : "desktop"
  );
  return (
    <>
      {info.safetyInfos && (
        <>
          {_isSelected === "info" && (
            <StationInfoDetails {...info} type={_type} key={_type} seoObject={seoObject} />
          )}
          {_isSelected === "transit" && (
            <TransitTransportPage
              typeOfPage="stationInfo"
              type={_type}
              {...info.stationDetails}
              // onClick={FormatDateYYYYMMDDTHHMM(new Date())}
            />
          )}
          {_isSelected === "parking" && (
            <StationInfoParking type={_type} {...info.stationDetails} />
          )}
          {_isSelected === "environment" &&
            (deviceMode === StyleMode.mobile ? (
              <StationInfoEnvironmentMobile {...info} />
            ) : (
              <StationInfoEnvironment {...info} type={_type} />
            ))}
        </>
      )}
    </>
  );
};

export default StationInfo;
