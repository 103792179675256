import { IArticle, IArticleWithMedia } from "../../../../Interfaces/IMagazinePage";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import { StyleMode } from "../../../../Utils/consts";
import useDeviceMode from "../../../../Utils/useWindowDimensions";
import Title from "../../Title";
import ArticleModal from "../ArticleModal";

type ILayoutMagazine = {
    title: string,
    anchor?: string
    children: React.ReactNode;
    modalData?: IArticle | IArticleWithMedia;
    onCloseModal?: () => void
}

const LayoutMagazine: React.FC<ILayoutMagazine> = (props) => {
    const { title, children, anchor, modalData, onCloseModal } = props;
    const deviceMode = useDeviceMode();
    const titleStyle = deviceMode === StyleMode.desktop ? styles.title : styles.titleMobile
    return (<>
        <div style={titleStyle} anchor-id={anchor}>
            <Title text={title} />
        </div>
        <div>
            {children}
        </div>
        {modalData && <ArticleModal article={modalData} onClose={() => onCloseModal && onCloseModal()} />}
    </>);
};
const styles: StyleSheet = {
    title: {
        paddingBlock: "3rem"
    },
    titleMobile: {
        padding: "2rem"
    }
};

export default LayoutMagazine;
