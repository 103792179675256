import { t } from "i18next";
import { useEffect, useState } from "react";
import colors from "../../Assets/Colors/Colors.json";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import i18n from "../../Services/i18n";

const dirLeftRight = i18n.dir() === "ltr" ? "right" : "left";

const CircleInfoMobile: React.FC<any> = (props) => {
  const { width, icon, title, index,scale,tagName ,location,stationDetails} = props;
  let [dirRightLeft, _setDirRightLeft] = useState<"left" | "right">("right");
  let [dirLeftRight, _setDirLeftRight] = useState<"left" | "right">("right");

  useEffect(() => {
    _setDirRightLeft(i18n.dir() === "ltr" ? "left" : "right")
    _setDirLeftRight(i18n.dir() === "ltr" ? "right" : "left")
  }, []);
  const styles: StyleSheet = {
    board: {
      overflow: "hidden",
      background: colors.white,
      width: "100%",
      aspectRatio: "2.25 / 1",
      // position: "fixed",
      // zIndex:11111111111111,
      // top: 0,
      // [dirLeftRight]: 0
    },
    dots: {
      position: "relative",
      height: "100%",
    },
    floatBubble: {
      position: "absolute",
      aspectRatio: "1 / 1",
      zIndex: 1,
    },
    dot: {
      width: "100%",
      height: "100%",
      boxShadow: "rgb(0 0 0 / 16%) 0px 0.25vw 2.2vw 0px",
      backgroundColor: colors.white,
      borderRadius: "50%",
      textAlign: "center",
      padding: "25%",
      boxSizing: "border-box",
      transition: "0.2s",
    },
    dotTitle: {
      display: "inline-block",
      fontFamily: "FbReforma-Medium, HelveticaNeue-Bold",
      fontWeight:`${i18n.language==='ar'?`600`:``}`,
      /* font-size: 140%; */
      color: colors.darkBlue,
      padding: "5% 0",
      // whiteSpace: "nowrap",
      lineHeight: "160%",
      fontSize: "0.75rem",
    },
    dotEmpty: {
      position: "absolute",
      width: "10rem",
      /* height: 10rem */
      background: colors.koromiko,
      borderRadius: "50%",
      aspectRatio: "1 / 1",
    },
  
    dotEmpty1: {
      top: "50%",
      width: "29.2%",
      [dirLeftRight]: 0,
      transform: "translate(-70%,-50%)",
      background: colors.pattensBlue,
    },
  
    dotEmpty2: {
      right: 0,
      top: "50%",
      width: "8.8%",
      transform: "translate(70%,-50%)",
    },
    dotEmpty3: {
      [dirLeftRight]: "65.5%",
  
      bottom: 0,
      width: "6.5%",
      transform: "translateY(40%)",
      background: colors.pattensBlue,
    },
    dotEmpty4: {
      top: "33.1%",
      [dirLeftRight]: "23.75%",
      width: "1.89%",
      background: colors.pattensBlue,
    },
    dotEmpty5: {
      top: "60.3%",
      [dirLeftRight]: "42.34%",
      width: "3.5%",
    },
    dotEmpty6: {
      top: "57.9%",
      [dirLeftRight]: "30.8%",
      width: "8.5%",
      background: colors.pattensBlue,
    },
    dotEmpty7: {
      top: "16.8%",
      [dirLeftRight]: "72.4%",
      width: "11.2%",
      background: colors.pattensBlue,
    },
    dot1: {
      top: "51.8%",
      [dirLeftRight]: "60.5%",
      width: "14%",
      // transform: "translateX(-50%)"
    },
    dot2: {
      top: "21.5%",
      [dirLeftRight]: "64%",
      width: "17.9%",
    },
    dot3: {
      top: "18%",
      [dirLeftRight]: "39.2%",
      width: "11.62%",
    },
    dot4: {
      top: "56.7%",
      [dirLeftRight]: "33.2%",
      width: "9%",
    },
    dot5: {
      top: "11%",
      [dirLeftRight]: "12.3%",
      width: "7.3%",
    },
    dot6: {
      top: "31.3%",
      [dirLeftRight]: "10.5%",
      width: "6.1%",
      // transform: "translateY(35%)"
    },
    dot7: {
      top: "36.5%",
      [dirLeftRight]: "41%",
      width: "4.75%",
    },
  };
  
  return (
    <a
      style={{
        ...styles.floatBubble,
        ...styles[`dot${index + 1}`],
        width: width + "%",
        // transform:`scale(${scale})`
      }}
      
      href={`https://easy.co.il/list/${tagName}?lat=${location.latitude}&lng=${location.lontitude}&addressname=${stationDetails.stationName}`}
      title={tagName}
      target={"_blank"}
    >
      <div
        className="column centering dot"
        style={{
          ...styles.dot,

          boxShadow: `rgb(0 0 0 / 16%) 0px 4.5px 44px 0`,
        }}
      >
        <img src={icon} style={{ width: "100%",height:`auto` }} alt={``} />
        <div style={{ ...styles.dotTitle}}>
        {t(title)}
        </div>
      </div>
    </a>
  );
};


export default CircleInfoMobile;
