import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { IDatePickerForm } from "../../../Interfaces_tenders/IForm";
import colors from "../../../../../Assets/Colors/Colors.json";
import { FormatDateYYYYMMDD } from "../../../../../Services/DateService";
import { StyleSheet } from "../../../Interfaces_tenders/IStyleSheet";
import DatePicker from "../../DatePicker/DatePicker";

import useDeviceMode from "../../../../../Utils/useWindowDimensions";



import { StyleMode } from "../../../../../Utils/consts";
import Images from "../../../../../Data/Images/Images";
import fonts from "../../../../../Assets/Fonts/Fonts.json";
import { isIOS } from "react-device-detect";

const DatePickerForm: React.FC<IDatePickerForm> = (props) => {
  const {
    icon,
    placeholder,
    date,
    onDateSelected,
    minDate,
    maxDate,
    customStyles,
    showPlaceHolder,
    step,
  } = props;

  const [_isDateVisible, _setIsDateVisible] = useState(false);
  const [_label, _setLabel] = useState(FormatDateYYYYMMDD(date));
  const deviceMode = useDeviceMode();
  const datePickerRef = useRef<HTMLInputElement>(null);
  const isIos =
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
    deviceMode === StyleMode.mobile;

    const [_isThinDevice, _setIsThinDevice] = useState(false);

  const handleDatePickerSelect = (date: Date) => {
    _setIsDateVisible(false);
    _setLabel(FormatDateYYYYMMDD(date));
    onDateSelected(date);
  };

  useEffect(() => {
   
    const element = document.getElementsByClassName("react-datepicker__month-container")[0];
    const htmlEl = element as HTMLElement
    

    if(htmlEl&&window.innerWidth < 320){
      htmlEl.style.fontSize = ".6rem";
    }

  }, [window]);

  useEffect(() => {
    const handleClickOutsideDatepicker = (event: any) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        _setIsDateVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideDatepicker);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideDatepicker);
    };
  }, [datePickerRef]);

  const handleDateClear = () => {
    _setIsDateVisible(false);
  };


  

  return (
    <div
      tabIndex={0}
      style={{
        ...styles.container,
        width: "11.6875rem",
        border: `0.0625rem solid ${
          _isDateVisible ? colors.darkBlue : colors.pastelGray
        }`,
        ...customStyles,
      }}
    >
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
        onClick={() => {
          _setIsDateVisible(!_isDateVisible);
        }}
      ></div>
      <img
      src={icon && icon._url}
        style={{
          ...styles.icon,
         scale:deviceMode === StyleMode.mobile?"0.7":"unset",
        }}
      ></img>
      <div
        style={{
          ...styles.value,
          marginInlineStart: "1rem",
        }}
      >
        {!showPlaceHolder ? _label.split("-").reverse().join(".") : placeholder}
      </div>
      {deviceMode === StyleMode.mobile && (
        <img src={Images.arrow} style={{ ...styles.arrowDownIcon, marginInlineEnd: "1rem" }}></img>
      )}
      {_isDateVisible && deviceMode !== StyleMode.mobile && (
        <div style={styles.datePickerContainer} ref={datePickerRef}>
          <DatePicker
            onChange={handleDatePickerSelect}
            selectedDate={_label ? _label : FormatDateYYYYMMDD(new Date())}
            minDate={minDate && minDate}
            onClear={handleDateClear}
            maxDate={maxDate && maxDate}
          />
        </div>
      )}
      {_isDateVisible && deviceMode === StyleMode.mobile && (
        <div
          style={{
            ...styles.datePickerContainerMobile,
            top: isIos
              ? step &&
                (step === "singlesupplier" ||
                  step === "exemptionsnotices" ||
                  step === "auctionresults")
                ? "-10rem"
                : "-17rem"
              : step &&
                (step === "singlesupplier" ||
                  step === "exemptionsnotices" ||
                  step === "auctionresults")
              ? "-10rem"
              : "unset",
          }}
          ref={datePickerRef}
        >
          <DatePicker
            onChange={handleDatePickerSelect}
            selectedDate={_label ? _label : FormatDateYYYYMMDD(new Date())}
            minDate={minDate && minDate}
            onClear={handleDateClear}
            maxDate={maxDate && maxDate}
          />
        </div>
      )}
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "18.6rem",
    width: "100%",
    height: "2.5rem",
    borderRadius: "1.25rem",
    gap: ".3rem",
    padding: "0 .5rem",
    position: "relative",
    cursor: "pointer",
  },
  value: {
    width: "80%",
    fontSize: "1.125rem",
    color: colors.pasterlGrayDark,
    letterSpacing: "0.043125rem",
    fontFamily: fonts.FbReformaRegular,
  },
  icon: {
    backgroundRepeat: "no-repeat",
  
  },
  datePickerContainer: {
    position: "absolute",
    backgroundColor: colors.whiteTransparent,
    zIndex: "1",
    marginInlineStart: "10rem",
    marginInlineEnd: "10rem",
    top: "3rem",
    border: `.1rem solid ${colors.whiteSmoke}`,
    borderRadius: ".7rem",
  },

  datePickerContainerMobile: {
    position: "absolute",
    backgroundColor: colors.white,
    zIndex: "999",
    //marginInlineStart: "10rem",
    //marginInlineEnd: "10rem",
    //top: "3rem",
    border: `.1rem solid ${colors.whiteSmoke}`,
    borderRadius: ".7rem",
  },
  arrowDownIcon: {
    backgroundRepeat: "no-repeat",

  },
};

export default DatePickerForm;
