import { t } from "i18next";
import { useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import colors from "../../../Assets/Colors/Colors.json";
import { ICustomInput } from "../../../Interfaces/IForms";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";

const CustomAttachedFiles: React.FC<ICustomInput> = (props) => {
  const { label, onChange, errors, touched } = props;
  const boolTouched = touched === "true" ? true : false;

  const [_curFiles, _setCurFiles] = useState<File[] | null>([]);

  const handleAttachedFileUpdateList = (e: FileList | null) => {
    const filesArray = e && Object.values(e);

    if (filesArray !== null && _curFiles !== null) {
      _setCurFiles([..._curFiles, ...filesArray]);
      onChange([..._curFiles, ...filesArray]);
    }
  };

  const handleAttachedFileRemoveFromList = (fileIndex: number) => {
    const updatedArr =
      _curFiles && _curFiles.filter((file, index) => index !== fileIndex);
    _setCurFiles(updatedArr);
    onChange(updatedArr);
  };

  return (
    <div style={styles.inputContainer}>
      <label style={styles.label}>{label}</label>
      <label style={styles.uploadBtn}>
        <div>{t("uploadFile")}</div>
        <input
          multiple
          style={styles.input}
          type="file"
          onChange={(e) => {
            handleAttachedFileUpdateList(e.target.files);
          }}
          className={
            errors && errors && boolTouched && boolTouched ? "input-error" : ""
          }
        />
      </label>
      {errors && errors && boolTouched && boolTouched && (
        <p className="error">{errors && errors}</p>
      )}
      <ul id="fileList" style={styles.fileList}>
        {_curFiles?.map((file, index) => (
          <li key={index}>
            <div style={styles.fileListItemContainer}>
              <span>{file.name}</span>
              <span
                style={styles.iconStyle}
                onClick={() => handleAttachedFileRemoveFromList(index)}
              >
                <FaTrashAlt />
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

const styles: StyleSheet = {
  label: {
    fontSize: "1rem",
    color: colors.black,
    fontWeight: "bold",
    display: "flex",
    margin: "1rem 0 0.2rem",
  },
  input: {
    width: "100%",
    padding: "0.65rem 0.5rem",
    fontSize: "1rem",
    color: colors.black,
    border: "1px solid",
    borderColor: colors.gray,
    borderRadius: "10px",
    display: "none",
  },
  inputContainer: {
    flex: "45%",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  uploadBtn: {
    display: "flex",
    borderRadius: "3.75rem",
    backgroundColor: "rgb(249, 186, 77)",
    border: "0px",
    fontSize: "1rem",
    padding: "1rem",
    fontFamily: "OpenSans-Bold, HelveticaNeue-Bold",
    width: "16rem",
    cursor: "pointer",
    color: "rgb(0, 69, 138)",
    justifyContent: "center",
    alignItems: "center",
  },
  fileListItemContainer: {
    display: "flex",
    gap: "1rem",
  },
  iconStyle: {
    display: "flex",
    alignItems: "center",
  },
};

export default CustomAttachedFiles;
