import colors from "../../Assets/Colors/Colors.json";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { IBaseElement } from "../../Interfaces/IBaseElement";
import Title from "./Title";
import { replaceRichtextBoldWithFont } from "../../Utils/utils";
import { IsMobile } from "../../Services/DeviceService";

const BaseElement: React.FC<IBaseElement> = (props) => {
  const {
    titleAndDescription,
    image,
    imageAlt,
    link,
    containerStyle,
    contentStyle,
    headerStyle,
    linkStyle,
    textAria,
    anchor,
  } = props;

  const navigate = useNavigate();

  useEffect(() => {}, []);

  const redirectToPage = (page?: string) => {
    page && navigate(`?page=${page}`);
  };

  const addListStyle = (text: string) => {
    return text.replaceAll("<li", `<li style="padding-inline-start: 1rem"`);
  };

  return (
    <div style={containerStyle} anchor-id={titleAndDescription?.anchor}>
      {image && image._url !== "" && (
        <img
          src={image._url}
          width={"100%"}
          height={"100%"}
          style={{
            maxWidth: "15rem",
            maxHeight: "15rem",
            backgroundRepeat: "no-repeat",
          }}
          alt={imageAlt ? imageAlt : ``}
        />
      )}
      {titleAndDescription && (
        <div style={{ ...headerStyle }}>
          <Title text={titleAndDescription.title} />
        </div>
      )}
      {titleAndDescription && (
        <div style={{ ...contentStyle }}>
          <label
            dangerouslySetInnerHTML={{
              __html: addListStyle(
                replaceRichtextBoldWithFont(titleAndDescription.description)
              ),
            }}
            style={{
              ...contentStyle,
            }}
          ></label>
        </div>
      )}
      {link && link.redirectTo !== "" && (
        <div style={{ ...linkStyle }} anchor-id={link.anchor}>
          <span
            onClick={() =>
              link.redirectTo.includes(`http://`) ||
              link.redirectTo.includes(`https://`)
                ? window.open(`${link.redirectTo}`, "_blank")
                : redirectToPage(link?.redirectTo)
            }
            role={"link"}
            tabIndex={!IsMobile() ? 0 : -1}
            aria-label={textAria || link.text}
            style={{
              ...linkStyle,
              color: colors.oldLavender,
              cursor: "pointer",
            }}
          >
            {link?.text && (
              <div style={{ display: "flex" }}>
                <span style={{ cursor: "pointer" }}></span>
                <span aria-hidden={"true"}>
                  {link.text} {`>`}
                </span>
              </div>
            )}
            {link?.image && (
              <img
                src={link.image._url}
                style={{
                  color: link.image.color ? link.image.color : colors.black,
                }}
                width={`auto`}
                height={`auto`}
                alt={``}
              />
            )}
          </span>
        </div>
      )}
    </div>
  );
};

export default BaseElement;
