import React, { useEffect, useState } from "react";
import { IUpdatesContent } from "../../Interfaces/IUmbraco";
import UpdatesTicker from "./UpdatesTicker";
import useDeviceMode from "../../Utils/useWindowDimensions";
import { StyleMode, UpdatesTypes } from "../../Utils/consts";
import { useNavigate } from "react-router-dom";
import { GetRegularAndPermanentUpdates, GetUpdatesByType } from "../../Utils/updates";
import UpdatesTickerMobile from "./Mobile/UpdatesTickerMobile";
import Config from "../../Configuration/server";

const TickerMain: React.FC = () => {
  const [_updates, _setUpdates] = useState<IUpdatesContent[]>([]);
  const [_currentUpdateIndex, _setCurrentUpdateIndex] = useState(0);
  const deviceMode = useDeviceMode();
  const navigate = useNavigate();

  useEffect(() => {
    if (_updates.length <= 0) {
      const updatesByType = GetRegularAndPermanentUpdates();
      _setUpdates(updatesByType.updates);

      // GetUpdatesByType(UpdatesTypes.regular).then((res) =>
      // );
    }
    return () => _setUpdates([]);
  }, []);

  const redirectToPage = (page?: string) => {
    page && navigate(`?page=${page}`);
  };

  const nextUpdate = () => {
    _setCurrentUpdateIndex(
      _currentUpdateIndex + 1 < _updates.length ? _currentUpdateIndex + 1 : 0
    );
  };

  const previousUpdate = () => {
    _setCurrentUpdateIndex(
      _currentUpdateIndex > 0 ? _currentUpdateIndex - 1 : _updates.length - 1
    );
  };
  return _updates.length > 0 && Config.IS_TENDERS === "false"  ? (
    deviceMode === StyleMode.desktop ? (
      <UpdatesTicker
        updates={_updates}
        currentUpdateIndex={_currentUpdateIndex}
        onNextButtonClick={nextUpdate}
        onPreviousButtonClick={previousUpdate}
        onRedirectTo={redirectToPage}
      />
    ) : (
      <UpdatesTickerMobile
        updates={_updates}
        currentUpdateIndex={_currentUpdateIndex}
        onNextButtonClick={nextUpdate}
        onPreviousButtonClick={previousUpdate}
        onRedirectTo={redirectToPage}
      />
    )
  ) : (
    <></>
  );
};

export default TickerMain;
