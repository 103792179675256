import { ChangeEvent, useEffect } from "react";
import colors from "../../../Assets/Colors/Colors.json";
import { ICurrentService } from "../../../Interfaces/IForms";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import CustomAttachedFiles from "../CustomFileds/CustomAttachedFiles";
import CustomDatePicker from "../CustomFileds/CustomDatePicker";
import CustomSelect from "../CustomFileds/CustomSelect";
import CustomTextArea from "../CustomFileds/CustomTextArea";
import FormsDB from "../db.json";

const FormAccessibilityOrCleaningIssueScreen: React.FC<ICurrentService> = (
  props
) => {
  const deviceMode = useDeviceMode();
  const { formik } = props;

  useEffect(() => {
  
    document
      .getElementById("FormAccessibilityIssueScreen_container")
      ?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, []);

  return (
    <div style={styles.container} id="FormAccessibilityIssueScreen_container">
      <div style={styles.form}>
        <CustomSelect
          label="בהתייחס ל"
          name="FormAccessibilityOrCleaningIssueScreen.issueLocation"
          placeholder="issueLocation"
          onChange={(issue: ChangeEvent<HTMLSelectElement>) => {
            formik.setFieldValue(
              "FormAccessibilityOrCleaningIssueScreen.issueLocation",
              issue.target.value
            );
          }}
          errors={
            formik.errors.FormAccessibilityOrCleaningIssueScreen?.issueLocation
          }
          touched={
            formik.touched.FormAccessibilityOrCleaningIssueScreen?.issueLocation+''
          }
          selectstyle={{
            flex: "unset",
            width: deviceMode === StyleMode.mobile ? "100%" : "48%",
          }}
        >
          <option value="">בחירה</option>
          {FormsDB.issueLocation.map((location, index) => (
            <option key={index} value={location.issueKey}>
              {location.issueValue}
            </option>
          ))}
        </CustomSelect>

        {formik.values.FormAccessibilityOrCleaningIssueScreen.issueLocation ===
          "wagon" && (
          <div style={styles.stationsSelectContainer}>
            <CustomSelect
              label="תחנת מוצא"
              name="FormAccessibilityOrCleaningIssueScreen.stationDeparture"
              placeholder="stationDeparture"
              onChange={(departure: ChangeEvent<HTMLSelectElement>) => {
                formik.setFieldValue(
                  "FormAccessibilityOrCleaningIssueScreen.stationDeparture",
                  departure.target.value
                );
              }}
              errors={
                formik.errors.FormAccessibilityOrCleaningIssueScreen
                  ?.stationDeparture
              }
              touched={
                formik.touched.FormAccessibilityOrCleaningIssueScreen
                  ?.stationDeparture+''
              }
              selectstyle={{
                flex: "unset",
                width: deviceMode === StyleMode.mobile ? "100%" : "48%",
              }}
            >
              <option value="">יש לבחור את שם התחנה</option>
              {FormsDB.stations.map((station, index) => (
                <option key={index} value={station.stationId}>
                  {station.stationName}
                </option>
              ))}
            </CustomSelect>
            <CustomSelect
              label="תחנת יעד"
              name="FormAccessibilityOrCleaningIssueScreen.stationDestination"
              placeholder="stationDestination"
              onChange={(destination: ChangeEvent<HTMLSelectElement>) => {
                formik.setFieldValue(
                  "FormAccessibilityOrCleaningIssueScreen.stationDestination",
                  destination.target.value
                );
              }}
              errors={
                formik.errors.FormAccessibilityOrCleaningIssueScreen
                  ?.stationDestination
              }
              touched={
                formik.touched.FormAccessibilityOrCleaningIssueScreen
                  ?.stationDestination+''
              }
              selectstyle={{
                flex: "unset",
                width: deviceMode === StyleMode.mobile ? "100%" : "48%",
              }}
            >
              <option value="">יש לבחור את שם התחנה</option>
              {FormsDB.stations.map((station, index) => (
                <option key={index} value={station.stationId}>
                  {station.stationName}
                </option>
              ))}
            </CustomSelect>
          </div>
        )}
        <div style={styles.finalElementsContainer}>
          <CustomDatePicker
            label="תאריך"
            name="FormAccessibilityOrCleaningIssueScreen.eventDateTime"
            type="date"
            value={
              formik.values.FormAccessibilityOrCleaningIssueScreen.eventDateTime
            }
            onBlur={formik.handleBlur}
            onChange={(date: Date) => {
              formik.setFieldValue(
                "FormAccessibilityOrCleaningIssueScreen.eventDateTime",
                date
              );
            }}
            errors={
              formik.errors.FormAccessibilityOrCleaningIssueScreen
                ?.eventDateTime
            }
            touched={
              formik.touched.FormAccessibilityOrCleaningIssueScreen
                ?.eventDateTime+''
            }
            placeholder="aa"
            showTime={true}
          />
          <CustomTextArea
            label="תיאור האירוע"
            name="FormAccessibilityOrCleaningIssueScreen.comments"
            type="textarea"
            value={
              formik.values.FormAccessibilityOrCleaningIssueScreen.comments
            }
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            errors={
              formik.errors.FormAccessibilityOrCleaningIssueScreen?.comments
            }
            touched={
              formik.touched.FormAccessibilityOrCleaningIssueScreen?.comments+''
            }
            //isValid={formik.isValid}
            placeholder="הזן תיאור האירוע"
          />
          <CustomAttachedFiles
            label="צירוף מסמכים
              (ניתן להוסיף יותר מקובץ אחד)"
            name="FormAccessibilityOrCleaningIssueScreen.attachFiles"
            value={
              formik.values.FormAccessibilityOrCleaningIssueScreen.attachFiles
            }
            onBlur={formik.handleBlur}
            onChange={(files: File[] | null) => {
              formik.setFieldValue(
                "FormAccessibilityOrCleaningIssueScreen.attachFiles",
                files
              );
            }}
            errors={formik.errors.attachFiles}
            touched={formik.touched.FormAccessibilityOrCleaningIssueScreen?.attachFiles+''}
            type="file"
            placeholder="צירוף קובץ"
          />
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    margin: "auto",
    paddingBottom: "2rem",
  },
  finalElementsContainer: {
    display: "flex",
    flexWrap: "wrap",
    columnGap: "2rem",
  },
  form: { display: "flex", flexWrap: "wrap", columnGap: "2rem" },
  button: {
    width: "100%",
    padding: "0.65rem 0.5rem",
    fontSize: "1rem",
    color: colors.white,
    border: "2px solid",
    backgroundColor: colors.blue,
    borderRadius: "10px",
    outline: "none",
    margin: "1rem 0",
    fontWeight: "bold",
    cursor: "pointer",
  },
  stationsSelectContainer: {
    display: "flex",
    width: "100%",
    columnGap: "2rem",
    flexWrap: "wrap",
  },
};

export default FormAccessibilityOrCleaningIssueScreen;
