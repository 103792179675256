import colors from "../../Assets/Colors/Colors.json";
import { IFormsTitles } from "../../Interfaces/IForms";
import { StyleSheet } from "../../Interfaces/IStyleSheet";

import React from "react";

const FormTitles: React.FC<IFormsTitles> = (props) => {
  const { header1, header2, header3 } = props;
  return (
    <div style={styles.container}>
      {header1 && <div style={styles.header1}>{header1}</div>}
      {header2 && <div style={styles.header2}>{header2}</div>}
      {header3 && <div style={styles.header3}>{header3}</div>}
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    maxWidth: "60%",
    margin: "auto",
    padding: "2rem",
  },

  header1: {
    paddingTop: "1rem",
    paddingBottom: "2rem",
    fontSize: "2rem",
    color: colors.blue,

    fontWeight: "bold",
    lineHeight: "2rem",
  },
  header2: {
    paddingBottom: "1rem",
    fontSize: "1.2rem",
    color: colors.blue,
    fontWeight: "bold",
    lineHeight: "2rem",
  },
  header3: {
    paddingBottom: ".5rem",
    color: colors.blue,

    fontSize: "1.2rem",
    lineHeight: "2rem",
  },
};

export default FormTitles;
