import moment from "moment";
import QueryString from "qs";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import store from "store2";
import PopMessageCards from "../../Components/PopMessage/PopMessageCards";
import Config from "../../Configuration/server";
import { IPopMessageBackend } from "../../Interfaces/IPopMessages";
import { ITrain } from "../../Interfaces/ITrain";
import { ITravel } from "../../Interfaces/ITravel";
import {
  ScheduleState,
  setDate,
  setHours,
  setMinutes,
} from "../../Redux/Slices/scheduleSlice";
import { GetPopUpMessages, GetPopUpMessagesWithStations } from "../../Services/CommonService";
import {
  FormatDateYYYYMMDD,
  FormatMissingDigit,
  GetDateAsTextFormat,
  GetDurationFormattedAsNumber,
  GetDurationTimeMS,
  IsDateBetween,
  IsToday,
} from "../../Services/DateService";
import { AddTravelToLastTravels } from "../../Services/LastTravelsService";
import { SearchTrainLuz } from "../../Services/RjpaService";
import i18n from "../../Services/i18n";
import { ChangeTitle, StyleMode, TitlePage } from "../../Utils/consts";
import { languagesDictionary } from "../../Utils/getLanguage";
import { GetStationsDictionary } from "../../Utils/helpers";
import useDeviceModeOSType, { GetSystemOS } from "../../Utils/useDeviceOS";
import useDeviceMode from "../../Utils/useWindowDimensions";
import RoutePlanSearchResultsMobile from "./Mobile/RoutePlanSearchResultsMobile";
import RoutePlanSearchResults from "./RoutePlanSearchResults";

const RoutePlanSearchResultsMain: React.FC = () => {
  const deviceMode = useDeviceMode();
  const deviceModeOsType = useDeviceModeOSType();
  const [_travels, _setTravels] = useState<ITravel[]>([]);
  const [_allTravels, _setAllTravels] = useState<ITravel[]>([]);

  const [_noTrains, _setNoTrains] = useState(false);
  const [_isLoading, _setIsLoading] = useState(true);
  const [_multisearch, _setMultisearch] = useState(false);
  const [_selectedTravel, _setSelectedTravel] = useState<ITravel>();
  const [_selectedTrainKey, _setSelectedTrainKey] = useState("");
  const [_dateTitle, _setDateTitle] = useState("");
  const [_noTrainsMessageTitle, _setNoTrainsMessageTitle] = useState<string>();
  const [_popMessages, _setPopMessages] = useState<IPopMessageBackend[]>([]);
  const [_popMessagesVisible, _setPopMessagesVisible] = useState(true);
  const [_noTrainsShareStatus, _setNoTrainsShareStatus] = useState<
    "TimePast" | "TrainNotExists" | "TrainCanceled"
  >();
  const [_isToday, _setIsToday] = useState(false);
  const isNextPreviousDayClickedRef = useRef(false);

  const numOfResultsToShow = useRef(0);
  const clientMessageId = useRef(1);
  const startFromIndex = useRef(0);
  const onFocusIndex = useRef(0);
  const noTrainsLastDate = useRef<Date>();
  const noTrainsNextDate = useRef<Date>();

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const schedule = useSelector(
    (state: any) => state.ScheduleReducer
  ) as ScheduleState;

  const scheduleBeforeChanges = useRef(schedule);
  const isMounted = useRef(false);
  const isSafariOs = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const [_durationInterval, _setDurationInterval] = useState<number[]>([]);

  const getRelevantPopupMessagesFiltered = (
    popupMessages: IPopMessageBackend[]
  ) => {
    const relevantsPopups = popupMessages.filter((message) => {
      const isPopMessageDateRelevant = IsDateBetween(
        FormatDateYYYYMMDD(new Date(message.startDate)),
        FormatDateYYYYMMDD(new Date(message.endDate)),
        FormatDateYYYYMMDD(new Date())
      );
      return isPopMessageDateRelevant;
    });

    return relevantsPopups;
  };

  useEffect(() => {
    isMounted.current = true;
    (async () => {
      const params = new URLSearchParams(window.location.search);
      _setMultisearch(params.get("multisearch") === "true");
      if (
        schedule.fromStation !== undefined &&
        schedule.toStation !== undefined
      ) {
        await getSearchResults("searchTrainLuzForDateTime", schedule);
        let stations = GetStationsDictionary(
          store.session.get(`${Config.BASE_LOCALSTORAGE_NAME}stations`)[
            i18n.language
          ]
        );

        let title = t(`${TitlePage.Resultes}`)
          ?.replace(`$`, `${stations[schedule.fromStation].stationName}`)
          .replace(`#`, `${stations[schedule.toStation].stationName}`)
          .replace(
            `&`,
            `${schedule.date} ${schedule.hours}:${schedule.minutes}`
          );
        const messagesPopup: IPopMessageBackend[] = await GetPopUpMessages(
          "RoutePlanning",
          languagesDictionary[i18n.language]
        );
        let PopUpMessagesFrom: IPopMessageBackend[] =
          await GetPopUpMessagesWithStations(
            "RoutePlanning",
            languagesDictionary[i18n.language],
            schedule.fromStation
          );
        let PopUpMessagesTo: IPopMessageBackend[] =
          await GetPopUpMessagesWithStations(
            "RoutePlanning",
            languagesDictionary[i18n.language],
            schedule.toStation
          );
        _setPopMessages([
          ...getRelevantPopupMessagesFiltered([
            ...messagesPopup.filter(
              (message) => message.title !== "" && message.messageBody !== ""
            ),
          ]),
          ...getRelevantPopupMessagesFiltered([
            ...PopUpMessagesFrom.filter(
              (message) => message.title !== "" && message.messageBody !== ""
            ),
          ]),
          ...getRelevantPopupMessagesFiltered([
            ...PopUpMessagesTo.filter(
              (message) => message.title !== "" && message.messageBody !== ""
            ),
          ]),
        ]);
        if (isMounted.current) {
          ChangeTitle(title);
        }
      }
    })();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const getSearchResults = async (
    dayToSearch:
      | "searchTrainLuzForDateTime"
      | "searchTrainLuzAfterDate"
      | "searchTrainLuzBeforeDate",
    scheduleParams: ScheduleState
  ) => {
    const isDatePast = moment(scheduleParams.date).isBefore(
      moment().format("yyyy-MM-DD")
    );

    if (!isDatePast) {
      const result = await SearchTrainLuz(scheduleParams, dayToSearch);

      //   const result =result1.result

      if (isMounted.current) {
        _setIsLoading(false);
        _setNoTrainsMessageTitle(undefined);
        _setNoTrainsShareStatus(undefined);
      }
      if (result) {
        numOfResultsToShow.current = result.numOfResultsToShow;
        clientMessageId.current = result.clientMessageId;
        startFromIndex.current = result.startFromIndex;
        onFocusIndex.current = result.onFocusIndex;

        let trainToFind: ITrain | undefined = undefined;

        const urlParams = QueryString.parse(
          location.search.split("?")[1]
        ) as unknown as ScheduleState;
        if (urlParams.trainNumber) {
          const trainNumber = parseInt(urlParams.trainNumber as string);
          const travels = [...result.travels];
          for (let travel of travels) {
            for (let train of travel.trains) {
              if (train.trainNumber.toString() === urlParams.trainNumber) {
                trainToFind = { ...train };
              }
            }
          }

          if (trainToFind !== undefined) {
            let timeout = setTimeout(() => {
              const selectedTravelParent = document.getElementById(
                `trainNumber_${trainNumber}`
              );
              if (selectedTravelParent) {
                const selectedTravelId =
                  selectedTravelParent.children.length > 0
                    ? selectedTravelParent.children[0].children[0].id.split(
                        "_"
                      )[2]
                    : undefined;
                if (selectedTravelId) {
                  const selectedTravel = result.travels[+selectedTravelId];
                  handleSelectedTrainCard(selectedTravel);
                }
              }
              clearTimeout(timeout);
            }, 250);
          } else {
            _setNoTrains(true);
            _setNoTrainsMessageTitle("ScheduleSharePastNotExists");
            _setNoTrainsShareStatus("TrainNotExists");
            // return;
          }
        }

        // const isTravelShared = ;

        if (isMounted.current) {
          _setTravels(result.travels);
          _setAllTravels(result.travels);
        }
        scheduleBeforeChanges.current = schedule;

        // Check if No Trains:
        if (result.clientMessageId > 1) {
          if (isMounted.current) {
            _setNoTrains(true);
          }

          if (result.travels.length > 0) {
            //Set the no trains last date, for example (הרכבת האחרונה להיום בשעה 20:51)
            noTrainsLastDate.current = new Date(
              schedule.scheduleType === 1
                ? result.travels[result.onFocusIndex].departureTime
                : result.travels[result.onFocusIndex].arrivalTime
            );

            //Set the no trains Next date, for example (הרכבת הבאה תצא מחר בשעה 05:45)
            noTrainsNextDate.current = new Date(
              schedule.scheduleType === 1
                ? result.travels[
                    result.onFocusIndex !== 0 ? result.onFocusIndex + 1 : 0
                  ].departureTime
                : result.travels[
                    result.onFocusIndex !== 0 ? result.onFocusIndex + 1 : 0
                  ].arrivalTime
            );
          }
        } else {
          if (isMounted.current) {
            _setNoTrains(false);
          }
        }
        if (result.travels.length > 0) {
          // update store with updated time from server:
          const departureArrivalDate = new Date(
            schedule.scheduleType === 1
              ? result.travels[result.onFocusIndex].departureTime
              : result.travels[result.onFocusIndex].arrivalTime
          );
          dispatch(setDate(FormatDateYYYYMMDD(departureArrivalDate)));
          // dispatch(setMinutes(departureArrivalDate.getMinutes().toString()));
          // dispatch(setHours(departureArrivalDate.getHours().toString()));

          // Set the title text: for example (רכבות מתאימות 24.3.2022, בסביבות 10:45)
          if (isMounted.current) {
            if (!isNextPreviousDayClickedRef.current) {
              _setDateTitle(
                `${GetDateAsTextFormat(departureArrivalDate)}, ${t("Around")} ${
                  schedule.hours
                }:${schedule.minutes}`
              );
            } else {
              _setDateTitle(`${GetDateAsTextFormat(departureArrivalDate)}`);
            }

            _setIsToday(IsToday(departureArrivalDate));
          }
          const selectedTravel = result.travels[result.onFocusIndex];
          handleSelectedTrainCard(selectedTravel);
          scrollToSelectedTravelCard();
        }
      }
    } else {
      _setIsLoading(false);
      _setNoTrains(true);
      _setNoTrainsMessageTitle("ScheduleSharePast");
      _setNoTrainsShareStatus("TimePast");
    }
  };

  useEffect(() => {
    const allDuration = _travels.map((train: ITravel) => {
      const durationInMs = GetDurationTimeMS(
        new Date(train.departureTime),
        new Date(train.arrivalTime)
      );

      return GetDurationFormattedAsNumber(durationInMs);
    });

    const travelDuration = Array.from(
      new Set(
        allDuration
          .map(Number)
          .sort()
          .filter((item: number) => item > 0)
      )
    );

    _setDurationInterval(travelDuration);
  }, [_travels]);

  const scrollToSelectedTravelCard = () => {
    // if(!IsMobile()){
    let timeout = setTimeout(() => {
      const selectedTravelCard = document.querySelector("[data-active='true']");
      const travelsContainer = document.getElementById("travelsContainer");

      if (selectedTravelCard) {
        const OS = GetSystemOS();
        const browserZoom =
          Math.round(window.devicePixelRatio * 100) / (OS !== `Mac` ? 1 : 2);
        selectedTravelCard.scrollIntoView({ block: "center" });
        if (browserZoom === 200 || isMobile) {
          (selectedTravelCard as any).scrollIntoViewIfNeeded(true);
          window.scrollTo(0, 0);
        } else {
          selectedTravelCard.scrollIntoView({ block: "center" });
          const mainScroll = document.getElementById("mainScroll")!;
          mainScroll.scrollTo(0, 0);
        }
      }
    }, 250);
    return () => {
      clearTimeout(timeout);
    };
    // }
  };

  // when = nextDay or previousDay
  const getTrainsNextOrPrevious = async (
    searchType: "searchTrainLuzAfterDate" | "searchTrainLuzBeforeDate"
  ) => {
    const newDate = FormatDateYYYYMMDD(moment(schedule.date).add(1).toDate());
    dispatch(setDate(newDate));
    const updatedSchedule = { ...schedule };
    _setIsLoading(true);
    await getSearchResults(searchType, updatedSchedule);
  };

  const handleSelectedTrainCard = (travel: ITravel) => {
    if (isMounted.current) {
      _setSelectedTrainKey(
        `${travel.trains[0].arrivalTime}-${travel.trains[0].departureTime}`
      );
      _setSelectedTravel(travel);
    }
  };

  const handleNoTrainsButtonClick = (buttonType: string) => {
    /* buttonType can be lastTrain or nextTrain  */
    _setNoTrains(false);

    if (buttonType === "lastTrain") {
      let allTravels = [..._travels];
      allTravels = allTravels.slice(0, onFocusIndex.current + 1);
      _setTravels(allTravels);
      scrollToSelectedTravelCard();
    } else if (buttonType === "nextTrain") {
      let travels = [..._travels];

      if (onFocusIndex.current === 0) {
        travels = travels.slice(0, travels.length);
      } else {
        travels = travels.slice(onFocusIndex.current + 1, travels.length);
      }

      // travels = travels.slice(onFocusIndex.current === 0 ? 0 : onFocusIndex.current + 1, travels.length);
      if (noTrainsNextDate.current) {
        dispatch(setDate(FormatDateYYYYMMDD(noTrainsNextDate.current)));
        dispatch(setHours(noTrainsNextDate.current.getHours().toString()));
        dispatch(setMinutes(noTrainsNextDate.current.getMinutes().toString()));

        const formatHours = FormatMissingDigit(
          noTrainsNextDate.current.getHours()
        );
        const formatMinutes = FormatMissingDigit(
          noTrainsNextDate.current.getMinutes()
        );

        _setDateTitle(
          `${GetDateAsTextFormat(noTrainsNextDate.current)}, ${t(
            "Around"
          )} ${formatHours}:${formatMinutes}`
        );
      }
      _setTravels(travels);
      if (travels.length > 0) {
        handleSelectedTrainCard(travels[0]);
      }
    }
  };

  function timeStringToMilliseconds(timeString: string): number {
    const [hours, minutes] = timeString.split(".").map(parseFloat);

    if (
      isNaN(hours) ||
      isNaN(minutes) ||
      Number(String(minutes).slice(0, 2)) >= 60
    ) {
      throw new Error('Invalid time format. Please use "hours.minutes"');
    }

    const totalMilliseconds = hours * 60 * 60 * 1000 + minutes * 60 * 1000;
    return totalMilliseconds;
  }

  const filterClickHandler = (changes: string, duration: string) => {
    const durationLimit = duration && timeStringToMilliseconds(duration);

    let allTravels = [..._allTravels];

    // allTravels.filter(
    //   (x) =>
    //     GetDurationTimeMS(new Date(x.departureTime), new Date(x.arrivalTime)) >
    //     durationLimit
    // );

    // debugger
    _setTravels(
      allTravels
        .filter((travel) => travel.trains.length <= Number(changes) + 1)
        .filter(
          (x) =>
            GetDurationTimeMS(
              new Date(x.departureTime),
              new Date(x.arrivalTime)
            ) <= durationLimit
        )
    );

    // const allDuration =
    //   allTravels &&
    //   allTravels.map((train: ITravel) => {
    //     const durationInMs = GetDurationTimeMS(
    //       new Date(train.departureTime),
    //       new Date(train.arrivalTime)
    //     );

    //     return GetDurationFormattedAsNumber(durationInMs);
    //   });

    // debugger
    // _setTravels(
    //   allTravels.filter(travel=>travel.)
    // )
  };
  const filterClearHandler = () => {
    _setTravels([..._allTravels]);
  };

  const sortTravelHandler = (value: string, sortType: string) => {
    let allTravels = [..._travels];

    if (sortType === "changes") {
      value === "ascending"
        ? _setTravels(
            allTravels.sort((a, b) => a.trains.length - b.trains.length)
          )
        : _setTravels(
            allTravels.sort((b, a) => a.trains.length - b.trains.length)
          );
      document.getElementById("trainsCardsWrapper")?.scrollTo({
        top: 0,
        behavior: "smooth", // Optional: Add smooth scrolling animation
      });
    } else {
      value === "ascending"
        ? _setTravels(
            allTravels.sort((a, b) => {
              const durationA =
                new Date(a.arrivalTime).getTime() -
                new Date(a.departureTime).getTime();
              const durationB =
                new Date(b.arrivalTime).getTime() -
                new Date(b.departureTime).getTime();

              return durationA - durationB;
            })
          )
        : _setTravels(
            allTravels.sort((b, a) => {
              const durationA =
                new Date(a.arrivalTime).getTime() -
                new Date(a.departureTime).getTime();
              const durationB =
                new Date(b.arrivalTime).getTime() -
                new Date(b.departureTime).getTime();

              return durationA - durationB;
            })
          );
      document.getElementById("trainsCardsWrapper")?.scrollTo({
        top: 0,
        behavior: "smooth", // Optional: Add smooth scrolling animation
      });
    }
  };

  // const sortedTravelArray = [..._travels].sort((a, b) => {
  //   const durationA =
  //     new Date(a.arrivalTime).getTime() - new Date(a.departureTime).getTime();
  //   const durationB =
  //     new Date(b.arrivalTime).getTime() - new Date(b.departureTime).getTime();

  //   return durationA - durationB;
  // });
  // const sortTravelHandlerByDuration = (value: string) => {
  //   //let allTravels = [..._travels];

  //   value === "ascending"
  //     ? _setTravels(
  //       _durationInterval.sort((a, b) => a.trains.length - b.trains.length)
  //       )
  //     : _setTravels(
  //       _durationInterval.sort((b, a) => a.trains.length - b.trains.length)
  //       );
  // };

  return (
    <Fragment>
      {_popMessagesVisible && _popMessages.length > 0 && (
        <PopMessageCards
          messages={_popMessages}
          onAcceptReading={() => {
            _setPopMessagesVisible(false);
          }}
        />
      )}
      {deviceMode === StyleMode.desktop ? (
        <RoutePlanSearchResults
          multisearch={_multisearch}
          travels={_travels}
          allTravels={_allTravels}
          isNoTrains={_noTrains}
          isLoading={_isLoading}
          selectedTravel={_selectedTravel}
          noTrainsInfo={{
            clientMessageId: clientMessageId.current,
            lastTrainDate: noTrainsLastDate.current,
            nextTrainDate: noTrainsNextDate.current,
            messageTitle: _noTrainsMessageTitle,
            shareStatus: _noTrainsShareStatus,
            onClick: handleNoTrainsButtonClick,
          }}
          selectedTrainKey={_selectedTrainKey}
          dateTitle={_dateTitle}
          isToday={_isToday}
          onSortClick={(value, type) => {
            sortTravelHandler(value, type);
          }}
          onFilterClear={filterClearHandler}
          onFilterClick={(changes, duration) => {
            filterClickHandler(changes, duration);
          }}
          onClickTravelCard={handleSelectedTrainCard}
          getDay={(searchType) => getTrainsNextOrPrevious(searchType)}
          onClickRoutePlanBarSearch={() => {
            _setIsLoading(true);
            isNextPreviousDayClickedRef.current = false;
            const params = QueryString.stringify(schedule);
            navigate(`?page=routePlanSearchResults&${params}`, {
              replace: true,
            });
            AddTravelToLastTravels(schedule);
            // getSearchResults("currentDay", schedule);
          }}
        />
      ) : (
        <RoutePlanSearchResultsMobile
          travels={_travels}
          allTravels={_allTravels}
          isNoTrains={_noTrains}
          isLoading={_isLoading}
          selectedTravel={_selectedTravel}
          noTrainsInfo={{
            clientMessageId: clientMessageId.current,
            lastTrainDate: noTrainsLastDate.current,
            nextTrainDate: noTrainsNextDate.current,
            messageTitle: _noTrainsMessageTitle,
            shareStatus: _noTrainsShareStatus,
            onClick: handleNoTrainsButtonClick,
          }}
          onFilterClear={filterClearHandler}
          onFilterClick={(changes, duration) => {
            filterClickHandler(changes, duration);
          }}
          selectedTrainKey={_selectedTrainKey}
          onClickTravelCard={handleSelectedTrainCard}
          isToday={_isToday}
          getDay={(when) => getTrainsNextOrPrevious(when)}
        />
      )}
    </Fragment>
  );
};

export default RoutePlanSearchResultsMain;
