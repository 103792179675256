import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { ISearchStationsAutoComplete } from "../../Interfaces/ISearchStationsAutoComplete";
import { IStation } from "../../Interfaces/IStation";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import {
  FilterAutoComplete,
  GetLastStations,
  GetNearbyStations,
  GetSortedStations
} from "../../Services/SearchStationsAutoCompleteService";
import StationsListGrouped from "../StationsList/StationsListGrouped";

const SearchStationsAutoCompleteDropdown: React.FC<
  ISearchStationsAutoComplete
> = (props) => {
  const {
    placeHolder,
    inputStyle,
    inputRef,
    onClick,
    stationsType,
    stationsListStyle,
    searchBoxContainerStyles,
    isSelectedStationId,
    handleVisible,
  } = props;

  const [_inputValue, _setInputValue] = useState("");
  const [_placeHolder, _setPlaceHolder] = useState(placeHolder || "");
  const [_stationsListKey, _setStationsListKey] = useState(0);
  const [_nearbyStations, _setNearbyStations] = useState<IStation[]>([]);
  const [_lastStations, _setLastStations] = useState<IStation[]>([]);
  const [_stations, _setStations] = useState<IStation[]>([]);
  const [_titleValue, _setTitleValue] = useState<string>(t("All-stations"));
  const [_hideLastORNearbyStations, _setHideLastORNearbyStations] =
    useState<boolean>(false);
  const [_isStationsVisible, _setIsStationsVisible] = useState(false);
  const stationsListWrapperRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();
  const checkMouseClickOutsideDropdown = (e: MouseEvent) => {
    if (stationsListWrapperRef.current && e.target instanceof HTMLElement) {
      if (
        stationsListWrapperRef.current.contains(e.target) ||
        document.activeElement === inputRef?.current
      ) {
        _setIsStationsVisible(true);
      } else {
        _setIsStationsVisible(false);
      }
    }
  };

  useEffect(() => {
    const lastStations = GetLastStations();
    _setLastStations(lastStations);
    window.addEventListener("click", (e) => {
      checkMouseClickOutsideDropdown(e);
    });
    return () => {
      window.removeEventListener("click", (e) => {
        checkMouseClickOutsideDropdown(e);
      });
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      const nearbyStations = await GetNearbyStations();
      if (isMounted) {
        _setNearbyStations(nearbyStations);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    handleVisible && handleVisible(_isStationsVisible);
  }, [_isStationsVisible]);

  useEffect(() => {
    // scroll stations list to the top when page is changed (for example from 'origin page' to 'destination page'):
    if (stationsListWrapperRef.current) {
      stationsListWrapperRef.current.scrollTo(0, 0);
    }

    const sortedStations = GetSortedStations();
    _setStations(sortedStations);
  }, [location]);

  const onBlur = (e: React.FocusEvent) => {
    if (stationsListWrapperRef.current) {
      if (stationsListWrapperRef.current.contains(e.relatedTarget)) {
        _setIsStationsVisible(true);
      } else {
        _setIsStationsVisible(false);
      }
    }
  };

  const onFocus = () => {
    _setIsStationsVisible(true);
  };

  const resetAllStationsDefault = () => {
    const stations = GetSortedStations();
    _setStations(stations);
  };
  const styleSearchBoxContainerStyles = searchBoxContainerStyles ? searchBoxContainerStyles : styles.searchBoxContainer
  const stationsListStyles =  stationsListStyle ? stationsListStyle : styles.stationsListWrapper
  
  return (
    <div
      style={{ ...styleSearchBoxContainerStyles }}>
      <input
        ref={inputRef}
        type={"text"}
        style={inputStyle ? inputStyle : styles.inputText}
        onClick={onFocus}
        onChange={(e) => {
          _setInputValue(e.target.value);
          _setStationsListKey(Math.floor(Math.random() * 1000));

          if (e.target.value !== "") {
            const filteredStations = FilterAutoComplete(
              e.currentTarget.value,
              _stations
            );
            _setStations(filteredStations);
          } else {
            resetAllStationsDefault();
          }
          _setHideLastORNearbyStations(e.currentTarget.value !== "");
          _setInputValue(e.target.value);
          if (inputRef && inputRef.current) {
            if (inputRef.current.value.length === 0) {
              _setTitleValue(t("All-stations"));
            } else {
              _setTitleValue("");
            }
          }
        }}
        aria-label={`${ stationsType === "origin"?t("AccessibilityOrigin-station"):t("AccessibilityDestination-station")} ${inputRef?.current?.placeholder}`}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onFocus();
          }
        }}
      />
      {_isStationsVisible && _stations.length > 0 && (
        <div
          ref={stationsListWrapperRef}
          style={{...stationsListStyles,zIndex: 1}}
          className="customScrollbar"
          onFocusCapture={() => {
            _setIsStationsVisible(true);
          }}
          onBlurCapture={(e) => {
            onBlur(e);
          }}
        >
          <StationsListGrouped
            isSelectedStationId={isSelectedStationId}
            key={_stationsListKey}
            title={_titleValue}
            allStations={_stations}
            recentLastStationsTitle={t(
              stationsType === "origin"
                ? "Closest-stations"
                : "Recent-destinations"
            )}
            recentLastStations={
              stationsType === "origin"
                ? _nearbyStations
                : _lastStations.slice(0, 3)
            }
            stationsType={stationsType}
            onClick={(station, stationsType) => {
              onClick(station, stationsType);
              _setInputValue(station.stationName);
              _setStationsListKey(Math.floor(Math.random() * 1000));
              const sortedStations = GetSortedStations();
              // const filteredStations = FilterAutoComplete("", sortedStations);
              _setStations(sortedStations);
              _setTitleValue(t("All-stations"));

              _setIsStationsVisible(false);
            }}
            hideLastORNearbyStations={_hideLastORNearbyStations}
            textValue={
              _inputValue.length > 0
                ? _inputValue
                : inputRef?.current?.placeholder || ""
            }
            inputValue={inputRef?.current?.value || ""}
          />
        </div>
      )}
      {_isStationsVisible && _stations.length === 0 && (
        <div
          ref={stationsListWrapperRef}
          style={{
            ...(stationsListStyle
              ? stationsListStyle
              : styles.stationsListWrapper),
            height: "5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: colors.white,
            fontSize: fontSize.Paragraph,
          }}
          onFocusCapture={() => {
            _setIsStationsVisible(true);
          }}
          onBlurCapture={(e) => {
            onBlur(e);
          }}
        >
          {t("No-stations-message")}
        </div>
      )}
    </div>
  );
};

const styles: StyleSheet = {
  searchBoxContainer: {
    display: "flex",
  },
  stationsListWrapper: {
    height: "38rem",
    width: "100%",
    position: "absolute",
    overflow: "auto",
    top: "5rem",
    right: "0",
  },
  notFoundContainer: {
    minWidth: `100%`,
    textAlign: "center",
  },
  notFoundImage: {
    minHeight: `15vh`,
  },
  notFoundText: {
    display: "block",
    alignSelf: "center",
    textAlign: "center",
    paddingTop: "33px",
    paddingBottom: "33px",
    fontSize: `30px`,
    color: colors.darkBlue,
  },

  inputText: {
    color: colors.nero,
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Text,
  },
};

export default SearchStationsAutoCompleteDropdown;
