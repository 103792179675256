import { t } from "i18next";
import QueryString from "qs";
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import colors from "../../../../../../../Assets/Colors/Colors.json";
import fonts from "../../../../../../../Assets/Fonts/Fonts.json";
import Images from "../../../../../../../Data/Images/Images";
import { FormatDateYYYYMMDD_Dots } from "../../../../../../../Services/DateService";
import { FilesToZipServiceServer } from "../../../../../../../Services/FilesToZipServiceServer";
import i18n from "../../../../../../../Services/i18n";
import { StyleMode } from "../../../../../../../Utils/consts";
import { truncateText } from "../../../../../../../Utils/helpers";
import useDeviceMode from "../../../../../../../Utils/useWindowDimensions";
import { IAuctionsSystemSingleSupplier } from "../../../../../Interfaces_tenders/IAuctionsSystemDetails";
import { StyleSheet } from "../../../../../Interfaces_tenders/IStyleSheet";
import { IServerFile } from "../../../../../Interfaces_tenders/IUmbraco";
import ServerFile from "../../../File/ServerFile";


const SingleSupplierCardMobile: React.FC<IAuctionsSystemSingleSupplier> = (props) => {
  const {
    publishDate,
    supplierName,
    subject,
    decisionDate,
    documentation,
    dynamicTextsDict,
    currentIndex,
    numOfAuctions,
  } = props;

  const dynamicTexts =
    dynamicTextsDict && dynamicTextsDict.length > 0
      ? dynamicTextsDict[0]
      : undefined;
  const [_isModalOpen, _setIsModalOpen] = useState(false);
  const [_currentFileName, _setCurrentFileName] = useState<string>("");
  const deviceMode = useDeviceMode();
  const location = useLocation();

  const opinionFile =
    documentation && documentation.filter((file) => file.docType === 3)[0];

  const messageFile =
    documentation && documentation.filter((file) => file.docType === 2)[0];

  const decisionFile =
    documentation && documentation.filter((file) => file.docType === 4)[0];


  const serverFilesMessage: IServerFile[] =
    documentation &&
    messageFile &&
    documentation
      .filter((file) => file.docType === messageFile.docType)
      .map((file) => {
        return {
          document: file.docName,
          filename: file.docDisplay ? file.docDisplay : t("defaultFileNeme"),
          displayName: file.docDisplay,
        };
      });

  const serverFilesOpinion: IServerFile[] =
    documentation &&
    opinionFile &&
    documentation
      .filter((file) => file.docType === opinionFile.docType)
      .map((file) => {
        return {
          document: file.docName,
          filename: file.docDisplay ? file.docDisplay : t("defaultFileNeme"),
          displayName: file.docDisplay,
        };
      });

  const serverFilesDecision: IServerFile[] =
    documentation &&
    decisionFile &&
    documentation
      .filter((file) => file.docType === decisionFile.docType)
      .map((file) => {
        return {
          document: file.docName,
          filename: file.docDisplay ? file.docDisplay : t("defaultFileNeme"),
          displayName: file.docDisplay,
        };
      });

  const mergeFiles = [];

  serverFilesMessage && mergeFiles.push(serverFilesMessage[0]);
  serverFilesOpinion && mergeFiles.push(serverFilesOpinion[0]);
  serverFilesDecision && mergeFiles.push(serverFilesDecision[0]);

  return (
    <Fragment>
      <div
        style={styles.singleSupplierCardContainer}
        role={"main"}
        tabIndex={0}
        aria-label={
          t("tenderIndexNumber") +
          (currentIndex && currentIndex) +
          t("fromTotal") +
          (numOfAuctions && numOfAuctions)
        }
      >
        <div style={styles.singleSupplierHeader}>
          <div style={styles.subjectName} title={subject}>
            {" "}
            {subject && truncateText(subject, 37)}
          </div>
          <div style={styles.supplierRow}>
          <img src={Images.person}></img>
            <div>
              {dynamicTexts && dynamicTexts["supplier"].text}: {supplierName}
            </div>
          </div>
          <div style={styles.supplierRowFiles}>
            {messageFile && (
              <ServerFile
                filename={
                  messageFile.docDisplay
                    ? messageFile.docDisplay
                    : t("defaultFileNeme")
                }
                document={messageFile.docName}
                onClick={() => {
                
                    const filesToDownload = [
                      ...serverFilesMessage.filter(
                        (file) => file.document !== null
                      ),
                    ];
                    FilesToZipServiceServer(
                      filesToDownload,
                      messageFile.docDisplay
                        ? messageFile.docDisplay
                        : t("defaultFileNeme")
                    );
                  
                }}
                onChangeFileName={(text) => _setCurrentFileName(text)}
                filenameStyles={{ textDecoration: "underline" }}
                displayName={messageFile.docDisplay}
              />
            )}
            {opinionFile && (
              <ServerFile
                filename={
                  opinionFile.docDisplay
                    ? opinionFile.docDisplay
                    : t("defaultFileNeme")
                }
                document={opinionFile.docName}
                onClick={() => {
              
                    const filesToDownload = [
                      ...serverFilesOpinion.filter(
                        (file) => file.document !== null
                      ),
                    ];
                    FilesToZipServiceServer(
                      filesToDownload,
                      opinionFile.docDisplay
                        ? opinionFile.docDisplay
                        : t("defaultFileNeme")
                    );
                  
                }}
                onChangeFileName={(text) => _setCurrentFileName(text)}
                filenameStyles={{ textDecoration: "underline" }}
                displayName={opinionFile.docDisplay}
              />
            )}
          </div>
        </div>
        
        <div style={{...styles.itemsSideLineContainer, fontSize:deviceMode===StyleMode.mobile?"0.9rem":"unset"}}>
          {publishDate &&
            publishDate.toString() !== "0001-01-01T00:00:00" &&
            publishDate.toString() !== "1900-01-01T00:00:00" &&
            publishDate.toString() !== "8900-12-31T00:00:00" && (
              <div style={{
                ...styles.itemSideLine,
                borderRight:
                  i18n.dir() === "rtl" ? `.1rem solid ${colors.black}` : "",
                borderLeft:
                  i18n.dir() === "ltr" ? `.1rem solid ${colors.black}` : "",
              }}>
                <div style={styles.dateTitle}>
                  {dynamicTexts && dynamicTexts["publishDate"].text}
                </div>
                <div style={styles.dateFormat}>
                  {FormatDateYYYYMMDD_Dots(new Date(publishDate))}
                </div>
              </div>
            )}
          {decisionDate &&
          decisionDate.toString() !== "0001-01-01T00:00:00" &&
          decisionDate.toString() !== "1900-01-01T00:00:00" &&
          decisionDate.toString() !== "8900-12-31T00:00:00" ? (
            <div style={{
              ...styles.itemSideLine,
              borderRight:
                i18n.dir() === "rtl" ? `.1rem solid ${colors.black}` : "",
              borderLeft:
                i18n.dir() === "ltr" ? `.1rem solid ${colors.black}` : "",
            }}>
              <div style={styles.dateTitle}>
                {dynamicTexts && dynamicTexts["dateOfDecision"].text}
              </div>
              <div style={styles.dateFormat}>
                {FormatDateYYYYMMDD_Dots(new Date(decisionDate))}
              </div>
            </div>
          ) : (
            <div></div>
          )}
          {decisionFile ? (
            <div style={{
              ...styles.itemSideLine,
              borderRight:
                i18n.dir() === "rtl" ? `.1rem solid ${colors.black}` : "",
              borderLeft:
                i18n.dir() === "ltr" ? `.1rem solid ${colors.black}` : "",
            }}>
              <Fragment>
                <div style={styles.dateTitle}>
                  {dynamicTexts && dynamicTexts["decision"].text}
                </div>
                <ServerFile
                  filename={
                    decisionFile.docDisplay
                      ? decisionFile.docDisplay
                      : t("defaultFileNeme")
                  }
                  document={decisionFile.docName}
                  onClick={() => {
                  
                      const filesToDownload = [
                        ...serverFilesDecision.filter(
                          (file) => file.document !== null
                        ),
                      ];
                      FilesToZipServiceServer(
                        filesToDownload,
                        decisionFile.docDisplay
                          ? decisionFile.docDisplay
                          : t("defaultFileNeme")
                      );
                    
                  }}
                  onChangeFileName={(text) => _setCurrentFileName(text)}
                  filenameStyles={{ textDecoration: "underline" }}
                  displayName={decisionFile.docDisplay}
                />
              </Fragment>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>

    </Fragment>
  );
};

const styles: StyleSheet = {
  singleSupplierCardContainer: {
    display: "flex",
    flexDirection: "column",
    height: "20rem",
    maxWidth: "40.625rem",
    width: "80%",
   // minWidth: "25rem",
    gap: ".5rem",
    boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 15px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: "0.625rem",
    position: "relative",
  },
  singleSupplierHeader: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "1.45rem",
    paddingInline:"1.45rem",
    gap: "1rem",
    flex: 1,
  },
  subjectName: {
    color: colors.darkBlue,
    fontSize: "1.6rem",
    letterSpacing: "0.039375rem",
    fontFamily: fonts.FbReformaMedium,
    maxWidth: "95%",
    lineHeight: "2rem",
    height: "4rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  supplierRowFiles: {
    display: "flex",
    gap: "0.5rem",
    flexDirection:"column"
 
  },
  supplierRow: {
    display: "flex",
    gap: "0.5rem",
 
  },
  icon: {
    backgroundRepeat: "no-repeat",
    width: "1.25rem",
    height: "1.5rem",
  },
  itemsSideLineContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width:"95%",
    alignSelf:"center",
    flex: 1,
  },
  itemSideLine: {
    // borderRight: i18n.dir() === "rtl" ? `.1rem solid ${colors.black}` : "",
    // borderLeft: i18n.dir() === "ltr" ? `.1rem solid ${colors.black}` : "",
    padding: "0 0.5rem",
  },
  dateTitle: {
    fontFamily: fonts.FbReformaRegular,
    color: colors.pasterlGrayDark,
  },
  dateFormat: {
    fontFamily: fonts.FbReformaRegular,
    letterSpacing: "0.038125rem",
    color: colors.black,
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.darkBlue,
    height: "2.75rem",
    borderBottomRightRadius: "0.625rem",
    borderBottomLeftRadius: "0.625rem",
    cursor: "pointer",
    flex: 0.5,
  },
  moreDetails: {
    color: colors.white,
    fontFamily: fonts.FbReformaMedium,
    letterSpacing: "0.029375rem",
    fontSize: "1rem",
  },
};

export default SingleSupplierCardMobile;
