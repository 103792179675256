import React, { useEffect, useRef, useState } from "react";
import ReactDatePicker, {
  registerLocale,
  setDefaultLocale,
} from "./react-datepicker/index";
import "react-datepicker/dist/react-datepicker.css";
import i18n from "../../Services/i18n";
import { he, ru, ar, enUS } from "date-fns/locale";
import { IDatePicker } from "../../Interfaces/IDatePicker";
import useDeviceMode from "../../Utils/useWindowDimensions";
import { StyleMode } from "../../Utils/consts";
import { changeLanguage, t } from "i18next";

const DatePicker: React.FC<IDatePicker> = (props) => {
  const {
    onChange,
    selectedDate,
    minDate,
    maxDate,
    noMinDate,
    showDisabledMonthNavigation,
    showMonthDropdown,
    showYearDropdown,
  } = props;
  const deviceMode = useDeviceMode();
  const [_ariaLabel, _setAriaLabel] = useState("");

  registerLocale("he", he);
  registerLocale("en", enUS);
  registerLocale("ru", ru);
  registerLocale("ar", ar);
  setDefaultLocale("he");

  useEffect(() => {
    const today = document.getElementsByClassName(
      "react-datepicker__day--selected"
    );
    if (today && today.length > 0) {
      _setAriaLabel(today[0].ariaLabel || "");
      today[0].ariaLabel = `${t("DatePickerKeysNavigation")} ${
        today[0].ariaLabel
      }`;
    }
  }, []);

  return (
    <div style={{}}>
      <ReactDatePicker
        selected={new Date(selectedDate)}
        onChange={onChange}
        dateFormat="Pp"
        inline
        locale={i18n.language}
        minDate={!noMinDate ? (minDate ? minDate : new Date()) : minDate}
        maxDate={
          maxDate
            ? maxDate
            : new Date(new Date().setMonth(new Date().getMonth() + 3))
        }
        showMonthDropdown={showMonthDropdown}
        showYearDropdown={showYearDropdown}
        showDisabledMonthNavigation={showDisabledMonthNavigation}
      />
    </div>
  );
};

export default DatePicker;
