import { ITitle } from "../../Interfaces/IBaseElement";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import fontSize from "../../Assets/Fonts/FontsSizes.json";

const Title: React.FC<ITitle> = (props) => {
  const {
    text,
    color,
    titleStyles,
    isUnderline = true,
    isTabIndex = true,
    containerStyles,
    anchor,
  } = props;

  return (
    <div
      style={containerStyles ? containerStyles : styles.container}
      anchor-id={anchor}
    >
      <h2
        style={{
          ...(titleStyles ? titleStyles : styles.title),
          color: color
            ? color
            : titleStyles
            ? titleStyles.color
            : colors.darkBlue,
        }}
      >
        <span style={isUnderline ? styles.titleUnderline : {}}>{text}</span>
      </h2>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    maxWidth: "76rem",
    width: "80%",
  },
  title: {
    display: "flex",
    fontSize: fontSize.UmbracoElementTitle,
    fontFamily: fonts.FbReformaMedium,
    color: colors.darkBlue,
  },
  titleUnderline: {
    borderBottom: `1px solid ${colors.koromiko}`,
    borderBottomWidth: ".2rem",
    padding: ".2rem 0",
    lineHeight: "2rem",
  },
};

export default Title;
