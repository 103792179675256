import colors from "../../../Assets/Colors/Colors.json";
import { IFormBasicDetails } from "../../../Interfaces/IForms";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import Title from "../../UmbracoElements/Title";
import CustomInput from "../CustomFileds/CustomInput";

const FormBasicDetails: React.FC<IFormBasicDetails> = (props) => {
  const { formik } = props;

  
  return (
    <div style={styles.container}>
      <Title text="פרטים אישיים" />

      <div id="my-form" style={styles.form}>
        <CustomInput
          label="שם פרטי"
          name="FormBasicDetails.firstName"
          type="text"
          placeholder="הזן שם משתמש"
          value={formik.values.FormBasicDetails.firstName}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          errors={formik.errors.FormBasicDetails?.firstName}
          touched={formik.touched.FormBasicDetails?.firstName+''}
        />

        <CustomInput
          label="שם משפחה"
          name="FormBasicDetails.lastName"
          type="text"
          placeholder="הזן שם משפחה"
          value={formik.values.FormBasicDetails.lastName}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          errors={formik.errors.FormBasicDetails?.lastName}
          touched={formik.touched.FormBasicDetails?.lastName+''}
        />
        <CustomInput
          label="כתובת"
          name="FormBasicDetails.streetBuildNum"
          type="text"
          placeholder="הזן כתובת"
          value={formik.values.FormBasicDetails.streetBuildNum}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          errors={formik.errors.FormBasicDetails?.streetBuildNum}
          touched={formik.touched.FormBasicDetails?.streetBuildNum+''}
        />
        <CustomInput
          label="ישוב"
          name="FormBasicDetails.city"
          type="text"
          placeholder="הזן ישוב"
          value={formik.values.FormBasicDetails.city}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          errors={formik.errors.FormBasicDetails?.city}
          touched={formik.touched.FormBasicDetails?.city+''}
        />
        <CustomInput
          label="מיקוד"
          name="FormBasicDetails.zipCode"
          type="text"
          placeholder="הזן מיקוד"
          value={formik.values.FormBasicDetails.zipCode}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          errors={formik.errors.FormBasicDetails?.zipCode}
          touched={formik.touched.FormBasicDetails?.zipCode+''}
        />
        <CustomInput
          label="תא דואר"
          name="FormBasicDetails.poBox"
          type="number"
          placeholder="הזן תא תואר"
          value={formik.values.FormBasicDetails.poBox}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          errors={formik.errors.FormBasicDetails?.poBox}
          touched={formik.touched.FormBasicDetails?.poBox+''}
        />
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    maxWidth: "60%",
    margin: "auto",
    paddingBottom: "2rem",
  },
  form: { display: "flex", flexWrap: "wrap", columnGap: "2rem" },
  button: {
    width: "100%",
    padding: "0.65rem 0.5rem",
    fontSize: "1rem",
    color: colors.white,
    border: "2px solid",
    backgroundColor: colors.blue,
    borderRadius: "10px",
    outline: "none",
    margin: "1rem 0",
    fontWeight: "bold",
    cursor: "pointer",
  },
};

export default FormBasicDetails;
