import { t } from "i18next";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IBreadcrumb, IBreadCrumbItem } from "../../Interfaces/IBreadcrumb";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { PageState } from "../../Redux/Slices/pageSlice";
import { HESpecialPages, SpecialPages, StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";
import fonts from "../../Assets/Fonts/Fonts.json";
import { CreateBreadcrumbSchema } from "../../Utils/seo";
import colors from "../../Assets/Colors/Colors.json";
import i18n from "../../Services/i18n";
import { GetLongTextWithDots } from "../../Utils/utils";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../Services/DeviceService";

const Breadcrumb: React.FC<IBreadcrumb> = (props) => {
  const [_breadcrumbItems, _setbreadcrumbItems] = useState<IBreadCrumbItem[]>(
    []
  );
  const { items } = props;
  const deviceMode = useDeviceMode();

  useEffect(() => {
    let Items: IBreadCrumbItem[] = [];
    if (
      items.length > 1 &&
      items[items.length - 1].text === items[items.length - 2].text
    ) {
      for (let i = 0; i < items.length - 1; i++) {
        Items.push(items[i]);
      }
      _setbreadcrumbItems(Items);
      CreateBreadcrumbSchema(Items);
    } else {
      _setbreadcrumbItems(items);
      CreateBreadcrumbSchema(items);
    }
  }, [items]);

  const navigate = useNavigate();

  const currentPage = (
    useSelector((state: any) => state.PageReducer) as PageState
  ).page;

  const handleRedirect = (link: string) => {
    const routeTo = `?page=${link}`;

    if (link !== "") {
      navigate(routeTo);
    }

    if (link === "/") {
      navigate("/");
    }
  };

  const addDotsToLongText = (text: string) => {
    if (i18n.language !== "he") {
      if (text.length > 14) {
        return GetLongTextWithDots(text, 14);
      }
    } else {
      if (text.length > 22) {
        return GetLongTextWithDots(text, 22);
      }
    }
    return text;
  };

  return (
    <div
      style={{
        ...styles.mainContainer,
        
      }}
      role={"region"}
      aria-label={t("YourLocation")}
    >
      <Fragment>
        <div
          style={styles.text}
          onClick={() => {
            handleRedirect("/");
          }}
          tabIndex={!IsMobile() ? 0 : -1}
          role={"link"}
        >
          <a
            style={{
              textDecoration: "underline",
              color: colors.white,
              fontSize: deviceMode === StyleMode.desktop ? "unset" : "0.8rem",
            }}
            title={t("Main-page-breadcrumb")}
          >
            {t("Main-page-breadcrumb")}
          </a>
        </div>
        <div style={{fontSize: deviceMode === StyleMode.desktop ? "unset" : "0.8rem",}}>{">"}</div>
      </Fragment>
      {_breadcrumbItems.map((breadcrumbItem, index) =>
        breadcrumbItem.text !== SpecialPages &&
        breadcrumbItem.text !== HESpecialPages ? (
          <Fragment key={index}>
            <div
              tabIndex={!IsMobile() ? 0 : -1}
              style={{
                ...styles.text,
                fontSize: deviceMode === StyleMode.desktop ? "unset" : "0.8rem",
                textDecoration:
                  index === _breadcrumbItems.length - 1 ||
                  breadcrumbItem.link.length === 0
                    ? "none"
                    : "underline",
                cursor:
                  breadcrumbItem.link === currentPage ||
                  breadcrumbItem.link.length === 0
                    ? ""
                    : "pointer",
              }}
              onClick={
                breadcrumbItem.link.toLowerCase() !== currentPage
                  ? () => {
                      handleRedirect(breadcrumbItem.link);
                    }
                  : undefined
              }
              role={"link"}
              aria-label={
                index === _breadcrumbItems.length - 1
                  ? t("CurrentPage", { page: breadcrumbItem.text })
                  : ""
              }
              title={breadcrumbItem.text}
            >
              {deviceMode !== StyleMode.desktop
                ? addDotsToLongText(breadcrumbItem.text)
                : breadcrumbItem.text}
            </div>
            {index < _breadcrumbItems.length - 1 && <div>{">"} </div>}
          </Fragment>
        ) : (
          ""
        )
      )}
    </div>
  );
};

const styles: StyleSheet = {
  mainContainer: {
    display: "flex",
    gap: ".5em",
  },
  text: {
    cursor: "pointer",
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Text,
  },
};

export default Breadcrumb;
