import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface CookiesSettings {
  isCookiesSettingsOpen: boolean;
  isCookiesModalVisible: boolean;
}

const initialState: CookiesSettings = {
  isCookiesSettingsOpen: false,
  isCookiesModalVisible: false,
};

export const cookiesSettingsSlice = createSlice({
  name: "cookies",
  initialState,
  reducers: {
    setIsCookiesSettingsOpen: (state, action: PayloadAction<boolean>) => {
      state.isCookiesSettingsOpen = action.payload;
    },
    setIsCookiesModalVisible: (state, action: PayloadAction<boolean>) => {
      state.isCookiesModalVisible = action.payload;
    },
  },
});

export const { setIsCookiesSettingsOpen, setIsCookiesModalVisible } =
  cookiesSettingsSlice.actions;

export default cookiesSettingsSlice.reducer;
