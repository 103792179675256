import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import colors from "../Assets/Colors/Colors.json";
import fonts from "../Assets/Fonts/Fonts.json";
import {
  ISearchResultsCategory,
  ISearchResultsPage,
} from "../Interfaces/ISearchResultsItems";
import { StyleSheet } from "../Interfaces/IStyleSheet";
import { ITreeData } from "../Interfaces/ITree";
import { ISearchResultsContent } from "../Interfaces/IUmbraco";
import { UmbracoStoreState } from "../Redux/Slices/umbracoSlice";
import { ChangeTitle, StyleMode } from "../Utils/consts";
import useDeviceMode from "../Utils/useWindowDimensions";
import fontSize from "../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../Services/DeviceService";
import { IAuctionNavigation } from "../Interfaces/IAuctionNavigation";
import Title from "../Components/UmbracoElements/Title";
import { GiCargoCrane, GiRailRoad } from "react-icons/gi";

const AuctionsLandingPage: React.FC<IAuctionNavigation> = (props) => {
  const { mainImage, mainTitle, title, auctionType } = props;

  const [_isLoading, _setIsLoading] = useState(true);
  const [_searchedItems, _setSearchedItems] = useState<ISearchResultsContent>();
  const [_categoriesAndPages, _setCategoriesAndPages] = useState<
    (ISearchResultsCategory | ISearchResultsPage)[]
  >([]);

  let isCardDisplay = false;
  const location = useLocation();
  const navigate = useNavigate();
  const deviceMode = useDeviceMode();
  const titleRef = useRef<HTMLDivElement>(null);

  const isSafariOs = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.tabIndex = 0;
      titleRef.current.focus();
      titleRef.current.style.outline = "none";
      titleRef.current.tabIndex = -1;
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    ChangeTitle(mainTitle);
    (async () => {})();
    return () => {
      isMounted = false;
    };
  }, [location]);

  return (
    <div
      style={{
        ...styles.mainContainer,
      }}
    >
      <div
        ref={titleRef}
        style={{ position: "absolute", zIndex: -1, color: "transparent" }}
        tabIndex={!IsMobile() ? 0 : -1}
      >
        {mainTitle}
      </div>
      <div
        style={{
          ...styles.cover,
          background: `30% 30% / cover url(${mainImage._url})`,
          height:
            deviceMode === StyleMode.desktop
              ? isCardDisplay
                ? "40vh"
                : "20.5vh"
              : "",
          minHeight:
            deviceMode === StyleMode.desktop
              ? isCardDisplay
                ? "17.18rem"
                : "14.25rem"
              : "",
        }}
      >
        <div className="cover-color-blur">
          <div
            style={{
              ...styles.coverElements,
              width: deviceMode === StyleMode.desktop ? "80%" : "75%",
            }}
            className="container"
          >
            <div
              style={{
                ...styles.breadcrumb,
                paddingTop: deviceMode === StyleMode.desktop ? "2rem" : "3rem",
              }}
            ></div>
            <h1
              style={{
                ...styles.title,
                paddingTop: deviceMode === StyleMode.desktop ? "2rem" : "3rem",
              }}
            >
              {mainTitle}
            </h1>
          </div>
        </div>
      </div>
      <div
        style={{
          ...styles.body,
          paddingTop: deviceMode !== StyleMode.desktop ? "2rem" : "",
        }}
      >
        <div style={styles.content}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginInlineStart: "3rem",
              marginInlineEnd: "3rem",
              paddingTop: "2rem",
            }}
          >
            <Title text={title} />
            <div style={styles.cardsContainer}>
              {auctionType.map((auctionType, index) => (
                <div style={styles.card} key={index}>
                  <div style={styles.cardIcon}>
                    {auctionType.typeOfPage === "cargoauctions" ? (
                      <GiCargoCrane size={40} color="rgb(0, 69, 138)" />
                    ) : (
                      <GiRailRoad size={40} color="rgb(0, 69, 138)" />
                    )}
                  </div>
                  <div
                    onClick={() =>
                      navigate(
                        `?page=${auctionType.typeOfPage}&step=openauctions`
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <Title containerStyles={{}} text={auctionType.title} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  mainContainer: {
    alignItems: "center",
    flexDirection: "column",
    zIndex: "10",
    minHeight: "100vh",
  },
  cover: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "20.5vh",
    position: "relative",
    minHeight: "14.25rem",
  },
  title: {
    color: colors.white,
    fontSize: fontSize.PageTitle,
    lineHeight: "3rem",
    letterSpacing: "0.05625rem",
    textShadow: `2px 2px ${colors.black}`,
    fontFamily: fonts.FbReformaMedium,
  },
  breadcrumb: {
    display: "flex",
    color: colors.white,
    fontSize: fontSize.Text,
    letterSpacing: "0.05625rem",
    fontFamily: fonts.FbReformaRegular,
    position: "absolute",
  },
  coverElements: {
    gap: "3rem",
    maxWidth: "76rem",
    margin: "auto",
    lineHeight: "2.5rem",
    padding: "1rem",
  },
  body: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "90rem",
  },
  cardsContainer: {
    display: "flex",
    gap: "3rem",
    justifyContent: "space-around",
    alignItems: "center",
    marginTop: "5rem",
    flexWrap: "wrap",
  },
  card: {
    position: "relative",
    width: "25rem",
    height: "10rem",
    borderRadius: "1rem",
    boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 15px 0 rgba(0, 0, 0, 0.19)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardIcon: {
    position: "absolute",
    top: "-2rem",
    backgroundColor: colors.white,
    boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 15px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: "50%",
    width: "4rem",
    height: "4rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default AuctionsLandingPage;
