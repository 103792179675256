import { ITreeData } from "../Interfaces/ITree"


export let hasChangeLanguage = false

export const languagesDictionary: { [locale: string]: string } = {
  he: "Hebrew",
  en: "English",
  ru: "Russian",
  ar: "Arabic",
};
export const Languages =[`he`,`en`,`ar`,`ru`]

export const ChangeLanguageFlag = (status:boolean)=>{
    hasChangeLanguage=status
}

const LanguageRegex={
        en : /["a-zA-Z"]/,
        he : /["א-ת"]/,
        ar : /[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufbc1]|[\ufbd3-\ufd3f]|[\ufd50-\ufd8f]|[\ufd92-\ufdc7]|[\ufe70-\ufefc]|[\uFDF0-\uFDFD]/,
        ru : /[\u0400-\u04FF]/,
    }

export const GetUrlLanguage = (param:string)=>{
    let currentLanguage = 'he'
    if(param.match(LanguageRegex.en)){currentLanguage ='en'}
    else if(param.match(LanguageRegex.ar)){currentLanguage = 'ar'}
    else if(param.match(LanguageRegex.ru)){currentLanguage = 'ru'}
    return currentLanguage
}

export const GetUrl = (param:string,currentTree:ITreeData)=>{

    let categoryIndex = -1;
    let pageIndex = -1;

    if(currentTree.Tree) {
      for(let category = 0; category < currentTree.Tree.length; category++) {
        const categoryObj = currentTree.Tree[category];

        for(let page = 0; page < categoryObj.pages.length; page++){
          const pageObj = categoryObj.pages[page];
          if(pageObj.toLowerCase() === param.toLowerCase()) {
            pageIndex = page;
            categoryIndex = category;
            break;
          }
        }

        if(pageIndex > -1 || categoryIndex > -1) {
          break;
        }


      }

      return { pageIndex,categoryIndex}
    }
}
