import { ChangeEvent, useEffect } from "react";
import colors from "../../../Assets/Colors/Colors.json";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import CustomAttachedFiles from "../CustomFileds/CustomAttachedFiles";
import CustomDatePicker from "../CustomFileds/CustomDatePicker";
import CustomSelect from "../CustomFileds/CustomSelect";
import CustomTextArea from "../CustomFileds/CustomTextArea";
import FormsDB from "../db.json";

import React from "react";
import { ICurrentService } from "../../../Interfaces/IForms";

const FormCheckEntranceStationScreen: React.FC<ICurrentService> = (props) => {
  const { formik } = props;
  const deviceMode = useDeviceMode();

  useEffect(() => {
    document
      .getElementById("FormCheckEntranceStationScreen_container")
      ?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, []);

  return (
    <div style={styles.container} id="FormCheckEntranceStationScreen_container">
      <div style={styles.form}>
        <CustomSelect
          label="תחנת מוצא"
          name="FormCheckEntranceStationScreen.stationDeparture"
          placeholder="stationDeparture"
          onChange={(departure: ChangeEvent<HTMLSelectElement>) => {
            formik.setFieldValue(
              "FormCheckEntranceStationScreen.stationDeparture",
              departure.target.value
            );
          }}
          errors={
            formik.errors.FormCheckEntranceStationScreen?.stationDeparture
          }
          touched={
            formik.touched.FormCheckEntranceStationScreen?.stationDeparture+''
          }
          selectstyle={{
            flex: "unset",
            width: deviceMode === StyleMode.mobile ? "100%" : "48%",
          }}
        >
          <option value="">יש לבחור את שם התחנה</option>
          {FormsDB.stations.map((station, index) => (
            <option key={index} value={station.stationId}>
              {station.stationName}
            </option>
          ))}
        </CustomSelect>

        <div style={styles.finalElementsContainer}>
          <CustomDatePicker
            label="תאריך"
            name="FormCheckEntranceStationScreen.eventDateTime"
            type="date"
            value={formik.values.FormCheckEntranceStationScreen.eventDateTime}
            onBlur={formik.handleBlur}
            onChange={(date: Date) => {
              formik.setFieldValue(
                "FormCheckEntranceStationScreen.eventDateTime",
                date
              );
            }}
            errors={formik.errors.FormCheckEntranceStationScreen?.eventDateTime}
            touched={
              formik.touched.FormCheckEntranceStationScreen?.eventDateTime+''
            }
            placeholder="aa"
            showTime={true}
          />
          <CustomTextArea
            label="תיאור האירוע"
            name="FormCheckEntranceStationScreen.comments"
            type="textarea"
            value={formik.values.FormCheckEntranceStationScreen.comments}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            errors={formik.errors.FormCheckEntranceStationScreen?.comments}
            touched={formik.touched.FormCheckEntranceStationScreen?.comments+''}
            placeholder="הזן תיאור האירוע"
          />
          <CustomAttachedFiles
            label="צירוף מסמכים
              (ניתן להוסיף יותר מקובץ אחד)"
            name="FormCheckEntranceStationScreen.attachFiles"
            value={formik.values.FormCheckEntranceStationScreen.attachFiles}
            onBlur={formik.handleBlur}
            onChange={(files: File[] | null) => {
              formik.setFieldValue(
                "FormCheckEntranceStationScreen.attachFiles",
                files
              );
            }}
            errors={formik.errors.FormCheckEntranceStationScreen?.attachFiles}
            touched={formik.touched+''}
            type="file"
            placeholder="צירוף קובץ"
          />
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    margin: "auto",
    paddingBottom: "2rem",
  },
  finalElementsContainer: {
    display: "flex",
    flexWrap: "wrap",
    columnGap: "2rem",
  },
  form: { display: "flex", flexWrap: "wrap", columnGap: "2rem" },
  button: {
    width: "100%",
    padding: "0.65rem 0.5rem",
    fontSize: "1rem",
    color: colors.white,
    border: "2px solid",
    backgroundColor: colors.blue,
    borderRadius: "10px",
    outline: "none",
    margin: "1rem 0",
    fontWeight: "bold",
    cursor: "pointer",
  },
};

export default FormCheckEntranceStationScreen;
