import { ICustomInput } from "../../../Interfaces/IForms";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";

const CustomCheckbox: React.FC<ICustomInput> = (props) => {


  const { touched, errors } = props;
  const boolTouched = touched === "true" ? true : false;


  return (
    <>
      <div style={styles.checkbox}>
        <input
          style={styles.checkboxSign}
       
          {...props}
         
          className={
            errors && errors && boolTouched && boolTouched ? "input-error" : ""
          }
        />
        <span>{props.value}</span>
      </div>
      {errors && errors && boolTouched && boolTouched && (
        <p className="error">{errors && errors}</p>
      )}
    </>
  );
};

const styles: StyleSheet = {
  checkbox: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: ".5rem",
  },
};
export default CustomCheckbox;
