import { IGuideLinesAndRatesCard } from "../Interfaces/IGuideLinesAndRates";
import { FormatDateYYYYMMDD, IsDateBetween } from "./DateService";

export interface IFilterGuideLinesAndRates {
  guideLinesAndRates: IGuideLinesAndRatesCard[];
  searchValue: string;
  planningArea: string;
  fromDate: Date;
  toDate: Date;
}

const GetserialNumber = (serialNumber:string)=>{
  // let serial = serialNumber+''
  // if(serial.length>1&&serial[1]&&serial[1]!=''&&serial[1].length===1){
  //   return serialNumber.toString()+`0`
  // }
  // else
  return serialNumber+``
}


export const FilterGuideLinesAndRates = (props: IFilterGuideLinesAndRates) => {
  const { guideLinesAndRates, searchValue, fromDate, toDate, planningArea } =
    props;
  const results = [];
  for (let i = 0; i < guideLinesAndRates.length; i++) {
    const fromDateFormmated = FormatDateYYYYMMDD(fromDate);
    const toDateFormmated = FormatDateYYYYMMDD(toDate);
    const updateDateFormatted = FormatDateYYYYMMDD(
      new Date(guideLinesAndRates[i].updateDate)
    );

    const isUpdateDateBetweenDates = IsDateBetween(
      fromDateFormmated,
      toDateFormmated,
      updateDateFormatted
    );

    if (isUpdateDateBetweenDates) {
      if (
        planningArea === guideLinesAndRates[i].planningAreas ||
        planningArea === ""
      ) {
        if (
          (guideLinesAndRates[i].subject !== null &&
            guideLinesAndRates[i].subject.includes(searchValue)) ||
            GetserialNumber(guideLinesAndRates[i].serialNumber).includes(searchValue)||
          searchValue === ""
        ) {
          results.push(guideLinesAndRates[i]);
        }
      }
    }
  }
  return results;
};
