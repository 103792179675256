import { useRef, useState } from "react";
import colors from "../../../../Assets/Colors/Colors.json";
import Images from "../../../../Data/Images/Images";
import { ILanguageSelection } from "../../Interfaces_tenders/ILanguage";
import { StyleSheet } from "../../Interfaces_tenders/IStyleSheet";
import i18n from "../../../../Services/i18n";
import { TiArrowSortedDown } from "react-icons/ti";

const LanguageSelection: React.FC<ILanguageSelection> = (props) => {
  const { languages, containerStyles } = props;
  const [_isOver, _setIsOver] = useState(false);

  const languagesRef = useRef<HTMLDivElement>(null);

  const [_languageLocaleOver, _setLanguageLocaleOver] = useState("");
  const handleLanguageSelect = (selectedLanguage: string) => {
    i18n.changeLanguage(selectedLanguage);
    _setIsOver(false);
  };
  return (
    <div
      tabIndex={0}
      role={"button"}
      onMouseOver={() => {
        _setIsOver(true);
      }}
      onMouseLeave={() => {
        _setIsOver(false);
      }}
      onFocusCapture={() => {
        _setIsOver(true);
      }}
    >
      <div
        style={
          containerStyles
            ? {
                ...styles.selectedLanguage,
                color: containerStyles?.color,
                alignItems: "end",
              }
            : styles.selectedLanguage
        }
      >
        <div>{i18n.language.toUpperCase()}</div>
        {containerStyles ? (
          <TiArrowSortedDown
            size={"1.35rem"}
            color="white"
            style={{ justifyContent: "center" }}
          />
        ) : (
          <div style={styles.arrowDownIcon}></div>
        )}
      </div>
      {_isOver && (
        <div
          style={!containerStyles ? styles.languages : containerStyles}     
          ref={languagesRef}
        >
          {languages.map((language, index) => (
            <div
              onMouseOver={() => {
                _setLanguageLocaleOver(language.languageLocale);
              }}
              onMouseLeave={() => {
                _setLanguageLocaleOver("");
              }}
              onFocusCapture={() => {
                _setLanguageLocaleOver(language.languageLocale);
              }}
              onBlurCapture={(e) => {
                _setLanguageLocaleOver("");

                _setIsOver(false);

                if (languagesRef.current) {
                  if (languagesRef.current.contains(e.relatedTarget)) {
                    _setIsOver(true);
                  } else {
                    _setIsOver(false);
                  }
                }
              }}
              style={{
                ...styles.languageItem,
                borderBottom:
                  index < languages.length - 1
                    ? `.1rem solid ${colors.platinum}`
                    : "",
                backgroundColor:
                  _languageLocaleOver === language.languageLocale
                    ? colors.pattensBlue
                    : "",
              }}
              role={"button"}
              tabIndex={0}
              aria-label={`change language`}
              onClick={() => handleLanguageSelect(language.languageLocale)}
              key={index}
            >
              <div>{language.languageName}</div>
              <div>
                {i18n.language === language.languageLocale && (
                  <div style={styles.approveIcon}></div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const styles: StyleSheet = {
  languages: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.white,
    lineHeight: "4rem",
    position: "absolute",
    width: "12rem",
    height: `${6 * LanguageSelection.length}`,
    boxShadow: `0 15px 30px 0 rgba(0, 0, 0, 0.15)`,
    borderRadius: "0.6rem",
    marginInlineStart: "-5rem",
    marginInlineEnd: "5rem",
  },
  languageItem: {
    display: "flex",
    padding: ".5rem 1.5rem",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    color: colors.darkBlue,
    fontWeight: "bold",
  },
  approveIcon: {
    backgroundRepeat: "no-repeat",
    width: "1.5em",
    height: "1.5rem",
  },
  arrowDownIcon: {
    backgroundRepeat: "no-repeat",
    width: "1rem",
    height: "1rem",
  },
  selectedLanguage: {
    display: "flex",
    gap: ".5rem",
    color: colors.darkBlue,
    cursor: "pointer",
    padding: "0 2.5rem",
    fontWeight: "bold",
    position: "relative",
  },
};

export default LanguageSelection;
