import { t } from "i18next";
import { useEffect, useState } from "react";
import fonts from "../../Assets/Fonts/Fonts.json";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { ITab } from "../../Interfaces/ITabs";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";
interface ITabsHeader {
  data: ITab[];
  onSelect: (selectedTab: ITab) => void;
}
const TabsHeader: React.FC<ITabsHeader> = (props) => {
  const { onSelect = () => {}, data } = props;
  const [_tabs, _setTabs] = useState<ITab[]>(data);
  const [_isSelected, _setIsSelected] = useState("info");
  const [_currentStation, _setCurrentStation] = useState<any>({});
  const [_Details, _setDetails] = useState<any>({});
  const [_searchObejct, _setSearchObejct] = useState({});
  const deviceMode = useDeviceMode();
  const handleTabClick = (selectedTab: ITab) => {
    let objectSearch = convertUrlToObject(window.location.search);
    objectSearch.step = objectSearch.step ? selectedTab.name : "info";
    onSelect(selectedTab);
  };

  useEffect(() => {
    _setTabs([...data]);
  }, [data]);

  const convertUrlToObject = (url: string) => {
    const searchObejct: any = {};
    decodeURI(url)
      .replace("?", "")
      .split("&")
      .map((search, index) => {
        const sreachParams = search.split("=");
        searchObejct[sreachParams[0].toLowerCase()] = sreachParams[1];
      });

    return searchObejct;
  };

  return (
    <div
      style={{
        boxShadow: "0 2px 15px 0 rgba(0, 0, 0, 0.25)",
        zIndex: 1,
        backgroundColor: "white",
      }}
    >
      <div style={{ maxWidth: "76rem", width: "100%", margin: "auto" }}>
        <div
          className={deviceMode === StyleMode.mobile ? "row-around " : "row"}
        >
          {_tabs.map((item: ITab, index: number) => (
            <div
              key={index}
              className="tab"
              onClick={() => handleTabClick(item)}
              style={{
                ...styles.tab,
                borderBottom: item.isSelected
                  ? `4px solid var(--koromiko)`
                  : "",
                color: item.isSelected ? "var(--darkBlue)" : "#757575",
                padding:
                  deviceMode === StyleMode.mobile
                    ? "1rem 0.5rem"
                    : "1rem 3.5rem",
              }}
            >
              {t(item.name)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  tab: {
    cursor: "pointer",
    fontFamily: fonts.FbReformaMedium,
    fontSize: "1.2rem",
    color: "#757575",
    lineHeight: "1rem",
    padding: "1rem 0.5rem",
  },
};

export default TabsHeader;
