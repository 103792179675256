import { t } from "i18next";
import React, { Fragment, useEffect, useRef, useState } from "react";
import * as ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import store from "store2";
import colors from "../../Assets/Colors/Colors.json";
import Config from "../../Configuration/server";
import Images from "../../Data/Images/Images";
import { ILastTravel, ILastTravelsList } from "../../Interfaces/ILastTravel";
import { IStation } from "../../Interfaces/IStation";
import { TranslationsState } from "../../Redux/Slices/translationsSlice";
import i18n from "../../Services/i18n";
import { StyleMode } from "../../Utils/consts";
import { GetStationsDictionary } from "../../Utils/helpers";
import CustomModal from "../CustomModal";
import LastTravel from "./LastTravel";
import LastTravelMobile from "./Mobile/LastTravelMobile";
interface ITravelsBounds {
  [travelId: string]: DOMRect;
}

const LastTravelsList: React.FC<ILastTravelsList> = (props) => {
  const {
    travels,
    handleFavorite,
    deviceMode,
    fromStationsClosed,
    toStationsClosed,
  } = props;
  const [_travelsBounds, _setTravelsBounds] = useState<ITravelsBounds>({});
  const [_travelClosedMessage, _setTravelClosedMessage] = useState("");
  const [_isTravelClosedModalVisible, _setIsTravelClosedModalVisible] =
    useState(false);
  const prevTravels = useRef<ILastTravel[]>(travels);

  const translations = (
    useSelector((state: any) => state.TranslationsReducer) as TranslationsState
  ).translations;

  const allStations = store.session.get(
    `${Config.BASE_LOCALSTORAGE_NAME}stations`
  );
  const stations: IStation[] = allStations && allStations[i18n.language];
  const stationsDictionary = GetStationsDictionary(stations);

  const handleOnTravelClosed = (
    isFromStationClosed: boolean,
    isToStationClosed: boolean,
    fromStation: IStation,
    toStation: IStation
  ) => {
    if (translations) {
      const translationsT = { ...(translations as any) };
      const currentLanguageTranslations = translationsT[
        i18n.language as keyof "he" | "en" | "ru" | "ar"
      ] as { [key: string]: string };

      if (isFromStationClosed) {
        if (currentLanguageTranslations["blockUpStationMessage"]) {
          _setTravelClosedMessage(
            t(currentLanguageTranslations["blockUpStationMessage"], {
              dep: stationsDictionary[fromStation.stationId].stationName,
            })
          );
        }
      }
      if (isToStationClosed) {
        if (currentLanguageTranslations["blockDownStationMessage"]) {
          _setTravelClosedMessage(
            t(currentLanguageTranslations["blockDownStationMessage"], {
              des: stationsDictionary[toStation.stationId].stationName,
            })
          );
        }
      }
      if (isFromStationClosed && isToStationClosed) {
        if (currentLanguageTranslations["blockAllStationMessage"]) {
          _setTravelClosedMessage(
            t(currentLanguageTranslations["blockAllStationMessage"], {
              dep: stationsDictionary[fromStation.stationId].stationName,
              des: stationsDictionary[toStation.stationId].stationName,
            })
          );
        }
      }
      _setIsTravelClosedModalVisible(true);
    }
  };

  useEffect(() => {
    const bounds: any = {};
    travels.forEach((travel) => {
      // find the ref for this specific name
      const ref = document.getElementById(
        `lastTravel-${travel.fromStation.stationId}-${travel.toStation.stationId}`
      );

      // Look up the DOM node
      const domNode = ReactDOM.findDOMNode(ref);

      if (domNode) {
        // Calculate the bounding box
        const boundingBox = (domNode as HTMLElement).getBoundingClientRect();
        // Store that box in the state, by its key.
        bounds[
          `lastTravel-${travel.fromStation.stationId}-${travel.toStation.stationId}`
        ] = boundingBox;
      }
    });

    _setTravelsBounds(bounds);
    prevTravels.current = travels;
  }, [travels]);

  useEffect(() => {
    // The DOM's new layout has been calculated
    // The screen has not been updated
    prevTravels.current.forEach((travel) => {
      const ref = document.getElementById(
        `lastTravel-${travel.fromStation.stationId}-${travel.toStation.stationId}`
      );

      let domNode = ReactDOM.findDOMNode(ref);
      if (domNode) {
        const newBox = (domNode as HTMLElement).getBoundingClientRect();
        const oldBox =
          _travelsBounds[
          `lastTravel-${travel.fromStation.stationId}-${travel.toStation.stationId}`
          ];

        if (oldBox) {
          const deltaY = oldBox.top - newBox.top;

          if (deltaY !== undefined) {
            requestAnimationFrame(() => {
              // Before the DOM paints, Invert it to its old position
              (
                domNode as HTMLElement
              ).style.transform = `translate3d(0, ${deltaY}px, 0)`;

              // Ensure that it inverts it immediately
              (domNode as HTMLElement).style.transition = "transform 0s";
              requestAnimationFrame(() => {
                if (deltaY < 0) {
                  // moving down in rank
                  // In order to get the animation to play, we'll need to wait for
                  // the 'invert' animation frame to finish, so that its inverted
                  // position has propagated to the DOM.
                  //
                  // Then, we just remove the transform, reverting it to its natural
                  // state, and apply a transition so it does so smoothly.
                  setTimeout(() => {
                    (domNode as HTMLElement).style.transform = "";
                    (domNode as HTMLElement).style.transition =
                      "transform 200ms";
                  }, 100);
                } else {
                  // moving up in rank
                  // (domNode as HTMLElement).style.backgroundColor = "blue";
                  (
                    domNode as HTMLElement
                  ).style.transform = `perspective(0) translate3d(0, ${deltaY}px, 0)`;
                  (domNode as HTMLElement).style.transition = "all 500ms";

                  setTimeout(() => {
                    // moving up in rank
                    (domNode as HTMLElement).style.transform =
                      "perspective(0) translate3d(0, 0, 0)";
                    (domNode as HTMLElement).style.transition =
                      "transform 500ms";
                  }, 100);

                  setTimeout(() => {
                    (
                      domNode as HTMLElement
                    ).style.transform = `translate3d(0, 0, 0)`;
                    (domNode as HTMLElement).style.backgroundColor = "white";
                    (domNode as HTMLElement).style.transition = "all 1500ms";
                  }, 1500);
                }
              });
            });
          }
        }
      }
    });
  }, [travels]);

  return (
    <>
      <ul
        style={{
          paddingInlineStart: "0",
        }}
      >
        {travels.map((lastTravel, index: number) => {
          return deviceMode === StyleMode.desktop ? (
            <LastTravel
              key={index}
              {...lastTravel}
              onClickFavorite={handleFavorite}
              isUnderline={index < travels.length - 1}
              isFromStationClosed={fromStationsClosed.includes(
                lastTravel.fromStation.stationId.toString()
              )}
              isToStationClosed={toStationsClosed.includes(
                lastTravel.toStation.stationId.toString()
              )}
              onTravelClosed={handleOnTravelClosed}
            />
          ) : (
            <Fragment key={index}>
              <LastTravelMobile
                {...lastTravel}
                onClickFavorite={handleFavorite}
                isFromStationClosed={fromStationsClosed.includes(
                  lastTravel.fromStation.stationId.toString()
                )}
                isToStationClosed={toStationsClosed.includes(
                  lastTravel.toStation.stationId.toString()
                )}
                onTravelClosed={handleOnTravelClosed}
              />
              {index < travels.length - 1 && (
                <div
                  style={{
                    borderBottom: `.1rem solid ${colors.pastelGray}`,
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                ></div>
              )}
            </Fragment>
          );
        })}
      </ul>
      <CustomModal visible={_isTravelClosedModalVisible}
        showCloseButton="external"
        icon={Images.info} floatIconStyle={{ img: { width: "1rem" } }} description={_travelClosedMessage} onClose={() => _setIsTravelClosedModalVisible(false)} />
    </>
  );
};
export default LastTravelsList;