import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import Images from "../../Data/Images/Images";
import { ICookiesModal } from "../../Interfaces/ICookiesModal";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { IsMobile } from "../../Services/DeviceService";
import i18n from "../../Services/i18n";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";
import CustomModal from "../CustomModal";

const CookiesModal: React.FC<ICookiesModal> = (props) => {
  const { onClick, cookies, onOpenSettings } = props;
  const navigate = useNavigate();
  const cookiesModalRef = useRef<HTMLImageElement>(null);
  const [_isModalOpen, _setIsModalOpen] = useState(true);
  const deviceMode = useDeviceMode();
  const redirectToPage = (page?: string) => {
    onClick("Skip");
    page && navigate(`?page=${page}`);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      cookiesModalRef.current?.focus();
    }, 1000);
    return () => clearTimeout(timer);
  }, []);
 
  return (<CustomModal visible={true}
    disabledClose={true}
    icon={deviceMode !== StyleMode.desktop && Images.cookie} position={"bottom"}
      responsiveModal={"center"}>
      <div style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around", alignItems: "center",
        gap: "1rem",
      }}>
        <div style={{ display: "flex", gap: "2rem", maxWidth: "76rem" }}>
          {deviceMode === StyleMode.desktop && <img
            src={Images.cookie}
            alt={``}
            style={{ maxHeight: `100%`, maxWidth: `100%` }}
          />}
          <div
            style={styles.information}
            // role={"textbox"}
            tabIndex={!IsMobile() ? 0 : -1}
            ref={cookiesModalRef}
          >
            <div>
              {cookies?.firstText}
              <span
                style={styles.link}
                role={"link"}
                tabIndex={!IsMobile() ? 0 : -1}
                onClick={() => redirectToPage(cookies?.firstLink?.redirectTo)}
              >
                {" "}
                {cookies?.firstLink?.text}
              </span>
            </div>
            {cookies?.secondText && (
              <div>
                {cookies?.secondText}
                {cookies?.secondLink?.text && (
                  <span
                    style={styles.link}
                    role={"link"}
                    tabIndex={!IsMobile() ? 0 : -1}
                    onClick={() => {
                      onOpenSettings && onOpenSettings();
                    }}
                  >
                    {" "}
                    {cookies?.secondLink?.text}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
        <div style={{ display: "flex", gap: "2rem" }}>
          <button
            style={styles.acceptButton}
            onClick={() => onClick("Accept")}
            onKeyPressCapture={() => onClick("Accept")}
          >
            {cookies?.agreeBtn}
          </button>
          <button
            style={styles.refuseButton}
            onClick={() => onClick("Refuse")}
            onKeyPressCapture={() => {
              onClick("Refuse");
            }}
            onKeyDown={(e) => {
              if (e.key === "Tab") {
                if (cookiesModalRef.current) {
                  e.stopPropagation();
                  e.preventDefault();
                  cookiesModalRef.current.focus();
                }
              }
            }}
          >
            {cookies?.refuseBtn}
          </button>
        </div>
      </div>
    </CustomModal>)

};

const styles: StyleSheet = {

  information: {
    fontFamily: fonts.FbReformaRegular,
    fontSize: `${i18n.language === "ru" ? fontSize.Text : fontSize.Paragraph}`,
    lineHeight: "normal",
    letterSpacing: "0.03875rem",
    maxWidth: "60.5rem",
    color: colors.darkBlue,
    outline: "none",
    maxHeight: "15rem",
    overflow: "auto"
  },
  acceptButton: {
    borderRadius: "2rem",
    backgroundColor: colors.darkBlue,
    border: 0,
    color: colors.white,
    fontSize: fontSize.Paragraph,
    minWidth: "10rem",
    paddingBlock: "0.5rem",
    cursor: "pointer",
    fontFamily: fonts.FbReformaMedium,
  },
  refuseButton: {
    borderRadius: "2rem",
    backgroundColor: colors.white,
    border: `.1rem solid`,
    color: colors.darkBlue,
    fontSize: fontSize.Paragraph,
    minWidth: "10rem",
    paddingBlock: "0.5rem",
    cursor: "pointer",
    fontFamily: fonts.FbReformaMedium,
  },
  link: {
    color: colors.oldLavender,
    cursor: "pointer",
    textDecoration: "underline",
  },
};

export default CookiesModal;
