import React from "react";
import { IRouteBarSortVolSlider } from "../../Interfaces/IRoutePlanSearchResults";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import InputRange from "./InputRange";
// Convert a duration in hours and minutes to minutes
export const toMinutes = (duration: number) => {
  const hours = Math.floor(duration);
  const minutes = Math.round((duration - hours) * 100);
  return hours * 60 + minutes;
};

// Convert minutes to a duration in hours and minutes
export const toDuration = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return hours + mins / 100;
};

// Format a duration in hours and minutes as a string
export const formatDuration = (duration: number) => {
  const hours = Math.floor(duration);
  const minutes = Math.round((duration - hours) * 100);
  return `${hours}:${minutes} ${"ש'"}`;
};

// A slider component that can handle an array of durations
const DurationSlider: React.FC<IRouteBarSortVolSlider> = ({
  onChange,
  durations,
  init,
  max,
  min,
  step,
  value,
}) => {
  // Convert the durations to minutes

  // Use state to store the selected value

  // Handle the change event of the input
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event.target.valueAsNumber);
  };
  return (
    <div className="slider-container">
      <InputRange
        value={value}
        min={min}
        max={max}
        step={step} // One minute step
        onChange={handleChange}
        renderLabel={
          <div style={styles.sliderStyle}>
            {formatDuration(toDuration(Number(value)))}
          </div>
        }
      />
    </div>
  );
};

const styles: StyleSheet = {
  sliderContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
  },
  sliderInput: {
    width: "105%",
    margin: "auto",
    marginTop: "2rem",
    cursor: "pointer",
  },
  sliderStyle: {
    border: "0.1rem solid rgb(205, 205, 205)",
    borderRadius: "2rem",
    padding: "0px 1rem",
    minWidth: "4rem",
    textAlign: "center",
    position: "relative",
  },
};

export default DurationSlider;

// Some CSS styles for the slider component
// .slider-container {
//   width: 80%;
//   margin: auto;
// }

// .slider-labels {
//   display: flex;
//   justify-content: space-between;
// }

// .slider-label {
//   position: relative;
//   transform: translateX(-50%);
// }

// .slider-value {
//   text-align: center;
// }
