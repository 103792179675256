import fonts from "../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { IMedia } from "../../../Interfaces/IBaseElement";
import { IArticle } from "../../../Interfaces/IMagazinePage";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import { IsImage, replaceRichtextBoldWithFont } from "../../../Utils/utils";
import Video from "../../Video/Video";
import Title from "../Title";
import CreationDetails from "./Components/CreationDetails";

const Article: React.FC<IArticle & { media?: IMedia }> = (props) => {
  const {
    titleAndDescription,
    author,
    creationDate,
    readingTime,
    containerStyles,
    anchor,
    media
  } = props;
  const deviceMode = useDeviceMode();

  return (
    <div
      style={{ ...styles.container, padding: deviceMode !== StyleMode.desktop ? "2rem" : "", ...containerStyles }}
      role={`article`}
      anchor-id={anchor}
    >
      <div style={styles.header}>
        <Title text={titleAndDescription.title} isTabIndex={false} />
        <CreationDetails author={author} creationDate={creationDate} readingTime={readingTime} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          lineHeight: "1.8rem",
          fontFamily: fonts.FbReformaRegular,
          fontSize: fontSize.Paragraph,
        }}
        dangerouslySetInnerHTML={{
          __html: replaceRichtextBoldWithFont(titleAndDescription.description),
        }}
      />
      {media && (
        <div
          style={{
            width: "100%",
          }}
        >
          {IsImage(media._url) ? (
            <img src={media._url} />
          ) : (
            <Video _url={media._url} header="" autoPlay={false} />
          )}
        </div>
      )}
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem"
  },
  header: {
    display: "flex",
    flexDirection: "column",
  }
};

export default Article;
