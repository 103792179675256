import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { IMemberModal } from "../../Interfaces/IDirectorateMember";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import useDeviceMode from "../../Utils/useWindowDimensions";
import { replaceRichtextBoldWithFont } from "../../Utils/utils";

const InfoMember: React.FC<IMemberModal> = (props) => {
  const {
    fullname,
    role,
    image,
    description,
    onClose,
  } = props;

  const deviceMode = useDeviceMode();

  return (
    <div className={`flex ${deviceMode === "MOBILE" ? "column" : "row"}`}>
      {/* <div className={`flex row`}> */}
        <div className="flex desk" >
          <img src={image._url} className="member-cover " 
              width={`auto`} height={`auto`} alt={``} 
          />   
        </div>
        <div className="modal-content" style={deviceMode === "MOBILE" ? {} : {flex:1} } >
            <div className="wapper-cover mob">
             <img src={image._url} className="member-cover "   
                width={`auto`} height={`auto`} alt={``} 
              />      
          </div>    
        <div className="content" style={{padding: "1rem"}}>
              <div style={styles.header} >
                <div style={styles.title}>{fullname}</div>
                <div style={styles.role}>{role}</div>
                <div style={styles.description} dangerouslySetInnerHTML={{ __html: replaceRichtextBoldWithFont(description) }} ></div>
              </div>
          </div>
        </div>
      </div>
   
  );
};

const styles: StyleSheet = {
  header: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    textAlign:'center'
  },
  title: {
    fontSize: fontSize.H1,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    letterSpacing: "0.065rem",
  },
  role: {
    fontSize: fontSize.UmbracoElementTitle,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    letterSpacing: "0.0425rem",
  },
  description: {
    fontSize: fontSize.FooterItem,
    fontFamily: fonts.FbReformaLight,
    letterSpacing: "0.034375rem",
    maxWidth: "33rem",
  },
};

export default InfoMember;
