import { t } from "i18next";
import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import colors from "../../../../../../../Assets/Colors/Colors.json";
import fonts from "../../../../../../../Assets/Fonts/Fonts.json";
import CustomModal from "../../../../../../../Components/CustomModal";
import Images from "../../../../../../../Data/Images/Images";
import { FormatDateYYYYMMDD_Dots, FormatHoursMinutes } from "../../../../../../../Services/DateService";
import { FilesToZipServiceServer } from "../../../../../../../Services/FilesToZipServiceServer";
import i18n from "../../../../../../../Services/i18n";
import { StyleMode } from "../../../../../../../Utils/consts";
import { truncateText } from "../../../../../../../Utils/helpers";
import useDeviceMode from "../../../../../../../Utils/useWindowDimensions";
import {
  IAuctionsSystemAuctions,
  IAuctionsSystemDomains,
  IAuctionsSystemTypes,
  IAuctionsTenderTypes,
} from "../../../../../Interfaces_tenders/IAuctionsSystemDetails";
import { StyleSheet } from "../../../../../Interfaces_tenders/IStyleSheet";
import { IServerFile } from "../../../../../Interfaces_tenders/IUmbraco";
import FileViewerServer from "../../../../FileViewer/FileViewerServer";
import Tag from "../../../../Tag/Tag";

const AuctionCard: React.FC<IAuctionsSystemAuctions> = (props) => {
  const {
    id,
    tenderName,
    tenderNumber,
    referentMail,
    publishDate,
    filed,
    type,
    clarifyingDate,
    biddingDate,
    documentation,
    dynamicTextsDict,
    auctionSystem,
    currentIndex,
    numOfAuctions,
    page,
  } = props;
  const navigate = useNavigate();
  const [_isModalOpen, _setIsModalOpen] = useState(false);
  const [_currentFileName, _setCurrentFileName] = useState<string>("");

  const _currentTypes = useSelector((state: any) => state.TendersReducer)
    .tenderType as IAuctionsSystemTypes[];

  const dynamicTexts =
    dynamicTextsDict && dynamicTextsDict.length > 0
      ? dynamicTextsDict[0]
      : undefined;
  const deviceMode = useDeviceMode();

  const serverFiles: IServerFile[] =
    documentation &&
    documentation.map((file: any) => {
      return {
        document: file.docName,
        filename: file.docDisplay ? file.docDisplay : t("defaultFileNeme"),
      };
    });


  const handleCardClick = () => {
    // Store.dispatch(setErrorStatus(false));
    navigate(`?page=${page}&auctionId=${id}`);
  };

  return (
    <Fragment>
      {
        <div
          style={styles.auctionCardContainer}
          role={"main"}
          tabIndex={0}
          aria-label={
            t("tenderIndexNumber") +
            (currentIndex && currentIndex) +
            t("fromTotal") +
            (numOfAuctions && numOfAuctions)
          }
        >
          <div style={styles.auctionHeader}>
            {_currentTypes.length > 0 && auctionSystem && (
              <div style={styles.domainNameAndTagWrapper}>
                {
                  <div style={styles.domainOptions}>
                    {filed !== 0
                      ? _currentTypes &&
                      auctionSystem &&
                      auctionSystem.domainOptions.filter(
                        (domain: IAuctionsSystemDomains) =>
                          domain.domainKey ===
                          _currentTypes.filter(
                            (field) => field.id === filed
                          )[0].name
                      )[0].domainValue
                      : _currentTypes && auctionSystem && ""}
                  </div>
                }
                <div>
                  {_currentTypes.length > 0 &&
                    auctionSystem.typeOptions.filter(
                      (curType: IAuctionsTenderTypes) =>
                        _currentTypes.filter((field) => field.id === type)
                          .length > 0 &&
                        curType.typeKey ===
                        _currentTypes.filter((field) => field.id === type)[0]
                          .name
                    ).length > 0 && (
                      <Tag
                        text={
                          filed !== 0
                            ? _currentTypes &&
                            auctionSystem &&
                            auctionSystem.typeOptions.filter(
                              (curType: IAuctionsTenderTypes) =>
                                curType.typeKey ===
                                _currentTypes.filter(
                                  (field) => field.id === type
                                )[0].name
                            )[0].typeValue
                            : _currentTypes && auctionSystem && ""
                        }
                        customStyles={filed === 0}
                      />
                    )}
                </div>
              </div>
            )}
            <div style={styles.auctionName} title={tenderName}>
              {tenderName && truncateText(tenderName, 65)}
            </div>
            {tenderNumber !== undefined && (
              <div style={styles.auctionNumber}>
                {dynamicTexts && dynamicTexts["auctionNumber"].text}{" "}
                {tenderNumber && tenderNumber}
              </div>
            )}
            <div style={styles.referent}>
              <div style={styles.referentRow}>
                <img src={Images.person}></img>
                {referentMail && (
                  <div>
                    {dynamicTexts && dynamicTexts["referent"].text}:{" "}
                    {referentMail.slice(0, 40)}
                  </div>
                )}
              </div>
              <div style={styles.referentRow}>
                {serverFiles && serverFiles.length > 0 && (
                  <Fragment>
                    <img src={Images.fileIcon}></img>
                    <div
                      tabIndex={0}
                      onClick={() => {
                        if (deviceMode !== StyleMode.mobile) {
                          _setIsModalOpen(true);
                        } else {
                          const filesToDownload = [
                            ...serverFiles.filter(
                              (file) => file.document !== null
                            ),
                          ];
                          FilesToZipServiceServer(
                            filesToDownload,
                            tenderNumber
                          );
                        }
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {serverFiles.length}{" "}
                      {dynamicTexts && dynamicTexts["attachedFiles"].text}
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              ...styles.dates,
              paddingLeft:
                i18n.dir() === "ltr" ? `1rem` : "",
            }}
          >
            {publishDate &&
              publishDate.toString() !== "0001-01-01T00:00:00" &&
              publishDate.toString() !== "1900-01-01T00:00:00" &&
              publishDate.toString() !== "8900-12-31T00:00:00" && (
                <div
                  style={{
                    ...styles.date,
                    borderRight:
                      i18n.dir() === "rtl" ? `.1rem solid ${colors.black}` : "",
                    borderLeft:
                      i18n.dir() === "ltr" ? `.1rem solid ${colors.black}` : "",
                  }}
                >
                  <div style={styles.dateTitle}>
                    {dynamicTexts && dynamicTexts["publishDate"].text}
                  </div>
                  <div style={styles.dateFormat}>
                    {FormatDateYYYYMMDD_Dots(new Date(publishDate))}
                  </div>
                  <div style={styles.dateFormat}>
                    {FormatHoursMinutes(publishDate?.toString())}
                  </div>
                </div>
              )}
            {biddingDate &&
              biddingDate.toString() !== "0001-01-01T00:00:00" &&
              biddingDate.toString() !== "1900-01-01T00:00:00" &&
              biddingDate.toString() !== "8900-12-31T00:00:00" && (
                <div
                  style={{
                    ...styles.date,
                    borderRight:
                      i18n.dir() === "rtl" ? `.1rem solid ${colors.black}` : "",
                    borderLeft:
                      i18n.dir() === "ltr" ? `.1rem solid ${colors.black}` : "",
                  }}
                >
                  <div style={styles.dateTitle}>
                    {dynamicTexts &&
                      dynamicTexts["deadlineForSubmissionOfBids"].text}
                  </div>
                  <div style={styles.dateFormat}>
                    {FormatDateYYYYMMDD_Dots(new Date(biddingDate))}
                  </div>
                  <div style={styles.dateFormat}>
                    {FormatHoursMinutes(biddingDate?.toString())}
                  </div>
                </div>
              )}
            {clarifyingDate &&
              clarifyingDate.toString() !== "0001-01-01T00:00:00" &&
              clarifyingDate.toString() !== "1900-01-01T00:00:00" &&
              clarifyingDate.toString() !== "8900-12-31T00:00:00" ? (
              <div
                style={{
                  ...styles.date,
                  borderRight:
                    i18n.dir() === "rtl" ? `.1rem solid ${colors.black}` : "",
                  borderLeft:
                    i18n.dir() === "ltr" ? `.1rem solid ${colors.black}` : "",
                }}
              >
                <Fragment>
                  <div style={styles.dateTitle}>
                    {t("AuctionDeadlineForSubmissionOfQuestions")}
                  </div>
                  <div style={styles.auctionPrice}>
                    {FormatDateYYYYMMDD_Dots(new Date(clarifyingDate))}
                  </div>
                  <div style={styles.dateFormat}>
                    {FormatHoursMinutes(clarifyingDate?.toString())}
                  </div>
                </Fragment>
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <div
            style={styles.button}
            onClick={handleCardClick}
          >
            <div style={styles.moreDetails} tabIndex={0}>
              {dynamicTexts && dynamicTexts["moreDetails"].text}
            </div>
          </div>
        </div>
      }
      <CustomModal visible={_isModalOpen}
        onClose={() => _setIsModalOpen(false)}
        showCloseButton="internal" dirClosebutton="start" typeStyle={"rectangle"}
        innerStyle={{ width: "85%", maxHeight: "100%", padding: 0, maxWidth: "unset", height: "50rem" ,lineHeight: 0,
        borderRadius: "0.8rem",
          overflow:"hidden"}}
        contentStyle={{ padding: 0 }}
      >
        {serverFiles && serverFiles.length > 0 && (
          <FileViewerServer
            files={serverFiles}
            title={`${t("tenderIndexNumber")} ${tenderNumber}`}
            curFileName={_currentFileName}
            key={new Date().toDateString()}
          />
        )}
      </CustomModal>
    </Fragment>
  );
};

const styles: StyleSheet = {
  auctionCardContainer: {
    display: "flex",
    flexDirection: "column",
    height: "24rem",
    maxWidth: "40.625rem",
    width: "80%",
    minWidth: "25rem",
    gap: ".5rem",
    boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 15px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: "0.625rem",
    position: "relative",
    overflow: "hidden",
  },
  auctionHeader: {
    display: "flex",
    flexDirection: "column",
    padding: "1.45rem",
    gap: ".5rem",
    flex: 1,
  },
  auctionName: {
    color: colors.darkBlue,
    fontSize: "1.6rem",
    letterSpacing: "0.039375rem",
    fontFamily: fonts.FbReformaMedium,
    maxWidth: "30rem",
    height: "4rem",
    lineHeight: "2rem",
    overflow: "hidden",

    textOverflow: "ellipsis",
  },
  domainNameAndTagWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  auctionNumber: {
    color: colors.darkBlue,
    fontSize: "1rem",
    letterSpacing: "0.0275rem",
    fontFamily: fonts.FbReformaLight,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  referent: {
    display: "flex",
    gap: "1rem",
  },
  referentRow: {
    display: "flex",
    gap: "0.5rem",
  },
  icon: {
    backgroundRepeat: "no-repeat",
    width: "1.25rem",
    height: "1.5rem",
  },
  dates: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    flex: 1,
  },
  date: {
    // borderRight: i18n.dir() === "rtl" ? `.1rem solid ${colors.black}` : "",
    // borderLeft: i18n.dir() === "ltr" ? `.1rem solid ${colors.black}` : "",
    padding: "0 1rem",
  },
  dateTitle: {
    fontFamily: fonts.FbReformaRegular,
    color: colors.pasterlGrayDark,
  },
  dateFormat: {
    fontFamily: fonts.FbReformaRegular,
    letterSpacing: "0.038125rem",
    color: colors.black,
  },
  auctionPrice: {
    fontFamily: fonts.FbReformaRegular,
    letterSpacing: "0.038125rem",
    color: colors.black,
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.darkBlue,
    height: "2.75rem",
    borderBottomRightRadius: "0.625rem",
    borderBottomLeftRadius: "0.625rem",
    cursor: "pointer",
    flex: 0.5,
  },
  moreDetails: {
    color: colors.white,
    fontFamily: fonts.FbReformaMedium,
    letterSpacing: "0.029375rem",
    fontSize: "1rem",
  },
  domainOptions: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaRegular,
    fontSize: "1rem",
    letterSpacing: "0.025rem",
  }
};

export default AuctionCard;
