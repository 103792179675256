import { t } from "i18next";
import moment, { Moment } from "moment";
import { ScheduleStateBuild } from "../Redux/Slices/scheduleSlice";
import i18n from "./i18n";

interface IGetMinutesByDivider {
  divider: number;
  date: Date;
}
export const GetMinutesByDivider = ({
  date,
  divider,
}: IGetMinutesByDivider) => {
  let minutes = date.getMinutes();
  minutes = Math.floor(minutes / divider) * divider;

  if (minutes < 10) {
    return `0${minutes}`;
  } else if (minutes === 60) {
    return "00";
  } else if (minutes >= 10) {
    return minutes.toString();
  }
};

interface IGetHoursByDivider {
  minutesDivider: number;
  date: Date;
}
export const GetHoursByMinutesDivider = ({
  minutesDivider,
  date,
}: IGetHoursByDivider) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();

  minutes = Math.floor(minutes / minutesDivider) * minutesDivider;
  hours = new Date(date.setHours(hours + minutes / 60)).getHours();

  if (hours < 10) {
    return `0${hours}`;
  } else if (hours >= 10) {
    return hours.toString();
  }
};

export const FormatDateToISOStringWithTimezone = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
  const timezoneOffsetMinutes = date.getTimezoneOffset();
  const timezoneOffsetHours = Math.abs(Math.floor(timezoneOffsetMinutes / 60));
  const timezoneOffsetMinutesRemainder = Math.abs(timezoneOffsetMinutes) % 60;
  const timezoneSign = timezoneOffsetMinutes > 0 ? '-' : '+';

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${timezoneSign}${String(timezoneOffsetHours).padStart(2, '0')}:${String(timezoneOffsetMinutesRemainder).padStart(2, '0')}`;
}


export const GetDurationTimeMS = (date1: Date, date2: Date) => {
  return new Date(date2).getTime() - new Date(date1).getTime();
};

export const GetDurationTimeMSStringType = (date1: string, date2: Date) => {
  const milliseconds = new Date(date1).getTime() - new Date(date2).getTime();
  const minutes = moment.duration(milliseconds).asMinutes();
  return minutes;
};

export const GetDurationFormatted = (milliseconds: number): string => {
  const minutes = milliseconds / 1000 / 60;
  if (minutes < 60) {
    return `${minutes}`;
  } else {
    let h = parseInt("" + minutes / 60);
    let m: string | number = minutes % 60;
    m = m < 10 ? `0${m}` : m;
    return `${h}:${m}`;
  }
};

export const GetDurationFormattedAsNumber = (milliseconds: number): string => {
  const minutes = milliseconds / 1000 / 60;
  if (minutes < 60) {
    return `${minutes}`;
  } else {
    let h = parseInt("" + minutes / 60);
    let m: string | number = minutes % 60;
    m = m < 10 ? `0${m}` : m;
    return `${h}.${m}`;
  }
};

export const IsDateBetween = (
  startDate: string,
  endDate: string,
  date: string
) => {
  return moment(date).isBetween(startDate, endDate, undefined, "[]");
};

export const GetDuration = (d1: Date, d2: Date) => {
  return new Date(d2).getTime() - new Date(d1).getTime();
};

export const SecondsToDurationFormat = (seconds: number) => {
  if (isNaN(seconds)) {
    return "00:00";
  }

  return new Date(seconds * 1000).toISOString().substring(14, 19);
};

export const GetFormatedDurationMinutes = (milliseconds: number) => {
  const minutes = Math.abs(milliseconds / 1000 / 60);
  return minutes;
};

export const GetFormatedDuration = (milliseconds: number): string => {
  //return something like 1:32 or 0:35
  const minutes = milliseconds / 1000 / 60;
  if (minutes < 60) {
    return `${minutes}`;
  } else {
    let h = parseInt("" + minutes / 60);
    let m: string | number = minutes % 60;
    m = m < 10 ? `0${m}` : m;
    return `${h}:${m}`;
  }
};

export const FormatDateBasic = (date: Date) => {
  return moment(date).format();
};

export const FormatDateYYYYMMDD = (date: Date) => {
  return moment(date).format("YYYY-MM-DD");
};

export const FormatDateYYYYMMDDTHHMM = (date: Date) => {
  return moment(date).format("YYYY-MM-DD HH:mm");
};

export const FormatStringYYYYMMDDTHHMM = (date: string) => {
  return moment(date).format("YYYY-MM-DD HH:mm");
};

export const FormatDateYYYYMMDD_Dots = (date?: Date) => {
  if (date) {
    return moment(date).format("DD.MM.YYYY");
  }
  return moment().format("DD.MM.YYYY");
};

export const FormatDateShort = (date: Date) => {
  return moment(date).format("DD/MM");
};

export const GetNextDay = () => {
  return moment().add(1, "days");
};

export const FormatHoursMinutes = (date: string) => {
  return moment(date).format("HH:mm");
};

export const FormatHoursMinutesSeconds = (date: string) => {
  return moment(date).format("HH:mm:ss");
};

export const GetFormatedTime = (date: Date) => {
  return moment(date).format("HH:mm");
};

export const GetFormatedTimeFromString = (date: string) => {
  return moment(date).format("HH:mm");
};

export const GetDateAsTextFormat = (date: Date, short: boolean = false) => {
  const language = i18n.language;
  const relevantTimeFormat = new Intl.RelativeTimeFormat([language], {
    numeric: "auto",
  });

  const toDate = short ? `` : `${t("ToDate")} `;

  if (IsToday(date)) {
    return relevantTimeFormat.format(0, "day");
  } else if (IsTommorow(date)) {
    return relevantTimeFormat.format(1, "day");
  }

  return `${toDate}` + date.toLocaleDateString(language);
};

export const AddSubtractTime = (date: Date, offset: number, unit: string) => {
  //return FormatedTime something like 23:03 or 06:11
  if (unit === "m") {
    //Offset can be minus
    offset = offset * 60 * 1000;
  }
  if (unit === "h") {
    //Offset can be minus
    offset = offset * 60 * 60 * 1000;
  }
  return GetFormatedTime(new Date(date.getTime() + offset));
};

export const FormatMissingDigit = (number: number) => {
  if (number <= 9) {
    return `0${number}`;
  } else {
    return number.toString();
  }
};
interface IFullTime {
  hours: string;
  minutes: string;
  date: string;
}
export const FormatFullDate = (data: ScheduleStateBuild) => {
  const hourFormatted = `${FormatMissingDigit(
    data.hours ? parseInt(data.hours) : 0
  )}:${FormatMissingDigit(data.minutes ? parseInt(data.minutes) : 0)}`;
  const scheduleTypeFormatted = `${t(
    data.scheduleType === 1 ? "Departure" : "Arrival"
  )} ${t("At")}`;
  const fulldate = `${GetDateAsTextFormat(
    new Date(data.date ? data.date : ""),
    true
  )} ${scheduleTypeFormatted} ${hourFormatted}`;
  return fulldate;
};

export const IsToday = (date: Date) => {
  const language = i18n.language;
  const today = moment().toDate().toLocaleDateString([language]);
  return date.toLocaleDateString([language]) === today;
};

export const IsTommorow = (date: Date) => {
  const language = i18n.language;
  const today = moment().add(1, "days").toDate().toLocaleDateString([language]);
  return date.toLocaleDateString([language]) === today;
};

export const calendar = (date: Date) => {
  return moment().calendar();
};
export const calendarString = (curDate: string) => {
  const date = moment(curDate);
  const formattedDate = date.calendar();
  return formattedDate;
};
export const subtractMonthsFromToday = (numMonths: string) => {
  const currentDate = moment();
  const newDate = currentDate.subtract(numMonths, "months").toDate();
  return newDate;
};

export const IsDateBetweenTender = (startDate: Date, endDate: Date, date: Date) => {
  // startDate.setHours(0);
  // startDate.setMinutes(0);
  // endDate.setHours(0);
  // endDate.setMinutes(0);
  // date.setHours(0);
  // date.setMinutes(0);
  return startDate <= date && endDate >= date;
};


export const timeString = (dateTimeString: string) => {
  return moment(dateTimeString).format("HH:mm");
};