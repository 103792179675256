import { IPisTrainCard, IPisTrainCards } from "../../../../Interfaces/IPis";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import i18n from "../../../../Services/i18n";
import PisTrainCardMobile from "./PisTrainCardMobile";
import { useState } from "react";

const PisTrainCardsMobile: React.FC<
  IPisTrainCards & { onCardSelected: (selected: IPisTrainCard) => void }
> = (props) => {
  const { pisTrainCards, onCardSelected } = props;
  const [_selectedCardIndex, _setSelectedCardIndex] = useState(-1);

  return (
    <div style={styles.container}>
      {pisTrainCards.map((pisTrainCard, index) => (
        <PisTrainCardMobile
          key={index}
          {...pisTrainCard}
          onClick={(selected) => {
            _setSelectedCardIndex(index);
            onCardSelected && onCardSelected(selected);
          }}
          isSelected={index === _selectedCardIndex}
          isBottomLineVisible={index < pisTrainCards.length - 1}
        />
      ))}
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    alignItems: "center",
    width: "100%",
  },
};

export default PisTrainCardsMobile;
