import { t } from "i18next";
import Lottie from "lottie-react";
import colors from "../../../../Assets/Colors/Colors.json";
import Loader from "../../../../Assets/Lotties/Loader/Loader.json";
import { ILoading } from "../../Interfaces_tenders/ILoading";
import { StyleSheet } from "../../Interfaces_tenders/IStyleSheet";
import { StyleMode } from "../../../../Utils/consts";
import useDeviceMode from "../../../../Utils/useWindowDimensions";

const Loading: React.FC<ILoading> = (props) => {
  
  const { message, containerStyles } = props;
  const deviceMode = useDeviceMode();

  return (
    <div
      style={{
        ...styles.loadingArea,
        height: deviceMode !== StyleMode.desktop ? "50vh" : "10vh",
        ...containerStyles,
      }}
    >
      <div>
        <Lottie
          animationData={Loader}
          loop={true}
          autoPlay={true}
          style={{ transform: "scale(1)" }}
        />
      </div>
      {message && <div style={styles.message}>{t(message)}</div>}
    </div>
  );
};

const styles: StyleSheet = {
  loadingArea: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "3rem",
    width: "100%",
  },
  message: {
    color: colors.darkBlue,
    fontSize: "1.25rem",
    maxWidth: "15rem",
  },
};

export default Loading;
