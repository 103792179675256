import colors from "../../../Assets/Colors/Colors.json";
import { IArticleWithMedia } from "../../../Interfaces/IMagazinePage";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import ArticleTitleAndText from "./Components/ArticleTitleAndText";
import ImageCover from "./Components/ImageCover";

const MediaArticle: React.FC<IArticleWithMedia> = (props) => {
  const {
    titleAndDescription,
    videoThumbnail,
    media,
  } = props;
  const deviceMode = useDeviceMode();
  const wapper = deviceMode === StyleMode.desktop ? styles.wapper : styles.wapperMobile
  return (<div style={wapper}>
    <ImageCover media={media} videoThumbnail={videoThumbnail} />
    <div style={{
      position: deviceMode === StyleMode.desktop ? "absolute" : "static",
      bottom: 0, width: "100%", boxSizing: "border-box",
      padding: deviceMode !== StyleMode.desktop ? "1rem 2rem" : 0
    }}>
      <ArticleTitleAndText {...props} title={titleAndDescription.title}
        textWapperStyle={deviceMode === StyleMode.desktop ? styles.headerContainer : {}}
        maxWidth={"100%"} tagsStyle={{ paddingInline: deviceMode === StyleMode.desktop ? "1rem" : "0" }} />
    </div>
  </div>
  )
};

const styles: StyleSheet = {
  wapper: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    borderRadius: "0.5rem",
    overflow: "hidden",
  },
  wapperMobile: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "hidden",
    backgroundColor: colors.ghostWhite
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.whiteTransparent,
    minHeight: "6.0625rem",
    gap: "0.5rem",
    width: "100%",
    padding: "1em",
    boxSizing: "border-box",
  }
};

export default MediaArticle;
