import { IGalleryVideo } from "../../../../Interfaces/IGallery";
import useDeviceMode from "../../../../Utils/useWindowDimensions";
import colors from "../../../../Assets/Colors/Colors.json";
import fonts from "../../../../Assets/Fonts/Fonts.json";
import GalleryVideo from "../GalleryItems/GalleryVideo";
import { StyleMode } from "../../../../Utils/consts";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import fontSize from "../../../../Assets/Fonts/FontsSizes.json";

const LinkableGalleryVideo: React.FC<IGalleryVideo> = (props) => {
  const {
    media,
    header,
    contentTypeAlias,
    videoStyles,
    thumbnail,
    isPlayButtonEnabled,
    onClick,
    isThumbnailAsBackground,
  } = props;

  const deviceMode = useDeviceMode();

  return (
    <div style={styles.galleryContainer}>
      {media && (
        <GalleryVideo
          {...props}
          isPlayButtonEnabled={true}
          videoStyles={{
            height: "100%",
          }}
        />
      )}
      <div
        style={{
          ...styles.headerContainer,
          top: "0",
          justifyContent: deviceMode !== StyleMode.desktop ? "center" : "",
        }}
      >
        <div
          style={{
            color: colors.darkBlue,
            fontFamily: fonts.FbReformaMedium,
            fontSize: fontSize.UmbracoElementTitle,
            position: "absolute",
            top: "35%", 
          }}
        >
          {header.text}
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  galleryContainer: {
    position: "relative",
    height: "30rem",
    width: "50rem",

    // maxWidth: "76rem",
    // height: "42rem",
  } as React.CSSProperties,
  currentDisplayed: {
    position: "relative",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "85.5rem",
    height: "48rem",
  } as React.CSSProperties,
  arrows: {
    position: "absolute",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    top: "40%",
  } as React.CSSProperties,
  headerContainer: {
    position: "absolute",
    backgroundColor: colors.whiteTransparent,
    width: "100%",
    minHeight: "5rem",
    maxHeight: "8rem",
    display: "flex",
  } as React.CSSProperties,
  header: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.UmbracoElementTitle,
    position: "absolute",
    top: "35%",
  } as React.CSSProperties,
  galleryBullets: {
    display: "flex",
    position: "absolute",
    gap: "1rem",
    bottom: "15%",
  } as React.CSSProperties,
  galleryItemBullet: {
    width: ".75rem",
    height: ".75rem",
    border: `1px solid ${colors.ghostWhite}`,
    borderRadius: "0.75rem",
    cursor: "pointer",
  } as React.CSSProperties,
};

export default LinkableGalleryVideo;
