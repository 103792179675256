import React, { useEffect, useRef, useState } from "react";
import colors from "../../../Assets/Colors/Colors.json";
import { useNavigate } from "react-router-dom";
import { CheckIsEnable } from "../../../Utils/consts";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { IFooterItem, ISubFooterItem } from "../../../Interfaces/IFooter";
import Title from "../../UmbracoElements/Title";
import Images from "../../../Data/Images/Images";
import i18n from "../../../Services/i18n";
import { ILinkOpenType, ISubMenuItem } from "../../../Interfaces/IMenu";
import fonts from "../../../Assets/Fonts/Fonts.json";
import { t } from "i18next";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../Services/DeviceService";

const FooterItemMobile: React.FC<IFooterItem> = (props) => {
  const {
    label,
    subMenus,
    isEnabled,
    itemsDirection,
    isAccordion,
    openType,
    isSubMenuGappedCenter = false,
    currentIndex,
    totalItems,
  } = props;

  const [_isSubMenus, _setIsSubMenus] = useState<boolean>(false);
  const subMenusContainerRef = useRef<HTMLUListElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (_isSubMenus) {
      if (subMenusContainerRef.current) {
        subMenusContainerRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [_isSubMenus]);

  const redirectToPage = (page?: string, open?: ILinkOpenType[`Type`]) => {
    let currentOpenType = open ? open : openType;
    if (page) {
      switch (currentOpenType) {
        case "Internal":
          navigate(`?page=${page}`);
          break;
        case "External":
          page.includes("tel")
            ? window.open(page, "_self")
            : window.open(page, "_blank");
          break;
      }
    }
  };

  const getAriaLabelByType = (menu: ISubFooterItem, index: number) => {
    let link = ``;
    if (menu.redirectTo.includes("mailto:")) {
      link = `${t("SendMailTo", { mailto: menu.redirectTo })}`;
    } else if (menu.redirectTo.includes("tel:")) {
      link = `${t("CallTo")}`;
    } else if (menu.redirectTo.includes("https://www.youtube.com")) {
      link = `${t("OpenIsraelRailwaysYoutube")}`;
    } else if (menu.redirectTo.includes("https://www.facebook.com")) {
      link = `${t("OpenIsraelRailwaysFacebook")}`;
    } else if (menu.redirectTo.includes("https://www.linkedin.com")) {
      link = `${t("OpenIsraelRailwaysLinkedin")}`;
    } else if (menu.redirectTo.includes("https://www.instagram.com")) {
      link = `${t("OpenIsraelRailwaysInstagram")}`;
    } else if (menu.redirectTo.includes("https://www.tiktok.com")) {
      link = `${t("OpenIsraelRailwaysTiktok")}`;
    }

    /*
${t("SubMenuItemNumberOutOfNumber", {
      currentIndex: index + 1,
      total: subMenus.length,
    })}
*/

    return `${link} ${menu.label} `;
  };

  const handleToggleSubMenus = () => {
    _setIsSubMenus(!_isSubMenus);
  };

  switch (CheckIsEnable(isEnabled)) {
    case true:
      return (
        <div style={styles.itemContainer}>
          {isAccordion ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: `.1rem solid ${colors.white}`,
                cursor: "pointer",
              }}
              onClick={() => {
                handleToggleSubMenus();
              }}
              onKeyPressCapture={() => {
                handleToggleSubMenus();
              }}
              tabIndex={!IsMobile() ? 0 : -1}
              aria-label={`${label} ${t("MenuItemNumberOutOfNumber", {
                currentIndex: (currentIndex || 0) + 1,
                total: totalItems ? totalItems - 1 : 0,
              })}`}
            >
              <div
                style={{
                  height: "3.125rem",
                  display: "flex",
                  justifyContent: "space-between",
                  lineHeight: "1.3125rem",
                  width: "100%",
                  alignItems: "center",
                  paddingBottom: ".7rem",
                }}
              >
                <span style={styles.footerLabel}>{label}</span>
                <img
                  src={Images.dropDownArrow}
                  style={{
                    transform:
                      (_isSubMenus && isAccordion) ||
                      (!_isSubMenus && !isAccordion)
                        ? "rotate(90deg)"
                        : i18n.dir() === "ltr"
                        ? "rotate(180deg)"
                        : "",
                    height: "1.5rem",
                  }}
                  width={`auto`}
                  alt={``}
                />
              </div>
            </div>
          ) : (
            <div style={styles.itemTitleContainer}>
              <h3 style={styles.itemTitle}>{label}</h3>
              <div
                style={{
                  position: "absolute",
                  backgroundColor: colors.koromiko,
                  width: "1.8125rem",
                  height: "2px",
                }}
              ></div>
            </div>
          )}

          {((_isSubMenus && isAccordion) ||
            (!_isSubMenus && !isAccordion) ||
            (_isSubMenus && !isAccordion)) && (
            <ul
              ref={subMenusContainerRef}
              style={{
                ...styles.subMenusContainer,
                flexDirection: itemsDirection,
                justifyContent: isSubMenuGappedCenter ? "space-around" : "",
                gap: isSubMenuGappedCenter ? "" : ".3rem",
              }}
            >
              {subMenus.map((footerItem, index) => (
                <li
                  key={footerItem.label + index}
                  style={{
                    ...styles.footerItem,
                    fontSize: fontSize.UmbracoElementTitle,
                  }}
                  aria-label={getAriaLabelByType(footerItem, index)}
                >
                  {footerItem.image && footerItem.image._url && (
                    <img
                      src={footerItem.image._url}
                      style={{
                        ...styles.footerItemImage,
                        cursor: !isAccordion ? "pointer" : "",
                      }}
                      aria-label={getAriaLabelByType(footerItem, index)}
                      width={`auto`}
                      height={`auto`}
                      alt={``}
                      tabIndex={!IsMobile() ? 0 : -1}
                      onClick={() => {
                        if (!isAccordion) {
                          handleToggleSubMenus();
                          redirectToPage(
                            footerItem.redirectTo,
                            footerItem.linkTo
                          );
                        }
                      }}
                      onKeyPressCapture={() => {
                        if (!isAccordion) {
                          handleToggleSubMenus();
                          redirectToPage(
                            footerItem.redirectTo,
                            footerItem.linkTo
                          );
                        }
                      }}
                    />
                  )}
                  <div>
                    <span
                      style={{
                        cursor: "pointer",
                        fontSize: fontSize.SubTitle
                      }}
                      tabIndex={!IsMobile() ? 0 : -1}
                      onKeyPressCapture={() => {
                        handleToggleSubMenus();
                        redirectToPage(
                          footerItem.redirectTo,
                          footerItem.linkTo
                        );
                      }}
                      onClick={() => {
                        handleToggleSubMenus();
                        redirectToPage(
                          footerItem.redirectTo,
                          footerItem.linkTo
                        );
                      }}
                      role={"link"}
                    >
                      {footerItem.label}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      );
    default:
      return null;
  }
};

const styles: StyleSheet = {
  itemContainer: {
    display: "flex",
    flexDirection: "column",
    color: colors.white,
    paddingInlineStart: "1rem",
    paddingInlineEnd: "1rem",
    fontSize: fontSize.Paragraph,
    width: "80%",
  },
  footerLabel: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    fontSize: fontSize.SubTitle,
    fontFamily: fonts.FbReformaLight,
    lineHeight: "2rem",
  },
  footerItem: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    paddingBottom: "1rem",
    fontFamily: fonts.FbReformaLight,
    lineHeight: "2rem",
  },
  footerItemImage: {
    backgroundRepeat: "no-repeat",
    width: "2.2rem",
    height: "2.3rem",
  },
  subMenusContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    paddingInlineStart: "1rem",
    paddingInlineEnd: "1rem",
  },
  itemTitle: {
    fontSize: fontSize.UmbracoElementTitle,
    lineHeight: "2rem",
    marginBlockStart: "0",
    marginBlockEnd: "1rem",
    fontFamily: fonts.FbReformaMedium,
  },
  itemTitleContainer: {
    position: "relative",
    height: "3.3rem",
    paddingBottom: "3rem",
  },
};
export default FooterItemMobile;
