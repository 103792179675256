import { IQuestionsAndAnswers } from "../../../Interfaces/IUmbraco";
import QuestionAndAnswer from "./QuestionAndAnswer";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import Title from "../Title";
import { useEffect } from "react";
import { CreateQuestionAndAnswerSchema } from "../../../Utils/seo";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";

const QuestionsAndAnswers: React.FC<IQuestionsAndAnswers> = (props) => {
  const { title, questionsAndAnswers, anchor } = props;

  useEffect(() => {
    CreateQuestionAndAnswerSchema(questionsAndAnswers);
  }, [questionsAndAnswers]);

  return (
    <div style={styles.container} anchor-id={anchor}>
      {title && title.length > 0 && <Title text={title} />}
      <div style={styles.content}>
        {questionsAndAnswers.map((questionAndAnswer, index) => (
          <div
            key={index}
            style={{
              borderBottom:
                index < questionsAndAnswers.length - 1
                  ? `1px solid ${colors.gray}`
                  : ``,
              paddingTop: ".5rem",
              paddingBottom: ".5rem",
            }}
          >
            <QuestionAndAnswer {...questionAndAnswer} />
          </div>
        ))}
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    maxWidth: "76rem",
    width: "80%",
  },
  title: {
    position: "relative",
    color: colors.darkBlue,
    fontSize: fontSize.UmbracoElementTitle,
    fontFamily: fonts.FbReformaMedium,
    borderBottom: `1px solid ${colors.koromiko}`,
    borderBottomWidth: ".2rem",
  },
  content: {
    display: "flex",
    maxWidth: "52.5rem",
    flexDirection: "column",
  },
};

export default QuestionsAndAnswers;
