import { t } from "i18next";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import Images from "../../Data/Images/Images";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { IVideoAndText } from "../../Interfaces/IVideo";
import { StyleMode } from "../../Utils/consts";
import { RemoveHtmlTags } from "../../Utils/helpers";
import useDeviceMode from "../../Utils/useWindowDimensions";
import { replaceRichtextBoldWithFont } from "../../Utils/utils";
import Video from "../Video/Video";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../Services/DeviceService";

const VideoAndText: React.FC<IVideoAndText> = (props) => {
  const {
    title,
    description,
    continueRead,
    video,
    backgroundColor,
    direction,
    thumbnail,
  } = props;

  const deviceMode = useDeviceMode();
  const navigate = useNavigate();

  const [_isVideoVisible, _setIsVideoVisible] = useState(false);
  const [_isContinueReadLinkOver, _setIsContinueReadLinkOver] = useState(false);

  const handleDisplayVideo = () => {
    _setIsVideoVisible(true);
  };

  const redirectToPage = (page?: string) => {
    page && navigate(`?page=${page}`);
  };

  return (
    <div
      style={{
        ...styles.imageAndTextContainer,
        backgroundColor: `#${backgroundColor}`,
        flexDirection:
          deviceMode === StyleMode.desktop
            ? direction === "Right to Left"
              ? "row"
              : "row-reverse"
            : direction === "Right to Left"
            ? "column"
            : "column-reverse",
      }}
    >
      <div style={{ flex: "2.2", position: "relative" }}>
        {!_isVideoVisible ? (
          <Fragment>
            <img src={thumbnail._url} width={"100%"} height={"100%"} alt={``} />
            <img
            src={Images.play}
              style={styles.playIcon}
              onClick={handleDisplayVideo}
              role={"button"}
              aria-label={`${t("PlayVideo")} ${title}`}
              tabIndex={!IsMobile() ? 0 : -1}
            ></img>
          </Fragment>
        ) : (
          video && <Video _url={video._url} header={video.header} />
        )}
      </div>
      <div style={styles.informationContainer}>
        <div style={styles.information}>
          <h3 style={styles.title} aria-labelledby={title}>
            {title}
          </h3>
          <div
            style={{
              ...styles.description,
              maxWidth: deviceMode === StyleMode.desktop ? "20rem" : "",
            }}
          >
            <div
              aria-label={RemoveHtmlTags(description)}
              dangerouslySetInnerHTML={{
                __html: replaceRichtextBoldWithFont(description),
              }}
            ></div>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: ".25rem",
                textDecoration: _isContinueReadLinkOver ? "underline" : "",
              }}
              tabIndex={!IsMobile() ? 0 : -1}
              role={"link"}
              aria-label={t("ContinueReadAbout", { title })}
              onClick={() => {
                if (continueRead) {
                  redirectToPage(continueRead.redirectTo);
                }
              }}
              onMouseOver={() => _setIsContinueReadLinkOver(true)}
              onMouseLeave={() => _setIsContinueReadLinkOver(false)}
            >
              {continueRead && (
                <div
                  style={{
                    ...styles.continueRead,
                  }}
                >
                  {`${continueRead.text}${
                    continueRead.text.length > 0 ? `>` : ``
                  }`}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  imageAndTextContainer: {
    display: "flex",
    width: "80%",
    maxWidth: "76rem",
    position: "relative",
  },
  informationContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "1",
  },
  information: {
    display: "flex",
    flexDirection: "column",
    margin: "0 1rem",
    gap: "1rem",
  },
  title: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.UmbracoElementTitle,
    maxWidth: "24rem",
    lineHeight: "1.7rem",
  },
  description: {
    maxWidth: "20rem",
    fontFamily: fonts.FbReformaLight,
    fontSize: fontSize.Paragraph,
    lineHeight: "1.75rem",
    flex: "1",
  },
  continueRead: {
    color: colors.oldLavender,
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Paragraph,
    cursor: "pointer",
  },
  image: {
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    flex: "2",
    backgroundPosition: "center",
  },
  playIcon: {
    position: "absolute",
    backgroundRepeat: "no-repeat",

    top: "calc(50% - 2rem)",
    left: "calc(50% - 1.25rem)",
    cursor: "pointer",
  },
};

export default VideoAndText;
