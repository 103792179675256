import { Translations } from "../../Services/GlobalService";
import i18n from "../../Services/i18n";
import Fare from "./Fare";
import { ICalcResult } from "./FromToStation";
const CardPrices: React.FC<{ cardPrices: ICalcResult[] }> = (props) => {
  const { cardPrices } = props;
  const Translation = Translations[
    i18n.language as `he` | `en` | `ar` | `ru`
  ] as any;
  return (
    <div className={"column"} style={{ gap: "1rem" }}>
      {cardPrices &&
        cardPrices.map((item) => (
          <Fare
            fromStation={
              item.search?.fromStationNumber
                ? item.search?.fromStationNumber
                : 0
            }
            toStation={
              item.search?.toStationNumber ? item.search?.toStationNumber : 0
            }
            title={Translation["titlePriceTravel"].replace("{{from}}", item.search?.fromStation).replace("{{to}}", item.search?.toStation)  }
            hidePayment={true}
          />
        ))}
    </div>
  );
};

export default CardPrices;
