import { IShareModal } from "../../Interfaces/ISocialShare";

const SocialShare: React.FC<Pick<IShareModal, "items">> = (props) => {
  const { items } = props;

  return (
    <div className={"content"}>
      {items.map((item, index) => (
        <a href={item.link} className="icon-app" target={"_blank"} key={index}>
          <img src={item.icon} />
          <span style={{ fontSize: "0.8rem" }}>{item.name}</span>
        </a>
      ))}
    </div>
  );
};

export default SocialShare;
