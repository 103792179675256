import { t } from "i18next";
import { useEffect, useState } from "react";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import Images from "../../Data/Images/Images";
import { IRouteInfo, IRouteSlice } from "../../Interfaces/IRouteDetails";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import i18n from "../../Services/i18n";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";
import RouteItem from "./RouteItem";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../Services/DeviceService";
import { FaArrowCircleDown } from "react-icons/fa";
import { ITrainsDotData } from "../../Interfaces/IStationLine";
import moment from "moment";
import StationLine from "./StationLine";

const RouteSlice: React.FC<IRouteSlice> = (props) => {
  const {
    stations,
    isUpDirection = true,
    hasArrows,
    sliceId,
    onDisplayModal,
    travel,
    index,
    routeSliceType,
    lastStationId,
  } = props;
  const [_toggle, _setToggle] = useState(!hasArrows);

  const deviceMode = useDeviceMode();

  return (
    <>
      {hasArrows && isUpDirection && (
        <div
          style={{
            ...styles.arrow,
            marginRight: i18n.dir() === "rtl" ? "-2rem" : "",
            marginLeft: i18n.dir() === "ltr" ? "-2rem" : "",
          }}
          className="arrow-route"
        >
          <div style={styles.time} className="time-route"></div>
          <div style={styles.details}>
            <img
              src={!_toggle ? Images.route_details_arrow_up : Images.route_details_arrow_down}
              style={{
                cursor: "pointer",
                transform: `translateX(${
                  i18n.dir() === "rtl" ? "50%" : "-50%"
                })`,
              }}
              onClick={() => {
                _setToggle(!_toggle);
              }}
              role={"button"}
              width={`auto`}
              height={`auto`}
              // alt={``}
              tabIndex={0}
              alt={`${t(
                _toggle
                  ? "RouteDetailsArrowCollapseAria"
                  : "RouteDetailsArrowExpandAria"
              )} ${t("RouteDetailsPreviousStations")}`}
            />
          </div>
        </div>
      )}
      <>
        {_toggle &&
          stations.map((route: IRouteInfo, index: number) => {
            return (
              // <div key={index}>
              <RouteItem
                key={index}
                toggle={_toggle}
                train={route}
                // onDisplayModal={onDisplayModal}
                travel={travel}
                index={props.index}
                isFirstItem={index === 0}
                isLastItem={index === stations.length - 1}
                destinationStationId={
                  routeSliceType === "stations" ? lastStationId : undefined
                }
              />
            );
          })}
      </>
      {hasArrows && !isUpDirection && (
        <div
          style={{
            ...styles.arrow,
            marginRight: i18n.dir() === "rtl" ? "-2rem" : "",
            marginLeft: i18n.dir() === "ltr" ? "-2rem" : "",
          }}
          className="arrow-route"
        >
          <div style={styles.time} className="time-route">
            &nbsp;
          </div>
          <div style={styles.details}>
            <img
              className={sliceId}
              src={_toggle ? Images.route_details_arrow_up : Images.route_details_arrow_down}
              style={{
                cursor: "pointer",
                transform: `translateX(${
                  i18n.dir() === "rtl" ? "50%" : "-50%"
                })`,
              }}
              onClick={() => {
                _setToggle(!_toggle);
              }}
              role={"button"}
              width={`auto`}
              height={`auto`}
              // alt={``}
              alt={`${t(
                _toggle
                  ? "RouteDetailsArrowCollapseAria"
                  : "RouteDetailsArrowExpandAria"
              )} ${t("RouteDetailsNextStations")}`}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Tab" && !e.shiftKey) {
                  const selectedTravelCard = document.querySelector(
                    "[data-active='true']"
                  );

                  const routeDetailsContainer = document.getElementById(
                    "routeDetailsContainer"
                  );
                  if (routeDetailsContainer) {
                    const allLastArrows =
                      document.getElementsByClassName("lastSlice");
                    if (allLastArrows && allLastArrows.length > 0) {
                      const isCurrentLastArrow =
                        e.currentTarget ===
                        allLastArrows[allLastArrows.length - 1];

                      if (isCurrentLastArrow && selectedTravelCard) {
                        if (deviceMode === StyleMode.desktop) {
                          const nextCardId =
                            parseInt(selectedTravelCard.id.split("_")[2]) + 1;
                          if (nextCardId !== undefined) {
                            const nextTravelCard = document.getElementById(
                              `train_card_${nextCardId}`
                            ) as HTMLDivElement;
                            if (nextTravelCard) {
                              let timeout = setTimeout(() => {
                                const mainScroll =
                                  document.getElementById("mainScroll")!;
                                mainScroll.scrollTo(0, 0);

                                let cardScrollTimeout = setTimeout(() => {
                                  nextTravelCard.scrollIntoView({
                                    behavior: "smooth",
                                    block: "center",
                                  });
                                  clearTimeout(cardScrollTimeout);
                                }, 0);
                                nextTravelCard.click();
                                nextTravelCard.tabIndex = 0;
                                nextTravelCard.focus();
                                clearTimeout(timeout);
                              }, 0);
                            } else {
                              const nextTrainsButton =
                                document.getElementById("nextTrainsButton");
                              if (nextTrainsButton) {
                                let timeout = setTimeout(() => {
                                  nextTrainsButton.tabIndex = 0;
                                  nextTrainsButton.focus();
                                  clearTimeout(timeout);
                                }, 20);
                              }
                            }
                          }
                        } else {
                          const closeRouteDetailsButton =
                            document.getElementById("closeRouteDetailsButton");
                          if (closeRouteDetailsButton) {
                            closeRouteDetailsButton.focus();
                          }
                        }
                      }
                    }
                  }
                }
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

const styles: StyleSheet = {
  box: {
    display: "flex",
    alignItems: "flex-start",
    paddingBottom: "23px",
    boxSizing: "border-box",
  },
  arrow: {
    display: "flex",
    alignItems: "flex-start",
    // paddingBottom: "23px",
    boxSizing: "border-box",
  },
  circle: {
    height: "10px",
    width: "10px",
    border: "1px solid rgba(134, 133, 133, 1)",
    borderRadius: "5px",
    margin: "4px auto",
    boxSizing: "border-box",
  },
  bullet: {
    width: "20px",
    height: "20px",
    boxSizing: "border-box",
  },
  time: {
    height: "20px",
    padding: "0 10px",
    boxSizing: "border-box",
    fontWeight: "bold",
    minWidth: "100px",
    fontFamily: fonts.FbReformaRegular,
  },
  del: {
    color: colors.gray,
  },
  lateTime: {
    color: colors.bittersweet,
    fontWeight: "bold",
  },
  details: {
    display: "flex",
    // marginInline: "-.5rem",
  },
  aside: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "pre",
    fontSize: fontSize.Text,
    fontFamily: fonts.FbReformaRegular,
  },
  change: {
    paddingBottom: "10px",
    color: colors.darkBlue,
    display: "flex",
    alignItems: "center",
  },
  station: {
    color: colors.gray,
    fontSize: fontSize.Text,
    fontWeight: "500",
  },
  stationName: {
    paddingBottom: ".5rem",
    lineHeight: "calc(1rem + 2px)",
    fontFamily: fonts.FbReformaRegular,
  },
  arrowIcon: {
    cursor: "pointer",
  },
};

export default RouteSlice;
