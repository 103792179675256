import React, { ChangeEvent, useEffect } from "react";
import colors from "../../../Assets/Colors/Colors.json";
import { ICurrentService } from "../../../Interfaces/IForms";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import CustomAttachedFiles from "../CustomFileds/CustomAttachedFiles";
import CustomDatePicker from "../CustomFileds/CustomDatePicker";
import CustomInput from "../CustomFileds/CustomInput";
import CustomSelect from "../CustomFileds/CustomSelect";
import CustomTextArea from "../CustomFileds/CustomTextArea";
import FormsDB from "../db.json";

const FormAppealRequirementPaymentScreen: React.FC<ICurrentService> = (
  props
) => {
  const deviceMode = useDeviceMode();
  const { formik } = props;

  useEffect(() => {
    document
      .getElementById("FormAppealRequirementPaymentScreen_container")
      ?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, []);

  return (
    <div
      style={styles.container}
      id="FormAppealRequirementPaymentScreen_container"
    >
      <div style={styles.form}>
        <CustomSelect
          label="האם נסיעתך בוצעה באמצעות כרטיס רב קו?"
          name="FormAppealRequirementPaymentScreen.isRavKav"
          placeholder="isRavKav"
          onChange={(isRavKav: ChangeEvent<HTMLSelectElement>) => {
            formik.setFieldValue(
              "FormAppealRequirementPaymentScreen.isRavKav",
              isRavKav.target.value
            );
          }}
          errors={formik.errors.FormAppealRequirementPaymentScreen?.isRavKav}
          touched={formik.touched.FormAppealRequirementPaymentScreen?.isRavKav+''}
          selectstyle={{
            flex: "unset",
            width: deviceMode === StyleMode.mobile ? "100%" : "48%",
          }}
        >
          <option value="">בחירה</option>
          {FormsDB.booleanList.map((travelType, index) => (
            <option key={index} value={travelType.boolKey}>
              {travelType.boolValue}
            </option>
          ))}
        </CustomSelect>

        {formik.values.FormAppealRequirementPaymentScreen.isRavKav !== "" && (
          <div style={styles.stationsSelectContainer}>
            <CustomSelect
              label="תחנת מוצא"
              name="FormAppealRequirementPaymentScreen.stationDeparture"
              placeholder="stationDeparture"
              onChange={(departure: ChangeEvent<HTMLSelectElement>) => {
                formik.setFieldValue(
                  "FormAppealRequirementPaymentScreen.stationDeparture",
                  departure.target.value
                );
              }}
              errors={
                formik.errors.FormAppealRequirementPaymentScreen
                  ?.stationDeparture
              }
              touched={
                formik.touched.FormAppealRequirementPaymentScreen
                  ?.stationDeparture+''
              }
              selectstyle={{
                flex: "unset",
                width: deviceMode === StyleMode.mobile ? "100%" : "48%",
              }}
            >
              <option value="">יש לבחור את שם התחנה</option>
              {FormsDB.stations.map((station, index) => (
                <option key={index} value={station.stationId}>
                  {station.stationName}
                </option>
              ))}
            </CustomSelect>

            <CustomSelect
              label="תחנת יעד"
              name="FormAppealRequirementPaymentScreen.stationDestination"
              placeholder="stationDestination"
              onChange={(destination: ChangeEvent<HTMLSelectElement>) => {
                formik.setFieldValue(
                  "FormAppealRequirementPaymentScreen.stationDestination",
                  destination.target.value
                );
              }}
              errors={
                formik.errors.FormAppealRequirementPaymentScreen
                  ?.stationDestination
              }
              touched={
                formik.touched.FormAppealRequirementPaymentScreen
                  ?.stationDestination+''
              }
              selectstyle={{
                flex: "unset",
                width: deviceMode === StyleMode.mobile ? "100%" : "48%",
              }}
            >
              <option value="">יש לבחור את שם התחנה</option>
              {FormsDB.stations.map((station, index) => (
                <option key={index} value={station.stationId}>
                  {station.stationName}
                </option>
              ))}
            </CustomSelect>
            <CustomInput
              label="מספר תעודת זהות"
              name="FormAppealRequirementPaymentScreen.userId"
              type="number"
              value={formik.values.FormAppealRequirementPaymentScreen.userId}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              errors={formik.errors.FormAppealRequirementPaymentScreen?.userId}
              touched={
                formik.touched.FormAppealRequirementPaymentScreen?.userId+''
              }
              placeholder="הזן מספר תעודת זהות"
              selectstyle={{
                flex: "unset",
                width: deviceMode === StyleMode.mobile ? "100%" : "48%",
              }}
            />
            <CustomInput
              label="מספר דרישה"
              name="FormAppealRequirementPaymentScreen.reqNumber"
              type="number"
              value={formik.values.FormAppealRequirementPaymentScreen.reqNumber}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              errors={
                formik.errors.FormAppealRequirementPaymentScreen?.reqNumber
              }
              touched={
                formik.touched.FormAppealRequirementPaymentScreen?.reqNumber+''
              }
              placeholder="הזן מספר דרישה"
              selectstyle={{
                flex: "unset",
                width: deviceMode === StyleMode.mobile ? "100%" : "48%",
              }}
            />
          </div>
        )}

        {formik.values.FormAppealRequirementPaymentScreen.isRavKav ===
          "true" && (
          <CustomInput
            label="מספר כרטיס רב קו"
            name="FormAppealRequirementPaymentScreen.ravKavCardNumber"
            type="number"
            value={
              formik.values.FormAppealRequirementPaymentScreen.ravKavCardNumber
            }
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            errors={
              formik.errors.FormAppealRequirementPaymentScreen?.ravKavCardNumber
            }
            touched={
              formik.touched.FormAppealRequirementPaymentScreen
                ?.ravKavCardNumber+''
            }
            placeholder="הזן מספר כרטיס רב קו"
            selectstyle={{
              flex: "unset",
              width: deviceMode === StyleMode.mobile ? "100%" : "48%",
            }}
          />
        )}

        <div style={styles.finalElementsContainer}>
          <CustomDatePicker
            label="תאריך"
            name="FormAppealRequirementPaymentScreen.eventDateTime"
            type="date"
            value={
              formik.values.FormAppealRequirementPaymentScreen.eventDateTime
            }
            onBlur={formik.handleBlur}
            onChange={(date: Date) => {
              formik.setFieldValue(
                "FormAppealRequirementPaymentScreen.eventDateTime",
                date
              );
            }}
            errors={
              formik.errors.FormAppealRequirementPaymentScreen?.eventDateTime
            }
            touched={
              formik.touched.FormAppealRequirementPaymentScreen?.eventDateTime+''
            }
            placeholder="aa"
            showTime={true}
          />
          <CustomTextArea
            label="תיאור האירוע"
            name="FormAppealRequirementPaymentScreen.comments"
            type="textarea"
            value={formik.values.FormAppealRequirementPaymentScreen.comments}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            errors={formik.errors.FormAppealRequirementPaymentScreen?.comments}
            touched={
              formik.touched.FormAppealRequirementPaymentScreen?.comments+''
            }
            placeholder="הזן תיאור האירוע"
          />
          <CustomAttachedFiles
            label="צירוף מסמכים
              (ניתן להוסיף יותר מקובץ אחד)"
            name="FormAppealRequirementPaymentScreen.attachFiles"
            value={formik.values.FormAppealRequirementPaymentScreen.attachFiles}
            onBlur={formik.handleBlur}
            onChange={(files: File[] | null) => {
              formik.setFieldValue(
                "FormAppealRequirementPaymentScreen.attachFiles",
                files
              );
            }}
            errors={
              formik.errors.FormAppealRequirementPaymentScreen?.attachFiles
            }
            touched={
              formik.touched.FormAppealRequirementPaymentScreen?.attachFiles+''
            }
            type="file"
            placeholder="צירוף קובץ"
          />
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    margin: "auto",
    paddingBottom: "2rem",
  },
  finalElementsContainer: {
    display: "flex",
    flexWrap: "wrap",
    columnGap: "2rem",
  },
  form: { display: "flex", flexWrap: "wrap", columnGap: "2rem" },
  button: {
    width: "100%",
    padding: "0.65rem 0.5rem",
    fontSize: "1rem",
    color: colors.white,
    border: "2px solid",
    backgroundColor: colors.blue,
    borderRadius: "10px",
    outline: "none",
    margin: "1rem 0",
    fontWeight: "bold",
    cursor: "pointer",
  },
  stationsSelectContainer: {
    display: "flex",
    width: "100%",
    columnGap: "2rem",
    flexWrap: "wrap",
  },
};

export default FormAppealRequirementPaymentScreen;
