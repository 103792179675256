import colors from "../../Assets/Colors/Colors.json";

import { StyleSheet } from "../../Interfaces/IStyleSheet";

import moment from "moment";
import { IStationLineProps } from "../../Interfaces/IStationLine";
import React from "react";
import useDeviceMode from "../../Utils/useWindowDimensions";
import { StyleMode } from "../../Utils/consts";

const StationLine: React.FC<IStationLineProps> = (props) => {
  const { isCurrent, trainsDotData, isFirstItem, isLastItem } = props;
  const deviceMode = useDeviceMode();

  const getTrainDotPosition = (): number => {
    const BOOTOM_LOCAION = 16;
    const TOP_LOCATION = -29;
    const LOCATION_RANG = Math.abs(TOP_LOCATION) + BOOTOM_LOCAION;
    let dotLocation;

    if (trainsDotData) {
      const timeCurr = trainsDotData.times[0];
      const timeNext = trainsDotData.times[1];
      const timeNow = moment().unix();
      const diffCurrNext = timeCurr - timeNext;
      const diffNowNext = timeNow - timeNext;

      const dotPercentRelativePosition = Math.ceil(
        (diffNowNext / diffCurrNext) * 100
      );
      dotLocation = (LOCATION_RANG * dotPercentRelativePosition) / 100;
    }
    const res = dotLocation ? BOOTOM_LOCAION - dotLocation : 100;

    return res;
  };

  return (
    <div
      style={{
        ...styles.container,
        // marginRight: deviceMode === StyleMode.desktop ? "63.5px" : "76.5px",
      }}
    >
      {isCurrent && (
        <div
          className="dot-animation"
          style={{
            position: "absolute",
            top: isFirstItem
              ? Math.max(getTrainDotPosition(), -16)
              : isLastItem
              ? Math.min(getTrainDotPosition(), 45)
              : getTrainDotPosition(),

            zIndex: 1,
            margin: "0 -7px",
          }}
        >
          <div style={styles.trainDot} />
        </div>
      )}
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    position: "relative",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    // marginRight: 63.5,
  },
  verticalLine: {
    width: 1,
    flexDirection: "column",
    overflow: "hidden",
    height: 30,
  },
  trainDot: {
    width: 14,
    height: 14,
    backgroundColor: colors.darkBlue,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: colors.blue,
  },
};

export default StationLine;
