import { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import colors from "../Assets/Colors/Colors.json";
import fonts from "../Assets/Fonts/Fonts.json";
import Images from "../Data/Images/Images";
import { IStaticPage } from "../Interfaces/IStaticPage";
import { StyleSheet } from "../Interfaces/IStyleSheet";
import {
  IUpdatesBackendContent,
  IUpdatesContent,
} from "../Interfaces/IUmbraco";
import { IUpdates } from "../Interfaces/IUpdates";
import { StyleMode } from "../Utils/consts";
import useDeviceMode from "../Utils/useWindowDimensions";
import fontSize from "../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../Services/DeviceService";

const TestPage: React.FC = () => {
  const [_updates, _setUpdates] = useState<IUpdatesContent[]>([]);
  const [_updatesBackend, _setUpdatesBackend] = useState<
    IUpdatesBackendContent[]
  >([]);
  const [_isUpdateContentPage, _setIsUpdateContentPage] = useState(false);
  const [_updateContentPage, _setUpdateContentPage] = useState<IStaticPage>();
  const [_selectedUpdateItem, _setSelectedUpdateItem] = useState(true);
  const [_currentUpdate, _setCurrentUpdate] = useState<IStaticPage>();
  const deviceMode = useDeviceMode();
  const { t } = useTranslation();

  let isCardDisplay = true;
  const titleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.focus();
      titleRef.current.style.outline = "none";
      titleRef.current.tabIndex = -1;
    }
  }, []);

  return (
    <Fragment>
      <div
        style={{
          ...styles.mainContainer,
          paddingTop: deviceMode === StyleMode.desktop ? "3rem" : "",
        }}
      >
        <div
          style={{
            ...styles.cover,
            background: `30% 30% / cover url(${Images.coverCloseImage})`,
            height:
              deviceMode === StyleMode.desktop
                ? isCardDisplay
                  ? "40vh"
                  : "20.5vh"
                : "27vh",
            minHeight:
              deviceMode === StyleMode.desktop
                ? isCardDisplay
                  ? "17.18rem"
                  : "14.25rem"
                : "27vh",
          }}
        >
          <div className="cover-color-blur">
            <div
              style={{
                ...styles.coverElements,
                width: deviceMode === StyleMode.desktop ? "80%" : "75%",
              }}
              className="container"
            >
              <div
              /*  ref={titleRef}*/
                style={{ position: "absolute", zIndex: -1 }}
                aria-label={t("Menu-updates")}
                tabIndex={!IsMobile() ? 0 : -1}
              ></div>
              <div
                style={{
                  ...styles.breadcrumb,
                  paddingTop:
                    deviceMode === StyleMode.desktop ? "2rem" : "3rem",
                }}
              ></div>
              <h1
                style={{
                  ...styles.title,
                  paddingTop:
                    deviceMode === StyleMode.desktop ? "2rem" : "3rem",
                }}
              >
                דף טסטים
              </h1>
            </div>
          </div>
        </div>
        <div>
          <div
            style={
              isCardDisplay && deviceMode === StyleMode.desktop
                ? styles.cardBorder
                : styles.cardBorderMobile
            }
          >
            <div
              className="card-body"
              style={
                isCardDisplay && deviceMode === StyleMode.desktop
                  ? styles.cardBody
                  : styles.cardBodyMobile
              }
            >
              {/* הכנס כאן קומפוננטות לבדיקות */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const styles: StyleSheet = {
  mainContainer: {
    alignItems: "center",
    flexDirection: "column",
    zIndex: "10",
  },
  cover: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "20.5vh",
    position: "relative",
    minHeight: "14.25rem",
  },
  cardBorder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transform: "translateY(-7.8rem)",
  },
  cardBorderMobile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "3rem",
    padding: "2rem 0",
    backgroundColor: colors.white,
    borderRadius: "0.3125rem",
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.15)",
    minWidth: "80%",
  },
  cardBodyMobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "3rem",
    padding: "2rem 0",
    width: "100%",
  },
  title: {
    color: colors.white,
    fontSize: fontSize.PageTitle,
    lineHeight: "3rem",
    letterSpacing: "0.05625rem",
    textShadow: `2px 2px ${colors.black}`,
    fontFamily: fonts.FbReformaMedium,
  },
  breadcrumb: {
    display: "flex",
    color: colors.white,
    fontSize: fontSize.Text,
    letterSpacing: "0.05625rem",
    fontFamily: fonts.FbReformaRegular,
    position: "absolute",
  },
  coverElements: {
    gap: "3rem",
    maxWidth: "76rem",
    margin: "auto",
    lineHeight: "2.5rem",
    padding: "1rem",
  },
};

export default TestPage;
