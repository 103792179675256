import { ForwardedRef, forwardRef } from "react";
import { FaCalendarAlt } from "react-icons/fa";
import colors from "../../../Assets/Colors/Colors.json";
import { ICustomInput } from "../../../Interfaces/IForms";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import DatePicker from "../../DatePicker/react-datepicker";

const CustomTimePicker: React.FC<ICustomInput> = (props) => {
  const { label, selectstyle, value, onChange, errors, touched } = props;
  const boolTouched = touched === "true" ? true : false;

  return (
    <div
      style={
        selectstyle
          ? {
              ...styles.inputContainer,
              flex: selectstyle.flex,
              width: selectstyle.width,
            }
          : styles.inputContainer
      }
    >
      <label style={styles.label}>{label}</label>
      <div style={styles.input}>
        <div style={styles.icon}>
          <FaCalendarAlt />
        </div>
        <DatePicker
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="שעה"
          dateFormat="HH:mm"
          timeFormat="HH:mm"
          selected={(value && new Date(value)) || null}
          onChange={onChange}
          className={
            errors && errors && boolTouched && boolTouched ? "input-error" : ""
          }
        />
      </div>
      {errors && errors && boolTouched && boolTouched && (
        <p className="error">{errors && errors}</p>
      )}
    </div>
  );
};

const styles: StyleSheet = {
  label: {
    fontSize: "1rem",
    color: colors.black,
    fontWeight: "bold",
    display: "flex",
    margin: "1rem 0 0.2rem",
  },
  input: {
    width: "100%",
    padding: "0.65rem 0.5rem",
    fontSize: "1rem",
    color: colors.black,
    border: "1px solid",
    borderColor: colors.gray,
    borderRadius: "10px",
    outline: "none",
    background: "white",
    display: "flex",
    justifyContent: "center",
    gap: ".5rem",
  },
  inputContainer: {
    flex: "45%",
  },
  icon: {
    paddingRight: ".5rem",
  },
};

export default CustomTimePicker;
