import store from "store2"
import Config from "../Configuration/server"
import { IStation } from "../Interfaces/IStation"
import { ICategoryPages, ITree } from "../Interfaces/ITree"
import { GetStations } from "../Services/GlobalService"
import { Languages } from "./getLanguage"

export let xmlSiteMap =''
export let treeSite:ITree[] = []
export let SiteData:ICategoryPages[] =[]

export  const CreateXml=(tree: ITree[])=>{
    const startXml = 
    `<?xml version="1.0" encoding="UTF-8"?>
        <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">`
    const endXml = `</urlset>`
    const pageXml = 
    `<url>
        <loc>$$$</loc>
        <lastmod>***</lastmod>
    </url>`
    let sitemap = startXml + 
                    pageXml.
                        replace(`$$$`,Config.DOMAIN!)
                        .replace(`***`,new Date().toDateString())
    for(let i=0;i<tree.length;i++){
        for(let j=0;j<tree[i].ids.length;j++){
            for(let z=0;z<Languages.length;z++){
                sitemap+=
                    pageXml.replace(`$$$`,`${Config.DOMAIN}?page=${tree[i].ids[j]}&amp;lan=${Languages[z]}`)
                    .replace(`***`,`${new Date().toDateString()}`)
            }
        }
    }

    // const allStations = await GetStations()
    // const stationList:IStation[] = allStations[`he`]  
    // for(let i=0;i<stationList.length;i++){
    //     sitemap+=
    //             pageXml.replace(`$$$`,`${Config.DOMAIN}?page=stationinfo&amp;stationname=${stationList[i].stationId}&amp;lan=he}`)
    //             .replace(`***`,`${new Date().toDateString()}`)
    //     sitemap+=
    //             pageXml.replace(`$$$`,`${Config.DOMAIN}?page=stationinfo&amp;stationname=${stationList[i].stationId}&amp;lan=en`)
    //             .replace(`***`,`${new Date().toDateString()}`)
    //     sitemap+=
    //             pageXml.replace(`$$$`,`${Config.DOMAIN}?page=stationinfo&amp;stationname=${stationList[i].stationId}&amp;lan=ar`)
    //             .replace(`***`,`${new Date().toDateString()}`)
    //     sitemap+=
    //             pageXml.replace(`$$$`,`${Config.DOMAIN}?page=stationinfo&amp;stationname=${stationList[i].stationId}&amp;lan=ru`)
    //             .replace(`***`,`${new Date().toDateString()}`)            
    // }

    xmlSiteMap=sitemap+=endXml
    treeSite = tree
}

export const CreateSiteDate =(data:ICategoryPages[])=>{
    SiteData = data;
}