import { Fragment, useState } from "react";
import colors from "../../../../Assets/Colors/Colors.json";
import fontSize from "../../../../Assets/Fonts/FontsSizes.json";
import Images from "../../../../Data/Images/Images";
import { IGalleryImage, IGalleryVideo } from "../../../../Interfaces/IGallery";
import { IImageAndText } from "../../../../Interfaces/IImageAndText";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import {
  ILinkableGalleries,
  ILinkableGallery,
} from "../../../../Interfaces/IUmbraco";
import { IVideoAndText } from "../../../../Interfaces/IVideo";
import CustomModal from "../../../CustomModal";
import ImageAndText from "../../ImageAndText";
import VideoAndText from "../../VideoAndText";
import LinkableGalleryImage from "./LinkableGalleryImage";
import LinkableGalleryVideo from "./LinkableGalleryVideo";

const LinkableGalleries: React.FC<ILinkableGalleries> = (props) => {
  const { gallery } = props;
  const [_isModalOpen, _setIsModalOpen] = useState(false);
  const [_selectedLinkableGallery, _setSelectedLinkableGallery] =
    useState<ILinkableGallery>();
  const [_currentGalleryIndex, _setCurrentGalleryIndex] = useState(0);

  const handleOpenModal = (linkableGallery: ILinkableGallery) => {
    _setSelectedLinkableGallery(linkableGallery);
    _setIsModalOpen(true);
  };

  const handleNextGallery = () => {
    const nextIndex = _currentGalleryIndex + 1;
    _setCurrentGalleryIndex(nextIndex);
  };

  const handlePreviousGallery = () => {
    const previousIndex = _currentGalleryIndex - 1;
    _setCurrentGalleryIndex(previousIndex);
  };

  const GetGalleryItem: React.FC<
    IGalleryVideo & IImageAndText & IVideoAndText & IGalleryImage
  > = (props) => {
    switch (props.contentTypeAlias) {
      case "imageAndText":
        return <ImageAndText {...(props as IImageAndText)} />;
      case "videoAndText":
        return <VideoAndText {...(props as IVideoAndText)} />;
      case "videoGalleryItem":
        return <LinkableGalleryVideo {...(props as IGalleryVideo)} />;
      case "imageGalleryItem":
        return <LinkableGalleryImage {...(props as IGalleryImage)} />;
      default:
        return null;
    }
  };

  return (
    <Fragment>
      <div style={styles.container}>
        {gallery.map((linkableGallery, index) => (
          <div
            key={index}
            style={{
              ...styles.gallery,
              boxShadow:
                linkableGallery.border === "1"
                  ? "0 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 15px 0 rgba(0, 0, 0, 0.19)"
                  : "none",
            }}
            onClick={() => handleOpenModal(linkableGallery)}
          >
            <div>
              {linkableGallery.media && (
                <img
                  src={linkableGallery.media._url}
                  style={{
                    ...styles.image,
                    width: linkableGallery.border === "1" ? "20rem" : "22rem",
                    height: linkableGallery.border === "1" ? "10rem" : "12rem",
                  }}
                  alt={``}
                />
              )}
            </div>
            <div style={styles.title}>{linkableGallery.title}</div>
          </div>
        ))}
      </div>
      <CustomModal visible={_isModalOpen}
        typeStyle={"rectangle"}
        innerStyle={{ width: "80%", height: "50rem", padding: 0, maxWidth: "90.625rem", }}
        contentStyle={{ padding: 0 }}
        onClose={() => {
          _setIsModalOpen(false);
        }}  ><div style={styles.galleriesContainer}>
          {_selectedLinkableGallery && (
            <GetGalleryItem
              {..._selectedLinkableGallery.gallery[_currentGalleryIndex]}
            />
          )}
          <div style={styles.currentGalleryArrows}>
            <div
              style={{
                width: "4.1rem",
                height: "4.3rem",
              }}
            >
              {_currentGalleryIndex > 0 && (
                <div
                  style={styles.arrowIcon}
                  onClick={handlePreviousGallery}
                ></div>
              )}
            </div>
            <div
              style={{
                width: "4.1rem",
                height: "4.3rem",
              }}
            >
              {_selectedLinkableGallery &&
                _currentGalleryIndex <
                _selectedLinkableGallery.gallery.length - 1 && (
                  <div
                    style={{ ...styles.arrowIcon, transform: "rotate(180deg)" }}
                    onClick={handleNextGallery}
                  ></div>
                )}
            </div>
          </div>
          <div
            style={{
              fontSize: fontSize.H1,
              color: colors.darkBlue,
              position: "absolute",
              bottom: "3rem",
            }}
          >
            {`${_currentGalleryIndex + 1}/${_selectedLinkableGallery?.gallery.length
              }`}
          </div>
        </div></CustomModal>
  
    </Fragment>
  );
};

const styles: StyleSheet = {
  container: {
    width: "80%",
    maxWidth: "76rem",
    display: "flex",
    flexWrap: "wrap",
    gap: "2rem",
    justifyContent: "center",
  } as React.CSSProperties,
  gallery: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    cursor: "pointer",
  } as React.CSSProperties,
  title: {
    paddingTop: "1rem",
    boxSizing: "border-box",
    maxWidth: "20rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: fontSize.UmbracoElementTitle,
    color: colors.darkBlue,
  } as React.CSSProperties,
  image: {
    width: "20rem",
    height: "10rem",
  } as React.CSSProperties,
  galleriesContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "2rem",
    width: "100%",
  } as React.CSSProperties,
  arrowIcon: {
    backgroundImage: `url(${Images.galleryArrow})`,
    backgroundRepeat: "no-repeat",
    width: "4.1rem",
    height: "4.3rem",
    cursor: "pointer",
  } as React.CSSProperties,
  currentGalleryArrows: {
    position: "absolute",
    top: "50%",
    width: "100%",
    display: "flex",
    gap: "3rem",
    justifyContent: "space-between",
    alignItems: "center",
  } as React.CSSProperties,
};

export default LinkableGalleries;
