import { useState } from "react";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import Images from "../../../Data/Images/Images";
import { IMemberShort } from "../../../Interfaces/IDirectorateMember";
import i18n from "../../../Services/i18n";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { RemoveHtmlTags } from "../../../Utils/helpers";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../Services/DeviceService";

const MemberShort: React.FC<IMemberShort> = (props) => {
  const { fullname, role, image,description, onSelect } = props;
  const [_isOver, _setIsOver] = useState(false);

  return (
    <div
      style={styles.container}
      onClick={() => {
        onSelect && onSelect(props);
      }}
      onMouseOver={() => {
        _setIsOver(true);
      }}
      onMouseLeave={() => {
        _setIsOver(false);
      }}
      onFocusCapture={()=>{
        _setIsOver(true)
      }}
      onBlurCapture={()=>{
        _setIsOver(false)
      }}
      
    >
      <div
        className="avatar"
        style={{ border: `.3rem solid ${_isOver ? colors.darkBlue : colors.white}`, }}
      >
        <div
          style={{
            backgroundImage: `url(${image ? image._url : ""})`,
            ...styles.image,
            backgroundPosition:`center`,
            backgroundPositionY:`0.1rem` ,
          }}
        >
          <div
            className="arrows"
          style={{
            backgroundImage: `url(${_isOver ? Images.arrowOver : Images.directorateArrow})`,
         
            left: `${i18n.dir() === "rtl" ? "2rem" : ""}`,
            right: `${i18n.dir() === "rtl" ? "" : "2rem"}`,
            transform: `scale(1.5) rotate(${
              i18n.dir() === "rtl" ? "180deg" : "0"
            })`,
          }}
          tabIndex={!IsMobile() ? 0 : -1}
          aria-label={`${fullname}, ${role}, ${RemoveHtmlTags(description)}`}
        ></div>

        </div>
       
      </div>
      <div style={styles.shortInfo}>
        <div style={styles.title}>{fullname}</div>
        <div  className="underline" style={styles.role}>{role}</div>
        <div dangerouslySetInnerHTML={{ __html: description.slice(0,70) }} className="career-description" style={{width:"12rem"}}></div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "2rem",
    cursor: "pointer",
    flex: "1",
  },
  title: {
    fontSize: fontSize.Paragraph,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
  },
  description: {
    flex: "1",
    fontSize: fontSize.FooterItem,
    fontFamily: fonts.FbReformaRegular,
  },
  shortInfo: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: ".2rem",
  },
  border: {
    position: "relative",
    borderColor: colors.darkBlue,
    backgroundColor: "white",
    width: "15rem",
    height: "15rem",
    borderRadius: "50%",
  },
  image: {
    backgroundRepeat: "no-repeat",
    position: "absolute",
    backgroundSize: "cover",
    borderRadius: "50%",
    width: "95%",
    height: "95%",
  },
};

export default MemberShort;
