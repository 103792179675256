import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Title from "../Components/UmbracoElements/Title";
import { StyleSheet } from "../Interfaces/IStyleSheet";
import { UmbracoStoreState } from "../Redux/Slices/umbracoSlice";
import { GetMailingList, RemoveEmailList } from "../Services/tenderServerServices";
const Unsubscribe: React.FC<any> = (props) => {
  let [_mail, _setMail] = useState<string | null>();
  const [_displayMessage, _setDisplayMessage] = useState(null);
  const umbracoStore = (
    useSelector((state: any) => state.UmbracoStoreReducer) as UmbracoStoreState
  ).umbracoStore;
  const navigate = useNavigate();

  useEffect(() => {
    init()
  }, []);

  const init = async () => {
    const params = new URLSearchParams(window.location.search);
    const mail: any = params.get("mail")
    const emails = await GetMailingList();
    if (mail) {
      const emailExist = emails.find((item: any) => item.mails === mail);
      if (emailExist) {
        removeEmailFromMailingList(mail ? mail : "")
      } else {
        _setDisplayMessage(t("mailNotFound"));
      }
    }
  }
  const removeEmailFromMailingList = async (mail: string) => {
    const res = await RemoveEmailList(mail ? mail : "");
    _setDisplayMessage(res.status === 200 ? t("mailingListRemove") : t("errorMessage"))
    _setMail(mail)
  };

  return (
    <div className="unsubscribe text-balance" style={styles.mainContainer}>
      <img
        src={umbracoStore?.headerContent?.logo._url}
        onClick={() => {
          navigate("/");
        }}
        alt={`${umbracoStore?.headerContent?.logoDescription || t(`GoToHomePage`)}`}
        style={{
          cursor: "pointer",
          width: "12rem",
          maxWidth: "100%",
        }}
        role={"link"}
      />

      {_displayMessage && <>
        <Title text={t("unsubscribedTitle")} containerStyles={{ textAlign: "center" }} />
        <div style={styles.displayMessage}>
          <p style={styles.label}>
            {_displayMessage}
          </p>
        </div></>
      }
    </div>
  );
};

const styles: StyleSheet = {
  mainContainer: {
    maxWidth: "25rem",
    margin: "auto",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "1rem",
    gap: "1rem",
    textAlign: "center",
    minHeight: "100vh"
  },
  label: {
    fontSize: "1.5rem",
    fontWeight: "400",
    paddingBottom: "1rem",
    lineHeight: "2rem",
  },
  displayMessage: {
    lineHeight: "2.5rem",
  },
};
export default Unsubscribe;
