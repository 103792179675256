import QueryString from "qs";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { IStationsListGrouped } from "../../Interfaces/IStation";
import { ScheduleState } from "../../Redux/Slices/scheduleSlice";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";
import StationsList from "./StationsList";

const StationsListGrouped: React.FC<IStationsListGrouped> = (props) => {
  const {
    title,
    allStations,
    recentLastStationsTitle,
    recentLastStations,
    onClick,
    stationsType,
    hideLastORNearbyStations,
    textValue,
    inputValue,
    isSelectedStationId
  } = props;

  const location = useLocation();
  const deviceMode = useDeviceMode();
  const schedule = useSelector(
    (state: any) => state.ScheduleReducer
  ) as ScheduleState;

  const getSelectedOriginStationId = () => {
    const urlParams = location.search.split("?")[1] || "";

    if (urlParams) {
      const step = QueryString.parse(urlParams)?.step as string;
      if (step === "destination") {
        return schedule.fromStation;
      } else {
        if (stationsType === "origin") {
          return schedule.toStation;
        } else if (stationsType === "destination") return schedule.fromStation;
      }
    }

    return undefined;
  };

  return (
    <>
      {!hideLastORNearbyStations && (
        <StationsList
          title={recentLastStationsTitle || ""}
          stations={recentLastStations}
          value={textValue}
          onClick={onClick}
          stationsType={stationsType}
          alreadySelectedStationId={isSelectedStationId ? isSelectedStationId : getSelectedOriginStationId()}
          inputValue={inputValue}
        />
      )}
      <StationsList
        title={title || ""}
        stations={allStations}
        value={textValue}
        onClick={onClick}
        stationsType={stationsType}
        alreadySelectedStationId={isSelectedStationId ? isSelectedStationId : getSelectedOriginStationId()}
        inputValue={inputValue}
      />
    </>
  );
};

export default StationsListGrouped;
