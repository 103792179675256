import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import Images from "../../../../Data/Images/Images";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import { IUpdateCardItemMobile } from "../../../../Interfaces/IUpdates";
import colors from "../../../../Assets/Colors/Colors.json";
import fonts from "../../../../Assets/Fonts/Fonts.json";
import AllElements from "../../AllElements";
import BaseElement from "../../BaseElement";
import { IAnyElement } from "../../../../Interfaces/IStaticPage";
import i18n from "../../../../Services/i18n";
import fontSize from "../../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../../Services/DeviceService";

const UpdateCardItemMobile: React.FC<IUpdateCardItemMobile> = (props) => {
  const { content, isLastItem, clickAction, elements } = props;
  const navigate = useNavigate();
  const [_isMoreInfoVisible, _setIsMoreInfoVisible] = useState(false);

  const redirectToPage = (page?: string) => {
    page && navigate(`?page=updates/${page}`);
  };

  const GetUmbracoElement: React.FC<IAnyElement & any> = (props) => {
    switch (props.contentTypeAlias) {
      case "baseElement":
        return (
          <BaseElement
            {...props}
            containerStyle={{
              maxWidth: "76rem",
              width: "80%",
              fontSize: fontSize.Paragraph,
              fontFamily: fonts.FbReformaRegular,
            }}
          />
        );
      case "titleAndDescription":
        return (
          <BaseElement
            titleAndDescription={props}
            isHeaderUnderline={true}
            headerStyle={
              {
                display: "flex",
                fontSize: fontSize.UmbracoElementTitle,
                fontFamily: fonts.FbReformaMedium,
                color: colors.darkBlue,
              } as StyleSheet
            }
            containerStyle={
              {
                maxWidth: "76rem",
                width: "80%",
                fontSize: fontSize.Paragraph,
              } as StyleSheet
            }
          />
        );
      case "link":
        return <BaseElement link={props.link} />;
      default:
        return <AllElements elementName={props.contentTypeAlias} {...props} />;
    }
  };

  return (
    <div style={styles.mainContainer}>
      <div
        style={{
          display: "flex",
        }}
      >
        <img
          src={Images.megaphone}
          style={{
            scale: ".6",
            alignSelf: "flex-start",
            transform: i18n.dir() === "ltr" ? "scaleX(-1)" : ""
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: ".5rem",
          }}
        >
          <h3
            style={{
              color: colors.darkBlue,
              marginBlockStart: "0",
              paddingTop: ".5rem",
            }}
          >
            {content.header}
          </h3>
          <p
            style={{
              fontFamily: fonts.FbReformaMedium,
            }}
            dangerouslySetInnerHTML={{ __html: content.contentdata }}
          ></p>
          {content.linkText && (
            <div onClick={clickAction}>
              <span style={styles.link}>{content.linkText}</span>{" "}
              <span style={styles.link} aria-hidden={true}>
                {">"}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
  },

  updateContent: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    flex: "10",
  },
  header: {
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.UmbracoElementTitle,
    maxWidth: "80%",
    color: colors.darkBlue,
  },
  content: {
    fontFamily: fonts.FbReformaLight,
    fontSize: fontSize.Paragraph,
    // maxWidth: "80%",
    color: colors.nero,
  },
  link: {
    cursor: "pointer",
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.SubTitle,
    color: colors.cyanBold,
  },
  linkContainer: {
    paddingBottom: "2rem",
    display: "flex",
    gap: "1rem",
  },
  line: {
    height: "0.07rem",
    width: "90%",
    backgroundColor: colors.pastelGray,
  },
};

export default UpdateCardItemMobile;
