import { useState } from "react";
import CustomModal from "../Components/CustomModal";
import Images from "../Data/Images/Images";
import colors from "../Assets/Colors/Colors.json";

const converted = {
  backgroundColor: "rgb(249, 186, 77)",
  width: "24rem",
  height: "3.3rem",
  borderRadius: "24rem",
  border: "none",
  fontSize: "1.5rem",
  fontFamily: "OpenSans-Bold, HelveticaNeue-Bold",
  boxShadow:
    "rgba(0, 0, 0, 0.2) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 0px 15px 0px",
  cursor: "pointer",
  marginBottom: "1rem"
}
const ObjectTest = {
  title: "כותרת לבדיקה",
  description: "תיאור הודעה מתפרצת לבדיקת מודל חדש",
  textButton: "לחץ כאן",
  subTextLink: "בפעם אחרת"
}

const TestPageScreen: React.FC = (props) => {
  let [openModal, setOpenModal] = useState(false);
  return (
    <div style={{ minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <button style={converted} onClick={() => setOpenModal(true)}>{"Click On Me"}</button>


      <CustomModal visible={openModal}
        icon={Images.bellIcon}
        {...ObjectTest}
       // isCloseDialog={true}
        showCloseButton={"external"}
       position="bottom"
        dirClosebutton={"start"}
        //disabledClose={true}
        onClose={() => setOpenModal(false)}
        //ignoreBaseProps={true}
        innerStyle={{ backgroundColor: colors.pattensBlueLighter, padding: 0, minHeight: "80vh" }}
        contentStyle={{ padding: 0 }}
      >
        <h1>check is title</h1>
      </CustomModal>
    </div>
  );
};

export default TestPageScreen;
