import { t } from "i18next";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import Images from "../../Data/Images/Images";
import { IHamburger } from "../../Interfaces/IHamburger";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { IsMobile } from "../../Services/DeviceService";
import i18n from "../../Services/i18n";
import useDeviceMode from "../../Utils/useWindowDimensions";
import CustomModal from "../CustomModal";
import LanguageSelectionMobile from "../LanguageSelection/Mobile/LanguageSelectionMobile";
import MenuItemMobile from "../UmbracoElements/MenuItem/MenuItemMobile";

const Hamburger: React.FC<IHamburger> = (props) => {
  const { menuItems, onClose, headerContent } = props;
  const [_menuClicked, _setMenuClicked] = useState(false);
  const [_isLanguageModalOpen, _setIsLanguageModalOpen] = useState(false);
  const [_inputValue, _setInputValue] = useState("");
  const [_isSearchButtonAvailable, _setIsSearchButtonAvailable] =
    useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const closeLanguagesButtonRef = useRef<HTMLImageElement>(null);
  const deviceMode = useDeviceMode();
  const isIos =
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
    deviceMode === "MOBILE";

  const mouseSwipeHandlers = useSwipeable({
    trackMouse: true,
    onSwipedRight: () => (i18n.dir() === "rtl" ? onClose && onClose() : {}),
    onSwipedLeft: () => (i18n.dir() === "ltr" ? onClose && onClose() : {}),
  });

  useEffect(() => {
    _setIsSearchButtonAvailable(_inputValue.length > 1);
  }, [_inputValue]);

  useEffect(() => {
    if (_isLanguageModalOpen) {
      if (closeLanguagesButtonRef.current) {
        closeLanguagesButtonRef.current.focus();
      }
    }
  }, [_isLanguageModalOpen]);

  const handleSearch = () => {
    const searchBoxText = _inputValue;
    if (searchBoxText.length > 0) {
      navigate(`?page=searchResults&term=${searchBoxText}`);
    }
  };

  const redirectToExternalPage = (page?: string) => {
    page && window.open(page, "_blank");
  };
  return (
    <Fragment>
      <CustomModal visible={true} position="start" innerStyle={{
        paddingBottom: 0, paddingTop: "3rem",
        borderRadius: "0",
        borderTopLeftRadius: i18n.dir() === "rtl" ? "3rem" : "",
        borderTopRightRadius: i18n.dir() === "ltr" ? "3rem" : "",
      }} contentStyle={{ padding: 0 }}
        showCloseButton="external"
        onClose={() => onClose && onClose()}>
        <div style={styles.hamburgerContainer}  {...mouseSwipeHandlers} onClick={(e) => {
          e.stopPropagation();
        }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
              width: "100%",
              minHeight: "4rem",
              paddingInline: "2rem"
            }}
          >
            <div style={styles.language}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: ".5rem",
                }}
              >
                <img
                  src={Images.languageSmall}
                  width={`auto`}
                  height={`auto`}
                  alt={``}
                />
                <span
                  style={{
                    fontSize: fontSize.UmbracoElementTitle,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    _setIsLanguageModalOpen(true);
                  }}
                  onKeyPressCapture={() => {
                    _setIsLanguageModalOpen(true);
                  }}
                  tabIndex={!IsMobile() ? 0 : -1}
                  role={`button`}
                >
                  {headerContent &&
                    headerContent.languages.find(
                      (language) => language.languageLocale === i18n.language
                    )?.languageName}
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              paddingInlineStart: "3rem",
              paddingInlineEnd: "3rem",
              flex: 0,
            }}
          >
            <div
              style={{
                ...styles.searchBoxContainer,
                justifyContent: !_isSearchButtonAvailable
                  ? "flex-start"
                  : "space-between",
              }}
            >
              {!_isSearchButtonAvailable && (
                <img src={Images.siteSearch} alt={``} />
              )}
              <input
                onChange={(e) => _setInputValue(e.target.value)}
                type={"text"}
                placeholder={headerContent?.searchPlaceholder}
                style={styles.searchBox}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
              {_isSearchButtonAvailable && (
                <img src={Images.siteSearch} onClick={handleSearch} alt={``} />
              )}
            </div>
          </div>
          <div
            style={{
              ...styles.menuItems,
              gap: isIos ? "1rem" : "1.5rem",
              paddingTop: !isIos ? "2rem" : "unset",
            }}
          >
            <MenuItemMobile
              label={t("Homepage")}
              redirectTo={"homepage"}
              subMenus={[]}
              icon={{
                _url: Images.homepage,
              }}
              isEnabled={"Yes"}
              openType={"Internal"}
              onClick={() => {
                onClose && onClose();
              }}
            />
            {menuItems.map((menuItem, index) => (
              <MenuItemMobile
                key={index}
                {...menuItem}
                onClick={() => {
                  onClose && onClose();
                }}
                totalItems={menuItems.length}
                currentIndex={index}
              />
            ))}
            <div style={styles.socialNetworkContainer}>
              <img
                style={styles.socialIcon}
                tabIndex={!IsMobile() ? 0 : -1}
                role={"link"}
                alt={`go to linkdin`}
                aria-label={t("OpenIsraelRailwaysLinkedin")}
                src={Images.linkedinHeader}
                width="50"
                height={`auto`}
                onClick={() =>
                  redirectToExternalPage(
                    "https://www.linkedin.com/company/isrealrailways/mycompany/"
                  )
                }
              />
              <img
                style={styles.socialIcon}
                tabIndex={!IsMobile() ? 0 : -1}
                role={"link"}
                alt={`go to youtub`}
                aria-label={t("OpenIsraelRailwaysYoutube")}
                src={Images.youtubeHeader}
                width="50"
                height={`auto`}
                onClick={() =>
                  redirectToExternalPage(
                    "https://www.youtube.com/c/IsraelRailsOnline-IsraelRailways"
                  )
                }
              />
              <img
                style={styles.socialIcon}
                tabIndex={!IsMobile() ? 0 : -1}
                role={"link"}
                alt={`go to facebook`}
                aria-label={t("OpenIsraelRailwaysFacebook")}
                src={Images.facebookHeader}
                width="50"
                onClick={() =>
                  redirectToExternalPage("https://www.facebook.com/irail")
                }
              />
              <img
                style={styles.socialIcon}
                tabIndex={!IsMobile() ? 0 : -1}
                role={"link"}
                alt={`go to instegram`}
                aria-label={t("OpenIsraelRailwaysInstagram")}
                src={Images.instagramHeader}
                width="50"
                height={`auto`}
                onClick={() =>
                  redirectToExternalPage("https://www.instagram.com/israrail")
                }
              />
              <img
                style={styles.socialIcon}
                tabIndex={!IsMobile() ? 0 : -1}
                role={"link"}
                alt={`go to tiktok`}
                aria-label={t("OpenIsraelRailwaysTiktok")}
                src={Images.iconTiktokHeader}
                width="50"
                height={`auto`}
                onClick={() =>
                  redirectToExternalPage("https://www.tiktok.com/@isra.rail")
                }
              />
            </div>
          </div>

          <div
            style={{
              ...styles.uxpertContainer,
              flexDirection: i18n.dir() === "rtl" ? "row" : "row-reverse",
            }}
          >
            <img
              src={Images.uxpertLogo}
              width={"60"}
              alt={"Designed By UXPERT"}
              height={`auto`}
            />
            <span style={styles.uxpertText}>Designed By</span>
          </div>
        </div>
      </CustomModal>

      <CustomModal visible={_isLanguageModalOpen} icon={Images.language} position="bottom"
        showCloseButton="external"
        onClose={() => _setIsLanguageModalOpen(false)}>
        {headerContent && (
          <LanguageSelectionMobile
            languages={headerContent.languages || []}
          />
        )}
      </CustomModal>
    </Fragment>
  );
};

const styles: StyleSheet = {
  hamburgerContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    height: "100%",
    boxSizing: "border-box",
  },
  menuItems: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "2rem",
    gap: "3.5rem",
    maxHeight: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    paddingLeft: "3rem",
    paddingRight: "3rem",
    paddingBottom: "3rem",
    flex: 3,
  },
  language: {
    display: "flex",
    justifyContent: "flex-end",
    fontSize: fontSize.UmbracoElementTitle,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaRegular,
    boxSizing: "border-box",
  },
  searchBoxContainer: {
    display: "flex",
    alignItems: "center",
    gap: ".5rem",
    fontFamily: fonts.FbReformaMedium,
    paddingBottom: ".5rem",
    borderBottom: `.1rem solid ${colors.darkBlue}`,
    fontSize: fontSize.Text,
  },
  searchBox: {
    fontSize: fontSize.SubTitle,
    fontFamily: fonts.FbReformaRegular,
  },
  socialNetworkContainer: {
    display: "flex",
    gap: "3rem",
    justifyContent: "center",
    flex: 0,
    paddingTop: "1rem",
  },
  uxpertContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: ".5rem",
    paddingBottom: "1rem",
  },
  uxpertText: {
    fontSize: fontSize.Text,
    fontFamily: "HelveticaNeue",
    fontWeight: "400",
  },
  socialIcon: {
    cursor: "pointer",
  },
};

export default Hamburger;
