import Images from "../../Data/Images/Images";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { ITransitTag } from "../../Interfaces/ITransitTransport";
import font from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";

const TransitTag: React.FC<ITransitTag> = (props) => {
  const {
    transitAgencyColor,
    transitAgencyIcon,
    lineNumber,
  } = props;

  const curAgentIcon = Images[transitAgencyIcon];

  return (
    <div style={{ ...styles.container, borderBottomColor: transitAgencyColor }}>
      <div style={styles.icon}>
        <img src={curAgentIcon} alt="bus" width="18" height="18" />
      </div>
      <div style={styles.lineNumber}>{lineNumber}</div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "1.845rem",
    width: "4.375rem",
    gap: "0.5rem",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    borderRadius: "0.4rem",
    borderBottom: "0.3rem solid",
  },
  icon: { display: "flex" },
  lineNumber: {
    fontSize: fontSize.SubTitle,
    fontFamily: font.FbReformaMedium,

  },
};

export default TransitTag;
