import { IPisTrainCard, IPisTrainCards } from "../../../Interfaces/IPis";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import i18n from "../../../Services/i18n";
import PisTrainCard from "./PisTrainCard";
import { useState } from "react";
import PisTrainCardsHeaders from "./PisTrainCardsHeaders";

const PisTrainCards: React.FC<
  IPisTrainCards & { onCardSelected: (selected: IPisTrainCard) => void }
> = (props) => {
  const { pisTrainCards, onCardSelected } = props;
  const [_selectedCardIndex, _setSelectedCardIndex] = useState(-1);

  return (
    <div
      style={{
        ...styles.container,
        marginInlineStart: i18n.dir() === "rtl" ? "20rem" : "",
        marginInlineEnd: i18n.dir() === "rtl" ? "10rem" : "",
      }}
    >
      <PisTrainCardsHeaders />
      {pisTrainCards.map((pisTrainCard, index) => (
        <PisTrainCard
          key={index}
          {...pisTrainCard}
          onClick={(selected) => {
            _setSelectedCardIndex(index);
            onCardSelected && onCardSelected(selected);
          }}
          isSelected={index === _selectedCardIndex}
        />
      ))}
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
  },
};

export default PisTrainCards;
