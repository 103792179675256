import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { createPortal } from 'react-dom';
import colors from "../../Assets/Colors/Colors.json";
import Images from "../../Data/Images/Images";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { IsMobile } from "../../Services/DeviceService";
import i18n from "../../Services/i18n";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";
import { listTypeLayout } from "./LayoutsModal";
import { ICustomModalProps, ILayout, IWrapperModal } from "./interface";
import "./modal.scss";

const BaseWrapperModal: React.FC<IWrapperModal> = (props) => {
    const { onClose, backgroundColor = colors.modal, icon, disabledClose,
        showCloseButton, children,
        isCloseDialog,
        innerStyle,
        dirClosebutton = "end",
        closeIcon,
        closeStyle,
        floatIconStyle,
        typeStyle = "rounded", position, withoutAnimtionOut, contentStyle,
        responsiveModal,
        label,
        id
    } = props;
    const [_isClose, _setIsClose] = useState(false);
    const [_isCloseDialog, _setIsCloseDialog] = useState(false);
    const modalRef = useRef<HTMLDivElement | null>(null);
    const initialFocusRef = useRef<HTMLElement | null>(null);

    const deviceMode = useDeviceMode();

    useEffect(() => {
        const mainScroll = document.getElementById("mainScroll")!;
        mainScroll?.setAttribute('style', 'overflow: hidden');
        const activeElement = document.activeElement as HTMLElement;
        initialFocusRef.current = activeElement;

        if (modalRef.current) {
            modalRef.current.focus();
        }
        document.addEventListener('keydown', handleTabKey);
        return () => {
            const isModalOpen = document.querySelector("#portal .custom-modal");
            if (initialFocusRef.current) {
                initialFocusRef.current.focus();
            }
            if (!isModalOpen) {
                mainScroll?.setAttribute('style', "overflow: auto")
                document.removeEventListener('keydown', handleTabKey);
            }
        };
    }, []);

    const handleTabKey = (event: KeyboardEvent) => {

        if (event.key === 'Tab') {
            if (!modalRef.current) return;
            const focusableElements = modalRef.current.querySelectorAll(
                'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
            );
            const firstFocusable: any = focusableElements[0];
            const lastFocusable: any = focusableElements[focusableElements.length - 1];

            if (event.shiftKey && document.activeElement === firstFocusable) {
                event.preventDefault();
                lastFocusable.focus();
            } else if (!event.shiftKey && document.activeElement === lastFocusable) {
                event.preventDefault();
                firstFocusable.focus();
            }
        }

        if (event.key === 'Escape') {
            _onClose();
        }
    };

    const _onClose = () => {
        if (isCloseDialog) {
            _setIsCloseDialog(true)
        } else if (!disabledClose) {
            onRequestClose()
        }
    };

    const onRequestClose = (status?: boolean) => {
        !withoutAnimtionOut && _setIsClose(true)
        setTimeout(() => {
            onClose && onClose(status);
        }, !withoutAnimtionOut ? 250 : 0);

    }


    const CloseButton = () => {
        const checkPosition = deviceMode === StyleMode.mobile ? (responsiveModal || position) : position;
        const isPositionInline = checkPosition === 'start' || checkPosition === 'end';

        const translateDir = isPositionInline ? i18n.dir() === "rtl" ?
            checkPosition === "start" ? "-100% 0" : "100% 0"
            : checkPosition === "start" ? "100% 0" : "-100% 0" : "0 -100%"

        const closeIconButton = showCloseButton === "internal" ? Images.closeBG : Images.closeIconWhite

        return <button className={"custom-modal-close"}
            aria-label={t("Close")}
            style={{ ...styles.closeModal, ...dirClosebuttonStyle, translate: showCloseButton === "internal" ? "unset" : translateDir, ...closeStyle, zIndex: "99" }} onClick={_onClose}>
            <img src={closeIcon ? closeIcon : closeIconButton} />
        </button>
    }
    const topInIcon = icon ? { paddingTop: "2.5rem" } : {};
    const dirClosebuttonStyle = dirClosebutton === "end" ? { insetInlineEnd: "0" } : { insetInlineStart: "0" }


    const Icon = typeof icon === "string" ? <div className={"custom-modal-float-icon"} style={{ ...styles.floatIcon, ...floatIconStyle?.container }}>
        <img src={icon} style={floatIconStyle?.img} />
    </div> : icon;

    let classesPositions = (responsiveModal && deviceMode === StyleMode.mobile) ?
        ` ${responsiveModal !== "center" ? `positioning ${responsiveModal}` : ""}` : position ? `positioning ${position}` : "";


    return (<> <div id={id} className={`custom-modal ${_isClose ? "close" : ""}`}
        style={{ ...styles.modal, backgroundColor }}
        onKeyDown={(e) => {
            e.key === "Escape" && _onClose()
        }}
        tabIndex={!IsMobile() ? 0 : -1}
        onClick={_onClose} dir={i18n.dir()} ref={modalRef}>

        <div className={`custom-modal-inner "rounded"  ${classesPositions}`}
            style={{ ...topInIcon, ...innerStyle }}
            onClick={(event) => event.stopPropagation()}
            aria-modal="true"
            role={"dialog"}
        >

            {(showCloseButton && !disabledClose) && <CloseButton />}
            {Icon}
            {label && <div style={{ color: colors.darkBlue, textAlign: 'center', paddingBlock: "1rem" }}>{label}</div>}
            <div className={`custom-modal-content`} style={contentStyle}>
                {children}
            </div>
        </div>
    </div>
        {_isCloseDialog && <CustomModalView visible typeOfLayout={"dialog"}
            disabledClose withoutAnimtionOut backgroundColor={"rgba(0, 0, 0, 0.4)"}
            onSelect={(status) => {
                _setIsCloseDialog(false)
                if (status) {
                    onRequestClose()
                } else {
                    if (modalRef.current) {
                        modalRef.current.focus();
                    }
                }
            }} />}
    </>)
}

const CustomModalView: React.FC<ICustomModalProps> = (props) => {
    const { onClose, typeOfLayout = "default" } = props;
    const SelectedLayout = (listTypeLayout[typeOfLayout] || listTypeLayout.default);
    return (
        <BaseWrapperModal {...props as IWrapperModal}
            onClose={onClose} >
            <SelectedLayout  {...props as ILayout} />
        </BaseWrapperModal>)
        ;
}
export const CustomModal: React.FC<ICustomModalProps> = (props) => {
    const [_isOpen, _setIsOpen] = useState(false);
    const { visible, onClose } = props;

    useEffect(() => {
        _setIsOpen(visible)

    }, [visible]);
    if (!_isOpen) {
        return <></>
    }
    return createPortal(<CustomModalView {...props} onClose={(status) => {
        _setIsOpen(false)
        onClose && onClose(status)
    }} />, document.getElementById("portal")!);


}

const styles: StyleSheet = {
    modal: {
        boxSizing: "border-box",
        position: "fixed",
        backgroundColor: colors.modal,
        zIndex: "9999999",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingInline: "1.4rem",
        inset: 0
    },
    floatIcon: {
        width: "4rem",
        aspectRatio: "1/1",
        boxShadow: "0 5px 11px 0 rgba(0, 0, 0, 0.21)",
        borderRadius: "50%",
        backgroundColor: colors.white,
        position: "absolute",
        left: "50%",
        top: 0,
        translate: "-50% -50% ",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "1rem",
        zIndex: 1
    },
    closeModal: {
        backgroundColor: "unset",
        border: 0,
        position: "absolute",
        cursor: "pointer",
        maxWidth: "3.5rem",
        insetInlineEnd: 0,
        padding: "1rem",
        top: 0,
    },
};

export default CustomModal;