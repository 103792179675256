import React, { useState } from "react";
import { IconContext } from "react-icons";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { ITransitLineAccordion } from "../../Interfaces/ITransitTransport";
import TransitLineAccordionItem from "./TransitLineAccordionItem";

const TransitLineAccordion: React.FC<ITransitLineAccordion> = (props) => {
  const { transitLines,typeOfPage } = props;
  const [_currentIndex, _setCurrentIndex] = useState(-1);

  const toggle = (index: React.SetStateAction<number>) => {
    if (_currentIndex === index) {
      return _setCurrentIndex(null as any);
    }

    _setCurrentIndex(index);
  };

  
  return (
    <IconContext.Provider value={{ color: "#00FFB9", size: "25px" }}>
      <div>
        <div>
          {transitLines.map((item, index: number) => {
            return (
              <TransitLineAccordionItem
                key={index}
                data={item}
                isOpen={_currentIndex}
                onClick={() => toggle(index)}
                currentId={index}
                transitLinesLength={transitLines.length}
                locationDescriptionHe={item[0].destinationRef}
                locationNumber={item[0].destinationRefNumber}
                typeOfPage={typeOfPage}
              />
            );
          })}
        </div>
      </div>
    </IconContext.Provider>
  );
};


export default TransitLineAccordion;
