import axios from "axios";
import { headers } from "./GlobalService";
import { IPisResult } from "../Interfaces/IPis";
import Config from "../Configuration/server";
import Store from "../Redux/Store/Store";
import { setSomethingWentWrong } from "../Redux/Slices/somethingWentWrongSlice";

export const GetPisDataByStationId = async (stationId: number) => {
  const url = `${Config.BASE_URL}/pisdata/api/v1/PisData/GetPisDataByStationId/${stationId}`;
  try {
    const res = await axios(url, { method: "GET", headers });
    return res.data as IPisResult;
  } catch (error) {
    // console.log(error);
    Store.dispatch(setSomethingWentWrong(true));
  }
};
