import { useState } from "react";
import { IMedia } from "../../../../Interfaces/IBaseElement";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import { IsImage } from "../../../../Utils/utils";
import Video from "../../../Video/Video";
import IconActionControl from "./IconActionControl";
import useDeviceMode from "../../../../Utils/useWindowDimensions";
import { StyleMode } from "../../../../Utils/consts";

type IImageCover = {
    media: IMedia,
    videoThumbnail?: IMedia,
    children?: React.ReactNode;
    onClick?: () => void,
    iconNotInMiddle?: boolean
}
const ImageCover: React.FC<IImageCover> = ({ media, videoThumbnail, iconNotInMiddle, children, onClick }) => {
    const [_isVideoPlaying, _setIsVideoPlaying] = useState(false);
    const deviceMode = useDeviceMode();
    const isMedia = IsImage(media._url);
    const iconFloat = deviceMode === StyleMode.desktop || iconNotInMiddle ? styles.iconFloat : styles.iconFloatMiddle
    const iconStyle: any = { ...styles.wapperIcon, ...iconFloat, pointerEvents: isMedia ? "none" : "auto" };
    return (<div style={styles.container} onClick={onClick}>
        {!_isVideoPlaying ? (<>
            <img src={isMedia ? media._url : videoThumbnail?._url} style={{ width: "100%", cursor: onClick ? "pointer" : "default" }} />
            {children}
        </>) : <Video _url={media._url} header={""} />}
        <div style={iconStyle}>
            <IconActionControl isMedia={isMedia} onClick={() => _setIsVideoPlaying(!_isVideoPlaying)} />
        </div>

    </div>);
};
const styles: StyleSheet = {
    container: {
        display: "flex",
        flex: 1,
        position: "relative"
    },
    wapperIcon: {
        aspectRatio: "1/1",
        position: "absolute",
        cursor: "pointer",
        lineHeight: 1,
        zIndex: 1,
    },
    iconFloat: {
        top: "1rem",
        width: "2.5rem",
        insetInlineStart: "1rem",
    },
    iconFloatMiddle: {
        width: "4rem",
        top: "50%",
        left: "50%",
        translate: "-50% -50%",
    },
};

export default ImageCover;
