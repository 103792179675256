import { useState } from "react";
import colors from "../../../../Assets/Colors/Colors.json";
import fonts from "../../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../../Assets/Fonts/FontsSizes.json";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";

type IArticleTitle = {
    title: string,
    onClick?: () => void,
}
const ArticleTitle: React.FC<IArticleTitle> = (props) => {
    const [_isOver, _setIsOver] = useState(false);
    const { title, onClick} = props;

    return (
        <h2
            aria-labelledby={title}
            onMouseOver={() => _setIsOver(true)}
            onMouseLeave={() => _setIsOver(false)}
            style={{
                ...styles.title,
                pointerEvents: onClick ? "auto" : "none",
                textDecoration: (onClick && _isOver) ? "underline" : "",
            }}
            onClick={onClick}
            role={"button"}
            tabIndex={0}
        >
            {title}
        </h2>
    );
};
const styles: StyleSheet = {
    title: {
        fontFamily: fonts.FbReformaMedium,
        fontSize: fontSize.SubTitle,
        lineHeight: 1.5,
        color: colors.darkBlue,
        width: "100%",
        margin: 0,
        cursor: "pointer",
        boxSizing: "border-box",
    }
};

export default ArticleTitle;
