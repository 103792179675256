import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import colors from "../../Assets/Colors/Colors.json";
import Images from "../../Data/Images/Images";
import { IStation, IStationsList } from "../../Interfaces/IStation";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import {
  handleHighlightEqualText,
  replaceRichtextBoldWithFont,
} from "../../Utils/utils";
import fonts from "../../Assets/Fonts/Fonts.json";
import { useLocation, useNavigate } from "react-router-dom";
import { GetSystemParams } from "../../Services/GlobalService";
import { ScheduleState } from "../../Redux/Slices/scheduleSlice";
import { useSelector } from "react-redux";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../Services/DeviceService";
import useDeviceMode from "../../Utils/useWindowDimensions";
import { StyleMode } from "../../Utils/consts";
import { PageState } from "../../Redux/Slices/pageSlice";
import QueryString from "qs";
import { SystemParamsState } from "../../Redux/Slices/systemParamsSlice";
import { TranslationsState } from "../../Redux/Slices/translationsSlice";
import i18n from "../../Services/i18n";

const StationsList: React.FC<IStationsList> = ({
  title,
  stations,
  onClick,
  stationsType,
  alreadySelectedStationId,
  value,
  inputValue,
}) => {
  const { t } = useTranslation();
  const [_overItem, _setOverItem] = useState(-1);
  const [_stationInfoOverKey, _setStationInfoOverKey] = useState(-1);
  const [_blockStations, _setBlockStations] = useState<string[]>([]);
  const [_title, _setTitle] = useState(``);
  const [_step, _setStep] = useState<string | undefined>();

  const currentPage = (
    useSelector((state: any) => state.PageReducer) as PageState
  ).page;

  const systemParams = (
    useSelector((state: any) => state.SystemParamsReducer) as SystemParamsState
  ).systemParams;

  const translations = (
    useSelector((state: any) => state.TranslationsReducer) as TranslationsState
  ).translations;

  const closedStationTranslation =
    translations[i18n.language as "en" | "he" | "ru" | "ar"]["more detailes"];

  const systemParamsDictionary = systemParams.reduce(
    (result: { [key: string]: string }, current) => {
      result[current.key] = current.value;
      return result;
    },
    {}
  );

  const navigate = useNavigate();
  const deviceMode = useDeviceMode();
  const location = useLocation();
  const schedule = useSelector(
    (state: any) => state.ScheduleReducer
  ) as ScheduleState;

  const redirectToStationPage = (stationId: number) => {
    navigate(`?page=stationinfo&stationname=${stationId}`);
  };

  useEffect(() => {
    const urlParams = location.search.split("?")[1] || "";
    const step = QueryString.parse(urlParams)?.step as string;
    if (step) {
      _setStep(step);
    }
  }, [location, currentPage]);

  const getBlockList = async () => {
    let systemParams = await GetSystemParams();
    let blockKey =
      stationsType === `origin` ? `FilterFromStations` : `FilterToStations`;
    let blockList = systemParams.find(
      (systemParam) => systemParam.key === blockKey
    )?.value;
    if (blockList) return blockList;
    else return ``;
  };

  const closeStaionText = `${t(`CloseStation`)}`;

  const checkBlock = (stationId: number) => {
    let block = false;
    _blockStations.map((blockStation) => {
      if (blockStation === stationId + ``) {
        block = true;
      }
    });
    return block;
  };

  const openStationInfoPage = (station: IStation) => {
    window.location.replace(
      `${
        window.location.href.split(`?`)[0]
      }${`?page=stationinfo&stationname=${station.stationId}`}`
    );
  };

  useEffect(() => {
    let isMounted = true;
    (async () => {
      const blockList = await getBlockList();
      if (blockList !== ``) {
        if (isMounted) {
          let list = blockList.split(`,`);
          _setBlockStations(list);
          _setTitle(title);
        }
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [title]);

  return (
    <ul style={styles.listBox}>
      {stations.length !== 0 && (
        <li style={styles.categoryHeader}>
          {_title.length > 0 && (
            <h3 style={styles.categoryTitle} tabIndex={!IsMobile() ? 0 : -1}>
              {title}
            </h3>
          )}
        </li>
      )}
      {stations.map((station, index) => {
        let ifOverItem = _overItem === station.stationId;
        let isDisabled = false;
        if (currentPage.toLowerCase() === "routePlan".toLowerCase()) {
          if (_step) {
            if (_step === "origin") {
              if (alreadySelectedStationId === station.stationId) {
                isDisabled = true;
              } else {
                isDisabled = false;
              }
            }
          }
        }

        let ifAlreadySelectedStationId =
          alreadySelectedStationId !== station.stationId; // && !checkBlock(station.stationId)
        let ifNotCheckBlock = !checkBlock(station.stationId);
        return (
          <div
            key={index}
            style={{
              borderBottom: `1px solid ${colors.platinum}`,
              backgroundColor:
                (isDisabled && ifNotCheckBlock && ifOverItem) ||
                (ifOverItem && ifAlreadySelectedStationId && ifNotCheckBlock)
                  ? colors.pattensBlue
                  : "",
              cursor:
                (ifOverItem && ifAlreadySelectedStationId) || isDisabled
                  ? "pointer"
                  : "",
              display: "flex",
              justifyContent: "space-between",
              gap: ".5rem",
            }}
            onMouseEnter={() => {
              (ifAlreadySelectedStationId || isDisabled) &&
                _setOverItem(station.stationId);
            }}
            onMouseLeave={() => {
              (ifAlreadySelectedStationId || isDisabled) && _setOverItem(-1);
            }}
            onClick={() => {
              ((ifAlreadySelectedStationId &&
                !!!checkBlock(station.stationId)) ||
                isDisabled) &&
                onClick(station, stationsType);
            }}
            onKeyPress={() =>
              ifAlreadySelectedStationId &&
              !!!checkBlock(station.stationId) &&
              onClick(station, stationsType)
            }
          >
            <div
              style={{
                ...styles.listItem,
                gap: deviceMode !== StyleMode.desktop ? "0" : ".5rem",
                flex: 1,
                justifyContent:
                  station.distance === undefined
                    ? deviceMode !== StyleMode.desktop
                      ? "unset"
                      : !checkBlock(station.stationId)
                      ? "space-between"
                      : "unset"
                    : !checkBlock(station.stationId)
                    ? "space-between"
                    : "unset",
                flexDirection:
                  station.distance === undefined
                    ? deviceMode !== StyleMode.desktop
                      ? "column"
                      : "row"
                    : "row",
                alignItems:
                  station.distance === undefined
                    ? deviceMode !== StyleMode.desktop
                      ? "start"
                      : "center"
                    : "center",
                flexWrap: deviceMode !== StyleMode.desktop ? "wrap" : "unset",
              }}
            >
              <span
                tabIndex={!IsMobile() ? 0 : -1}
                style={{
                  ...styles.stationName,
                  justifyContent: "space-between",
                  cursor: `${
                    !!!checkBlock(station.stationId) ? `pointer` : `default`
                  }`,
                  color: isDisabled
                    ? colors.black
                    : ifAlreadySelectedStationId
                    ? !ifNotCheckBlock
                      ? colors.gray
                      : colors.black
                    : colors.gray,
                  fontFamily:
                    deviceMode === StyleMode.desktop
                      ? fonts.FbReformaLight
                      : fonts.FbReformaRegular,
                  fontSize:
                    deviceMode === StyleMode.desktop
                      ? fontSize.FooterItem
                      : "1.2rem",
                }}
                role={"button"}
                dangerouslySetInnerHTML={{
                  __html: `${replaceRichtextBoldWithFont(
                    handleHighlightEqualText(
                      station,
                      value,
                      inputValue,
                      stationsType === "origin"
                        ? schedule.fromStation
                        : schedule.toStation
                    )
                  )} ${
                    !ifAlreadySelectedStationId
                      ? stationsType === "destination"
                        ? `(${t("Origin-station")})`
                        : `(${t("Destination-station")})`
                      : ""
                  }${checkBlock(station.stationId) ? closeStaionText : ``}`,
                }}
                aria-label={`${
                  station.distance
                    ? `${station.stationName} ` +
                      `${station.distance.toFixed(1)} ${t("Km")}` +
                      `${t(`OptionNumberOutOfNumber`, {
                        currentIndex: index + 1,
                        total: stations.length,
                      })}`
                    : `${station.stationName} ` +
                      `${t(`OptionNumberOutOfNumber`, {
                        currentIndex: index + 1,
                        total: stations.length,
                      })}`
                }`}
              />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {systemParamsDictionary["closedStationInfo"] === "true" && (
                  <span
                    tabIndex={0}
                    role="link"
                    style={{
                      ...styles.stationName,
                      fontFamily: fonts.FbReformaLight,
                      color: colors.darkBlue,
                      textDecoration: "underline",
                    }}
                    className="closedStationInfo"
                    onClick={() => openStationInfoPage(station)}
                    onKeyPress={(e) => {
                      e.stopPropagation();
                      // redirectToStationPage(station.stationId);
                      openStationInfoPage(station);
                    }}
                  >
                    {systemParamsDictionary["closedStationInfo"] === "true" &&
                      checkBlock(station.stationId) &&
                      deviceMode === StyleMode.desktop &&
                      `-`}
                    {checkBlock(station.stationId)
                      ? `${closedStationTranslation}`
                      : ""}
                  </span>
                )}
              </div>
            </div>
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {station.distance !== undefined && (
                  <div style={styles.distance}>
                    <div
                      style={{
                        display: "flex",
                        gap: ".25rem",
                      }}
                    >
                      <img
                        src={
                          station.distance !== undefined
                            ? station.distance.toFixed(1) === "0.0"
                              ? Images.origin
                              : Images.direction
                            : Images.direction
                        }
                        width={`auto`}
                        height={`auto`}
                        alt={``}
                      />
                      {station.distance !== undefined && (
                        <strong style={{ alignSelf: "flex-end" }}>
                          {station.distance.toFixed(1)}
                          {` ${t("Km")}`}
                        </strong>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <img
                onMouseEnter={() => {
                  _setStationInfoOverKey(station.stationId);
                }}
                onMouseLeave={() => {
                  _setStationInfoOverKey(-1);
                }}
                onKeyPress={(e) => {
                  e.stopPropagation();
                  // redirectToStationPage(station.stationId);
                  openStationInfoPage(station);
                }}
                role={"link"}
                tabIndex={!IsMobile() ? 0 : -1}
                alt={`${t(`RedirectToStationInfoPage`, {
                  name: station.stationName,
                })}`}
                src={Images.infoCircledIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  openStationInfoPage(station);
                  // redirectToStationPage(station.stationId);
                }}
                style={{
                  cursor: `pointer`,

                  filter: `brightness(${
                    _stationInfoOverKey === station.stationId ? ".2" : ""
                  })`,
                }}
                width={`auto`}
                height={`auto`}
              />
            </>
          </div>
        );
      })}
    </ul>
  );
};

const styles: StyleSheet = {
  listItem: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    gap: ".5rem",
  },
  categoryTitle: {
    fontSize: fontSize.Paragraph,
    fontFamily: fonts.FbReformaMedium,
  },
  categoryHeader: {
    display: "flex",
    alignItems: "flex-start",
  },
  distance: {
    display: "flex",
    justifyContent: "space-between",
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.Text,
    alignItems: "center",
    gap: "1rem",
  },
  stationName: {
    alignItems: "center",
    lineHeight: "3rem",
    whiteSpace: "pre-wrap",
    fontFamily: fonts.FbReformaLight,
    fontSize: fontSize.FooterItem,
    color: colors.nero,
  },
  listBox: {
    paddingInlineStart: "0",
  },
};

export default StationsList;
