interface ITag {
  name: string;
  active?: boolean;
  onClick?:()=> void
}
const TagName: React.FC<ITag> = (props) => {
  const { name, active,onClick  } = props;
  return (
    <div
    onClick={onClick}
      className={`tag`}
      style={{
        cursor:"pointer",
        color: "var(--darkBlue)",
        border: "1px solid",
        borderRadius: "2rem",
        paddingInline: "1rem",
        fontSize: "0.8rem",
        backgroundColor: active ? "var(--pattensBlue)" : "white",
      }}
    >
      {name}
    </div>
  );
};

export default TagName;
