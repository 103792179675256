import { useState, useEffect } from "react";
import { IRoutePlanBar } from "../../Interfaces/IRoutePlanBar";
import { IStation } from "../../Interfaces/IStation";
import { ITab } from "../../Interfaces/ITabs";
import { ScheduleStateBuild } from "../../Redux/Slices/scheduleSlice";
import { GetSortedStations } from "../../Services/SearchStationsAutoCompleteService";
import { GetStationsDictionary, IDictStation } from "../../Utils/helpers";
import Tabs from "../Tabs/Tabs";
import { v4 as uuidv4 } from "uuid";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import RoutePlanBarProps from "./RoutePlanBarProps";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { FormatFullDate } from "../../Services/DateService";
import Images from "../../Data/Images/Images";
import { MdDelete } from "react-icons/md";

interface IRoutePlanBarMulti extends IRoutePlanBar {
  onSearchMulti?: (list?: any[]) => void;
  onTabClick?: (key: string) => void;
  multisearch?: boolean;
}
const RoutePlanMultiProps: React.FC<IRoutePlanBarMulti> = (props) => {
  const { multisearch = false } = props;
  const [_tabs, _setTabs] = useState<ITab[]>([]);
  const [_tabSelected, _setTabSelected] = useState<string>("");
  const [_schedule, _setSchedule] = useState<ScheduleStateBuild>({});
  const [_schedules, _setSchedules] = useState<ScheduleStateBuild[]>([]);
  const [_stations, _setStations] = useState<IStation[]>([]);
  const [_station, _setStation] = useState<IStation[]>([]);
  const [_stationKey, _setStationKey] = useState<IDictStation>({});

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    SearchBarParams();
  }, [_stationKey]);

  const SearchBarParams = () => {
    let url = new URLSearchParams(window.location.search);
    let params: any = {
      fromStation: url.get("fromStation")?.split(","),
      toStation: url.get("toStation")?.split(","),
      date: url.get("date")?.split(","),
      hours: url.get("hours")?.split(","),
      minutes: url.get("minutes")?.split(","),
      scheduleType: url.get("scheduleType")?.split(","),
    };
    let _schedules: ScheduleStateBuild[] = [];
    for (let index = 0; index < params.fromStation.length; index++) {
      let obj = {
        fromStation: params.fromStation[index],
        toStation: params.toStation[index],
        date: params.date[index],
        hours: params.hours[index],
        minutes: params.minutes[index],
        scheduleType: Number(params.scheduleType[index]),
        id:uuidv4()
      };
      _schedules.push(obj);
    }
    SetTab(_schedules);

    _setSchedules(_schedules);
  };
  const init = () => {
    let res = GetSortedStations();
    let resKey = GetStationsDictionary(res);
    _setStationKey(resKey);
    _setStation(res);
  };

  const SetTab = (schedules: ScheduleStateBuild[]) => {
    let Tabs: ITab[] = [];
    for (let index = 0; index < schedules.length; index++) {
      const element = schedules[index];

      if (element.fromStation && element.toStation) {
        Tabs.push({
          id: `${element.fromStation}-${element.toStation}`,
          name: `${_stationKey[element.fromStation]?.stationName}-${
            _stationKey[element.toStation]?.stationName
          }`,
          isSelected: index === 0,
        });
      }
      index === 0 &&
        _setTabSelected(`${element.fromStation}-${element.toStation}`);
    }

    _setTabs(Tabs);
  };
  return (
    <>
   
        <RoutePlanBarProps
          key={_schedule.id ? _schedule.id : 0}
          multisearch={multisearch}
          data={_schedule}
          onSelect={(data) => {
            if (props.multisearch) {
              if (!_schedule.id) {
                _setSchedules([
                  ..._schedules,
                  {
                    id: uuidv4(),
                    ...data,
                  },
                ]);
              } else {
                let indexOf = _schedules.findIndex((item) => item.id === data.id)
                _schedules[indexOf] = data
                _setSchedules(_schedules);
              }
              _setSchedule({})
            } else {
            }
          }}
        />
     
      {_schedules && _schedules.length > 0 && <div style={{ ...styles.mainWrapper, display: "flex", gap: "1rem" }}>
        {_schedules.map((schedule, index) => (
          <div
            key={schedule.id}
            style={{
              display: "inline-flex",
              border: "0.1rem solid rgb(205, 205, 205)",
              backgroundColor: "rgb(255, 255, 255)",
              borderRadius: "2rem",
              gap: "1rem",
              overflow: "hidden",
              cursor: "pointer"
              
            }}
            onClick={() => {
              _setSchedule({ ...schedule })
            }}
          >
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
                padding: "0.5rem 0.7rem",
                gap: "1rem",
              }}
            >
              {schedule.fromStation && schedule.toStation && (
                <div style={{ display: "inline-flex", gap: "0.5rem" }}>
                  <img src={Images.origin} style={styles.destinationIcon} />
                  {_stationKey[schedule.fromStation]?.stationName}
                  <img
                    src={Images.destination}
                    style={styles.destinationIcon}
                  />
                  {_stationKey[schedule.toStation]?.stationName}
                </div>
              )}
              <div style={{ display: "inline-flex", gap: "0.5rem" }}>
                <img src={Images.calendar} style={styles.datePickerIcon} />
                {FormatFullDate(schedule)}
              </div>
            </div>
            <div
              style={{
                minWidth: "3rem",
                background: colors.red,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                cursor: "pointer",

              }}
              onClick={(e) => {
                e.stopPropagation()
                _schedules.splice(index,1)
                _setSchedules([..._schedules])
              }}
            >
              <MdDelete />
            </div>
          </div>
        ))}
        <button
          style={{
            ...styles.searchTrainsButton,
            width: "8rem",
            aspectRatio: "unset",
          }}
          onClick={async () => {
            SetTab(_schedules);

            props.onSearchMulti && props.onSearchMulti(_schedules);
            // _setAllTravels(AllTravels);
          }}
        >
          חיפוש
        </button>
      </div>}
    </>
  );
};

const styles: StyleSheet = {
  mainWrapper: {
    display: "flex",
    gap: "2rem",
    margin: "2rem 4rem",
    flexWrap: "wrap",
    position: "relative",
  },
  searchTrainsButton: {
    backgroundColor: colors.koromiko,
    fontFamily: fonts.FbReformaMedium,
    width: "16rem",
    height: "2.5rem",
    borderRadius: "24rem",
    border: "none",
    fontSize: fontSize.Text,
    color: colors.nero,
  },
};
export default RoutePlanMultiProps;
