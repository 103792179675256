import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { ITravelMessages } from "../../../Interfaces/ITravel";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import Images from "../../../Data/Images/Images";
import { useState } from "react";
import TravelMessage from "./TravelMessage";
import { t } from "i18next";

const TravelMessages: React.FC<ITravelMessages> = (props) => {
  const { travelMessages } = props;
  const [_isOpen, _setIsOpen] = useState(false);

  return (
    <div
      style={{
        ...styles.container,
        backgroundColor:
          travelMessages.length === 1 && travelMessages[0].sevirity === 1
            ? "#00ABE8"
            : colors.appleRed,
      }}
      onClick={(e) => {
        e.stopPropagation();
        _setIsOpen(!_isOpen);
      }}
    >
      <div
        style={{
          display: "flex",
          gap: ".5rem",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span
          style={{
            ...styles.travelMessagesCount,
            color:
              travelMessages.length === 1 && travelMessages[0].sevirity === 1
                ? colors.black
                : colors.white,
          }}
        >{`${t(
          travelMessages.length === 1 ? "OneTravelMessage" : "TravelMessages",
          {
            count: travelMessages.length,
          }
        )}`}</span>
        {_isOpen ? (
          <img
            src={
              travelMessages.length === 1 && travelMessages[0].sevirity === 1
                ? Images.arrowUpBlack
                : Images.arrowUpWhite
            }
          />
        ) : (
          <img
            src={
              travelMessages.length === 1 && travelMessages[0].sevirity === 1
                ? Images.arrowDownBlack
                : Images.arrowDownWhite
            }
          />
        )}
      </div>
      {_isOpen && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              width: "90%",
              height: "1px",
              backgroundColor: colors.gray,
            }}
          ></div>
        </div>
      )}
      {_isOpen && (
        <ul style={styles.travelMessagesContainer}>
          {travelMessages.map((travelMessage, index) => (
            <TravelMessage
              {...travelMessage}
              isLastMessage={index === travelMessages.length - 1}
              key={index}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    backgroundColor: colors.appleRed,
    borderBottomRightRadius: "1rem",
    borderBottomLeftRadius: "1rem",
    display: "flex",
    flexDirection: "column",
  },
  travelMessagesCount: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: colors.white,
    paddingTop: ".5rem",
    paddingBottom: ".5rem",
    fontFamily: fonts.FbReformaRegular,
  },
  travelMessagesContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    paddingInlineStart: "0",
  },
};

export default TravelMessages;
