import { t } from "i18next";
import Config from "../Configuration/server";
import { IBreadCrumbItem } from "../Interfaces/IBreadcrumb";
import { IQaAndIcon } from "../Interfaces/IQaAndIcon";
import { IHeaderContent, IQuestionAndAnswer } from "../Interfaces/IUmbraco";
import i18n from "../Services/i18n";
import { HESpecialPages, SpecialPages } from "./consts";
import { Languages } from "./getLanguage";
import { RemoveHtmlTags } from "./helpers";

    export const ChangeHrefLang = (baseUrl: string) => {
    for(let i=0;i<Languages.length;i++){
      let link = document.head.getElementsByClassName(`${Languages[i]}flang`)
      link[0]?.setAttribute('href',`${Config.DOMAIN}${baseUrl}&lan=${Languages[i]}`)
    }
    let canonical = document.head.getElementsByClassName(`canonicalFlang`)
    canonical[0]?.setAttribute('href',`${Config.DOMAIN}${baseUrl}&lan=${i18n.language}`)
  };
  
  export const CreateBreadcrumbSchema=(BreadCrumbItem:IBreadCrumbItem[]=[])=>{
    const script = document.createElement(`script`)
    script.async=true
    script.id= `BreadCrumbSchema`
    script.type = `application/ld+json`
    let position=2;
    let initScript = 
    `{
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [`+
      `{"@type": "ListItem",
        "position": 1,
        "name": "${t("Main-page-breadcrumb")}",
        "item": "${Config.DOMAIN}?lan=${i18n.language}"}`
  
      for(let i=0;i<BreadCrumbItem.length;i++){
        if(BreadCrumbItem[i].text.toLowerCase()!== HESpecialPages.toLocaleLowerCase()
            && BreadCrumbItem[i].text.toLowerCase()!== SpecialPages.toLowerCase())
        {
            initScript+=
            `,{
            "@type": "ListItem",
            "position": ${position},
            "name": "${BreadCrumbItem[i].text}",
            "item": "${Config.DOMAIN}?page=${BreadCrumbItem[i].link}&lan=${i18n.language}"}`
            
            position++
        }
      
      }
      initScript+=`]}`
    script.append(initScript)
    let currentBreadcrumb = document.getElementById('BreadCrumbSchema')  
    if(currentBreadcrumb){
      document.head.removeChild(currentBreadcrumb)
    }
    if(BreadCrumbItem.length>0){
      document.head.appendChild(script)
    }
  };
  
  export const CreateQuestionAndAnswerSchema=(QuestionsAndAnswers?:IQuestionAndAnswer[])=>{
    const script = document.createElement(`script`)
    if(QuestionsAndAnswers){
      
      script.async=true
      script.id= `QuestionAndAnswerSchema`
      script.type = `application/ld+json`
      let initScript = 
      `
        {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
      `
      for(let i = 0; i< QuestionsAndAnswers.length;i++){
        initScript+=
        `
        {
          "@type": "Question",
          "name": "${RemoveHtmlTags(QuestionsAndAnswers[i].question)}",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "${RemoveHtmlTags(QuestionsAndAnswers[i].answer)}"
          }
        }${i<QuestionsAndAnswers.length-1?`,`:``}
        `
      }
      initScript+=`]}`
      script.append(initScript)
    }
    let currentQuestionsAndAnswers = document.getElementById('QuestionAndAnswerSchema')  
    if(currentQuestionsAndAnswers){
      document.head.removeChild(currentQuestionsAndAnswers)
    }
    if(QuestionsAndAnswers&&QuestionsAndAnswers.length>0){
      document.head.appendChild(script)
    }
   
  };

  export const CreateQaAndIconSchema=(QaAndIcons?:IQaAndIcon[])=>{
    const script = document.createElement(`script`)
    if(QaAndIcons){
      
      script.async=true
      script.id= `QaAndIconSchema`
      script.type = `application/ld+json`
      let initScript = 
      `
        {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
      `
      for(let i = 0; i< QaAndIcons.length;i++){
        initScript+=
        `
        {
          "@type": "Question",
          "name": "${QaAndIcons[i].title}",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "${QaAndIcons[i].description}"
          }
        }${i<QaAndIcons.length-1?`,`:``}
        `
      }
      initScript+=`]}`
      script.append(initScript)
    }
    let currentQaAndIcons = document.getElementById('QaAndIconSchema')  
    if(currentQaAndIcons){
      document.head.removeChild(currentQaAndIcons)
    }
    if(QaAndIcons&&QaAndIcons.length>0){
      document.head.appendChild(script)
    }
   
  };

  export const CreateLogoSchema=(data?:IHeaderContent )=>{
    const script = document.createElement(`script`)
    script.async=true
    script.id= `LogoSchema`
    script.type = `application/ld+json`
    let initScript = 
      `
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "${Config.DOMAIN}",
          "logo": "${data?.logo._url}"
        }
      `
    script.append(initScript)
    let currentLogo = document.getElementById('LogoSchema')  
    if(currentLogo){
        document.head.removeChild(currentLogo)  
    }
    document.head.appendChild(script)
   
  };
  