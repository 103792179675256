import React, { useEffect } from "react";
import colors from "../../../Assets/Colors/Colors.json";
import { ICurrentService } from "../../../Interfaces/IForms";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import CustomAttachedFiles from "../CustomFileds/CustomAttachedFiles";
import CustomDatePicker from "../CustomFileds/CustomDatePicker";
import CustomInput from "../CustomFileds/CustomInput";
import CustomTextArea from "../CustomFileds/CustomTextArea";

const FormClaimTicketPurchaseScreen: React.FC<ICurrentService> = (props) => {
  const { formik } = props;

  useEffect(() => {
    document
      .getElementById("FormClaimTicketPurchaseScreen_container")
      ?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, []);

  return (
    <div style={styles.container} id="FormClaimTicketPurchaseScreen_container">
      <div style={styles.form}>
        <CustomInput
          label="מספר תעודת זהות"
          name="FormClaimTicketPurchaseScreen.userId"
          type="number"
          value={formik.values.FormClaimTicketPurchaseScreen.userId}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          errors={formik.errors.FormClaimTicketPurchaseScreen?.userId}
          touched={formik.touched.FormClaimTicketPurchaseScreen?.userId+''}
          placeholder="הזן מספר תעודת זהות"
        />
        <CustomInput
          label="מספר כרטיס רב קו"
          name="FormClaimTicketPurchaseScreen.ravKavCardNumber"
          type="number"
          value={formik.values.FormClaimTicketPurchaseScreen.ravKavCardNumber}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          errors={formik.errors.FormClaimTicketPurchaseScreen?.ravKavCardNumber}
          touched={
            formik.touched.FormClaimTicketPurchaseScreen?.ravKavCardNumber+''
          }
          placeholder="הזן מספר כרטיס רב קו"
        />
        <div style={styles.finalElementsContainer}>
          <CustomDatePicker
            label="תאריך"
            name="FormClaimTicketPurchaseScreen.eventDateTime"
            type="date"
            value={formik.values.FormClaimTicketPurchaseScreen.eventDateTime}
            onBlur={formik.handleBlur}
            onChange={(date: Date) => {
              formik.setFieldValue(
                "FormClaimTicketPurchaseScreen.eventDateTime",
                date
              );
            }}
            errors={formik.errors.FormClaimTicketPurchaseScreen?.eventDateTime}
            touched={
              formik.touched.FormClaimTicketPurchaseScreen?.eventDateTime+''
            }
            placeholder="aa"
            showTime={true}
          />

          <CustomTextArea
            label="תיאור האירוע"
            name="FormClaimTicketPurchaseScreen.comments"
            type="textarea"
            value={formik.values.FormClaimTicketPurchaseScreen.comments}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            errors={formik.errors.FormClaimTicketPurchaseScreen?.comments}
            touched={formik.touched.FormClaimTicketPurchaseScreen?.comments+''}
            placeholder="הזן תיאור האירוע"
          />
          <CustomAttachedFiles
            label="צירוף מסמכים
              (ניתן להוסיף יותר מקובץ אחד)"
            name="FormClaimTicketPurchaseScreen.attachFiles"
            value={formik.values.FormClaimTicketPurchaseScreen.attachFiles}
            onBlur={formik.handleBlur}
            onChange={(files: File[] | null) => {
              formik.setFieldValue(
                "FormClaimTicketPurchaseScreen.attachFiles",
                files
              );
            }}
            errors={formik.errors.FormClaimTicketPurchaseScreen?.attachFiles}
            touched={formik.touched.FormClaimTicketPurchaseScreen?.attachFiles+''}
            type="file"
            placeholder="צירוף קובץ"
          />
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    margin: "auto",
    paddingBottom: "2rem",
  },
  finalElementsContainer: {
    display: "flex",
    flexWrap: "wrap",
    columnGap: "2rem",
  },
  form: { display: "flex", flexWrap: "wrap", columnGap: "2rem" },
  button: {
    width: "100%",
    padding: "0.65rem 0.5rem",
    fontSize: "1rem",
    color: colors.white,
    border: "2px solid",
    backgroundColor: colors.blue,
    borderRadius: "10px",
    outline: "none",
    margin: "1rem 0",
    fontWeight: "bold",
    cursor: "pointer",
  },
};

export default FormClaimTicketPurchaseScreen;
