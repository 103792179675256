import { useEffect } from "react";
import TopBanner from "../Components/TopBanner";
import AllElements from "../Components/UmbracoElements/AllElements";
import { IStaticPage } from "../Interfaces/IStaticPage";
import { ChangeTitle, UpdateSeo } from "../Utils/consts";

const ContactUsNew: React.FC<IStaticPage> = (props) => {

  useEffect(() => {
    ChangeTitle(props?.title?.text);
    UpdateSeo(
      props.seoTitle,
      props.seo,
      props.seoSocialNetworksTitle,
      props.seoSocialNetworksDescription,
      props.seoSocialNetworksImage
    );
  }, []);

  return (
    <div className="contact-us-new">
      <TopBanner
        cover={props.coverImage._url}
        title={props.title.text}
        inTitle={props?.subTitle}
        breakLine={true}
      />
      {props.elements.map((item: any, index: number) => (
        <AllElements
          key={index}
          elementName={item.contentTypeAlias}
          {...item}
        />
      ))}
    </div>
  );
};

export default ContactUsNew;
