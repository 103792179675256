const Images: { [key: string]: any } = {
  logo: require("../../Assets/Logo/logo-rail.png"),
  logoLight: require("../../Assets/Logo/logo-rail-light.png"),

  switch: require("../../Assets/Icons/switch.svg").default,
  change: require("../../Assets/Icons/change.svg").default,
  handicap: require("../../Assets/Icons/handicap.svg").default,
  handicap2: require("../../Assets/Icons/handicap2.svg").default,
  airPollution: require("../../Assets/Icons/airPollution.svg").default,
  homepage: require("../../Assets/Icons/homepage.svg").default,
  train: require("../../Assets/Icons/train.svg").default,
  location: require("../../Assets/Icons/location.svg").default,
  arrow: require("../../Assets/Icons/arrowDown.svg").default,
  origin: require("../../Assets/Icons/origin.svg").default,
  destination: require("../../Assets/Icons/destination.svg").default,
  originWhite: require("../../Assets/Icons/origin_white.svg").default,
  destinationWhite: require("../../Assets/Icons/destination_white.svg").default,
  info: require("../../Assets/Icons/info.svg").default,
  backButton: require("../../Assets/Icons/back.svg").default,
  closeButton: require("../../Assets/Icons/close.svg").default,
  wheelSelected: require("../../Assets/Icons/wheel_selected.svg").default,
  wheelLongSelected: require("../../Assets/Icons/wheel_long_selected.svg")
    .default,
  backButtonMob: require("../../Assets/Icons/back_mobile.svg").default,
  closeButtonMob: require("../../Assets/Icons/close_mobile.svg").default,
  close: require("../../Assets/Icons/close.svg").default,
  food: require("../../Assets/Icons/food.svg").default,
  info_mobile: require("../../Assets/Icons/info_mobile.svg").default,
  outofhome: require("../../Assets/Icons/outofhome.svg").default,
  services: require("../../Assets/Icons/services.svg").default,
  shoping: require("../../Assets/Icons/shoping.svg").default,
  tickets: require("../../Assets/Icons/tickets.svg").default,
  trip: require("../../Assets/Icons/trip.svg").default,
  contactUs: require("../../Assets/Icons/contactUs.svg").default,
  contactUsClock: require("../../Assets/Icons/clock.svg").default,

  plusasstar: require("../../Assets/Icons/plusasstar.svg").default,
  atm: require("../../Assets/Icons/ATM_stand.svg").default,
  bicycleFacilities: require("../../Assets/Icons/bicycle_facilities.svg")
    .default,
  convenienStore: require("../../Assets/Icons/convenien_store.svg").default,
  drinksVendingMachine: require("../../Assets/Icons/drinks_vending_machine.svg")
    .default,
  foodDrink: require("../../Assets/Icons/food_drink.svg").default,
  handicapFill: require("../../Assets/Icons/handicap-fill.svg").default,
  intelligencePosition: require("../../Assets/Icons/intelligence_position.svg")
    .default,
  libraryStation: require("../../Assets/Icons/library_station.svg").default,
  location2: require("../../Assets/Icons/location-2.svg").default,
  lockersStorage: require("../../Assets/Icons/lockers_storage.svg").default,
  lostFound: require("../../Assets/Icons/lost_found.svg").default,
  megaphone: require("../../Assets/Icons/megaphone.svg").default,
  note: require("../../Assets/Icons/note.svg").default,
  parking: require("../../Assets/Icons/parking.svg").default,
  payPhone: require("../../Assets/Icons/pay_phone.svg").default,
  playingStation: require("../../Assets/Icons/playing_station.svg").default,
  publicToilets: require("../../Assets/Icons/public_toilets.svg").default,
  ticketMachines: require("../../Assets/Icons/ticket_machines.svg").default,
  bicycle_facilities: require("../../Assets/Icons/bicycle_facilities.svg")
    .default,
  army: require("../../Assets/Icons/army.svg").default,
  share: require("../../Assets/Icons/share.png"),
  plane: require("../../Assets/Icons/plane.png"),
  taxi: require("../../Assets/Icons/taxi.svg").default,
  transportation: require("../../Assets/Icons/transportation.svg").default,
  refresh: require("../../Assets/Icons/refresh.svg").default,
  calendar: require("../../Assets/Icons/calendar.svg").default,
  play: require("../../Assets/Icons/play.svg").default,
  playWhite: require("../../Assets/Icons/play_white.svg").default,
  directorateArrow: require("../../Assets/Icons/directorateArrow.svg").default,
  closeBGBlue:
    require("../../Assets/Icons/close_no_background_directorate_modal.svg")
      .default,
  closeBG: require("../../Assets/Icons/close_no_background.svg").default,
  bullet: require("../..//Assets/Icons/bullet.svg").default,
  search: require("../../Assets/Icons/site_search.svg").default,
  searchGray: require("../../Assets/Icons/site_search_gray.svg").default,
  switchMob: require("../../Assets/Icons/switch_mobile.svg").default,
  hamburger: require("../../Assets/Icons/hamburger.svg").default,
  approve: require("../../Assets/Icons/approve.svg").default,
  fromTo: require("../../Assets/Icons/from_to.svg").default,
  dots_horizontal: require("../../Assets/Icons/dots_horizontal.svg").default,
  favorite: require("../../Assets//Icons/star.svg").default,
  favoriteFilled: require("../../Assets//Icons/star_blue.svg").default,
  noTrains: require("../../Assets/Icons/no_trains.svg").default,
  notFound: require("../../Assets/Images/notFoundStations.svg").default,
  arrowOver: require("../../Assets/Icons/directorateArrowOver.svg").default,
  galleryArrow: require("../../Assets/Icons/gallery_arrow.svg").default,
  galleryArrowLeft: require("../../Assets/Icons/gallery_arrow_left.svg").default,
  audioEnd: require("../../Assets/Icons/audio_end.svg").default,
  audio30SecondsNext: require("../../Assets/Icons/audio_30_seconds_next.svg")
    .default,
  audioPlay: require("../../Assets/Icons/audio_play.svg").default,
  audioPlay_white: require("../../Assets/Icons/audio_play_white.svg").default,
  audioPause: require("../../Assets/Icons/audio_pause.png").default,
  audio10SecondsBack: require("../../Assets/Icons/audio_10_seconds_back.svg")
    .default,
  bulletBlack: require("../../Assets/Icons/bullet_black.png"),
  bulletWhite: require("../../Assets/Icons/bullet_white.png"),
  destinationBlack: require("../../Assets/Icons/destination_black.png"),
  audioStart: require("../../Assets/Icons/audio_start.svg").default,
  playbackRate: require("../../Assets/Icons/audio_playbackRate.svg").default,
  closeBlue: require("../../Assets/Icons/close_blue.svg").default,
  cancel: require("../../Assets/Icons/cancel.svg").default,
  plus: require("../../Assets/Icons/plus.svg").default,
  pinus: require("../../Assets/Icons/minus.svg").default,
  galleryArrowDown: require("../../Assets/Icons/gallery_arrow_down.svg")
    .default,
  galleryArrowUp: require("../../Assets/Icons/gallery_arrow_up.svg").default,
  moreActionsCircle: require("../../Assets/Icons/more_actions_circle.svg")
    .default,
  moreActionsDots: require("../../Assets/Icons/more_actions_dots.svg").default,
  railLogoFormBackGround: require("../../Assets/Icons/rails_logo.svg").default,
  // PNG
  pdfIcon2: require("../../Assets/Icons/pdf-icon24.png"),
  zipIcon: require("../../Assets/Icons/zip-icon.svg").default,
  manIcon: require("../../Assets/Icons/man_icon.png"),
  fileIcon: require("../../Assets/Icons/attach_file_icon.png"),
  audioPauseIcon: require("../../Assets/Icons/audio_pause.png"),
  audioShareIcon: require("../../Assets/Icons/audio_share.png"),
  misraIcon: require("../../Assets/Icons/misra.png"),
  infoIcon: require("../../Assets/Icons/info.png"),
  michrazIcon: require("../../Assets/Icons/michraz.png"),
  documentIcon: require("../../Assets/Icons/document.png"),
  bellIcon: require("../../Assets/Icons/bell.png"),
  bellBigIcon: require("../../Assets/Icons/bell_big.png"),
  coverImage: require("../../Assets/Backgrounds/rail.png"),
  coverCloseImage: require("../../Assets/Backgrounds/rail-close.png"),
  podcast: require("../../Assets/Images/Podcast.png"),

  audioPauseSvg: require("../../Assets/Icons/audio_pause.svg").default,
  audioPauseSvg_white: require("../../Assets/Icons/audio_pause_white.svg")
    .default,
  audioPrevCircle: require("../../Assets/Icons/audio_prev_circle.svg").default,
  audioNextCircle: require("../../Assets/Icons/audio_next_circle.svg").default,
  arrowDown: require("../../Assets/Icons/arrowDown.svg").default,
  arrowDownGray: require("../../Assets/Icons/arrowDownGray.svg").default,
  arrowUp: require("../../Assets/Icons/arrowUp.svg").default,
  arrowUpWhite: require("../../Assets/Icons/arrowUpWhite.svg").default,
  arrowDownWhite: require("../../Assets/Icons/arrowDownWhite.svg").default,
  arrowUpBlack: require("../../Assets/Icons/arrowUpBlack.svg").default,
  arrowDownBlack: require("../../Assets/Icons/arrowDownBlack.svg").default,
  arrowSide: require("../../Assets/Icons/arrowSide.svg").default,
  cookie: require("../../Assets/Icons/cookie.svg").default,
  settings: require("../../Assets/Icons/settings.svg").default,
  siteSearch: require("../../Assets/Icons/site_search.svg").default,
  zoomIn: require("../../Assets/Icons/zoomIn.svg").default,
  zoomInOver: require("../../Assets/Icons/zoomIn_hover.svg").default,
  zoomOut: require("../../Assets/Icons/zoomOut.svg").default,
  zoomOutOver: require("../../Assets/Icons/zoomOut_hover.svg").default,
  shareMail: require("../../Assets/Icons/share-mail.svg").default,
  whatsapp: require("../../Assets/Icons/whatsapp.svg").default,
  notification: require("../../Assets/Icons/notification.svg").default,
  calendarShare: require("../../Assets/Icons/calendar_share.svg").default,
  transitDetails: require("../../Assets/Icons/busIcon.svg").default,
  shekels: require("../../Assets/Icons/shekels.svg").default,
  shareSmallGray: require("../../Assets/Icons/share_small_gray.svg").default,
  app_store: require("../../Assets/Icons/app_store.png"),
  google_play: require("../../Assets/Icons/google_play.png"),
  route_details_arrow_up:
    require("../../Assets/Icons/route_details_arrow_up.svg").default,
  route_details_arrow_down:
    require("../../Assets/Icons/route_details_arrow_down.svg").default,
  share_full: require("../../Assets/Icons/share_full.svg").default,
  whatsapp_blue_fill: require("../../Assets/Icons/whatsapp_blue_fill.svg")
    .default,
  mail_blue_fill: require("../../Assets/Icons/mail_blue_fill.svg").default,
  twitter_blue_fill: require("../../Assets/Icons/twitter_blue_fill.svg")
    .default,
  facebook_blue_fill: require("../../Assets/Icons/facebook_blue_fill.svg")
    .default,
  // linkedin_blue_fill: require("../../Assets/Icons/linkedin_blue_fill.svg").default,
  close_full: require("../../Assets/Icons/close_full.svg").default,

  shareBlue: require("../../Assets/Icons/share_blue.svg").default,

  datePicker: require("../../Assets/Icons/date_picker.svg").default,
  logoMobile: require("../../Assets/Icons/mobile-logo.svg").default,
  logoMobileBlue: require("../../Assets/Icons/mobile-logo-blue.svg").default,
  dropDownArrow: require("../../Assets/Icons/dropdown_arrow.svg").default,
  language: require("../../Assets/Icons/language.svg").default,
  languageSmall: require("../../Assets/Icons/language_small.svg").default,
  direction: require("../../Assets/Icons/direction.svg").default,
  dots: require("../../Assets/Icons/dots.svg").default,
  dotsNew: require("../../Assets/Icons/dotsNew.svg").default,
  return: require("../../Assets/Icons/return.svg").default,
  dotsNewHome: require("../../Assets/Icons/dotsNewHome.svg").default,
  notificationsBell: require("../../Assets/Icons/notifications_bell.svg")
    .default,
  playIcon: require("../../Assets/Icons/playIcon.svg").default,
  photoIcon: require("../../Assets/Icons/photoIcon.png"),
  moreActionsDotsCirceled:
    require("../../Assets/Icons/more_actions_dots_circled.svg").default,
    threeDots:require("../../Assets/Icons/threeDots.svg").default,
  instagramHeader: require("../../Assets/Icons/instagram_header.svg").default,

  error: require("../../Assets/Icons/error.svg").default,
  facebookHeader: require("../../Assets/Icons/facebook_header.svg").default,
  linkedinHeader: require("../../Assets/Icons/linkedin_header.svg").default,
  youtubeHeader: require("../../Assets/Icons/youtube_header.svg").default,
  infoCircledIcon: require("../../Assets/Icons/icon_station_info.svg").default,
  triangle: require("../../Assets/Icons/triangle.svg").default,
  xmlIcon: require("../../Assets/Icons/xml-file.svg").default,

  exitIcon: require("../../Assets/Icons/exit.svg").default,
  printer: require("../../Assets/Icons/printer.svg").default,
  printerNew: require("../../Assets/Icons/printer2.svg").default,
  closeIconWhite: require("../../Assets/Icons/close_icon_white.svg").default,
  searchIconBlue: require("../../Assets/Icons/searchIconBlue.svg").default,
  map: require("../../Assets/Icons/map.svg").default,
  trainInCard: require("../../Assets/Icons/train_in_card.svg").default,

  // PNG
  googleMaps: require("../../Assets/Images/google-maps.png"),
  waze: require("../../Assets/Images/waze.png"),

  easy: require("../../Assets/Images/easy.png"),
  docxIcon: require("../../Assets/Icons/word-icon.svg").default,
  docIcon: require("../../Assets/Icons/word-icon.svg").default,
  xlsIcon: require("../../Assets/Icons/excel-icon.svg").default,
  sharePng: require("../../Assets/Icons/share.png"),
  xlsxIcon: require("../../Assets/Icons/excel-icon.svg").default,
  // pdfIcon: require("../../Assets/Icons/pdf.png"),

  coverImageMobile: require("../../Assets/Backgrounds/mobile-rail-2x.png"),
  wordFile2: require("../../Assets/Icons/word_file.png"),
  excelFile2: require("../../Assets/Icons/excel_file.png"),
  pdfFile2: require("../../Assets/Icons/pdf_file.png"),
  excelFile: require("../../Assets/Icons/excel-icon.svg").default,
  pdfFile: require("../../Assets/Icons/PDF-icon.svg").default,
  pdfIcon: require("../../Assets/Icons/PDF-icon.svg").default,
  wordFile: require("../../Assets/Icons/word-icon.svg").default,

  iconTiktok: require("../../Assets/Icons/Icon_tiktok.svg").default,
  iconTiktokHeader: require("../../Assets/Icons/Icon_tiktok.svg").default,

  icon_route_way_rtl: require("../../Assets/Icons/icon_route_way_rtl.svg").default,
  icon_route_way_ltr: require("../../Assets/Icons/icon_route_way_ltr.svg").default,



  rarIcon: require("../../Assets/Icons/rar-icon.svg").default,
  imageIcon: require("../../Assets/Icons/image-icon.svg").default,
  ravkav: require("../../Assets/Images/ravkav-70.png"),
  pango: require("../../Assets/Images/pango.png"),

  hopon: require("../../Assets/Images/hopon72.png"),
  hatahana: require("../../Assets/Images/pti70.png"),
  moovit: require("../../Assets/Images/moovit72.png"),
  ravpass: require("../../Assets/Images/ravpass72.png"),
  anyway: require("../../Assets/Images/anyway70.png"),
  uxpertLogo: require("../../Assets/Icons/uxpert_logo.png"),
  blueCalender: require("../../Assets/Icons/blue_calender.png"),
  person: require("../../Assets/Icons/person.png"),
  fares: require("../../Assets/Icons/fares.svg").default,
  rarIcon2: require("../../Assets/Icons/rar-icon24.jpg"),
  formBackground: require("../../Assets/Backgrounds/BackgroundForm.jpg"),

  kavim: require("../../Assets/Images/kavim.png"),
  dan: require("../../Assets/Images/dan.png"),
  egged: require("../../Assets/Images/egged.png"),
  afikim: require("../../Assets/Images/electra.png"),
  metropolin: require("../../Assets/Images/metropolin.png"),
  superbus: require("../../Assets/Images/superbus.png"),
  ntnt: require("../../Assets/Images/ntnt.png"),
  cable: require("../../Assets/Images/cable.png"),
  eggedT: require("../../Assets/Images/eggedT.png"),
  eilot: require("../../Assets/Images/eilot.png"),
  extra: require("../../Assets/Images/extra.png"),
  galim: require("../../Assets/Images/galim.png"),
  gbt: require("../../Assets/Images/gbt.png"),
  golan: require("../../Assets/Images/golan.png"),
  jerusRamalla: require("../../Assets/Images/jerusRamalla.png"),
  kfir: require("../../Assets/Images/kfir.png"),
  nativ: require("../../Assets/Images/nativ.png"),
  sb: require("../../Assets/Images/sb.png"),
  tnufa: require("../../Assets/Images/tnufa.png"),
  unbs: require("../../Assets/Images/unbs.png"),
  bus: require("../../Assets/Images/bus.png"),
  noBusIcon: require("../../Assets/Images/noBusIcon.png"),
  reload: require("../../Assets/Icons/reload.png"),
  information: require("../../Assets/Icons/information.png"),


};

export default Images;
