import moment from "moment";
import store from "store2";
import fonts from "../Assets/Fonts/Fonts.json";
import Config from "../Configuration/server";
import { IStation } from "../Interfaces/IStation";
import i18n from "../Services/i18n";
import { LanguageRegex } from "./translation";


export const languagesDictionary: { [locale: string]: string } = {
  he: "Hebrew",
  en: "English",
  ru: "Russian",
  ar: "Arabic",
};
export const Languages =[`he`,`en`,`ar`,`ru`]
export const GetStaionIdByName = (stationName: string) => {
  let language = i18n.language;
  const allStations = store.session.get(
    `${Config.BASE_LOCALSTORAGE_NAME}stations`
  );

  let stations: IStation[] = allStations && allStations[language];
  if (LanguageRegex.he.test(stationName) && language != "he") {
    language = "he";
    if (allStations && allStations[language]) {
      stations = store.session.get(`${Config.BASE_LOCALSTORAGE_NAME}stations`)[
        language
      ];
    }
  } else if (LanguageRegex.en.test(stationName) && language != "en") {
    language = "en";
    if (allStations && allStations[language]) {
      stations = store.session.get(`${Config.BASE_LOCALSTORAGE_NAME}stations`)[
        language
      ];
    }
  } else if (LanguageRegex.ar.test(stationName) && language != "ar") {
    language = "ar";
    if (allStations && allStations[language]) {
      stations = store.session.get(`${Config.BASE_LOCALSTORAGE_NAME}stations`)[
        language
      ];
    }
  } else if (LanguageRegex.ru.test(stationName) && language != "ru") {
    language = "ru";
    if (allStations && allStations[language]) {
      stations = store.session.get(`${Config.BASE_LOCALSTORAGE_NAME}stations`)[
        language
      ];
    }
  }
  let StationId: IStation =
    stations &&
    stations.find(
      (station) =>
        stationName.toLowerCase() === station.stationName.toLowerCase()
    )!;

  return StationId
    ? { stationId: StationId.stationId, language: language }
    : undefined;
};

export const CheckTimeActive = (start: string, end: string) => {
  return moment(new Date()).isBetween(start, end);
};

export const GetDistinctedItems = (items: string[]) => {
  let uniqueItems: string[] = [];
  items.forEach((item) => {
    if (!uniqueItems.includes(item)) {
      uniqueItems.push(item);
    }
  });
  return uniqueItems;
};

export const IsImage = (fileUrl: string) => {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(fileUrl);
};

export const replaceRichtextBoldWithFont = (richtext: string) => {
  if(richtext === undefined) return "";
  
  const span = `<span style="font-family: ${fonts.FbReformaMedium}; ${
    i18n.language === `ar` ? `font-weight: 600;` : ""
  }">`;
  return richtext
    .replaceAll("<strong>", span)
    .replaceAll("</strong>", "</span>")
    .replaceAll("<b>", span)
    .replaceAll("</b>", "</span>");
};

export const handleHighlight = (
  textToHighlight: string,
  searchedText: string
) => {
  let regex = new RegExp(
    searchedText.replace(/[.*+?^${}()|[\]\\]/g, "\\$&").toLowerCase(),
    "gi"
  );

  return textToHighlight.replace(regex, (match) =>
    match ? `<b>${match}</b>` : ""
  );
};

export const removeSpecialCharacters = (value: string) => {
  return value
    .replaceAll(`'`, "")
    .replaceAll("-", "")
    .replaceAll("/", "")
    .replaceAll(`"`, "");
};

export const GetLongTextWithDots = (text: string, charsLength: number) => {
  return text.substring(0, charsLength).concat("...");
};

export const handleHighlightEqualText = (
  station: IStation,
  searchedText: string,
  inputValue: string,
  selectedStationId?: number
) => {
  if (!inputValue) {
    if (selectedStationId !== undefined) {
      if (station.stationId === selectedStationId) {
        return `<b>${station.stationName}</b>`;
      } else {
        return station.stationName;
      }
    }
  }

  let regex = new RegExp(
    searchedText.replace(/[.*+?^${}()|[\]\\]/g, "\\$&").toLowerCase(),
    "gi"
  );

  // return textToHighlight;
  return station.stationName.replace(regex, (match) => {
    return match ? `<b>${match}</b>` : "";
  });
};

export const onORoffPreviewMode = (
  url: string,
  preview: boolean = false,
  navigate: any
) => {
  let finalUrl = ``;
  if (url.includes(`?`)) {
    finalUrl = url;
  } else {
    finalUrl = url + `?`;
  }
  if (preview) {
    navigate(`${finalUrl}&preview=true`);
  } else {
    navigate(`${finalUrl.replace(`preview=true`, `preview=false`)}`);
  }
};

export const QueryStringWorking = (params: any) => {
  return Object.entries(params)
    .filter(([, value]: any) => value.toString().length > 0)
    .map((param) => `${param[0]}=${param[1]}`)
    .join("&");
};

interface IGlobal {
  readonly value: any;
  set: (key: string, value: any) => void;
  get: (key: string) => any;
  remove: (key: string) => any;
}

export var globalStorage: IGlobal = {
  value: {},
  set: (key: string, data: any) => {
    globalStorage.value[key] = data;
  },
  get: (key: string) => {
    return globalStorage.value[key];
  },
  remove: (key: string) => {
    delete globalStorage.value[key];
  },
};
export var Gloabl_allProfile: any;

