import { Fragment } from "react";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";
import fontSize from "../../Assets/Fonts/FontsSizes.json";

const CounterAndTitles: React.FC<any> = (props) => {
  const deviceMode = useDeviceMode();


  const row =deviceMode === StyleMode.mobile ? styles.wrap : styles.wrap
  return (
    <div style={styles.bgGray}>
      <div style={{ ...styles.maxWidth, padding: "1rem" }}>
        <div style={{ ...row }}>
          {props.counts.map((item: any, index: number) => (
            <Fragment key={index}>
              <div style={styles.wrapperInfo}>
                <div style={styles.infoBlue}>{item.count}</div>
                <div style={styles.name}>{item.title}</div>
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};
const styles: StyleSheet = {
  wrap: {
    // display: "flex",
    // flexWrap: "wrap",
    display: "grid",
    gridGap: "1rem",
    // flexWrap: "wrap",
    gridTemplateColumns: "repeat(auto-fill, minmax(17rem, 1fr))",
  },
  bgGray: {
    backgroundColor: colors.darkBlue,
  },
  info: {
    fontSize: fontSize.Paragraph,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaLight,
    margin: 0,
  },

  mainHeader: {
    minHeight: "15.6rem",
    display: "flex",
    height: "100vh",
    maxHeight: "28rem",
  },
  maxWidth: {
    maxWidth: "68rem",
    margin: "auto",
    paddingBlock: "2rem",
    paddingInline: "1rem",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  wrapperInfo: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    justifyContent: "center",
    alignItems: "center",
    color: colors.white,
    flex: "1 0 0",
  },
  infoBlue: {
    fontSize: fontSize.CareerElements,
    fontFamily: fonts.FbReformaMedium,
    lineHeight: "normal",
  },
  name: {
    fontFamily: fonts.FbReformaLight,
    fontSize: fontSize.H1,
  },
};

export default CounterAndTitles;
