import { t } from "i18next";
import { useState } from "react";
import ReactModal from "react-modal";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import Images from "../../Data/Images/Images";
import { IMoreDetails, ISocialCards } from "../../Interfaces/IBaseElement";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";
import Contact from "../Contact";
import MoreDetails from "./MoreDetails";
import CustomModal from "../CustomModal";

const SocialCards: React.FC<ISocialCards> = (props) => {
  const { title, inTitle, inNote, note, options } = props;
  const [_moreDetails, _setMoreDetails] = useState<IMoreDetails>();
  const deviceMode = useDeviceMode();
  return (
    <div
      style={{
        background:
          "linear-gradient(rgb(249, 186, 77) 18rem, rgba(7, 7, 7, 0) 0)",
      }}
    >
      <div
        className="social-cards"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          padding: "1rem",
          backgroundSize: "50rem",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "-4rem -6rem",
          backgroundImage: `url(${Images.logoLight})`,
          textAlign: deviceMode !== StyleMode.desktop ? "center" : "unset",
          fontFamily: fonts.FbReformaRegular,
        }}
      >
        <div className="max-width" style={{ paddingInline: "3rem" }}>
          <h1
            style={{
              // fontSize:"3rem",
              flex: 1,
              color: colors.darkBlue,
              fontSize: "2rem",
              fontWeight: "normal",
              letterSpacing: "2.46px",
              margin: 0,
              paddingBottom: "3rem",
              lineHeight: 1.2,
            }}
          >
            {title} <br />
            {inTitle && (
              <span
                className="inTitle"
                style={{
                  fontWeight: "bold",
                  fontFamily: fonts.FbReformaMedium,
                }}
              >
                {inTitle}
              </span>
            )}
          </h1>
          <div
            className="questionsAndAnswers"
            style={{
              display: "flex",
              gap: "3rem 2rem",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {options.map((option, index) => (
              <Contact
                key={index}
                {...option}
                onShowDetails={(data) => _setMoreDetails(data)}
              />
            ))}
          </div>
          <p
            style={{
              color: colors.darkBlue,
              fontWeight: "bold",
              paddingBlock: "3rem",

              fontFamily: fonts.FbReformaMedium,
            }}
          >
            {note} {deviceMode !== StyleMode.desktop && <br />}
            <span
              style={{
                fontWeight: "normal",
                fontFamily: fonts.FbReformaRegular,
              }}
            >
              {inNote}
            </span>
          </p>
        </div>
      </div>
      {_moreDetails && (
        <CustomModal
          visible={_moreDetails.title !== undefined}
          onClose={() => _setMoreDetails(undefined)}
          showCloseButton="internal"
          icon={Images.contactUsClock}
          typeStyle={"rectangle"}
          innerStyle={{
            width: "85%",
            maxWidth: "42rem",
            borderRadius: "13px 13px 288px 288px / 13px 13px 43px 43px",
          }}
          contentStyle={{ padding: 0 }}
        >
          <MoreDetails {..._moreDetails} />
        </CustomModal>
      )}
    </div>
  );
};

export default SocialCards;

const styles: StyleSheet = {
  iconContainer: {
    width: "4.3rem",
    height: "4.3rem",
    boxShadow: "0 5px 11px 0 rgba(0, 0, 0, 0.21)",
    borderRadius: "50%",
    backgroundColor: "white",
    position: "absolute",
    display: "flex",
    top: "0",
    left: "50%",
    justifyContent: "center",
    alignItems: "center",
    transform: "translate(-50%,-50%)",
  },
  closeIcon: {
    position: "absolute",
    top: "1rem",
    cursor: "pointer",
    insetInlineEnd: "1rem",
    zIndex: 1,
  },
};
