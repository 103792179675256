import axios from "axios";
import Config from "../Configuration/server";
import { headers } from "./GlobalService";
import { ICalendarDataForAPI } from "../Interfaces/ICalendar";

export const SendCalendarMail = async (calenderData: ICalendarDataForAPI) => {
  const url = `${Config.BASE_URL}/sms/api/v1/MailSending/SendCalendarMail`;
  try {
    const res = await axios.post(url, calenderData, { headers });
    return res;
  } catch (error) {
    console.log(error);
  }
};
