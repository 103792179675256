
import { useNavigate } from "react-router-dom";
import { IIconAndDescription } from "../../Interfaces_tenders/IIconAndDescription";
import { GiCargoCrane, GiRailRoad  } from "react-icons/gi";
import Title from "./Title";

const IconAndDescription: React.FC<IIconAndDescription> = (props) => {
  const {step, isExtrenal, typeOfPage } = props;
  const navigate = useNavigate();
  const handleAuctionsClick = () => {
    navigate(
      `?page=${typeOfPage}&step=${step}&auctionsExtrenal=${isExtrenal}`,
      { replace: true }
    );
  };

  return (
    <div style={styles.wapperAccordion}>
      <div style={styles.floatIcon}>
        {typeOfPage === "cargoauctions" && (
          <GiCargoCrane size={40} color="rgb(0, 69, 138)" />
        )}
        {typeOfPage === "generalauctions" && (
          <GiRailRoad size={40} color="rgb(0, 69, 138)" />
        )}
      </div>
      <div style={styles.accordion}>
        <div style={styles.paddingWapper} onClick={handleAuctionsClick}>
          <Title text={props.title} containerStyles={{width:"auto"}}/>
        </div>
      </div>
    </div>
  );
};

const styles: any = {
  wapperAccordion: {
    position: "relative",
    display: "flex",
    flex: "1 1 25rem",
    maxWidth: "25rem",
    margin: "2rem 0",
  } as React.CSSProperties,
  floatIcon: {
    zIndex: "1",
    padding: "1rem",
    boxShadow: "var(--shadowColor)",
    borderRadius: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "var(--white)",
    margin: "auto",
    top: "0",
    left: "50%",
    position: "absolute",
    height: "2rem",
  } as React.CSSProperties,
  accordion: {
    
    backgroundColor: "var(--white)",
    textAlign: "center",
    boxShadow: "var(--shadowColor)",
    borderRadius: "1rem",
    position: "relative",
    width: "100%",
    overflow: "hidden",
  } as React.CSSProperties,
  paddingWapper: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "10rem",
    width: "100%",
  } as React.CSSProperties,
};

export default IconAndDescription;
