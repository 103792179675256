import { ForwardedRef, forwardRef } from "react";
import colors from "../../../Assets/Colors/Colors.json";
import { ICustomInput } from "../../../Interfaces/IForms";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";

const CustomTextArea: React.FC<ICustomInput> = forwardRef(
  (props, ref: ForwardedRef<HTMLInputElement>) => {
    const { label, errors, touched } = props;

    const boolTouched = touched === "true" ? true : false;

    return (
      <div style={styles.inputContainer}>
        <label style={styles.label}>{label}</label>
        <textarea
          style={styles.input}
          {...props}
          className={
            errors && errors && boolTouched && boolTouched ? "input-error" : ""
          }
        />
        {errors && errors && boolTouched && boolTouched && (
          <p className="error">{errors && errors}</p>
        )}
      </div>
    );
  }
);

const styles: StyleSheet = {
  label: {
    fontSize: "1rem",
    color: colors.black,
    fontWeight: "bold",
    display: "flex",
    margin: "1rem 0 0.2rem",
  },
  input: {
    width: "100%",
    padding: "0.65rem 0.5rem",
    fontSize: "1rem",
    color: colors.black,
    border: "1px solid",
    borderColor: colors.gray,
    borderRadius: "10px",
    outline: "none",
    resize: "none",
    minHeight: "6rem",
    maxHeight: "8rem",
  },
  inputContainer: {
    flex: "45%",
  },
};

export default CustomTextArea;
