import { Fragment, useState } from "react";
import {
  ICustomerServiceItem,
  ICustomerServicesHeadline,
} from "../../../Interfaces/ICustomerServices";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import CustomModal from "../../CustomModal";
import CustomerServiceHeadlineItem from "./CustomerServiceHeadlineItem";

const CustomerServicesHeadline: React.FC<ICustomerServicesHeadline> = (
  props
) => {
  const { customerServicesHeadLine, anchor } = props;
  const [_isModalOpened, _setIsModalOpened] = useState(false);
  const [
    _selectedCustomerServiceHeadlineItem,
    _setSelectedCustomerServiceHeadlineItem,
  ] = useState<ICustomerServiceItem>();

  const deviceMode = useDeviceMode();

  const handleCustomerServiceSelection = (
    customerServiceHeadlineItem: ICustomerServiceItem
  ) => {
    _setSelectedCustomerServiceHeadlineItem(customerServiceHeadlineItem);

    switch (customerServiceHeadlineItem.openMode) {
      case "Modal":
        _setIsModalOpened(true);
        break;
      case "New Window":
        window.open(customerServiceHeadlineItem.formUrl, "_blank");
        break;
    }
  };
  return (
    <Fragment>
      <div
        anchor-id={anchor}
        style={{
          ...styles.container,
          justifyContent:
            deviceMode === StyleMode.desktop ? props.rowPosition : "",
        }}
      >
        {customerServicesHeadLine.map((customerServiceHeadLine, index) => (
          <CustomerServiceHeadlineItem
            {...customerServiceHeadLine}
            key={index}
            onSelect={handleCustomerServiceSelection}
          />
        ))}
      </div>
      {_selectedCustomerServiceHeadlineItem && <CustomModal visible={_isModalOpened}
        onClose={() => _setIsModalOpened(false)}
        showCloseButton="internal" typeStyle={"rectangle"}
        innerStyle={{ width: "85%", maxHeight: "100%", padding: 0, maxWidth: "90.625rem", height: "50rem" }}
        contentStyle={{ padding: 0 }}
        closeStyle={{ ...styles.closeMainModalIcon, top: "-1rem", insetInlineEnd: "-1rem" }}
      >

        <iframe
          src={_selectedCustomerServiceHeadlineItem.formUrl}
          style={{ maxHeight: "99%", border: 0 }}
          width={"100%"}
          height={"100%"}
        ></iframe>

      </CustomModal>}
    </Fragment>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    width: "80%",
    maxWidth: "76rem",
    gap: "1rem",
  }
};

export default CustomerServicesHeadline;
