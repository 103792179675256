import { t } from "i18next";
import { useRef } from "react";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { IQuestionAndAnswersExtendedFilterItem } from "../../../Interfaces/IUmbraco";

const QuestionAndAnswersExtendedFilterItem: React.FC<
  IQuestionAndAnswersExtendedFilterItem
> = (props) => {
  const { value, isSelected = false, onClick, onKeyDown, index, total } = props;

  const filterItemRef = useRef<HTMLDivElement>(null);

  return (
    <div
      role={"tab"}
      aria-selected={isSelected ? "true" : "false"}
      ref={filterItemRef}
      tabIndex={isSelected ? 0 : -1}
      style={{
        ...styles.container,
        backgroundColor: isSelected ? colors.pattensBlue : colors.white,
        color: colors.darkBlue,
      }}
      onClick={() => {
        onClick(value);
      }}
      onKeyDownCapture={(e) => {
        if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
          onKeyDown(filterItemRef, e.key, index);
        }
      }}
      onFocusCapture={() => {
        onClick(value);
      }}
      aria-label={t("FilterOutOf", {
        count: index + 1,
        total,
        value,
      })}
      aria-controls={`tabpanel-${index}`}
    >
      {value}
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.pattensBlue,
    fontFamily: fonts.FbReformaRegular,
    borderRadius: "1rem",
    height: "2rem",
    gap: ".2rem",
    cursor: "pointer",
    letterSpacing: "0.05625rem",
    border: `1px solid ${colors.darkBlue}`,
    whiteSpace: "pre",
    paddingInline: "1rem",
    boxSizing: "border-box",
    fontSize: "1.3rem",
  },
};

export default QuestionAndAnswersExtendedFilterItem;
