import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";

import { Fragment } from "react";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import Images from "../../Data/Images/Images";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import {
  ScheduleStateBuild
} from "../../Redux/Slices/scheduleSlice";
import {
  FormatFullDate
} from "../../Services/DateService";
import { IsMobile } from "../../Services/DeviceService";
import RoutePlanTimeProps from "../RoutePlanTime/RoutePlanTimeProps";

export interface ScheduleStateProps {
  onSelect?: (object: ScheduleStateBuild) => void;
  onDelete?: (object: ScheduleStateBuild) => void;
  onEdit?: (object: ScheduleStateBuild) => void;
  data: ScheduleStateBuild;
  multisearch?: boolean;
  isNew?: boolean;
}
let currentTime = new Date();
const initialSchale: ScheduleStateBuild = {
  date: moment().format("YYYY-MM-DD"),
  hours: currentTime.getHours().toString(),
  minutes: currentTime.getMinutes().toString(),
  scheduleType: 1,
};
const TimeCapsuleProps: React.FC<ScheduleStateProps> = (props) => {
  // const { onVisibleTrigger, onConfirm } = props;
  const [_isVisible, _setIsVisible] = useState(false);
  const [_isConfirmOver, _setIsConfirmOver] = useState(false);
  const [_fulldate, _setFulldate] = useState<string>("");
  const [_schedule, _setSchedule] = useState<ScheduleStateBuild>(initialSchale);

  const scheduleTimePlanRef = useRef<HTMLInputElement>(null);
  const timePlanContainerRef = useRef<HTMLDivElement>(null);
  const confirmRef = useRef<HTMLButtonElement>(null);
  const isVisibleRef = useRef(_isVisible);



  useEffect(() => {
    document.addEventListener("mousedown", checkMouseClickOutside);
    return () => {
      document.removeEventListener("mousedown", checkMouseClickOutside);
    };
  }, []);
  useEffect(() => {
    _setSchedule({
      ..._schedule,
      ...props.data,
    });
    onSetTime({
      ..._schedule,
      ...props.data,
    });
  }, [props.data]);

  const onSetTime = (data: ScheduleStateBuild) => {
    _setFulldate(FormatFullDate(data));
  };
  const handleCancel = () => {
    isVisibleRef.current = false;
    props.onSelect && props.onSelect(_schedule);
    _setIsVisible(false);
    // onVisibleTrigger(false);
    // scheduleBeforeChanges.current
    isVisibleRef.current = false;
  };

  const handleConfirm = () => {
    let timeout = setTimeout(() => {
      _setIsVisible(false);
      isVisibleRef.current = false;
      // onVisibleTrigger(false);
      clearTimeout(timeout);
      props.onSelect && props.onSelect(_schedule);

      // onConfirm && onConfirm();
    }, 100);
  };

  const checkMouseClickOutside = (e: any) => {
    // if (
    //   (scheduleTimePlanRef.current &&
    //     !scheduleTimePlanRef.current.contains(e.target)) ||
    //   (scheduleTimePlanRef.current &&
    //     !scheduleTimePlanRef.current.contains(confirmRef.current))
    // ) {
    //   _setIsVisible(false);
    // }
    // if (
    //   scheduleTimePlanRef.current &&
    //   !scheduleTimePlanRef.current.contains(e.target)
    // ) {
    //   handleCancel();
    // }
  };

  const onBlur = (e: React.FocusEvent) => {
    if (timePlanContainerRef.current) {
      if (timePlanContainerRef.current.contains(e.relatedTarget)) {
        _setIsVisible(true);
      } else {
        // handleCancel();
      }
    }
  };

  return (
    <Fragment>
      {_isVisible && (
        <div
          style={{
            position: "fixed",
            width: "100%",
            left: "0",
            top: "0",
            height: "100%",
          }}
          onClick={() => {
            handleCancel();
          }}
        ></div>
      )}
      <div
        ref={scheduleTimePlanRef}
        style={{ ...styles.mainWrapper }}
        onClick={(e) => {
          e.stopPropagation();
          _setIsVisible(true);
          // onVisibleTrigger(true);
        }}
      >
        <img src={Images.calendar} style={styles.datePickerIcon}></img>

        <div
          style={styles.date}
          role={"button"}
          tabIndex={!IsMobile() ? 0 : -1}
          aria-label={`${t("TimeCapsuleButton", {
            fulldate: _fulldate,
          })}`}
        >
          {_fulldate}
        </div>
        {_isVisible && (
          <div
            ref={timePlanContainerRef}
            style={styles.scheduleTimePlan}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onFocusCapture={() => {
              _setIsVisible(true);
            }}
            // tabIndex={!IsMobile() ? 0 : -1}
            onBlurCapture={(e) => {
              onBlur(e);
            }}
          >
            <RoutePlanTimeProps
              data={_schedule}
              hasSearchButton={false}
              stylePlan={true}
              // onSelect={(schedule) =>_setSchedule(schedule)}
              onChange={(schedule) => {
                _setSchedule(schedule);
                onSetTime(schedule);
              }}
            />
            <button
              onMouseOver={() => _setIsConfirmOver(true)}
              onMouseLeave={() => _setIsConfirmOver(false)}
              ref={confirmRef}
              onClick={(e) => {
                e.stopPropagation();
                handleConfirm();
              }}
              style={{
                ...styles.confirm,
                filter: _isConfirmOver ? "brightness(.95)" : "",
              }}
            >
              {t("Confirm")}
            </button>
          </div>
        )}
        <img src={Images.arrowDown} style={styles.arrowIcon}></img>
      </div>
    </Fragment>
  );
};

const styles: StyleSheet = {
  mainWrapper: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    border: `.1rem solid ${colors.pastelGray}`,
    backgroundColor: colors.white,
    borderRadius: "2rem",
    minWidth: "25rem",
    padding: ".3rem .7rem",
    cursor: "pointer",
  },
  date: {
    color: colors.nero,
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Text,
  },
  datePickerIcon: {
    backgroundRepeat: "no-repeat",
    width: "1rem",
    height: "1rem",
    transform: "scale(1.5)",
    filter: `brightness(0.5)`,
  },
  arrowIcon: {
    backgroundRepeat: "no-repeat",
  },
  scheduleTimePlan: {
    position: "absolute",
    paddingTop: "4rem",
    backgroundColor: colors.white,
    width: "32rem",
    height: "40rem",
    top: "5.5rem",
    boxShadow: `0 15px 30px 0 rgba(0, 0, 0, 0.15)`,
    borderRadius: "0.6rem",
    zIndex: 1,
  },
  confirm: {
    textAlign: "center",
    position: "absolute",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    bottom: "0",
    backgroundColor: colors.koromiko,
    width: "100%",
    fontSize: fontSize.H1,
    border: "none",
    cursor: "pointer",
    fontFamily: fonts.FbReformaRegular,
  },
};

export default TimeCapsuleProps;
