import { t } from "i18next";
import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import Images from "../../Data/Images/Images";
import { IActivityTime } from "../../Interfaces/IActivityTime";
import { IStationInfo } from "../../Interfaces/IStationInfo";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { UrlTranslationsState } from "../../Redux/Slices/urlTranslationsSlice";
import { Translations } from "../../Services/GlobalService";
import i18n from "../../Services/i18n";
import { UpdatesTypes } from "../../Utils/consts";
import Gallery from "../UmbracoElements/Gallery/Gallery";
import ActivityTime from "./ActivityTime/ActivityTime";
import ExtendTitle from "./ActivityTime/ExtendTitle";

const IMAGES_SERVICE = [
  {
    title: "מכונות כרטיסים",
    icon: Images.ticketMachines,
  },
  {
    title: "חניון צמוד",
    icon: Images.parking,
    accessibility: true,
  },
  {
    title: "מתקני אופניים",
    icon: Images.bicycleFacilities,
  },
  {
    title: "עמדת מודיעין",
    icon: Images.intelligencePosition,
  },
  {
    title: "לוקרים לאחסון",
    icon: Images.lockersStorage,
  },
  {
    title: "אבידות ומציאות",
    icon: Images.lostFound,
  },
  {
    title: "מזון ושתייה",
    icon: Images.foodDrink,
  },
  {
    title: "מכונת משקאות",
    icon: Images.drinksVendingMachine,
  },
  {
    title: "חנות נוחות",
    icon: Images.convenienStore,
  },
  {
    title: "שירותים ציבוריים",
    icon: Images.publicToilets,
    accessibility: true,
  },
  {
    title: "ספרייה בתחנה",
    icon: Images.libraryStation,
  },
  {
    title: "מנגנים בתחנה",
    icon: Images.playingStation,
  },
  {
    title: "טלפון ציבורי",
    icon: Images.payPhone,
  },
  {
    title: "עמדת כספומט",
    icon: Images.atm,
  },
];

const StationInfoDetails: React.FC<IStationInfo> = (props) => {
  const REGEX_ENT = /(?:\*ent\*)[^\*]+(?:\*ent\*)/gm;
  const [_tooltipMessage, __SeTooltipMessage] = useState<string>("");
  const [_isModalOpened, _setIsModalOpened] = useState<boolean>(false);
  const [_showToolTip, _setShowToolTip] = useState<boolean>(false);
  const Translation = Translations[
    i18n.language as `he` | `en` | `ar` | `ru`
  ] as any;

  const {
    stationDetails,
    stationUpdates,
    gateInfo,
    safetyInfos,
    stationServices,
    seoObject,
    type = "desktop",
  } = props;

  const mergeItem = (item: IActivityTime) => {
    item.stationName = stationDetails.stationName;
    return item;
  };

  const navigate = useNavigate();

  const GlassixURL = `https://app.glassix.com/Clients/widget-in-iframe.html?apiKey=02a0f845-81ce-4067-b752-de3ed4e5fcc5&snippetId=5ab0d56e9f8c839c98be`;

  const urlTranslations = (
    useSelector(
      (state: any) => state.UrlTranslationsReducer
    ) as UrlTranslationsState
  ).urlTranslations;

  const subTitle =
    type === "desktop" ? styles.subTitleDesktop : styles.subTitle;
  const _title = type === "desktop" ? styles.titleDetktop : styles.title;
  const stationOptions =
    type === "desktop" ? styles.stationOptionsDesktop : styles.stationOptions;
  const itemOption =
    type === "desktop" ? styles.itemOptionDesktop : styles.itemOption;
  const circleWapper =
    type === "desktop" ? styles.circleWapperDesktop : styles.circleWapper;

  const specialUpdates: any[] = [];
  stationUpdates.map((update: any, index: number) => {
    if (update.updateType === UpdatesTypes.special) {
      specialUpdates.push(update);
    }
  });
  const regularUpdates: any[] = [];
  stationUpdates.map((update: any, index: number) => {
    if (
      update.updateType === UpdatesTypes.regular ||
      update.updateType === UpdatesTypes.permanent
    ) {
      regularUpdates.push(update);
    }
  });

  const redirectToPage = (page?: string) => {
    page && navigate(`?page=${page}`);
  };

  return (
    <div
      className="container over-hidden"
      style={{ ...styles.container, boxSizing: "border-box" }}
    >
      {regularUpdates && regularUpdates.length > 0 && (
        <div style={styles.spaceBetweenElement}>
          <div
            style={{
              ...styles.updates,
              paddingBottom: type === "desktop" ? "2rem" : "",
            }}
          >
            {regularUpdates.slice(0, 3).map((update: any, index: number) => (
              <Fragment key={index}>
                <a
                  href={type === "desktop" ? update.updateLink : undefined}
                  className="row"
                  style={{
                    ...styles.note,
                    gap: type === "desktop" ? "2rem" : ".5rem",
                    lineHeight: type === "desktop" ? "3rem" : "2rem",
                  }}
                  key={index}
                >
                  <img
                    src={Images.megaphone}
                    style={{
                      width: "1.9rem",
                      height: "1.7rem",
                      borderRadius: "50%",
                      rotate: i18n.dir() === "ltr" ? "y 180deg" : "",
                    }}
                    alt={``}
                  />
                  <div
                    style={{
                      ...subTitle,
                      lineHeight: type === "desktop" ? "3rem" : "2rem",
                      display: "flex",
                      fontSize:
                        type === "desktop" ? fontSize.Paragraph : fontSize.Text,
                      fontFamily:
                        type === "desktop"
                          ? fonts.FbReformaMedium
                          : fonts.FbReformaMedium,
                      gap: ".5rem",
                    }}
                  >
                    <span>
                      {`${update.updateHeader}${
                        type === "desktop" ? ">" : ` `
                      }`}
                      {type !== "desktop" && (
                        <span
                          style={{
                            color: colors.darkCyan,
                          }}
                          onClick={() =>
                            navigate(
                              `?page=${update.updateLink.split(`/?page=`)[1]}`
                            )
                          }
                        >{`${update.linkText}>`}</span>
                      )}
                    </span>
                  </div>
                </a>
                {type !== "desktop" && (
                  <div style={{ display: "flex", gap: ".5rem" }}>
                    <div style={{ width: "1.9rem", height: "1.7rem" }}></div>
                  </div>
                )}
              </Fragment>
            ))}
            {type === "desktop" && regularUpdates.length > 3 && (
              <span
                role={"link"}
                tabIndex={0}
                onClick={() => redirectToPage("updates")}
                style={{
                  color: colors.darkCyan,
                  fontSize: fontSize.SubTitle,
                  fontFamily: fonts.FbReformaMedium,
                  marginInline: "1rem",
                  cursor: "pointer",
                }}
                className={"all-updates"}
              >
                {t("All-updates")}
                {" > "}
              </span>
            )}
          </div>
        </div>
      )}
      {specialUpdates &&
        specialUpdates.length > 0 &&
        specialUpdates.map((update: any, index: number) => (
          <div
            style={{
              ...styles.spaceBetweenElement,
              // paddingInlineStart: "3rem",
            }}
            key={index}
          >
            <div
              className="row"
              style={{
                ...styles.note,
                gap: type === "desktop" ? "2rem" : ".5rem",
              }}
            >
              <img
                src={Images.note}
                style={{
                  width: "2rem",
                  height: "2rem",
                  borderRadius: "50%",
                }}
                alt={``}
              />
              <div
                style={{
                  ...subTitle,
                  lineHeight: type === "desktop" ? "3rem" : "2rem",
                  fontSize:
                    type === "desktop" ? fontSize.Paragraph : fontSize.Text,
                  fontFamily:
                    type === "desktop"
                      ? fonts.FbReformaMedium
                      : fonts.FbReformaMedium,
                }}
              >
                {update.updateHeader}
              </div>
            </div>
          </div>
        ))}

      {stationDetails?.nonActiveElevatorsLink && (
        <div style={styles.spaceBetweenElement}>
          <div style={styles.attention}>
            <h4
              style={{
                ...subTitle,
                fontSize: fontSize.Paragraph,
                margin: 0,
                padding: 0,
              }}
            >
              {stationDetails.nonActiveElevators}
            </h4>
            <div>
              <a
                href={`/?page=trainAccessibility`}
                className="link"
                style={{
                  fontSize: fontSize.FooterItem,
                  color: "var(--cyanBold)",
                }}
              >
                {t("ForInformationPassengers")}
              </a>
            </div>
          </div>
        </div>
      )}

      {gateInfo.length > 0 && (
        <ExtendTitle
          type={type}
          title={t("ActivityTime")}
          link={
            stationDetails.stationMap ? (
              <div
                className="row"
                style={{
                  ..._title,
                  display: "flex",
                  alignItems: "center",
                  fontSize: fontSize.Text,
                  color: colors.darkCyan,
                  gap: "0.5rem",
                }}
              >
                <img src={Images.map} style={{ width: "1.5rem" }} />
                <a
                  href={stationDetails.stationMap}
                  style={{ color: colors.darkCyan }}
                  target={"_blank"}
                >
                  {t("orientationMap")}
                </a>
              </div>
            ) : undefined
          }
          disabled={true}
          show={true}
          bodyChildren={
            stationDetails.stationIsClosed ? (
              <div
                style={{
                  paddingBottom: "3rem",
                  fontSize: fontSize.SubTitle,
                  color: colors.danger,
                  fontFamily: fonts.FbReformaMedium,
                }}
              >
                {stationDetails.stationIsClosedText}
              </div>
            ) : (
              <div style={{ ...stationOptions, columnGap: "3rem" }}>
                <Swiper
                  slidesPerView={1}
                  breakpoints={{
                    // when window width is >= 640px
                    0: {
                      slidesPerView: 1,
                    },
                    // when window width is >= 768px
                    768: {
                      slidesPerView: 2,
                    },
                    1040: {
                      slidesPerView: 3,
                    },
                  }}
                  navigation={type === "desktop"}
                  spaceBetween={30}
                  pagination={{ clickable: true }}
                  modules={[Pagination, Navigation]}
                  className="mySwiper swiper-ativity-times"
                  style={{
                    padding: type === "desktop" ? "1rem 0" : "2rem 1rem",
                    overflow: type === "desktop" ? "unset" : "hidden",
                  }}
                >
                  {gateInfo.map((item: IActivityTime, index: number) => (
                    <SwiperSlide key={index}>
                      <ActivityTime
                        {...mergeItem(item)}
                        type={type}
                        index={index}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            )
          }
        />
      )}

      <ExtendTitle
        type={type}
        title={t("safetyInstructions")}
        bodyChildren={
          <div style={{ overflow: "hidden" }}>
            <div
              style={{
                paddingBottom: "1rem",
              }}
            >
              {t("safetyInstructionsText")}
            </div>
            <a
              // style={styles.alternativePublicTransport}
              href={urlTranslations[i18n.language]["SafetyInstructions"]}
              // target={"_blank"}
              rel={"noreferrer"}
            >
              {t("safetyInstructionsLink")}
            </a>
          </div>
        }
      />

      {stationDetails?.stationInfo && (
        <ExtendTitle
          type={type}
          title={stationDetails.stationInfoTitle}
          bodyChildren={
            stationDetails?.stationInfo?.match(REGEX_ENT) ? (
              <div
                className="wrpar"
                style={{
                  display: "grid",
                  gridTemplateColumns: `repeat(2, 1fr)`,
                  overflow: "hidden",
                }}
              >
                {stationDetails?.stationInfo
                  ?.match(REGEX_ENT)
                  .map((list: string, index: number) => (
                    <div
                      key={index}
                      dangerouslySetInnerHTML={{
                        __html: list.replace(/\*ent\*/g, ""),
                      }}
                      style={{
                        fontFamily: fonts.FbReformaLight,
                        fontSize: "1.275rem",
                        lineHeight: "1.75rem",
                        flex: "1 1 48%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    />
                  ))}
              </div>
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: stationDetails?.stationInfo.replace(/\*ent\*/g, ""),
                }}
                style={{
                  fontFamily: fonts.FbReformaLight,
                  fontSize: "1.275rem",
                  lineHeight: "1.75rem",
                  flex: "1 1 48%",
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                }}
              />
            )
          }
        />
      )}
      {seoObject?.gallery && seoObject?.gallery?.gallery?.length > 0 && (
        <ExtendTitle
          type={type}
          title={t("gallery")}
          bodyChildren={<Gallery {...seoObject.gallery} />}
        />
      )}
      {stationServices?.length > 0 && (
        <ExtendTitle
          type={type}
          title={t("WhatsAtStation")}
          titleEndChildren={
            type === "desktop" && (
              <div
                className="row"
                style={{ alignItems: "center", gap: "0.5rem" }}
              >
                {Translation.StationAccessibility?.length && (
                  <>
                    <div style={{ position: "relative" }}>
                      <img
                        src={Images.infoCircledIcon}
                        title={Translation.StationAccessibility}
                        alt={Translation.StationAccessibility}
                        style={{ width: "1.2rem", cursor: "help" }}
                      />
                    </div>
                    <h3
                      style={
                        type === "desktop" ? styles.titleDetktop : styles.title
                      }
                    >
                      {t("AccessibleStation")}
                    </h3>
                  </>
                )}
              </div>
            )
          }
          link={
            type === "desktop" && (
              <div
                className="row"
                style={{
                  ..._title,
                  display: "flex",
                  alignItems: "center",
                  fontSize: fontSize.Text,
                  color: colors.darkCyan,
                  gap: "0.5rem",
                }}
              >
                <a
                  href={`${GlassixURL}`}
                  style={{ color: colors.darkCyan }}
                  target={"_blank"}
                >
                  {t("CoordinateTravel")}
                </a>
              </div>
            )
          }
          bodyChildren={
            <div className={"station-options-grid"}>
              {stationServices.map((item: any, index: number) => (
                <div style={itemOption} key={index}>
                  <div style={circleWapper}>
                    {IMAGES_SERVICE[Number(item.serviceIcon) - 1]
                      .accessibility && (
                      <img
                        src={Images.handicapFill}
                        style={{
                          position: "absolute",
                          zIndex: 1,
                          right: 0,
                          top: 0,
                          width: "40%",
                          transform: "translate(25%, -25%)",
                        }}
                        alt={``}
                      />
                    )}
                    <img
                      src={IMAGES_SERVICE[Number(item.serviceIcon) - 1].icon}
                      // style={{ width: "100%", height: `auto` }}
                      alt={``}
                    />
                  </div>
                  <div
                    style={{
                      ...styles.nameOption,
                      whiteSpace: type === "desktop" ? "nowrap" : "unset",
                    }}
                  >
                    {item.serviceName}
                  </div>
                </div>
              ))}
            </div>
          }
        />
      )}
      {type === "mobile" && (
        <ExtendTitle
          type={type}
          title={t("AccessibleStation")}
          link={
            <div
              className="row"
              style={{
                ..._title,
                display: "flex",
                alignItems: "center",
                fontSize: fontSize.Text,
                color: colors.darkCyan,
                gap: "0.5rem",
              }}
            >
              <a
                href={`https://app.glassix.com/Clients/widget-in-iframe.html?apiKey=02a0f845-81ce-4067-b752-de3ed4e5fcc5&snippetId=5ab0d56e9f8c839c98be`}
                style={{ color: colors.darkCyan }}
                target={"_blank"}
              >
                {t("CoordinateTravel")}
              </a>
            </div>
          }
          bodyChildren={Translation.StationAccessibility}
        />
      )}
      {safetyInfos.length > 0 && (
        <ExtendTitle
          type={type}
          title={t("Safety")}
          bodyChildren={
            <div style={{ overflow: "hidden" }}>
              <div style={{ paddingBlock: "1rem" }}>
                <b
                  style={{
                    fontFamily: fonts.FbReformaLight,
                    fontSize: fontSize.FooterItem,
                    lineHeight: "1.75rem",
                  }}
                >
                  {Translation.securityCheck}
                </b>
                <p
                  style={{
                    fontFamily: fonts.FbReformaLight,
                    fontSize: fontSize.FooterItem,
                    lineHeight: "1.75rem",
                  }}
                >
                  {Translation.securityCheckText}
                </p>

                <p
                  style={{
                    fontFamily: fonts.FbReformaLight,
                    fontSize: fontSize.FooterItem,
                    lineHeight: "1.75rem",
                  }}
                >
                  {Translation.securityCheckText2}
                </p>
              </div>
              {/* <h3
                style={{
                  ..._title,
                  padding: "1.5rem 0",
                }}
              >
                {Translation.SafetyGuidelines}
              </h3>
              {safetyInfos.map((list: string, index: number) => (
                <p
                  key={index}
                  style={{
                    fontFamily: fonts.FbReformaLight,
                    fontSize: fontSize.FooterItem,
                    lineHeight: "1.75rem",
                  }}
                >
                  {index + 1}. {list}
                </p>
              ))} */}
            </div>
          }
        />
      )}
      {stationDetails.aboutStationTitle && (
        <ExtendTitle
          type={type}
          title={stationDetails.aboutStationTitle}
          bodyChildren={
            <div style={{ overflow: "hidden" }}>
              <div
                style={styles.text}
                dangerouslySetInnerHTML={{
                  __html: stationDetails.aboutStationContent,
                }}
              />
            </div>
          }
        />
      )}
    </div>
  );
};

const styles: StyleSheet = {
  nameOption: {
    fontFamily: fonts.FbReformaRegular,
    color: "var(--darkBlue)",
    textAlign: "center",
    fontSize: fontSize.Text,
    whiteSpace: "nowrap",
  },

  circleWapperDesktop: {
    width: "6.25rem",
    height: "6.25rem",
    borderRadius: "50%",
    // padding: "20px",
    border: "2px solid var(--darkBlue)",
    boxSizing: "border-box",
    position: "relative",
    backgroundColor: "var(--white)",
    boxShadow: "var(--shadowColor)",
    margin: "auto",
    marginBottom: "0.5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  circleWapper: {
    width: "2rem",
    height: "2rem",
    borderRadius: "50%",
    padding: "10px",
    border: "2px solid var(--darkBlue)",
    position: "relative",
    backgroundColor: "var(--white)",
    boxShadow: "var(--shadowColor)",
    margin: "auto",
    marginBottom: "0.5rem",
    display: "flex",
  },
  topHeader: {
    backgroundColor: colors.pattensBlue,
    padding: "0.5rem 1rem",
  },
  itemDescription: {
    flex: "1 1 20rem",
  },
  itemOptionDesktop: {
    position: "relative",
    textAlign: "center",
    padding: "0.19rem",
    // flex: "1 0 auto",
  },
  itemOption: {
    position: "relative",
    textAlign: "center",
    padding: "0.19rem",
    // flex: "1 0 20%",
    // maxWidth: "4rem",
  },
  titleDetktop: {
    display: "inline-block",
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.FooterItem,
    borderBottom: `1px solid var(${colors.koromiko})`,
    color: colors.darkBlue,
    borderBottomWidth: ".2rem",
    padding: ".5rem 0",
    margin: 0,
  },
  title: {
    display: "inline-block",
    fontFamily: fonts.FbReformaMedium,
    fontWeight: `${i18n.language === "ar" ? `600` : ``}`,
    fontSize: fontSize.Paragraph,
    borderBottom: `1px solid var(${colors.koromiko})`,
    color: colors.darkBlue,
    borderBottomWidth: ".2rem",
    padding: ".5rem 0",
    margin: 0,
  },
  subTitleDesktop: {
    display: "inline-block",
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.UmbracoElementTitle,
    color: colors.darkBlue,
  },
  subTitle: {
    display: "inline-block",
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.FooterItem,
    color: colors.black,
    margin: 0,
  },
  wrapperTwoRow: {
    flex: "1 0 25rem",
    maxWidth: "50%",
  },
  stationOptionsDesktop: {
    // display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    rowGap: "3rem",
    columnGap: "1rem",
  },
  stationOptions: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  text: {
    fontFamily: fonts.FbReformaLight,
    fontSize: fontSize.FooterItem,
    lineHeight: "1.75rem",
  },
  note: {
    alignItems: "center",
    lineHeight: "3rem",
    gap: "2rem",
  },
  updates: {
    backgroundColor: "var(--lightGray)",
    paddingTop: `2rem`,
    paddingBottom: `2rem`,
    lineHeight: "3rem",
    borderRadius: "1rem",
  },
  attention: {
    borderInlineStart: "4px solid var(--red)",
    paddingInlineStart: "2rem",
    lineHeight: "1.75rem",
  },

  container: {
    width: "100%",
    minHeight: "25rem",
    // paddingTop: "0rem",
  } as React.CSSProperties,
  spaceBetweenElement: {
    marginBlock: "1.5rem",
  } as React.CSSProperties,
};

export default StationInfoDetails;
