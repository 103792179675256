import QueryString from "qs";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import store from "store2";
import colors from "../Assets/Colors/Colors.json";
import fonts from "../Assets/Fonts/Fonts.json";
import FromWhereMobile from "../Components/FromWhereMobile/FromWhereMobile";
import RoutePlanButtons from "../Components/RoutePlanButtons/RoutePlanButtons";
import RoutePlanSearchStations from "../Components/RoutePlanSearchStations/RoutePlanSearchStations";
import RoutePlanTime from "../Components/RoutePlanTime/RoutePlanTime";
import Config from "../Configuration/server";
import Images from "../Data/Images/Images";
import { IStation } from "../Interfaces/IStation";
import { StyleSheet } from "../Interfaces/IStyleSheet";
import { PageState } from "../Redux/Slices/pageSlice";
import { IsMobile } from "../Services/DeviceService";
import i18n from "../Services/i18n";
import { ChangeTitle, StyleMode, TitlePage, UpdateSeo } from "../Utils/consts";
import { GetStationsDictionary } from "../Utils/helpers";
import useDeviceMode from "../Utils/useWindowDimensions";
import { GetStaionIdByName } from "../Utils/utils";
import { IsNumeric } from "../Utils/validation";

const RoutePlan: React.FC = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const deviceMode = useDeviceMode();

  const [_title, _setTitle] = useState(`${t(`From-where`)}`);
  const [_inputPlaceHolder, _setInputPlaceHolder] = useState("");
  const [_step, _setStep] = useState("");
  const [_stationName, _setStationName] = useState("");
  const [_animation, _setAnimation] = useState(false);
  const [_isInputFocus, _setIsInputFocus] = useState(false);

  const currentPage = (
    useSelector((state: any) => state.PageReducer) as PageState
  ).page;

  const inputRef = useRef<HTMLInputElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);

  const isSafariOs = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isSafariAndWebMode = isSafariOs && deviceMode === "DESKTOP";

  const [_isIos, _setIsIos] = useState(isSafariOs && !isSafariAndWebMode);

  useEffect(() => {
    _setIsIos(isSafariOs && !isSafariAndWebMode);
  }, [isSafariAndWebMode]);

  useEffect(() => {
    handleUrlParams();
    const urlParams = location.search.split("?")[1] || "";
    const step = QueryString.parse(urlParams)?.step as string;
    ChangeTitle(
      `${t(`${TitlePage.Route}`)} ` +
        `${
          step === "origin"
            ? t(`From-where`)
            : step === "destination"
            ? t(`To-where`)
            : t(`When`)
        }`
    );

    if (titleRef.current) {
      titleRef.current.tabIndex = 0;
      titleRef.current.focus();
      titleRef.current.style.outline = "none";
      titleRef.current.tabIndex = -1;
    }

  }, [location, currentPage]);

  useEffect(() => {
    UpdateSeo(
      `${t(TitlePage.Route)} - ${t(_title)}`,
      `${t(TitlePage.Route)} - ${t(_title)}`,
      `${t(TitlePage.Route)} - ${t(_title)}`,
      `${t(TitlePage.Route)} - ${t(_title)}`,
      Images.coverImage
    );
  }, [_title, location, currentPage])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = _stationName;
    }
  }, [_stationName]);

  const handleUrlParams = () => {
    const urlParams = location.search.split("?")[1] || "";
    if (urlParams) {
      const step = QueryString.parse(urlParams)?.step as string;
      const allParams = QueryString.parse(urlParams);
      const { fromStation, toStation } = allParams;

      let from = undefined;
      let to = undefined;

      if (!IsNumeric(fromStation + "")) {
        from = GetStaionIdByName(fromStation + "");
      }
      if (!IsNumeric(toStation + "")) {
        to = GetStaionIdByName(toStation + "");
      }
      const allStations = store.session.get(
        `${Config.BASE_LOCALSTORAGE_NAME}stations`
      );
      const stations: IStation[] = allStations && allStations[i18n.language];
      const dictStations = allStations && GetStationsDictionary(stations);
      const fromStationName = from
        ? dictStations && dictStations[from.stationId].stationName
        : fromStation
        ? dictStations && dictStations[+fromStation].stationName
        : "";
      const toStationName = to
        ? dictStations && dictStations[to.stationId].stationName
        : toStation
        ? dictStations && dictStations[+toStation].stationName
        : "";

      if (step) {
        switch (step) {
          case "origin":
            _setTitle("From-where");
            _setInputPlaceHolder("Origin-station");
            _setStationName(fromStationName);
            if (inputRef.current) {
              inputRef.current.value = fromStationName;
            }
            break;
          case "destination":
            _setTitle("To-where");
            _setInputPlaceHolder("Destination-station");
            _setStationName(toStationName);

            if (inputRef.current) {
              inputRef.current.value = toStationName;
            }
            break;
          case "when":
            _setTitle("When");
            break;
        }
        _setStep(step);
      }
    }
  };

  useEffect(() => {
    _setAnimation(true);
  }, []);

  return (
    <>
      {/* <span
        ref={currentPageRef}
        tabIndex={!IsMobile() ? 0 : -1}
        style={{
          zIndex: "-20",
          position: "absolute",
        }}
      >
        {t("CurrentPage", {
          page: _title,
        })}
      </span> */}
      <div
        style={{
          ...styles.mainWrapper,
          height: deviceMode !== StyleMode.desktop ? "100vh" : "",
        }}
      >
        <div
          style={{
            ...styles.coverWrapper,
            height: deviceMode === StyleMode.desktop ? "33vh" : "",
          }}
          className="coverWrapper"
        >
          <div
            style={{
              ...styles.routePlanButtonsWrapper,
              // width: !_isIos ? "100%" : "85%",
              // padding: !_isIos ? "1rem" : "0 7.5%",
              width: "100%",
              padding: "1rem",
              boxSizing: "border-box",
            }}
          >
            <RoutePlanButtons />
          </div>
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              ref={titleRef}
              style={{ position: "absolute", zIndex: -1, color: "transparent" }}
              aria-label={t(_title)}
              tabIndex={!IsMobile() ? 0 : -1}
            >
              {t("CurrentPage", {
                page: t(_title),
              })}
            </div>
            {deviceMode === StyleMode.desktop ? (
              <div
                style={{
                  ...styles.title,
                  // top: "calc(50% - 3rem)",
                  animationDuration: "1s",
                  animationName: _animation ? "routePlanTitleGrow" : "",
                  animationFillMode: "forwards",
                }}
              >
                {t(_title)}
              </div>
            ) : (
              _step !== "when" && (
                <div
                  style={{
                    ...styles.title,
                    top: "calc(50% - 3rem)",
                    animationDuration: "2s",
                    animationName: _animation ? "routePlanTitleGrow" : "",
                    animationFillMode: "forwards",
                    transform: !_isInputFocus ? "scale(2)" : "scale(1)",
                  }}
                >
                  {t(_title)}
                </div>
              )
            )}
          </div>
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "50%",
              zIndex: "1",
              top: "3rem",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            {deviceMode !== StyleMode.desktop && _step === "when" && (
              <FromWhereMobile />
            )}
          </div>
          <div
            style={{
              ...styles.cover,
              height: deviceMode === StyleMode.desktop ? "33vh" : "14rem",
            }}
            className={
              deviceMode === StyleMode.desktop
                ? "blurred-container"
                : "blurred-container-mobile"
            }
          >
            <div
              style={{
                ...styles.blurryCover,
                backdropFilter: `${
                  deviceMode === StyleMode.desktop
                    ? `blur(.5rem) brightness(.45)`
                    : `brightness(.4)`
                }`,
                WebkitBackdropFilter: `${
                  deviceMode === StyleMode.desktop
                    ? `blur(.5rem) brightness(.45)`
                    : `brightness(.4)`
                }`,
              }}
            ></div>
          </div>
        </div>

        {(_step === "origin" || _step === "destination") && (
          <RoutePlanSearchStations
            placeHolder={t(
              _step === "origin" ? "Origin-station" : "Destination-station"
            )}
            stationsType={_step}
            handleInputFocus={(isFocus) => {
              if (deviceMode !== StyleMode.desktop) {
                _setIsInputFocus(isFocus);
              }
            }}
          />
        )}
        {_step === "when" && (
          <div style={styles.timePickerWrapper}>
            <RoutePlanTime />
          </div>
        )}
      </div>
    </>
  );
};

const styles: StyleSheet = {
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  coverWrapper: {
    width: "100%",
    position: "relative",
    overflow: "hidden",
  },
  cover: {
    background: `30% 80% url(${Images.coverImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // filter: "blur(3px) brightness(.5)",
  },
  blurryCover: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  title: {
    position: "absolute",
    fontFamily: fonts.FbReformaMedium,
    textShadow: "0 2px 30px rgba(0, 0, 0, 0.7)",
    color: colors.white,
    zIndex: "5",
    width: "100%",
    textAlign: "center",
    fontSize: `2em`,
    marginBlockStart: ` 0.67em`,
    marginBlockEnd: `0.67em`,
    fontWeight: `bold`,
  },
  routePlanButtonsWrapper: {
    position: "absolute",
    // top: "1.5rem",
    zIndex: "2",
  },
  timePickerWrapper: {
    width: "100%",
  },
};

export default RoutePlan;
