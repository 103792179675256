import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import Images from "../../../Data/Images/Images";
import { ILinkOpenType, IMenuItem } from "../../../Interfaces/IMenu";
import { CheckIsEnable } from "../../../Utils/consts";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import i18n from "../../../Services/i18n";
import { useSelector } from "react-redux";
import { PageState } from "../../../Redux/Slices/pageSlice";
import { ITreeData } from "../../../Interfaces/ITree";
import { UmbracoStoreState } from "../../../Redux/Slices/umbracoSlice";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../Services/DeviceService";
import SubMenuItem from "./SubMenuItem";

const MenuItem: React.FC<IMenuItem> = (props) => {
  const {
    label,
    redirectTo,
    subMenus,
    isEnabled,
    openType,
    totalItems,
    currentIndex,
    deviceToDisplay = "Both",
  } = props;
  const navigate = useNavigate();
  const [_isOver, _setIsOver] = useState<boolean>(false);
  const [_currentCategory, _setCurrentCategory] = useState(false);
  const [_itemKeyOver, _setItemKeyOver] = useState("");
  const [_isParentFocus, _setIsParentFocus] = useState(false);
  const [_tabIndex, _setTabIndex] = useState(0);

  const currentPage = (
    useSelector((state: any) => state.PageReducer) as PageState
  ).page;
  const currentTree = (
    useSelector((state: any) => state.UmbracoStoreReducer) as UmbracoStoreState
  ).umbracoStore.treeData as ITreeData;
  const location = useLocation();
  const menuItemRef = useRef<HTMLLIElement>(null);

  const filteredSubMenus = subMenus.filter((item) =>
    CheckIsEnable(item.isEnabled)
  );

  useEffect(() => {
    if (currentPage.length > 0) {
      currentPage.toLowerCase() === redirectTo.toLowerCase()
        ? _setCurrentCategory(true)
        : filteredSubMenus.map(
            (item) =>
              currentPage.toLowerCase() === item.redirectTo.toLowerCase() &&
              _setCurrentCategory(true)
          );
    }
  }, [location, currentTree, currentPage]);

  const redirectToPage = (page?: string, open?: ILinkOpenType[`Type`]) => {
    let currentOpenType = open ? open : openType;
    if (page) {
      switch (currentOpenType) {
        case "Internal":
          navigate(`?page=${page}`);
          break;
        case "External":
          window.open(page, "_blank");
          break;
      }
    }
  };

  const getCursor = () => {
    if (redirectTo === "") {
      return "default";
    }
    if (filteredSubMenus.length > 0 && redirectTo === "") {
      return "default";
    }
    return "pointer";
  };

  switch (CheckIsEnable(isEnabled)) {
    case true:
      return deviceToDisplay === "Both" || deviceToDisplay === "Web" ? (
        <li
          onMouseOver={() => {
            _setIsOver(true);
          }}
          onMouseLeave={() => {
            _setIsOver(false);
          }}
          // aria-expanded={
          //   subMenus.length === 0 ? undefined : _isOver ? "true" : "false"
          // }
          ref={menuItemRef}
          aria-label={
            filteredSubMenus.length === 0
              ? undefined
              : redirectTo
              ? label
              : undefined
          }
          tabIndex={0}
          // tabIndex={!IsMobile() ? 0 : -1}
          className="menu-item"
          style={{
            cursor: getCursor(),
            fontFamily: fonts.FbReformaMedium,
            listStyleImage: "none",
            minWidth: "6rem",
          }}
          key={label}
          // aria-expanded={_isOver ? "true" : "false"}
          // aria-expanded={redirectTo ? "true" : "false"}
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              if (menuItemRef.current) {
                menuItemRef.current.focus();
              }
              _setIsOver(false);
            }
            if (e.key === "Enter") {
              if (!redirectTo) {
                _setIsOver(true);
                if (menuItemRef.current) {
                  // menuItemRef.current.focus();
                }
              }
            }
          }}
          onKeyDownCapture={(e) => {
            if (e.code === "Space") {
              e.preventDefault();
              _setIsOver(true);
            }

            if (e.code === "Enter") {
              e.preventDefault();
              redirectToPage(redirectTo);
            }
          }}
          onKeyPressCapture={() => {
            if (!redirectTo) {
              // _setIsOver(true);
            }
          }}
          onBlurCapture={(e) => {
            if (menuItemRef.current) {
              if (menuItemRef.current.contains(e.relatedTarget)) {
                _setIsOver(true);
              } else {
                _setIsOver(false);
                _setTabIndex(0);
              }
            }
          }}
          onFocusCapture={() => {
            _setIsParentFocus(true);
          }}
        >
          {_isOver && (
            <div
              style={styles.menuParentUnderLine}
              tabIndex={-1}
              aria-hidden={"true"}
            ></div>
          )}
          {_currentCategory && (
            <div
              style={styles.selectedUnderline}
              tabIndex={-1}
              aria-hidden={"true"}
            ></div>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            role={
              filteredSubMenus.length === 0
                ? undefined
                : redirectTo
                ? "application"
                : undefined
            }
            // aria-hidden={
            //   subMenus.length === 0 ? "false" : redirectTo ? "true" : undefined
            // }
          >
            <span
              tabIndex={-1}
              role={filteredSubMenus.length === 0 ? "link" : "button"}
              aria-expanded={
                filteredSubMenus.length === 0
                  ? undefined
                  : _isOver
                  ? "true"
                  : "false"
              }
              onClick={(e) => {
                e.stopPropagation();
                redirectToPage(redirectTo);
                _setIsOver(true);
                _setTabIndex(-1);
              }}
            >
              {label}
            </span>
            {filteredSubMenus.length > 0 && (
              <img
                alt={``}
                src={Images.arrowDown}
                style={styles.arrowDownIcon}
              />
            )}
          </div>
          {_isOver && filteredSubMenus.length > 0 && (
            <ul
              style={{
                ...styles.subMenuContainer,
                right: i18n.dir() === "rtl" ? "0" : "",
                left: i18n.dir() === "ltr" ? "0" : "",
                marginBlockStart: "14px",
              }}
            >
              {filteredSubMenus.map((item, index) => (
                <SubMenuItem
                  key={item.label + index}
                  {...item}
                  itemIndex={index}
                  totalItems={filteredSubMenus.length}
                />
              ))}
            </ul>
          )}
        </li>
      ) : null;
    default:
      return null;
  }
};

const styles: StyleSheet = {
  menuItemsContainer: {
    display: "flex",
    height: "1rem",
    padding: ".5rem .5rem",
    fontSize: fontSize.Text,
    fontFamily: fonts.FbReformaMedium,
    position: "relative",
  },
  subMenuContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minWidth: "15rem",
    position: "absolute",
    marginTop: "1.5rem",
    padding: "1rem 2.2rem",
    backgroundColor: colors.timberwolf,
    boxShadow: `0 15px 30px 0 rgba(0, 0, 0, 0.15)`,
    borderBottomRightRadius: "0.6rem",
    borderBottomLeftRadius: "0.6rem",
    listStyleImage: "none",
    boxSizing: "border-box",
  },
  menuItemParent: {
    display: "flex",
    alignItems: "center",
    gap: ".5rem",
  },
  arrowDownIcon: {},
  menuParentUnderLine: {
    boxSizing: "border-box",
    width: "100%",
    height: "5px",
    backgroundColor: colors.koromiko,
    position: "absolute",
    bottom: "-11px",
    left: "0",
    outline: "none",
  },
  selectedUnderline: {
    position: "absolute",
    width: "100%",
    height: "5px",
    bottom: "-11px",
    left: "0",
    outline: "none",
    backgroundColor: colors.darkBlue,
  },
};

export default MenuItem;
