import { t } from "i18next";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import store from "store2";
import fonts from "../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import Images from "../../../Data/Images/Images";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { IFileElement } from "../../../Interfaces/IUmbraco";
import { IsMobile } from "../../../Services/DeviceService";
import { DownloadFilesAsZipFile } from "../../../Services/FilesToZipService";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import { GetLongTextWithDots } from "../../../Utils/utils";
import CustomModal from "../../CustomModal";
import FileViewer from "../../FileViewer/FileViewer";

const FileElement: React.FC<IFileElement> = (props) => {
  const { fileName, file, openType, anchor, containerStyles, onClick } = props;
  const [_isPreviewModalActive, _setIsPreviewModalActive] = useState(false);
  const navigate = useNavigate();
  const deviceMode = useDeviceMode();

  const redirectToPage = (page?: string) => {
    page && navigate(`?page=${page}`);
  };

  const openPageNewTab = (page?: string) => {
    window.open(`?page=${page}`, "_blank");
  };

  const getFileIcon = () => {
    if (!file.umbracoExtension) return Images.pdfIcon;
    switch (file.umbracoExtension) {
      case "pdf":
        return Images.pdfFile;
      case "docx":
        return Images.wordFile;
      case "xls":
        return Images.excelFile;
    }
  };

  const openFile = () => {
    if (deviceMode !== StyleMode.desktop) {
      DownloadFilesAsZipFile(
        [
          {
            filename: fileName,
            document: file,
          },
        ],
        fileName
      );
      return;
    }

    switch (openType) {
      case "new tab":
        store.session.set("fileElement", file._url);
        store.session.set("fileElementName", fileName);
        openPageNewTab("filePreview_external");
        break;
      case "in window":
        store.session.set("fileElement", file._url);
        store.session.set("fileElementName", fileName);
        redirectToPage("filePreview_internal");
        break;
      case "preview":
        _setIsPreviewModalActive(true);
        break;
      case "download only":
        DownloadFilesAsZipFile(
          [
            {
              filename: fileName,
              document: file,
            },
          ],
          fileName
        );
        break;
    }
  };

  return (
    <Fragment>
      <div
        style={{ ...styles.container, ...containerStyles }}
        anchor-id={anchor}
      >
        <div
          style={styles.fileWrapper}
          onClick={(e) => {
            openFile();
            onClick && onClick(e);
          }}
          role={`button`}
          tabIndex={!IsMobile() ? 0 : -1}
          aria-label={
            openType != "download only"
              ? `${t(`OpenFile`, {
                fileName: fileName,
                fileType: file.umbracoExtension,
              })} ${openType === "new tab"
                ? `${t(`NewTab`)}`
                : openType === `preview`
                  ? `${t(`Preview`)}`
                  : ``
              }`
              : `${t(`Download`)} ${fileName} ${file.umbracoExtension}`
          }
        >
          <img src={getFileIcon()} style={{ scale: "1.5" }} alt={``} />
          <span style={styles.filename}>
            {fileName.length > 65
              ? GetLongTextWithDots(fileName, 65)
              : fileName}
          </span>
        </div>
      </div>
      <CustomModal visible={_isPreviewModalActive}
        onClose={() => _setIsPreviewModalActive(false)}
        showCloseButton="internal" typeStyle={"rectangle"}
        innerStyle={{ width: "85%", maxHeight: "100%", padding: 0, height: "50rem" ,lineHeight: 0,
        maxWidth: "100rem",
        borderRadius: "0.8rem",
          overflow:"hidden"}}
        contentStyle={{ padding: 0 }}
        dirClosebutton={"start"}
      >

        {file && (
          <FileViewer
            files={[
              {
                document: file,
                filename: fileName,
              },
            ]}
            curFileName=""
            title={fileName}
          />
        )}
      </CustomModal>
    </Fragment>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  fileWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    cursor: "pointer",
  },
  filename: {
    fontSize: fontSize.FooterItem,
    fontFamily: fonts.FbReformaRegular,
  }
};

export default FileElement;
