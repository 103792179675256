import axios from "axios";
import Config from "../Configuration/server";
import {
  ISystemParam,
  ITranslations,
  IURLTranslation,
} from "../Interfaces/IConfig";
import { IStation } from "../Interfaces/IStation";
import {
  ICategoryPages,
  IPaths,
  ITree,
  IUmbraco,
  IUmbracoFull,
} from "../Interfaces/ITree";
import { ISEOObject } from "../Interfaces/IUmbraco";
import { setSomethingWentWrong } from "../Redux/Slices/somethingWentWrongSlice";
import Store from "../Redux/Store/Store";

const COMMON = `${Config.BASE_URL}${Config.COMMON}`;
const MANAGMENT = `https://app-rail-back-content-management-qa.azurewebsites.net/api/V1/`;

export const headers = {
  "Ocp-Apim-Subscription-Key": Config["OCP_APIM_SUBSCRIPTION_KEY"]!,
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
};

export const GetStations = async () => {
  try {
    const stations_he = `${COMMON}stations?languageId=Hebrew&systemType=${Config.SYSTEM_TYPE}`;
    const stations_en = `${COMMON}stations?languageId=English&systemType=${Config.SYSTEM_TYPE}`;
    const stations_ru = `${COMMON}stations?languageId=Russian&systemType=${Config.SYSTEM_TYPE}`;
    const stations_ar = `${COMMON}stations?languageId=Arabic&systemType=${Config.SYSTEM_TYPE}`;

    const res = await Promise.all([
      await axios(stations_he, { method: "GET", headers }),
      await axios(stations_en, { method: "GET", headers }),
      await axios(stations_ru, { method: "GET", headers }),
      await axios(stations_ar, { method: "GET", headers }),
    ]);

    const stations = {
      he: res[0].data.result as IStation[],
      en: res[1].data.result as IStation[],
      ru: res[2].data.result as IStation[],
      ar: res[3].data.result as IStation[],
    };

    // Store.dispatch(setSomethingWentWrong(false));
    return stations;
  } catch (error) {
    Store.dispatch(setSomethingWentWrong(true));
    // console.log(error);
  }
};

export const GetUmbracoTreeData = async (language: string) => {
  const Url = `https://localhost:44349/GetTree?culture=${language}`;
  //const Url = `https://localhost:44349/api/GetTree?culture=${language}`;
  try {
    const result = await axios(`${Url}`, {
      method: "GET",
      headers,
    });
    let umbracoData = result.data;
    // Store.dispatch(setSomethingWentWrong(false));
    return (
      (umbracoData as {
        paths: IPaths;
        Tree: ITree[];
        categories: ICategoryPages[];
        seos: ISEOObject;
      }) || {
        seos: {},
        paths: {
          categories: [],
        },
        Tree: [],
        categories: [],
      }
    );
  } catch (error) {
    Store.dispatch(setSomethingWentWrong(true));
    return {
      paths: {
        categories: [],
      },
      Tree: [],
      categories: [],
      seos: {
        contentTypeAlias: "stationsSeo",
        name: "",
        stations: [],
      } as ISEOObject,
    };
  }
};

export const GetAllUmbracoData = async (
  language: string,
  isPreview = false
) => {
  const Url = `${Config.UMBRACO_CONTENT}/api/RailWeb/GetUmbracoContentsByLanguage?culture=${language}`;
  //  const Url = `https://localhost:44349/api/RailWeb/GetUmbracoContentsByLanguage?culture=${language}`;
  try {
    const result = await axios(`${Url}`, {
      method: "GET",
      headers: {
        "Ocp-Apim-Subscription-Key": Config["OCP_APIM_SUBSCRIPTION_KEY"]!,
      },
    });
    let umbracoData = result.data;
    // Store.dispatch(setSomethingWentWrong(false));
    return umbracoData as IUmbracoFull;
  } catch (error) {
    Store.dispatch(setSomethingWentWrong(true));
  }
};

export const GetUmbracoTranslations = async (language: string) => {
  const Url = `${Config.UMBRACO_CONTENT}/api/RailWeb/GetUmbracoContentsByLanguage?culture=${language}`;
  // const Url = `https://localhost:44349/api/RailWeb/GetUmbracoContentsByLanguage?culture=${language}`;
  try {
    const result = await axios(`${Url}`, {
      method: "GET",
      headers: {
        "Ocp-Apim-Subscription-Key": Config["OCP_APIM_SUBSCRIPTION_KEY"]!,
      },
    });
    let translations = result.data as {
      translations: {
        result: { [key: string]: string };
      };
    };
    // Store.dispatch(setSomethingWentWrong(false));
    return translations;
  } catch (error) {
    Store.dispatch(setSomethingWentWrong(true));
  }
};

// system params services start

let SystemParams: ISystemParam[] = [];

export const GetSystemParams = async (): Promise<ISystemParam[]> => {
  if (SystemParams.length <= 0) {
    SystemParams = await getSystemParams();
  }
  return SystemParams;
};
export const GetSystemParamsKey = async (): Promise<{
  [key: string]: ISystemParam;
}> => {
  const systemParams = await GetSystemParams();

  const systemParamsKey = systemParams.reduce((prev, current) => {
    prev = {
      ...prev,
      [current.key]: current,
    };
    return prev;
  }, {});
  return systemParamsKey;
};

export const GetURLTranslations = async () => {
  try {
    const res = await axios(
      `${COMMON}/URLTranslations?SystemType=${Config.SYSTEM_TYPE}`,
      {
        method: "GET",
        headers,
      }
    );
    // Store.dispatch(setSomethingWentWrong(false));

    return res.data.result as IURLTranslation;
  } catch (error) {
    Store.dispatch(setSomethingWentWrong(true));
    // console.log(error);
  }
};

const getSystemParams = async () => {
  try {
    const res = await axios(
      `${COMMON}/Configuration/bysystemtype?systemTypeId=${Config.SYSTEM_TYPE}`,
      {
        method: "GET",
        headers,
      }
    );
    // Store.dispatch(setSomethingWentWrong(false));

    return res.data.result;
  } catch (error) {
    Store.dispatch(setSomethingWentWrong(true));
    // console.log(error);
  }
};
// system params services end
//Soft Updates Replace Interval
export let Translations: ITranslations = { he: {}, ar: {}, en: {}, ru: {} };

export const GetTranslations = async (): Promise<ITranslations> => {
  if (Translations.he) {
    Translations = await getTranslations();
  }
  return Translations;
};

const getTranslations = async () => {
  try {
    const res = await axios(
      `${COMMON}/Translations?SystemType=${Config.SYSTEM_TYPE}`,
      {
        method: "GET",
        headers,
      }
    );
    // Store.dispatch(setSomethingWentWrong(false));

    // console.log(res.data.result)
    return res.data.result;
  } catch (error) {
    Store.dispatch(setSomethingWentWrong(true));
    // console.log(error);
  }
};

export const getCurrentTranslation = async (
  language: `he` | `en` | `ar` | `ru`
) => {
  let result = await GetTranslations();

  return result[language];
};
