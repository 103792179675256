import { ITableUpgraded } from "../../../Interfaces/IUmbraco";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import Title from "../Title";
import { useEffect, useRef } from "react";
import { replaceRichtextBoldWithFont } from "../../../Utils/utils";
import {
  AddTableAccessability,
  HandleTableMargin,
  HandleTableStyles,
} from "../../../Services/TableService";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";

const TableUppgraded: React.FC<ITableUpgraded> = (props) => {
  const { title, table, tableStyles, tableMargin } = props;
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (tableRef.current) {
      AddTableAccessability(tableRef.current, title);
      if (tableStyles && tableStyles.length > 0)
        HandleTableStyles(tableRef.current, tableStyles);
      if (tableMargin) HandleTableMargin(tableRef.current, tableMargin);
    }
  }, []);

  return (
    <div style={styles.tableContainer}>
      <Title text={title} />
      <div
        ref={tableRef}
        dangerouslySetInnerHTML={{
          __html: replaceRichtextBoldWithFont(table),
        }}
      ></div>
    </div>
  );
};

const styles: StyleSheet = {
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    width: "80%",
    maxWidth: "76rem",
    overflow: "auto",
  },
  title: {
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.UmbracoElementTitle,
    borderBottom: `1px solid ${colors.koromiko}`,
    color: colors.darkBlue,
    borderBottomWidth: ".2rem",
    padding: ".5rem 0",
  },
};

export default TableUppgraded;
