import { useEffect, useState } from "react";
import store from "store2";
import colors from "../../Assets/Colors/Colors.json";
import Images from "../../Data/Images/Images";
import { ILinesMapResponse } from "../../Interfaces/ILinesMap";
import {
  ILinesArrays,
  IRouteDetailsMap,
} from "../../Interfaces/IRouteDetailsMap";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { ITrain } from "../../Interfaces/ITrain";
import { GetStationMapSVG } from "../../Services/CommonService";
import i18n from "../../Services/i18n";

const colorArrays: ILinesArrays = {
  darkgreenline: [
    1600, 1500, 1400, 1220, 2100, 2200, 2300, 3500, 3600, 3700, 4600, 4900,
    5000, 5010, 5300, 6900, 6150, 7000, 8550, 7300, 7320,
  ] /*V*/,
  orangeline: [
    1840, 1820, 1400, 1220, 2100, 2200, 2300, 3100, 3500, 3600, 3700, 4600,
    4900, 5000, 7000, 7300, 7320,
  ] /*V*/,
  darkredline: [1840, 1820, 1400, 700, 1300, 1220, 2100, 2200, 2300] /*V*/,
  lightgreenline: [
    1600, 1500, 1400, 700, 1300, 1220, 2100, 2200, 2300, 2500, 2800, 3600, 3700,
    4600, 4900, 8600, 300, 400,
  ],
  purpleline: [1280, 1260, 1250, 1240, 1220, 2100, 2200, 2300, 2500],
  darkblueline: [
    2800, 2820, 3100, 3300, 3310, 3400, 3500, 3600, 3700, 4600, 4900, 4800,
    5150, 5000, 5300, 5200, 5410, 5800, 5900, 9600, 9650, 9700, 7300, 7320,
  ],
  lightblueline: [3300, 3400, 3500, 3600, 3700, 4600, 5150, 5000, 5010, 6300],
  nightline: [2800, 3100, 3300, 3500, 3700, 8600, 680],
  pinkline: [3500, 3600, 3700, 4600, 4900, 8600, 680],
  redline: [
    3500, 2940, 2960, 9200, 8700, 8800, 4250, 4170, 4100, 3600, 3700, 4600,
    4900, 4640, 4660, 4680, 4690, 9800, 9000, 5800, 5900,
  ],
  darkpinkline: [400, 300, 680],
  darkpurpleline: [5000, 9100],
  brownline: [7300, 7500],
};
const RouteDetailsMap: React.FC<IRouteDetailsMap> = (props) => {
  const [_svg, _setSvg] = useState("");
  const {
    width = "34rem",
    zoom = "135%",
    id = "mapContainer",
    close,
    trains = [],
  } = props;
  const findClosestMatch = (linesData: any, targetArray: any[]): string => {
    let closestLine = "";
    let closestSimilarity = -1;

    for (const lineName in linesData) {
      const line = linesData[lineName];
      const similarity = calculateSimilarity(line, targetArray);
      if (similarity > closestSimilarity) {
        closestSimilarity = similarity;
        closestLine = lineName;
      }
    }

    return closestLine;
  };

  const calculateSimilarity = (line: any, targetArray: any) => {
    const commonElements = line.filter((value: any) =>
      targetArray.includes(value)
    );
    return commonElements.length;
  };

  const stopCalc = (
    train: ITrain,
    svgString: string,
    haveNexttrain?: boolean
  ) => {
    const path = train.routeStations.map((item: any) => item.stationId);
    const closestMatch = findClosestMatch(colorArrays, path);

    const pathStop: number[] = [
      train.orignStation,
      ...train.stopStations.map((item: any) => item.stationId),
      train.destinationStation,
    ];
    const parser = new DOMParser();
    const serializer = new XMLSerializer();
    const xmlDoc = parser.parseFromString(svgString, "text/xml");
    if (!xmlDoc.documentElement) {
      throw new Error("Invalid SVG string");
    }
    const spliceArrayA = colorArrays[closestMatch].indexOf(pathStop[0]);
    const spliceArrayB = colorArrays[closestMatch].indexOf(
      pathStop[pathStop.length - 1]
    );
    const finalStop = colorArrays[closestMatch].slice(
      Math.min(spliceArrayA, spliceArrayB),
      Math.max(spliceArrayA + 1, spliceArrayB + 1)
    );

    const pathElements = xmlDoc.querySelectorAll(`path.${closestMatch}`);

    pathElements.forEach((pathElement, index) => {
      const classAttribute = pathElement.getAttribute("class");
      if (classAttribute) {
        const idAttribute = pathElement.getAttribute("id");
        if (idAttribute) {
          const [first, second] = idAttribute.split("-");

          pathElement.setAttribute(
            "fromStation",
            Math.min(Number(first), Number(second)).toString()
          );
          pathElement.setAttribute(
            "toStation",
            Math.max(Number(first), Number(second)).toString()
          );
          if (
            finalStop.includes(Number(first)) &&
            finalStop.includes(Number(second))
          ) {
            pathElement.classList.remove("inactiveline");
            pathElement.classList.add(`currentlocation-map`);
            train.orignStation < train.destinationStation &&
              pathElement.classList.add(`reverse`);
          }
        }
      }
    });
    if (haveNexttrain) {
      const changeStation = xmlDoc.getElementById(
        `stationgroup${train.destinationStation}`
      );
      const pathChanges = changeStation?.querySelectorAll("path")!;
      pathChanges.forEach((pathChange, index) => {
        pathChange.classList.add("currentlocation-map");
      });
    }
    return serializer.serializeToString(xmlDoc);
  };

  const modifySVGMap = (svgString: string): string => {
    const parser = new DOMParser();
    const serializer = new XMLSerializer();
    const xmlDoc = parser.parseFromString(svgString, "text/xml");

    if (!xmlDoc.documentElement) {
      throw new Error("Invalid SVG string");
    }

    const pathElements = xmlDoc.querySelectorAll("path");

    pathElements.forEach((pathElement) => {
      const classAttribute = pathElement.getAttribute("class");
      if (classAttribute) {
        if (
          classAttribute.startsWith("darkgreenline") ||
          classAttribute.startsWith("orangeline") ||
          classAttribute.startsWith("darkredline") ||
          classAttribute.startsWith("lightgreenline") ||
          classAttribute.startsWith("purpleline") ||
          classAttribute.startsWith("darkblueline") ||
          classAttribute.startsWith("lightblueline") ||
          classAttribute.startsWith("nightline") ||
          classAttribute.startsWith("pinkline") ||
          classAttribute.startsWith("redline") ||
          classAttribute.startsWith("darkpinkline") ||
          classAttribute.startsWith("darkpurpleline") ||
          classAttribute.startsWith("brownline")
        ) {
          pathElement.setAttribute("class", classAttribute + " inactiveline");
        }
        const idAttribute = pathElement.getAttribute("id");
        if (idAttribute) {
          const [first, second] = idAttribute.split("-");

          pathElement.setAttribute("fromStation", first);
          pathElement.setAttribute("toStation", second);
        }
      }
    });
    let modifiedSVGStrings = serializer.serializeToString(xmlDoc);
    for (let index = 0; index < trains.length; index++) {
      const element = trains[index];
      modifiedSVGStrings = stopCalc(
        element,
        modifiedSVGStrings,
        index < trains.length - 1
      );
    }
    return modifiedSVGStrings;
  };

  useEffect(() => {
    let isMounted = true;
    (async () => {
      const linesMapLanguages = store.session.get(
        "linesMapLanguages"
      ) as ILinesMapResponse | null;

      if (isMounted) {
        let svgData: ILinesMapResponse | undefined;
        if (linesMapLanguages === null) {
          svgData = await GetStationMapSVG();
          store.session.set("linesMapLanguages", svgData);
        } else {
          svgData = linesMapLanguages;
        }

        if (svgData) {
          let svgLanguage = svgData.codeHe;
          switch (i18n.language) {
            case "he":
              svgLanguage = svgData.codeHe;
              break;
            case "en":
              svgLanguage = svgData.codeEn;
              break;
            case "ru":
              svgLanguage = svgData.codeRu;
              break;
            case "ar":
              svgLanguage = svgData.codeAR;
              break;
          }

          const modifiedSVGMap = modifySVGMap(svgLanguage);

          _setSvg(modifiedSVGMap);
        }
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [trains]);

  return (
    <div>
      <div id={id} style={{ ...styles.mapContainer, zoom, width }}>
        <div style={styles.floatIcon} onClick={props.onClickZoom}>
          {close ? <img src={Images.close} /> : <img src={Images.search} />}
        </div>
        <div
          style={styles.map}
          id={`Map`}
          dangerouslySetInnerHTML={{ __html: _svg }}
        ></div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  mapContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    zoom: "135%",
    width: "45rem",
  },
  map: {
    border: `0.15rem solid ${colors.darkBlue}`,
    boxShadow: `0 5px 11px 0 ${colors.black}`,
    borderRadius: ".7rem",
    overflow: "hidden",
    // backgroundColor: colors.darkBlue,
    width: "100%",
    direction: "ltr",
  } as React.CSSProperties,
  floatIcon: {
    position: "absolute",
    top: 15,
    right: 15,
    cursor: "pointer",
  },
};

export default RouteDetailsMap;
