import { t } from "i18next";
import moment from "moment";
import QueryString from "qs";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import { IScheduleTimePlan } from "../../Interfaces/IScheduleTimePlan";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import {
  ScheduleState,
  setDate,
  setHours,
  setMinutes,
  setScheduleType,
} from "../../Redux/Slices/scheduleSlice";
import {
  FormatDateShort,
  FormatDateYYYYMMDD,
  FormatDateYYYYMMDD_Dots,
  GetNextDay,
  IsToday,
  IsTommorow,
} from "../../Services/DateService";
import i18n from "../../Services/i18n";
import { AddTravelToLastTravels } from "../../Services/LastTravelsService";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode, {
  GetWindowDimensions,
} from "../../Utils/useWindowDimensions";
import DatePicker from "../DatePicker/DatePicker";
import TimePickers from "../TimePickers/TimePickers";
import WheelPicker from "../WheelPicker/WheelPicker";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { getMobileOS } from "../../Utils/useDeviceOS";
import "moment/locale/he";

const RoutePlanTime: React.FC<IScheduleTimePlan> = (props) => {
  const { hasSearchButton = true, stylePlan } = props;
  const [_selectedCapsule, _setSelectedCapsule] = useState(0);
  const [_iHoverSearchButton, _setIsHoverSearchButton] = useState(false);
  const [_datePickerVisible, _setDatePickerVisible] = useState(false);
  const [_selectedDate, _setSelectedDate] = useState<Date>(new Date());

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const deviceMode = useDeviceMode();

  const schedule = useSelector(
    (state: any) => state.ScheduleReducer
  ) as ScheduleState;
  const [_tabs, _setTabs] = useState([
    { label: t("Today"), id: 0 },
    { label: t("Tomorrow"), id: 1 },
    { label: t("Different-date"), id: 2 },
  ]);

  const refToday = useRef<HTMLInputElement>(null);
  const refTomorrow = useRef<HTMLInputElement>(null);
  const refDifferent = useRef<HTMLInputElement>(null);

  const scheduleTypesData = [
    { value: t("Departure"), id: "0" },
    { value: t("Arrival"), id: "1" },
  ];

  useEffect(() => {
    const wheelPickerId = document.getElementById("wheelPickerId");
    wheelPickerId &&
      wheelPickerId.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
  }, []);

  useEffect(() => {
    const urlParams = location.search.split("?")[1] || "";
    if (urlParams) {
      const allParams = QueryString.parse(urlParams);
      const { date, hours, minutes } = allParams;
      if (date && hours && minutes) {
        const urlDate = new Date(`${date} ${hours}:${minutes}`);
        let tabIndex = 0;
        if (IsToday(urlDate)) {
          tabIndex = 0;
        } else if (IsTommorow(urlDate)) {
          tabIndex = 1;
        } else {
          tabIndex = 0;
        }
        handleTabClick(tabIndex);
      }
    }
  }, [location]);

  const MoveFocusToNextRadio = (e: KeyboardEvent) => {
    switch (document.activeElement) {
      case refToday.current:
        switch (e.key) {
          case `ArrowLeft`:
            if (i18n.language === (`he` || `ar`)) refTomorrow.current?.focus();
            break;
          case `ArrowRight`:
            if (i18n.language === (`en` || `ru`)) refTomorrow.current?.focus();
            break;

          default:
            break;
        }
        break;
      case refTomorrow.current:
        switch (e.key) {
          case `ArrowLeft`:
            if (i18n.language === (`he` || `ar`)) refDifferent.current?.focus();
            if (i18n.language === (`en` || `ru`)) refToday.current?.focus();
            break;
          case `ArrowRight`:
            if (i18n.language === (`he` || `ar`)) refToday.current?.focus();
            if (i18n.language === (`en` || `ru`)) refDifferent.current?.focus();
            break;

          default:
            break;
        }
        break;
      case refDifferent.current:
        switch (e.key) {
          case `ArrowLeft`:
            if (i18n.language === (`en` || `ru`)) refTomorrow.current?.focus();
            break;
          case `ArrowRight`:
            if (i18n.language === (`he` || `ar`)) refTomorrow.current?.focus();
            break;

          default:
            break;
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", MoveFocusToNextRadio);
    return () => {
      document.removeEventListener("keydown", MoveFocusToNextRadio);
    };
  }, []);

  const handleTabClick = (tabIndex: number) => {
    _setSelectedCapsule(tabIndex);
    _setDatePickerVisible(tabIndex === 2 && !_datePickerVisible);

    if (tabIndex === 0) {
      dispatch(setDate(FormatDateYYYYMMDD(moment().toDate())));
    }
    if (tabIndex === 1) {
      dispatch(setDate(FormatDateYYYYMMDD(GetNextDay().toDate())));
    }
    if (tabIndex === 2) {
      const otherDate = [{ ..._tabs[0] }, { ..._tabs[1] }];
      if (
        schedule.date &&
        !IsToday(new Date(schedule.date)) &&
        !IsTommorow(new Date(schedule.date))
      ) {
        _setTabs([
          ...otherDate,
          { label: FormatDateShort(new Date(schedule.date)), id: 2 },
        ]);
      } else {
        _setTabs([
          { label: t("Today"), id: 0 },
          { label: t("Tomorrow"), id: 1 },
          { label: t("Different-date"), id: 2 },
        ]);
      }
    }
  };

  const handleDatePickerSelection = (selectedDate: Date) => {
    _setSelectedDate(selectedDate);
    const formattedDate = FormatDateYYYYMMDD(selectedDate);
    dispatch(setDate(formattedDate));

    const otherDate = [{ ..._tabs[0] }, { ..._tabs[1] }];
    _setTabs([...otherDate, { label: FormatDateShort(selectedDate), id: 2 }]);
    setTimeout(() => {
      _setDatePickerVisible(false);
      refDifferent.current?.focus();
    }, 0);
  };

  const handleSearchTrains = () => {
    const params = QueryString.stringify(schedule);
    navigate(`?page=routePlanSearchResults&${params}`);
    AddTravelToLastTravels(schedule);
  };
  // Allows you to reduce the size of the font by up to 0.85 percent (intended for narrow screens)
  const width = GetWindowDimensions().width;
  const capsole = width / 3;
  const text =
    10 * 1.5 * parseFloat(getComputedStyle(document.documentElement).fontSize);
  const capsuleTextCompression = Math.max(Math.min(1, capsole / text), 0.85);
  const isSafariOs = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  // END
  return (
    <div
      style={{
        ...styles.mainWrapper,
        minHeight:
          deviceMode !== StyleMode.desktop ? (isSafariOs ? "" : "") : "",
      }}
    >
      <div style={styles.capsuleWrapper}>
        <div
          style={{
            position: "absolute",
            top: ".08rem",
            width: "33%",
            backgroundColor: colors.chooseTimeCapsula,
            height: "85%",
            left:
              i18n.dir() === "rtl"
                ? _selectedCapsule === 0
                  ? "66%"
                  : _selectedCapsule === 1
                  ? "33%"
                  : "0"
                : "",
            right:
              i18n.dir() === "ltr"
                ? _selectedCapsule === 2
                  ? "0"
                  : _selectedCapsule === 1
                  ? "33%"
                  : "66%"
                : "",
            transition: `${i18n.dir() === "rtl" ? "left" : "right"} 180ms`,
            border: `3px solid ${colors.white}`,
            borderRadius: "3rem",
            borderColor: colors.white,
            cursor: "pointer",
            fontSize: fontSize.H1,
            whiteSpace: "nowrap",
            zIndex: "-1",
          }}
        ></div>
        {_tabs.map((button, index) => (
          <div
            // type={`radio`}
            key={index}
            ref={
              index === 0 ? refToday : index === 1 ? refTomorrow : refDifferent
            }
            role={"radio"}
            // checked={index === _selectedCapsule}
            aria-checked={index === _selectedCapsule}
            tabIndex={index === _selectedCapsule ? 0 : -1}
            aria-label={
              index !== 2
                ? `${button.label}`
                : _selectedCapsule !== index
                ? `${t(
                    button.label === t("Different-date")
                      ? "OpenCalendar"
                      : "SelectedDateOpenCalendar",
                    {
                      date: moment(_selectedDate).locale("he").format("LL"),
                    }
                  )}`
                : `${t(
                    button.label === t("Different-date")
                      ? "OpenCalendar"
                      : "SelectedDateOpenCalendar",
                    {
                      date: moment(_selectedDate).locale("he").format("LL"),
                    }
                  )}`
            }
            onClick={() => handleTabClick(index)}
            onKeyPress={() => handleTabClick(index)}
            style={{
              ...styles.tab,
              fontSize:
                deviceMode !== StyleMode.desktop
                  ? 1.5 * capsuleTextCompression + "rem"
                  : stylePlan
                  ? 1.5 * capsuleTextCompression + "rem"
                  : 2 * capsuleTextCompression + "rem",
              color:
                index === _selectedCapsule
                  ? colors.white
                  : deviceMode === StyleMode.desktop
                  ? colors.nero
                  : colors.trolleyGrey,
              fontFamily:
                deviceMode === StyleMode.desktop
                  ? fonts.FbReformaRegular
                  : fonts.FbReformaMedium,
            }}
          >
            {" "}
            {button.label}
          </div>
        ))}
      </div>
      {!_datePickerVisible && (
        <div
          style={{
            ...styles.wheelPickersWrapper,
            gap: i18n.dir() === "rtl" ? "20%" : "10%",
            height:
              deviceMode === StyleMode.desktop
                ? "24rem"
                : deviceMode === StyleMode.mobile
                ? getMobileOS() !== "IOS"
                  ? ""
                  : ""
                : "",
          }}
        >
          <WheelPicker
            data={scheduleTypesData}
            onChange={(selectedScheduleType) => {
              if (
                parseInt(selectedScheduleType.id) + 1 !==
                schedule.scheduleType
              ) {
                dispatch(
                  setScheduleType(parseInt(selectedScheduleType.id) + 1)
                );
              }
            }}
            defaultSelectedIndex={
              schedule.scheduleType > 0 ? `${schedule.scheduleType - 1}` : "0"
            }
            wheelId={"scheduleType-wheel"}
            isArrows={false}
            width={
              i18n.dir() === "ltr"
                ? i18n.language === "ru"
                  ? 15
                  : 11.3
                : undefined
            }
          />
          <div style={styles.wheelPicker}>
            {schedule.date && schedule.hours && schedule.minutes && (
              <TimePickers
                date={schedule.date}
                hours={schedule.hours}
                minutes={schedule.minutes}
                divider={15}
                onMinutesSelected={(selectedMinutes) => {
                  dispatch(setMinutes(selectedMinutes.value.toString()));
                }}
                onHoursSelected={(selectedHours) => {
                  dispatch(setHours(selectedHours.value.toString()));
                }}
              />
            )}
          </div>
        </div>
      )}

      {_datePickerVisible && schedule.date && (
        <DatePicker
          onChange={handleDatePickerSelection}
          selectedDate={schedule.date}
        />
      )}
      <div
        style={{
          paddingTop: "2rem",
          zIndex: "1",
        }}
      >
        {!_datePickerVisible &&
          hasSearchButton &&
          deviceMode === StyleMode.desktop && (
            <button
              style={{
                ...styles.searchTrainsButton,
                filter: _iHoverSearchButton ? "brightness(0.95)" : "",
              }}
              onMouseMove={() => {
                _setIsHoverSearchButton(true);
              }}
              onMouseLeave={() => {
                _setIsHoverSearchButton(false);
              }}
              onClick={handleSearchTrains}
            >
              {t("Search-trains")}
            </button>
          )}
      </div>
      {!_datePickerVisible &&
        hasSearchButton &&
        deviceMode !== StyleMode.desktop && (
          <button
            style={{
              ...styles.searchTrainsButtonMobile,
              filter: _iHoverSearchButton ? "brightness(0.95)" : "",
            }}
            onMouseMove={() => {
              _setIsHoverSearchButton(true);
            }}
            onMouseLeave={() => {
              _setIsHoverSearchButton(false);
            }}
            onClick={handleSearchTrains}
          >
            {t("Search-trains")}
          </button>
        )}
    </div>
  );
};

const styles: StyleSheet = {
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    position: "absolute",
    fontSize: fontSize.PageTitle,
    color: colors.white,
  },
  capsuleWrapper: {
    zIndex: "3",
    display: "flex",
    justifyContent: "center",
    backgroundColor: colors.white,
    width: "90%",
    maxWidth: "45rem",
    height: "4rem",
    textAlign: "center",
    borderRadius: "3rem",
    boxShadow: "-1px 1px 33px 1px rgb(64 60 67 / 40%)",
    transform: "translateY(-2.2rem)",
    position: "relative",
  },
  tab: {
    width: "33%",
    cursor: "pointer",
    /*fontSize: fontSize.H1,*/
    alignSelf: "center",
    whiteSpace: "nowrap",
  },
  capsuleButtonSelected: {
    color: colors.white,
  },
  wheelPickersWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "24rem",
    flexDirection: "row-reverse",
  },
  searchWrapper: {
    marginTop: "10rem",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    bottom: "0",
  },
  searchTrainsButton: {
    backgroundColor: colors.koromiko,
    width: "24rem",
    height: "3.3rem",
    borderRadius: "24rem",
    border: "none",
    fontSize: fontSize.UmbracoElementTitle,
    fontFamily: fonts.FbReformaMedium,
    boxShadow: `0 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 15px 0 rgba(0, 0, 0, 0.19)`,
    cursor: "pointer",
    marginBottom: "1rem"
  },
  searchTrainsButtonMobile: {
    backgroundColor: colors.koromiko,
    position: "fixed",
    bottom: "0",
    minHeight: "5rem",
    border: "none",
    fontSize: fontSize.H1,
    fontFamily: fonts.FbReformaMedium,
    width: "100%",
    color: colors.black,
  },
};

export default RoutePlanTime;
