
import { useEffect } from "react";
import colors from "../Assets/Colors/Colors.json";
import fonts from "../Assets/Fonts/Fonts.json";
import { StyleSheet } from "../Interfaces/IStyleSheet";
import { StyleMode } from "../Utils/consts";
import {  xmlSiteMap } from "../Utils/sitemap";
import useDeviceMode from "../Utils/useWindowDimensions";
import fontSize from "../Assets/Fonts/FontsSizes.json";



const XmlSiteMap: React.FC = () => {
  const deviceMode = useDeviceMode();

  const SiteMapXml = () => {
    const line = `${xmlSiteMap}`;
    const xmlBlob = new Blob([line], {type: 'text/plain'});
    const filename = "siteMap.xml";
   
    useEffect(() => {
      let a = document.createElement('a');

      a.setAttribute('href', window.URL.createObjectURL(xmlBlob));
      a.click();
    }, [])
  
   
    return (
      <>
        <div style={{...styles.container,direction:`ltr`}}>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        style={{
          ...styles.mainContainer,
          paddingTop: deviceMode === StyleMode.desktop ? "3rem" : "",
        }}
      >
        <div style={styles.bodyBorderMobile}>
          <div style={styles.mainCardMobile}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "3rem",
                padding: "5rem 0",
                alignItems: `flex-start`,
              }}
            >
              <SiteMapXml />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const styles: StyleSheet = {
  container: {
    display: "",
    flexDirection: "column",
    alignItems: "center",
  },
  mainContainer: {
    alignItems: "center",
    flexDirection: "column",
    zIndex: "10",
  },
  bodyBorderMobile: {
    display: `grid`,
    justifyContent: `left`,
    marginLeft: `6rem`,
  },
  mainCardMobile: {
    width: "80%",
    marginTop: "1%",
    minHeight: "40rem",
  }
};

export default XmlSiteMap;



