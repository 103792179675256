import { t } from "i18next";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { IInfoCards } from "../../Interfaces/IUmbraco";
import { StyleMode } from "../../Utils/consts";
import { RemoveHtmlTags } from "../../Utils/helpers";
import useDeviceMode from "../../Utils/useWindowDimensions";
import {
  GetLongTextWithDots,
  replaceRichtextBoldWithFont,
} from "../../Utils/utils";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../Services/DeviceService";

const InfoCards: React.FC<IInfoCards> = (props) => {
  const { infoCards } = props;
  const deviceMode = useDeviceMode();
  const navigate = useNavigate();
  const [_itemKeyOver, _setItemKeyOver] = useState("");
  const isSafariOs = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const redirectToPage = (page?: string) => {
    page && navigate(`?page=${page}`);
  };

  return (
    <div
      className="info-cards"
      style={{
        ...styles.infoCards,
        flexDirection:
          deviceMode === StyleMode.desktop || deviceMode === StyleMode.tablet
            ? "row"
            : "column",
      }}
    >
      {infoCards.map((infoCard, key) => (
        <div
          id={`infoCard${key}`}
          key={key}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "2.5rem",
            minHeight:
              deviceMode === StyleMode.mobile && isSafariOs ? "20rem" : "26rem",
          }}
        >
          <img
            src={infoCard.baseElement.image?._url}
            style={{
              width: "15rem",
              height: "15rem",
              maxWidth: "15rem",
            }}
            max-width={`${
              document.getElementById(`infoCard${key}`)?.clientWidth
            }`}
            height={`auto`}
            alt={``}
          />
          <h2
            style={{
              fontFamily: fonts.FbReformaMedium,
              fontSize: fontSize.UmbracoElementTitle,
              color: colors.darkBlue,
              marginBlockStart: "0",
              marginBlockEnd: "0",
              flex: 1,
            }}
          >
            {infoCard.baseElement.titleAndDescription?.title}
          </h2>
          <div
            style={styles.description}
            title={
              infoCard.baseElement.titleAndDescription!.description.length > 120
                ? RemoveHtmlTags(
                    infoCard.baseElement.titleAndDescription!.description
                  )
                : ""
            }
            dangerouslySetInnerHTML={{
              __html: replaceRichtextBoldWithFont(
                infoCard.baseElement.titleAndDescription!.description.length >
                  120
                  ? GetLongTextWithDots(
                      infoCard.baseElement.titleAndDescription!.description,
                      120
                    )
                  : infoCard.baseElement.titleAndDescription!.description
              ),
            }}
          ></div>
          <div
            onMouseOver={() => {
              _setItemKeyOver(key.toString());
            }}
            onMouseLeave={() => {
              _setItemKeyOver("");
            }}
            style={{
              color: colors.oldLavender,
              fontFamily: fonts.FbReformaRegular,
              fontSize: fontSize.Paragraph,
              flex: 1,
              cursor: "pointer",
              display: "flex",
              gap: ".25rem",
              textDecoration:
                _itemKeyOver === key.toString() ? "underline" : "",
            }}
            aria-label={
              t("More-info") +
              t("About") +
              ` ` +
              `${RemoveHtmlTags(
                infoCard.baseElement.titleAndDescription!.title
              )}! ` /*+ `${infoCard.baseElement.link?.text}`*/
            }
            role={"link"}
            tabIndex={!IsMobile() ? 0 : -1}
            onClick={() => {
              redirectToPage(infoCard.baseElement.link!.redirectTo);
            }}
          >
            <span>{infoCard.baseElement.link?.text}</span>
            <span aria-hidden={"true"}>{">"}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

const styles: StyleSheet = {
  infoCards: {
    display: "flex",
    justifyContent: "center",
    gap: "6rem",
    flexWrap: "wrap",
  },
  mainHeader: {
    padding: "1rem 10rem",
    fontSize: fontSize.H1,
  },
  containerStyle: {
    backgroundColor: `${colors.white}`,
    textAlign: "center",
    padding: "1.5rem 1rem",
    minHeight: "18rem",
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    fontFamily: fonts.FbReformaRegular,
    marginBottom: "3rem",
    flexDirection: "column",
  },
  headerStyle: {
    textAlign: "center",
    fontSize: fontSize.UmbracoElementTitle,
    fontWeight: "bold",
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaRegular,
    flex: "0",
  },
  contentStyle: {
    flex: "2",
    letterSpacing: "0.038125",
    fontSize: fontSize.Paragraph,
    maxWidth: "22rem",
  },
  imageStyle: {
    width: "15rem",
    height: "15rem",
  },
  linkStyle: {
    flex: "0",
    fontSize: fontSize.Paragraph,
    color: colors.cyan,
  },
  description: {
    fontFamily: fonts.FbReformaLight,
    lineHeight: "1.75rem",
    fontSize: fontSize.Paragraph,
    width: "20.375rem",
    textAlign: "center",
    marginTop: "-1rem",
    flex: 10,
  },
};

export default InfoCards;
