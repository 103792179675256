import { MouseEventHandler, useEffect, useState } from "react";
import {
  IAuctionsSystemExemptionNotices,
  IAuctionsSystemSingleSupplier,
  IAuctionsSystemAuctions,
  IAuctionsSystemTypes,
} from "../Auctions/src/Interfaces_tenders/IAuctionsSystemDetails";

import { IsDateBetween, IsDateBetweenTender } from "./DateService";

interface IAuctionsSearch {
  currentTypes?: IAuctionsSystemTypes[];
  currentAuctions?: IAuctionsSystemAuctions[];
  currentOpenAuctions?: IAuctionsSystemAuctions[];
  currentCloseAuctions?: IAuctionsSystemAuctions[];
  currentResultAuctions?: IAuctionsSystemAuctions[];
  currentSingleSuppliers?: IAuctionsSystemSingleSupplier[];
  currentExemptionNotices?: IAuctionsSystemExemptionNotices[];
  searchValue: string;
  selectedDomain: string;
  selectedType: string;
  fromDate: Date;
  toDate: Date;
  typeOfContent: string;
}
export const SearchAndGetAuctionsServer = (props: IAuctionsSearch) => {
  const {
    currentTypes,
    //  currentAuctions,
    currentOpenAuctions,
    currentCloseAuctions,
    currentResultAuctions,
    currentSingleSuppliers,
    currentExemptionNotices,
    searchValue,
    selectedDomain,
    selectedType,
    fromDate,
    toDate,
    typeOfContent,
  } = props;


  if (currentTypes && currentOpenAuctions && typeOfContent === "openauctions") {
    return currentOpenAuctions
      /*.sort(
        (a, b) =>
          new Date(b.biddingDate).getTime() - new Date(a.biddingDate).getTime()
      )*/

      .filter((currentAuction: any) => {
        const auction = currentAuction as IAuctionsSystemAuctions;
        return FilterAuctions({
          currentTypes,
          auction,
          searchValue,
          selectedDomain,
          selectedType,
          fromDate,
          toDate,
          typeOfContent,
        });
      });
  } else if (
    currentTypes &&
    currentCloseAuctions &&
    typeOfContent === "closedauctions"
  ) {
    return currentCloseAuctions
      /*.sort(
        (a, b) =>
          new Date(b.publishDate).getTime() - new Date(a.publishDate).getTime()
      )*/

      .filter((currentAuction) => {
        const auction = currentAuction as IAuctionsSystemAuctions;
        return FilterAuctions({
          currentTypes,
          auction,
          searchValue,
          selectedDomain,
          selectedType,
          fromDate,
          toDate,
          typeOfContent,
        });
      });
  } else if (
    currentTypes &&
    currentResultAuctions &&
    typeOfContent === "auctionresults"
  ) {
    return currentResultAuctions
      /*.sort(
        (a, b) =>
          new Date(b.biddingDate).getTime() - new Date(a.biddingDate).getTime()
      )*/

      .filter((currentAuction) => {
        const auction = currentAuction as IAuctionsSystemAuctions;
        return FilterAuctions({
          currentTypes,
          auction,
          searchValue,
          selectedDomain,
          selectedType,
          fromDate,
          toDate,
          typeOfContent,
        });
      });
  } else if (
    currentTypes &&
    currentSingleSuppliers &&
    typeOfContent === "singlesupplier"
  ) {
    return currentSingleSuppliers
      /*.sort(
        (a, b) =>
          new Date(b.publishDate).getTime() - new Date(a.publishDate).getTime()
      )*/
      .filter((currentAuction) => {
        const singleSupplier = currentAuction as IAuctionsSystemSingleSupplier;
        return FilterSingleSupplier({
          singleSupplier,
          searchValue,
          fromDate,
          toDate,
        });
      });
  } else if (
    currentTypes &&
    currentExemptionNotices &&
    typeOfContent === "exemptionsnotices"
  ) {
    return currentExemptionNotices
      /*.sort(
        (a, b) =>
          new Date(b.publishDate).getTime() - new Date(a.publishDate).getTime()
      )*/
      .filter((currentAuction) => {
        const exemptionNotices =
          currentAuction as IAuctionsSystemExemptionNotices;
        return FilterExemptionNotices({
          exemptionNotices,
          searchValue,
          fromDate,
          toDate,
        });
      });
  }
};

/*------------------------------------------------------------------------*/
interface IFilterAuctions {
  currentTypes: IAuctionsSystemTypes[];
  auction: IAuctionsSystemAuctions;
  searchValue: string;
  selectedDomain: string;
  selectedType: string;
  fromDate: Date;
  toDate: Date;
  typeOfContent: any;
}
const FilterAuctions = (props: IFilterAuctions) => {
  const {
    currentTypes,
    auction,
    searchValue,
    selectedDomain,
    selectedType,
    fromDate,
    toDate,
  } = props;


  const auctionStringify = JSON.stringify(auction);

  const isDomainExists =
    currentTypes
      .filter((x) => x.id === auction.filed)
      .map((x) => x.name)
      .includes(selectedDomain) || selectedDomain.length === 0;

  const isTypeExists =
    currentTypes
      .filter((x) => x.id === auction.type)
      .map((x) => x.name)
      .includes(selectedType) || selectedType.length === 0;

  const isDatePublishedBetweenDates = IsDateBetweenTender(
    fromDate,
    toDate,
    new Date(auction.publishDate)
  );

  if (
    auctionStringify.includes(searchValue) &&
    isDomainExists &&
    isTypeExists &&
    isDatePublishedBetweenDates // &&
    // isDateDeadlineBetweenDates
  ) {
    return auction;
  }
  return null;
};

/*------------------------------------------------------------------------*/
interface IFilterSingleSupplier {
  singleSupplier: IAuctionsSystemSingleSupplier;
  searchValue: string;
  fromDate: Date;
  toDate: Date;
}
const FilterSingleSupplier = (props: IFilterSingleSupplier) => {
  const { singleSupplier, searchValue, fromDate, toDate } = props;
  const auctionStringify = JSON.stringify(singleSupplier);
  const isDatePublishedBetweenDates = IsDateBetweenTender(
    fromDate,
    toDate,
    new Date(singleSupplier.publishDate)
  );
  /*const isDateOfDecisionBetweenDates = IsDateBetween(
    fromDate,
    toDate,
    new Date(singleSupplier.DecisionDate)
  );*/
  if (
    auctionStringify.includes(searchValue) &&
    isDatePublishedBetweenDates //&&
    //isDateOfDecisionBetweenDates
  ) {
    return singleSupplier;
  }
  return null;
};

/*------------------------------------------------------------------------*/
interface IFilterExemptionNotices {
  exemptionNotices: IAuctionsSystemExemptionNotices;
  searchValue: string;
  fromDate: Date;
  toDate: Date;
}
const FilterExemptionNotices = (props: IFilterExemptionNotices) => {
  const { exemptionNotices, searchValue, fromDate, toDate } = props;
  const auctionStringify = JSON.stringify(exemptionNotices);
  const isDatePublishedBetweenDates = IsDateBetweenTender(
    fromDate,
    toDate,
    new Date(exemptionNotices.publishDate)
  );

  if (auctionStringify.includes(searchValue) && isDatePublishedBetweenDates) {
    return exemptionNotices;
  }
  return null;
};
