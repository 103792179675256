import { t } from "i18next";
import { useEffect, useState } from "react";
import colors from "../../Assets/Colors/Colors.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import Images from "../../Data/Images/Images";
import { IStationInfo } from "../../Interfaces/IStationInfo";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import i18n from "../../Services/i18n";
import CircleInfo from "./CircleInfo";
import CircleInfoMobile from "./CircleInfoMobile";

const allObject = {
  environments: [
    {
      icon: Images.food,
      title: "Food",
      width: 14,
      tagName: "Restaurants-and-Fast-Food",
    },
    {
      icon: Images.outofhome,
      title: "ActivitiesRecreation",
      width: 17.9,
      tagName: "Activities-&-Recreation",
    },
    {
      icon: Images.shoping,
      title: "Shopping",
      width: 11.62,
      tagName: "Shopping",
    },
    {
      icon: Images.info_mobile,
      title: "AllInstitutes",
      width: 9,
      tagName: "All-Institutes",
    },
    {
      icon: Images.services,
      title: "HelpServices",
      width: 7.3,
      tagName: "Help-&-Services",
    },
    {
      icon: Images.tickets,
      title: "Events",
      width: 6.1,
      tagName: "Events",
    },
    {
      icon: Images.trip,
      title: "Travel",
      width: 4.75,
      tagName: "Tourism-&-Travel",
    },
  ],
};
const StationInfoEnvironment: React.FC<IStationInfo> = (props) => {
  const { type, location, stationDetails } = props;
  let [_scale, _setScale] = useState(1);
  let [dirRightLeft, _setDirRightLeft] = useState<"left" | "right">("right");
  let [dirLeftRight, _setDirLeftRight] = useState<"left" | "right">("right");
  useEffect(() => {
    scale();
  }, [window.innerWidth]);
  useEffect(() => {
    _setDirRightLeft(i18n.dir() === "ltr" ? "left" : "right")
    _setDirLeftRight(i18n.dir() === "ltr" ? "right" : "left")
  }, []);

  const scale = () => {
    const BASE_WIDTH = type === "mobile" ? 375 : 1920;
    const board: any = document.querySelector(".board");
    const boardWidth = board?.offsetWidth;
    _setScale(boardWidth / BASE_WIDTH);
  };
  const styles: StyleSheet = {
    boardDetktop: {
      overflow: "hidden",
      background: colors.white,
      width: "100%",
      aspectRatio: "2.25 / 1",
    },
    board: {
      overflow: "hidden",
      background: colors.white,
      width: "100%",
      aspectRatio: "0.67 / 1",
    },
    plusasstarDetktop: {
      position: "absolute",
      top: "16.8%",
      [dirLeftRight]: "76.7%",
      zIndex: 22,
    },
    plusasstar: {
      position: "absolute",
      top: "7%",
      [dirLeftRight]: "60%",
      zIndex: 22,
      lineHeight: "19px",
    },
    imgPlusasstarDetktop: {
      position: "absolute",
      [dirLeftRight]: "0",
      top: 0,
      transform: "translateY(-50%)",
      width: 45,
    },
  
    imgPlusasstar: {
      position: "absolute",
      [dirLeftRight]: "-10%",
      top: 0,
      transform: "translateX(-50%)",
      width: 30,
    },
    titleDetktop: {
      fontFamily: "FbReforma-Regular",
      fontSize: fontSize.H1,
      color: "rgba(0, 69, 138, 1)",
      margin: 0,
      lineHeight: "2rem",
    },
    title: {
      fontFamily: "FbReforma-Medium",
      fontWeight: `${i18n.language === "ar" ? `600` : ``}`,
      fontSize: fontSize.Text,
      color: "rgba(0, 69, 138, 1)",
      margin: 0,
      lineHeight: "2.5rem",
    },
    imgEasyDetktop: {
      position: "absolute",
      left: "19%",
      top: "57%",
      width: "3.125rem",
    },
    imgEasy: {
      position: "absolute",
      left: "19%",
      top: "57%",
      width: "3.125rem",
    },
    dots: {
      position: "relative",
      height: "100%",
    },
    floatBubble: {
      position: "absolute",
      aspectRatio: "1 / 1",
      zIndex: 1,
    },
    dotEmpty: {
      position: "absolute",
      width: "10rem",
      /* height: 10rem */
      background: colors.koromiko,
      borderRadius: "50%",
      aspectRatio: "1 / 1",
    },
  
    dotEmpty1: {
      top: "50%",
      width: "29.2%",
      [dirLeftRight]: 0,
      transform:
        i18n.dir() === "ltr" ? "translate(50%,-50%)" : "translate(-70%,-50%)",
      background: colors.pattensBlue,
    },
  
    dotEmpty2: {
      [dirRightLeft]: 0,
      top: "50%",
      width: "8.8%",
      transform:
        i18n.dir() === "ltr" ? "translate(-70%,-50%)" : "translate(70%,-50%)",
    },
    dotEmpty3: {
      [dirLeftRight]: "65.5%",
      bottom: 0,
      width: "6.5%",
      transform: "translateY(40%)",
      background: colors.pattensBlue,
    },
    dotEmpty4: {
      top: "33.1%",
      [dirLeftRight]: "23.75%",
      width: "1.89%",
      background: colors.pattensBlue,
    },
  
    dotEmpty5: {
      top: "60.3%",
      [dirLeftRight]: "42.34%",
      width: "3.5%",
    },
    dotEmpty6: {
      top: "57.9%",
      [dirLeftRight]: "30.8%",
      width: "8.5%",
      background: colors.pattensBlue,
    },
    dotEmpty7: {
      top: "16.8%",
      [dirLeftRight]: "72.4%",
      width: "11.2%",
      background: colors.pattensBlue,
    },
    dotEmpty1Mob: {
      top: "43%",
      width: "13.6%",
      [dirLeftRight]: 0,
      transform: "translate(-50%,-50%)",
      background: colors.pattensBlue,
    },
  
    dotEmpty2Mob: {
      top: "66.7%",
      [dirLeftRight]: 0,
      width: "31.2%",
      transform: "translateX(-60%)",
    },
    dotEmpty3Mob: {
      right: 0,
      transform: "translateX(50%)",
  
      top: "75.8%",
      width: "15.36%",
      background: "#009dd6",
    },
    dotEmpty4Mob: {
      top: "80.5%",
      right: 0,
      width: "22.4%",
      transform: "translateX(50%)",
    },
    container: {
      width: "100%",
    }
  };
  let plusasstar =
    type === "desktop" ? styles.plusasstarDetktop : styles.plusasstar;
  let title = type === "desktop" ? styles.titleDetktop : styles.title;
  let board = type === "desktop" ? styles.boardDetktop : styles.board;
  let imgEasy = type === "desktop" ? styles.imgEasyDetktop : styles.imgEasy;

  return (
    <div style={styles.container}>
      <div style={board} className="board">
        <div style={styles.dots}>

            <div style={{ translate: `${i18n.dir() === "ltr" ? "25%" : 0} -50%` ,  ...plusasstar,
              [dirLeftRight]: "76.7%",
              transform: `scale(${_scale})`,}}>
              <h1
                style={{
                  ...title,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                }}
              >
                {t("PointsOfinterest")}
                <img
                  src={Images.plusasstar}
                  style={{ width: 45, translate: "0 -25%" }}
                  width={`auto`}
                  height={`auto`}
                  alt={``}
                />
              </h1>
              <h1 style={title}>{t("EnvironmentTheTrain")}</h1>
            </div>
          {allObject.environments.map((environment, index) =>
            
            type === "desktop" ? (
              <CircleInfo
                key={index}
                {...props}
                {...environment}
                tagName={environment.tagName}
                index={index}
                scale={_scale}
              />
            ) : (
                <CircleInfoMobile
                key={index}
                {...props}
                {...environment}
                tagName={environment.tagName}
                index={index}
                scale={_scale}
              />
            )
          )}
          {Array.from(Array(type === "desktop" ? 7 : 4).keys()).map(
            (item, index: number) => (
              <div
                key={index}
                style={{
                  ...styles.dotEmpty,
                  ...styles[
                    `dotEmpty${item + 1}${type === "desktop" ? "" : "Mob"}`
                  ],
                }}
              />
            )
          )}
          <a
            href={`https://easy.co.il/?lat=${location?.latitude}&lng=${location?.lontitude}&addressname=${stationDetails.stationName}`}
            style={imgEasy}
            target={"_blank"}
          >
            <img src={Images.easy} />
          </a>
        </div>
      </div>
    </div>
  );
};



export default StationInfoEnvironment;
