import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IHeaderContent } from "../../Interfaces/IUmbraco";

export interface HeaderState {
  header: IHeaderContent | {};
}

const initialState: HeaderState = {
  header: {},
};

export const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    setHeader: (state, action: PayloadAction<IHeaderContent>) => {
      state.header = action.payload;
    },
  },
});

export const { setHeader } = headerSlice.actions;

export default headerSlice.reducer;
