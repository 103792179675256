import { useState } from "react";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import Images from "../../../Data/Images/Images";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { IUmbracoFile } from "../../../Interfaces/IUmbraco";
import { IsMobile } from "../../../Services/DeviceService";
import { HiDownload } from "react-icons/hi";

const UmbracoFile: React.FC<IUmbracoFile> = (props) => {
  const { filename, onClick, filenameStyles, anchor } = props;
  const [_isDownloadHover, _setIsDownloadHover] = useState(false);

  const getFileIcon = () => {
    if (props.document) {
      if (props.document._url.includes(".pdf")) {
        return Images.pdfIcon;
      }
      if (props.document._url.includes(".xls")) {
        return Images.xlsIcon;
      }
      if (props.document._url.includes(".doc")) {
        return Images.wordFile;
      }
      if (props.document._url.includes(".rar")) {
        return Images.rarIcon;
    }
    }
    return Images.pdfIcon;
  };

  const downloadFileFromURL = (url: string, filename: string) => {
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = filename;

        a.style.display = "none";
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        window.URL.revokeObjectURL(a.href);
      })
      .catch((error) => {
        console.error("Error while downloading the file:", error);
      });
  };

  const getFileType = () => {
    if (props.document) {
      if (props.document._url.includes(".pdf")) {
        return `pdf`;
      }
      if (props.document._url.includes(".xls")) {
        return `exel`;
      }
      if (props.document._url.includes(".doc")) {
        return `word`;
      }
      if (props.document._url.includes(".rar")) {
        return `rar`;
      }
    }
    return Images.pdfIcon;
  };

  return (
    <div style={styles.fileContainer} anchor-id={anchor}>
      <img src={getFileIcon()} width={"20"} alt={``} />
      <div
        style={{
          ...styles.filename,
          ...filenameStyles,
          cursor: props.document ? "pointer" : "",
          textDecoration: props.document ? "underline" : "",
        }}
        onClick={() => {
          onClick && onClick();
        }}
        role={`button`}
        aria-label={`${filename} ${getFileType()}`}
        tabIndex={!IsMobile() ? 0 : -1}
      >
        {filename.length < 15 ? filename : `${filename.substring(0, 15)}...`}
      </div>
      <div
        style={styles.download}
        onMouseOver={() => _setIsDownloadHover(true)}
        onMouseLeave={() => _setIsDownloadHover(false)}
        onClick={() => downloadFileFromURL(props.document._url, filename)}
      >
        <HiDownload
          size={"20"}
          color={_isDownloadHover ? colors.cyanBold : colors.black}
        />
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  fileContainer: {
    display: "flex",
    gap: ".5rem",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  filename: {
    color: colors.cyanBold,
    fontFamily: fonts.FbReformaRegular,
    letterSpacing: "0.0275rem",
  },
  download: {
    marginInlineEnd: "1rem",
    cursor: "pointer",
  },
};

export default UmbracoFile;
