import fonts from "../../../../Assets/Fonts/Fonts.json";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import { StyleMode } from "../../../../Utils/consts";
import { RemoveHtmlTags } from "../../../../Utils/helpers";
import useDeviceMode from "../../../../Utils/useWindowDimensions";
import { replaceRichtextBoldWithFont } from "../../../../Utils/utils";

const RichDescription: React.FC<{ text: string }> = ({ text }) => {
    const deviceMode = useDeviceMode();
    return (<div
        style={{
            ...styles.description,
            maxWidth: deviceMode === StyleMode.desktop ? "20rem" : "",
        }}>
        <div
            dangerouslySetInnerHTML={{
                __html: replaceRichtextBoldWithFont(text),
            }}
            aria-label={RemoveHtmlTags(text)} />
    </div>);
};
const styles: StyleSheet = {
    description: {
        fontFamily: fonts.FbReformaLight,
        fontSize: "1.2rem",
        lineHeight: 1.5,
    },
};

export default RichDescription;
