import { Fragment, useEffect, useRef, useState } from "react";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import {
  IDirectorateMemberModal,
  IDirectorateMemberShort,
  IDirectorateMembers,
} from "../../../Interfaces/IDirectorateMember";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import { replaceRichtextBoldWithFont } from "../../../Utils/utils";
import DirectorateMemberModal from "./DirectorateMemberModal/DirectorateMemberModal";
// import DirectorateMemberModalMobile from "./DirectorateMemberModal/Mobile/DirectorateMemberModalMobile";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../Services/DeviceService";
import CustomModal from "../../CustomModal";
import DirectorateMemberShort from "./DirectorateMemberShort";

const DirectorateMembers: React.FC<IDirectorateMembers> = (props) => {
  const { directorateMembers } = props;

  const deviceMode = useDeviceMode();
  const closeButtonRef = useRef<HTMLImageElement>(null);
  const fullNameRef = useRef<HTMLImageElement>(null);

  const [_isModalOpened, _setIsModalOpened] = useState(false);
  const [_selectedDirectorateMember, _setSelectedDirectorateMember] =
    useState<IDirectorateMemberModal>();

  const handleDirectorateMemberSelection = (
    directorateMember: IDirectorateMemberShort
  ) => {
    _setSelectedDirectorateMember(directorateMember);
    _setIsModalOpened(true);
  };

  const handleCloseModal = () => {
    _setIsModalOpened(false);
  };

  useEffect(() => {
    closeButtonRef.current?.focus();
  }, [closeButtonRef]);

  useEffect(() => {
    if (
      _isModalOpened &&
      _selectedDirectorateMember &&
      deviceMode !== StyleMode.desktop
    ) {
      fullNameRef.current?.focus();
    }
  }, [_isModalOpened, _selectedDirectorateMember, deviceMode]);

  return (
    <Fragment>
      <div style={styles.container}>
        {directorateMembers.map((directorateMember, index) => (
          <DirectorateMemberShort
            {...directorateMember}
            key={index}
            onSelect={handleDirectorateMemberSelection}
          />
        ))}
      </div>
      <CustomModal visible={_isModalOpened && deviceMode === StyleMode.desktop}
        onClose={() => _setIsModalOpened(false)}
        showCloseButton="internal" typeStyle={"rectangle"}
        innerStyle={{
          width: "50%", maxHeight: "100%", padding: 0,
          maxWidth: "76rem", height: "60%", overflow: "hidden"
        }}
        contentStyle={{ padding: 0 }}
      >

        {_selectedDirectorateMember && (
          <DirectorateMemberModal
            {..._selectedDirectorateMember}
            onClose={handleCloseModal}
          />
        )}
      </CustomModal>
      {_selectedDirectorateMember && <CustomModal visible={_isModalOpened && deviceMode !== StyleMode.desktop}
        onClose={() => _setIsModalOpened(false)}
        showCloseButton="external"
        innerStyle={{ paddingTop: "5rem" }}
        icon={_selectedDirectorateMember.image._url}
        floatIconStyle={{
          container: {
            padding: "0.5rem",
            width: "7rem"
          },
          img: {
            borderRadius: "50%",
            aspectRatio: "1/1",
            objectFit: "fill"
          }
        }}

      >

        {_selectedDirectorateMember && (
          <>
            <div style={styles.bodyUpper}>
              <div
                style={styles.fullname}
                ref={fullNameRef}
                tabIndex={!IsMobile() ? 0 : -1}
              >
                {_selectedDirectorateMember.fullname}
              </div>
              <div
                style={{
                  fontSize: fontSize.UmbracoElementTitle,
                  fontFamily: fonts.FbReformaLight,
                  marginBottom: "0.25rem",
                  textAlign: "center",
                }}
              >
                {_selectedDirectorateMember.role}
              </div>
            </div>
            <div
              style={{
                paddingInlineStart: "3rem",
                fontSize: fontSize.FooterItem,
              }}
            >
              <div
                style={{
                  width: "90%",
                  fontFamily: fonts.FbReformaLight,
                  letterSpacing: "0.034375rem",
                  marginBottom: "0.8rem",
                }}
                dangerouslySetInnerHTML={{
                  __html: replaceRichtextBoldWithFont(
                    _selectedDirectorateMember.description
                  ),
                }}
              ></div>
              <div style={styles.infoContainer}>
                <div
                  style={{
                    marginBottom: "0.25rem",
                    fontFamily: fonts.FbReformaMedium,
                  }}
                >
                  {_selectedDirectorateMember.birthYear.title}
                </div>
                <div
                  style={{
                    fontFamily: fonts.FbReformaLight,
                    letterSpacing: "0.034375rem",
                    marginBottom: "0.8rem",
                  }}
                  dangerouslySetInnerHTML={{
                    __html:
                      _selectedDirectorateMember.birthYear.description,
                  }}
                ></div>
              </div>
              <div style={styles.infoContainer}>
                <div
                  style={{
                    marginBottom: "0.25rem",
                    fontFamily: fonts.FbReformaMedium,
                  }}
                >
                  {_selectedDirectorateMember.education.title}
                </div>
                <div
                  style={{
                    fontFamily: fonts.FbReformaLight,
                    letterSpacing: "0.034375rem",
                    marginBottom: "0.8rem",
                  }}
                  dangerouslySetInnerHTML={{
                    __html:
                      _selectedDirectorateMember.education.description,
                  }}
                ></div>
              </div>
              <div style={styles.infoContainer}>
                <div
                  style={{
                    fontFamily: fonts.FbReformaMedium,
                    marginBottom: "0.25rem",
                  }}
                >
                  {_selectedDirectorateMember.workExperience.title}
                </div>
                <div
                  onKeyDownCapture={(e) => {
                    if (e.key === "Tab") {
                      if (closeButtonRef.current) {
                        e.stopPropagation();
                        e.preventDefault();
                        closeButtonRef.current.focus();
                      }
                    }
                  }}
                  style={{
                    fontFamily: fonts.FbReformaLight,
                    letterSpacing: "0.034375rem",
                    marginBottom: "0.8rem",
                  }}
                  dangerouslySetInnerHTML={{
                    __html:
                      _selectedDirectorateMember.workExperience.description,
                  }}
                ></div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <span></span>
              </div>
            </div></>
        )}
      </CustomModal>}
    </Fragment>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    gap: "4rem",
    width: "80%",
    maxWidth: "76rem",
  },
  fullname: {
    fontSize: fontSize.UmbracoElementTitle,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    letterSpacing: "0.065rem",
    marginBottom: "0.25rem",
  },
  bodyUpper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: ".5rem",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    width: "90%",
  }
};

export default DirectorateMembers;
