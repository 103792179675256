import { useState } from "react";
import { useNavigate } from "react-router-dom";
import colors from "../../Assets/Colors/Colors.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { IsMobile } from "../../Services/DeviceService";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";
import CustomModal from "../CustomModal";
import IconAndText, { IIconAndText } from "../IconAndText";

export interface IServicesCustomerOptions {
  services: IIconAndText[];
}
const ServicesCustomerOptions: React.FC<IServicesCustomerOptions> = (props) => {
  const { services } = props;
  const navigate = useNavigate();
  const [_selectedItem, _setSelecedItem] = useState<IIconAndText>();
  const [_modalVisible, _setModalVisible] = useState(false);
  const deviceMode = useDeviceMode();

  const redirectToPage = (page?: string) => {
    page && navigate(`?page=${page}`);
  };
  const handleCustomerServiceSelection = (
    customerServiceItem: IIconAndText
  ) => {
    _setSelecedItem(customerServiceItem);

    switch (customerServiceItem.openMode) {
      case "Modal":
        _setModalVisible(true);
        break;
      case "New Window":
        if (customerServiceItem.openType === "External") {
          window.open(customerServiceItem.link, "_blank");
        } else if (customerServiceItem.openType === "Internal") {
          redirectToPage(customerServiceItem.link);
        }
        break;
    }
  };

  return (
    <>
      <div
        className="max-width"
        id="pageMainContent"
        tabIndex={!IsMobile() ? 0 : -1}
      >
        <div
          className="row"
          style={{
            gap: "2.5rem",
            flexWrap: "wrap",
            justifyContent: "center",
            paddingTop: deviceMode !== StyleMode.desktop ? "2rem" : 0,
          }}
        >
          {services.map((item, index) => (
            <IconAndText
              key={index}
              {...item}
              style={{
                translate: deviceMode === StyleMode.desktop ? "0 -50%" : "0 0",
                width: "10rem",
              }}
              onClick={(clicked) => handleCustomerServiceSelection(clicked)}
            />
          ))}
        </div>
      </div>
      <CustomModal visible={_modalVisible}
        isCloseDialog={true}
        onClose={() => _setModalVisible(false)}
        showCloseButton="internal" typeStyle={"rectangle"}
        innerStyle={{ width: "85%", maxHeight: "100%", padding: 0, maxWidth: "90.625rem", height: "50rem" }}
        contentStyle={{ padding: 0 }}
        closeStyle={{...styles.closeMainModalIcon,top:"-1rem",insetInlineEnd:"-1rem"}}
      >
        <iframe
          src={_selectedItem?.link}
          style={{ maxHeight: "99%", border: 0 }}
          width={"100%"}
          height={"100%"}
          tabIndex={!IsMobile() ? 0 : -1}
          aria-label={`${_selectedItem?.title} iframe`}
        ></iframe>
      </CustomModal>
    </>
  );
};

const styles: StyleSheet = {
  closeMainModalIcon: {
    borderRadius: "50%",
    backgroundColor: colors.white,
  },
};

export default ServicesCustomerOptions;
