import {
  IIconAndDescription,
  IIconAndDescriptions,
} from "../../Interfaces_tenders/IIconAndDescription";

import Title from "./Title";

import IconAndDescription from "./IconAndDescription";


const IconAndDescriptionGallery: React.FC<IIconAndDescriptions> = (props) => {
  const { title, qAandIcons } = props;

  return (
    <div style={styles.container}>
      <div style={styles.title}>
        <Title text={title} />
      </div>
      <div style={styles.wrap}>
        {qAandIcons.map((item: IIconAndDescription, index: number) => (
          <IconAndDescription {...item} key={index} />
        ))}
      </div>

    </div>
  );
};

const styles: any = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    padding: "0 2rem",
    maxWidth: "76rem",
    margin: "auto",
    gap: "7rem",
  } as React.CSSProperties,
  title: {
    padding: "2rem 0",
  } as React.CSSProperties,
  wrap: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    gap: "2rem",
  } as React.CSSProperties,


};

export default IconAndDescriptionGallery;
