import React, { useState } from "react";

interface ReadMoreProps {
  limit: number;
  more: string;
  less: string;
  content: string;
}

const ReadMore: React.FC<ReadMoreProps> = ({ limit, more, less, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const renderContent = () => {
    if (content.length > limit) {
      if (!isOpen) {
        return (
          <>
            <p>
              {content.trim().slice(0, limit)}&hellip;
              <span onClick={toggle} style={{ cursor: 'pointer' ,color:"blue" }}>{more}</span>
            </p>
          </>
        );
      } else {
        return (
          <>
            <p>{content}</p>
            <span onClick={toggle}style={{ cursor: 'pointer' ,color:"blue" }}>{less}</span>
          </>
        );
      }
    }
    return <p>{content}</p>;
  };

  return <div>{renderContent()}</div>;
};

export default ReadMore;
