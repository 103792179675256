import { IDescirption } from "../../Interfaces/IBaseElement";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { replaceRichtextBoldWithFont } from "../../Utils/utils";
import { RemoveHtmlTags } from "../../Utils/helpers";
import fontSize from "../../Assets/Fonts/FontsSizes.json";

const Description: React.FC<IDescirption> = (props) => {
  const { text } = props;

  const addListStyle = (text: string) => {
    return text.replaceAll("<li", `<li style="padding-inline-start: 1rem"`);
  };

  return (
    <div style={styles.container}>
      <div
        style={{
          ...styles.description,
        }}
      >
        <label
          dangerouslySetInnerHTML={{
            __html: replaceRichtextBoldWithFont(addListStyle(text)),
          }}
          aria-label={RemoveHtmlTags(text)}
        ></label>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    maxWidth: "76rem",
    width: "80%",
  },
  description: {
    display: "flex",
    fontSize: fontSize.Paragraph,
    lineHeight: "1.3rem",
    fontFamily: fonts.FbReformaRegular,
  },
};

export default Description;
