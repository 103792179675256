import {
  IAuctionsSystem,
  IAuctionSystem,
} from "../../Interfaces_tenders/IAuctionsSystem";
import { IAuctionsSystemTypes } from "../../Interfaces_tenders/IAuctionsSystemDetails";
import { Actions } from "../consts";

const init_state: any = {};

export const AuctionsReducer = (state = init_state, auctions: any) => {
  switch (auctions.type) {
    case Actions.Auctions:
      let auctionsArray = auctions.payload as IAuctionsSystem[];
      auctionsArray.map((auction) => {
        state[`${auction.id.toLowerCase()}`] = auction as IAuctionsSystem;
      });
      return state;
    default:
      return state;
  }
};

/*export const AuctionsTypeReducer = (state = init_state, action: any) => {
  switch (action.type) {
    case "setAuctionsTypes":
      return action.payload;
    default:
      return state;
  }
};*/

export const AuctionsErrorStatus = (state = false, action: any) => {
  switch (action.type) {
    case "setErrorStatus":
      return action.payload;
    default:
      return state;
  }
};



export const AuctionsErrorStatusUpdateClientNewTenders = (state = false, action: any) => {
  switch (action.type) {
    case "setErrorStatusUpdateClientNewTenders":
      return action.payload;
    default:
      return state;
  }
};



