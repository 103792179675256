import { useEffect, useRef } from "react";
import colors from "../Assets/Colors/Colors.json";
import TopBannerOld from "../Components/TopBannerOld";
import AllElements from "../Components/UmbracoElements/AllElements";
import { IMagazinePage } from "../Interfaces/IMagazinePage";
import { StyleSheet } from "../Interfaces/IStyleSheet";
import { IsMobile } from "../Services/DeviceService";
import { StyleMode } from "../Utils/consts";
import useDeviceMode from "../Utils/useWindowDimensions";

const MagazinePage: React.FC<IMagazinePage> = (props) => {
  const { title, cover, elements } = props;

  const deviceMode = useDeviceMode();
  const titleRef = useRef<HTMLDivElement>(null);
  let isCardDisplay = true;

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.tabIndex = 0;
      titleRef.current.focus();
      titleRef.current.style.outline = "none";
      titleRef.current.tabIndex = -1;
    }
  }, []);

  const cardBody = deviceMode === StyleMode.desktop ? { ...styles.cardBody, transform: isCardDisplay ? "translateY(-7.8rem)" : "unset", } : styles.cardBodyMobile
  return (
    <div style={styles.mainContainer}>
      <TopBannerOld cover={cover._url} title={title} link={"magazine"} isCardDisplay={true} />
      <div
        id="pageMainContent"
        tabIndex={!IsMobile() ? 0 : -1}
        className="card-body"
        style={cardBody}>
        {elements.map((element, index) => (
          <AllElements
            key={index}
            elementName={element.contentTypeAlias}
            {...element}
          />
        ))}
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  mainContainer: {
    minHeight: "100vh",
  },
  cardBody: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    alignItems: "unset",
    gap: 0,
    paddingTop: "4rem",
    paddingInline: "3rem",
    paddingBottom: "5rem",
    backgroundColor: colors.white,
    borderRadius: "0.3125rem",
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.15)",
    lineHeight: `1.6rem`,
    maxWidth: "101rem",
    margin: "auto",
    minWidth: "unset",
    width: "100%",
  },
  cardBodyMobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "unset",
    gap: 0,
    width: "100%",
    padding: 0,

  },
};

export default MagazinePage;
