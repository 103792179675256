import { t } from "i18next";
import Lottie from "lottie-react";
import colors from "../../Assets/Colors/Colors.json";
import Loader from "../../Assets/Lotties/Loader/Loader.json";
import { ILoading } from "../../Interfaces/ILoading";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";
import fontSize from "../../Assets/Fonts/FontsSizes.json";

const Loading: React.FC<ILoading> = (props) => {
  const { message, containerStyles } = props;
  const deviceMode = useDeviceMode();

  return (
    <div
      style={{
        minHeight: "100vh",
        ...styles.loadingArea,
        height: deviceMode !== StyleMode.desktop ? "50vh" : "100vh",
        ...containerStyles,
      }}
    >
      <Lottie
        animationData={Loader}
        loop={true}
        autoPlay={true}
        style={{
          transform:
            deviceMode !== StyleMode.desktop ? "scale(1)" : "scale(1.2)",
        }}
      />
      {message && <div style={styles.message}>{t(message)}</div>}
    </div>
  );
};

const styles: StyleSheet = {
  loadingArea: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "3rem",
    width: "100%",
  },
  message: {
    color: colors.darkBlue,
    fontSize: fontSize.Paragraph,
    maxWidth: "80%",
    textAlign: "center",
  },
};

export default Loading;
