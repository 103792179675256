import { t } from "i18next";
import Marquee from "react-fast-marquee";
import { useState } from "react";
import { IUpdatesTicker } from "../../../Interfaces/IUpdatesTicker";
import Images from "../../../Data/Images/Images";
import i18n from "../../../Services/i18n";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";

const UpdatesTickerMobile: React.FC<IUpdatesTicker> = (props) => {
  const {
    updates,
    currentUpdateIndex,
    onNextButtonClick,
    onPreviousButtonClick,
    onRedirectTo,
  } = props;

  const [_isPaused, _setIsPaused] = useState(false);

  return (
    <div
      style={{
        ...styles.container,
        display: updates.length > 0 ? "flex" : "none",
      }}
    >
      <img
        src={!_isPaused ? Images.audioPauseSvg_white : Images.audioPlay_white}
        onClick={() => {
          _setIsPaused((prev) => !prev);
        }}
        role={"button"}
        aria-label={t(_isPaused ? "PlayUpdates" : "PauseUpdates")}
        width={"18"}
        alt={``}
      />

      <div style={{ width: "100%" }}>
        {updates.map(
          (update, index) =>
            index === currentUpdateIndex && (
              <Marquee
                key={index + update.updatePage?.id}
                gradient={false}
                speed={60}
                onCycleComplete={onNextButtonClick}
                pauseOnHover={true}
                direction={i18n.dir() === "rtl" ? "right" : "left"}
                play={!_isPaused}
                style={{ maxWidth: `100%`, height: "auto" }}
              >
                <h2
                  role={"link"}
                  style={{ color: colors.white, fontSize: fontSize.SubTitle }}
                  onClick={() => onRedirectTo(update.updatePage?.id)}
                >
                  {update.header}
                </h2>
              </Marquee>
            )
        )}
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    width: "100%",
    backgroundColor: colors.darkBlue,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    position: "relative",
    zIndex: "99999",
    padding: "0 .5rem",
    height: "2.8rem"
  },
  updatesPaginationContainer: {
    display: "flex",
    alignItems: "center",
  },
  updateNumberOutOfNumberContainer: {
    display: "flex",
    alignItems: "center",
    flex: "1",
  },
  update: {
    fontFamily: fonts.FbReformaRegular,
    color: colors.darkBlue,
    fontSize: fontSize.Paragraph,
    whiteSpace: "nowrap",
  },
  allUpdates: {
    fontFamily: fonts.FbReformaRegular,
    color: colors.darkBlue,
    fontSize: fontSize.Paragraph,
    whiteSpace: "nowrap",
    cursor: "pointer",
    fontWeight: "300",
  },
  updatesContainer: {
    display: "flex",
    alignItems: "center",
    gap: ".5rem",
    padding: "0px 5%",
  },
  arrow: {
    transform: "rotate(90deg)",
    cursor: "pointer",
  },
  headerContainer: {
    display: "flex",
    gap: "1.5rem",
  },
  allUpdatesContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  pause: {
    cursor: "pointer",
  },
};

export default UpdatesTickerMobile;
