import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import enFile from '../Data/Languages/en.json';
import heFile from '../Data/Languages/he.json';
import arFile from '../Data/Languages/ar.json';
import ruFile from '../Data/Languages/ru.json';
import store from 'store2';

const Languages = ['en', 'he', 'ru', 'ar']
const resources = {
  en: {
    translation: enFile
  },
  he: {
    translation: heFile
  },
  ar: {
    translation: arFile
  },
  ru: {
    translation: ruFile
  }
};
i18n
  .use(Backend)
  // .use(LanguageDetector)
  .use(initReactI18next)
  .on("languageChanged", (newLanguage) => {
    store.set("i18nextLng", newLanguage)
  })
  .init({
    resources,
    fallbackLng: 'he',
    debug: false,
    supportedLngs: Languages,
    nonExplicitSupportedLngs: false,
    detection: {
      lookupQuerystring: 'locale'
    },
    lng: store.get("i18nextLng") || "he"
  });
   i18n.services.pluralResolver.getSuffix = (code, count, ...other) => {
      if (count > 1) {
        return '_plural';
      }
      return `_${count}`;
  };

export default i18n;