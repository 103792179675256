import { t } from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import store from "store2";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import Config from "../../Configuration/server";
import { IStation } from "../../Interfaces/IStation";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { ScheduleStateBuild } from "../../Redux/Slices/scheduleSlice";
import {
  FormatMissingDigit,
  GetDateAsTextFormat
} from "../../Services/DateService";
import { IsMobile } from "../../Services/DeviceService";
import i18n from "../../Services/i18n";
import { GetStationsDictionary } from "../../Utils/helpers";
import { removeSpecialCharacters } from "../../Utils/utils";
import SearchStationsAutoComplete from "./SearchStationsAutoComplete";
import TimeCapsuleProps, { ScheduleStateProps } from "./TimeCapsuleProps";
let currentTime = new Date();

const initialSchale: ScheduleStateBuild = {
  date: moment().format("YYYY-MM-DD"),
  hours: currentTime.getHours().toString(),
  minutes: currentTime.getMinutes().toString(),
  scheduleType: 1,
};
const RoutePlanBarProps: React.FC<ScheduleStateProps> = (props) => {
  // const { onSearchTrainsClick } = props;
  const allStations = store.session.get(
    `${Config.BASE_LOCALSTORAGE_NAME}stations`
  );
  const [_stations, _setStations] = useState<IStation[]>([]);
  const [_fulldate, _setFulldate] = useState<string>("");

  const [_isSearchButtonDisabled, _setIsSearchButtonDisabled] = useState(false);
  const [_isSearchButtonOver, _setIsSearchButtonOver] = useState(false);
  const [_isSwitchButtonOver, _setIsSwitchButtonOver] = useState(false);
  const [_schedule, _setSchedule] = useState<ScheduleStateBuild>(initialSchale);
  const [render_test, _setRenderTest] = useState(0);

  const scheduleTypeFormatted = `${t(
    props.data.scheduleType === 1 ? "Departure" : "Arrival"
  )} ${t("At")}`;

  useEffect(() => {
    init();
  }, [props.data]);

  const init = () => {
    const hourFormatted = `${FormatMissingDigit(
      props.data.hours ? parseInt(props.data.hours) : 0
    )}:${FormatMissingDigit(
      props.data.minutes ? parseInt(props.data.minutes) : 0
    )}`;

    const fulldate = `${GetDateAsTextFormat(
      new Date(props.data.date ? props.data.date : ""),
      true
    )} ${scheduleTypeFormatted} ${hourFormatted}`;
    _setSchedule({ ...initialSchale, ...props.data });
    _setFulldate(fulldate);
  };
  const originStationAria =
    props.data.fromStation &&
    removeSpecialCharacters(
      GetStationsDictionary(allStations[i18n.language])[props.data.fromStation]
        .stationName
    );

  const destinationStationAria =
    props.data.toStation &&
    removeSpecialCharacters(
      GetStationsDictionary(allStations[i18n.language])[props.data.toStation]
        .stationName
    );

  return (
    <div style={styles.mainWrapper}>
      <SearchStationsAutoComplete
        fromStation={_schedule.fromStation}
        toStation={_schedule.toStation}
        onSearchTrainsClick={(e) => {
        }}
        onSelectStation={(FromTo) => {
          _setSchedule({
            ..._schedule,
            ...FromTo,
          });
          // !_schedule.id &&
          //   props.onSelect &&
          //   props.onSelect({
          //     ..._schedule,
          //     ...FromTo,
          //   });
        }}
      />
      <div style={styles.timeCapsuleWrapper}>
        <TimeCapsuleProps
          data={_schedule}
          onSelect={(schedule) => {
            _setSchedule({
              ..._schedule,
              ...schedule,
            });
            // !_schedule.id &&
            //   props.onSelect &&
            //   props.onSelect({
            //     ..._schedule,
            //     ...schedule,
            //   });
          }}
        />
      </div>
      <div style={styles.searchTrainsButtonWrapper}>
        <button
          onMouseOver={() => _setIsSearchButtonOver(true)}
          onMouseLeave={() => _setIsSearchButtonOver(false)}
          style={{
            ...styles.searchTrainsButton,
            filter: !_isSearchButtonDisabled
              ? !_isSearchButtonOver
                ? "brightness(1)"
                : "brightness(.95)"
              : "",
            cursor: "pointer",
            backgroundColor:_schedule.id ? colors.green: colors.koromiko
          }}
          onClick={() => {
            props.onSelect && props.onSelect(_schedule);
            _schedule.id && init();
          }}
          disabled={!_schedule.fromStation || !_schedule.toStation}
          tabIndex={!IsMobile() ? 0 : -1}
          aria-label={`${t("SearchTrainsAria", {
            fullTravelString: t("From-station-to", {
              origin: originStationAria,
              destination: destinationStationAria,
            }),
            fulldate: _fulldate,
          })}`}
        >
          {props.multisearch
            ? _schedule.id
              ? t("ערוך")
              : t("הוסף למסלול")
            : t("Search-trains")}
        </button>

        {_isSearchButtonDisabled && (
          <div style={styles.buttonDisabledWrapper}></div>
        )}
      </div>
      {/* {isLoading && <div style={{ ...styles.blockRoutePlan }}></div>} */}
    </div>
  );
};

const styles: StyleSheet = {
  mainWrapper: {
    display: "flex",
    gap: "2rem",
    margin: "2rem 4rem",
    flexWrap: "wrap",
    justifyContent: "space-around",
    position: "relative",
  },
  mainStationsWrapper: {
    display: "flex",
    border: `.1rem solid ${colors.pastelGray}`,
    borderRadius: "2rem",

    // marginTop: "3rem",
  },
  timeCapsuleWrapper: {
    display: "flex",
    padding: "0 2rem ",
    // marginTop: "3rem",
  },
  searchBoxWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    minWidth: "25rem",
    gap: "0.5rem",
    position: "relative",
  },
  searchBox: {
    textAlign: "center",
    lineHeight: "2rem",
    fontSize: fontSize.FooterItem,
  },
  info: {
    cursor: "pointer",
    filter: `brightness(0.5)`,
  },
  originIcon: {
    backgroundRepeat: "no-repeat",
    width: "1rem",
    height: "1rem",
    scale: "1.25",
    filter: "brightness(.5)",
  },
  destinationIcon: {
    filter: "brightness(.6)",
    backgroundRepeat: "no-repeat",
    width: "1rem",
    height: "1rem",
    scale: "1.25",
  },
  switchStations: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  switchStationsLine: {
    position: "absolute",
    width: ".1rem",
    height: "100%",
    backgroundColor: colors.pastelGray,
    zIndex: "-1",
  },
  switchIcon: {
    cursor: "pointer",
    scale: "1.1",
  },
  searchTrainsButton: {
    backgroundColor: colors.koromiko,
    fontFamily: fonts.FbReformaMedium,
    width: "16rem",
    height: "2.5rem",
    borderRadius: "24rem",
    border: "none",
    fontSize: fontSize.Text,
    color: colors.nero,
  },
  searchTrainsButtonWrapper: {
    display: "flex",
    gap: "1rem",
    // marginTop: "3rem",
  },
  searchStationsAutoComplete: {
    color: colors.nero,
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Text,
    width: "13rem",
  },
  stationsListStyle: {
    height: "38rem",
    width: "26rem",
    position: "absolute",
    overflow: "auto",
    top: "3.7rem",
    backgroundColor: colors.white,
    boxShadow: `0 15px 30px 0 rgba(0, 0, 0, 0.15)`,
    borderRadius: "0.6rem",
    padding: "1rem",
    boxSizing: "border-box",
  },
  blockRoutePlan: {
    width: "100%",
    height: "100%",
    position: "absolute",
    left: "0",
    top: "0",
    backgroundColor: colors.whiteTransparent,
    zIndex: "9999999999",
  },
  buttonDisabledWrapper: {
    position: "absolute",
    top: "0",
    width: "100%",
    height: "100%",
    backgroundColor: colors.whiteTransparent,
  },
};

export default RoutePlanBarProps;
