import { IRichTextEditor } from "../../Interfaces/IUmbraco";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { replaceRichtextBoldWithFont } from "../../Utils/utils";
import { RemoveHtmlTags } from "../../Utils/helpers";
import fontSize from "../../Assets/Fonts/FontsSizes.json";

const RichTextEditor: React.FC<IRichTextEditor> = (props) => {
  const { text, customStyles, anchor } = props;

  const addListStyle = (text: string) => {
    return text.replaceAll("<li", `<li style="padding-inline-start: 1rem"`);
  };

  return (
    <div
      style={{ ...styles.richTextEditorContainer, ...customStyles }}
      anchor-id={anchor}
    >
      <div
        aria-label={RemoveHtmlTags(text)}
        dangerouslySetInnerHTML={{
          __html: addListStyle(replaceRichtextBoldWithFont(text)),
        }}
      ></div>
    </div>
  );
};

const styles: StyleSheet = {
  richTextEditorContainer: {
    maxWidth: "76rem",
    width: "80%",
    lineHeight: "1.5rem",
    fontSize: fontSize.Paragraph,
  },
};

export default RichTextEditor;
