import { ITable } from "../../../Interfaces/IUmbraco";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import TableMobile from "./Mobile/TableMobile";
import Table from "./Table";

const TableMain: React.FC<ITable> = (props) => {
  const deviceMode = useDeviceMode();
  return deviceMode === StyleMode.desktop ? (
    <Table {...props} />
  ) : (
    <TableMobile {...props} />
  );
};

export default TableMain;
