import React, { ChangeEvent, useEffect } from "react";
import colors from "../../../Assets/Colors/Colors.json";
import { ICurrentService } from "../../../Interfaces/IForms";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import CustomAttachedFiles from "../CustomFileds/CustomAttachedFiles";
import CustomDatePicker from "../CustomFileds/CustomDatePicker";
import CustomInput from "../CustomFileds/CustomInput";
import CustomSelect from "../CustomFileds/CustomSelect";
import CustomTextArea from "../CustomFileds/CustomTextArea";
import FormsDB from "../db.json";

const FormClaimRailsWorkerOrThanksAppreciationScreen: React.FC<
  ICurrentService
> = (props) => {
  const deviceMode = useDeviceMode();
  const { formik } = props;

  useEffect(() => {
    document
      .getElementById(
        "FormClaimRailsWorkerOrThanksAppreciationScreen_container"
      )
      ?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, []);

  return (
    <div
      style={styles.container}
      id="FormClaimRailsWorkerOrThanksAppreciationScreen_container"
    >
      <div style={styles.form}>
        <CustomSelect
          label="תפקיד העובד"
          name="FormClaimRailsWorkerOrThanksAppreciationScreen.role"
          placeholder="role"
          onChange={(role: ChangeEvent<HTMLSelectElement>) => {
            formik.setFieldValue(
              "FormClaimRailsWorkerOrThanksAppreciationScreen.role",
              role.target.value
            );
          }}
          errors={
            formik.errors.FormClaimRailsWorkerOrThanksAppreciationScreen?.role
          }
          touched={
            formik.touched.FormClaimRailsWorkerOrThanksAppreciationScreen?.role+''
          }
          selectstyle={{
            flex: "unset",
            width: deviceMode === StyleMode.mobile ? "100%" : "48%",
          }}
        >
          <option value="">בחירה</option>
          {FormsDB.roles.map((role, index) => (
            <option key={index} value={role.roleKey}>
              {role.roleValue}
            </option>
          ))}
        </CustomSelect>

        <div style={styles.stationsSelectContainer}>
          {formik.values.FormClaimRailsWorkerOrThanksAppreciationScreen.role ===
            "other" && (
            <CustomInput
              label="תפקיד העובד"
              name="FormClaimRailsWorkerOrThanksAppreciationScreen.other"
              type="text"
              value={
                formik.values.FormClaimRailsWorkerOrThanksAppreciationScreen
                  .other
              }
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              errors={
                formik.errors.FormClaimRailsWorkerOrThanksAppreciationScreen
                  ?.other
              }
              touched={
                formik.touched.FormClaimRailsWorkerOrThanksAppreciationScreen
                  ?.other+''
              }
              placeholder="יש לציין את תפקיד העובד"
              selectstyle={{
                flex: "unset",
                width: deviceMode === StyleMode.mobile ? "100%" : "48%",
              }}
            />
          )}
          {formik.values.FormClaimRailsWorkerOrThanksAppreciationScreen.role !==
            "" &&
            formik.values.FormClaimRailsWorkerOrThanksAppreciationScreen
              .role !== "other" && (
              <CustomSelect
                label="תחנת מוצא"
                name="FormClaimRailsWorkerOrThanksAppreciationScreen.stationDeparture"
                placeholder="stationDeparture"
                onChange={(departure: ChangeEvent<HTMLSelectElement>) => {
                  formik.setFieldValue(
                    "FormClaimRailsWorkerOrThanksAppreciationScreen.stationDeparture",
                    departure.target.value
                  );
                }}
                errors={
                  formik.errors.FormClaimRailsWorkerOrThanksAppreciationScreen
                    ?.stationDeparture
                }
                touched={
                  formik.touched.FormClaimRailsWorkerOrThanksAppreciationScreen
                    ?.stationDeparture+''
                }
                selectstyle={{
                  flex: "unset",
                  width: deviceMode === StyleMode.mobile ? "100%" : "48%",
                }}
              >
                <option value="">יש לבחור את שם התחנה</option>
                {FormsDB.stations.map((station, index) => (
                  <option key={index} value={station.stationId}>
                    {station.stationName}
                  </option>
                ))}
              </CustomSelect>
            )}

          {(formik.values.FormClaimRailsWorkerOrThanksAppreciationScreen
            .role === "inspector" ||
            formik.values.FormClaimRailsWorkerOrThanksAppreciationScreen
              .role === "Dale") && (
            <CustomSelect
              label="תחנת יעד"
              name="FormClaimRailsWorkerOrThanksAppreciationScreen.stationDestination"
              placeholder="stationDestination"
              onChange={(destination: ChangeEvent<HTMLSelectElement>) => {
                formik.setFieldValue(
                  "FormClaimRailsWorkerOrThanksAppreciationScreen.stationDestination",
                  destination.target.value
                );
              }}
              errors={
                formik.errors.FormClaimRailsWorkerOrThanksAppreciationScreen
                  ?.stationDestination
              }
              touched={
                formik.touched.FormClaimRailsWorkerOrThanksAppreciationScreen
                  ?.stationDestination+''
              }
              selectstyle={{
                flex: "unset",
                width: deviceMode === StyleMode.mobile ? "100%" : "48%",
              }}
            >
              <option value="">יש לבחור את שם התחנה</option>
              {FormsDB.stations.map((station, index) => (
                <option key={index} value={station.stationId}>
                  {station.stationName}
                </option>
              ))}
            </CustomSelect>
          )}
        </div>

        <div style={styles.finalElementsContainer}>
          <CustomDatePicker
            label="תאריך"
            name="FormClaimRailsWorkerOrThanksAppreciationScreen.eventDateTime"
            type="date"
            value={
              formik.values.FormClaimRailsWorkerOrThanksAppreciationScreen
                .eventDateTime
            }
            onBlur={formik.handleBlur}
            onChange={(date: Date) => {
              formik.setFieldValue(
                "FormClaimRailsWorkerOrThanksAppreciationScreen.eventDateTime",
                date
              );
            }}
            errors={
              formik.errors.FormClaimRailsWorkerOrThanksAppreciationScreen
                ?.eventDateTime
            }
            touched={
              formik.touched.FormClaimRailsWorkerOrThanksAppreciationScreen
                ?.eventDateTime+''
            }
            placeholder="aa"
            showTime={true}
          />
          <CustomTextArea
            label="תיאור האירוע"
            name="FormClaimRailsWorkerOrThanksAppreciationScreen.comments"
            type="textarea"
            value={
              formik.values.FormClaimRailsWorkerOrThanksAppreciationScreen
                .comments
            }
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            errors={
              formik.errors.FormClaimRailsWorkerOrThanksAppreciationScreen
                ?.comments
            }
            touched={
              formik.touched.FormClaimRailsWorkerOrThanksAppreciationScreen
                ?.comments+''
            }
            placeholder="הזן תיאור האירוע"
          />
          <CustomAttachedFiles
            label="צירוף מסמכים
              (ניתן להוסיף יותר מקובץ אחד)"
            name="FormClaimRailsWorkerOrThanksAppreciationScreen.attachFiles"
            value={
              formik.values.FormClaimRailsWorkerOrThanksAppreciationScreen
                .attachFiles
            }
            onBlur={formik.handleBlur}
            onChange={(files: File[] | null) => {
              formik.setFieldValue(
                "FormClaimRailsWorkerOrThanksAppreciationScreen.attachFiles",
                files
              );
            }}
            errors={
              formik.errors.FormClaimRailsWorkerOrThanksAppreciationScreen
                ?.attachFiles
            }
            touched={
              formik.touched.FormClaimRailsWorkerOrThanksAppreciationScreen
                ?.attachFiles+''
            }
            type="file"
            placeholder="צירוף קובץ"
          />
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    margin: "auto",
    paddingBottom: "2rem",
  },
  finalElementsContainer: {
    display: "flex",
    flexWrap: "wrap",
    columnGap: "2rem",
  },
  form: { display: "flex", flexWrap: "wrap", columnGap: "2rem" },
  button: {
    width: "100%",
    padding: "0.65rem 0.5rem",
    fontSize: "1rem",
    color: colors.white,
    border: "2px solid",
    backgroundColor: colors.blue,
    borderRadius: "10px",
    outline: "none",
    margin: "1rem 0",
    fontWeight: "bold",
    cursor: "pointer",
  },
  stationsSelectContainer: {
    display: "flex",
    width: "100%",
    columnGap: "2rem",
    flexWrap: "wrap",
  },
};

export default FormClaimRailsWorkerOrThanksAppreciationScreen;
