import { t } from "i18next";
import { Fragment, useEffect, useRef, useState } from "react";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import Images from "../../../Data/Images/Images";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { IPlayingPodcast } from "../../../Interfaces/IUmbraco";
import {
  FormatDateYYYYMMDD_Dots,
  SecondsToDurationFormat,
} from "../../../Services/DateService";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../Services/DeviceService";
import i18n from "../../../Services/i18n";

const PlayingPodcast: React.FC<IPlayingPodcast> = (props) => {
  const {
    titleAndDescription,
    thumbnail,
    date,
    media,
    onClickPrevious,
    onClickNext,
    onShareClick,
  } = props;
  const [_currentTimeInSeconds, _setCurrentTimeInSeconds] = useState(0);
  const [_duration, _setDuration] = useState<number | undefined>();
  const [_isPlaying, _setIsPlaying] = useState(false);
  const [_audioNext, _setAudioNext] = useState(1.25);
  const [_mediaType, _setMediaType] = useState<"Audio" | "Video">("Audio");

  const audioRef = useRef<HTMLAudioElement>(null);
  const deviceMode = useDeviceMode();

  useEffect(() => {
    if (media && media._url) {
      const lastSplitted =
        media._url.split("/")[media._url.split("/").length - 1];
      const mediaType = lastSplitted.split(".")[1];
      switch (mediaType) {
        case "mp3":
          _setMediaType("Audio");
          break;
        default:
          _setMediaType("Video");
          break;
      }
    }
  }, [media]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.load();
      _setIsPlaying(false);
      _setDuration(undefined);
      _setCurrentTimeInSeconds(0);
    }
  }, [media]);

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (audioRef.current.paused) {
        audioRef.current.play();
        _setDuration(audioRef.current.duration);
      } else {
        audioRef.current.pause();
      }
    }
  };

  const handleForwardSecondsTimeSkip = (seconds: number) => {
    if (audioRef.current) {
      audioRef.current.currentTime += seconds;
    }
  };

  const handleBackwardSecondsTimeSkip = (seconds: number) => {
    if (audioRef.current) {
      audioRef.current.currentTime -= seconds;
    }
  };

  const handlePlaybackRate = () => {
    if (audioRef.current) {
      if (audioRef.current.playbackRate + 0.25 <= 4) {
        audioRef.current.playbackRate += 0.25;
      } else {
        audioRef.current.playbackRate = 1;
      }
      if (_audioNext < 4) {
        _setAudioNext(audioRef.current.playbackRate + 0.25);
      } else {
        _setAudioNext(1);
      }
    }
  };

  return (
    <Fragment>
      <div style={styles.container}>
        {_mediaType === "Audio" ? (
          <Fragment>
            <div
              style={{
                ...styles.podcastDetails,
                flexDirection:
                  deviceMode === StyleMode.desktop ? "row" : "column",
              }}
            >
              <img
                src={
                  thumbnail && thumbnail._url ? thumbnail._url : Images.podcast
                }
                style={styles.thumbnail}
                width={`auto`}
                height={`auto`}
                alt={``}
              />
              <div style={styles.titleAndDescriptionContainer}>
                <div style={styles.date}>
                  {FormatDateYYYYMMDD_Dots(new Date(date))}
                </div>
                <div style={styles.title}>{titleAndDescription.title}</div>
                <div
                  style={styles.description}
                  dangerouslySetInnerHTML={{
                    __html: titleAndDescription.description,
                  }}
                ></div>
              </div>
            </div>
            {media && (
              <div style={styles.audioControlsWrapper}>
                <div
                  style={{
                    ...styles.audioControls,
                    gap: deviceMode === StyleMode.desktop ? "3.5rem" : "2rem",
                  }}
                >
                  <div
                    style={{
                      ...styles.playbackRateIcon,
                      backgroundImage: `url(${Images.playbackRate})`,
                    }}
                    onClick={() => handlePlaybackRate()}
                    onKeyPress={() => handlePlaybackRate()}
                    role={"button"}
                    tabIndex={!IsMobile() ? 0 : -1}
                    aria-label={`${
                      _audioNext === 1
                        ? t(`ReturenSpeed`)
                        : t(`AddSpeed`, { number: _audioNext })
                    }`}
                  >
                    <div style={{ color: colors.darkBlue }}>{`${
                      audioRef.current ? audioRef.current.playbackRate : 1
                    }x`}</div>
                  </div>
                  <img
                    src={Images.audioStart}
                    style={{
                      ...styles.audioStartIcon,
                    }}
                    onClick={() => onClickPrevious()}
                    onKeyPress={() => onClickPrevious()}
                    role={"button"}
                    tabIndex={!IsMobile() ? 0 : -1}
                    aria-label={`${t(`PreviousPodcast`)}`}
                  ></img>
                  <img
                    src={Images.audio10SecondsBack}
                    style={{
                      ...styles.audio10SecondsBackIcon,
                    }}
                    onClick={() => handleBackwardSecondsTimeSkip(10)}
                    onKeyPress={() => handleBackwardSecondsTimeSkip(10)}
                    role={"button"}
                    tabIndex={!IsMobile() ? 0 : -1}
                    aria-label={`${t(`BackwardSecondsTime`)}`}
                  ></img>
                  <img
                    src={_isPlaying ? Images.audioPauseIcon : Images.audioPlay}
                    style={{
                      ...styles.audioPlayIcon,
                    }}
                    onClick={() => handlePlayPause()}
                    onKeyPress={() => handlePlayPause()}
                    role={"button"}
                    tabIndex={!IsMobile() ? 0 : -1}
                    aria-label={`${_isPlaying ? t(`PauseBtn`) : t(`PlayBtn`)}`}
                  ></img>
                  <img
                    src={Images.audio30SecondsNext}
                    style={{
                      ...styles.audio30SecondsNextIcon,
                    }}
                    onClick={() => handleForwardSecondsTimeSkip(30)}
                    onKeyPress={() => handleForwardSecondsTimeSkip(30)}
                    role={"button"}
                    tabIndex={!IsMobile() ? 0 : -1}
                    aria-label={`${t(`ForwardSecondsTimeSkip`)}`}
                  ></img>
                  <img
                    src={Images.audioEnd}
                    style={{
                      ...styles.audioEndIcon,
                    }}
                    onClick={() => onClickNext()}
                    onKeyPress={() => onClickNext()}
                    role={"button"}
                    tabIndex={!IsMobile() ? 0 : -1}
                    aria-label={`${t(`NextPodcast`)}`}
                  ></img>
                  <img
                    src={Images.audioShareIcon}
                    style={styles.control}
                    width={`auto`}
                    height={`auto`}
                    alt={``}
                    onClick={() => {
                      onShareClick && onShareClick(media._url);
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={styles.duration}>
                    <div>
                      {_duration !== undefined &&
                        SecondsToDurationFormat(_duration)}
                    </div>
                    <div>/</div>
                    <div>{SecondsToDurationFormat(_currentTimeInSeconds)}</div>
                  </div>
                  <audio
                    autoPlay
                    preload="metadata"
                    onTimeUpdate={(e) => {
                      _setCurrentTimeInSeconds(e.currentTarget.currentTime);
                    }}
                    onPause={() => {
                      _setIsPlaying(false);
                    }}
                    onPlay={() => {
                      _setIsPlaying(true);
                      if (audioRef.current) {
                        _setDuration(audioRef.current.duration);
                      }
                    }}
                    ref={audioRef}
                    controls
                    controlsList="nodownload noplaybackrate noremoteplayback noplaybackrate"
                  >
                    <source src={media._url} />
                  </audio>
                </div>
              </div>
            )}
          </Fragment>
        ) : (
          <div>
            <div style={styles.titleAndDescriptionContainer}>
              <div style={styles.date}>
                {FormatDateYYYYMMDD_Dots(new Date(date))}
              </div>
              <div style={styles.title}>{titleAndDescription.title}</div>
              <div
                style={styles.description}
                dangerouslySetInnerHTML={{
                  __html: titleAndDescription.description,
                }}
              ></div>
            </div>
            <video
              width="320"
              height="240"
              controls
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              <source src={media._url} type="video/mp4"></source>
            </video>
          </div>
        )}
      </div>
    </Fragment>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "5rem",
  },
  podcastDetails: {
    gap: "5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: ".5rem",
  },
  thumbnail: {
    width: "29rem",
    height: "16rem",
    borderRadius: "5%",
  },
  titleAndDescriptionContainer: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "1.5rem",
    gap: "1.2rem",
  },
  title: {
    color: colors.darkBlue,
    fontSize: fontSize.UmbracoElementTitle,
    letterSpacing: "0.039375rem",
    fontFamily: fonts.FbReformaMedium,
  },
  description: {
    color: colors.nero,
    fontFamily: fonts.FbReformaLight,
    letterSpacing: "0.038125rem",
    fontSize: fontSize.Paragraph,
    maxWidth: "46.75rem",
  },
  information: {
    display: "flex",
    flexDirection: "column",
    gap: ".5rem",
    alignItems: "center",
    paddingBottom: "4rem",
  },
  duration: {
    color: colors.darkBlue,
    letterSpacing: "0.038125rem",
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Paragraph,
    marginInline: "4rem",
    display: "flex",
    gap: ".25rem",
  },
  date: {
    color: colors.nero,
    fontSize: fontSize.Text,
    fontFamily: fonts.FbReformaLight,
    letterSpacing: "0.0275rem",
  },
  audioControlsWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "2.5rem",
  },
  audioControls: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    justifyContent: "center",
  },
  control: {
    cursor: "pointer",
  },
  playbackRateIcon: {
    backgroundRepeat: "no-repeat",
    width: "4.5rem",
    height: "2.25rem",
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  audioStartIcon: {
    backgroundRepeat: "no-repeat",
  },
  audio10SecondsBackIcon: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: "1.875rem",

    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: colors.darkBlue,
  },
  audioPlayIcon: {
    width: "1.4rem",
    height: "1.8rem",
    cursor: "pointer",
  },
  audio30SecondsNextIcon: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: "1.875rem",
    height: "2rem",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: colors.darkBlue,
  },
  audioEndIcon: {
    backgroundRepeat: "no-repeat",

    cursor: "pointer",
  },
};

export default PlayingPodcast;
