import { useEffect, useRef, useState } from "react";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import colors from "../../../Assets/Colors/Colors.json";
import Images from "../../../Data/Images/Images";
import { IAutoCompleteForm } from "../../../Interfaces/IForm";
import fonts from "../../../Assets/Fonts/Fonts.json";
import { t } from "i18next";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../Services/DeviceService";
import { TranslateToSiteLanguage } from "../../../Utils/translation";
import i18n from "../../../Services/i18n";

const AutoCompleteForm: React.FC<IAutoCompleteForm> = (props) => {
  const {
    placeholder,
    items,
    onItemClick,
    defaultSelection,
    isAllDisplayed = true,
    customStyles,
    onChange,
    id,
  } = props;
  const [_isDropDownVisible, _setIsDropDownVisible] = useState(false);
  const [_filteredItems, _setFilteredItems] = useState(items);
  const [_inputValue, _setInputValue] = useState("");
  const [_backgroundItem, _setBackgroundItem] = useState(colors.white);

  const inputRef = useRef<HTMLInputElement>(null);
  const dropDownRef = useRef<HTMLDivElement>(null);

  const checkMouseClickOutside = (e: MouseEvent) => {
    if (dropDownRef.current && e.target instanceof HTMLElement) {
      if (!dropDownRef.current.contains(e.target)) {
        _setIsDropDownVisible(false);
      } else {
        _setIsDropDownVisible(true);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", checkMouseClickOutside);
    return () => {
      document.removeEventListener("click", checkMouseClickOutside);
    };
  }, []);

  useEffect(() => {
    if (defaultSelection) {
      handleItemSelect(defaultSelection);
    }
  }, [defaultSelection]);

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.value.length>1){
        const text = TranslateToSiteLanguage(event.target.value,i18n.language as "he" | "en" | "ar" | "ru");
    _setInputValue(text);

    const filteredItems = [
      ...items.filter((item) =>
        item.toLowerCase().trim().includes(text.toLowerCase().trim())
      ),
    ];
    _setFilteredItems(filteredItems);
    }
    else{
      _setFilteredItems(items)
    }
  
  };

  const handleItemSelect = (selectedItem: string) => {
    if (inputRef.current) {
      inputRef.current.value = selectedItem;
    }

    onItemClick(selectedItem);
    _setIsDropDownVisible(false);
  };

  return (
    <div
      ref={dropDownRef}
      style={{
        ...styles.container,
        width: "100%",
        border: `0.0625rem solid ${
          _isDropDownVisible ? colors.darkBlue : colors.pastelGray
        }`,
        ...customStyles,
      }}
    >
      <input
        type={"text"}
        placeholder={placeholder}
        style={{ ...styles.input, marginInlineStart: "1rem" }}
        onChange={(e) => {
          handleFilter(e);
          onChange && onChange(e);
        }}
        ref={inputRef}
        onClick={() => {
          _setIsDropDownVisible(!_isDropDownVisible);
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            _setIsDropDownVisible(true);
          }
        }}
        id={id}
      />
      <img src={Images.arrowDown} style={{ ...styles.arrowDownIcon }}></img>
      {_isDropDownVisible && _filteredItems.length > 0 && (
        <div style={styles.dropDownContainer}>
          {_filteredItems.map((item, index) => (
            <div 
              style={{...styles.item}} 
              key={index} 
              onClick={() => handleItemSelect(item)}
              // onMouseLeave={()=>{_setBackgroundItem(colors.white)}}
              // onMouseOver={()=>{_setBackgroundItem(colors.cyan)}}
            >
              <span
                onClick={() => handleItemSelect(item)}
                tabIndex={!IsMobile() ? 0 : -1}
                aria-label={`${item} ${t("MenuItemNumberOutOfNumber", {
                  currentIndex: index + 1,
                  total: _filteredItems.length,
                })}`}
                // style={{backgroundColor:_backgroundItem}}
              >
                {item}
              </span>
              {index < _filteredItems.length - 1 && (
                <div style={styles.line}></div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "18.6rem",
    width: "100%",
    height: "2.5rem",
    borderRadius: "1.25rem",
    gap: ".3rem",
    padding: "0 .5rem",
    position: "relative",
    zIndex: "30",
  },
  input: {
    width: "80%",
    fontSize: fontSize.Text,
    color: colors.pasterlGrayDark,
    letterSpacing: "0.043125rem",
    fontFamily: fonts.FbReformaRegular,
  },
  arrowDownIcon: {
    backgroundRepeat: "no-repeat",
    width: "2rem",
    height: "1.3rem",
  },
  dropDownContainer: {
    position: "absolute",
    minHeight: "10rem",
    maxHeight: "20rem",
    top: "2.7rem",
    width: "100%",
    boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 15px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: "0.3125rem",
    backgroundColor: colors.white,
    zIndex: "1",
    overflow: "auto",
    paddingTop: "1rem",
  },
  item: {
    position: "relative",
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Text,
    color: colors.black,
    letterSpacing: "0.03125rem",
    margin: "1rem",
    paddingTop: ".3rem",
    cursor: "pointer",
  },
  
  line: {
    position: "absolute",
    width: "100%",
    height: "0.125rem",
    backgroundColor: colors.platinum,
    top: "2rem",
  },
};

export default AutoCompleteForm;
