

const stationRedirect:{[key:string]:string} ={
    "/stations/afula":"/?page=stationinfo&stationname=1260",
"/stations/ahihud":"/?page=stationinfo&stationname=1820",
"/stations/ako":"/?page=stationinfo&stationname=1500",
"/stations/ashdod-ad-halom-(m-bar-kochva)":"/?page=stationinfo&stationname=5800",
"/stations/ashkelon":"/?page=stationinfo&stationname=5900",
"/stations/atlit":"/?page=stationinfo&stationname=2500",
"/stations/bat-yam-komemiyut":"/?page=stationinfo&stationname=4690",
"/stations/bat-yam-yoseftal":"/?page=stationinfo&stationname=4680",
"/stations/beer-sheva-center":"/?page=stationinfo&stationname=7320",
"/stations/beer-sheva-north-university":"/?page=stationinfo&stationname=7300",
"/stations/beer-yaakov":"/?page=stationinfo&stationname=5300",
"/stations/beit-shean":"/?page=stationinfo&stationname=1280",
"/stations/ben-gurion-airport":"/?page=stationinfo&stationname=8600",
"/stations/bet-shemesh":"/?page=stationinfo&stationname=6300",
"/stations/bet-yehoshua":"/?page=stationinfo&stationname=3400",
"/stations/binyamina":"/?page=stationinfo&stationname=2800",
"/stations/bne-brak":"/?page=stationinfo&stationname=4100",
"/stations/caesarea-pardes-hana":"/?page=stationinfo&stationname=2820",
"/stations/dimona":"/?page=stationinfo&stationname=7500",
"/stations/hadera-west":"/?page=stationinfo&stationname=3100",
"/stations/haifa-bat-galim":"/?page=stationinfo&stationname=2200",
"/stations/haifa-center-hashmona":"/?page=stationinfo&stationname=2100",
"/stations/haifa-hof-hakarmel-(razi-el)":"/?page=stationinfo&stationname=2300",
"/stations/hertsliya":"/?page=stationinfo&stationname=3500",
"/stations/hod-hasharon-sokolov":"/?page=stationinfo&stationname=9200",
"/stations/karmiel":"/?page=stationinfo&stationname=1840",
"/stations/hutsot-hamifrats":"/?page=stationinfo&stationname=1300",
"/stations/jerusalem-biblical-zoo":"/?page=stationinfo&stationname=6500",
"/stations/jerusalem-malha":"/?page=stationinfo&stationname=6700",
"/stations/jerusalem-navon":"/?page=stationinfo&stationname=680",
"/stations/kfar-habad":"/?page=stationinfo&stationname=4800",
"/stations/kfar-sava-nordau-(a-kostyuk)":"/?page=stationinfo&stationname=8700",
"/stations/kiryat-gat":"/?page=stationinfo&stationname=7000",
"/stations/kiryat-hayim":"/?page=stationinfo&stationname=700",
"/stations/kiryat-malachi":"/?page=stationinfo&stationname=6150",
"/stations/kiryat-motzkin":"/?page=stationinfo&stationname=1400",
"/stations/lehavim-rahat":"/?page=stationinfo&stationname=8550",
"/stations/lev-hamifrats":"/?page=stationinfo&stationname=1220",
"/stations/lod":"/?page=stationinfo&stationname=5000",
"/stations/lod-gane-aviv":"/?page=stationinfo&stationname=5150",
"/stations/mazkeret-batya":"/?page=stationinfo&stationname=6900",
"/stations/migdal-haemek-kfar-barukh":"/?page=stationinfo&stationname=1250",
"/stations/modiin-center":"/?page=stationinfo&stationname=400",
"/stations/nahariya":"/?page=stationinfo&stationname=1600",
"/stations/netanya":"/?page=stationinfo&stationname=3300",
"/stations/netanya-sapir":"/?page=stationinfo&stationname=3310",
"/stations/netivot":"/?page=stationinfo&stationname=9650",
"/stations/ofakim":"/?page=stationinfo&stationname=9700",
"/stations/paate-modiin":"/?page=stationinfo&stationname=300",
"/stations/petah-tikva-kiryat-arye":"/?page=stationinfo&stationname=4170",
"/stations/petah-tikva-segula":"/?page=stationinfo&stationname=4250",
"/stations/raanana-south":"/?page=stationinfo&stationname=2960",
"/stations/raanana-west":"/?page=stationinfo&stationname=2940",
"/stations/ramla":"/?page=stationinfo&stationname=5010",
"/stations/rishon-letsiyon-harishonim":"/?page=stationinfo&stationname=9100",
"/stations/rishon-letsiyon-moshe-dayan":"/?page=stationinfo&stationname=9800",
"/stations/rosh-haayin-north":"/?page=stationinfo&stationname=8800",
"/stations/sderot":"/?page=stationinfo&stationname=9600",
"/stations/tel-aviv-hahagana":"/?page=stationinfo&stationname=4900",
"/stations/tel-aviv-hashalom":"/?page=stationinfo&stationname=4600",
"/stations/tel-aviv-savidor-center":"/?page=stationinfo&stationname=3700",
"/stations/tel-aviv-university":"/?page=stationinfo&stationname=3600",
"/stations/yavne-east":"/?page=stationinfo&stationname=5410",
"/stations/yavne-west":"/?page=stationinfo&stationname=9000",
"/stations/yokneam-kfar-yehoshua":"/?page=stationinfo&stationname=1240",
"/stations/rehovot-(e-hadar)":"/?page=stationinfo&stationname=5200",
"/stations/holon-junction":"/?page=stationinfo&stationname=4640",
"/stations/holon-wolfson":"/?page=stationinfo&stationname=4660"
}


export const checkStationsRedirect = (url:string)=>{

   const languge = url.includes(`/ar/`)?`&lan=ar`:url.includes(`/en/`)?`&lan=en`:url.includes(`/ru/`)?`&lan=ru`:`&lan=he`
   let currntLan = ''
   switch (languge) {
    case '&lan=ar':
        currntLan =`/ar`
        break;
    case '&lan=en':
        currntLan=`/en`
        break;
    case '&lan=ru':
        currntLan=`/ru`
        break;
    default:
        break;
   }


   const oldUrl = url.replace(`${currntLan}`,'')
   const newUrl = stationRedirect[`${oldUrl}`]
   if(newUrl){
     return `${newUrl}${languge}`
   }
   else
    return `/?page=PageNotFound`
}