import { t } from "i18next";
import Images from "../../../../../../Data/Images/Images";

import { Fragment, useEffect, useRef, useState } from "react";
import colors from "../../../../../../Assets/Colors/Colors.json";
import i18n from "../../../../../../Services/i18n";
import { StyleSheet } from "../../../../Interfaces_tenders/IStyleSheet";
import Title from "../../Title";

import { FormatDateYYYYMMDD_Dots } from "../../../../../../Services/DateService";
import { IServerFile } from "../../../../Interfaces_tenders/IUmbraco";
import RichTextEditor from "../../RichTextEditor";

import {
  ChangeTitle,
  StyleMode,
  TitlePage,
} from "../../../../../../Utils/consts";
import useDeviceMode from "../../../../../../Utils/useWindowDimensions";

import { FilesToZipServiceServer } from "../../../../../../Services/FilesToZipServiceServer";
import { PostEmailList } from "../../../../../../Services/tenderServerServices";
import {
  IAuctionsSystemAuctions,
  IAuctionsSystemMeetings,
} from "../../../../Interfaces_tenders/IAuctionsSystemDetails";
import FileViewerServer from "../../../FileViewer/FileViewerServer";
import ServerFile from "../../File/ServerFile";
import MeetingCard from "../Meetings/MeetingCard";

import { useSelector } from "react-redux";
import fonts from "../../../../../../Assets/Fonts/Fonts.json";
import CustomModal from "../../../../../../Components/CustomModal";
import {
  linkBuilder,
  linkShareFormat,
} from "../../../../../../Components/SharePage/ShareOptions";
import SocialShare from "../../../../../../Components/SocialShare/SocialShare";
import { SystemParamsState } from "../../../../../../Redux/Slices/systemParamsSlice";
import { FormatHoursMinutes } from "../../../../../../Services/DateService";
import { UseWindowScrollPositions } from "../../../../../../Utils/useWindowScrollPositions";
import InputForm from "../../../Form/InputForm/InputForm";

const AuctionDetailsModal: React.FC<IAuctionsSystemAuctions> = (props) => {
  const {
    tenderName,
    tenderNumber,
    referentMail,
    publishDate,
    clarifyingDate,
    biddingDate,
    meetings,
    documentation,
    biddings,
    description,
  } = props;
  const [_isOpenFileViewerModal, _setIsOpenFileViewerModal] = useState(false);
  const [_currentFileName, _setCurrentFileName] = useState<string>("");
  const appInfoRef = useRef<HTMLDivElement | null>(null);
  const [_isArrowAnimation, _setIsArrowAnimation] = useState(true);
  const handleOpenFilesViewer = () => {
    _setIsOpenFileViewerModal(true);
  };

  const [_isUpdateMeModalOpen, _setIsUpdateMeModalOpen] = useState(false);
  const [_fakeLoaderFlag, _setFakeLoaderFlag] = useState(true);
  const [_emailAdressValue, _setEmailAdressValue] = useState<string>("");
  const [_nameAdressValue, _setNameAdressValue] = useState<string>("");

  const [_mobileFilterIsOpen, _setMobileFilterIsOpen] = useState(false);
  const [_curDisplayContentGot, _setCurDisplayContentGot] = useState(false);

  const [_areAuctionsFiltered, _setAreAuctionsFiltered] = useState(false);

  const [_mailingFormDisplay, _setMailingFormDisplay] = useState(true);
  const errorDisplayUpdateMe = useSelector(
    (state: any) => state.AuctionsErrorStatusUpdateClientNewTenders
  );

  const [_placeHolderFrom, _setPlaceHolderFrom] = useState(true);
  const [_placeHolderTo, _setPlaceHolderTo] = useState(true);
  const deviceMode = useDeviceMode();
  const validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const { scrollY } = UseWindowScrollPositions();
  const serverFiles: IServerFile[] =
    documentation &&
    documentation.map((file, index) => {
      return {
        document: file.docName,
        filename: file.docDisplay ? file.docDisplay : t("defaultFileNeme"),
      };
    });
  const [_isDetailsModalOpen, _setIsDetailsModalOpen] = useState(false);
  const [_currentMeetingsDetails, _seCcurrentMeetingsDetails] =
    useState<IAuctionsSystemMeetings[]>();

  const [_isShareCardModalVisible, _setIsShareCardModalVisible] =
    useState(false);

  useEffect(() => {
    ChangeTitle(t(`${TitlePage.HomePage}`));
  }, []);

  const handleShareModal = () => {
    _setIsShareCardModalVisible(true);
  };
  const systemParams = (
    useSelector((state: any) => state.SystemParamsReducer) as SystemParamsState
  ).systemParams;

  const systemParamsDictionary = systemParams.reduce(
    (result: { [key: string]: string }, current) => {
      result[current.key] = current.value;
      return result;
    },
    {}
  );

  const getShareUrl = (url: string) => {
    let link = `${window.location.href}&step=openauctions`;
    linkShareFormat.title = description;
    linkShareFormat.subject = t("shareButtonAria") + " " + tenderNumber;
    linkShareFormat.body = encodeURIComponent(
      t("shareButtonAria") + " " + tenderNumber + "%0A" + link
    );
    linkShareFormat.summary = encodeURIComponent(description + "%0A" + link);
    linkShareFormat.text = encodeURIComponent(
      t("shareButtonAria") + " " + tenderNumber + "%0A" + link
    );
    linkShareFormat.quote = description;
    linkShareFormat.url = encodeURIComponent(link);
    linkShareFormat.u = encodeURIComponent(link);
    // sharePagetext
    let newLink = linkBuilder(url, linkShareFormat);

    return decodeURI(newLink);
  };
  const sendEmailToMailingListHandler = () => {

    (async () => {
      const postEmailAdress = await PostEmailList({
        mails: _emailAdressValue,
        name: _nameAdressValue,
        tenderId: props.id,
        page: props.category,
      });

      if (postEmailAdress.status === 200) {
        _setMailingFormDisplay(false);
      }
    })();
  };

  return (
    <Fragment>
      <div style={styles.container}>
        <div
          style={{
            paddingTop: "3rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title text={t("AuctionNumber", { tenderNumber }).slice(0, 35)} />
          {new Date(biddingDate) > new Date() &&
            systemParamsDictionary["updateSpecificTender"] === "true" && (
              <div
                style={styles.updateMeContainer}
                onClick={() => {
                  _setIsUpdateMeModalOpen(true);
                }}
                tabIndex={0}
                aria-label={t("updateMeOnChange")}
              >
                <img src={Images.bellIcon} style={styles.bellIcon} />
                {deviceMode !== StyleMode.mobile && (
                  <div style={styles.updateMe}>{t("updateMeOnChange")}</div>
                )}
              </div>
            )}
        </div>
        <div>
          <Title text={tenderName} isUnderline={false} />
        </div>
        <div style={styles.datesContainer}>
          <div style={styles.dateItem}>
            <img src={Images.datePicker}></img>
            <div style={styles.datePublishContainer}>
              <div>{t("AuctionPublishDate")}</div>
              <div style={{ fontWeight: "bold" }}>
                {FormatDateYYYYMMDD_Dots(new Date(publishDate))}
                {` - `}
                {FormatHoursMinutes(publishDate?.toString())}
              </div>
              {/* <div style={{ fontWeight: "bold" }}>
              {new Date(publishDate).toLocaleTimeString().substring(0,5)}
              </div> */}
            </div>
          </div>
          {clarifyingDate &&
            clarifyingDate.toString() !== "0001-01-01T00:00:00" && (
              <div style={styles.dateItem}>
                <img src={Images.datePicker}></img>
                <div style={styles.datePublishContainer}>
                  <div>{t("AuctionDeadlineForSubmissionOfQuestions")}</div>
                  <div style={{ fontWeight: "bold" }}>
                    {FormatDateYYYYMMDD_Dots(new Date(clarifyingDate))}
                    {` - `}
                    {FormatHoursMinutes(clarifyingDate?.toString())}
                  </div>
                  <div style={{ fontWeight: "bold" }}></div>
                </div>
              </div>
            )}
          <div style={styles.dateItem}>
            <img src={Images.datePicker}></img>

            <div style={styles.datePublishContainer}>
              <div>{t("AuctionDeadlineForSubmissionOfBids")}</div>
              <div style={{ fontWeight: "bold" }}>
                {FormatDateYYYYMMDD_Dots(new Date(biddingDate))}
                {` - `}
                {FormatHoursMinutes(biddingDate?.toString())}
              </div>
              {/* <div style={{ fontWeight: "bold" }}>
              {new Date(biddingDate).toLocaleTimeString().substring(0,5)}
              </div> */}
            </div>
          </div>
        </div>
        {meetings && meetings.length > 0 && (
          <div style={styles.meetingContainer}>
            <Title text={t("MeetingOffers")} />

            {meetings &&
              meetings.map((meeting, index) => (
                <MeetingCard
                  key={index}
                  meetingsId={meeting.meetingsId}
                  meetingDate={meeting.meetingDate}
                  registretionLink={meeting.registretionLink}
                  location={meeting.location}
                />
              ))}
          </div>
        )}
        {biddings && biddings.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
            }}
          >
            <Title text={t("HowAndWhenSendOffer")} />
            <div>
              <RichTextEditor text={biddings} />
            </div>
          </div>
        )}
        {
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
              paddingBottom: "2rem",
            }}
          >
            {documentation && documentation.length > 0 && (
              <div>
                <Title text={t("AuctionDocuments")} />
              </div>
            )}
            {serverFiles && serverFiles.length > 0 && (
              <div
                style={
                  deviceMode === StyleMode.mobile
                    ? {
                        ...styles.auctionDocumentsContainer,
                        flexDirection: "column",
                      }
                    : styles.auctionDocumentsContainer
                }
              >
                {serverFiles &&
                  serverFiles.map((document, index) => (
                    <div
                      key={index}
                      style={styles.auctionDocumentFile}
                      onClick={() => {
                        if (deviceMode !== StyleMode.mobile) {
                          handleOpenFilesViewer();
                        } else {
                          const filesToDownload = [
                            ...serverFiles.filter(
                              (file) => file.document !== null
                            ),
                          ];
                          //const filesToDownload = [document];
                          FilesToZipServiceServer(
                            filesToDownload,
                            document.filename
                          );
                        }
                      }}
                    >
                      <ServerFile
                        document={document.document}
                        filename={document.filename}
                        onChangeFileName={(text) => _setCurrentFileName(text)}
                        filenameStyles={{
                          display: "flex",
                          gap: ".5rem",
                          justifyContent: "start",
                          alignItems: "center",
                          color: colors.darkBlue,
                          fontSize: "1.2rem",
                          cursor: "pointer",
                          marginBottom: "1%",
                          textDecoration: "none",
                        }}
                      />
                      {/*
                  <div
                    style={{
                      ...styles.fileIcon,
                      background: `url(${
                        Images[
                          `${document.document.umbracoExtension}Icon` as keyof {
                            [key: string]: any;
                          }
                        ]
                      }) no-repeat`,
                    }}
                  ></div>
                
                  <div>{document.filename}</div>*/}
                    </div>
                  ))}
              </div>
            )}

            {description && description.length > 0 && (
              <div>
                <Title text={t("AuctionDescription")} />
              </div>
            )}

            {description && description.length > 0 && (
              <div>
                <div
                  style={styles.descriptionText}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
            )}

            <div
              style={{
                display: "flex",
              }}
            ></div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <div
                  tabIndex={0}
                  style={styles.requestClarification}
                  onClick={() => {
                    window.location.href = `mailto:${referentMail}`;
                  }}
                >
                  {t("RequestForClarification")}
                </div>
              </div>
              <div>
                <span>
                  <img
                    src={
                      appInfoRef.current &&
                      appInfoRef.current.getBoundingClientRect().top +
                        appInfoRef.current.getBoundingClientRect().height <
                        scrollY
                        ? Images.sharePng
                        : Images.sharePng
                    }
                    style={
                      deviceMode === StyleMode.mobile
                        ? {
                            ...styles.arrowIcon,
                            left: i18n.dir() === "rtl" ? "14%" : "",
                            right: i18n.dir() === "ltr" ? "14%" : "",
                            animationName: _isArrowAnimation
                              ? "moveUpDown"
                              : "",
                            width: "3rem",
                            // borderRadius: "100%",
                            cursor: "pointer",
                          }
                        : {
                            ...styles.arrowIcon,
                            left: i18n.dir() === "rtl" ? "4%" : "",
                            right: i18n.dir() === "ltr" ? "4%" : "",

                            animationName: _isArrowAnimation
                              ? "moveUpDown"
                              : "",
                            width: "3rem",
                            //  borderRadius: "100%",
                            cursor: "pointer",
                          }
                    }
                    tabIndex={0}
                    role={"button"}
                    aria-label={t("shareButtonAria")}
                    onClick={handleShareModal}
                  />
                </span>
              </div>
            </div>
          </div>
        }
      </div>
      <CustomModal visible={_isOpenFileViewerModal}
        onClose={() => _setIsOpenFileViewerModal(false)}
        showCloseButton="internal" dirClosebutton="start" typeStyle={"rectangle"}
        innerStyle={{ width: "85%", maxHeight: "100%", padding: 0, maxWidth: "unset", height: "50rem" }}
        contentStyle={{ padding: 0 }}>
        {serverFiles && serverFiles.length > 0 && (
          <FileViewerServer
            files={serverFiles}
            title={`${t("tenderIndexNumber")} ${tenderNumber}`}
            curFileName={_currentFileName}
            key={new Date().toDateString()}
          />
        )}
      </CustomModal>
      <CustomModal
        visible={_isShareCardModalVisible}
        onClose={() => _setIsShareCardModalVisible(false)}
        typeStyle={"rectangle"}
        icon={Images.share}
        title={t("Share")}
        responsiveModal={"bottom"}
        contentStyle={{paddingTop: "1rem"}}
        innerStyle={{ width: "100%", minHeight: "16rem", padding: 0, lineHeight: "1rem",
        maxWidth: "25rem",
          overflow:"visible"}}
      >
        <SocialShare
          items={[
            {
              name: t("Facebook"),
              icon: Images.facebookHeader,
              link: getShareUrl(
                `https://www.facebook.com/sharer/sharer.php?u=`
              ),
              ariaLabel: t("ShareFacebook"),
            },
            {
              name: t("WhatsApp"),
              icon: Images.whatsapp,
              link: getShareUrl(`https://wa.me/?text=`),
              ariaLabel: t("ShareWhatsapp"),
            },
            {
              name: t("Mail"),
              icon: Images.shareMail,
              link: getShareUrl(
                `mailto:?subject=&body=`
              ),
              ariaLabel: t("ShareEmail"),
            },
            {
              name: t("Linkedin"),
              icon: Images.linkedinHeader,
              link: getShareUrl(
                `https://www.linkedin.com/sharing/share-offsite/?url=`
              ),
              ariaLabel: t("ShareLinkedin"),
            },
          ]}
        />
        <button
          className="btn-modal"
          onClick={() => _setIsShareCardModalVisible(false)}
          style={{ textAlign: "center", backgroundColor: "#F0F0F0" }}
        >
          {t("Cancel")}
        </button>
      </CustomModal>

      <CustomModal visible={_isUpdateMeModalOpen}
        onClose={() => _setIsUpdateMeModalOpen(false)}
        icon={Images.bellBigIcon}
        showCloseButton="internal"
        contentStyle={{ paddingTop: "1rem" }}
        innerStyle={{
          width: "85%",
          maxHeight: "100%",
          maxWidth: "42rem",
          height: "22rem",
          borderRadius: "13px 13px 288px 288px / 13px 13px 43px 43px",
        }}
      >

        <div style={styles.updateMeModalFormContainer}>
          <div
            style={styles.updateMeModalTitle}
            dangerouslySetInnerHTML={{
              __html: t("updateMeOnChange"),
            }}
          />
          {_mailingFormDisplay && (
            <InputForm
              placeholder={t("firstName")}
              onChange={(e) => {
                _setNameAdressValue(e);
              }}
              type="Text"
            />
          )}
          {_mailingFormDisplay && (
            <InputForm
              placeholder={t("Mail")}
              onChange={(e) => {
                _setEmailAdressValue(e);
              }}
              type="Email"
            />
          )}
          {_mailingFormDisplay && (
            <div
              style={
                _emailAdressValue === "" ||
                  !_emailAdressValue.match(validRegex)
                  ? {
                    ...styles.updateMeButton,
                    pointerEvents: "none",
                    opacity: "0.4",
                  }
                  : {
                    ...styles.updateMeButton,
                  }
              }
              tabIndex={0}
              role={"button"}
              onClick={sendEmailToMailingListHandler}
            >
              {t("updateMe")}
            </div>
          )}
          {!_mailingFormDisplay && !errorDisplayUpdateMe && (
            <div style={styles.updateMeModalTitle}>{t("mailingListAdd")}</div>
          )}
          {errorDisplayUpdateMe && (
            <p>{t("errorMessageUpdateClientNewTenders")}</p>
          )}
        </div>

      </CustomModal>
    </Fragment>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "5rem",
    height: "100%",
    overflow: "auto",
    paddingInlineStart: "3rem",
    paddingInlineEnd: "3rem",
    maxWidth: "70rem",
    width: "100%",
  },
  meetingTimeItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
    cursor: "pointer",
  },
  icon: {
    backgroundRepeat: "no-repeat",
    width: "2.5rem",
    height: "2.5rem",
  },
  fileIcon: {
    backgroundSize: "contain",
    width: "1.5rem",
    height: "1.5rem",
  },
  datesContainer: {
    display: "flex",
    gap: "3rem",
    flexWrap: "wrap",
    alignItems: "center",
  },
  dateItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: colors.darkBlue,
    fontSize: "1.2rem",
    gap: "1rem",
  },
  auctionDocumentFile: {
    display: "flex",
    gap: ".5rem",
    justifyContent: "start",
    alignItems: "center",
    color: colors.darkBlue,
    fontSize: "1.2rem",
    cursor: "pointer",
    marginBottom: "1%",
    flexBasis: "45%",
  },
  auctionDocumentsContainer: {
    display: "flex",
    gap: "10%",
    flexWrap: "wrap",
  },
  bellBigIcon: {
    backgroundImage: `url(${Images.bellBigIcon})`,
    width: "2rem",
    height: "2rem",
    backgroundRepeat: "round",
  },
  bellIcon: {
    width: "1.5rem",
  } as React.CSSProperties,
  updateMe: {
    fontFamily: fonts.FbReformaRegular,
    //  fontSize: "1.525rem",
    color: "rgb(0, 69, 138)",
  } as React.CSSProperties,
  updateMeContainer: {
    display: "flex",
    gap: ".5rem",
    alignItems: "center",
    cursor: "pointer",
  } as React.CSSProperties,
  requestClarification: {
    backgroundColor: colors.darkBlue,
    color: colors.white,
    fontSize: "1rem",
    borderRadius: "16rem",
    justifyContent: "center",
    paddingTop: ".5rem",
    alignItems: "center",
    maxWidth: "12rem",
    minHeight: "1.8rem",
    textAlign: "center",
    cursor: "pointer",
    paddingInline: "1rem",
  },
  datePublishContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  meetingContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },
  descriptionText: {
    color: colors.darkBlue,
    fontSize: "1.2rem",
  } as React.CSSProperties,
  updateMeModalFormContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "1.5rem",
  } as React.CSSProperties,
  updateMeModalTitle: {
    fontSize: "1.6rem",
    letterSpacing: "0.035625rem",
    lineHeight: "1.75rem",
    fontFamily: fonts.FbReformaRegular,
    color: colors.darkBlue,
    maxWidth: "22rem",
    textAlign: "center",
  } as React.CSSProperties,

  updateMeButton: {
    backgroundColor: colors.darkBlue,
    color: colors.white,
    fontFamily: fonts.FbReformaMedium,
    fontSize: "1.2rem",
    borderRadius: "16rem",
    width: "8rem",
    
    fontWeight: "bold",
    letterSpacing: "0.029375rem",
    textAlign: "center",
    padding: ".5rem",
    cursor: "pointer",
  } as React.CSSProperties,
};

export default AuctionDetailsModal;
