import { t } from "i18next";
import { Fragment, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import colors from "../../../../../../../Assets/Colors/Colors.json";
import fonts from "../../../../../../../Assets/Fonts/Fonts.json";
import Images from "../../../../../../../Data/Images/Images";
import { FormatDateYYYYMMDD_Dots } from "../../../../../../../Services/DateService";
import { FilesToZipServiceServer } from "../../../../../../../Services/FilesToZipServiceServer";
import i18n from "../../../../../../../Services/i18n";
import { truncateText } from "../../../../../../../Utils/helpers";
import { IAuctionsSystemExemptionNotices } from "../../../../../Interfaces_tenders/IAuctionsSystemDetails";
import { StyleSheet } from "../../../../../Interfaces_tenders/IStyleSheet";
import { IServerFile } from "../../../../../Interfaces_tenders/IUmbraco";
import ServerFile from "../../../File/ServerFile";

const ExemptionNoticesCardMobile: React.FC<IAuctionsSystemExemptionNotices> = (
  props
) => {
  const {
    publishDate,
    subject,
    supplierName,
    documentation,
    dynamicTextsDict,
    currentIndex,
    numOfAuctions,
  } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const dynamicTexts =
    dynamicTextsDict && dynamicTextsDict.length > 0
      ? dynamicTextsDict[0]
      : undefined;
  const [_isModalOpen, _setIsModalOpen] = useState(false);
  const [_currentFileName, _setCurrentFileName] = useState<string>("");

  const opinionFile =
    documentation && documentation.filter((file) => file.docType === 5)[0];


  const opinionFilesMessage: IServerFile[] =
    opinionFile &&
    documentation &&
    documentation
      .filter((file) => file.docType === opinionFile.docType)
      .map((file) => {
        return {
          document: file.docName,
          filename: file.docDisplay ? file.docDisplay : t("defaultFileNeme"),
          displayName: opinionFile.docDisplay,
        };
      });

  return (
    <div
      style={styles.exemptionNoticesCardContainer}
      role={"main"}
      tabIndex={0}
      aria-label={
        t("tenderIndexNumber") +
        (currentIndex && currentIndex) +
        t("fromTotal") +
        (numOfAuctions && numOfAuctions)
      }
    >
      <div style={styles.exemptionNoticesHeader}>
        <div style={styles.subjectName} title={subject}>
          {subject && truncateText(subject, 30)}
        </div>
        <div style={styles.supplierRow}>
          <img src={Images.person} style={{ scale: "0.7" }}></img>
          <div>
            {" "}
            {dynamicTexts && dynamicTexts["supplier"].text}: {supplierName}
          </div>
        </div>
        <div style={styles.docContainer}>
          {documentation && opinionFile && (
            <Fragment>
              <div style={styles.dateTitle}>
                {" "}
                {dynamicTexts && dynamicTexts["document"].text}
              </div>

              <ServerFile
                isMobile
                filename={
                  opinionFile.docDisplay
                    ? opinionFile.docDisplay
                    : t("defaultFileNeme")
                }
                document={opinionFile.docName}
                onClick={() => {

                  const filesToDownload = [
                    ...opinionFilesMessage.filter(
                      (file) => file.document !== null
                    ),
                  ];
                  FilesToZipServiceServer(
                    filesToDownload,
                    opinionFile.docDisplay
                      ? opinionFile.docDisplay
                      : t("defaultFileNeme")
                  );

                }}
                onChangeFileName={(text) => _setCurrentFileName(text)}
                filenameStyles={{ textDecoration: "underline" }}
                displayName={opinionFile.docDisplay}
              />
            </Fragment>
          )}
        </div>
        {publishDate &&
          publishDate.toString() !== "0001-01-01T00:00:00" &&
          publishDate.toString() !== "1900-01-01T00:00:00" &&
          publishDate.toString() !== "8900-12-31T00:00:00" && (
            <div style={{
              ...styles.itemSideLine,
              borderRight:
                i18n.dir() === "rtl" ? `.1rem solid ${colors.black}` : "",
              borderLeft:
                i18n.dir() === "ltr" ? `.1rem solid ${colors.black}` : "",
            }}>
              <div style={styles.dateTitle}>
                {" "}
                {dynamicTexts && dynamicTexts["publishDate"].text}
              </div>
              <div style={styles.dateFormat}>
                {FormatDateYYYYMMDD_Dots(new Date(publishDate))}
              </div>
            </div>
          )}
      </div>
      <div style={styles.itemsSideLineContainer}></div>
    </div>
  );
};

const styles: StyleSheet = {
  exemptionNoticesCardContainer: {
    display: "flex",
    flexDirection: "column",
    height: "16rem",
    maxWidth: "40.625rem",
    //minWidth: "25rem",
    width: "80%",
    gap: ".5rem",
    boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 15px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: "0.625rem",
  },
  exemptionNoticesHeader: {
    display: "flex",
    flexDirection: "column",
    padding: "1.45rem",
    gap: "1rem",
    flex: 1,
  },
  subjectName: {
    color: colors.darkBlue,
    fontSize: "1.6rem",
    letterSpacing: "0.039375rem",
    fontFamily: fonts.FbReformaMedium,
    maxWidth: "95%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  supplierRow: {
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
  },
  icon: {
    backgroundRepeat: "no-repeat",
    width: "1.25rem",
    height: "1.5rem",
  },
  itemsSideLineContainer: {
    display: "flex",
    alignItems: "center",
    paddingInline: "2rem",
    gap: "1rem",
    flex: 1,
  },
  itemSideLine: {
    // borderRight: i18n.dir() === "rtl" ? `.1rem solid ${colors.black}` : "",
    // borderLeft: i18n.dir() === "ltr" ? `.1rem solid ${colors.black}` : "",
    padding: "0 1rem",
  },
  dateTitle: {
    fontFamily: fonts.FbReformaRegular,
    color: colors.pasterlGrayDark,
  },
  docContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
};

export default ExemptionNoticesCardMobile;
