import React, { useState } from "react";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { IAppInfo } from "../../Interfaces/IUmbraco";
import { StyleMode } from "../../Utils/consts";
import { RemoveHtmlTags } from "../../Utils/helpers";
import useDeviceMode from "../../Utils/useWindowDimensions";
import { replaceRichtextBoldWithFont } from "../../Utils/utils";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../Services/DeviceService";

const AppInfo: React.FC<IAppInfo> = (props) => {
  const { baseElement, links, sideImage, sideImageAlt ,subTitle} = props;
  const deviceMode = useDeviceMode();
  const [_itemKeyOver, _setItemKeyOver] = useState("");

  const openExternalPage = (page?: string) => {
    if (page) {
      window.open(page, "_blank");
    }
  };
  

  return (
    <div
      style={{
        ...styles.appInfoContainer,
        flexDirection:
          deviceMode === StyleMode.desktop ? "row" : "column-reverse",
        gap: "1.5rem",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: deviceMode === StyleMode.desktop ? "start" : "center",
        }}
      >
        <h2
          style={{
            fontFamily: fonts.FbReformaRegular,
            fontSize: deviceMode === StyleMode.desktop ? "2.8rem" : "1.8rem",
            lineHeight: deviceMode === StyleMode.desktop ? "3.125rem" : "2rem",
            maxWidth: deviceMode === StyleMode.desktop ? "20rem" : "14rem",
            textAlign: deviceMode !== StyleMode.desktop ? "center" : "start",
            color: colors.darkBlue,
            fontWeight:"unset"
          }}
        >
          {baseElement.titleAndDescription?.title}
          <div style={{
              fontWeight: "bold",
         
            }}>{subTitle}</div>
        </h2>
        <div
          style={{
            maxWidth: deviceMode === StyleMode.desktop ? "37.75rem" : "25rem",
            textAlign: deviceMode !== StyleMode.desktop ? "center" : "start",
          }}
          aria-label={`${RemoveHtmlTags(
            baseElement.titleAndDescription!.description
          )}`}
        >
          <label
            style={{
              fontFamily: fonts.FbReformaLight,
              lineHeight: "1.75rem",
              fontSize: fontSize.Paragraph,
            }}
            dangerouslySetInnerHTML={{
              __html: replaceRichtextBoldWithFont(
                baseElement.titleAndDescription!.description
              ),
            }}
          ></label>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            gap: "1.2rem",
          }}
          id={`linkDiv`}
        >
          {links.map((link, key) => (
            <img
              onMouseOver={() => {
                _setItemKeyOver(key.toString());
              }}
              onMouseLeave={() => {
                _setItemKeyOver("");
              }}
              onClick={() => openExternalPage(link.redirectTo)}
              key={key}
              src={link.image._url}
              style={{
                maxWidth: `150rem`,
                width: `13rem`,
                maxHeight: `100%`,
                cursor: "pointer",
                filter: `brightness(${
                  _itemKeyOver === key.toString() ? ".8" : ""
                })`,
              }}
              alt={link.text.text}
              tabIndex={!IsMobile() ? 0 : -1}
              role={`link`}
            />
          ))}
        </div>
      </div>
      <img
        src={sideImage._url}
        width={"100%"}
        height={"100%"}
        style={styles.sideImage}
        alt={sideImageAlt ? sideImageAlt : ``}
      />
    </div>
  );
};

const styles: StyleSheet = {
  appInfoContainer: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "space-around",
  } as React.CSSProperties,
  sideImage: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    maxWidth: "30rem",
    maxHeight: "30rem",
  } as React.CSSProperties,
};

export default AppInfo;
