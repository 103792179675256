

export const Actions ={
    setUpdate:`setUpdate`,
    setTree:`setTree`,
    setOrigin:`setOrigin`, 
    setDestination:`setDestination`,
    setDate:`setDate`,
    setHours:`setHours`,
    setMinutes:`setMinutes`, 
    setScheduleType:`setScheduleType`,
    setPage:`setPage`,
    Auctions:`Auctions`,
    /*setAuctionsTypes :`setAuctionsTypes`,*/
    setErrorStatus:`setErrorStatus`,
    setErrorStatusUpdateClientNewTenders:`setErrorStatusUpdateClientNewTenders`
}