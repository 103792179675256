import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import Images from "../../../Data/Images/Images";
import { ILinkOpenType, IMenuItem } from "../../../Interfaces/IMenu";
import { CheckIsEnable } from "../../../Utils/consts";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../Services/DeviceService";

const MenuItemMobile: React.FC<IMenuItem> = (props) => {
  const {
    label,
    redirectTo,
    subMenus,
    isEnabled,
    onClick,
    icon,
    openType,
    currentIndex,
    totalItems,
    deviceToDisplay = "Both",
  } = props;
  const navigate = useNavigate();
  const [_isSubMenus, _setIsSubMenus] = useState<boolean>(false);
  const subMenusContainerRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (_isSubMenus) {
      if (subMenusContainerRef.current) {
        subMenusContainerRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [_isSubMenus]);

  const redirectToPage = (page?: string, open?: ILinkOpenType[`Type`]) => {
    let currentOpenType = open ? open : openType;
    if (page) {
      switch (currentOpenType) {
        case "Internal":
          navigate(`?page=${page}`);
          break;
        case "External":
          window.open(page, "_blank");
          break;
      }
    }
  };

  const handleToggleMenuItemClick = () => {
    _setIsSubMenus(!_isSubMenus);
  };

  switch (CheckIsEnable(isEnabled)) {
    case true:
      return deviceToDisplay === "Both" || deviceToDisplay === "Mobile" ? (
        <Fragment>
          <div style={styles.menuItem}>
            <div>
              <div
                style={styles.itemLabel}
                onClick={() => {
                  if (subMenus.length > 0) {
                    handleToggleMenuItemClick();
                  }
                }}
                onKeyPressCapture={() => {
                  if (subMenus.length > 0) {
                    handleToggleMenuItemClick();
                  }
                }}
              >
                <div
                  onClick={() => {
                    if (subMenus.length === 0) {
                      redirectToPage(redirectTo);
                      onClick && onClick();
                    }
                  }}
                  onKeyPressCapture={() => {
                    if (subMenus.length === 0) {
                      redirectToPage(redirectTo);
                      onClick && onClick();
                    }
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1.2rem",
                    width: "100%",
                  }}
                >
                  <img
                    src={icon ? icon._url : ""}
                    style={{
                      width: "1.8rem",
                    }}
                    width={`auto`}
                    height={`auto`}
                    alt={``}
                  />
                  <span
                    role={redirectTo ? "link" : "button"}
                    tabIndex={!IsMobile() ? 0 : -1}
                    style={{
                      fontSize: fontSize.Paragraph,
                    }}
                  >
                    {label}
                  </span>
                </div>
                <div>
                  {subMenus.length > 0 && (
                    <img
                      src={!_isSubMenus ? Images.arrowDown : Images.arrowUp}
                      style={{
                        transform: "scale(1.5)",
                      }}
                      width={`auto`}
                      height={`auto`}
                      alt={``}
                    />
                  )}
                </div>
              </div>
              <div>
                {_isSubMenus && (
                  <div style={styles.subMenusContainer}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    ></div>
                    <ul style={styles.subMenuItems} ref={subMenusContainerRef}>
                      {redirectTo !== "" && (
                        <li style={styles.menuItem}>
                          <span
                            tabIndex={!IsMobile() ? 0 : -1}
                            onKeyPressCapture={(e) => {
                              e.stopPropagation();
                              redirectToPage(redirectTo);
                              onClick && onClick();
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              redirectToPage(redirectTo);
                              onClick && onClick();
                            }}
                            style={{
                              color: colors.dimGray,
                              fontSize: fontSize.SubTitle,
                            }}
                          >
                            {label}
                          </span>
                        </li>
                      )}
                      {subMenus.map((subMenu, index) =>
                        subMenu.isEnabled === "Yes" ? (
                          <li
                            key={index + subMenu.label}
                            style={styles.menuItem}
                          >
                            <span
                              tabIndex={!IsMobile() ? 0 : -1}
                              onKeyPressCapture={(e) => {
                                e.stopPropagation();
                                redirectToPage(
                                  subMenu.redirectTo,
                                  subMenu.linkTo
                                );
                                onClick && onClick();
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                redirectToPage(
                                  subMenu.redirectTo,
                                  subMenu.linkTo
                                );
                                onClick && onClick();
                              }}
                              style={{
                                color: colors.dimGray,
                              }}
                            >
                              {subMenu.label}
                            </span>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Fragment>
      ) : null;
    default:
      return null;
  }
};

const styles: StyleSheet = {
  menuItem: {
    display: "flex",
    flexDirection: "column",
    fontFamily: fonts.FbReformaRegular,
    gap: "1rem",
    cursor: "pointer",
    color: colors.darkBlue,
  },

  subMenuItems: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "3.125rem",
    fontSize: fontSize.Paragraph,
    fontFamily: fonts.FbReformaRegular,
    textDecoration: "underline",
    textUnderlineOffset: ".5rem",
    textDecorationColor: colors.gentleGray,
  },
  subMenusContainer: {
    paddingInlineStart: "1.7rem",
    paddingInlineEnd: "1.7rem",
  },
  subMenuParent: {
    fontSize: fontSize.Paragraph,
    padding: "1rem",
  },
  backToMenuItem: {
    fontSize: fontSize.H1,
    padding: "1rem",
    cursor: "pointer",
    border: "1px solid black",
    borderRadius: "50%",
  },
  itemLabel: {
    display: "flex",
    gap: ".25rem",
    fontSize: fontSize.H1,
    fontFamily: fonts.FbReformaRegular,
    justifyContent: "space-between",
  },
  icon: {
    backgroundRepeat: "no-repeat",
    width: "1.5rem",
    height: "1.5rem",
  },
};

export default MenuItemMobile;
