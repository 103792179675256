import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { FormatDateYYYYMMDD } from "../../Services/DateService";
import moment from "moment";

export interface ScheduleState {
  fromStation: number | undefined;
  toStation: number | undefined;
  date: string;
  hours: string;
  minutes: string;
  scheduleType: number;
  trainNumber?: number | string;
}
export interface ScheduleStateBuild {
  id?: string;
  fromStation?: number | undefined;
  toStation?: number | undefined;
  date?: string;
  hours?: string;
  minutes?: string;
  scheduleType?: number;
}
export interface IScheduleSlice extends ScheduleState {
  routesPlans: ScheduleState[];
}
 
const initialState: IScheduleSlice = {
  fromStation: undefined,
  toStation: undefined,
  date: FormatDateYYYYMMDD(moment().toDate()),
  hours: "00",
  minutes: "00",
  scheduleType: 1,
  routesPlans: [],
};

export const scheduleSlice = createSlice({
  name: "schedule",
  initialState,
  reducers: {
    setOriginStation: (state, action: PayloadAction<number | undefined>) => {
      state.fromStation = action.payload;
    },
    setDestinationStation: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      state.toStation = action.payload;
    },
    setDate: (state, action: PayloadAction<string>) => {
      state.date = action.payload;
    },
    setHours: (state, action: PayloadAction<string>) => {
      state.hours = action.payload;
    },
    setMinutes: (state, action: PayloadAction<string>) => {
      state.minutes = action.payload;
    },
    setScheduleType: (state, action: PayloadAction<number>) => {
      state.scheduleType = action.payload;
    },
    setRoutesPlans: (state, action: PayloadAction<ScheduleState>) => {
      state.routesPlans?.push(action.payload);
      state.fromStation = undefined;
      state.toStation = undefined;
      state.date = FormatDateYYYYMMDD(moment().toDate());
      state.hours = "00";
      state.minutes = "00";
      state.scheduleType = 1;
    },
    setRoutesPlanRemove: (state, action: PayloadAction<number>) => {
      state.routesPlans?.splice(action.payload,1)
    },
  },
});

export const {
  setOriginStation,
  setDestinationStation,
  setDate,
  setHours,
  setMinutes,
  setScheduleType,
  setRoutesPlans,
  setRoutesPlanRemove
} = scheduleSlice.actions;

export default scheduleSlice.reducer;
