import colors from "../../../Assets/Colors/Colors.json";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import CustomInput from "../CustomFileds/CustomInput";
import { IFormContactDetails } from "../../../Interfaces/IForms";
import Title from "../../UmbracoElements/Title";

const FormContactDetails: React.FC<IFormContactDetails> = ({ formik }) => {
  return (
    <div style={styles.container}>
      <Title text="פרטי התקשרות" />

      <div style={styles.form}>
        <CustomInput
          label="טלפון נייד (אליו ישלח סמס עם מספר פנייתך)"
          name="FormContactDetails.phoneNumber"
          type={"number"}
          value={formik.values.FormContactDetails?.phoneNumber}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          errors={formik.errors.FormContactDetails?.phoneNumber}
          touched={formik.touched.FormContactDetails?.phoneNumber+''}
          placeholder="הזן טלפון נייד"
        />
        <CustomInput
          label="דואר אלקטרוני"
          name="FormContactDetails.email"
          type="email"
          value={formik.values.FormContactDetails?.email}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          errors={formik.errors.FormContactDetails?.email}
          touched={formik.touched.FormContactDetails?.email+''}
          placeholder="הזן דואר אלקטרוני"
        />
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    maxWidth: "60%",
    margin: "auto",
    paddingBottom: "2rem",
  },
  form: { display: "flex", flexWrap: "wrap", columnGap: "2rem" },
  button: {
    width: "100%",
    padding: "0.65rem 0.5rem",
    fontSize: "1rem",
    color: colors.white,
    border: "2px solid",
    backgroundColor: colors.blue,
    borderRadius: "10px",
    outline: "none",
    margin: "1rem 0",
    fontWeight: "bold",
    cursor: "pointer",
  },
};

export default FormContactDetails;
