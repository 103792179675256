import { ITabs } from "../../Interfaces_tenders/ITabs";
import colors from "../../../../Assets/Colors/Colors.json";
import { StyleSheet } from "../../Interfaces_tenders/IStyleSheet";
import fonts from "../../../../Assets/Fonts/Fonts.json";
import { useEffect, useRef } from "react";

const Tabs: React.FC<ITabs> = (props) => {
  const {
    tabs,
    onTabClick,
    containerStyle,
    tabStyle,
    lineStyle,
    isMobile,
    conFixStyle,
    step,
  } = props;
  const tabRef = useRef<HTMLInputElement[]>();

  useEffect(() => {
    isMobile &&
      tabs.length > 0 &&
      tabs &&
      step &&
      document
        .getElementById(
          "tab_" +
            tabs.filter((tab) => tab.id.toLowerCase() === step.toLowerCase())[0]
              .index
        )
        ?.scrollIntoView({ block: "end", inline: "center" });
  }, []);


  return (
    <div style={conFixStyle && conFixStyle}>
      <div
        style={containerStyle ? containerStyle : styles.container}
        className={containerStyle ? "auctionsMobileTabs" : ""}
      >
        {tabs.map((tab, index) => (
          <div
            id={"tab_" + index}
            tabIndex={0}
            style={tabStyle ? tabStyle : styles.tab}
            key={index}
            onClick={() => {
              onTabClick(tab);

              isMobile &&
                document
                  .getElementById("tab_" + index)
                  ?.scrollIntoView({ block: "end", inline: "center" });
            }}
          >
            <span
              style={{ ...styles.tabName, opacity: tab.isSelected ? 1 : 0.5 }}
            >
              {tab.name}
            </span>
            {tab.isSelected && (
              <div style={lineStyle ? lineStyle : styles.line}></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "4rem",
    boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 15px 0 rgba(0, 0, 0, 0.19)",
    gap: "1rem",
    paddingTop: ".5rem",
  },
  tab: {
    cursor: "pointer",
    position: "relative",
    minWidth: "17rem",
    textAlign: "center",
  },
  line: {
    position: "absolute",
    width: "100%",
    height: ".5rem",
    bottom: "-1.3rem",
    backgroundColor: colors.koromiko,
  },
  tabName: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    fontSize: "1.6rem",
    letterSpacing: "0.039375rem",
  },
};

export default Tabs;
