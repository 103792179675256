import { useEffect, useState } from "react";


export const getMobileOS = () => {
  const ua = navigator.userAgent;
  if (/android/i.test(ua)) {
    return "Android";
  } else if (
    /iPad|iPhone|iPod/.test(ua) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  ) {
    return "IOS";
  }
  return "Other";
};

export const GetSystemOS=()=>{
  const ua = navigator.userAgent;
  let OSName = `Windows`
  if (window.navigator.userAgent.indexOf("Mac")!= -1){
    if (/iPad|iPhone|iPod/.test(ua)) 
    {
      OSName = "IOS";
    }
    else{
      OSName="Mac";
    }
    
  }
  return OSName;
}

export default function useDeviceModeOSType() {
  const [_deviceModeOSType, _setDeviceModeOSType] = useState("Other");

  useEffect(() => {
    _setDeviceModeOSType(getMobileOS());
  }, [navigator.userAgent]);

  return _deviceModeOSType;
}
