import { t } from "i18next";
import moment from "moment";
import { Fragment, SetStateAction, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import store from "store2";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import Config from "../../Configuration/server";
import Images from "../../Data/Images/Images";
import {
  IRoute,
  IRouteDetails,
  IRouteInfo,
  IRouteStation,
} from "../../Interfaces/IRouteDetails";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { ITrain } from "../../Interfaces/ITrain";
import { ScheduleState } from "../../Redux/Slices/scheduleSlice";
import {
  FormatDateYYYYMMDD,
  FormatDateYYYYMMDDTHHMM,
  GetDateAsTextFormat,
  GetDuration,
  GetFormatedDurationMinutes,
} from "../../Services/DateService";
import { IsMobile } from "../../Services/DeviceService";
import { Translations } from "../../Services/GlobalService";
import i18n from "../../Services/i18n";
import { StyleMode } from "../../Utils/consts";
import { GetStationsDictionary } from "../../Utils/helpers";
import useDeviceMode from "../../Utils/useWindowDimensions";
import TransitTransportPage from "../ComplimentaryTransport/TransitTransportPage";
import CustomModal from "../CustomModal";
import PrintControl from "../Print/PrintControl";
import { linkBuilder, linkShareFormat } from "../SharePage/ShareOptions";
import SocialShare from "../SocialShare/SocialShare";
import RouteDetailsMap from "./RouteDetailsMap";
import RouteSlice from "./RouteSlice";

const RouteDetails: React.FC<IRouteDetails> = (props) => {
  const { travel, routeDetailsBodyStyles } = props;
  const [_routes, _setRoutes] = useState<IRoute[]>([]);
  const [_showPrint, _setShowPrint] = useState(false);
  const [_currentChangeIndex, _setCurrentChangeIndex] = useState(0);
  const changeRef = useRef<HTMLSpanElement>(null);
  const [_isShareModalVisible, _setIsShareModalVisible] = useState(false);
  const [_mapModalVisible, _setMapModalVisible] = useState(false);

  const deviceMode = useDeviceMode();
  const schedule = useSelector(
    (state: any) => state.ScheduleReducer
  ) as ScheduleState;
  const Translation = Translations[
    i18n.language as `he` | `en` | `ar` | `ru`
  ] as any;

  const getCurrentChangeItemIndex = (value: string) => {
    const changes = Array.from(document.getElementsByClassName("change"));
    const index = changes.findIndex(
      (item) => (item as HTMLElement).innerText === value
    );
    _setCurrentChangeIndex(index);
  };

  const [_currentTab, _setCurrentTab] = useState("route-details");
  const [_transitTime, _setTransitTime] = useState(
    travel && travel.arrivalTime
  );
  const [_currentRouteDetailsTab, _setCurrentRouteDetailsTab] =
    useState("route-details-time");

  const transitTimeHandler = (st: SetStateAction<string | undefined>) => {
    _setTransitTime(st);
  };

  useEffect(() => {
    const stations = store.session.get(
      `${Config.BASE_LOCALSTORAGE_NAME}stations`
    )[i18n.language];
    const dictStations = GetStationsDictionary(stations);
    const routes: IRoute[] = travel
      ? travel.trains.map((train: ITrain, i: number, arr: ITrain[]) => {
        const routeStations = [...train.routeStations];
        let beforeOriginStationSlice = routeStations.splice(
          0,
          routeStations.findIndex(
            (routeStation) => train.orignStation === routeStation.stationId
          )
        );
        let afterDestinationStationSlice = routeStations.splice(
          routeStations.findIndex(
            (routeStation) =>
              train.destinationStation === routeStation.stationId
          ) + 1
        );

        const toRouteDetails = (
          slice: IRouteStation[],
          isBeforeOrigin = true
        ) => {
          return slice.map(
            (routeStation: IRouteStation, i, arr): IRouteInfo => {
              return {
                arrivalTime:
                  FormatDateYYYYMMDD(new Date(train.departureTime)) +
                  "T" +
                  routeStation.arrivalTime +
                  ":00",
                departureTime: null,
                platform:
                  (isBeforeOrigin && i === 0) ||
                    (!isBeforeOrigin && !arr[i + 1])
                    ? routeStation.platform
                    : null,
                stationId: routeStation.stationId,
                stationName:
                  dictStations[routeStation.stationId]?.stationName,
                trainNumber: null,
                services: null,
                trainPosition: train.trainPosition,
                isIrrelevant: true,
                irrelevantOrigin: isBeforeOrigin && i === 0,
                irrelevantDestination: !isBeforeOrigin && !arr[i + 1],
              };
            }
          );
        };
        const beforeOriginStation = toRouteDetails(beforeOriginStationSlice);
        const afterDestinationStation = toRouteDetails(
          afterDestinationStationSlice,
          false
        );
        const originStation: IRouteInfo = {
          stationId: train.orignStation,
          stationName: dictStations[train.orignStation]?.stationName,
          departureTime: train.departureTime,
          arrivalTime: null,
          platform: train.originPlatform,
          trainNumber: train.trainNumber,
          services: {
            handicap: !!train.handicap,
            reservedSeats: false,
          },
          trainPosition: train.trainPosition,
          isOrigin: i === 0 ? true : false,
          isEmphesized: true,
        };
        let waiting = 0;
        if (arr[i + 1]) {
          waiting = GetDuration(
            new Date(arr[i].arrivalTime),
            new Date(arr[i + 1].departureTime)
          );
        }
        const stopStations: IRouteInfo[] = train.stopStations.map(
          (stopStation) => {
            return {
              stationId: stopStation.stationId,
              stationName: dictStations[stopStation.stationId]?.stationName,
              departureTime: stopStation.departureTime,
              arrivalTime: stopStation.arrivalTime,
              platform: null,
              trainNumber: null,
              services: null,
              trainPosition: null,
              isStop: true,
            };
          }
        );
        const destinationStation: IRouteInfo = {
          stationId: train.destinationStation,
          stationName: dictStations[train.destinationStation]?.stationName,
          departureTime: null,
          arrivalTime: train.arrivalTime,
          platform: train.destPlatform,
          trainNumber: null,
          services: null,
          trainPosition: null,
          waiting: waiting ? GetFormatedDurationMinutes(waiting) : null,
          isDestination: !arr[i + 1] ? true : false,
        };
        return {
          beforeOriginStation,
          stations: [originStation, ...stopStations, destinationStation],
          afterDestinationStation,
          trainNumber: train.trainNumber,
        };
      })
      : [];
    _setRoutes(routes);
    transitTimeHandler(travel && travel.arrivalTime);
  }, [travel]);

  const getShareTrainUrl = (url: string) => {
    let allStations = store.session.get(
      `${Config.BASE_LOCALSTORAGE_NAME}stations`
    );
    let Stations: any = GetStationsDictionary(
      allStations ? allStations[i18n.language] : []
    );
    let fromStation = schedule.fromStation
      ? Stations[schedule.fromStation].stationName
      : "";
    let toStation = schedule.toStation
      ? Stations[schedule.toStation].stationName
      : "";
    if (travel && travel.trains.length > 0) {
      const date = moment(travel.trains[0].departureTime).toDate();
      const dateFormat = FormatDateYYYYMMDD(date);

      const hourNumber = date.getHours();
      const minuteNumber = date.getMinutes();
      const hours = hourNumber <= 9 ? `0${hourNumber}` : hourNumber.toString();
      const minutes =
        minuteNumber <= 9 ? `0${minuteNumber}` : minuteNumber.toString();
      const description = `${Translation["travel by train"]} ${Translation["from station"]} ${fromStation} ${Translation["to station"]} ${toStation}%0A`;
      // const scheduleType = schedule.scheduleType;
      const andTime = `${t("date")}: ${dateFormat}%0A${t(
        "HourText"
      )}: ${hours}:${minutes}%0A`;

      const navigateUrl = `${window.location.href.split("/?")[0]}/mldp?FSID=${schedule.fromStation
        }&TSID=${schedule.toStation
        }&Date=${dateFormat}&Hour=${hours}${minutes}&Train=${travel.trains[0].trainNumber
        }`.replaceAll("&", "%26");

      linkShareFormat.title = description;
      linkShareFormat.subject = t("sharedWithYou") + " " + description;
      linkShareFormat.body = encodeURIComponent(
        description + andTime + "" + navigateUrl
      );
      linkShareFormat.summary = encodeURIComponent(description + "" + andTime);
      linkShareFormat.text = encodeURIComponent(
        description + andTime + "" + navigateUrl
      );
      linkShareFormat.quote = description;
      linkShareFormat.url = encodeURIComponent(navigateUrl);
      linkShareFormat.u = encodeURIComponent(navigateUrl);
      // sharePagetext
      let newLink = linkBuilder(url, linkShareFormat);

      // const navigateUrl = `?page=mldp&FSID=${schedule.fromStation}&TSID=${
      //   schedule.toStation
      // }&Date=${dateFormat}&Hour=${`${hours}${minutes}`}&Train=${
      //   travel.trains[0].trainNumber
      // }`;

      return decodeURI(newLink);
    }
    return "";
  };

  const routeDetailsBodyStylesSelect = routeDetailsBodyStyles
    ? routeDetailsBodyStyles
    : styles.routeDetailsBody;

  const onPrinting = (classContent: string) => {
    let body = document.body;
    let printMode: any = document.querySelector(".print-mode-show");
    let print: any = document.querySelector(".printing");
    let printContent = print.querySelector(".print-content");

    let routeDetails: any = document
      .querySelector(classContent)
      ?.cloneNode(true);
    printContent?.appendChild(routeDetails);
    printMode.appendChild(print);
    body.classList.add("mode-print");
    setTimeout(() => {
      window.print();
    }, 1);

    window.addEventListener("afterprint", (event) => {
      body.classList.remove("mode-print");
      printMode.innerHTML = "";
      printContent?.querySelector(classContent)?.remove();
      _setShowPrint(false);
    });
  };

  return (
    <>
      <PrintControl _routes={_routes} />
      <div style={styles.routeDetails} className="not-printing">
        <div
          style={styles.routeDetailsHeader}
        >
          {deviceMode !== StyleMode.desktop ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "1rem .5rem",
                gap: ".5rem",
              }}
            >
              <div style={styles.routeDetailsTitle}>
                <span
                  style={{
                    fontFamily: fonts.FbReformaMedium,
                    fontWeight: "500",
                  }}
                  className="route-details"
                >
                  {t("Route-details")}
                </span>
                <span>{`${GetDateAsTextFormat(new Date(schedule.date))}`}</span>
              </div>

              <span
                style={{
                  fontFamily: fonts.FbReformaRegular,
                  fontWeight: "500",
                }}
              >
                {t("Change", {
                  count: (travel && travel.trains.length - 1) || 0,
                })}
              </span>
            </div>
          ) : (
            <div
              tabIndex={0}
              id="route-details"
              style={styles.routeDetailsTabsContainer}
            >
              <h2
                onClick={() => {
                  _setCurrentTab("route-details");
                }}
                style={{
                  ...styles.routeDetailsTitle,
                  fontFamily: fonts.FbReformaMedium,

                  borderBottom:
                    _currentTab === "route-details"
                      ? "4px solid var(--koromiko)"
                      : "",
                }}
                tabIndex={!IsMobile() ? 0 : -1}
                onKeyDown={(e) => {
                  if (e.key === "Tab" && e.shiftKey) {
                    const cards = Array.from(
                      document.getElementsByClassName("train_card")
                    );
                    cards.forEach(
                      (card) => ((card as HTMLDivElement).tabIndex = -1)
                    );
                    const selectedTravelCard = document.querySelector(
                      "[data-active='true']"
                    );
                    const routeDetailsContainer = document.getElementById(
                      "routeDetailsContainer"
                    );

                    if (routeDetailsContainer && selectedTravelCard) {
                      const prevCardId =
                        parseInt(selectedTravelCard.id.split("_")[2]) - 1;
                      if (prevCardId !== undefined) {
                        const prevPurchaseButton = document.getElementById(
                          `purchase_button_${prevCardId}`
                        ) as HTMLDivElement;
                        if (prevPurchaseButton) {
                          let timeout = setTimeout(() => {
                            const prevCard = document.getElementById(
                              `train_card_${prevCardId}`
                            );
                            if (prevCard) {
                              (selectedTravelCard as HTMLDivElement).tabIndex =
                                -1;
                              prevCard.click();
                              const mainScroll =
                                document.getElementById("mainScroll")!;
                              mainScroll.scrollTo(0, 0);

                              let cardScrollTimeout = setTimeout(() => {
                                prevCard.scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                });
                                clearTimeout(cardScrollTimeout);
                              }, 0);
                              prevPurchaseButton.tabIndex = 0;
                              prevPurchaseButton.focus();
                              clearTimeout(timeout);
                            }
                          }, 0);
                        }
                      }
                    }
                  }
                }}
              >
                {t("Route-details")}
              </h2>

              <h2
                onClick={() => {
                  _setCurrentTab("transit-details");
                }}
                style={{
                  ...styles.routeDetailsTitle,
                  fontFamily: fonts.FbReformaMedium,
                  borderBottom:
                    _currentTab === "transit-details"
                      ? "4px solid var(--koromiko)"
                      : "",
                }}
              >
                {t("BusesFromTheDestinationStation")}
              </h2>
            </div>
          )}
          <div style={styles.routeDetailsOptions}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "2rem",
                flexWrap: "wrap",
                paddingLeft: i18n.dir() === "rtl" ? "1rem" : "",
                paddingRight: i18n.dir() === "ltr" ? "1rem" : "",
              }}
            >
              {deviceMode === StyleMode.desktop && (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".5rem",
                    }}
                  >
                    <img
                      src={Images.printerNew}
                      width={`25%`}
                      height={`25%`}
                      alt={``}
                      style={{
                        width: "1rem",
                        height: "1rem",
                      }}
                    />
                    <h1
                      style={{
                        color: colors.grayDark,
                        fontFamily: fonts.FbReformaRegular,
                        fontSize: fontSize.Text,
                      }}
                      tabIndex={!IsMobile() ? 0 : -1}
                      onClick={() => {
                        travel
                          ? onPrinting(".route-details")
                          : onPrinting(".list-trains");
                      }}
                      className="click"
                      role={`button`}
                      aria-label={t("Printing")}
                    >
                      {t("Printing")}
                    </h1>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".5rem",
                    }}
                  >
                    <img
                      src={Images.shareSmallGray}
                      width={`25%`}
                      height={`25%`}
                      alt={``}
                      style={{
                        filter: "brightness(.2)",
                      }}
                    />
                    <h1
                      style={{
                        color: colors.grayDark,
                        fontFamily: fonts.FbReformaRegular,
                        fontSize: fontSize.Text,
                      }}
                      tabIndex={!IsMobile() ? 0 : -1}
                      onClick={() => {
                        _setIsShareModalVisible(true);
                      }}
                      className="click"
                      role={`button`}
                      aria-label={t("Share")}
                    >
                      {t("Share")}
                    </h1>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {_currentTab === "route-details" && (
          <div style={{display:"flex", flexDirection:"column",overflow:"hidden"}}>
            <div style={styles.routeDetailsMapContainer}>
              <h2
                onClick={() => {
                  _setCurrentRouteDetailsTab("route-details-time");
                }}
                style={{
                  ...styles.routeDetailsTitle,
                  fontFamily: fonts.FbReformaMedium,

                  borderBottom:
                    _currentRouteDetailsTab === "route-details-time"
                      ? "4px solid var(--koromiko)"
                      : "",
                }}
              >
                תצוגת ציר זמן
              </h2>
              <h2
                onClick={() => {
                  _setCurrentRouteDetailsTab("route-details-map");
                }}
                style={{
                  ...styles.routeDetailsTitle,
                  fontFamily: fonts.FbReformaMedium,

                  borderBottom:
                    _currentRouteDetailsTab === "route-details-map"
                      ? "4px solid var(--koromiko)"
                      : "",
                }}
              >
                תצוגת מפה
              </h2>
            </div>
            {_currentRouteDetailsTab === "route-details-time" && (

              <section
                className="route-details"
                style={{
                  ...routeDetailsBodyStylesSelect,
                  overflowX: "hidden",
                }}
              >
                {_routes.map((route: IRoute, i: number) => {
                  return (
                    <Fragment key={i}>
                      <div
                        style={{
                          ...styles.route,
                          minWidth: "100%",
                          display: "flex",
                          flexDirection: "column",
                          paddingRight:
                            deviceMode === StyleMode.desktop
                              ? i18n.dir() === "rtl"
                                ? "25%"
                                : ""
                              : "",
                          paddingLeft:
                            deviceMode === StyleMode.desktop
                              ? i18n.dir() === "ltr"
                                ? "25%"
                                : ""
                              : "",
                        }}
                        className="route"
                        key={i}
                      >
                        <RouteSlice
                          stations={route.beforeOriginStation}
                          hasArrows={true}
                          // onDisplayModal={routeSliceHandler}
                          travel={travel}
                          index={i}
                          routeSliceType="beforeOriginStation"
                        />
                        <RouteSlice
                          stations={route.stations}
                          hasArrows={false}
                          // onDisplayModal={routeSliceHandler}
                          travel={travel}
                          index={i}
                          routeSliceType="stations"
                          lastStationId={
                            route.afterDestinationStation.length > 0
                              ? route.afterDestinationStation[
                                route.afterDestinationStation.length - 1
                              ].stationId
                              : route.stations[route.stations.length - 1]
                                .stationId
                          }
                        />
                        <RouteSlice
                          stations={route.afterDestinationStation}
                          hasArrows={true}
                          isUpDirection={false}
                          sliceId={"lastSlice"}
                          // onDisplayModal={routeSliceHandler}
                          travel={travel}
                          index={i}
                          routeSliceType="afterDestinationStation"
                        />
                      </div>
                      {route.stations.map((station: IRouteInfo) => {
                        return (
                          !!station.waiting && (
                            <div key={i} style={{ display: "flex" }}>
                              <div
                                style={{
                                  ...styles.time,
                                  marginRight:
                                    i18n.dir() === "rtl" ? "-2rem" : "",
                                  marginLeft:
                                    i18n.dir() === "ltr" ? "-2rem" : "",
                                }}
                                className="route-time"
                              ></div>
                              <div style={styles.change} className="change">
                                <img
                                  src={Images.change}
                                  width={`auto`}
                                  height={`auto`}
                                  alt={``}
                                  className="img-bullet"
                                  style={{
                                    transform: `translateX(${i18n.dir() === "rtl" ? "50%" : "-50%"
                                      })`,
                                  }}
                                />
                                <span
                                  ref={changeRef}
                                  tabIndex={!IsMobile() ? 0 : -1}
                                  role={"link"}
                                  aria-label={`${t("Route-waiting", {
                                    count: parseInt(
                                      (station.waiting || 0) as string
                                    ),
                                  })} ${t("ChangeTrain", {
                                    fromStation: _routes[
                                      _currentChangeIndex + 1
                                    ]
                                      ? _routes[_currentChangeIndex + 1]
                                        .stations[0].stationName
                                      : "",
                                    originPlatform: _routes[
                                      _currentChangeIndex + 1
                                    ]
                                      ? _routes[_currentChangeIndex + 1]
                                        .stations[0].platform
                                      : "",
                                    toStation:
                                      _routes[_currentChangeIndex + 1] &&
                                        _routes[_currentChangeIndex + 1]
                                          .stations[
                                        _routes[_currentChangeIndex + 1]
                                          .stations.length - 1
                                        ]
                                        ? _routes[_currentChangeIndex + 1]
                                          .stations[
                                          _routes[_currentChangeIndex + 1]
                                            .stations.length - 1
                                        ].stationName
                                        : "",
                                    destPlatform:
                                      _routes[_currentChangeIndex + 1] &&
                                        _routes[_currentChangeIndex + 1]
                                          .stations[
                                        _routes[_currentChangeIndex + 1]
                                          .stations.length - 1
                                        ]
                                        ? _routes[_currentChangeIndex + 1]
                                          .stations[
                                          _routes[_currentChangeIndex + 1]
                                            .stations.length - 1
                                        ].platform
                                        : "",
                                  })}`}
                                  onKeyDown={() => {
                                    if (changeRef.current) {
                                      getCurrentChangeItemIndex(
                                        changeRef.current.innerText
                                      );
                                    }
                                  }}
                                >
                                  {t("Route-waiting", {
                                    count: parseInt(
                                      (station.waiting || 0) as string
                                    ),
                                  })}
                                </span>
                              </div>
                            </div>
                          )
                        );
                      })}
                    </Fragment>
                  );
                })}
              </section>
            )}
            {_currentRouteDetailsTab === "route-details-map" && (
              <div style={styles.routeDetailsMap}>
                <section>
                  <RouteDetailsMap
                    trains={travel?.trains}
                    onClickZoom={() => _setMapModalVisible(true)}
                  />
                </section>
              </div>
            )}
          </div>
        )}

        {_currentTab === "transit-details" && travel && (
          <section className="transit-details">
            <TransitTransportPage
              stationId={schedule.toStation}
              typeOfPage="transitInfo"
              startTime={_transitTime}
              onClick={() =>
                transitTimeHandler(FormatDateYYYYMMDDTHHMM(new Date()))
              }
            // key={travel.arrivalTime}
            />
          </section>
        )}
      </div>

      <CustomModal
        visible={_isShareModalVisible}
        onClose={() => _setIsShareModalVisible(false)}
        typeStyle={"rectangle"}
        contentStyle={{paddingTop: "1rem"}}
        innerStyle={{ width: "100%", minHeight: "16rem", padding: 0, lineHeight: "1rem",
        maxWidth: "25rem",
          overflow:"visible"}}
        icon={Images.share}
        title={t("Share")}
      >
        <SocialShare
          items={[
            {
              name: t("Facebook"),
              icon: Images.facebookHeader,
              link: getShareTrainUrl(
                "https://www.facebook.com/sharer/sharer.php?u=&quote="
              ),
              ariaLabel: t("ShareFacebook"),
            },
            {
              name: t("WhatsApp"),
              icon: Images.whatsapp,
              link: getShareTrainUrl("https://wa.me/?text="),
              ariaLabel: t("ShareWhatsapp"),
            },
            {
              name: t("Mail"),
              icon: Images.shareMail,
              link: getShareTrainUrl("mailto:?subject=&body="),

              ariaLabel: t("ShareEmail"),
            },
            {
              name: t("Linkedin"),
              icon: Images.linkedinHeader,
              link: getShareTrainUrl(
                "https://www.linkedin.com/sharing/share-offsite/?url=&title=&summary="
              ),
              ariaLabel: t("ShareLinkedin"),
            },
          ]}
        />
        <button
          className="btn-modal"
          onClick={() => _setIsShareModalVisible(false)}
          style={{ textAlign: "center", backgroundColor: "#F0F0F0" }}
        >
          {t("Cancel")}
        </button>
      </CustomModal>

      <CustomModal
        visible={_mapModalVisible}
        onClose={() => _setMapModalVisible(false)}
        typeStyle={"rectangle"}
        innerStyle={{ maxHeight: "90%", maxWidth: "unset" }}
      >
        <RouteDetailsMap
          trains={travel?.trains}
          id={"zoom"}
          onClickZoom={() => _setMapModalVisible(false)}
          zoom={"100%"}
          width={"80rem"}
          close={true}
        />
      </CustomModal>
    </>
  );
};

const styles: StyleSheet = {
  routeDetails: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  routeDetailsHeader: {
    display: "flex",
    justifyContent: "space-between",
    marginInlineStart: "1rem",
    marginInlineEnd: "1rem",
    // lineHeight: "4rem",
    borderBottom: `.1rem solid ${colors.pastelGray}`,
    flexWrap: "wrap",
    minHeight: "5rem",
    // padding: "0 3rem",
  },
  routeDetailsTitle: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    //borderBottom:"4px solid var(--koromiko)",
    gap: "1rem",
    fontSize: fontSize.Text,
    cursor: "pointer",
  },
  routeDetailsOptions: {
    display: "contents",
    flexWrap: "wrap",
    gap: "1rem",
  },
  routeDetailsBody: {
    margin: "1rem",
    maxHeight: "80vh",
    // paddingInlineStart: "25%",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    alignItems: `center`,
  },
  change: {
    paddingBottom: "10px",
    color: colors.darkBlue,
    display: "flex",
    alignItems: "center",
  },
  time: {
    height: "20px",
    padding: "0 10px",
    boxSizing: "border-box",
    fontWeight: "bold",
    minWidth: "100px",
    fontFamily: fonts.FbReformaRegular,
  },
  routeDetailsTabsContainer: {
    display: "flex",
    alignItems: "center",
    gap: "3rem",
  },
  routeDetailsMapContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    marginInlineStart: "1rem",
    marginInlineEnd: "1rem",
    // lineHeight: "4rem",
    borderBottom: `.1rem solid ${colors.pastelGray}`,
    flexWrap: "wrap",
    minHeight: "5rem",
    // padding: "0 3rem",
  },

  routeDetailsMap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // background:"green"
  },
};

export default RouteDetails;
