import { ITag } from "../../Interfaces_tenders/ITag";
import colors from "../../../../Assets/Colors/Colors.json";
import { StyleSheet } from "../../Interfaces_tenders/IStyleSheet";
import fonts from "../../../../Assets/Fonts/Fonts.json"
const Tag: React.FC<ITag> = (props) => {
  const { text, customStyles } = props;

  return (
    <div
      style={
        customStyles
          ? { ...styles.container, backgroundColor: "white" }
          : { ...styles.container }
      }
    >
      <div style={styles.text}>{text}</div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.pattensBlue,
    borderRadius: "1rem",
    //width: "5rem",
    height: "1.4rem",
    paddingRight: "1rem",
    paddingLeft: "1rem",
  },
  text: {
    fontFamily: fonts.FbReformaRegular,
    fontSize: "1rem",
    letterSpacing: "0.029375rem",
    color: colors.dimGray,
  },
};

export default Tag;
