import { IMagazineCategory } from "../../../../Interfaces/IMagazinePage";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import font from "../../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../../Assets/Fonts/FontsSizes.json";
import colors from "../../../../Assets/Colors/Colors.json";
import { useNavigate } from "react-router-dom";

const MagazineCategory: React.FC<IMagazineCategory> = (props) => {
  const { categoryName, redirectTo, anchor } = props;
  const navigate = useNavigate();
  return (
    <span
      style={styles.container}
      role={"link"}
      tabIndex={0}
      onClick={() => {
        navigate(`?page=${redirectTo}`);
      }}
      anchor-id={anchor}
    >
      {categoryName}
    </span>
  );
};

const styles: StyleSheet = {
  container: {
    fontSize: fontSize.SubTitle,
    color: colors.cyanBold,
    fontFamily: font.FbReformaRegular,
    textDecoration: "underline",
    cursor: "pointer",
  },
};

export default MagazineCategory;
