import { useState } from "react";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import Images from "../../../Data/Images/Images";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { IsMobile } from "../../../Services/DeviceService";
import i18n from "../../../Services/i18n";
import { RemoveHtmlTags } from "../../../Utils/helpers";
import { replaceRichtextBoldWithFont } from "../../../Utils/utils";

interface IQuestionAndAnswerNew {
  active: boolean;
  firstItem?: boolean;
  lastItem?: boolean;
  
  answer: string;
  question: string;
  onClick?: () => void;
}
const QuestionAndAnswerNew: React.FC<IQuestionAndAnswerNew> = (props) => {
  const { question, answer, active, onClick,firstItem ,lastItem} = props;
  const [_isAnswerVisible, _setIsAnswerVisible] = useState(false);

  return (
    <div
    style={{
      borderTop:  firstItem ? `1px solid ${colors.gray}` : "",
      borderBottom:  `1px solid ${colors.gray}`,
        
      paddingTop: ".5rem",
      paddingBottom: ".5rem",
    }}
  >
      <div style={styles.questionAndAnswerWrapper}>
        <img
        src={!_isAnswerVisible ? Images.plus : Images.pinus}
          role={"button"}
          tabIndex={0}
          aria-label={`${question}`}
          aria-expanded={_isAnswerVisible}
          onClick={() => {
            if (answer !== "") {
              _setIsAnswerVisible(!_isAnswerVisible);
            }
          }}
          onKeyPress={() => {
            if (answer !== "") {
              _setIsAnswerVisible(!_isAnswerVisible);
            }
          }}
          style={{
            ...styles.icon,
            height: i18n.language === "ru" ? "2rem" : "1.8rem",
            
          }}
        />
        <div
          style={{ ...styles.question, cursor: answer ? "pointer" : "default" }}
          onClick={() => {
            if (answer !== "") {
              _setIsAnswerVisible(!_isAnswerVisible);
            }
          }}
          onKeyPress={() => {
            if (answer !== "") {
              _setIsAnswerVisible(!_isAnswerVisible);
            }
          }}
        >
          {question}
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ flex: "1.6" }}></div>
        <div style={{ flex: "17" }}>
          {_isAnswerVisible && (
            <div
              role={"tabpanel"}
              tabIndex={!IsMobile() ? 0 : -1}
              dangerouslySetInnerHTML={{
                __html: replaceRichtextBoldWithFont(answer),
              }}
              aria-label={`${RemoveHtmlTags(answer)}`}
              style={styles.answer}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  questionAndAnswerWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  },
  icon: {

    flex: "1",
    width: "1.8rem",
    cursor: "pointer",
  },
  question: {
    color: colors.nero,
    fontSize: fontSize.Paragraph,
    lineHeight: "1.75rem",
    fontFamily: fonts.FbReformaRegular,
    flex: "14",
    padding: ".5rem 0",
  },
  answer: {
    flex: "17",
    fontFamily: fonts.FbReformaLight,
    color: colors.nero,
    fontSize: fontSize.FooterItem,
    lineHeight: "1.3rem",
  },
};

export default QuestionAndAnswerNew;
