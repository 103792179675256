import { t } from "i18next";
import { useState } from "react";
import { useSelector } from "react-redux";
import Swiper, { EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import { Swiper as ReactSwiper, SwiperSlide } from "swiper/react";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import Images from "../../Data/Images/Images";
import { IPopMessages } from "../../Interfaces/IPopMessages";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import i18n from "../../Services/i18n";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";
import CustomModal from "../CustomModal";
import PopMessageCard from "./PopMessageCard";

const PopMessageCards: React.FC<IPopMessages> = (props) => {
  const { messages, onAcceptReading } = props;
  const [_swiper, _setSwiper] = useState<Swiper>();
  const [_currentSlideIndex, _setCurrentSlideIndex] = useState(0);
  const deviceMode = useDeviceMode();
  const HomeReducer = useSelector((state: any) => state.HomeReducer);
  let swiper: Swiper;

  const changeIndex = (index: number) => {
    swiper = new Swiper("#popupMessageSwiper", {});
    if (swiper) {
      swiper.slideTo(index, 0);
      _setCurrentSlideIndex(index);
    }
  };

  return <CustomModal visible={true} icon={Images.megaphone} innerStyle={{ maxWidth: "36rem", width: "100%" }} contentStyle={{ padding: 0 }}>
    <div style={styles.messages}>
      <ReactSwiper
        id="popupMessageSwiper"
        speed={0}
        slidesPerView={1}
        slideToClickedSlide={false}
        noSwiping={true}
        noSwipingClass="swiper-no-swiping"
        allowTouchMove={false}
        onSlideChange={(swiper) => {
          _setCurrentSlideIndex(swiper.activeIndex);
        }}
        style={{
          height: deviceMode !== StyleMode.desktop ? "88%" : "auto",
        }}
        modules={[EffectFade]}
      >
        {messages.map((message, index) => (
          <SwiperSlide
            key={index}
            className="swiper-no-swiping"
          >
            <PopMessageCard
              {...message}
              supportRichText={
                HomeReducer.systemParamsKey["IsRtfActive"]
                  ? HomeReducer.systemParamsKey["IsRtfActive"].value ===
                  "true"
                  : false
              }
            />
          </SwiperSlide>
        ))}
      </ReactSwiper>
    </div>
    <button
      tabIndex={0}
      style={styles.finishButton}
      role={"button"}
      aria-label={t(`Understood`)}
      onClick={() => {
        if (_currentSlideIndex < messages.length - 1) {
          changeIndex(_currentSlideIndex + 1);
        } else {
          onAcceptReading && onAcceptReading();
        }
      }}
    >
      {t(`Understood`)}
    </button>
  </CustomModal>

};

const styles: StyleSheet = {
  finishButton: {
    borderRadius: "3.75rem",
    backgroundColor: colors.darkBlue,
    border: "0.06rem solid",
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.UmbracoElementTitle,
    fontWeight: `${i18n.language === "ar" ? `600` : ``}`,
    textAlign: "center",
    minWidth: "8rem",
    minHeight: "3rem",
    color: colors.white,
    cursor: "pointer",
    flex: 1,
  },

  messages: {
    width: "100%",
    height: "100%",
  },

};

export default PopMessageCards;
