import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import { ITitleAndDescription } from "../../Interfaces/IBaseElement";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { RemoveHtmlTags } from "../../Utils/helpers";
import { replaceRichtextBoldWithFont } from "../../Utils/utils";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../Services/DeviceService";

const TitleAndDescriptionRichTextTitle: React.FC<ITitleAndDescription> = (
  props
) => {
  const { title, description, anchor } = props;

  return (
    <div className="side-title" anchor-id={anchor}>
      <div
        className="title-rich"
        style={{
          ...styles.accurecyIndexTitle,
        }}
        dangerouslySetInnerHTML={{ __html: replaceRichtextBoldWithFont(title) }}
        tabIndex={!IsMobile() ? 0 : -1}
        aria-label={RemoveHtmlTags(title)}
      ></div>
      <div
        style={styles.accurecyIndexDescription}
        dangerouslySetInnerHTML={{
          __html: replaceRichtextBoldWithFont(description),
        }}
        tabIndex={!IsMobile() ? 0 : -1}
        aria-label={RemoveHtmlTags(description)}
      ></div>
    </div>
  );
};

const styles: StyleSheet = {
  line: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "3rem",
  },
  accurecyIndexTitle: {
    fontFamily: fonts.FbReformaMedium,
    letterSpacing: "0.08375",
    color: colors.darkBlue,
    fontSize: fontSize.PageTitle,
  },
  accurecyIndexDescription: {
    fontFamily: fonts.FbReformaLight,
    letterSpacing: "0.038125rem",
    color: colors.nero,
    fontSize: fontSize.Paragraph,
    // maxWidth: "19rem",
    paddingBottom: "1rem",
  },
};

export default TitleAndDescriptionRichTextTitle;
