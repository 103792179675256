import { useState } from "react";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import { ICustomerServiceItem } from "../../../Interfaces/ICustomerServices";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import { GetLongTextWithDots } from "../../../Utils/utils";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../Services/DeviceService";

const CustomerServiceItem: React.FC<ICustomerServiceItem> = (props) => {
  const {
    icon,
    mode,
    serviceName,
    openType,
    onSelect,
    iconAlt,
    serviceType,
    anchor,
  } = props;
  const [_isOver, _setIsOver] = useState(false);

  const deviceMode = useDeviceMode();
  return (
    <div
      anchor-id={anchor}
      style={{
        ...styles.container,
        animationName: _isOver ? "shadowOver" : "",
      }}
      onMouseMove={() => _setIsOver(true)}
      onMouseLeave={() => _setIsOver(false)}
      onFocusCapture={() => _setIsOver(true)}
      onBlurCapture={() => _setIsOver(false)}
      onClick={() => {
        if (mode.includes("Only Mobile")) {
          if (deviceMode === StyleMode.mobile) {
            onSelect(props);
          }
        } else {
          onSelect(props);
        }
      }}
      role={`button`}
      tabIndex={!IsMobile() ? 0 : -1}
      aria-label={`${serviceName}`}
    >
      <img
        src={icon._url}
        style={{
          width:
            icon._url.length > 0 && !icon._url.includes(".svg") ? "50%" : " ",
        }}
        width={30}
        height={30}
        alt={iconAlt ? iconAlt : ``}
      />
      <div
        style={styles.serviceName}
        title={serviceName.length > 25 ? serviceName : ""}
      >
        {serviceName.length > 25
          ? GetLongTextWithDots(serviceName, 25)
          : serviceName}
      </div>
      <div style={styles.underline}></div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    boxShadow: `0 2px 6px 0 rgba(0, 0, 0, 0.11)`,
    height: "11rem",
    maxWidth: "50%",
    minWidth: "8rem",
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "3px",
    animationFillMode: "forwards",
    animationDuration: "1s",
    flexDirection: "column",
    cursor: "pointer",
    gap: "1rem",
  },

  serviceName: {
    fontFamily: fonts.FbReformaRegular,
    letterSpacing: "0.043125rem",
    color: colors.darkBlue,
    fontSize: fontSize.Paragraph,
    textAlign: "center",
    maxWidth: "10rem",
  },
  underline: {
    width: "14px",
    height: "1px",
    backgroundColor: colors.koromiko,
  },
};

export default CustomerServiceItem;
