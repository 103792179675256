import { configureStore } from "@reduxjs/toolkit";
import cookiesSettingsSlice from "../Slices/cookiesSettingsSlice";
import footerReducer from "../Slices/footerSlice";
import formReducer from "../Slices/formsSlice";
import headerReducer from "../Slices/headerSlice";
import homeReducer from "../Slices/homeSlice";
import pageReducer from "../Slices/pageSlice";
import scheduleReducer from "../Slices/scheduleSlice";
import somethingWentWrongReducer from "../Slices/somethingWentWrongSlice";
import systemParamsReducer from "../Slices/systemParamsSlice";
import tendersReducer from "../Slices/tendersSlice";
import translationsReducer from "../Slices/translationsSlice";
import umbracoStoreReducer from "../Slices/umbracoSlice";
import urlTranslationsReducer from "../Slices/urlTranslationsSlice";


export default configureStore({
  reducer: {
    PageReducer: pageReducer,
    ScheduleReducer: scheduleReducer,
    HeaderReducer: headerReducer,
    FooterReducer: footerReducer,
    UmbracoStoreReducer: umbracoStoreReducer,
    SomethingWentWrongReducer: somethingWentWrongReducer,
    HomeReducer: homeReducer,
    CookiesSettingsSlice: cookiesSettingsSlice,
    TendersReducer: tendersReducer,
    FormReducer: formReducer,
    UrlTranslationsReducer: urlTranslationsReducer,
    SystemParamsReducer: systemParamsReducer,
    TranslationsReducer: translationsReducer,
  },
});
