import colors from "../../Assets/Colors/Colors.json";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import Title from "../UmbracoElements/Title";

export interface IInfoCareer {
  title: string;
  description: string;
  img: string,
  maxWidth?: string
}

const TextAndBGModal: React.FC<IInfoCareer> = (props) => {
  const { title, description, img, maxWidth } = props;

  return (
    <div style={{
      ...styles.modalContainer,
      backgroundImage: `url(${img})`,
      backgroundSize: "cover",
      maxWidth: maxWidth

    }}>
      <div style={{
        padding: "2rem 3rem",
        backgroundColor: colors.whiteTransparent,
        minHeight: "50vh"
      }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",

          }}
        // tabIndex={!IsMobile() ? 0 : -1}
        >
          <Title text={title} />
          <p style={{ fontSize: "1.1rem" }}>{description}</p>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  modalContainer: {
    maxHeight: "100%",
    overflow: "auto",
  },

};

export default TextAndBGModal;
