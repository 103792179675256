import { IPagination, IPaginationDots } from "../../Interfaces_tenders/IPagination";
import colors from "../../../../Assets/Colors/Colors.json";
import { StyleSheet } from "../../Interfaces_tenders/IStyleSheet";
import { t } from "i18next";
import { Fragment } from "react";
import useDeviceMode from "../../../../Utils/useWindowDimensions";

const PaginationDots = (props: IPaginationDots) => {
  const { active, step, onClickHandler, itemsPerPage, totalItems } = props;
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  const size = pageNumbers.length;
  const _ = require("lodash");
  const showingNumbers = step * 2 + 1;
  let startNumber = 2;
  let startArrayNumber = props.step;

  let needStartDots = false;
  let needEndDots = false;

  const deviceMode = useDeviceMode();


  if (active > step) {
    startArrayNumber = active - step;

    needStartDots = active > step + startNumber ? true : false;
  }

  if (size > showingNumbers) {
    needEndDots = size > active + step + 1 ? true : false;
    if (size < active + step + 1) {
      startArrayNumber = size - showingNumbers;
    }
  }

  let contentNumber;
  return (
    <ul className="pagination" style={{...styles.pagination , gap:deviceMode==='MOBILE'?"1.3rem":"2rem"}}>
      {active > 1 ? (
        <li
          style={styles.pageNumber}
          className="page-item prev arrow-icon"
          onClick={() => onClickHandler(active - 1)}
        >
          &#x2039;
        </li>
      ) : (
        <li
          className="page-item prev arrow-icon disabled"
          style={styles.pageNumberDisable}
        >
          &#x2039;
        </li>
      )}
      {size > showingNumbers + startNumber ? (
        <Fragment>
          <li
            style={styles.pageNumber}
            onClick={(e) => onClickHandler(e.currentTarget.textContent)}
            className={`page-item ${active === 1 && "active"}`}
          >
            1
          </li>

          {needStartDots && <span>...</span>}

          {_.times(showingNumbers, (i: any) => (
            <li
              style={styles.pageNumber}
              key={i++}
              {...((contentNumber = needStartDots
                ? startArrayNumber
                : startNumber) as unknown as Object)}
              {...(startNumber++ as unknown as Object)}
              {...(startArrayNumber++ as unknown as Object)}
              className={`page-item ${active === contentNumber && "active"}`}
              onClick={(e) => onClickHandler(e.currentTarget.textContent)}
            >
              {contentNumber}
            </li>
          ))}

          {needEndDots && <span>...</span>}
          <li
          
            style={styles.pageNumber}
            className={`page-item ${active === size && "active"}`}
            onClick={(e) => onClickHandler(e.currentTarget.textContent)}
          >
            {size}
          </li>
        </Fragment>
      ) : (
        ((startArrayNumber = 1),
        _.times(size, (i: any) => (
          <li
          style={styles.pageNumber}
            key={i++}
            className={`page-item ${active === startArrayNumber && "active"}`}
            onClick={(e) => onClickHandler(e.currentTarget.textContent)}
          >
            {startArrayNumber++}
          </li>
        )))
      )}
      {active < size ? (
        <li
          style={styles.pageNumber}
          className="page-item next arrow-icon"
          onClick={() => onClickHandler(active + 1)}
        >
          &#8250;
        </li>
      ) : (
        <li
          className="page-item next arrow-icon disabled"
          style={styles.pageNumberDisable}
        >
          &#8250;
        </li>
      )}
    </ul>
  );
};

const styles: StyleSheet = {
  pagination: {
    marginTop: "40px",
    padding: "0px",
    listStyle: "none",
    userSelect: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    direction:"ltr"

   // gap: "2rem",
  },
  pageNumber: {
    fontSize: "2rem",
    cursor: "pointer",
    color: "rgb(0, 69, 138)",
   // paddingInlineStart:"unset"
  },
  pageNumberDisable: {
    fontSize: "2rem",
   // cursor: "pointer",
    color: "grey",
  },
};

export default PaginationDots;
