import { useState } from "react";
import { IArticle, IArticleSlices } from "../../../Interfaces/IMagazinePage";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import ArticleTitleAndText from "./Components/ArticleTitleAndText";
import LayoutMagazine from "./Components/LayoutMagazine";
import LinkTo from "./Components/LinkTo";

const ArticleSlices: React.FC<IArticleSlices> = (props) => {
  const { articles, title, link, anchor } = props;
  const deviceMode = useDeviceMode();
  const [_articleOnModal, _setArticleOnModal] = useState<IArticle>();

  const articlesSorted = articles.sort((a, b) => {
    if (a.creationDate > b.creationDate) {
      return -1
    } else if (a.creationDate < b.creationDate) {
      return 1
    }
    return 0
  });

  return (<LayoutMagazine title={title} anchor={anchor} modalData={_articleOnModal}
    onCloseModal={() => _setArticleOnModal(undefined)}>
    <div style={{ padding: deviceMode !== StyleMode.desktop ? "2rem" : "0rem" }}>
      <div style={{ ...styles.articleSlicesContainer, gap: "1rem" }}>
        {articlesSorted.map((article, index) => (
          <ArticleTitleAndText key={index} {...article}
            title={article.titleAndDescription.title} onClick={() => {
              _setArticleOnModal(article);
            }} />

        ))}
      </div>
      <LinkTo redirectTo={link.redirectTo} text={link.text} />
    </div>
  </LayoutMagazine>)
};

const styles: StyleSheet = {
  articleSlicesContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  }
};

export default ArticleSlices;