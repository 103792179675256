import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import { IProfileDataLongData } from "./Profiles";
export interface IFarePrice {
  title: string;
  price?: number;
  disclaimer?: boolean
  
}
export interface ICardPrice extends IFarePrice {
  description?: string;
  discount?: number;
  profile?: IProfileDataLongData;
}
const CardPrice: React.FC<ICardPrice> = (props) => {
  const { title, profile, description, discount, price } = props;

  return (
    <div
      style={{
        backgroundColor: colors.darkBlue,
        flex: 1,
        borderRadius: "1rem",
        overflow: "hidden",
        minWidth: "18rem",
      }}
    >
      <div
        className="header-name"
        style={{
          padding: "2rem",
          backgroundColor: colors.koromiko,
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ fontFamily: fonts.FbReformaRegular, fontSize: "1.2rem" }}>
          {title}
        </div>
        <div style={{ fontFamily: fonts.FbReformaMedium, fontSize: "1.5rem" }}>
          {price && price > 0
            ? price?.toLocaleString("he", {
                style: "currency",
                currency: "ILS",
              })
            : "חינם"}
        </div>
      </div>
      {(discount || description) && (
        <div
          style={{
            padding: "1rem",
            color: "white",
            textAlign: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            maxHeight: "85px",
            alignItems: "center",
          }}
        >
          {discount ? (
            <p>{`לאחר ${discount}% הנחת ${profile?.profileName}`}</p>
          ) : (
            ""
          )}
          {description && <p>{description}</p>}
        </div>
      )}
    </div>
  );
};
const LinePrice: React.FC<IFarePrice> = (props) => {
  const { title, price ,disclaimer  } = props;

  return (
    <>
      <div
         className="row"
        style={{
          textAlign: "center",
          justifyContent: "space-between",
          paddingBlock: "0.75rem",
          width:"100%"

        }}
      >
        <div style={{ fontFamily: fonts.FbReformaRegular, fontSize: "1.1rem" }}>
          {title}{disclaimer ? "*" : ""}
        </div>
        <div style={{ fontFamily: fonts.FbReformaMedium, fontSize: "1.1rem" }}>
          { price?.toLocaleString("he", {
                style: "currency",
              currency: "ILS",
            minimumFractionDigits: 0,
              
              })
            }
        </div>
      </div>
      {/* {(discount || description) && (
        <div
          style={{
            padding: "1rem",
            color: "white",
            textAlign: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            maxHeight: "85px",
            alignItems: "center",
          }}
        >
          {discount ? <p>{`לאחר ${discount}% הנחת ${profile?.profileName}`}</p> : ""}
          {description && <p>{description}</p>}
        </div>
      )} */}
    </>
  );
};

export { LinePrice };
export default CardPrice;
