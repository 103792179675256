import React, { ChangeEvent, useEffect } from "react";
import colors from "../../../Assets/Colors/Colors.json";
import { ICurrentService } from "../../../Interfaces/IForms";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import CustomAttachedFiles from "../CustomFileds/CustomAttachedFiles";
import CustomDatePicker from "../CustomFileds/CustomDatePicker";
import CustomInput from "../CustomFileds/CustomInput";
import CustomSelect from "../CustomFileds/CustomSelect";
import CustomTextArea from "../CustomFileds/CustomTextArea";
import FormsDB from "../db.json";

const FormCompensationRequestScreen: React.FC<ICurrentService> = (props) => {
  const deviceMode = useDeviceMode();
  const { formik } = props;

  useEffect(() => {
    document
      .getElementById("FormCompensationRequestScreen_container")
      ?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, []);

  return (
    <div style={styles.container} id="FormCompensationRequestScreen_container">
      <div style={styles.form}>
        <CustomSelect
          label="האם נסיעתך בוצעה באמצעות כרטיס רב קו?"
          name="FormCompensationRequestScreen.isRavKav"
          placeholder="isRavKav"
          onChange={(isRavKav: ChangeEvent<HTMLSelectElement>) => {
            formik.setFieldValue(
              "FormCompensationRequestScreen.isRavKav",
              isRavKav.target.value
            );
          }}
          errors={formik.errors.FormCompensationRequestScreen?.isRavKav}
          touched={formik.touched.FormCompensationRequestScreen?.isRavKav+''}
          selectstyle={{
            flex: "unset",
            width: deviceMode === StyleMode.mobile ? "100%" : "48%",
          }}
        >
          <option value="">בחירה</option>
          {FormsDB.booleanList.map((travelType, index) => (
            <option key={index} value={travelType.boolKey}>
              {travelType.boolValue}
            </option>
          ))}
        </CustomSelect>

        {formik.values.FormCompensationRequestScreen.isRavKav !== "" && (
          <div style={styles.stationsSelectContainer}>
            <CustomSelect
              label="תחנת מוצא"
              name="FormCompensationRequestScreen.stationDeparture"
              placeholder="stationDeparture"
              onChange={(departure: ChangeEvent<HTMLSelectElement>) => {
                formik.setFieldValue(
                  "FormCompensationRequestScreen.stationDeparture",
                  departure.target.value
                );
              }}
              errors={
                formik.errors.FormCompensationRequestScreen?.stationDeparture
              }
              touched={
                formik.touched.FormCompensationRequestScreen?.stationDeparture+''
              }
              selectstyle={{
                flex: "unset",
                width: deviceMode === StyleMode.mobile ? "100%" : "48%",
              }}
            >
              <option value="">יש לבחור את שם התחנה</option>
              {FormsDB.stations.map((station, index) => (
                <option key={index} value={station.stationId}>
                  {station.stationName}
                </option>
              ))}
            </CustomSelect>

            <CustomSelect
              label="תחנת יעד"
              name="FormCompensationRequestScreen.stationDestination"
              placeholder="stationDestination"
              onChange={(destination: ChangeEvent<HTMLSelectElement>) => {
                formik.setFieldValue(
                  "FormCompensationRequestScreen.stationDestination",
                  destination.target.value
                );
              }}
              errors={
                formik.errors.FormCompensationRequestScreen?.stationDestination
              }
              touched={
                formik.touched.FormCompensationRequestScreen?.stationDestination+''
              }
              selectstyle={{
                flex: "unset",
                width: deviceMode === StyleMode.mobile ? "100%" : "48%",
              }}
            >
              <option value="">יש לבחור את שם התחנה</option>
              {FormsDB.stations.map((station, index) => (
                <option key={index} value={station.stationId}>
                  {station.stationName}
                </option>
              ))}
            </CustomSelect>
          </div>
        )}

        {formik.values.FormCompensationRequestScreen.isRavKav === "true" && (
          <CustomInput
            label="מספר כרטיס רב קו"
            name="FormCompensationRequestScreen.ravKavCardNumber"
            type="number"
            value={formik.values.FormCompensationRequestScreen.ravKavCardNumber}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            errors={
              formik.errors.FormCompensationRequestScreen?.ravKavCardNumber
            }
            touched={
              formik.touched.FormCompensationRequestScreen?.ravKavCardNumber+''
            }
            placeholder="הזן מספר כרטיס רב קו"
            selectstyle={{
              flex: "unset",
              width: deviceMode === StyleMode.mobile ? "100%" : "48%",
            }}
          />
        )}

        <div style={styles.finalElementsContainer}>
          <CustomDatePicker
            label="תאריך"
            name="FormCompensationRequestScreen.eventDateTime"
            type="date"
            value={formik.values.FormCompensationRequestScreen.eventDateTime}
            onBlur={formik.handleBlur}
            onChange={(date: Date) => {
              formik.setFieldValue(
                "FormCompensationRequestScreen.eventDateTime",
                date
              );
            }}
            errors={formik.errors.FormCompensationRequestScreen?.eventDateTime}
            touched={
              formik.touched.FormCompensationRequestScreen?.eventDateTime+''
            }
            placeholder="aa"
            showTime={true}
          />
          <CustomTextArea
            label="תיאור האירוע"
            name="FormCompensationRequestScreen.comments"
            type="textarea"
            value={formik.values.FormCompensationRequestScreen.comments}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            errors={formik.errors.FormCompensationRequestScreen?.comments}
            touched={formik.touched.FormCompensationRequestScreen?.comments+''}
            placeholder="הזן תיאור האירוע"
          />
          <CustomAttachedFiles
            label="צירוף מסמכים
              (ניתן להוסיף יותר מקובץ אחד)"
            name="FormCompensationRequestScreen.attachFiles"
            value={formik.values.FormCompensationRequestScreen.attachFiles}
            onBlur={formik.handleBlur}
            onChange={(files: File[] | null) => {
              formik.setFieldValue(
                "FormCompensationRequestScreen.attachFiles",
                files
              );
            }}
            errors={formik.errors.FormCompensationRequestScreen?.attachFiles}
            touched={formik.touched.FormCompensationRequestScreen?.attachFiles+''}
            type="file"
            placeholder="צירוף קובץ"
          />
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    margin: "auto",
    paddingBottom: "2rem",
  },
  finalElementsContainer: {
    display: "flex",
    flexWrap: "wrap",
    columnGap: "2rem",
  },
  form: { display: "flex", flexWrap: "wrap", columnGap: "2rem" },
  button: {
    width: "100%",
    padding: "0.65rem 0.5rem",
    fontSize: "1rem",
    color: colors.white,
    border: "2px solid",
    backgroundColor: colors.blue,
    borderRadius: "10px",
    outline: "none",
    margin: "1rem 0",
    fontWeight: "bold",
    cursor: "pointer",
  },
  stationsSelectContainer: {
    display: "flex",
    width: "100%",
    columnGap: "2rem",
    flexWrap: "wrap",
  },
};

export default FormCompensationRequestScreen;
