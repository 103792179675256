import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ISystemParam } from "../../Interfaces/IConfig";
import { allPricies } from "../../Utils/helpers";

export interface HomeState {
  websiteVersion: string;
  systemParamsKey: { [key: string]: ISystemParam };
  allPrice?: allPricies;
  listProfiles?:number[]
}


const initialState: HomeState = {
  websiteVersion: "",
  systemParamsKey: {},
  allPrice: undefined,
  listProfiles:undefined,
};

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setWebsiteVersion: (state, action: PayloadAction<string>) => {
      state.websiteVersion = action.payload;
    },
    setSystemParamsKey: (
      state,
      action: PayloadAction<{ [key: string]: ISystemParam }>
    ) => {
      state.systemParamsKey = action.payload;
    },
    setAllPrice: (
      state,
      action: PayloadAction<allPricies>
    ) => {
      state.allPrice = action.payload;
    },
    setListProfiles: (
      state,
      action: PayloadAction<number[]>
    ) => {
      state.listProfiles = action.payload;
    },
  },
});

export const { setWebsiteVersion, setSystemParamsKey,setListProfiles ,setAllPrice} = homeSlice.actions;

export default homeSlice.reducer;
