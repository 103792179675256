import { useState } from "react";
import colors from "../../../../../Assets/Colors/Colors.json";
import Images from "../../../../../Data/Images/Images";
import { IInputForm } from "../../../Interfaces_tenders/IForm";
import fonts from "../../../../../Assets/Fonts/Fonts.json"
const InputForm: React.FC<IInputForm> = (props) => {
  const { placeholder, onChange, maxWidth, type } = props;
  const [_isFocus, _setIsFocus] = useState(false);

  return (
    <div
      style={{
        ...styles.container,
        border: `0.0625rem solid ${
          _isFocus ? colors.darkBlue : colors.pastelGray
        }`,
        maxWidth: maxWidth ? maxWidth : "18.6rem",
      }}
    >
      <input
        type={type}
        placeholder={placeholder}
        style={styles.input}
        onClick={() => _setIsFocus(true)}
        onBlur={() => _setIsFocus(false)}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

const styles: any = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "2.5rem",
    borderRadius: "1.25rem",
    gap: ".3rem",
    padding: "0 .5rem",
    position: "relative",
  } as React.CSSProperties,
  input: {
    width: "80%",
    fontSize: "1.125rem",
    color: colors.pasterlGrayDark,
    letterSpacing: "0.043125rem",
    fontFamily: fonts.FbReformaRegular,
  } as React.CSSProperties,

};

export default InputForm;
