import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { IWhyWorkHere } from "../../Interfaces/ICareers";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import useDeviceMode from "../../Utils/useWindowDimensions";

const WhyWorkHere: React.FC<IWhyWorkHere> = (props) => {
  const { title, inTitle, options } = props;

  const deviceMode = useDeviceMode();
  const styleRow = deviceMode === "MOBILE" ? styles.column : styles.row;
  return (
    <div style={{ ...styles.maxWidth }}>
      <div
        style={{
          ...styles.row,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ flex: 1, ...styles.column, gap: "1rem" }}>
          <div
            style={{
              paddingBottom: "2rem",
              flex: 1,
              ...styles.column,
              gap: "1rem",
            }}
          >
            <h2 style={styles.title}>
              <span>{title}</span>
              <span style={styles.inTitle}>{inTitle}</span>
            </h2>
          </div>
          <div
            style={{
              ...styleRow,
              flex: 1.5,
              gap: "2rem",
              paddingBlock: "2rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {options.map((item, index) => (
              <div style={styles.whyToWork} key={index}>
                <img
                  src={item.image._url}
                  alt={``}
                  height={`auto`}
                  width={`auto`}
                  style={{
                    scale: "1.5",
                  }}
                />
                <h3 style={styles.whyTitle}>{item.title}</h3>
                <div
                  style={styles.infoBlack}
                  dangerouslySetInnerHTML={{
                    __html: item.description,
                  }}
                />
                <div
                  style={{
                    width: "60%",
                    height: 4,
                    borderRadius: 2,
                    backgroundColor: colors.koromiko,
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
const styles: StyleSheet = {
  title: {
    fontSize: fontSize.H1,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaLight,
    lineHeight: "3rem",
    margin: 0,
    display: "flex",
    flexDirection: "column",
  },
  subtitle: {
    fontSize: fontSize.H1,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaLight,
    margin: 0,
  },
  inTitle: {
    fontSize: fontSize.H1,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
  },
  maxWidth: {
    maxWidth: "68rem",
    margin: "auto",
    paddingBlock: "2rem",
    paddingInline: "1rem",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },

  whyToWork: {
    display: "flex",
    flexDirection: "column",
    gap: "2.5rem",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "22rem",
  },
  whyTitle: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.H1,
    // marginBlock: 0,
  },
  infoBlack: {
    color: "#282828",
    fontSize: fontSize.UmbracoElementTitle,
    letterSpacing: "0.042rem",
    fontFamily: fonts.FbReformaLight,

    lineHeight: "2rem",
  },
};

export default WhyWorkHere;
