import { StyleSheet } from "../../Interfaces/IStyleSheet";
import colors from "../../Assets/Colors/Colors.json";
import Images from "../../Data/Images/Images";
import i18n from "../../Services/i18n";
import { useState } from "react";
import { ShareOptions, linkBuilder, linkShareFormat } from "./ShareOptions";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import useDeviceMode from "../../Utils/useWindowDimensions";
import { StyleMode } from "../../Utils/consts";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { Translations } from "../../Services/GlobalService";
import { IShrePage } from "../../Interfaces/IShrePage";

interface IDragInfo {
  x: number;
  y: number;
  time: number;
}

const SharePage: React.FC<IShrePage> = (props) => {
  const { shareUrl } = props;
  const [_isHover, _setIsHover] = useState(false);
  const [_shareOptionHover, _setShareOptionHover] = useState("");
  const [_currentUrl, _setCurrentUrl] = useState(window.location.href);
  const [_dragInfo, _setDragInfo] = useState<IDragInfo>();
  const [_lastDragPositionX, _setLastDragPositionX] = useState(0);
  const [_lastDragPositionY, _setLastDragPositionY] = useState(0);
  const [_dragPositionX, _setdragPositionX] = useState(0);
  const [_dragPositionY, _setdragPositionY] = useState(0);
  const [_isDragging, _setIsDragging] = useState(false);
  const draggableContainerRef = useRef<HTMLDivElement>(null);
  const [_mobileItemsPos, _setMobileItemsPos] = useState<"Top" | "Bottom">(
    "Top"
  );

  const mobileItemHeightCalc = 4.5;
  const desktopItemWidthCalc = 3.5;

  const location = useLocation();
  const deviceMode = useDeviceMode();
  const [_isDragDisabled, _setIsDragDisabled] = useState(
    deviceMode === StyleMode.desktop || _isHover
  );

  useEffect(() => {
    shareUrl
      ? _setCurrentUrl(shareUrl.replaceAll("&", "%26"))
      : _setCurrentUrl(window.location.href.replaceAll("&", "%26"));

    _setIsHover(false);
  }, [location]);

  const getTranslateXValue = (translateString: string) => {
    var n = translateString.indexOf("(");
    var n1 = translateString.indexOf(",");

    var res = parseInt(translateString.slice(n + 1, n1 - 2));

    return res;
  };
  const getTranslateYValue = (translateString: string) => {
    var n = translateString.indexOf(",");
    var n1 = translateString.indexOf(")");

    var res = parseInt(translateString.slice(n + 1, n1 - 1));
    return res;
  };

  const handleItemsPositions = () => {
    if (draggableContainerRef.current) {
      const yPosition = draggableContainerRef.current.getBoundingClientRect().y;
      const windowHeight = window.innerHeight;
      if (yPosition < windowHeight / 2 && yPosition < 0 && _isHover) {
        if (draggableContainerRef.current) {
          const y =
            getTranslateYValue(draggableContainerRef.current.style.transform) +
            draggableContainerRef.current.getBoundingClientRect().height / 2 -
            25;
          draggableContainerRef.current.style.transform = `translate(0px, ${y}px)`;
          _setLastDragPositionY(y);
          _setdragPositionY(y);
        }
      }
    }
  };

  useEffect(() => {
    handleItemsPositions();
  }, [_dragPositionY]);
  const Translation = Translations[
    i18n.language as `he` | `en` | `ar` | `ru`
  ] as any;

  const handleShare = (redirectTo: string) => {
    let des = `${Translation.sharePagetext.replace(/{{.*?}}/, document.title)}`;
    linkShareFormat.subject = des;
    linkShareFormat.body = _currentUrl;
    linkShareFormat.text = `${des} ${_currentUrl}`;
    linkShareFormat.url = _currentUrl;
    linkShareFormat.u = `${des} ${_currentUrl}`;
    // sharePagetext
    let newLink = linkBuilder(redirectTo, linkShareFormat);
    window.open(newLink, "_blank");
  };

  let handleDragStart = (e: DraggableEvent, data: DraggableData) => {
    _setDragInfo({
      x: data.x,
      y: data.y,
      time: Date.now(),
    });
  };

  let handleDragStop = (e: any, data: DraggableData) => {
    if (!_dragInfo) return;

    _setdragPositionY(data.y);
    let change = {
      x: Math.abs(data.x - _dragInfo.x),
      y: Math.abs(data.y - _dragInfo.y),
      time: Date.now() - _dragInfo.time,
    };
    if (change.x + change.y <= 10 && change.time < 300) e.srcElement?.click?.();
  };

  return (
    <Draggable
      bounds={{ bottom: 0, top: -window.innerHeight + 150 }}
      disabled={deviceMode === StyleMode.desktop || _isHover}
      onStart={handleDragStart}
      onStop={handleDragStop}
      position={{
        x: _dragPositionX,
        y: _dragPositionY,
      }}
      axis="y"
    >
      <div
        ref={draggableContainerRef}
        style={{
          ...styles.container,
          left: i18n.dir() === "rtl" ? "1rem" : "",
          right: i18n.dir() === "ltr" ? "1rem" : "",
          borderTopLeftRadius: i18n.dir() === "rtl" ? "2rem" : "2rem",
          borderBottomLeftRadius: i18n.dir() === "rtl" ? "2rem" : "2rem",
          borderTopRightRadius: i18n.dir() === "ltr" ? "2rem" : "2rem",
          borderBottomRightRadius: i18n.dir() === "ltr" ? "2rem" : "2rem",
          overflow: "hidden",
          gap: _isHover ? "1rem" : "",
          flexDirection:
            deviceMode === StyleMode.desktop ? "row-reverse" : "column-reverse",
        }}
      >
        <img
          src={_isHover ? Images.close_full : Images.share_full}
          onClick={() => {
            _setIsHover(!_isHover);

            if (draggableContainerRef.current) {
              const yPosition =
                draggableContainerRef.current.getBoundingClientRect().y;
              const windowHeight = window.innerHeight;
              if (yPosition < windowHeight / 2) {
                if (draggableContainerRef.current && !_isHover) {
                  const y =
                    -1 *
                      (draggableContainerRef.current.getBoundingClientRect()
                        .height /
                        2) *
                      10 +
                    20;
                  draggableContainerRef.current.style.transform = `translate(0px, ${y}px)`;
                  _setLastDragPositionY(y);
                  _setdragPositionY(y);
                }
              }
            }
          }}
          style={{
            borderRadius: "50%",
            cursor: "pointer",
            zIndex: "999999",
          }}
        />
        <div
          style={{
            width:
              deviceMode === StyleMode.desktop
                ? !_isHover
                  ? "0"
                  : `${ShareOptions.length * desktopItemWidthCalc}rem`
                : "unset",
            height:
              deviceMode !== StyleMode.desktop
                ? !_isHover
                  ? "0"
                  : `${
                      Math.floor(ShareOptions.length * mobileItemHeightCalc) - 2
                    }rem`
                : "unset",
            transition: ".25s ease-out",
            backgroundColor: colors.white,
          }}
        >
          <ul
            style={{
              display: "flex",
              gap: deviceMode === StyleMode.desktop ? "1rem" : "1rem",
              flexDirection:
                deviceMode === StyleMode.desktop
                  ? "row-reverse"
                  : "column-reverse",
              alignItems: "center",
              marginBlockEnd: ".51rem",
              paddingInlineStart: "0",
              backgroundColor: colors.white,
              zIndex: "9999",
            }}
          >
            {ShareOptions.map((ShareItem, index) => (
              <li
                key={index}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                title={ShareItem.name}
                onClick={() => handleShare(ShareItem.redirectTo)}
                onMouseOver={() => _setShareOptionHover(ShareItem.name)}
                onMouseLeave={() => _setShareOptionHover("")}
              >
                <img src={ShareItem.image} width={"36"} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Draggable>
  );
};

const styles: StyleSheet = {
  container: {
    position: "fixed",
    bottom: "12rem",
    zIndex: "10",
    display: "flex",
    alignItems: "center",
    backgroundColor: colors.white,
    boxShadow: "#00000094 0px 2px 10px 0px",
  },
};

export default SharePage;
