const Config = {
  BASE_LOCALSTORAGE_NAME: process.env.REACT_APP_BASE_LOCALSTORAGE_NAME,
  BASE_URL: process.env.REACT_APP_BASE_URL,
  COMMON: process.env.REACT_APP_COMMON,
  CONNTENT_MANAGMENT: process.env.REACT_APP_CONNTENT_MANAGMENT,
  UMBRACO_CONTENT: process.env.REACT_APP_UMBRACO_CONTENT,
  OCP_APIM_SUBSCRIPTION_KEY: process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
  RJPA_URL: process.env.REACT_APP_RJPA_URL,
  SYSTEM_TYPE: process.env.REACT_APP_SYSTEM_TYPE,
  SITE_VERSION: process.env.REACT_APP_SITE_VERSION,
  DOMAIN: process.env.REACT_APP_DOMAIN,
  PREVIEW_ACTIVE: process.env.REACT_APP_PREVIEW_ACTIVE,
  TENDERS_URL: process.env.REACT_APP_TENDERS_URL,
  TENDERS_FILES: process.env.REACT_APP_TENDERS_FILES,
  IS_TENDERS: process.env.REACT_APP_IS_TENDERS
};
export default Config;
