import { FiFilter } from "react-icons/fi";
import { FaFilter } from "react-icons/fa";
import { IFilterAuction } from "../../Interfaces_tenders/IFilterAuction";


const FilterAuction: React.FC<IFilterAuction> = (props) => {
  const { areAuctionsFiltered, onMobileFilterToggle, dynamicTextsDict } = props;

  return (
    <div>
      {areAuctionsFiltered ? (
        <div onClick={onMobileFilterToggle} style={styles.filterIconContainer}>
          <div>
            <FaFilter/>
          </div>
          <div >
            {dynamicTextsDict[0]["filterContent"].text}
          </div>
        </div>
      ) : (
        <div onClick={onMobileFilterToggle} style={styles.filterIconContainer}>
         
            <FiFilter/>
          
          <div >
            {dynamicTextsDict[0]["filterContent"].text}
          </div>
        </div>
      )}
    </div>
  );
};

const styles: any = {
  filterIconContainer: {
    display: "flex",
    gap: "0.5rem",
    alignItems: "flex-end",
    color: "rgb(0, 157, 214)",
    fontSize: "1.5rem",
  } as React.CSSProperties,
};

export default FilterAuction;
