import React, { useState } from "react";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { useFormik } from "formik";
import { IFormCustomerServiceContactForm } from "../../../Interfaces/IForms";
import { PostForm } from "../../../Services/CommonService";
import FormBasicDetails from "../FormBasicDetails/FormBasicDetails";
import FormContactDetails from "../FormContactDetails/FormContactDetails";
import FormReferenceDetails from "../FormReferenceDetails/FormReferenceDetails";
import FormTitles from "../FormTitles";
import { formSchema } from "../schemas";

const FormCustomerServiceContactForm: React.FC<
  IFormCustomerServiceContactForm
> = (props) => {
  const [_submitted, _setSubmitted] = useState(false);

  const formik = useFormik({
    initialValues: {
      FormBasicDetails: {
        firstName: "",
        lastName: "",
        streetBuildNum: "",
        city: "",
        zipCode: "",
        poBox: "",
      },
      FormContactDetails: {
        phoneNumber: "",
        email: "",
      },
      FormReferenceDetails: {
        serviceSubject: "",
      },
      FormClaimTicketPurchaseScreen: {
        currentSubject: "",

        userId: "",
        ravKavCardNumber: "",
        eventDateTime: new Date(),
        comments: "",
        attachFiles: [],
      },
      FormAccessibilityOrCleaningIssueScreen: {
        currentSubject: "",
        issueLocation: "",
        stationDeparture: "",
        stationDestination: "",
        eventDateTime: new Date(),
        comments: "",
        attachFiles: [],
      },
      FormAppealRequirementPaymentScreen: {
        currentSubject: "",

        isRavKav: "",
        stationDeparture: "",
        stationDestination: "",
        userId: "",
        ravKavCardNumber: "",
        reqNumber: "",
        eventDateTime: new Date(),
        comments: "",
        attachFiles: [],
      },
      FormCheckEntranceStationScreen: {
        currentSubject: "",

        stationDeparture: "",
        eventDateTime: new Date(),
        comments: "",
        attachFiles: [],
      },
      FormClaimRailsWorkerOrThanksAppreciationScreen: {
        currentSubject: "",

        role: "",
        stationDeparture: "",
        stationDestination: "",
        other: "",
        eventDateTime: new Date(),
        comments: "",
        attachFiles: [],
      },
      FormCompensationRequestScreen: {
        currentSubject: "",

        isRavKav: "",
        stationDeparture: "",
        stationDestination: "",
        ravKavCardNumber: "",
        eventDateTime: new Date(),
        comments: "",
        attachFiles: [],
      },
      FormInquiryAboutWebAppScreen: {
        currentSubject: "",

        comments: "",
        attachFiles: [],
      },
    },
    validationSchema: formSchema,
    onSubmit: async (values, actions: { resetForm: () => void }) => {
      await new Promise((resolve) => {
        setTimeout(resolve, 1000);


        let description = "";
        let userId = "";
        let destination = "";
        let initialstation = "";
        let ravkavnumber = "";
        let occasiondate = new Date();
        let files: File[] | undefined = [];
        let firstTriple = 0;
        let secondTriple = 0;
        let thirdTriple = 0;

        switch (values.FormReferenceDetails.serviceSubject) {
          case "claimTicketPurchase":
            occasiondate = values.FormClaimTicketPurchaseScreen.eventDateTime;
            description = values.FormClaimTicketPurchaseScreen.comments;
            userId = values.FormClaimTicketPurchaseScreen.userId;
            ravkavnumber =
              values.FormClaimTicketPurchaseScreen.ravKavCardNumber;
            files = values.FormClaimTicketPurchaseScreen.attachFiles;
            firstTriple = 1;
            secondTriple = 5;
            thirdTriple = 0;
            break;
          case "accessibilityIssue":
            occasiondate =
              values.FormAccessibilityOrCleaningIssueScreen.eventDateTime;
            description =
              values.FormAccessibilityOrCleaningIssueScreen.comments;
            destination =
              values.FormAccessibilityOrCleaningIssueScreen.stationDestination;
            initialstation =
              values.FormAccessibilityOrCleaningIssueScreen.stationDeparture;
            files = values.FormAccessibilityOrCleaningIssueScreen.attachFiles;
            firstTriple = 1;
            secondTriple = 6;

            switch (
              values.FormAccessibilityOrCleaningIssueScreen.issueLocation
            ) {
              case "station":
                thirdTriple = 1;
                break;
              case "wagon":
                thirdTriple = 2;
                break;
            }

            break;
          case "cleaningIssue":
            occasiondate =
              values.FormAccessibilityOrCleaningIssueScreen.eventDateTime;
            description =
              values.FormAccessibilityOrCleaningIssueScreen.comments;
            destination =
              values.FormAccessibilityOrCleaningIssueScreen.stationDestination;
            initialstation =
              values.FormAccessibilityOrCleaningIssueScreen.stationDeparture;
            files = values.FormAccessibilityOrCleaningIssueScreen.attachFiles;
            firstTriple = 2;
            secondTriple = 1;
            thirdTriple = 0;
            break;
          case "appealRequirementPayment":
            occasiondate =
              values.FormAppealRequirementPaymentScreen.eventDateTime;
            description = values.FormAppealRequirementPaymentScreen.comments;
            userId = values.FormAppealRequirementPaymentScreen.userId;
            destination =
              values.FormAppealRequirementPaymentScreen.stationDestination;
            initialstation =
              values.FormAppealRequirementPaymentScreen.stationDeparture;
            ravkavnumber =
              values.FormAppealRequirementPaymentScreen.ravKavCardNumber;
            firstTriple = 1;
            secondTriple = 8;
            thirdTriple = 0;
            break;
          case "checkEntranceStation":
            occasiondate = values.FormCheckEntranceStationScreen.eventDateTime;
            description = values.FormCheckEntranceStationScreen.comments;
            initialstation =
              values.FormCheckEntranceStationScreen.stationDeparture;
            files = values.FormCheckEntranceStationScreen.attachFiles;
            firstTriple = 1;
            secondTriple = 7;
            thirdTriple = 0;
            break;
          case "claimRailsWorker":
            occasiondate =
              values.FormClaimRailsWorkerOrThanksAppreciationScreen
                .eventDateTime;
            description =
              values.FormClaimRailsWorkerOrThanksAppreciationScreen.comments;
            destination =
              values.FormClaimRailsWorkerOrThanksAppreciationScreen
                .stationDestination;
            initialstation =
              values.FormClaimRailsWorkerOrThanksAppreciationScreen
                .stationDeparture;
            files =
              values.FormClaimRailsWorkerOrThanksAppreciationScreen.attachFiles;
            firstTriple = 1;
            secondTriple = 3;

            switch (
              values.FormClaimRailsWorkerOrThanksAppreciationScreen.role
            ) {
              case "stationManager":
                thirdTriple = 1;
                break;
              case "inspector":
                thirdTriple = 2;
                break;
              case "Dale":
                thirdTriple = 3;
                break;
              case "cashier":
                thirdTriple = 4;
                break;
              case "ravKavDelegate":
                thirdTriple = 5;
                break;
              case "guard":
                thirdTriple = 6;
                break;
              case "other":
                thirdTriple = 7;
                break;
            }

            break;
          case "thanksAppreciation":
            occasiondate =
              values.FormClaimRailsWorkerOrThanksAppreciationScreen
                .eventDateTime;
            description =
              values.FormClaimRailsWorkerOrThanksAppreciationScreen.comments;
            destination =
              values.FormClaimRailsWorkerOrThanksAppreciationScreen
                .stationDestination;
            initialstation =
              values.FormClaimRailsWorkerOrThanksAppreciationScreen
                .stationDeparture;
            files =
              values.FormClaimRailsWorkerOrThanksAppreciationScreen.attachFiles;
            firstTriple = 1;
            secondTriple = 4;

            switch (
              values.FormClaimRailsWorkerOrThanksAppreciationScreen.role
            ) {
              case "stationManager":
                thirdTriple = 1;
                break;
              case "inspector":
                thirdTriple = 2;
                break;
              case "Dale":
                thirdTriple = 3;
                break;
              case "cashier":
                thirdTriple = 4;
                break;
              case "ravKavDelegate":
                thirdTriple = 5;
                break;
              case "guard":
                thirdTriple = 6;
                break;
              case "other":
                thirdTriple = 7;
                break;
            }

            break;
          case "compensationRequest":
            occasiondate = values.FormCompensationRequestScreen.eventDateTime;
            description = values.FormCompensationRequestScreen.comments;
            destination =
              values.FormCompensationRequestScreen.stationDestination;
            initialstation =
              values.FormCompensationRequestScreen.stationDeparture;
            ravkavnumber =
              values.FormCompensationRequestScreen.ravKavCardNumber;
            files = values.FormCompensationRequestScreen.attachFiles;
            firstTriple = 1;
            secondTriple = 9;
            thirdTriple = 0;
            break;
          case "inquiryAboutWebApp":
            description = values.FormInquiryAboutWebAppScreen.comments;
            files = values.FormInquiryAboutWebAppScreen.attachFiles;
            firstTriple = 2;
            secondTriple = 0;
            thirdTriple = 0;
        }

        PostForm(
          Number("" + firstTriple + secondTriple + thirdTriple), //formId
          values.FormContactDetails.phoneNumber, //address1_telephone1
          values.FormBasicDetails.lastName, //lastname
          values.FormBasicDetails.firstName, //firstname
          occasiondate, //new_occasiondate
          destination, //new_destination
          initialstation, //new_initialstation
          files, //files
          "", //new_casesubject1
          "", //new_casesubject2
          "", //new_casesubject3
          userId, //new_customerid
          values.FormBasicDetails.city, //address1_city
          values.FormBasicDetails.streetBuildNum, //address1_line1
          values.FormBasicDetails.zipCode, //address1_postalcode
          values.FormBasicDetails.poBox, //address1_postofficebox
          values.FormContactDetails.email, //emailaddress1
          description, //description
          ravkavnumber //new_ravkavnumber
        );
      });

      actions.resetForm();
      _setSubmitted(true);
    },
  });

  return (
    <div
      style={
        _submitted
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }
          : {}
      }
    >
      {!_submitted ? (
        <form
          className="FormCustomerServiceContactForm"
          onSubmit={formik.handleSubmit}
        >
          <FormTitles
            header1="לקוחות יקרים,"
            header2="שירות הלקוחות של רכבת ישראל עומד לרשותכם.
על מנת שנוכל לטפל בפנייתך, חובה למלא את השדות הנדרשים."
            header3="* אין ביכולתנו לטפל בפניות שיחסר בהן המידע הנדרש."
          />

          <FormBasicDetails formik={formik} />
          <FormContactDetails formik={formik} />
          <FormReferenceDetails formik={formik} />

          <div style={styles.btnContainer}>
            <button style={styles.btn} type="submit">
              שליחה
            </button>
          </div>
        </form>
      ) : (
        <div style={styles.messageContainer}>
          תודה פנייתך התקבלה בהצלחה ,מספר הפניה הוא 1000
        </div>
      )}
    </div>
  );
};

const styles: StyleSheet = {
  btn: {
    backgroundColor: "rgb(0, 69, 138)",
    color: "rgb(255, 255, 255)",
    borderColor: "unset",
    fontSize: "1.2rem",
    borderRadius: "16rem",
    width: "8rem",
    height: "2.5rem",
    fontWeight: "bold",
    letterSpacing: "0.029375rem",
    textAlign: "center",
    cursor: "pointer",
  },

  btnContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    paddingTop: "2rem",
    paddingBottom: "2rem",
  },
  messageContainer: {
    // backgroundColor: "rgb(0, 69, 138)",
    color: "rgb(0, 69, 138)",
    fontSize: "2rem",
    fontWeight: "400",
  },
};

export default FormCustomerServiceContactForm;
