import { IMagazineCategories } from "../../../../Interfaces/IMagazinePage";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import { StyleMode } from "../../../../Utils/consts";
import useDeviceMode from "../../../../Utils/useWindowDimensions";
import LayoutMagazine from "../Components/LayoutMagazine";
import MagazineCategory from "./MagazineCategory";

const MagazineCategories: React.FC<IMagazineCategories> = (props) => {
  const { title, categories, anchor } = props;
  const deviceMode = useDeviceMode();

  return (<LayoutMagazine title={title} anchor={anchor} >
    <div
      style={{
        ...styles.container,
        gap: deviceMode !== StyleMode.desktop ? "1rem" : "12rem",
        justifyContent: deviceMode !== StyleMode.desktop ? "space-between" : "start",
        padding: deviceMode !== StyleMode.desktop ? "0 2rem" : ""
      }}>
      {categories.map((category, index) => (
        <MagazineCategory key={index} {...category} />
      ))}
    </div>
  </LayoutMagazine>)
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    justifyContent: "start",

  }
};

export default MagazineCategories;
