import { t } from "i18next";
import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import store from "store2";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import colors from "../Assets/Colors/Colors.json";
import fonts from "../Assets/Fonts/Fonts.json";
import fontSize from "../Assets/Fonts/FontsSizes.json";
import AutoCompleteForm from "../Components/Form/AutoCompleteForm/AutoCompleteForm";
import Loading from "../Components/Loading/Loading";
import ActivityTime from "../Components/StationInfo/ActivityTime/ActivityTime";
import RichTextEditor from "../Components/UmbracoElements/RichTextEditor";
import Config from "../Configuration/server";
import Images from "../Data/Images/Images";
import { IActivityTime } from "../Interfaces/IActivityTime";
import { ILinesMap, ILinesMapResponse } from "../Interfaces/ILinesMap";
import { IStation } from "../Interfaces/IStation";
import { StyleSheet } from "../Interfaces/IStyleSheet";
import {
  GetStationInformation,
  GetStationMapSVG,
} from "../Services/CommonService";
import { IsMobile } from "../Services/DeviceService";
import { GetStations } from "../Services/GlobalService";
import {
  findAndAddClicksToStations,
  fixSafariStationsNamesDirections,
  handleMapPin,
} from "../Services/LinesMapService";
import { GetSortedStations } from "../Services/SearchStationsAutoCompleteService";
import i18n from "../Services/i18n";
import { StyleMode } from "../Utils/consts";
import {
  GetDynamicTextsDictionary,
  GetStationsDictionary,
  IDictStation,
} from "../Utils/helpers";
import useDeviceMode from "../Utils/useWindowDimensions";
import { removeSpecialCharacters } from "../Utils/utils";

const LinesMap: React.FC<ILinesMap> = (props) => {
  const { dynamicPart } = props;
  const [_svg, _setSvg] = useState("");
  const [_printSvg, _setPrintSvg] = useState("");
  const [_isPrinting, _setIsPrinting] = useState(false);
  const [_isStationTooltipVisible, _setIsStationTooltipVisible] =
    useState(false);
  const [_mouseXPosition, _setMouseXPosition] = useState<number>();
  const [_mouseYPosition, _setMouseYPosition] = useState<number>();
  const [_selectedStationId, _setSelectedStationId] = useState("");
  const [_firstWidth] = useState(window.innerWidth * 0.98);
  const [_tooltipInfo, _setTooltipInfo] = useState<IActivityTime[]>([]);
  const [_currentStationId, _setCurrentStationId] = useState("");
  const [_currentStationName, _setCurrentStationName] = useState("");
  const [_isTooltipVisible, _setIsTooltipVisible] = useState(false);
  const [_activityTimeKey, _setActivityTimeKey] = useState(0);
  const [_isZoomInOver, _setIsZoomInOver] = useState(false);
  const [_isZoomOutOver, _setIsZoomOutOver] = useState(false);
  const [_isStationHover, _setIsStationHover] = useState(false);
  const [_hoverStationId, _setHoverStationId] = useState<string | number>("");
  const [_allStations, _setAllStations] = useState(
    store.session.get(`${Config.BASE_LOCALSTORAGE_NAME}stations`)
  );
  const [dictStations, _setDictStations] = useState<IDictStation>(
    GetStationsDictionary(_allStations[i18n.language])
  );
  const tooltipInfoObjRef = useRef<{ [key: string | number]: any }>({});
  const displayTooltipTimeoutRef = useRef<any>(null);

  const deviceMode = useDeviceMode();
  const navigate = useNavigate();
  const [_isLoadingTooltip, _setIsLoadingTooltip] = useState(false);

  const resizeHandler = () => {
    if (deviceMode !== StyleMode.desktop) {
      let ZOOM = 1;
      const viewport = window.visualViewport;
      if (viewport) {
        ZOOM = viewport?.scale;
        const stationsTexts = document.getElementsByClassName(
          `smallstations_${i18n.language}`
        ) as HTMLCollection;
        if (stationsTexts !== null && stationsTexts !== undefined) {
          Array.from(stationsTexts).forEach((stationText) => {
            const screenAvail = window.screen.availHeight;
            const documentFontSize = document.documentElement.style
              .getPropertyValue("--fontSize")
              .split("px")[0];
            const size =
              Math.ceil(
                (((screenAvail * parseInt(documentFontSize)) / 600) * ZOOM) / 3
              ) + "px";
            (stationText as HTMLElement).style.fontSize = size;
          });
        }
      }
    }
  };


  // const stations: IStation[] = store.session.get(
  //   `${Config.BASE_LOCALSTORAGE_NAME}stations`
  // )[i18n.language];
  // const dictStations = GetStationsDictionary(stations);
  const isSafariOs = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  // useEffect(() => {
  //   let isMounted = true;

  //   const outerstationsdiv =
  //     document.getElementsByClassName("outerstationsdiv");
  //   const smallstations_he =
  //     document.getElementsByClassName("smallstations_he");
  //   const smallstations_en =
  //     document.getElementsByClassName("smallstations_en");
  //   const smallstations_ar =
  //     document.getElementsByClassName("smallstations_ar");
  //   const smallstations_ru =
  //     document.getElementsByClassName("smallstations_ru");

  //   const smallheader_he = document.getElementsByClassName("smallheader_he");
  //   const smallheader_en = document.getElementsByClassName("smallheader_en");
  //   const smallheader_ar = document.getElementsByClassName("smallheader_ar");
  //   const smallheader_ru = document.getElementsByClassName("smallheader_ru");

  //   const forigen = document.getElementsByTagName("foreignObject");
  //   // console.log("forigen",forigen)

  //   if (
  //     outerstationsdiv !== null &&
  //     isSafariOs &&
  //     deviceMode !== StyleMode.mobile
  //   ) {
  //     for (let i = 0; i < outerstationsdiv.length; i++) {
  //       outerstationsdiv[i].classList.add("outerstationsdivSafariWeb");
  //     }
  //   }

  //   if (forigen !== null && isSafariOs && deviceMode !== StyleMode.mobile) {
  //     for (let i = 0; i < forigen.length; i++) {
  //       forigen[i].classList.add("forigenSafariWeb");
  //     }
  //   }

  //   if (
  //     outerstationsdiv !== null &&
  //     isSafariOs &&
  //     deviceMode === StyleMode.mobile
  //   ) {
  //     for (let i = 0; i < outerstationsdiv.length; i++) {
  //       outerstationsdiv[i].classList.add("outerstationsdivSafariMobile");
  //     }

  //     for (let i = 0; i < smallstations_he.length; i++) {
  //       smallstations_he[i].classList.add("smallstations_heSafariMobile");
  //     }

  //     for (let i = 0; i < smallstations_en.length; i++) {
  //       smallstations_en[i].classList.add("smallstations_enSafariMobile");
  //     }

  //     for (let i = 0; i < smallstations_ar.length; i++) {
  //       smallstations_ar[i].classList.add("smallstations_arSafariMobile");
  //     }

  //     for (let i = 0; i < smallstations_ru.length; i++) {
  //       smallstations_ru[i].classList.add("smallstations_ruSafariMobile");
  //     }

  //     for (let i = 0; i < smallheader_he.length; i++) {
  //       smallheader_he[i].classList.add("smallheader_heSafariMobile");
  //     }

  //     for (let i = 0; i < smallheader_en.length; i++) {
  //       smallheader_en[i].classList.add("smallheader_enSafariMobile");
  //     }

  //     for (let i = 0; i < smallheader_ar.length; i++) {
  //       smallheader_ar[i].classList.add("smallheader_arSafariMobile");
  //     }

  //     for (let i = 0; i < smallheader_ru.length; i++) {
  //       smallheader_ru[i].classList.add("smallheader_ruSafariMobile");
  //     }
  //   }

  //   //classList.add("mystyle")//.getElementsByClassName('outerstationsdiv')

  //   return () => {
  //     isMounted = false;
  //   };
  // }, [document.getElementById("outer_div")]);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      const linesMapLanguages = store.session.get(
        "linesMapLanguages"
      ) as ILinesMapResponse | null;

      if (isMounted) {
        let svgData: ILinesMapResponse | undefined;
        if (linesMapLanguages === null) {
          svgData = await GetStationMapSVG();
          store.session.set("linesMapLanguages", svgData);
        } else {
          svgData = linesMapLanguages;
        }

        if (svgData) {
          let svgLanguage = svgData.codeHe;
          switch (i18n.language) {
            case "he":
              svgLanguage = svgData.codeHe;
              break;
            case "en":
              svgLanguage = svgData.codeEn;
              break;
            case "ru":
              svgLanguage = svgData.codeRu;
              break;
            case "ar":
              svgLanguage = svgData.codeAR;
              break;
          }
          _setSvg(svgLanguage);
          _setPrintSvg(svgLanguage);
        }
      }
      // const printSvg = await getStationMapSVG()
    })();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    const tooltip = document.getElementById("tooltip") as HTMLDivElement;
    if (tooltip) {
      tooltip.addEventListener("mousemove", () => {
        tooltip.style.visibility = "visible";
      });
      tooltip.addEventListener("mouseleave", () => {
        tooltip.style.visibility = "hidden";
        _setIsStationHover(false);
        _setHoverStationId("");
        // clearTimeout(displayTooltipTimeoutRef.current);
        // displayTooltipTimeoutRef.current = null;
      });

      return () => {
        tooltip.removeEventListener("mousemove", () => {
          tooltip.style.visibility = "visible";
        });
        tooltip.removeEventListener("mouseleave", () => {
          tooltip.style.visibility = "hidden";
        });
      };
    }
  }, [_svg]);

  useEffect(() => {
    fixSafariStationsNamesDirections(dictStations);
  }, [_svg]);

  useEffect(() => {
    findAndAddClicksToStations(onStationMovement, onStationLeave);
  }, [_svg, _printSvg]);

  useEffect(() => {
    let isMounted = true;
    let dictioneryStation: IDictStation;
    (async () => {
      if (!!!_allStations) {
        let allStations = await GetStations();
        _setAllStations(allStations);
        dictioneryStation = GetStationsDictionary(_allStations[i18n.language]);
        _setDictStations(dictioneryStation);
      }
    })();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    clearTimeout(displayTooltipTimeoutRef.current);
    if (_isStationHover) {
      displayTooltipTimeoutRef.current = setTimeout(() => {
        (async () => {
          if (!tooltipInfoObjRef.current[_hoverStationId]) {
            tooltipInfoObjRef.current[_hoverStationId] = [];
            const data = await GetStationInformation({
              language: i18n.language,
              systemType: +Config.SYSTEM_TYPE!,
              StationId: _hoverStationId as number,
            });
            const tooltip = document.getElementById("tooltip");
            if (tooltip) {
              if (tooltip.style.visibility !== "visible") {
                _setIsLoadingTooltip(true);
                tooltip.style.visibility = "visible";
              }
            }
            tooltipInfoObjRef.current[_hoverStationId] = data.gateInfo;
            _setTooltipInfo(tooltipInfoObjRef.current[_hoverStationId]);
            // _setCurrentStationId(_hoverStationId.toString());
          } else {
            _setTooltipInfo(tooltipInfoObjRef.current[_hoverStationId]);
            // _setCurrentStationId(_hoverStationId.toString());
            if (dictStations) {
              const stationName = removeSpecialCharacters(
                dictStations[parseInt(_hoverStationId.toString())].stationName
              );
              _setCurrentStationName(stationName);
            }

            const tooltip = document.getElementById("tooltip");
            if (tooltip) {
              if (tooltip.style.visibility !== "visible") {
                _setIsLoadingTooltip(true);
                tooltip.style.visibility = "visible";
              }
            }
          }
          _setActivityTimeKey(Math.random() * 1000);
        })();
      }, 1250);
    } else {
    }
    return () => {
      clearTimeout(displayTooltipTimeoutRef.current);
    };
  }, [_isStationHover, _hoverStationId]);

  useEffect(() => {
    if (_svg) {
      if (deviceMode === StyleMode.desktop) {
        // handleZoomButtonsInsideMap();
      }
    }
  }, [_svg]);

  const handlePrint = () => {
    let content = document.getElementById("printDiv");
    let ifremPrint = document.getElementById(
      "ifmcontentstoprint"
    ) as HTMLIFrameElement;
    let print = ifremPrint.contentWindow;
    print?.document.open();
    print?.document.write(
      `<html>
        <head>
          <style type=\"text/css\" media=\"print\">                     
              @page{             
                      size:auto; /* auto is the initial value */                      
                      margin:0mm;  /* this affects the margin in the printer settings */    
                    }

              html{    
                      background-color: #FFFFFF; 
                      zoom: 45%;

                  }

              body{   
                      font-family:\"Times New Roman\", Times, serif;            
                      max-width:90%;            
                      margin: 0; /* margin you want for the content */ 
                  }                  
          .table {
                      width: 100%;       
                      max-width: 100%;                     
                      margin-bottom: 20px;         
                      border-collapse: collapse;                 
                      background-color: transparent;                     
                      display: table;         
                  }                 
  .table-bordered {                  
                      border: 1px solid #ccc;                
                  }                
              tr {            
                      display: table-row;                     
                      vertical-align: inherit;              
                      border-color: inherit;                     
                      padding:15px;                 
                  }       
.table-bordered tr td {
                      border: 1px solid #ccc!important; 
                      padding:15px!important;
                    }    
                 </style>
                 <title></title>
              </head>
              <body>`.concat(content ? content.innerHTML : "", `</body>`)
    );
    print?.resizeBy(100, 100);
    print?.document.close();
    print?.focus();
    print?.print();
  };

  const zoom = (Type: `in` | `out`) => {
    const map = document.getElementById(`Map`);

    let width = map?.offsetWidth;
    if (map) {
      if (width) {
        if (width % 40 !== 0) {
          width = width - (width % 40);
        }
        switch (Type) {
          case `in`:
            map.style.width = `${
              width < _firstWidth ? width + 40 : _firstWidth
            }px`;
            break;
          case `out`:
            map.style.width = `${width > 400 ? width - 40 : width}px`;
            break;
          default:
            break;
        }
      }
    }
  };

  useEffect(() => {
    if (_isLoadingTooltip) {
      let timeout = setTimeout(() => {
        _setIsLoadingTooltip(false);
        clearTimeout(timeout);
      }, 500);
    }
  }, [_isLoadingTooltip]);

  const handleZoomButtonsInsideMap = () => {
    const map = document.getElementsByTagName("svg")[0];
    if (map) {
      const zoomInImage = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "image"
      );
      zoomInImage.setAttributeNS(null, "height", "20");
      zoomInImage.setAttributeNS(null, "width", "20");
      zoomInImage.setAttributeNS(
        "http://www.w3.org/1999/xlink",
        "href",
        Images.zoomIn
      );
      zoomInImage.setAttributeNS(null, "x", "10");
      zoomInImage.setAttributeNS(null, "y", "10");
      zoomInImage.setAttributeNS(null, "visibility", "visible");
      zoomInImage.style.cursor = "pointer";

      zoomInImage.addEventListener("click", () => zoom("in"));
      map.appendChild(zoomInImage);

      const zoomOutImage = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "image"
      );
      zoomOutImage.setAttributeNS(null, "height", "20");
      zoomOutImage.setAttributeNS(null, "width", "20");
      zoomOutImage.setAttributeNS(
        "http://www.w3.org/1999/xlink",
        "href",
        Images.zoomOut
      );
      zoomOutImage.setAttributeNS(null, "x", "50");
      zoomOutImage.setAttributeNS(null, "y", "10");
      zoomOutImage.setAttributeNS(null, "visibility", "visible");
      zoomOutImage.style.cursor = "pointer";

      zoomOutImage.addEventListener("click", () => zoom("out"));
      map.appendChild(zoomOutImage);
    }
  };

  const dynamicTextsDict = [
    GetDynamicTextsDictionary(
      dynamicPart.dynamicElements.filter(
        (dynamicElement) => dynamicElement.contentTypeAlias === "dynamicText"
      )
    ),
  ][0];

  const stationsNamesList =
    GetSortedStations().map((station) => station.stationName) || [];

  const onStationLeave = () => {
    _setIsStationHover(false);
    _setHoverStationId("");
    clearTimeout(displayTooltipTimeoutRef.current);

    // clearTimeout(loadingTimeout.current);
    // clearTimeout(displayTooltipTimeoutRef.current);
    // displayTooltipTimeoutRef.current = null;
    // loadingTimeout.current = null;
  };

  const onStationMovement = (
    stationId?: number | string,
    mouseEvent?: MouseEvent
  ) => {
    if (mouseEvent) {
      const mouseX = mouseEvent.clientX;
      const mouseY = mouseEvent.clientY;
      _setMouseXPosition(mouseX);
      _setMouseYPosition(mouseY);

      if (deviceMode === StyleMode.desktop) {
        _setIsStationHover(true);
        _setHoverStationId(stationId as string);
        if (dictStations) {
          const stationName = removeSpecialCharacters(
            dictStations[parseInt(stationId!.toString())].stationName
          );
          _setCurrentStationName(stationName);
        }
      }
    }
  };

  const redirectToPage = (page?: string) => {
    page && navigate(`?page=${page}`);
  };




  return _svg && _allStations? (
    <Fragment>
      <iframe
        id="ifmcontentstoprint"
        style={{
          height: "0px",
          width: "0px",
          position: "absolute",
          visibility: "hidden",
          transform: `scale(${"0.55"})`,
        }}
      ></iframe>
      <div style={styles.container}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <AutoCompleteForm
            items={stationsNamesList}
            onItemClick={(item) => {
              const stations: IStation[] = _allStations[i18n.language];
              const station = stations.find(
                (station) => station.stationName === item
              );
              if (station) {
                if (deviceMode === StyleMode.desktop) {
                  handleMapPin(station.stationId);
                  _setSelectedStationId(station.stationId.toString());

                  const stationGElement = document.getElementById(
                    `stationgroup${station.stationId.toString()}`
                  );
                  if (stationGElement) {
                    stationGElement.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                  }
                } else {
                  redirectToPage(
                    `stationinfo&stationname=${station.stationId}`
                  );
                }
              }
            }}
            placeholder={
              deviceMode === StyleMode.desktop
                ? dynamicTextsDict["stationSelectPlaceholder"].text
                : dynamicTextsDict["stationSelectPlaceholderMobile"].text
            }
            customStyles={{
              width: "100%",
              maxWidth: "35rem",
            }}
          />
          {deviceMode === StyleMode.desktop && (
            <div
              tabIndex={!IsMobile() ? 0 : -1}
              role={"button"}
              style={styles.printButton}
              onClick={handlePrint}
            >
              <img
                src={Images.printer}
                width={"30"}
                style={{
                  filter: "brightness(.1)",
                }}
              />
              <span
                style={{
                  fontSize: fontSize.Paragraph,
                }}
              >
                {t("Printing")}
              </span>
            </div>
          )}
        </div>
        <div style={{ maxWidth: "70rem" }}>
          <RichTextEditor text={dynamicTextsDict["about"].text} />
        </div>
        {deviceMode === StyleMode.desktop && (
          <div
            style={{
              ...styles.zoomButtons,
              display: isSafariOs ? "none" : "flex",
            }}
          >
            <img
              src={!_isZoomInOver ? Images.zoomIn : Images.zoomInOver}
              onClick={() => zoom("in")}
              style={{ cursor: "pointer" }}
              onMouseOver={() => _setIsZoomInOver(true)}
              onMouseLeave={() => _setIsZoomInOver(false)}
            />
            <img
              src={!_isZoomOutOver ? Images.zoomOut : Images.zoomOutOver}
              onClick={() => zoom("out")}
              style={{ cursor: "pointer" }}
              onMouseOver={() => _setIsZoomOutOver(true)}
              onMouseLeave={() => _setIsZoomOutOver(false)}
            />
          </div>
        )}
        <div
          id={"mapContainer"}
          style={{
            ...styles.mapContainer,
            zoom: deviceMode === StyleMode.desktop ? "135%" : "",
            width: deviceMode === StyleMode.desktop ? "76rem" : "",
          }}
        >
          <div
            id={`Map`}
            aria-details={`${dynamicTextsDict["mapDescription"].text.replace(
              `X`,
              Date.now().toLocaleString()
            )}`}
            style={
              !_isPrinting
                ? {
                    ...styles.map,
                    zoom: deviceMode === StyleMode.desktop ? "120%" : "",
                  }
                : {}
            }
            dangerouslySetInnerHTML={{ __html: _svg }}
          ></div>
        </div>
        <div
          id="tooltip"
          style={{
            ...styles.tooltip,
            left: _mouseXPosition,
            top: _mouseYPosition,
          }}
        >
          {!_isLoadingTooltip ? (
            <>
              <div
                style={{
                  backgroundColor: "#f9f9f9",
                  padding: "1rem",
                  boxSizing: "border-box",
                }}
              >
                <h1
                  style={{
                    paddingInlineStart: "1rem",
                  }}
                >
                  {_currentStationName}
                </h1>
              </div>

              <Swiper
                slidesPerView={1}
                navigation={true}
                spaceBetween={deviceMode === StyleMode.desktop ? 60 : 30}
                pagination={{ clickable: true }}
                modules={[Pagination, Navigation]}
                className="swiper-ativity-times"
                style={{ padding: deviceMode === "desktop" ? "2rem" : "1rem" }}
                slideToClickedSlide={true}
              >
                {_tooltipInfo.map((item, index) => (
                  <SwiperSlide key={index}>
                    <ActivityTime
                      {...item}
                      type={"desktop"}
                      index={index}
                      key={_activityTimeKey}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              {dictStations &&
                dictStations[parseInt(_hoverStationId as string)] && (
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                      backgroundColor: colors.darkBlue,
                      borderRadius: "1rem",
                      boxShadow: "0 0 10px #00000026",
                      color: colors.white,
                    }}
                    onClick={() =>
                      redirectToPage(
                        `stationinfo&stationname=${_hoverStationId as string}`
                      )
                    }
                  >
                    {`${t("ToStationInfo", {
                      stationName: _currentStationName,
                    })}`}
                    {" >"}
                  </span>
                )}
            </>
          ) : (
            <Loading
              containerStyles={{
                minHeight: "25rem",
                height: "25rem",
              }}
              message={t("LoadingStationInfoDetails")}
            />
          )}
        </div>
      </div>
      <div
        id="printDiv"
        style={{
          maxHeight: "0px",
          maxWidth: "0px",
          visibility: "hidden",
          overflow: "hidden",
          // zoom: "20%",
        }}
        dangerouslySetInnerHTML={{ __html: _printSvg }}
      ></div>
    </Fragment>
  ) : (
    <Loading containerStyles={{ height: "30rem" }} />
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  } as React.CSSProperties,
  commonDestinations: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },
  link: {
    cursor: "pointer",
  },
  mapContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    zoom: "135%",
  },
  map: {
    border: `0.15rem solid ${colors.darkBlue}`,
    boxShadow: `0 5px 11px 0 ${colors.black}`,
    borderRadius: ".7rem",
    overflow: "hidden",
    // backgroundColor: colors.darkBlue,
    width: "100%",
    direction: "ltr",
  } as React.CSSProperties,
  printButton: {
    backgroundColor: colors.white,
    color: colors.darkBlue,
    border: "none",
    fontSize: fontSize.Text,
    cursor: "pointer",
    fontFamily: fonts.FbReformaMedium,
    display: "flex",
    gap: ".5rem",
    justifyContent: "center",
    alignItems: "center",
  } as React.CSSProperties,
  tooltip: {
    position: "fixed",
    width: "25rem",
    zIndex: "99999",
    color: colors.darkBlue,
    boxSizing: "border-box",
    zoom: "80%",
    minHeight: "20rem",
    borderRadius: "1rem",
    backgroundColor: colors.white,
    boxShadow: "0 0 10px #00000026",
    visibility: "hidden",
  } as React.CSSProperties,
  mapPin: {
    position: "absolute",
    scale: "1.8",
    zIndex: "99999",

    backgroundRepeat: "no-repeat",
    borderRadius: ".5rem",
    color: colors.darkBlue,
    width: ".7rem",
    height: "1rem",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
  } as React.CSSProperties,
  resizeButton: {
    backgroundColor: `transparent`,
    borderColor: `transparent`,
    cursor: `pointer`,
  } as React.CSSProperties,
  zoomButtons: {
    position: "fixed",
    left: "15%",
    display: "flex",
    gap: "1rem",
    zIndex: "10",
    top: "50%",
    scale: "1.5",
  },
};

export default LinesMap;
