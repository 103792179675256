import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import colors from "../../../../Assets/Colors/Colors.json";
import Config from "../../../../Configuration/server";
import Images from "../../../../Data/Images/Images";
import {
  IFileViewer,
  IFileViewerServer,
} from "../../Interfaces_tenders/IFileViewer";
import { StyleSheet } from "../../Interfaces_tenders/IStyleSheet";
import { IServerFile, IUmbracoFile } from "../../Interfaces_tenders/IUmbraco";
import { DownloadFilesAsZipFile } from "../../../../Services/FilesToZipService";
import { FilesToZipServiceServer } from "../../../../Services/FilesToZipServiceServer";
import { truncateText } from "../../../../Utils/helpers";
import ServerFile from "../UmbracoElements/File/ServerFile";
import UmbracoFile from "../UmbracoElements/File/UmbracoFile";
import Title from "../UmbracoElements/Title";

const FileViewerServer: React.FC<IFileViewerServer> = (props) => {
  const { title, files, curFileName } = props;
  const [_currentFile, _setCurrentFile] = useState("");
  const [_currenrIndex, _setCurrenrIndex] = useState(0);
  const [_filePreviewUrl, _setFilePreviewUrl] = useState("");
  const [_currentFileIndex, _setCurrentFileIndex] = useState(-1);
  const URL = Config.TENDERS_FILES;

  useEffect(() => {
    const selectedFile = document.getElementById("file_" + _currentFileIndex);
    if (selectedFile) {
      selectedFile.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [_currentFileIndex]);

  useEffect(() => {
    if (files.length > 0 && _currenrIndex > -1) {
      if (curFileName) {
        _setCurrenrIndex(files.findIndex((x) => x.filename === curFileName));
      }
      _setCurrentFile(URL + files[_currenrIndex].document);
      _setCurrentFileIndex(_currenrIndex);
      handleFilePreview(files[_currenrIndex]);
    } else {
      _setCurrentFile("");
      _setCurrentFileIndex(-1);
    }
  }, [_currentFile, _currenrIndex, curFileName, files]);

  const handleFilePreview = (file: IServerFile) => {
    let file_preview_url = URL + file.document;
    if (
      file_preview_url.includes(".xls") ||
      file_preview_url.includes(".xlsx") ||
      file_preview_url.includes("docx") ||
      file_preview_url.includes("doc") ||
      file_preview_url.includes("ppt")
    ) {
      file_preview_url = `https://view.officeapps.live.com/op/embed.aspx?src=${file_preview_url}`;
    }
    _setFilePreviewUrl(file_preview_url);
  };

  return (
    <div style={styles.container}>
      <div style={styles.titleAndFilesWrapper}>
        <Title text={t("filesTitle")} color="" />
        <div style={styles.files}>
          {files.map(
            (file, index) =>
              file && (
                <div
                  id={"file_" + index}
                  style={{
                    ...styles.fileName,
                    backgroundColor:
                      index === _currentFileIndex ? colors.gentleGray : "",
                  }}
                  key={index}
                >
                  <ServerFile
                    key={index}
                    {...file}
                    filenameStyles={{
                      fontSize: "1.4rem",
                    }}
                    onClick={() => {
                      if (file) {
                        handleFilePreview(file);
                        _setCurrentFileIndex(index);
                      }
                    }}
                    isSingleDownload={true}
                  />
                </div>
              )
          )}
        </div>
        <button
          style={styles.downloadAllButton}
          onClick={() => {
            const filesToDownload = [
              ...files.filter((file) => file.document !== null),
            ];
            FilesToZipServiceServer(filesToDownload, title);
          }}
        >
          {t("downloadAllFiles")}
        </button>
      </div>
      <div style={styles.pdfPreview}>
        {_currentFile && (
          <iframe
            title="content"
            src={`${_filePreviewUrl}#=zoomFitH`}
            width="100%"
            height="100%"
            style={{
              border: "none",
            }}
          />
        )}
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    height: "100%",
    width: "100%"
  },
  titleAndFilesWrapper: {
    display: "flex",
    flexDirection: "column",
    /* flex: 1,*/
    gap: "2rem",
    paddingTop: "5rem",
    paddingInlineStart: "5rem",
    paddingInlineEnd: "5rem",
  },
  files: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    gap: "2rem",
    /* maxHeight: "20rem",*/
    overflow: "auto",
  },
  downloadAllTitle: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    gap: "2rem",
    fontSize: "2rem",
  },
  title: {
    fontSize: "2rem",
  },

  pdfPreview: {
    height: "100%",
    flex: 6,
  },
  downloadAllButton: {
    backgroundColor: colors.darkBlue,
    color: colors.white,
    fontSize: "1rem",
    borderRadius: "16rem",
    borderColor: colors.darkBlue,
    minWidth: "8rem",
    height: "2.5rem",
    fontWeight: "bold",
    textAlign: "center",
    cursor: "pointer",
    marginBottom: "3rem",
  },
  fileName: {
    height: "3rem",
    display: "flex",
  },
};

export default FileViewerServer;
