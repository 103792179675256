import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import store from "store2";
import { ChangeTitle } from "../../../Utils/consts";

const FileElementPage: React.FC = () => {
  const [_file, _setFile] = useState<string>("");
  const location = useLocation();

  useEffect(() => {
    const file = store.session.get("fileElement");
    const fileName = store.session.get("fileElementName");
    if (file && fileName) {
      _setFile(file as string);
      ChangeTitle(fileName);
    }
  }, [location, _file]);

  return (
    <div
      style={{
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <iframe src={`${_file}#=zoomFitH`} width="100%" height="100%" />
    </div>
  );
};

export default FileElementPage;
