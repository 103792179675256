import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { IIntermediateDetails } from "../../../Interfaces/IPis";
import PisRouteItem from "./PisRouteItem/PisRouteItem";
import PisRouteItemEmptyLine from "./PisRouteItem/PisRouteItemEmptyLine";
import { Fragment } from "react";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import { StyleMode } from "../../../Utils/consts";

const PisRouteDetails: React.FC<
  IIntermediateDetails & { onClose: () => void }
> = (props) => {
  const { intermediateStations, currentTrainBasicDetails } = props;

  const deviceMode = useDeviceMode();

  return (
    <div
      style={{
        ...styles.routeContainer,
        padding:
          deviceMode === StyleMode.desktop ? "1.5rem 3.8rem" : "1.5rem 2.2rem",
      }}
    >
      {intermediateStations.map((station, index) => (
        <Fragment key={index}>
          <PisRouteItem
            {...station}
            isFirstItem={index === 0}
            isLastItem={index === intermediateStations.length - 1}
            trainNumber={currentTrainBasicDetails.trainNumber}
            destinationStationNumber={
              currentTrainBasicDetails.destinationStationNumber
            }
          />
          {index < intermediateStations.length - 1 && <PisRouteItemEmptyLine />}
        </Fragment>
      ))}
    </div>
  );
};

const styles: StyleSheet = {
  routeContainer: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    maxHeight: "35rem",
    margin: ".5rem",
  },
};

export default PisRouteDetails;
