import { Fragment, useRef, useState } from "react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import Images from "../../../Data/Images/Images";
import { IMemberModal } from "../../../Interfaces/IDirectorateMember";
import { IWorkerTalk } from "../../../Interfaces/IUmbraco";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import CustomModal from "../../CustomModal";
import InfoMember from "../../Modal/InfoMember";
import MemberShort from "../DirectorateMembers/MemberShort";

const WorkerTalk: React.FC<IWorkerTalk> = (props) => {
  const { title, workers } = props;
  const deviceMode = useDeviceMode();
  const [_isModalOpen, _setIsModalOpen] = useState(false);
  const [_selectedWorker, _setSelectedWorker] = useState<IMemberModal>();

  const prevRef = useRef(null);

  return (
    <Fragment>
      <div className="container">
        <h3 className="title">{title}</h3>
        <div className="row">
          <Swiper
            loop={true}
            loopFillGroupWithBlank={true}
            slidesPerView={
              deviceMode === StyleMode.desktop
                ? 4
                : deviceMode === StyleMode.mobile
                ? 2
                : 3
            }
            spaceBetween={30}
            navigation={true}
            modules={[Navigation]}
            className="mySwiper"
          >
            {workers.map((worker, index) => (
              <SwiperSlide key={index}>
                <MemberShort
                  {...worker}
                  onSelect={() => {
                    _setSelectedWorker(worker);
                      _setIsModalOpen(true);
                  }}
                  key={index}
                />
              </SwiperSlide>
            ))}

            <div
              className="arrows-main prev"
              style={{ backgroundImage: `url(${Images.directorateArrow})` }}
              ref={prevRef}
            />
          </Swiper>
        </div>
      </div>

            <CustomModal visible={_isModalOpen}
        onClose={() => _setIsModalOpen(false)}
        showCloseButton="internal"
        contentStyle={{ padding: 0 }}
      >
       {_selectedWorker && <InfoMember {..._selectedWorker} />}

      </CustomModal>
    </Fragment>
  );
};

export default WorkerTalk;