import { t } from "i18next";
import { Fragment, SetStateAction, useEffect, useRef, useState } from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { useSelector } from "react-redux";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import FromWhereMobile from "../../../Components/FromWhereMobile/FromWhereMobile";
import Loading from "../../../Components/Loading/Loading";
import NoTrains from "../../../Components/NoTrains/NoTrains";
import RouteDetails from "../../../Components/RouteDetails/RouteDetails";
import SearchResultsButtonsMobile from "../../../Components/SearchResultsButtons/Mobile/SearchResultsButtonsMobile";
import TimeCapsuleMobile from "../../../Components/TimeCapsule/Mobile/TimeCapsuleMobile";
import TrainCardMobile from "../../../Components/TrainCard/Mobile/TrainCardMobile";
import Images from "../../../Data/Images/Images";
import { IRoutePlanSearchResultsMobile } from "../../../Interfaces/IRoutePlanSearchResults";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { ITravel } from "../../../Interfaces/ITravel";
import { ScheduleState } from "../../../Redux/Slices/scheduleSlice";
import { UmbracoStoreState } from "../../../Redux/Slices/umbracoSlice";
import i18n from "../../../Services/i18n";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import RoutePlanSearchResultsMoreActions from "./RoutePlanSearchResultsMoreActions";
// import { createEvent, EventAttributes } from "ics";
import moment from "moment";
import { FiFilter } from "react-icons/fi";
import store from "store2";
import TransitTransportPage from "../../../Components/ComplimentaryTransport/TransitTransportPage";
import CustomModal from "../../../Components/CustomModal";
import { IFromTO } from "../../../Components/PaymentAndDiscounts/CapsuleFromTo";
import Fare from "../../../Components/PaymentAndDiscounts/Fare";
import RoutePlanFilterBar from "../../../Components/RoutePlanBar/RoutePlanFilterBar";
import {
  linkBuilder,
  linkShareFormat,
} from "../../../Components/SharePage/ShareOptions";
import SocialShare from "../../../Components/SocialShare/SocialShare";
import Title from "../../../Components/UmbracoElements/Title";
import Config from "../../../Configuration/server";
import { ITrain } from "../../../Interfaces/ITrain";
import { SystemParamsState } from "../../../Redux/Slices/systemParamsSlice";
import {
  FormatDateYYYYMMDDTHHMM,
  GetDurationFormattedAsNumber,
  GetDurationTimeMS,
  GetDurationTimeMSStringType
} from "../../../Services/DateService";
import { Translations } from "../../../Services/GlobalService";
import { GetStationsDictionary } from "../../../Utils/helpers";
import RouteToCalendar from "../RouteToCalendar";

export const maxChagnes = (data: ITravel[]) => {
  const maxChanges: any = data?.reduce((prev, current) => {
    if (current.trains.length - 1 > prev) {
      prev = current.trains.length - 1;
      return prev;
    }
    return prev;
  }, 0);
  return maxChanges;
};
export interface IRangeDurationCalc {
  value: string;
  label: number;
}
export const rangeDurationCalc = (data: ITravel[]): IRangeDurationCalc[] => {
  const allDuration =
    data &&
    data.map((train: ITravel) => {
      const durationInMs = GetDurationTimeMS(
        new Date(train.departureTime),
        new Date(train.arrivalTime)
      );

      return {
        value: GetDurationFormattedAsNumber(durationInMs),
        label: GetDurationTimeMS(
          new Date(train.departureTime),
          new Date(train.arrivalTime)
        ),
      };
    });
  return allDuration;
};

export const travelDurations = (data: IRangeDurationCalc[]): number[] => {
  return Array.from(
    new Set(
      data
        .map((dur) => dur.value)
        .map(Number)
        .sort()
        .filter((item: number) => item > 0)
    )
  );
};

const RoutePlanSearchResultsMobile: React.FC<IRoutePlanSearchResultsMobile> = (
  props
) => {
  const {
    travels,
    allTravels,
    isLoading,
    isNoTrains,
    selectedTravel,
    noTrainsInfo,
    selectedTrainKey,
    onClickTravelCard,
    getDay,
    isToday,
    onSortClick,
    onFilterClick,
    onFilterClear,
  } = props;

  const [_isRouteDetailsVisible, _setIsRouteDetailsVisible] = useState(false);
  const [_isActionsVisible, _setIsActionsVisible] = useState(false);
  const [_moreActionsPositionX, _setMoreActionsPositionX] = useState(0);
  const [_moreActionsPositionY, _setMoreActionsPositionY] = useState(0);
  const [_isPurchaseModalOpen, _setIsPurchaseModalOpen] = useState(false);
  const [_isFromTo, _setFromTO] = useState<IFromTO>({
    fromStation: 0,
    toStation: 0,
  });
  const [_travelsToDisplay, _setTravelsToDisplay] =
    useState<ITravel[]>(travels);
  const systemParams = (
    useSelector((state: any) => state.SystemParamsReducer) as SystemParamsState
  ).systemParams;
  const [_selectedTrainToShare, _setSelectedTrainToShare] = useState<ITrain>();
  const [_selectedTrainToCalendar, _setSelectedTrainToCalendar] =
    useState<ITrain>();
  const [_isDelayInfoVisible, _setIsDelayInfoVisible] = useState(false);
  const [_isAddCalendarModalVisible, _setIsAddCalendarModalVisible] =
    useState(false);
  const [_mobileFilterIsOpen, _setMobileFilterIsOpen] = useState(false);

  const closeRouteDetailsButtonRef = useRef<HTMLImageElement>(null);
  const closeTransitDetailsButtonRef = useRef<HTMLImageElement>(null);
  const Translation = Translations[
    i18n.language as `he` | `en` | `ar` | `ru`
  ] as any;

  const deviceMode = useDeviceMode();

  interface IDragInfo {
    x: number;
    y: number;
    time: number;
  }

  const [_dragInfo, _setDragInfo] = useState<IDragInfo>();
  const [_isNotificationListVisible, _setIsNotificationListVisible] =
    useState(false);

  const [_isShareModalVisible, _setIsShareModalVisible] = useState(false);
  const [_isTransitModalVisible, _setIsTransitModalVisible] = useState(false);

  const [_currentNotificationTrainIndex, _setCurrentNotificationTrainIndex] =
    useState(-1);

  const [_transitTime, _setTransitTime] = useState(
    _selectedTrainToShare && _selectedTrainToShare.arrivalTime
  );

  const EARLY_QUERY = -29;
  const LATE_QUERY = 240;
  const [_earlyQuery, _setEarlyQuery] = useState(false);
  const [_lateQuery, _setLateQuery] = useState(false);
  const [_durationEarlyTime, _setDurationEarlyTime] = useState();
  const [_durationLateTime, _setDurationLateTime] = useState(false);

  const durationEarlyTime =
    _transitTime && GetDurationTimeMSStringType(_transitTime, new Date());

  const durationLateTime =
    _transitTime && GetDurationTimeMSStringType(_transitTime, new Date());

  useEffect(() => {
    if (_travelsToDisplay.length > 1) {
      _setEarlyQuery(false);
      _setLateQuery(false);
      _setIsTransitModalVisible(false);
    } else {
      if (
        (durationLateTime && durationLateTime > LATE_QUERY) ||
        (durationEarlyTime && durationEarlyTime < EARLY_QUERY)
      ) {
        if (durationLateTime && durationLateTime > LATE_QUERY) {
          _setLateQuery(true);
          _setEarlyQuery(false);
          // alert("late")
        }
        if (durationEarlyTime && durationEarlyTime < EARLY_QUERY) {
          _setEarlyQuery(true);
          _setLateQuery(false);
          // alert("early")
        }
      }
    }
  }, [_transitTime, _travelsToDisplay]);



  const schedule = useSelector(
    (state: any) => state.ScheduleReducer
  ) as ScheduleState;

  const handleOpenRouteDetails = () => {
    _setIsRouteDetailsVisible(true);
  };


  const transitTimeHandler = (st: SetStateAction<string | undefined>) => {
    _setTransitTime(st);
  };

  const handleMobileFilterToggle = () => {
    _setMobileFilterIsOpen(!_mobileFilterIsOpen);
  };
  const getShareTravelUrl = (url: string) => {
    let allStations = store.session.get(
      `${Config.BASE_LOCALSTORAGE_NAME}stations`
    );
    let Stations: any = GetStationsDictionary(
      allStations ? allStations[i18n.language] : []
    );
    let fromStation = schedule.fromStation
      ? Stations[schedule.fromStation].stationName
      : "";
    let toStation = schedule.toStation
      ? Stations[schedule.toStation].stationName
      : "";
    if (_selectedTrainToShare) {
      const date = moment(_selectedTrainToShare.departureTime).toDate();
      const dateFormat = moment(date).format("yyyyMMDD");

      const hourNumber = date.getHours();
      const minuteNumber = date.getMinutes();
      const hours = hourNumber <= 9 ? `0${hourNumber}` : hourNumber.toString();
      const minutes =
        minuteNumber <= 9 ? `0${minuteNumber}` : minuteNumber.toString();

      const description = `${Translation["travel by train"]} ${Translation["from station"]} ${fromStation} ${Translation["to station"]} ${toStation}%0A`;
      const andTime = `${t("date")}: ${moment(date).format("yyyy-MM-DD")}%0A${t(
        "HourText"
      )}: ${hours}:${minutes}%0A`;

      const navigateUrl = `${window.location.href.split("/?")[0]}/mldp?FSID=${schedule.fromStation
        }&TSID=${schedule.toStation
        }&Date=${dateFormat}&Hour=${hours}${minutes}&Train=${_selectedTrainToShare.trainNumber
        }`.replaceAll("&", "%26");
      linkShareFormat.title = description;
      linkShareFormat.subject = t("sharedWithYou") + " " + description;
      linkShareFormat.body = encodeURIComponent(
        description + andTime + "" + navigateUrl
      );
      linkShareFormat.summary = encodeURIComponent(description + andTime);
      linkShareFormat.text = encodeURIComponent(
        description + andTime + navigateUrl
      );
      linkShareFormat.quote = description;
      linkShareFormat.url = encodeURIComponent(navigateUrl);
      linkShareFormat.u = encodeURIComponent(navigateUrl);
      let newLink = linkBuilder(url, linkShareFormat);
      return decodeURI(newLink);
    }
    return "";
  };

  let handleDragStart = (e: DraggableEvent, data: DraggableData) => {
    _setDragInfo({
      x: data.x,
      y: data.y,
      time: Date.now(),
    });
  };

  let handleDragStop = (e: any, data: DraggableData) => {
    if (!_dragInfo) return;

    _setMoreActionsPositionX(data.x);
    _setMoreActionsPositionY(data.y);
    let change = {
      x: Math.abs(data.x - _dragInfo.x),
      y: Math.abs(data.y - _dragInfo.y),
      time: Date.now() - _dragInfo.time,
    };
    if (change.x + change.y <= 10 && change.time < 300) e.srcElement?.click?.();
  };

  useEffect(() => {
    if (_isRouteDetailsVisible) {
      closeRouteDetailsButtonRef?.current?.focus();
    }
  }, [_isRouteDetailsVisible]);

  useEffect(() => {
    if (_isTransitModalVisible) {
      closeTransitDetailsButtonRef?.current?.focus();
    }
  }, [_isTransitModalVisible]);

  const isSafariOs = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isSafariAndWebMode = isSafariOs && deviceMode === "DESKTOP";

  const [_isIos, _setIsIos] = useState(isSafariOs && !isSafariAndWebMode);

  const umbracoStore = (
    useSelector((state: any) => state.UmbracoStoreReducer) as UmbracoStoreState
  ).umbracoStore;

  useEffect(() => {
    let allTravels = [...travels];
    if (_currentNotificationTrainIndex === -1) {
      _setTravelsToDisplay(
        allTravels.map((travel) => {
          return { ...travel, isNotificationVisible: false };
        })
      );
    } else {
      _setTravelsToDisplay(
        allTravels
          .filter((train, index) => index === _currentNotificationTrainIndex)
          .map((travel) => {
            return {
              ...travel,
              isNotificationVisible: true,
            };
          })
      );
    }

    transitTimeHandler(
      _selectedTrainToShare && _selectedTrainToShare.arrivalTime
    );
  }, [_currentNotificationTrainIndex, travels]);

  useEffect(() => {
    _setIsIos(isSafariOs && !isSafariAndWebMode);
  }, [isSafariAndWebMode]);
  const travelsContainerNotIos = isSafariOs
    ? {}
    : styles.travelsContainerNotIos;
  const nextPreviousContainer = isSafariOs
    ? { paddingTop: "1rem" }
    : { paddingTop: "4rem" };

  return (
    <Fragment>
      <CustomModal visible={_isPurchaseModalOpen} icon={umbracoStore?.popUpFares?.icon._url
        ? umbracoStore.popUpFares.icon._url
        : Images.fares}
        innerStyle={{ minHeight: "90%" }}
        contentStyle={{ padding: 0 }}
        onClose={() => {
          _setIsPurchaseModalOpen(false);
        }} showCloseButton="external" position={"bottom"} >
        <Fare
          title={umbracoStore.popUpFares?.title}
          fromStation={_isFromTo.fromStation}
          toStation={_isFromTo.toStation}
        />
      </CustomModal>

      <div style={styles.mainWrapper}>
        <div
          style={styles.cover}
          className={"blurred-container-mobile"}
        >
          <div style={styles.blurryCover}>
            <div
              style={{
                ...styles.searchResultsButtonsMobile,
                padding: "1rem .5rem",
                boxSizing: "border-box",
              }}
            >
              <SearchResultsButtonsMobile />
            </div>
            <FromWhereMobile />
          </div>
          <div style={{ position: "absolute", bottom: 0, width: "100%", zIndex: 1 }}> <TimeCapsuleMobile /></div>
        </div>
        <div style={{
          position: "relative",
          overflowY: "auto",
          display: "flex",
          height: "85vh",
          flexDirection: "column",
          gap: "2rem"
        }}>

          {isLoading ? (
            <Loading
              message="Loading-schedule"
              containerStyles={{
                gap: "",
                alignItems: "center",
                justifyContent: "center",
                flex: 1,
                minHeight: "unset"
              }}
            />
          ) : (
            <Fragment>
              {!isNoTrains ? (
                <div
                  style={{
                    ...styles.travelsContainer,
                    ...travelsContainerNotIos,
                    minHeight: "",
                  }}
                  id="travelsContainer"
                >
                  {!isToday && !_isNotificationListVisible ? (
                    <div style={styles.topSearchResultContainer}>
                      <div
                        style={{
                          ...styles.nextPreviousContainer,
                          ...nextPreviousContainer,
                        }}
                        onClick={() => getDay("searchTrainLuzBeforeDate")}
                      >
                        <button
                          style={styles.nextPrevious}
                          role={"button"}
                          aria-label={t("View-previous-day")}
                        >
                          {t("View-previous-day")}
                        </button>
                        <img
                          src={Images.arrowUp}
                          style={styles.arrowIcon}
                          width={`auto`}
                          height={`auto`}
                          alt={``}
                        />
                      </div>
                      {systemParams.find(
                        (item) => item.key === "showRouteResultFilter"
                      )?.value === "true" && (
                          <div
                            onClick={handleMobileFilterToggle}
                            style={styles.filterIconContainer}
                          >
                            <FiFilter />
                          </div>
                        )}
                    </div>
                  ) : (
                    <div
                      style={nextPreviousContainer}
                    />
                  )}
                  {_travelsToDisplay.map((train, key) => (
                    <div
                      key={train.arrivalTime + train.departureTime + key}
                      style={styles.trainCard}
                      onClick={() => {
                        onClickTravelCard(train);
                        handleOpenRouteDetails();
                      }}
                      onKeyPressCapture={() => {
                        onClickTravelCard(train);
                        handleOpenRouteDetails();
                      }}
                    >
                      <TrainCardMobile
                        {...train.trains[0]}
                        travelMessages={train.travelMessages}
                        arrivalTime={train.arrivalTime}
                        departureTime={train.departureTime}
                        changes={train.trains.length - 1}
                        isSelected={
                          `${train.trains[0].arrivalTime}-${train.trains[0].departureTime}` ===
                          selectedTrainKey
                        }
                        lastDestanation={
                          train.trains[train.trains.length - 1].routeStations[
                            train.trains[train.trains.length - 1].routeStations
                              .length - 1
                          ].stationId
                        }
                        index={key}
                        totalTrains={travels.length}
                        trainPosition={train.trains[0].trainPosition}
                        onPurchaseButtonClick={() => {
                          _setFromTO({
                            fromStation: train.trains[0].orignStation,
                            toStation:
                              train.trains[train.trains.length - 1]
                                .destinationStation,
                          });
                          _setIsPurchaseModalOpen(true);
                        }}
                        onNotificationButtonClick={(
                          trainIndex,
                          selectedTrain
                        ) => {
                          _setSelectedTrainToShare(selectedTrain);
                          _setSelectedTrainToCalendar(selectedTrain);
                          _setIsNotificationListVisible(
                            !_isNotificationListVisible
                          );
                          if (!_isNotificationListVisible) {
                            _setCurrentNotificationTrainIndex(trainIndex);
                          } else {
                            _setCurrentNotificationTrainIndex(-1);
                          }
                        }}
                        isNotificationVisible={train.isNotificationVisible}
                        handleDelayInfoModal={_setIsDelayInfoVisible}
                      />
                    </div>
                  ))}
                  {_isNotificationListVisible && (
                    <div style={styles.notificationsContainer}>
                      {/* <div
                        style={styles.notificationRow}
                        onClick={handleAddToCalendar}
                      >
                        <img src={Images.calendarShare} width={"20"} />
                        <span>{t("AddTravelToCalendar")}</span>
                      </div> */}
                      <div style={styles.notificationRow}>
                        <img src={Images.calendarShare} width={"20"} />
                        <span
                          onClick={() => {
                            _setIsAddCalendarModalVisible(true);
                            _setSelectedTrainToCalendar(_selectedTrainToShare);
                          }}
                        >
                          {t("AddToCalendarTitle")}
                        </span>
                      </div>
                      <div
                        style={styles.notificationRow}
                        onClick={(e) => {
                          e.stopPropagation();
                          _setIsShareModalVisible(true);
                        }}
                      >
                        <img src={Images.shareBlue} width={"20"} />
                        <span>{t("ShareTravel")}</span>
                      </div>
                      <div
                        style={styles.notificationRow}
                        onClick={(e) => {
                          e.stopPropagation();
                          _setIsTransitModalVisible(true);
                        }}
                      >
                        <img src={Images.transitDetails} width={"20"} />
                        <span> {t("BusesFromTheDestinationStation")}</span>
                      </div>
                    </div>
                  )}
                  {!_isActionsVisible && !_isNotificationListVisible && (
                    <Draggable
                      onStart={handleDragStart}
                      onStop={handleDragStop}
                      position={{
                        x: _moreActionsPositionX,
                        y: _moreActionsPositionY,
                      }}
                    >
                      <div
                        style={styles.moreActionsCircleIcon}
                        onClick={() => {
                          _setIsActionsVisible(true);
                        }}
                      >
                        <img
                          src={Images.moreActionsCircle}
                          width={`auto`}
                          height={`auto`}
                          alt={``}
                        />
                        <img
                          src={Images.moreActionsDots}
                          style={styles.moreActionsDotsIcon}
                          width={`auto`}
                          height={`auto`}
                          alt={``}
                        />
                      </div>
                    </Draggable>
                  )}
                  {!_isNotificationListVisible && (
                    <div
                      style={{ ...styles.nextPreviousContainer }}
                      onClick={() => getDay("searchTrainLuzAfterDate")}
                    >
                      <button
                        style={styles.nextPrevious}
                        role={"button"}
                        aria-label={t("View-next-day")}
                      >
                        {t("View-next-day")}
                      </button>
                      <img
                        src={Images.arrowDown}
                        style={styles.arrowIcon}
                        width={`auto`}
                        height={`auto`}
                        alt={``}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div
                  style={styles.noTrainsContainer}
                >
                  <NoTrains
                    clientMessageId={noTrainsInfo.clientMessageId}
                    lastTrainDate={noTrainsInfo.lastTrainDate}
                    nextTrainDate={noTrainsInfo.nextTrainDate}
                    onClick={noTrainsInfo.onClick}
                    messageTitle={noTrainsInfo.messageTitle}
                    shareStatus={noTrainsInfo.shareStatus}
                  />
                </div>
              )}
            </Fragment>
          )}
        </div>
      </div>
      <CustomModal visible={_isRouteDetailsVisible} id="routeDetailsContainer"
        contentStyle={{ alignItems: "unset" }} showCloseButton="external" onClose={() => {
          _setIsRouteDetailsVisible(false);
          _setIsActionsVisible(false);
        }} position="bottom">
        <RouteDetails
          travel={selectedTravel}
          routeDetailsBodyStyles={{
            margin: "1rem",
            overflowY: "auto",
          }}
        />
      </CustomModal>
      <CustomModal visible={_isActionsVisible} innerStyle={{ paddingBottom: 0 }} contentStyle={{ alignItems: "unset", padding: 0 }}
        label={t("Actions")}
        icon={Images.threeDots} showCloseButton="external" onClose={() => {
          _setIsActionsVisible(false);
        }} position="bottom">
        {schedule.fromStation && schedule.toStation && (
          <RoutePlanSearchResultsMoreActions
            fromStation={schedule.fromStation}
            toStation={schedule.toStation}
          />
        )}
      </CustomModal>

      <CustomModal
        visible={_isShareModalVisible}
        onClose={() => _setIsShareModalVisible(false)}
        typeStyle={"rectangle"}
        icon={Images.share}
        title={t("Share")}
        position={"bottom"}
      >
        <SocialShare items={[
          {
            name: t("Facebook"),
            icon: Images.facebookHeader,
            link: getShareTravelUrl(
              `https://www.facebook.com/sharer/sharer.php?u=`
            ),
            ariaLabel: t("ShareFacebook"),
          },
          {
            name: t("WhatsApp"),
            icon: Images.whatsapp,
            link: getShareTravelUrl(`https://wa.me/?text=`),
            ariaLabel: t("ShareWhatsapp"),
          },
          {
            name: t("Mail"),
            icon: Images.shareMail,
            link: getShareTravelUrl(`mailto:?subject=&body=`),
            ariaLabel: t("ShareEmail"),
          },
          {
            name: t("Linkedin"),
            icon: Images.linkedinHeader,
            link: getShareTravelUrl(
              `https://www.linkedin.com/sharing/share-offsite/?url=`
            ),
            ariaLabel: t("ShareLinkedin"),
          },
        ]} />
        <button
          className="btn-modal"
          onClick={() => _setIsShareModalVisible(false)}
          style={{ textAlign: "center", backgroundColor: "#F0F0F0" }}
        >
          {t("Cancel")}
        </button>
      </CustomModal>

      {_isTransitModalVisible && <CustomModal visible={_earlyQuery || _lateQuery}
        innerStyle={{ width: "80%" }}
        icon={Images.error}
        title={_earlyQuery ? t("rideOver") : t("rideNotStat")}
        description={_earlyQuery ? t("pastBus") : t("futureBus")}
        textButton={t("closeBusModal")}
        onClickButton={() => _setIsTransitModalVisible(false)}
        disabledClose={true} />}

      <CustomModal visible={_isTransitModalVisible && !_lateQuery && !_earlyQuery} id="routeDetailsContainer"
        contentStyle={{ alignItems: "unset" }} icon={Images.bus} showCloseButton="external" onClose={() => {
          _setIsTransitModalVisible(false);
          _setIsActionsVisible(false);
        }} position="bottom">

        <TransitTransportPage
          stationId={schedule.toStation}
          typeOfPage="transitInfo"
          startTime={_transitTime}
          onClick={() =>
            transitTimeHandler(FormatDateYYYYMMDDTHHMM(new Date()))
          }
        />
      </CustomModal>

      <CustomModal visible={_isDelayInfoVisible}
        innerStyle={{ width: "80%" }}
        showCloseButton="external"
        icon={Images.info_mobile}
        onClose={() => _setIsDelayInfoVisible(false)} >
        <h3
          style={{
            textAlign: "center",
            lineHeight: "1.5rem",
            color: colors.darkBlue,
          }}
        >
          {t("delayInfo")}
        </h3></CustomModal>
      <CustomModal visible={_mobileFilterIsOpen}
        position="bottom"
        showCloseButton="external"
        onClose={() => handleMobileFilterToggle()} >
        <div style={styles.containerStyles}>
          <div style={styles.formElementsContainerMobile}>
            <div style={styles.mobileTitleAndClear}>
              <div style={styles.mobileHeaderFIlter}>
                <Title
                  text={"title"}
                  titleStyles={{
                    display: "flex",
                    fontSize: "1.5625rem",
                    fontFamily: fonts.FbReformaMedium,
                    color: colors.darkBlue,
                    borderRight: `1px solid ${colors.koromiko}`,
                    borderRightWidth: ".2rem",
                    paddingRight: ".3rem",
                  }}
                  isUnderline={false}
                />
                <div
                  style={styles.clearMobile}
                // onClick={handleUrlParams}
                >
                  {"clear"}
                </div>
              </div>
            </div>
            {systemParams.find(
              (item) => item.key === "showRouteResultFilter"
            )?.value === "true" &&
              (allTravels &&
                allTravels.length > 0) && (
                <div style={styles.mobileFilterInputes}>
                  <RoutePlanFilterBar
                    maxChanges={maxChagnes(allTravels ? allTravels : [])}
                    rangeDuration={rangeDurationCalc(
                      allTravels ? allTravels : []
                    )}
                    onSortClick={onSortClick}
                    onFilterClick={onFilterClick}
                    onFilterClear={onFilterClear}
                    isMobile={true}
                  />
                </div>
              )}
            <div style={styles.searchButtonMobileContainer}>
              <button
                style={styles.searchButtonMobile}
              >
                {"fil"}
              </button>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal visible={_isAddCalendarModalVisible}
        contentStyle={{ padding: 0 }}
        onClose={() => {
          _setIsAddCalendarModalVisible(false);
        }} showCloseButton="external"  >
        {_selectedTrainToCalendar && (
          <>
            <RouteToCalendar
              {..._selectedTrainToCalendar}
              toStation={schedule.toStation}
              fromStation={schedule.fromStation}
            />
          </>
        )}
      </CustomModal>
    </Fragment>
  );
};

const styles: StyleSheet = {
  mainWrapper: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  cover: {
    background: `30% 80% url(${Images.coverImage}) no-repeat`,
    backgroundSize: "cover",
    width: "100%",
    height: "25vh",
    position: "relative",
  },
  blurryCover: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backdropFilter: "brightness(.4)",
    WebkitBackdropFilter: "brightness(.4)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  travelsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    overflow: "auto",
    paddingBlock: "2rem"
  },
  travelsContainerNotIos: {
    paddingBottom: "2.5rem",
    //minHeight: "100vh",
  },
  trainCard: {
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  },
  nextPreviousContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  nextPrevious: {
    color: colors.darkCyan,
    cursor: "pointer",
    border: "none",
    backgroundColor: "transparent",
    fontSize: fontSize.Paragraph,
  },
  arrowIcon: {
    cursor: "pointer",
  },
  searchResultsButtonsMobile: {
    position: "absolute",
    top: "0",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    zIndex: "10",
  },
  moreActionsCircleIcon: {
    position: "fixed",
    bottom: "25%",
    left: "0",
    zIndex: "8",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    // scale: "1.5",
  },
  moreActionsDotsIcon: {
    position: "absolute",
  },
  noTrainsContainer: {
    justifyContent: "center",
    display: "flex",
    flex: 1
  },
  notificationsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  notificationRow: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    padding: "0 3rem",
    cursor: "pointer",
    // paddingInlineStart: "1.5rem",
  },
  filterIconContainer: {
    display: "flex",
    gap: "0.5rem",
    alignItems: "flex-end",
    color: "rgb(0, 157, 214)",
    fontSize: "1.5rem",
  } as React.CSSProperties,
  topSearchResultContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: "1rem",
  },
  containerStyles: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.white,
    width: "100%",
    padding: "2rem",
    boxSizing: "border-box",
    borderRadius: "2.6rem",
    height: "100%",
  },
  formElementsContainerMobile: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  } as React.CSSProperties,

  mobileTitleAndClear: {
    flex: "2",
  } as React.CSSProperties,

  mobileHeaderFIlter: {
    display: "flex",
    minHeight: "5vh",
    width: "100%",
    justifyContent: "center",
  } as React.CSSProperties,

  clearMobile: {
    fontFamily: fonts.FbReformaRegular,
    fontSize: "1rem",
    textDecoration: "underline",
    color: colors.darkCyan,
    cursor: "pointer",
  } as React.CSSProperties,

  mobileFilterInputes: {
    flex: "15",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1rem",
  } as React.CSSProperties,

  searchButtonMobileContainer: {
    flex: "3",
    display: "flex",
    justifyContent: "center",
  } as React.CSSProperties,

  searchButtonMobile: {
    width: "100%",
    height: "2.5rem",
    backgroundColor: colors.darkBlue,
    border: `1px solid ${colors.darkBlue}`,
    borderRadius: "1.25rem",
    color: colors.white,
    fontFamily: fonts.FbReformaMedium,
    fontSize: "1rem",
    letterSpacing: "0.029375rem",
    cursor: "pointer",
  } as React.CSSProperties,
};

export default RoutePlanSearchResultsMobile;
