import { useState } from "react";
import colors from "../Assets/Colors/Colors.json";
import fonts from "../Assets/Fonts/Fonts.json";
import QuestionsAndAnswers from "../Components/UmbracoElements/QuestionsAndAnswers/QuestionsAndAnswers";
import { StyleSheet } from "../Interfaces/IStyleSheet";
import fontSize from "../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../Services/DeviceService";
import i18n from "../Services/i18n";

const Entitled: React.FC<any> = (props) => {
  const [_currentItemIndex, _setCurrentItemIndex] = useState(0);
  const [value, setValue] = useState("");
  const [_isArrowAnimation, _setIsArrowAnimation] = useState(true);

  const onCheck = () => {
    // value
  };
  return (
    <div style={{ overflow: "hidden" }}>
      <div
        style={{
          ...styles.mainHeader,
          backgroundImage: `url(${props.coverImage._url})`,
        }}
      >
        <div style={styles.cover}>
          <div style={{ ...styles.maxWidth, width: "100%" }}>
            <h1 tabIndex={!IsMobile() ? 0 : -1} style={{ color: "white", fontSize: fontSize.CareerTitle }}>
              {props.title?.text}
            </h1>
          </div>
        </div>
      </div>

      <div style={styles.maxWidth}>
        <div style={{ flex: 1, ...styles.column, gap: "2rem" }}>
          <div
            style={{
              borderBottom: "1px solid #00458A",
              paddingBottom: "2rem",
              flex: 1,
              ...styles.column,
              gap: "1rem",
            }}
          >
            <h2 tabIndex={!IsMobile() ? 0 : -1} style={styles.title}>
              {"דף לבדיקת"}
              <br />
              <span style={styles.inTitle}>{"זכאות ערך צבור"}</span>
            </h2>
            <p
              tabIndex={!IsMobile() ? 0 : -1}
              style={styles.description}
            >{`דף לבדיקת זכאות ערך צבור דף זה מאפשר לבדוק אם אתה זכאי לערך צבור לפי נתוני
 הרב קו על פי מספר הרב קו`}</p>
          </div>
          <div
            style={{
              borderRadius: "50px",
              overflow: "hidden",
              display: "flex",
              alignSelf: "center",
              border: "thin solid",
              maxWidth: "28rem",
              width: "100%",
              backgroundColor: "white",
            }}
          >
            <input
              onChange={(e) => {
                if (/^\d*$/.test(e.target.value)) {
                  setValue(e.target.value);
                }
              }}
              value={value}
              style={{
                border: "0px",
                padding: "0.5rem 1rem",
                outline: "0",
                flex: 1,
                fontSize: fontSize.FooterItem,
                color: "var(--darkBlue)",
                fontFamily: "FbReforma-Medium, HelveticaNeue-Bold",
                fontWeight:`${i18n.language==='ar'?`600`:``}`,

              }}
              placeholder={"מספר רב קו"}
            />
            <button
              onClick={onCheck}
              disabled={value.length === 0}
              style={{
                cursor: "pointer",
                backgroundColor: "var(--koromiko)",
                border: "0px",
                fontSize: fontSize.Text,
                padding: "1rem",
                fontFamily: "FbReforma-Medium, HelveticaNeue-Bold",
                fontWeight:`${i18n.language==='ar'?`600`:``}`,
                color: "var(--darkBlue)",
                textAlign: "center",
              }}
            >
              {"בדיקה"}
            </button>
          </div>
        </div>

        <QuestionsAndAnswers
          title="מה זה ערך צבור"
          questionsAndAnswers={[
            {
              question: "מה זה המרת ערך צבור?",
              answer: `פעולת המרת ערך צבור מאפשרת המרה של ערך צבור מפרופיל אחד או יותר לפרופיל אחר.`,
            },
            {
              question: "מה זה המרת ערך צבור?",
              answer: `פעולת המרת ערך צבור מאפשרת המרה של ערך צבור מפרופיל אחד או יותר לפרופיל אחר.`,
            },
            {
              question: "מה זה המרת ערך צבור?",
              answer: `פעולת המרת ערך צבור מאפשרת המרה של ערך צבור מפרופיל אחד או יותר לפרופיל אחר.`,
            },
            {
              question: "מה זה המרת ערך צבור?",
              answer: `פעולת המרת ערך צבור מאפשרת המרה של ערך צבור מפרופיל אחד או יותר לפרופיל אחר.`,
            },
          ]}
        />
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  wrap: {
    display: "flex",
    flexWrap: "wrap",
  },
  bgGray: {
    backgroundColor: "#F9F9F9",
  },
  title: {
    fontSize: fontSize.PageTitle,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaLight,
    lineHeight: "3rem",
    margin: 0,
  },
  subtitle: {
    fontSize: fontSize.H1,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaLight,
    margin: 0,
  },
  info: {
    fontSize: fontSize.Paragraph,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaLight,
    margin: 0,
  },
  inTitle: {
    fontSize: fontSize.PageTitle,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
  },
  mainHeader: {
    minHeight: "250px",
    display: "flex",
    height: "100vh",
    maxHeight: "28rem",
    backgroundSize: "cover",
    backgroundPosition: "100%",
  },
  cover: {
    backgroundColor: "rgba(0, 70, 143, 0.5)",
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  maxWidth: {
    maxWidth: "68rem",
    margin: "auto",
    paddingBlock: "2rem",
    paddingInline: "1rem",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  btn: {
    borderRadius: "60px",
    backgroundColor: "rgb(249, 186, 77)",
    border: "0px",
    fontSize: fontSize.Text,
    padding: "1rem",
    fontFamily: fonts.FbReformaMedium,

    width: "16rem",
  },
  btnOutline: {
    backgroundColor: "transparent",
    color: colors.darkBlue,
    border: "1px solid",
  },
  wrapperInfo: {
    flex: "1.5 1 0%",
    flexDirection: "column",
    gap: "1rem",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    textAlign: "center",
  },
  infoBlue: {
    fontSize: fontSize.CareerElements,
    fontFamily: fonts.FbReformaMedium,
    lineHeight: "normal",
    color: colors.darkBlue,
  },
  name: {
    fontSize: fontSize.H1,
    color: "rgb(249, 186, 77)",
  },
  lineDivider: {
    width: 1,

    backgroundColor: colors.darkBlue,
  },
  description: {
    fontFamily: fonts.FbReformaLight,
    color: colors.darkBlue,
    fontSize: fontSize.UmbracoElementTitle,
    letterSpacing: "0.023rem",
    lineHeight: "2rem",
  },
  cardJob: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0 2px 23px 0 rgb(0 0 0 / 8%)",

    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    flex: 1,
  },
  tags: {
    display: "flex",
    flexWrap: "wrap",
  },
  tag: {
    border: "1px solid #00458a",
    borderRadius: "50px",
    minWidth: "4.4rem",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#D8F2FC",
    textAlign: "center",
    display: "inline-flex",
  },
  linkMoreInfo: {
    color: "#009DD6",
    fontSize: fontSize.Paragraph,
    letterSpacing: "0.55px",
    lineHeight: "24px",
  },
  whyToWork: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  whyTitle: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.H1,
  },
  infoBlack: {
    color: "#282828",
    fontSize: fontSize.UmbracoElementTitle,
    letterSpacing: "0.68px",
    fontFamily: fonts.FbReformaLight,

    lineHeight: "32px",
  },
  // ".boxss": { backgroundColor: "#D8F3FE" },
  topBox: {
    backgroundColor: "#D8F3FE",
    padding: "1rem",
    flexDirection: "column",
    display: "flex",
    gap: "7.5px",
  },
  letsTalks: {
    color: colors.darkBlue,
    fontSize: fontSize.UmbracoElementTitle,
    fontFamily: fonts.FbReformaLight,
    letterSpacing: "0.44px",
    lineHeight: "30px",
  },
  letsNote: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.UmbracoElementTitle,
    letterSpacing: "0.75px",
    lineHeight: "27px",
  },
  letsDescription: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Text,
    letterSpacing: "0.57px",
    lineHeight: "18px",
  },
  input: {
    display: "flex",
    backgroundColor: "rgb(255, 255, 255)",
    borderRadius: "3.25rem",
    border: "1px solid rgb(205, 205, 205)",
    alignItems: "center",
    gap: "1rem",
    padding: "0.5rem 1rem",
  },
};
export default Entitled;
