import { IGalleryImage } from "../../../../Interfaces/IGallery";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import { StyleMode } from "../../../../Utils/consts";
import useDeviceMode from "../../../../Utils/useWindowDimensions";
import GalleryImage from "../GalleryItems/GalleryImage";
import colors from "../../../../Assets/Colors/Colors.json";
import fonts from "../../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../../Assets/Fonts/FontsSizes.json";

const LinkableGalleryImage: React.FC<IGalleryImage> = (props) => {
  const { media, header, contentTypeAlias, imageStyles } = props;
  const deviceMode = useDeviceMode();

  return (
    <div style={styles.galleryContainer}>
      {media && (
        <img
          src={media._url}
          style={{
            maxHeight: "30rem",
          }}
          width={`100%`} 
          height={`100%`} 
          alt={``}
        />
      )}
      <div
        style={{
          ...styles.headerContainer,
          bottom: "0",
          justifyContent: deviceMode !== StyleMode.desktop ? "center" : "",
        }}
      >
        <div
          style={{
            ...styles.header,
            right: deviceMode === StyleMode.desktop ? "2rem" : "",
          }}
        >
          {header.text}
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  galleryContainer: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  } as React.CSSProperties,
  currentDisplayed: {
    position: "relative",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "85.5rem",
    height: "48rem",
  } as React.CSSProperties,
  arrows: {
    position: "absolute",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    top: "40%",
  } as React.CSSProperties,
  headerContainer: {
    position: "absolute",
    backgroundColor: colors.whiteTransparent,
    width: "100%",
    minHeight: "5rem",
    maxHeight: "8rem",
    display: "flex",
  } as React.CSSProperties,
  header: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.UmbracoElementTitle,
    position: "absolute",
    top: "35%",
  } as React.CSSProperties,
  galleryBullets: {
    display: "flex",
    position: "absolute",
    gap: "1rem",
    bottom: "15%",
  } as React.CSSProperties,
  galleryItemBullet: {
    width: ".75rem",
    height: ".75rem",
    border: `1px solid ${colors.ghostWhite}`,
    borderRadius: "0.75rem",
    cursor: "pointer",
  } as React.CSSProperties,
};
export default LinkableGalleryImage;
