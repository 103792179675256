export {};
declare global {
  interface String {
    toUpperCaseIndexOf(index?: number): string;
  }
}

String.prototype.toUpperCaseIndexOf = function (index?: number): string {
  if (index !== undefined && index <= this.length - 1) {
    const letter = this.split("");
    letter[index] = letter[index].toUpperCase();
    return letter.join("");
  }
  return this.toUpperCase();
};
