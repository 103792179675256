import { t } from "i18next";
import { ITableMargin, ITableStyles } from "../Interfaces/IUmbraco";
import i18n from "./i18n";

export const AddTableAccessability = (
  tableRef: HTMLDivElement,
  title: string
) => {
  if (tableRef) {
    const tableElements = tableRef.getElementsByTagName("table");
    if (tableElements && tableElements.length > 0) {
      const table = tableElements[0];
      table.setAttribute("summary", title);
      const trElements = table.getElementsByTagName("tr");
      if (trElements.length > 0) {
        for (let i = 0; i < trElements.length; i++) {
          const tdElements = trElements[i].getElementsByTagName("td");
          if (tdElements.length > 0) {
            for (let j = 0; j < tdElements.length; j++) {
              const tdAlign = (tdElements[j] as any).align;
              if (!tdAlign) {
                if (i18n.dir() === "rtl") {
                  tdElements[j].setAttribute("align", "right");
                  tdElements[j].style.padding = "0 1rem";
                } else {
                  tdElements[j].setAttribute("align", "left");
                  tdElements[j].style.padding = "0 1rem";
                }
              }

              // tdElements[j].setAttribute("tabIndex", "0");
            }
          }
        }
      }

      const tbodyElement = table.getElementsByTagName("tbody");
      if (tbodyElement.length > 0) {
        const firstRow = (tbodyElement[0] as HTMLElement).children[0];
        if (firstRow) {
          const tdElements = firstRow.children;
          for (let i = 0; i < tdElements.length; i++) {
            const tdValue = tdElements[i].innerHTML;
            const th = document.createElement("th");
            const tdAlign = (tdElements[i] as any).align;
            if (!tdAlign) {
              if (i18n.dir() === "rtl") {
                th.setAttribute("align", "right");
                th.style.padding = "0 1rem";
              } else {
                th.setAttribute("align", "left");
                th.style.padding = "0 1rem";
              }
            }
            tdElements[i].replaceWith(th);
            tdElements[i].innerHTML = tdValue;
            // tdElements[i].setAttribute("tabIndex", "0");
            tdElements[i].setAttribute("style", `line-height:2.5rem`);
          }
        }
      }
    }

    const links = tableRef.getElementsByTagName("a");
    if (links && links.length > 0) {
      for (let i = 0; i < links.length; i++) {
        const linkType = links[i].href.split(".");
        const fileName = links[i].innerText;
        let isDownloadLink = false;
        switch (linkType[linkType.length - 1]) {
          case "pdf":
          case "xlsx":
          case "xls":
          case "docx":
          case "doc":
          case "svg":
            isDownloadLink = true;
            break;
          default:
            isDownloadLink = false;
            break;
        }
        links[i].setAttribute(
          "aria-label",
          isDownloadLink
            ? `${t("DownloadFileWithType", {
                fileName: fileName,
                fileType: linkType[linkType.length - 1],
              })} `
            : `${t("OpenLink")} ${linkType[linkType.length - 1]}`
        );
        links[i].setAttribute("role", "button");
      }
    }
  }
};

export const HandleTableStyles = (
  tableRef: HTMLDivElement,
  tableStyles: ITableStyles[]
) => {
  if (tableStyles) {
    const tableElements = tableRef.getElementsByTagName("table");
    if (tableElements && tableElements.length > 0) {
      const table = tableElements[0];
      const trElements = table.getElementsByTagName("tr");
      if (trElements && trElements.length > 0) {
        const thElements = trElements[0].getElementsByTagName("th") || [];
        const columns = [];
        for (let i = 0; i < thElements.length; i++) {
          const thValue = thElements[i].innerText;
          for (let j = 0; j < tableStyles.length; j++) {
            if (tableStyles[j].columnID === thValue) {
              columns.push({ ...tableStyles[j], columnIndex: i });
            }
          }
        }
        for (let trIndex = 0; trIndex < trElements.length; trIndex++) {
          if (trIndex === 0) {
            continue;
          }
          for (
            let tdIndex = 0;
            tdIndex < trElements[trIndex].children.length;
            tdIndex++
          ) {
            const td = trElements[trIndex].children[tdIndex] as HTMLElement;
            for (
              let columnIndex = 0;
              columnIndex < columns.length;
              columnIndex++
            ) {
              if (columns[columnIndex].columnIndex === tdIndex) {
                td.setAttribute(
                  "style",
                  `width: ${columns[columnIndex].width}%; vertical-align:top`
                );
                const tdValue = td.innerHTML;
                const div = document.createElement("div");
                div.innerHTML = tdValue;
                div.setAttribute(
                  "style",
                  `
                   max-height: ${
                     columns[columnIndex].height / trElements.length
                   }rem;
                   overflow: hidden;
                   `
                );
                if (i18n.dir() === "rtl") {
                  div.setAttribute("text-align", "right");
                  div.style.padding = "0 1rem";
                } else {
                  div.setAttribute("text-align", "left");
                  div.style.padding = "0 1rem";
                }

                td.innerHTML = "";
                td.appendChild(div);
                break;
              }
            }
          }
        }
      }
    }
  }
};

export const HandleTableMargin = (
  tableRef: HTMLDivElement,
  tableMargin: ITableMargin
) => {
  if (tableRef && tableMargin) {
    tableRef.setAttribute(
      "style",
      `margin-inline-start: ${tableMargin.start}%;
         margin-inline-end: ${tableMargin.end}%;
         margin-top: ${tableMargin.top}%;
         margin-bottom: ${tableMargin.bottom}%`
    );
  }
};
