import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";
import Breadcrumb from "../UmbracoElements/Breadcrumb";

interface ITopBanner {
  cover: string;
  title: string;
  inTitle?: string;
  breakLine?: boolean;
}
const TopBanner: React.FC<ITopBanner> = (props) => {
  const { cover, title, inTitle, breakLine } = props;
  const deviceMode = useDeviceMode();

  return (
    <div
      style={{
        ...styles.cover,
        background: cover && `30% 30% / cover url(${cover})`,
        height: deviceMode === StyleMode.desktop ? "40vh" : "11rem",
        minHeight: deviceMode === StyleMode.desktop ? "1.5rem" : "unset",
        width: "100%",
        color: "white",
        display: "flex",
        flexDirection: "column",
      }}
      className="top-banner-header"
    >
      <div
        style={{
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: "1rem",
          height: "100%",
          justifyContent: "center",
        }}
        className="max-width"
      >
        <div style={{ flex: deviceMode === StyleMode.desktop ? 0.4 : 0 }}>
          <Breadcrumb items={[{ text: title || "", link: "" }]} />
        </div>
        <h1
          style={{
            // fontSize:"3rem",
            flex: deviceMode === StyleMode.desktop ? 1 : 0,
            color: "#FFFFFF",
            fontSize: deviceMode === StyleMode.desktop ? "2.8rem" : "1.5rem",
            fontWeight: deviceMode === StyleMode.desktop ? "normal" : "bold",
            fontFamily:fonts.FbReformaRegular,
            // letterSpacing: "2.46px",
            margin: 0,
            lineHeight: 1.2,
          }}
        >
          {title}
          {breakLine && deviceMode === StyleMode.desktop && <br />}
          {inTitle && (
            <span className="inTitle" style={{ fontWeight: "bold",fontFamily:fonts.FbReformaMedium }}>
              {" "}
              {inTitle}
            </span>
          )}
        </h1>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  mainHeaderWrapper: {
    display: "flex",
    flexDirection: "column",
    // position: "fixed",
    top: "0",
    left: "0",
    // zIndex: "99999",
    width: "100%",
  },
  menuContainer: {
    minHeight: "3rem",
    width: "100%",
    backgroundColor: colors.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "0 2px 20px 0 rgba(0, 0, 0, 0.1)",
    // boxShadow: "0px -1px 5px 0px black",
    zIndex: "20",
  },
  menuItemsContainer: {
    display: "flex",
    gap: "2rem",
    flex: 2,
    color: colors.darkBlue,
    maxWidth: "75vw",
    flexWrap: "wrap",
  },
  logo: {
    paddingInlineStart: "2rem",
    paddingInlineEnd: "2rem",
    display: "flex",
    alignItems: "center",
  },
  menuUtils: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
  },
  line: {
    boxSizing: "border-box",
    height: "1.5rem",
    width: "0.0063125rem",
    border: `0.01875rem solid ${colors.koromiko}`,
  },
  siteSearchIcon: {
    cursor: "pointer",
    transform: "scale(.8)",
    marginInlineStart: ".5rem",
  },
  siteClearSearchIcon: {
    cursor: "pointer",
    transform: "scale(0.8)",
    margin: "0 .8rem",
  },

  closeSearchContainer: {
    display: "flex",
    fontSize: fontSize.Text,
    fontFamily: fonts.FbReformaMedium,
    color: colors.darkBlue,
    cursor: "pointer",
    gap: ".25rem",
    minWidth: "8rem",
  },
  searchContainer: {
    display: "flex",
    backgroundColor: colors.blackTransparent,
    height: "12rem",
    alignItems: "center",
    justifyContent: "center",
    gap: "1.5rem",
  },
  searchBoxContainer: {
    display: "flex",
    backgroundColor: colors.white,
    height: "2.5rem",
    borderRadius: "1.25rem",
    border: `1px solid ${colors.pastelGray}`,
    maxWidth: "35rem",
    width: "100%",
    alignItems: "center",
    overflow: "hidden",
    gap: "1rem",
  },
  searchBox: {
    flex: "1",
    fontSize: fontSize.Text,
    fontFamily: fonts.FbReformaRegular,
    color: colors.pasterlGrayDark,
    letterSpacing: "0.043125rem",
  },
  searchButton: {
    width: "10rem",
    height: "2.5rem",
    backgroundColor: colors.darkBlue,
    border: `1px solid ${colors.darkBlue}`,
    borderRadius: "1.25rem",
    color: colors.white,
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.Text,
    letterSpacing: "0.029375rem",
    cursor: "pointer",
  },
  arrowDownIcon: {},
  selectedLanguage: {
    display: "flex",
    gap: ".5rem",
    justifyContent: "center",
    color: colors.darkBlue,
    cursor: "pointer",
    padding: "0 2.5rem",
    position: "relative",
  },
  skipBtn: {
    position: "absolute",
    zIndex: "9",
    backgroundColor: "rgb(238, 238, 238)",
    left: "0.25rem",
    top: "10%",
    minWidth: "15%",
    height: "auto",
    overflow: "auto",
    margin: "auto",
    padding: "5px",
    border: "0.3rem dashed",
    textAlign: "center",
    fontSize: fontSize.FooterItem,
  },
};

export default TopBanner;
