import { useState } from "react";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import arrow from "../../../Assets/Icons/arrow.svg";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import i18n from "../../../Services/i18n";

interface IExtendTitle {
  title?: React.ReactNode;
  titleEnd?: React.ReactNode;
  titleEndChildren?: React.ReactNode;
  bodyChildren: React.ReactNode;
  link?: React.ReactNode;
  type: string;
  show?: boolean;
  disabled?: boolean;
}
const ExtendTitle: React.FC<IExtendTitle> = (props) => {
  const {
    title,
    bodyChildren,
    type,
    show,
    disabled,
    titleEndChildren,
    titleEnd,
    link,
  } = props;
  let [_showDetail, _setShowDetail] = useState(type === "desktop" || show);
  return (
    <div style={styles.spaceBetweenElementMob}>
      <div
        className="row-between"
        style={{
          cursor: type !== "desktop" ?"pointer"  :"unset",

          // pointerEvents: type !== "desktop" ? "none" : "auto",
        }}
        onClick={() =>
          type !== "desktop" && !disabled && _setShowDetail(!_showDetail)
        }
      >
        <div className="row">
          {!disabled && type !== "desktop" && (
            <img
              src={arrow}
              style={{
                transition: "0.2s",
                transform: `rotateZ(${
                  _showDetail ? 0 : i18n.dir() === "rtl" ? 90 : -90
                }deg)`,
                marginInlineEnd: "0.5rem",
              }}
              width={`auto`}
              height={`auto`}
              alt={``}
            />
          )}
          {title && (
            <h3 style={type === "desktop" ? styles.titleDetktop : styles.title}>
              {title}
            </h3>
          )}
        </div>
        <div className="row-between" style={{ alignItems: "center" }}>
          <div className="row" style={{ gap: "0.5rem", alignItems: "center" }}>
            {(titleEnd || titleEndChildren) && titleEndChildren ? (
              titleEndChildren
            ) : (
              <h3
                style={type === "desktop" ? styles.titleDetktop : styles.title}
              >
                {titleEnd}
              </h3>
            )}
            <div style={{ backgroundColor: colors.grayDark, width: "1px" }} />

            {link}
          </div>
        </div>
      </div>
      {_showDetail && (
        <div style={{ paddingBlock: "1rem",fontFamily:fonts.FbReformaLight,fontSize:"1.25rem",lineHeight:"1.75rem" }}>{bodyChildren}</div>
      )}
    </div>
  );
};

const styles: StyleSheet = {
  spaceBetweenElement: {
    marginBlock: "1.5rem",
  },
  spaceBetweenElementMob: {
    paddingBlock: "0.2rem",
  },
  titleDetktop: {
    display: "inline-block",
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.FooterItem,
    borderBottom: `1px solid var(${colors.koromiko})`,
    color: colors.darkBlue,
    borderBottomWidth: ".2rem",
    padding: ".5rem 0",
    margin: 0,
  },
  title: {
    display: "inline-block",
    fontFamily: "FbReforma-Medium , HelveticaNeue-Bold",
    fontWeight: `${i18n.language === "ar" ? `600` : ``}`,
    fontSize: fontSize.Paragraph,
    borderBottom: `1px solid var(${colors.koromiko})`,
    color: colors.darkBlue,
    borderBottomWidth: ".2rem",
    padding: ".5rem 0",
    margin: 0,
  },
};
export default ExtendTitle;
