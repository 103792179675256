/* eslint-disable @typescript-eslint/no-unused-expressions */
import * as yup from "yup";
import i18n from "../../Services/i18n";

const regexEnHeNums = /^((?!\s)[\w]|[\u05D0-\u05FF\d\s]){0,24}$/;
const regexEmail = /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;
const regexNumbersOnly = /^\d*$/;

const isMultiUserIdValid = (value: any): boolean => {
  const idNumber = String(value);
  let isValidateId = value && value.length === 9 && /^[0-9]*$/.test(idNumber);
  if (isValidateId) {
    const t = JSON.parse(JSON.stringify(value));
    let r = 0;
    for (let o = 0; o < 9; o++) {
      let l = (parseInt(t[o], 10) || 0) * ((o % 2) + 1);
      // eslint-disable-next-line no-sequences
      l > 9 && (l -= 9), (r += l);
    }
    isValidateId = r % 10 === 0;
  }
  return isValidateId;
};

export const formSchema = yup.object().shape({
  FormReferenceDetails: yup.object().shape({
    serviceSubject: yup.string().required(i18n.t("validErrRequiredFiels")),
  }),

  FormBasicDetails: yup.object().shape({
    firstName: yup
      .string()
      .min(2, i18n.t("validErrMinXCharacters"))
      .max(18, i18n.t("validErrMaxXCharacters"))
      .matches(regexEnHeNums, i18n.t("validErrErrFillingData"))
      .required(i18n.t("validErrRequiredFiels")),
    lastName: yup
      .string()
      .min(2, i18n.t("validErrMinXCharacters"))
      .max(18, i18n.t("validErrMaxXCharacters"))
      .matches(regexEnHeNums, i18n.t("validErrErrFillingData"))
      .required(i18n.t("validErrRequiredFiels")),
    streetBuildNum: yup
      .string()
      .min(2, i18n.t("validErrMinXCharacters"))
      .max(18, i18n.t("validErrMaxXCharacters"))
      .matches(regexEnHeNums, i18n.t("validErrErrFillingData"))
      .required(i18n.t("validErrRequiredFiels")),
    city: yup
      .string()
      .min(2, i18n.t("validErrMinXCharacters"))
      .max(18, i18n.t("validErrMaxXCharacters"))
      .matches(regexEnHeNums, i18n.t("validErrErrFillingData"))
      .required(i18n.t("validErrRequiredFiels")),
    zipCode: yup
      .string()
      .min(7, i18n.t("validErrMinXNumbers"))
      .max(7, i18n.t("validErrMaxXNumbers"))
      .matches(/^\d*$/, i18n.t("validErrErrFillingData")),
    poBox: yup
      .string()
      .min(2, i18n.t("validErrMinXCharacters"))
      .max(10, i18n.t("validErrMaxXCharacters"))
      .matches(regexEnHeNums, i18n.t("validErrErrFillingData")),
  }),

  FormContactDetails: yup.object().shape({
    phoneNumber: yup
      .string()
      .min(6, i18n.t("validErrMinXNumbers"))
      .max(10, i18n.t("validErrMaxXNumbers"))
      .matches(regexNumbersOnly, i18n.t("validErrErrFillingData"))
      .required(i18n.t("validErrRequiredFiels")),
    email: yup
      .string()
      .matches(regexEmail, i18n.t("validErrEmailInvalid"))
      .max(32, i18n.t("validErrMaxXCharacters"))
      .required(i18n.t("validErrRequiredFiels")),
  }),

  FormAccessibilityOrCleaningIssueScreen: yup.object().shape({
    issueLocation: yup.string().when(["currentSubject"], {
      is: (serviceSubject: string) =>
        serviceSubject === "accessibilityIssue" ||
        serviceSubject === "cleaningIssue",
      then: (schema) => schema.required(i18n.t("validErrRequiredFiels")),
    }),

    stationDeparture: yup.string().when(["currentSubject", "issueLocation"], {
      is: (serviceSubject: string, issueLocation: string) =>
        (serviceSubject === "accessibilityIssue" ||
          serviceSubject === "cleaningIssue") &&
        issueLocation === "wagon",
      then: (schema) => schema.required(i18n.t("validErrRequiredFiels")),
    }),

    stationDestination: yup.string().when(["currentSubject", "issueLocation"], {
      is: (serviceSubject: string, issueLocation: string) =>
        (serviceSubject === "accessibilityIssue" ||
          serviceSubject === "cleaningIssue") &&
        issueLocation === "wagon",
      then: (schema) => schema.required(i18n.t("validErrRequiredFiels")),
    }),

    eventDateTime: yup.string().when(["currentSubject"], {
      is: (serviceSubject: string) =>
        serviceSubject === "accessibilityIssue" ||
        serviceSubject === "cleaningIssue",
      then: (schema) => schema.required(i18n.t("validErrRequiredFiels")),
    }),

    comments: yup.string().when(["currentSubject"], {
      is: (serviceSubject: string) =>
        serviceSubject === "accessibilityIssue" ||
        serviceSubject === "cleaningIssue",
      then: (schema) =>
        schema
          .min(2, i18n.t("validErrMinXNumbers"))
          .max(255, i18n.t("validErrMaxXCharacters"))
          .required(i18n.t("validErrRequiredFiels")),
    }),
  }),

  FormAppealRequirementPaymentScreen: yup.object().shape({
    isRavKav: yup.string().when(["currentSubject"], {
      is: (serviceSubject: string) =>
        serviceSubject === "appealRequirementPayment",
      then: (schema) => schema.required(i18n.t("validErrRequiredFiels")),
    }),

    ravKavCardNumber: yup.string().when(["currentSubject", "isRavKav"], {
      is: (serviceSubject: string, isRavKav: string) =>
        serviceSubject === "appealRequirementPayment" &&
        isRavKav &&
        isRavKav === "true",
      then: (schema) =>
        schema
          .min(8, i18n.t("validErrMinXNumbers"))
          .max(10, i18n.t("validErrMaxXNumbers"))
          .matches(regexNumbersOnly, i18n.t("validErrErrFillingData"))
          .required(i18n.t("validErrRequiredFiels")),
      otherwise: (schema) => schema,
    }),

    stationDeparture: yup.string().when(["currentSubject"], {
      is: (serviceSubject: string) =>
        serviceSubject === "appealRequirementPayment",
      then: (schema) => schema.required(i18n.t("validErrRequiredFiels")),
    }),

    stationDestination: yup.string().when(["currentSubject"], {
      is: (serviceSubject: string) =>
        serviceSubject === "appealRequirementPayment",
      then: (schema) => schema.required(i18n.t("validErrRequiredFiels")),
    }),

    reqNumber: yup.string().when(["currentSubject"], {
      is: (serviceSubject: string) =>
        serviceSubject === "appealRequirementPayment",
      then: (schema) =>
        schema
          .min(2, i18n.t("validErrMinXNumbers"))
          .max(10, i18n.t("validErrMaxXNumbers"))
          .matches(regexNumbersOnly, i18n.t("validErrErrFillingData"))
          .required(i18n.t("validErrRequiredFiels")),
    }),

    userId: yup.string().when(["currentSubject"], {
      is: (serviceSubject: string) =>
        serviceSubject === "appealRequirementPayment",
      then: (schema) =>
        schema
          .min(6, i18n.t("validErrMinXCharacters"))
          .max(9, i18n.t("validErrMaxXNumbers"))
          .test("multiUserId TEST", i18n.t("validErrErrFillingData"), (value) =>
            isMultiUserIdValid(value)
          )
          .matches(/^\d*$/, i18n.t("validErrErrFillingData"))
          .required(i18n.t("validErrRequiredFiels")),
    }),

    eventDateTime: yup.string().when(["currentSubject"], {
      is: (serviceSubject: string) =>
        serviceSubject === "appealRequirementPayment",
      then: (schema) => schema.required(i18n.t("validErrRequiredFiels")),
    }),

    comments: yup.string().when(["currentSubject"], {
      is: (serviceSubject: string) =>
        serviceSubject === "appealRequirementPayment",
      then: (schema) =>
        schema
          .min(2, i18n.t("validErrMinXNumbers"))
          .max(255, i18n.t("validErrMaxXCharacters"))
          .required(i18n.t("validErrRequiredFiels")),
    }),
  }),

  FormCheckEntranceStationScreen: yup.object().shape({
    stationDeparture: yup.string().when(["currentSubject"], {
      is: (serviceSubject: string) => serviceSubject === "checkEntranceStation",
      then: (schema) => schema.required(i18n.t("validErrRequiredFiels")),
    }),

    eventDateTime: yup.string().when(["currentSubject"], {
      is: (serviceSubject: string) => serviceSubject === "checkEntranceStation",
      then: (schema) => schema.required(i18n.t("validErrRequiredFiels")),
    }),

    comments: yup.string().when(["currentSubject"], {
      is: (serviceSubject: string) => serviceSubject === "checkEntranceStation",
      then: (schema) =>
        schema
          .min(2, i18n.t("validErrMinXNumbers"))
          .max(255, i18n.t("validErrMaxXCharacters"))
          .required(i18n.t("validErrRequiredFiels")),
    }),
  }),

  FormClaimRailsWorkerOrThanksAppreciationScreen: yup.object().shape({
    role: yup.string().when(["currentSubject"], {
      is: (serviceSubject: string) =>
        serviceSubject === "thanksAppreciation" ||
        serviceSubject === "claimRailsWorker",
      then: (schema) => schema.required(i18n.t("validErrRequiredFiels")),
    }),

    stationDeparture: yup.string().when(["currentSubject", "role"], {
      is: (serviceSubject: string, role: string) =>
        (serviceSubject === "thanksAppreciation" ||
          serviceSubject === "claimRailsWorker") &&
        role !== "other",
      then: (schema) => schema.required(i18n.t("validErrRequiredFiels")),
    }),

    stationDestination: yup.string().when(["currentSubject", "role"], {
      is: (serviceSubject: string, role: string) =>
        (serviceSubject === "thanksAppreciation" ||
          serviceSubject === "claimRailsWorker") &&
        (role === "Dale" || role === "inspector"),
      then: (schema) => schema.required(i18n.t("validErrRequiredFiels")),
    }),

    other: yup.string().when(["currentSubject", "role"], {
      is: (serviceSubject: string, role: string) =>
        (serviceSubject === "thanksAppreciation" ||
          serviceSubject === "claimRailsWorker") &&
        role === "other",
      then: (schema) => schema.required(i18n.t("validErrRequiredFiels")),
    }),

    eventDateTime: yup.string().when(["currentSubject"], {
      is: (serviceSubject: string) =>
        serviceSubject === "thanksAppreciation" ||
        serviceSubject === "claimRailsWorker",
      then: (schema) => schema.required(i18n.t("validErrRequiredFiels")),
    }),

    comments: yup.string().when(["currentSubject"], {
      is: (serviceSubject: string) =>
        serviceSubject === "thanksAppreciation" ||
        serviceSubject === "claimRailsWorker",
      then: (schema) =>
        schema
          .min(2, i18n.t("validErrMinXNumbers"))
          .max(255, i18n.t("validErrMaxXCharacters"))
          .required(i18n.t("validErrRequiredFiels")),
    }),
  }),

  FormClaimTicketPurchaseScreen: yup.object().shape({
    userId: yup.string().when(["currentSubject"], {
      is: (serviceSubject: string) => serviceSubject === "claimTicketPurchase",
      then: (schema) =>
        schema
          .min(6, i18n.t("validErrMinXCharacters"))
          .max(9, i18n.t("validErrMaxXNumbers"))
          .test("multiUserId TEST", i18n.t("validErrErrFillingData"), (value) =>
            isMultiUserIdValid(value)
          )
          .matches(/^\d*$/, i18n.t("validErrErrFillingData"))
          .required(i18n.t("validErrRequiredFiels")),
    }),

    ravKavCardNumber: yup.string().when(["currentSubject"], {
      is: (serviceSubject: string) => serviceSubject === "claimTicketPurchase",
      then: (schema) =>
        schema
          .min(8, i18n.t("validErrMinXNumbers"))
          .max(10, i18n.t("validErrMaxXNumbers"))
          .matches(regexNumbersOnly, i18n.t("validErrErrFillingData"))
          .required(i18n.t("validErrRequiredFiels")),
      otherwise: (schema) => schema,
    }),

    eventDateTime: yup.string().when(["currentSubject"], {
      is: (serviceSubject: string) => serviceSubject === "claimTicketPurchase",
      then: (schema) => schema.required(i18n.t("validErrRequiredFiels")),
    }),

    comments: yup.string().when(["currentSubject"], {
      is: (serviceSubject: string) => serviceSubject === "claimTicketPurchase",
      then: (schema) =>
        schema
          .min(2, i18n.t("validErrMinXNumbers"))
          .max(255, i18n.t("validErrMaxXCharacters"))
          .required(i18n.t("validErrRequiredFiels")),
    }),
  }),

  FormCompensationRequestScreen: yup.object().shape({
    isRavKav: yup.string().when(["currentSubject"], {
      is: (serviceSubject: string) => serviceSubject === "compensationRequest",
      then: (schema) => schema.required(i18n.t("validErrRequiredFiels")),
    }),

    ravKavCardNumber: yup.string().when(["currentSubject", "isRavKav"], {
      is: (serviceSubject: string, isRavKav: string) =>
        serviceSubject === "compensationRequest" &&
        isRavKav &&
        isRavKav === "true",
      then: (schema) =>
        schema
          .min(8, i18n.t("validErrMinXNumbers"))
          .max(10, i18n.t("validErrMaxXNumbers"))
          .matches(regexNumbersOnly, i18n.t("validErrErrFillingData"))
          .required(i18n.t("validErrRequiredFiels")),
      otherwise: (schema) => schema,
    }),

    stationDeparture: yup.string().when(["currentSubject"], {
      is: (serviceSubject: string) => serviceSubject === "compensationRequest",
      then: (schema) => schema.required(i18n.t("validErrRequiredFiels")),
    }),

    stationDestination: yup.string().when(["currentSubject"], {
      is: (serviceSubject: string) => serviceSubject === "compensationRequest",
      then: (schema) => schema.required(i18n.t("validErrRequiredFiels")),
    }),

    eventDateTime: yup.string().when(["currentSubject"], {
      is: (serviceSubject: string) => serviceSubject === "compensationRequest",
      then: (schema) => schema.required(i18n.t("validErrRequiredFiels")),
    }),

    comments: yup.string().when(["currentSubject"], {
      is: (serviceSubject: string) => serviceSubject === "compensationRequest",
      then: (schema) =>
        schema
          .min(2, i18n.t("validErrMinXNumbers"))
          .max(255, i18n.t("validErrMaxXCharacters"))
          .required(i18n.t("validErrRequiredFiels")),
    }),
  }),

  FormInquiryAboutWebAppScreen: yup.object().shape({
    comments: yup.string().when(["currentSubject"], {
      is: (serviceSubject: string) => serviceSubject === "inquiryAboutWebApp",
      then: (schema) =>
        schema
          .min(2, i18n.t("validErrMinXNumbers"))
          .max(255, i18n.t("validErrMaxXCharacters"))
          .required(i18n.t("validErrRequiredFiels")),
    }),
  }),
});
