import { t } from "i18next";
import { useState } from "react";
import Images from "../../../../Data/Images/Images";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";

type IIconActionControl = {
    isMedia: boolean,
    onClick?: () => void,
}

const IconActionControl: React.FC<IIconActionControl> = ({ isMedia, onClick }) => {
    const [_isVideoPlaying, _setIsVideoPlaying] = useState(false);
    return (
        <img
            src={isMedia ? Images.photoIcon : Images.playIcon}
            style={styles.icon}
            aria-label={!isMedia ? t("PlayVideo") : ""}
            tabIndex={0}
            role={"button"}
            onClick={onClick}
        />);
};
const styles: StyleSheet = {

    icon: { width: "100%" },

};

export default IconActionControl;
