import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import Images from "../../Data/Images/Images";
import { IStation } from "../../Interfaces/IStation";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { ITab } from "../../Interfaces/ITabs";
import { Translations } from "../../Services/GlobalService";
import { GetSortedStations } from "../../Services/SearchStationsAutoCompleteService";
import i18n from "../../Services/i18n";
import {
  GetStationsDictionary,
  IDictStation,
  getTravelPrice,
} from "../../Utils/helpers";
import StationsListGrouped from "../StationsList/StationsListGrouped";
import { IProfileData } from "./Profiles";

type IStationsType = "fromStation" | "toStation";
interface ISearchObject {
  fromStation: string;
  toStation: string;
  profile?: IProfileData;
}
export interface IFromTOProfile {
  fromStation: number;
  toStation: number;
  profile?: IProfileData;
  hidePayment?: boolean;
  title?: string;
}

export interface ITrainPriceRange {
  singleBusPrice: number;
  singleTrainPrice: number;
  dailyBusPrice: number;
  dailyTrainAndBusPrice: number;
  monthlyPrice: number;
  monthlyTrainAndBusPrice: number;
}

export interface ITrainPriceRangeNew {
  heb_Contract_Description: string;
  eng_Contract_Description: string;
  arb_Contract_Description: string;
  rus_Contract_Description: string;
  ticketType: 1 | 2 | 3;
  distanceCode: number;
  profile_ID: number;
  full_Price: number;
  finalPrice: number;
  discount_Rate: number;
  heb_Note_To_The_Passenger: string;
  eng_Note_To_The_Passenger: string;
  arb_Note_To_The_Passenger: string;
  rus_Note_To_The_Passenger: string;
}

export interface IDestination {
  from_Station_Code_ISR: number;
  to_Station_Code_ISR: number;
  distance_Code: number;
  s_Price: number;
  d_Price: number;
  m_Price: number;
}
export interface ISearch {
  fromStation?: string;
  toStation?: string;
  fromStationNumber?: number;
  toStationNumber?: number;
  distance?: number;
  profile?: IProfileData;
  maxRange?: IDistanceBt;
}
export interface ITrainPricies {
  profileId: number;
  statusFree: boolean;
  trainPricies: ITrainPriceRangeNew[];
}
export interface IPricies {
  fromStation: number;
  toStation: number;
  distanceCode: number;
  price1: number;
  price2: number;
  price3: number;
}
export interface ICalcResult {
  data?: ITrainPricies[];
  search?: ISearch;
}
export interface ITrainPrice {
  [key: string]: ITrainPriceRange;
}
export const trainPrice: ITrainPrice = {
  "0-15": {
    singleBusPrice: 5.5,
    singleTrainPrice: 9,
    dailyBusPrice: 12.5,
    dailyTrainAndBusPrice: 18,
    monthlyPrice: 225,
    monthlyTrainAndBusPrice: 255,
  },
  "15-40": {
    singleBusPrice: 12,
    singleTrainPrice: 18,
    dailyBusPrice: 24,
    dailyTrainAndBusPrice: 28,
    monthlyPrice: 225,
    monthlyTrainAndBusPrice: 255,
  },
  "40-75": {
    singleBusPrice: 16,
    singleTrainPrice: 24,
    dailyBusPrice: 32,
    dailyTrainAndBusPrice: 37,
    monthlyPrice: 225,
    monthlyTrainAndBusPrice: 410,
  },
  "75-120": {
    singleBusPrice: 16,
    singleTrainPrice: 27,
    dailyBusPrice: 32,
    dailyTrainAndBusPrice: 42,
    monthlyPrice: 225,
    monthlyTrainAndBusPrice: 610,
  },
  "120-225": {
    singleBusPrice: 27,
    singleTrainPrice: 48,
    dailyBusPrice: 54,
    dailyTrainAndBusPrice: 74,
    monthlyPrice: 225,
    monthlyTrainAndBusPrice: 610,
  },
  "225": {
    singleBusPrice: 68.5,
    singleTrainPrice: 0,
    dailyBusPrice: 74,
    dailyTrainAndBusPrice: 0,
    monthlyPrice: 610,
    monthlyTrainAndBusPrice: 610,
  },
};
export type IDistanceBt =
  | "0-15"
  | "15-40"
  | "40-75"
  | "75-120"
  | "120-225"
  | "225";
export const listDis: IDistanceBt[] = [
  "0-15",
  "15-40",
  "40-75",
  "75-120",
  "120-225",
  "225",
];

const FromToStation: React.FC<any> = (props) => {
  let [_tabs, _setTabs] = useState<ITab[]>([]);
  let [_tabSelected, _setTabSelected] = useState<string>("");
  let [_station, _setStation] = useState<IStation[]>([]);
  let [_stationKey, _setStationKey] = useState<IDictStation>({});
  let [_AllTravels, _setAllTravels] = useState<any>({});
  let [_isFocus, _setIsFocus] = useState<boolean>(false);
  let [_stationsType, _setStationsType] =
    useState<IStationsType>("fromStation");
  let [_searchObject, _setSearchObject] = useState<ISearchObject>({
    fromStation: "",
    toStation: "",
  });
  const [_objectTravel, _setObjectTravel] = useState<IFromTOProfile | any>();
  const [_objectTravels, _setObjectTravels] = useState<IFromTOProfile[]>([]);
  const fromInput: any = useRef(null);
  const init = () => {
    let res = GetSortedStations();
    let resKey = GetStationsDictionary(res);
    _setStationKey(resKey);
    _setStation(res);
  };
  const outbox = (e: any) => {
    if (!fromInput.current.contains(e.target)) {
      _setIsFocus(false);
    }
  };
  const Translation = Translations[
    i18n.language as `he` | `en` | `ar` | `ru`
  ] as any;

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (_isFocus) {
      document.addEventListener("click", outbox);
    } else {
      document.removeEventListener("click", outbox);
    }
    return () => {
      document.removeEventListener("click", outbox);
    };
  }, [_isFocus]);

  const _onChange = (stationKey: IStationsType, text: string) => {
    let res = GetSortedStations().filter((item) =>
      item.stationName.includes(text)
    );
    _setSearchObject({
      ..._searchObject,
      [stationKey]: text,
    });
    _setStationsType(stationKey);
    _setStation(res);
  };

  const onSwitch = () => {
    _setSearchObject({
      ..._searchObject,
      fromStation: _searchObject.toStation,
      toStation: _searchObject.fromStation,
    });
  };

  const onCalcTravel = async () => {
    let listsOfResults: any[] = [];
    for (let index = 0; index < _objectTravels.length; index++) {
      const element = _objectTravels[index];
      let res = await getTravelPrice(element);
      listsOfResults.push(res);
    }

    props.onSearch(listsOfResults);
  };
  return (
    <>
      <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <div style={styles.wrapperBtn} ref={fromInput}>
          {/* <div style={styles.searchBoxWrapper}> */}
          <div style={styles.wrapperInput}>
            <img src={Images.origin} style={styles.icon} />
            <input
              value={_searchObject.fromStation}
              onFocus={() => {
                _setStationsType("fromStation");
                _setIsFocus(true);
              }}
              style={styles.inputText}
              onChange={(e) => _onChange("fromStation", e.target.value)}
            />
            <img src={Images.info} style={styles.icon} width={"8"} />
          </div>
          {/* <div style={{
          width: "1px", 
          border:"0.1rem solid rgb(205, 205, 205)"
        }}> */}
          <img
            src={Images.switch}
            onClick={onSwitch}
            style={{ cursor: "pointer" }}
          />
          {/* </div> */}
          <div style={styles.wrapperInput}>
            <img src={Images.destination} style={styles.icon} />
            <input
              onFocus={() => {
                _setStationsType("toStation");
                _setIsFocus(true);
              }}
              value={_searchObject.toStation}
              style={styles.inputText}
              onChange={(e) => _onChange("toStation", e.target.value)}
            />
            <img src={Images.info} style={styles.icon} width={"8"} />
          </div>

          {_isFocus && (
            <div style={styles.droplist}>
              <StationsListGrouped
                title={""}
                allStations={_station}
                recentLastStationsTitle={t("Closest-stations")}
                recentLastStations={[]}
                stationsType={"destination"}
                onClick={(Station: IStation) => {
                  _setObjectTravel({
                    ..._objectTravel,
                    [_stationsType]: Station.stationId,
                  });
                  _setSearchObject({
                    ..._searchObject,
                    [_stationsType]: Station.stationName,
                  });
                  _setStationsType(_stationsType);

                  _setIsFocus(false);
                }}
                hideLastORNearbyStations={false}
                textValue={_searchObject[_stationsType]}
                inputValue={_searchObject[_stationsType]}
              />
            </div>
          )}
        </div>
        <button
          style={styles.btn}
          disabled={!_searchObject.toStation || !_searchObject.fromStation}
          onClick={() => {
            _setObjectTravels(_objectTravels.concat(_objectTravel));
            _setObjectTravel({});
            _setSearchObject({
              fromStation: "",
              toStation: "",
              profile: _searchObject.profile,
            });
          }}
        >
          +
        </button>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        {_objectTravels.map((item: IFromTOProfile, index: number) => (
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <div
              style={{ ...styles.wrapperBtn, padding: "0.5rem", gap: "0.5rem" }}
              key={index}
            >
              <div style={{ flex: 1, textAlign: "center" }}>
                {_stationKey[item.fromStation]?.stationName}
              </div>
              <img src={Images.fromTo} />
              <div style={{ flex: 1, textAlign: "center" }}>
                {_stationKey[item.toStation]?.stationName}
              </div>
            </div>
            <button
              style={{
                ...styles.btn,
                backgroundColor: colors.red,
                cursor: "pointer",
              }}
              onClick={async () => {
                _objectTravels.splice(index, 1);
                _setObjectTravels([..._objectTravels]);
              }}
            >
              -
            </button>
          </div>
        ))}
      </div>
      {/* <CapsuleFromTo data={_objectTravels} /> */}

      {_objectTravels.length > 0 && (
        <button
          style={{ ...styles.btn, width: "8rem", aspectRatio: "unset" }}
          onClick={onCalcTravel}
        >
          {t("Search")}
        </button>
      )}
    </>
  );
};

const styles: StyleSheet = {
  container: {
    minHeight: "100vh",
    width: "80%",
    maxWidth: "76rem",
    display: "flex",
    flexDirection: "column",
    gap: "5rem",
    flexWrap: "wrap",
  },
  mainStationsWrapper: {
    display: "flex",
    border: `.1rem solid ${colors.pastelGray}`,
    borderRadius: "2rem",
    height: "2rem",
    width: "16rem",
    justifyContent: "center",
  },
  stationsListStyle: {
    maxHeight: "25rem",
    width: "20rem",
    position: "absolute",
    overflow: "auto",
    top: "3rem",
    backgroundColor: colors.white,
    boxShadow: `0 15px 30px 0 rgba(0, 0, 0, 0.15)`,
    borderRadius: "0.6rem",
    padding: "1rem",
    boxSizing: "border-box",
  },
  stationsSearchBoxesContainer: {
    display: "flex",
    gap: "2rem",
    zIndex: "1",
    flexWrap: "wrap",
  },
  profiles: {
    display: "flex",
    gap: "2rem",
    flexWrap: "wrap",
    alignItems: "center",
  },
  profile: {
    borderRadius: "3rem",
    padding: "1rem",
    width: "8rem",
    textAlign: "center",
    cursor: "pointer",
  },
  calculateButton: {
    backgroundColor: colors.koromiko,
    width: "24rem",
    height: "3.3rem",
    borderRadius: "24rem",
    border: "none",
    fontSize: fontSize.Paragraph,
    boxShadow: `0 2px .3rem .02rem ${colors.gray}`,
    cursor: "pointer",
  },
  wrapperInput: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    padding: "0.5rem",
    gap: "0.2rem",
  },
  inputText: {
    width: "100%",
    color: colors.nero,
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Text,
    border: "0",
    outline: "0",
    flex: 1,
  },
  icon: {
    filter: "brightness(0.6)",
    backgroundRepeat: "no-repeat",
    width: "1rem",
    height: "1rem",
  },
  droplist: {
    position: "absolute",
    backgroundColor: "white",
    zIndex: 1,
    top: "calc(100% + 17px)",
    right: 0,
    left: 0,
    boxShadow: "var(--shadowColor)",
    borderRadius: "1rem",
    padding: "0.5rem",
    maxHeight: "400px",
    overflow: "auto",
  },
  wrapperBtn: {
    position: "relative",
    flex: "1 1 0%",
    boxShadow: "rgba(0, 0, 0, 0.21) 0px 5px 11px 0px",
    borderRadius: "2rem",
    backgroundColor: "white",
    padding: "0.25rem",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.5rem",
    display: "flex",
    border: "0",
  },
  btn: {
    backgroundColor: "rgb(249, 186, 77)",
    width: "2.5rem",
    aspectRatio: "1/1",
    borderRadius: "2rem",
    border: "none",
    fontSize: "1.5rem",
    fontFamily: "OpenSans-Bold, HelveticaNeue-Bold",
    boxShadow:
      "rgb(0 0 0 / 20%) 0px 0px 1px 0px, rgb(0 0 0 / 19%) 0px 0px 15px 0px",
  },
};

export default FromToStation;
