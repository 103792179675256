import moment from "moment";
import { ISchedule } from "../../Interfaces_tenders/ISchedule";
import { FormatDateYYYYMMDD } from "../../../../Services/DateService";
import { Actions } from "../consts";

const init_state: ISchedule = {
  fromStation: undefined,
  toStation: undefined,
  date: FormatDateYYYYMMDD(moment().toDate()),
  hours: "00",
  minutes: "00",
  scheduleType: 1,
};

export const ScheduleReducer = (state = init_state, action: any) => {
  switch (action.type) {
    case Actions.setOrigin:
      return { ...state, fromStation: action.payload };
    case Actions.setDestination:
      return { ...state, toStation: action.payload };
    case Actions.setDate:
      return { ...state, date: action.payload };
    case Actions.setHours:
      return { ...state, hours: action.payload };
    case Actions.setMinutes:
      return { ...state, minutes: action.payload };
    case Actions.setScheduleType:
      return { ...state, scheduleType: action.payload };
    default:
      return { ...state };
  }
};
