import { IStep } from "../../../Interfaces/IUmbraco";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import { useNavigate } from "react-router-dom";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import { StyleMode } from "../../../Utils/consts";
import { replaceRichtextBoldWithFont } from "../../../Utils/utils";
import { useState } from "react";
import { t } from "i18next";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../Services/DeviceService";

const Step: React.FC<IStep> = (props) => {
  const { image, title, description, link } = props;

  const [over, setOver] = useState(false);

  const navigate = useNavigate();
  const deviceMode = useDeviceMode();

  const redirectToPage = (page?: string) => {
    page && navigate(`?page=${page}`);
  };

  return (
    <div
      style={{
        ...styles.mainContainer,
        alignItems: deviceMode === StyleMode.desktop ? "center" : "flex-start",
      }}
      className="step"
    >
      <img
        src={image._url}
        style={{ width: "7.5rem" }}
        width={`auto`}
        height={`auto`}
        alt={``}
      />
      <h3 style={styles.title}>{title}</h3>
      <div
        style={{
          ...styles.description,
          lineHeight: "1.7rem",
          maxWidth: deviceMode !== StyleMode.desktop ? "17.5625rem" : "",
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: replaceRichtextBoldWithFont(description),
          }}
        ></div>
      </div>
      <div>
        <span
          style={
            over
              ? { ...styles.link, textDecoration: "underline" }
              : { ...styles.link }
          }
          onClick={() => redirectToPage(link.redirectTo)}
          role={`link`}
          tabIndex={!IsMobile() ? 0 : -1}
          aria-label={`${link.text} ${t("About")} ${title}`}
          onMouseOver={() => {
            setOver(true);
          }}
          onMouseLeave={() => {
            setOver(false);
          }}
          onFocus={() => {
            setOver(true);
          }}
          onBlur={() => {
            setOver(false);
          }}
        >
          {link.text}<span aria-hidden={true}>{`${link.text.length>0?`>`:``}`}</span>
        </span>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
  },
  image: {
    backgroundRepeat: "no-repeat",
    width: "7.5rem",
    height: "7.5rem",
    padding: "0 .5rem",
  },
  title: {
    fontFamily: fonts.FbReformaMedium,
    color: colors.darkBlue,
    fontSize: fontSize.UmbracoElementTitle,
  },
  description: {
    fontFamily: fonts.FbReformaLight,
    fontSize: fontSize.Paragraph,
    color: colors.black,
  },
  link: {
    fontFamily: fonts.FbReformaMedium,
    color: colors.oldLavender,
    fontSize: fontSize.Paragraph,
    cursor: "pointer",
  },
};

export default Step;
