import colors from "../../../Assets/Colors/Colors.json";
import { ICustomSelect } from "../../../Interfaces/IForms";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";

const CustomSelect: React.FC<ICustomSelect> = (props) => {
  const { label, onChange, selectstyle, errors, touched, value } = props;
const boolTouched = touched === "true" ? true : false;

  return (
    <div
      style={
        selectstyle
          ? {
              ...styles.inputContainer,
              flex: selectstyle.flex,
              width: selectstyle.width,
            }
          : styles.inputContainer
      }
    >
      <label style={styles.label}>{label}</label>
      <select

        style={styles.select}
        {...props}
        onChange={onChange}
        className={errors && errors && boolTouched && boolTouched ? "input-error" : ""}
        value={value}
      ></select>
      {errors && errors && boolTouched && boolTouched && (
        <p className="error">{errors && errors}</p>
      )}
    </div>
  );
};
const styles: StyleSheet = {
  label: {
    fontSize: "1rem",
    fontWeight: "bold",
    display: "flex",
    margin: "1rem 0 0.2rem",
  },

  select: {
    width: "100%",
    padding: "0.65rem 0.5rem",
    fontSize: "1rem",
    color: colors.black,
    border: "1px solid",
    borderColor: colors.darkBlue,
    borderRadius: "10px",
    outline: "none",
  },
  inputContainer: {
    flex: "1 0 45%",
  },
};

export default CustomSelect;
