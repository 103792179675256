import { useEffect, useRef, useState } from "react";
import { StyleSheet } from "../../../Interfaces_tenders/IStyleSheet";
import colors from "../../../../../Assets/Colors/Colors.json";
import Images from "../../../../../Data/Images/Images";
import {
  IAutoCompleteForm,
  IAutoCompleteFormTypes,
} from "../../../Interfaces_tenders/IForm";
import fonts from "../../../../../Assets/Fonts/Fonts.json";
import {
  IAuctionsSystemDomains,
  IAuctionsSystemTypesAndDomains,
  IAuctionsSystemTypesAndTenderTypes,
} from "../../../Interfaces_tenders/IAuctionsSystemDetails";

const AutoCompleteFormTypes: React.FC<IAutoCompleteFormTypes> = (props) => {
  const {
    placeholder,
    items,
    onItemClick,
    defaultSelection,
    isAllDisplayed = true,
    customStyles,
    onChange,
    id,
  } = props;

  const [_isDropDownVisible, _setIsDropDownVisible] = useState(false);
  const [_filteredItems, _setFilteredItems] = useState(items);
  const [_inputValue, _setInputValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const dropDownRef = useRef<HTMLDivElement>(null);
  

  const checkMouseClickOutside = (e: MouseEvent) => {
    if (dropDownRef.current && e.target instanceof HTMLElement) {
      if (!dropDownRef.current.contains(e.target)) {
        _setIsDropDownVisible(false);
      } else {
        _setIsDropDownVisible(true);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", checkMouseClickOutside);
    return () => {
      document.removeEventListener("click", checkMouseClickOutside);
    };
  }, []);

  /*useEffect(() => {
    if (defaultSelection) {
      handleItemSelect(defaultSelection);
    }
  }, [defaultSelection]);*/

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    _setInputValue(text);

    const filteredItems = [
      ...items.filter((item) =>
        item.typeValue.toLowerCase().includes(text.toLowerCase())
      ),
    ];
    _setFilteredItems(filteredItems);
  };

  const handleItemSelect = (
    selectedItem: IAuctionsSystemTypesAndTenderTypes
  ) => {
    if (inputRef.current) {
      inputRef.current.value = selectedItem.typeValue;
    }

    onItemClick(selectedItem.typeKey);
    _setIsDropDownVisible(false);
  };

  return (
    <div
      ref={dropDownRef}
      style={{
        ...styles.container,
        width: "11.5625rem",
        border: `0.0625rem solid ${
          _isDropDownVisible ? colors.darkBlue : colors.pastelGray
        }`,
        ...customStyles,
      }}
    >
      <input
        type={"text"}
        placeholder={placeholder}
        style={{ ...styles.input, marginInlineStart: "1rem" }}
        onChange={(e) => {
          handleFilter(e);
          onChange && onChange(e);
        }}
        ref={inputRef}
        onClick={() => {
          _setIsDropDownVisible(!_isDropDownVisible);
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}
        id={id}
      />
         <img
        src={Images.arrow}
        style={{marginInlineEnd: "1rem" }}
      />
      {_isDropDownVisible && _filteredItems.length > 0 && (
        <div style={styles.dropDownContainer}>
          {_filteredItems.map((item, index) => (
            <div
              style={styles.item}
              key={index}
              onClick={() => handleItemSelect(item)}
            >
              {item.typeValue}
              {index < _filteredItems.length - 1 && (
                <div style={styles.line}></div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "18.6rem",
    width: "100%",
    height: "2.5rem",
    borderRadius: "1.25rem",
    gap: ".3rem",
    padding: "0 .5rem",
    position: "relative",
    zIndex: "30",
  },
  input: {
    width: "80%",
    fontSize: "1.125rem",
    color: colors.pasterlGrayDark,
    letterSpacing: "0.043125rem",
    fontFamily: fonts.FbReformaRegular,
  },

  dropDownContainer: {
    position: "absolute",

    top: "2.7rem",
    width: "100%",
    boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 15px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: "0.3125rem",
    backgroundColor: colors.white,
    zIndex: "1",
    overflow: "auto",
  },
  item: {
    position: "relative",
    fontFamily: fonts.FbReformaRegular,
    fontSize: "1.125rem",
    color: colors.black,
    letterSpacing: "0.03125rem",
    margin: "1rem",
    paddingTop: ".3rem",
    cursor: "pointer",
  },
  line: {
    position: "absolute",
    width: "100%",
    height: "0.125rem",
    backgroundColor: colors.platinum,
    top: "2rem",
  },
};

export default AutoCompleteFormTypes;
