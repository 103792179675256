import moment from "moment";
import QueryString from "qs";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import store from "store2";
import Config from "../../Configuration/server";
import Images from "../../Data/Images/Images";
import { IStation } from "../../Interfaces/IStation";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import i18n from "../../Services/i18n";
import { FormatDateYYYYMMDD } from "../../Services/DateService";
import { StyleMode } from "../../Utils/consts";
import { GetStationsDictionary } from "../../Utils/helpers";
import useDeviceMode from "../../Utils/useWindowDimensions";
import { t } from "i18next";
import { PageState, setPage } from "../../Redux/Slices/pageSlice";
import {
  ScheduleState,
  setDate,
  setDestinationStation,
  setHours,
  setMinutes,
  setOriginStation,
  setScheduleType,
} from "../../Redux/Slices/scheduleSlice";
import { removeSpecialCharacters } from "../../Utils/utils";
import { IsMobile } from "../../Services/DeviceService";

const RoutePlanButtons: React.FC = () => {
  const navigate = useNavigate();
  const schedule = useSelector(
    (state: any) => state.ScheduleReducer
  ) as ScheduleState;
  const currentPage = (
    useSelector((state: any) => state.PageReducer) as PageState
  ).page;
  const location = useLocation();
  const deviceMode = useDeviceMode();
  const dispatch = useDispatch();
  const [_step, _setStep] = useState("");
  const [_isCloseButtonOver, _setIsCloseButtonOver] = useState(false);
  const [_isBackButtonOver, _setIsBackButtonOver] = useState(false);
  const allStations = store.session.get(
    `${Config.BASE_LOCALSTORAGE_NAME}stations`
  );
  const stations: IStation[] = allStations && allStations[i18n.language];
  const dictStations = stations && GetStationsDictionary(stations);

  const originStationAria =
    schedule.fromStation &&
    removeSpecialCharacters(
      GetStationsDictionary(allStations[i18n.language])[schedule.fromStation]
        .stationName
    );

  const destinationStationAria =
    schedule.toStation &&
    removeSpecialCharacters(
      GetStationsDictionary(allStations[i18n.language])[schedule.toStation]
        .stationName
    );

  useEffect(() => {
    const urlParams = location.search.split("?")[1] || "";
    if (urlParams) {
      const step = QueryString.parse(urlParams)?.step as string;
      _setStep(step);
    }
  }, [location]);

  const handleBack = () => {
    switch (_step) {
      case "origin":
        handleClose();
        break;
      case "destination":
        navigate(
          `?page=routePlan&step=origin&fromStation=${schedule.fromStation}`
        );
        break;
      case "when":
        navigate(
          `?page=routePlan&step=destination&fromStation=${schedule.fromStation}&toStation=${schedule.toStation}`
        );
        break;
    }
  };

  const handleClose = () => {
    dispatch(setOriginStation(undefined));
    dispatch(setDestinationStation(undefined));
    dispatch(setDate(FormatDateYYYYMMDD(moment().toDate())));
    dispatch(setHours("00"));
    dispatch(setMinutes("00"));
    dispatch(setScheduleType(1));
    navigate("/");
  };

  const handleSwitchStations = () => {
    if (schedule.fromStation && schedule.toStation) {
      const originStation = dictStations[schedule.fromStation].stationId;
      const destinationStation = dictStations[schedule.toStation].stationId;
      dispatch(setOriginStation(destinationStation));
      dispatch(setDestinationStation(originStation));
    }
  };

  const handleSwitchClick = () => {
    handleSwitchStations();
    if (currentPage === "routePlanSearchResults".toLowerCase()) {
      dispatch(setPage("routePlan"));
      navigate(
        `?page=routePlan&step=when&fromStation=${schedule.toStation}&toStation=${schedule.fromStation}&date=${schedule.date}&hours=${schedule.hours}&minutes=${schedule.minutes}&scheduleType=${schedule.scheduleType}`
      );
    }
  };

  return (
    <Fragment>
      <div style={styles.mainWrapper}>
        <img
          onMouseOver={() => {
            _setIsBackButtonOver(true);
          }}
          onMouseLeave={() => {
            _setIsBackButtonOver(false);
          }}
          alt={`${t(`BackTo`, {
            step: `${
              _step === `destination` ? t(`From-where`) : t(`To-where`)
            }`,
          })}`}
          style={{
            ...styles.button,
            opacity: deviceMode === StyleMode.desktop ? ".85" : "1",
            filter: `brightness(${_isBackButtonOver ? ".8" : ""})`,
            rotate: i18n.dir() === "ltr" ? "180deg" : "0deg",
            margin: deviceMode === StyleMode.desktop ? "0 2rem" : "",
            // transform: `translateX(${
            //   i18n.dir() === "rtl" ? "-.5rem" : "-.5rem"
            // })`,
          }}
          src={
            deviceMode === StyleMode.desktop
              ? Images.backButton
              : Images.backButtonMob
          }
          onClick={handleBack}
          onKeyPress={handleBack}
          role={"button"}
          tabIndex={!IsMobile() ? 0 : -1}
          width={`auto`}
          height={`auto`}
        />
        {_step === `when` && deviceMode === StyleMode.desktop && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              // position: "absolute",
              width: "100%",
              gap: "1rem",
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "2rem",
                width: "90%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flex: "20",
                  justifyContent: "end",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: ".5rem",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{ ...styles.fromToTitle, flex: "1" }}
                    tabIndex={!IsMobile() ? 0 : -1}
                  >
                    {schedule.fromStation &&
                      dictStations[schedule.fromStation].stationName}
                  </span>
                  <img src={Images.origin} width={20} />
                </div>
              </div>
              <img
                width={20}
                src={Images.switchMob}
                style={{
                  cursor: "pointer",
                  // scale: "1.5",
                  rotate: "-90deg",
                  flex: 1,
                }}
                tabIndex={!IsMobile() ? 0 : -1}
                role={"button"}
                onClick={handleSwitchClick}
                alt={`switch direction`}
                aria-label={`${t("CurrentRoute")} ${t("From-station-to", {
                  origin: originStationAria,
                  destination: destinationStationAria,
                })}
            
            ${t("ChangeStationsAria", {
              fromStation: destinationStationAria,
              toStation: originStationAria,
            })}`}
              />
              {/* <img src={Images.dots_horizontal} style={{ width: "7rem" }} /> */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flex: "20",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: ".5rem",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={Images.destination} width={20} />
                  <span
                    style={{ ...styles.fromToTitle, flex: "1" }}
                    tabIndex={!IsMobile() ? 0 : -1}
                  >
                    {schedule.toStation &&
                      dictStations[schedule.toStation].stationName}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        {_step === `destination` && deviceMode === StyleMode.desktop && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // position: "absolute",
              width: "100%",
              gap: "1rem",
            }}
          >
            <div
              style={{ ...styles.fromToTitle }}
              tabIndex={!IsMobile() ? 0 : -1}
            >
              {`${t(`From`)}${i18n.language !== "he" ? " " : ""}${
                schedule.fromStation &&
                dictStations &&
                dictStations[schedule.fromStation].stationName
              }`}
            </div>
          </div>
        )}
        <img
          onMouseOver={() => {
            _setIsCloseButtonOver(true);
          }}
          onMouseLeave={() => {
            _setIsCloseButtonOver(false);
          }}
          alt={`${t(`ExistWizard`)}`}
          style={{
            ...styles.button,
            opacity: deviceMode === StyleMode.desktop ? ".85" : "1",
            filter: `brightness(${_isCloseButtonOver ? ".8" : ""})`,
            margin: deviceMode === StyleMode.desktop ? "0 2rem" : "",
          }}
          src={
            deviceMode === StyleMode.desktop
              ? Images.closeButton
              : Images.closeButtonMob
          }
          onClick={handleClose}
          onKeyPress={handleClose}
          role={"button"}
          tabIndex={!IsMobile() ? 0 : -1}
          width={`auto`}
          height={`auto`}
        />
      </div>
    </Fragment>
  );
};

const styles: StyleSheet = {
  mainWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  button: {
    cursor: "pointer",
    zIndex: "999999999",
  },
  fromToTitle: {
    fontSize: `1.7rem`,
    fontFamily: `FbReforma-Light,FbReforma-Medium, HelveticaNeue-Bold`,
    fontWeight: `${i18n.language === "ar" ? `600` : ``}`,
    textShadow: `rgb(0 0 0 / 70%) 0px 2px 30px`,
    color: `rgb(255, 255, 255)`,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    // marginTop: `2%`,
  },
};

export default RoutePlanButtons;
