import { useState } from "react";
import {
  IDuration,
  IRoutePlanFilterBar,
  ITypeOfSort,
} from "../../Interfaces/IRoutePlanSearchResults";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { travelDurations } from "../../Screens/RoutePlanSearchResults/Mobile/RoutePlanSearchResultsMobile";
import { formatDuration, toDuration, toMinutes } from "./DurationSlider";
import InputRange from "./InputRange";
import RouteBarFilterDropDown from "./RouteBarFilterDropDown";
import RouteBarSortBar from "./RouteBarSortDropDown";

const RoutePlanFilterBar: React.FC<IRoutePlanFilterBar> = (props) => {
  const {
    onSortClick,
    onFilterClick,
    onFilterClear,
    isMobile,
    maxChanges,
    rangeDuration,
  } = props;

  const [_trainChanges, _setTrainChanges] = useState<number>(maxChanges);
  const [_durationaValue, _setDurationaValue] = useState<number>(
    travelDurations(rangeDuration)[travelDurations(rangeDuration).length - 1]
  );
  const [_durationInterval, _setDurationInterval] = useState<IDuration[]>([]);

  const [_typeOfSort, _setTypeOfSort] = useState<ITypeOfSort[]>([
    { value: "choise", label: "בחירה" },
    { value: "duration", label: "משך נסיעה" },
    { value: "changes", label: "מספר החלפות" },
  ]);
  const [_selectedOption, _setSelectedOption] = useState("");

  const handleSortChanges = (
    sortOrder: "ascending" | "descending" | "noSort"
  ) => {
    sortOrder === "noSort"
      ? handleFilterClearClick()
      : onSortClick && onSortClick(sortOrder, _selectedOption);
  };

  const handleFilterApplyClick = () => {
    onFilterClick &&
      onFilterClick(_trainChanges.toString(), _durationaValue.toString());
  };

  const handleFilterClearClick = () => {
    onFilterClear && onFilterClear();

    _setTrainChanges(maxChanges);
    _setDurationaValue(
      travelDurations(rangeDuration)[travelDurations(rangeDuration).length - 1]
    );
  };

  const setSelectedHandler = (value: string) => {
    // debugger;
    if (
      _typeOfSort.filter((type) => type.label === value)[0].value === "choise"
    ) {
      handleFilterClearClick();
      _setSelectedOption(
        _typeOfSort.filter((type) => type.label === value)[0].value
      );
      handleSortChanges("noSort");
    } else {
      _setSelectedOption(
        _typeOfSort.filter((type) => type.label === value)[0].value
      );
    }
  };
  const durationIntervalHandler = (event: number) => {
    _setDurationaValue(toDuration(event));
  };

  return (
    <div style={{ ...styles.container, gap: isMobile ? "3rem" : "none" }}>
      <div
        style={{
          ...styles.sortWrapper,
          flexDirection: isMobile ? "column" : "row",
          gap: isMobile ? "1rem" : "none",
        }}
      >
        <div style={styles.sortTitle}>מיין תוצאות לפי</div>
        <div style={styles.sortDurationContainer}>
          <div style={styles.sortDurationContainerTitle}>מיון לפי</div>
          <div style={styles.sortDurationContainerDropDown}>
            <RouteBarFilterDropDown
              options={_typeOfSort.map((type) => type.label)}
              onValClick={(value) => {
                setSelectedHandler(value);
              }}
            />
          </div>
        </div>
        <div style={styles.sortChangesContainer}>
          <div style={styles.sortChangesContainerTitle}>סדר המיון</div>
          <div style={styles.sortChangesContainerDropDown}>
            <RouteBarSortBar
              onSortChange={handleSortChanges}
              sortOption={_selectedOption}
            />
          </div>
        </div>
        <div style={styles.btnContainerFilter}></div>
      </div>

      <div
        style={{
          ...styles.filterWrapper,
          flexDirection: isMobile ? "column" : "row",
          gap: isMobile ? "1.2rem" : "none",
        }}
      >
        <div style={styles.filterTitle}>הצג תוצאות לפי</div>
        <div style={styles.filterChangesContainer}>
          <div style={styles.filterChangesContainerTitle}>
            מספר החלפות מקסימלי
          </div>
          <div style={styles.filterChangesContainerDropDown}>
            <InputRange
              value={_trainChanges}
              min={1}
              max={maxChanges}
              step={1} // One minute step
              onChange={(e) => _setTrainChanges(e.target.valueAsNumber)}
              renderLabel={
                <div style={styles.sliderStyle}>{_trainChanges}</div>
              }
            />
          </div>
        </div>
        <div style={styles.filterDurationContainer}>
          <div style={styles.filterDurationContainerTitle}>
            משך נסיעה מקסימלי
          </div>
          <div style={styles.filterDurationContainerDropDown}>
            <InputRange
              value={toMinutes(_durationaValue)}
              min={toMinutes(travelDurations(rangeDuration)[0])}
              max={toMinutes(
                travelDurations(rangeDuration)[
                  travelDurations(rangeDuration).length - 1
                ]
              )}
              step={1} // One minute step
              onChange={(event) =>
                durationIntervalHandler(event.target.valueAsNumber)
              }
              renderLabel={
                <div style={styles.sliderStyle}>
                  {formatDuration(Number(_durationaValue))}
                </div>
              }
            />
          </div>
        </div>
        <div style={styles.btnContainer}>
          <button style={styles.btn} onClick={handleFilterApplyClick}>
            החל
          </button>
          <button style={styles.btn} onClick={handleFilterClearClick}>
            נקה
          </button>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  filterWrapper: {
    display: "flex",
    justifyContent: "space-around",
    // width: "70%",
    alignItems: "center",
    paddingBottom: "1rem",
  },

  sortWrapper: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    // width: "70%",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
    paddingBottom: "1rem",
  },
  sortDurationContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
  },
  sortChangesContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
  },

  filterChangesContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
  },
  filterDurationContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
  },
  sliderStyle: {
    border: "0.1rem solid rgb(205, 205, 205)",
    borderRadius: "2rem",
    padding: "0px 1rem",
    minWidth: "4rem",
    textAlign: "center",
    position: "relative",
  },
  btn: {
    backgroundColor: "rgb(249, 186, 77)",
    fontFamily: "OpenSans-Bold, HelveticaNeue-Bold",
    width: "10rem",
    height: "2.5rem",
    borderRadius: "24rem",
    border: "none",
    fontSize: "1rem",
    color: "rgb(40, 40, 40)",
    filter: "brightness(1)",
    cursor: "pointer",
  },
  btnContainerFilter: {
    width: "20rem",
  },
  // filterChangesContainer: { display: "flex", flexDirection:"column"},
  // sortChangesContainer: {},
};

export default RoutePlanFilterBar;
