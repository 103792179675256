import { useState } from "react";
import { IGallery } from "../../../Interfaces/IGallery";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import DefaultGallery from "./GalleryTypes/DefaultGallery/DefaultGallery";
import DefaultGalleryMobile from "./GalleryTypes/DefaultGallery/Mobile/DefaultGalleryMobile";
import ModalGallery from "./GalleryTypes/ModalGallery";

const Gallery: React.FC<IGallery> = (props) => {
  const { galleryType } = props;
  const [_currentItemIndex, _setCurrentItemIndex] = useState(0);

  const deviceMode = useDeviceMode();
  const GalleryDisplayedByType: React.FC = () => {
    switch (galleryType) {
      case "Default":
        if (deviceMode === StyleMode.desktop) {
          return <DefaultGallery {...props} />;
        } else {
          return <DefaultGalleryMobile {...props} />;
        }
      case "Modal":
        return <ModalGallery {...props} />;
      default:
        return null;
    }
  };

  return (
    <div
      style={{
        ...styles.galleryContainer,
        height:
          galleryType === "Default"
            ? deviceMode === StyleMode.desktop
              ? "42rem"
              : "30rem"
            : "",
        paddingBottom: "3rem",
      }}
    >
      <GalleryDisplayedByType />
    </div>
  );
};

const styles: StyleSheet = {
  galleryContainer: {
    width: "100%",
    maxWidth: "76rem",
    position: "relative",
  },
};

export default Gallery;
