import { Fragment, useState } from "react";
import Images from "../../../../Data/Images/Images";
import { IGalleryVideo } from "../../../../Interfaces/IGallery";
import Video from "../../../Video/Video";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import { t } from "i18next";
import { IsMobile } from "../../../../Services/DeviceService";

const GalleryVideo: React.FC<IGalleryVideo> = (props) => {
  const {
    media,
    header,
    videoStyles,
    thumbnail,
    isPlayButtonEnabled,
    onClick,
    isThumbnailAsBackground = true,
  } = props;
  const [_isPlaying, _setIsPlaying] = useState(false);

  const handlePlayButtonClick = () => {
    if (isPlayButtonEnabled) {
      _setIsPlaying(true);
      onClick && onClick();
    }
  };

  const handleVideoThumbnailClick = () => {
    onClick && onClick();
  };

  return (
    <Fragment>
      {!_isPlaying ? (
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          {isThumbnailAsBackground ? (
            <div
              style={{
                ...videoStyles,
                position: "relative",
              }}
             
              onClick={() => {
                handleVideoThumbnailClick();
              }}
            >
              {thumbnail._url && (
                <img
                  src={thumbnail._url}
                  style={{ ...videoStyles ,maxHeight:`100%`,maxWidth:`100%`}}
                  alt={``}
                  aria-label={header.text}
                />
              )}
              <img
                src={Images.play}
                style={styles.playIcon}
                width={`auto`}
                height={`auto`}
                role={"button"}
                tabIndex={!IsMobile() ? 0 : -1}
                aria-label={`${t(`PlayVideo`)} ${header.text}`}
                onClick={handlePlayButtonClick}
                onKeyPress={handlePlayButtonClick}
                alt={``}
              />
            </div>
          ) : (
            thumbnail._url && (
              <img
                src={thumbnail._url}
                style={{ ...videoStyles, objectFit: "cover" }}
                width={`auto`}
                height={`auto`}
                aria-label={header.text}
                alt={``}
              />
            )
          )}
        </div>
      ) : (
        <div
          style={{
            ...videoStyles,
            width: "100%",
            position: "relative",
          }}
        >
          {media && <Video _url={media._url} header={header.text} />}
        </div>
      )}
    </Fragment>
  );
};

const styles: StyleSheet = {
  playIcon: {
    position: "absolute",
    top: "calc(50% - 2rem)",
    left: "calc(50% - 2rem)",
    cursor: "pointer",
    zIndex: "2",
  },
};

export default GalleryVideo;
