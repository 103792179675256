import {isMobile,isBrowser,isTablet} from 'react-device-detect';
import { getDesktopMode, StyleMode } from './consts';
import { getMobileOS, GetSystemOS } from './useDeviceOS';

export const GetDeviceMode=(widthSize?:number)=>{

    let mode =isTablet?StyleMode.tablet:isMobile?StyleMode.mobile:StyleMode.desktop
     const OS = GetSystemOS()
     const browserZoom = Math.round(window.devicePixelRatio*100)/ (OS!==`Mac`?1:2)
    
    if(mode===StyleMode.desktop && widthSize){
        if(widthSize<=767){
            mode = StyleMode.mobile
        }
        else if(widthSize>=1024&&browserZoom!=200&& OS=== `Mac`){
            mode = StyleMode.desktop
        }
        else if(widthSize<1024&&browserZoom!=200&& OS!== `Mac`){
            mode = StyleMode.tablet
        }
    }
    
    
    // let currentZoom = getDesktopMode()[browserZoom]
    // switch (mode) {
    //     case StyleMode.mobile:
    //         document.body.setAttribute('style',`zoom:100%`)
    //         break;
    //     case StyleMode.tablet:
    //         document.body.setAttribute('style',`zoom:100%`)
    //         break;
        
    //     default:
    //         if(browserZoom>150){
    //             mode = StyleMode.tablet
    //         }
    //         else if(widthSize&&widthSize>=1024 && OS === `Mac`){
    //             document.body.setAttribute('style',`zoom:auto`)
    //         }
    //         else if(widthSize&&widthSize<=1024){
    //             document.body.setAttribute('style',`zoom:auto`)
    //         }
    //         else{
    //             document.body.setAttribute('style',`zoom:${100*currentZoom}%`)
    //         }
            
    //         break;
    // }

    return mode
}

  

  
