import { IQuestionsAndAnswersExtended } from "../../../Interfaces/IUmbraco";
import QuestionAndAnswerExtended from "./QuestionAndAnswerExtended";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import Title from "../Title";
import React, { useState, useRef, useEffect } from "react";
import QuestionAndAnswersExtendedFilterItem from "./QuestionAndAnswersExtendedFilterItem";
import { t } from "i18next";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import { StyleMode } from "../../../Utils/consts";

const QuestionsAndAnswersExtended: React.FC<IQuestionsAndAnswersExtended> = (
  props
) => {
  const { title, questionsAndAnswersExtended } = props;
  const deviceMode = useDeviceMode();

  const getDistinctedTags = () => {
    const tags: string[] = [];

    _questionsAndAnswersExtended.forEach((question) =>
      question.tag.forEach((tag) => {
        if (!tags.includes(tag.tagOptions)) {
          tags.push(tag.tagOptions);
        }
      })
    );

    return tags;
  };
  const [_selectedTag, _setSelectedTag] = useState("");
  const [_focusIndex, _setFocusIndex] = useState(0);
  const [_filterFocusIndex, _setFilterFocusIndex] = useState(0);
  const [_questionsAndAnswersExtended, _setQuestionsAndAnswersExtended] =
    useState(questionsAndAnswersExtended);

  const [_tags, _setTags] = useState(getDistinctedTags);
  const tagsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const filteredExtendedQuestions = [...questionsAndAnswersExtended].filter(
      (question) =>
        question.tag.find(
          (tag) => tag.tagOptions === _selectedTag || _selectedTag === ""
        )
    );
    _setQuestionsAndAnswersExtended(filteredExtendedQuestions);
  }, [_selectedTag]);

  useEffect(() => {
    //FilterOutOf
    if (tagsRef.current) {
      const children = Array.from(tagsRef.current.children);
      (children[0] as HTMLElement).tabIndex = 0;
    }
  }, []);

  const inTitle =
    title.length > 0
      ? title.indexOf(" ") !== -1
        ? title.split(" ")[0]
        : title
      : "";
  let firstSpaceIndex = title.indexOf(" ");
  let secondaryTitle = "";
  if (firstSpaceIndex !== -1) {
    secondaryTitle = title.substring(firstSpaceIndex + 1);
  } else {
    secondaryTitle = "";
  }

  return (
    <div
      style={{
        ...styles.container,
        marginTop: deviceMode !== StyleMode.desktop ? "4rem" : "",
      }}
    >
      {title && title.length > 0 && (
        <h1 style={styles.inTitle}>
          {inTitle}{" "}
          <span style={{ fontFamily: fonts.FbReformaMedium }}>
            {secondaryTitle}
          </span>
        </h1>
      )}
      <div
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "1rem",
            flexDirection: "row-reverse",
          }}
        >
          {_selectedTag && (
            <div
              style={styles.clearFilter}
              tabIndex={0}
              onClick={() => {
                _setSelectedTag("");
                if (tagsRef.current) {
                  const children = Array.from(tagsRef.current.children);
                  // (children[0] as HTMLElement).click();
                  // (children[0] as HTMLElement).focus();
                }
              }}
              onKeyPressCapture={(e) => {
                if (e.key === "Enter") {
                  _setSelectedTag("");
                  if (tagsRef.current) {
                    const children = Array.from(tagsRef.current.children);

                    (children[0] as HTMLElement).focus();
                  }
                }
              }}
              role={"button"}
              aria-label={t("Clean")}
            >
              {t("Clean")}
            </div>
          )}
          <div ref={tagsRef} style={styles.tags} className="filters">
            {_tags.map((tag, index) => (
              <QuestionAndAnswersExtendedFilterItem
                key={index}
                value={tag}
                isSelected={tag === _selectedTag}
                onClick={(selectedTag) => {
                  _setSelectedTag(selectedTag);
                }}
                total={_tags.length}
                index={index}
                onKeyDown={(filterItemRef, keyPressed, selectedIndex) => {
                  if (keyPressed === "ArrowLeft" && index === selectedIndex) {
                    if (index + 1 < _tags.length) {
                      if (filterItemRef.current) {
                        filterItemRef.current.blur();
                      }
                      _setFilterFocusIndex(index + 1);
                      _setSelectedTag(_tags[index + 1]);
                      if (tagsRef.current) {
                        const children = Array.from(tagsRef.current.children);
                        (children[index + 1] as HTMLElement).focus();
                      }
                    } else {
                      if (filterItemRef.current) {
                        filterItemRef.current.blur();
                      }
                      _setFilterFocusIndex(0);
                      _setSelectedTag(_tags[0]);
                      if (tagsRef.current) {
                        const children = Array.from(tagsRef.current.children);
                        (children[0] as HTMLElement).focus();
                      }
                    }
                  }
                  if (keyPressed === "ArrowRight" && index === selectedIndex) {
                    if (index - 1 >= 0) {
                      if (filterItemRef.current) {
                        filterItemRef.current.blur();
                      }
                      _setFilterFocusIndex(index - 1);
                      _setSelectedTag(_tags[index - 1]);
                      if (tagsRef.current) {
                        const children = Array.from(tagsRef.current.children);
                        (children[index - 1] as HTMLElement).focus();
                      }
                    } else {
                      _setFilterFocusIndex(_tags.length - 1);
                      _setSelectedTag(_tags[_tags.length - 1]);
                      if (tagsRef.current) {
                        const children = Array.from(tagsRef.current.children);
                        (children[_tags.length - 1] as HTMLElement).focus();
                      }
                    }
                  }
                }}
              />
            ))}
          </div>
        </div>
      </div>
      <div style={styles.content}>
        {_questionsAndAnswersExtended.map(
          (questionAndAnswerExtended, index) => (
            <div
              key={index}
              style={{
                borderBottom:
                  index < _questionsAndAnswersExtended.length - 1 ||
                  _questionsAndAnswersExtended.length > 1
                    ? `1px solid ${colors.gray}`
                    : ``,
                borderTop: index === 0 ? `1px solid ${colors.gray}` : ``,
                paddingTop: ".5rem",
                paddingBottom: ".5rem",
              }}
            >
              <QuestionAndAnswerExtended {...questionAndAnswerExtended} />
            </div>
          )
        )}
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    maxWidth: "76rem",
    width: "80%",
    margin: "0 auto",
  },
  inTitle: {
    fontSize: fontSize.H1,
    fontFamily: fonts.FbReformaLight,
    color: colors.darkBlue,
  },
  title: {
    position: "relative",
    color: colors.darkBlue,
    fontSize: fontSize.UmbracoElementTitle,
    fontFamily: fonts.FbReformaMedium,
    borderBottom: `1px solid ${colors.koromiko}`,
    borderBottomWidth: ".2rem",
  },
  content: {
    display: "flex",
    maxWidth: "52.5rem",
    flexDirection: "column",
    marginBottom: "4rem",
  },
  tags: {
    display: "flex",
    gap: "1rem",
    maxWidth: "100%",
    overflowX: "auto",
    paddingInlineStart: "1rem",
    paddingBottom: "1rem",
    alignItems: "center",
    flexWrap: "wrap",
  },
  clearFilter: {
    fontSize: fontSize.FooterItem,
    textDecoration: "underline",
    color: "rgb(0, 172, 236)",
    cursor: "pointer",
  },
};

export default QuestionsAndAnswersExtended;
