import { Fragment, useEffect, useRef, useState } from "react";
import { CgMenuLeftAlt, CgMenuRightAlt } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import colors from "../../../Assets/Colors/Colors.json";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import Images from "../../../Data/Images/Images";
import { IHeader } from "../../../Interfaces/IHeader";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { PageState } from "../../../Redux/Slices/pageSlice";
import {
  setDestinationStation,
  setOriginStation,
} from "../../../Redux/Slices/scheduleSlice";
import { IsMobile } from "../../../Services/DeviceService";
import i18n from "../../../Services/i18n";
import Hamburger from "../../Hamburger/Hamburger";
import Config from "../../../Configuration/server";

const HeaderMobile: React.FC<IHeader> = (props) => {
  const { headerContent } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPage = (
    useSelector((state: any) => state.PageReducer) as PageState
  ).page;
  const mouseSwipeHandlers = useSwipeable({
    trackMouse: true,
    onSwipedLeft: () => (i18n.dir() === "rtl" ? _setIsHamburgerOpen(true) : {}),
    onSwipedRight: () =>
      i18n.dir() === "ltr" ? _setIsHamburgerOpen(true) : {},
  });

  const [_isSearchVisible, _setIsSearchVisible] = useState(false);
  const [_isHamburgerOpen, _setIsHamburgerOpen] = useState(false);
  const [_menuClicked, _setMenuClicked] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    handleCloseSearch();
  }, [location]);

  useEffect(() => {
    if (_isSearchVisible) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [_isSearchVisible]);

  const handleOpenCloseHamburger = () => {
    _setMenuClicked(true);
    _setIsHamburgerOpen(!_isHamburgerOpen);
    const html = document.documentElement;
    if (html) {
      html.style.overflow = !_isHamburgerOpen ? "hidden" : "";
    }
  };

  const handleLogoClick = () => {
    dispatch(setOriginStation(undefined));
    dispatch(setDestinationStation(undefined));
    navigate("/");
  };

  const handleCloseSearch = () => {
    _setIsSearchVisible(false);
  };

  return (
    <Fragment>
      <div
        {...mouseSwipeHandlers}
        style={{
          ...styles.swipeOpenMenuStyles,
          right: i18n.dir() === "rtl" ? "0" : "",
          left: i18n.dir() === "ltr" ? "0" : "",
        }}
      />
      <div style={styles.wrapper}>
        <div style={styles.menuContainer}>
          {Config.IS_TENDERS === "false" && (
            <div
              style={{
                cursor: "pointer",
                color: "white",
                fontSize: fontSize.H1,
              }}
              onClick={handleOpenCloseHamburger}
              role={`button`}
              aria-label={headerContent?.hamburgerDescription}
              tabIndex={!IsMobile() ? 0 : -1}
              onKeyPressCapture={handleOpenCloseHamburger}
            >
              {i18n.dir() === "rtl" ? <CgMenuLeftAlt /> : <CgMenuRightAlt />}
            </div>
          )}
          <img
            src={Images.logoMobile}
            onClick={handleLogoClick}
            role={`button`}
            height={`auto`}
            alt={`${headerContent?.logoDescription}`}
            onKeyPressCapture={handleLogoClick}
            tabIndex={!IsMobile() ? 0 : -1}
          />
        </div>
      </div>
      {_isHamburgerOpen && (
        <Hamburger
          menuItems={headerContent?.menuCategories || []}
          headerContent={headerContent}
          onClose={handleOpenCloseHamburger}
        />
      )}
    </Fragment>
  );
};

const styles: StyleSheet = {
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    //position: "absolute",
    zIndex: 555,
    width: "100%",
    height: "0px",
    // top: "1.3rem",
  },
  menuContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "90%",
    position: "relative",
    top: "1.5rem",
    zIndex: "555",
  },
  menuItemsContainer: {
    display: "flex",
    marginInlineStart: "5rem",
    marginInlineEnd: "5rem",
    gap: "25px",
    color: colors.darkBlue,
  },
  swipeOpenMenuStyles: {
    position: "fixed",
    top: "0",
    width: "2rem",
    height: "100%",
    // border: "2px dashed gray",
    zIndex: "2",
  },
};

export default HeaderMobile;
