import { t } from "i18next";
import { ICarrerButton } from "../../../../Interfaces/ICareers";
import { IsMobile } from "../../../../Services/DeviceService";

const Button: React.FC<ICarrerButton> = (props) => {
  const { text, href, backgroundColor, style } = props;
  return (
    <button
      aria-label={t("LinkOpenExternalSite", {
        text,
      })}
      style={{ ...style, cursor: "pointer" }}
      onClick={() => {
        window.open(`${href}`, `_blank`);
      }}
      role={`link`}
      tabIndex={!IsMobile() ? 0 : -1}
    >
      {text}
    </button>
  );
};

export default Button; //
