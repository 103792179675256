import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import colors from "../../../../Assets/Colors/Colors.json";
import fonts from "../../../../Assets/Fonts/Fonts.json";
import { IIntermediateStation } from "../../../../Interfaces/IPis";
import { FormatHoursMinutes } from "../../../../Services/DateService";
import Images from "../../../../Data/Images/Images";
import store from "store2";
import Config from "../../../../Configuration/server";
import i18n from "../../../../Services/i18n";
import { GetStationsDictionary } from "../../../../Utils/helpers";
import { t } from "i18next";
import useDeviceMode from "../../../../Utils/useWindowDimensions";
import { StyleMode } from "../../../../Utils/consts";

const PisRouteItem: React.FC<
  IIntermediateStation & { isFirstItem: boolean; isLastItem: boolean }
> = (props) => {
  const {
    arrivalTime,
    stationNumber,
    originPlatform,
    order,
    arrivalStatus,
    isFirstItem,
    isLastItem,
    trainNumber,
    destinationStationNumber,
  } = props;

  const stations = store.session.get(
    `${Config.BASE_LOCALSTORAGE_NAME}stations`
  )[i18n.language];
  const dictStations = GetStationsDictionary(stations);
  const deviceMode = useDeviceMode();

  return (
    <div style={styles.container}>
      <div style={styles.row}>
        <div style={styles.firstSideContainer}>
          <span
            style={{
              fontFamily:
                isFirstItem || isLastItem
                  ? fonts.FbReformaMedium
                  : fonts.FbReformaRegular,
            }}
          >
            {FormatHoursMinutes(arrivalTime)}
          </span>
          <div style={styles.bulletContainer}>
            <img
              src={
                isFirstItem
                  ? Images.bulletBlack
                  : isLastItem
                  ? Images.destinationBlack
                  : Images.bulletWhite
              }
            />
            {arrivalStatus === "cancelled" && (
              <img src={Images.cancel} style={styles.cancel} />
            )}
          </div>
          <div style={styles.stationNameContainer}>
            {deviceMode === StyleMode.desktop &&
              isFirstItem &&
              trainNumber &&
              destinationStationNumber && (
                <div style={styles.firstStationContainer}>
                  <img src={Images.trainInCard} />
                  <span>{trainNumber}</span>
                  <span>
                    {t("To")}
                    {dictStations[destinationStationNumber].stationName}
                  </span>
                </div>
              )}
            <span
              style={{
                fontFamily:
                  isFirstItem || isLastItem
                    ? fonts.FbReformaMedium
                    : fonts.FbReformaRegular,
              }}
            >
              {dictStations[stationNumber].stationName}
            </span>
          </div>
        </div>
        <span
          style={{
            ...styles.firstSideContainer,
            color: arrivalStatus === "cancelled" ? colors.red : colors.black,
            fontFamily:
              arrivalStatus === "cancelled"
                ? fonts.FbReformaMedium
                : fonts.FbReformaRegular,
          }}
        >
          {arrivalStatus !== "cancelled"
            ? originPlatform !== -1
              ? `${t("Platform")} ${originPlatform}`
              : ""
            : t("Canceled")}
        </span>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    width: "100%",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  firstStationContainer: {
    display: "flex",
    gap: ".5rem",
    paddingBottom: "1rem",
    position: "absolute",
    minWidth: "20rem",
    top: "-1.5rem",
  },
  firstSideContainer: {
    display: "flex",
    gap: "1.5rem",
    alignItems: "center",
  },
  stationNameContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  bulletContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    width: "1.2rem",
    height: "1.2rem",
  },
  cancel: {
    position: "absolute",
  },
};

export default PisRouteItem;
