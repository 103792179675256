import { useState } from "react";
import colors from "../../../../../../../Assets/Colors/Colors.json";
import fonts from "../../../../../../../Assets/Fonts/Fonts.json";
import CustomModal from "../../../../../../../Components/CustomModal";
import Images from "../../../../../../../Data/Images/Images";
import { IAuctionsSystemWinSupplier } from "../../../../../Interfaces_tenders/IAuctionsSystemDetails";
import { StyleSheet } from "../../../../../Interfaces_tenders/IStyleSheet";

const AuctionSuppliers: React.FC<IAuctionsSystemWinSupplier> = (props) => {
  const { winningSupplierArr, waitingSupplierArr, dynamicTextsDict } = props;

  const waitingSupplierExist =
    waitingSupplierArr && waitingSupplierArr.length > 0;

  const dynamicTexts =
    dynamicTextsDict && dynamicTextsDict.length > 0
      ? dynamicTextsDict[0]
      : undefined;

  const [_isWinSupplierModalOpen, _setIsWinSupplierModalOpen] = useState(false);

  return (
    <div style={styles.suppliersContainer}>
      <div style={styles.referent}>
        {winningSupplierArr.length > 0 &&
          dynamicTexts &&
          dynamicTexts["winSupplierListTitile"].text}
      </div>
      <div style={styles.auctionsWinningContainer}>
        {!waitingSupplierExist &&
          winningSupplierArr.slice(0, 4).map((supplier, index) => (
            <div style={styles.referent} key={index}>
              <div style={styles.referentRow}>
                <img src={Images.person}></img>
                <div style={styles.winSupplierMulti} title={supplier}>
                  {supplier}
                </div>
              </div>
            </div>
          ))}
        {waitingSupplierExist && (
          <div style={{ width: "100%", display: "flex" }}>
            <div style={styles.referent}>
              <div style={styles.referentRow}>
                <img src={Images.person}></img>
                <div>
                  {dynamicTexts && dynamicTexts["winSupplier"].text}:{" "}
                  {winningSupplierArr[0]}
                </div>

                <div></div>
              </div>
            </div>
            <div style={styles.referent}>
              <div style={styles.referentRow}>
                <img src={Images.person}></img>
                <div>
                  {dynamicTexts && dynamicTexts["waitingSupplier"].text}:{" "}
                  {waitingSupplierArr[0]}
                </div>
              </div>
            </div>
          </div>
        )}

        {!waitingSupplierExist && winningSupplierArr.length > 4 && (
          <div style={styles.winSupplier} tabIndex={0}>
            <span
              onClick={() => {
                _setIsWinSupplierModalOpen(true);
              }}
            >
              {dynamicTexts && dynamicTexts["winSupplierList"].text}
            </span>
          </div>
        )}
      </div>
      <CustomModal visible={_isWinSupplierModalOpen}
        innerStyle={{ paddingBottom: 0 }}
        contentStyle={{ padding: 0 }}
        onClose={() => _setIsWinSupplierModalOpen(false)} showCloseButton="external" >
        <div
          style={{
            ...styles.auctionsWinningContainer,
            padding: "1.45rem",
            fontSize: "1.3rem",
          }}
        >
          {winningSupplierArr &&
            !waitingSupplierExist &&
            winningSupplierArr.map((auction, index) => (
              <div style={styles.referent} key={index}>
                <div style={styles.referentRow}>
                  <img src={Images.person}></img>
                  <div>{auction}</div>

                  <div></div>
                </div>
              </div>
            ))}
        </div>
      </CustomModal>
    </div>
  );
};

const styles: StyleSheet = {
  referent: {
    display: "flex",
    gap: "1rem",
    width: "50%",
    paddingBottom: "0.5rem",
  },
  referentRow: {
    display: "flex",
    justifyContent: "space-between",
    gap: "0.2rem",
  },

  auctionsWinningContainer: {
    display: "flex",
    flexWrap: "wrap",
  } as React.CSSProperties,

  winSupplier: {
    fontFamily: fonts.FbReformaRegular,
    fontSize: "1.125rem",
    color: colors.cyanBold,
    cursor: "pointer",
  } as React.CSSProperties,

  suppliersContainer: {
    height: "5rem",
  },

  winSupplierMulti: {
    width: "15rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  } as React.CSSProperties,
};

export default AuctionSuppliers;
