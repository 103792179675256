import { Fragment } from "react";
import { IHeader } from "../../../Interfaces_tenders/IHeader";


const HeaderMobile: React.FC<IHeader> = (props) => {

  return (
    <Fragment>
    
    </Fragment>
  );
};

export default HeaderMobile;