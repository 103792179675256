import React, { useState } from "react";
import { StyleSheet } from "../../Interfaces/IStyleSheet";

interface IRouteBarFilterDropDown {
  options: number[] | string[];
  onValClick?: (val: string ) => void;
}

const RouteBarFilterDropDown: React.FC<IRouteBarFilterDropDown> = ({
  options,
  onValClick,
}) => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionSelect = (option: string) => {
    setSelectedOption(option);
    onValClick && onValClick(option);
  };

  return (
    <div style={styles.container}>
      <select style={styles.select}
        value={selectedOption}
        onChange={(e) => handleOptionSelect(e.target.value)}
      >
        {/* <option value="">בחר אפשרות</option> */}
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    // maxWidth: "18.6rem",
    // width: "100%",
    // height: "2.5rem",
    // borderRadius: "1.25rem",
    // gap: ".3rem",
    // padding: "0 .5rem",
    // position: "relative",
    // zIndex: "30",
    
  },
  select:{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "18.6rem",
    width: "11.5625rem",
    height: "2.5rem",
    borderRadius: "1.25rem",
    gap: "0.3rem",
    padding: "0px 0.5rem",
    position: "relative",
    zIndex: 30,
    border: "0.0625rem solid rgb(205, 205, 205)"
  }
};

export default RouteBarFilterDropDown;
