import { ReactNode } from "react";

interface IInputRange {
  renderLabel?: ReactNode;
  onChange?: (e: any) => void;
  max?: number | string;
  value?: number | string;
  min?: number | string;
  step?: number;
}
const InputRange: React.FC<IInputRange> = (props) => {
  const { max, value, min, step, onChange, renderLabel } = props;
  return (
    <div>
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={onChange}
      />
      <div>{renderLabel ? renderLabel : <p>{value}</p>}</div>
    </div>
  );
};

export default InputRange;
