import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import { ITextAndLink } from "../../Interfaces/IBaseElement";
import { StyleSheet } from "../../Interfaces/IStyleSheet";

type ITextAndLinkProps = {
  disabled?: boolean,
  style?: React.CSSProperties
} & ITextAndLink

const TextAndLink: React.FC<ITextAndLinkProps> = (props) => {
  const { link, text, disabled, style } = props;
  const styleDisabled = disabled ? styles.disabled : {}
  const myStyles: React.CSSProperties = { ...styles.link, ...style, ...styleDisabled }

  return (<a href={link} style={myStyles}>{text}</a>);
};

const styles: StyleSheet = {
  link: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaRegular,
  },
  disabled: {
    pointerEvents: "none"
  }
};

export default TextAndLink;
