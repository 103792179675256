import axios from "axios";
import Config from "../Configuration/server";
import { headers } from "./GlobalService";
export const CONTENTMANAGEMENT = Config.RJPA_URL;

export const UmbracoSearch = async (
  term: string,
  language: string
): Promise<any | undefined> => {
  try {
    const Url = `${Config.UMBRACO_CONTENT}/api/RailWeb/Search?term=${term}&culture=${language}`;
    // const Url = `https://localhost:44349/api/RailWeb/Search?term=${term}&culture=${language}`;
    const response = await axios(Url, {
      method: "GET",
      headers: {
        "Ocp-Apim-Subscription-Key": Config["OCP_APIM_SUBSCRIPTION_KEY"]!,
      },
    });
    const data = await response.data.result;
    return data;
  } catch (error) {
    // console.log(error);
  }
};
