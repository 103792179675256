import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import { ICustomerServiceItem } from "../../../Interfaces/ICustomerServices";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import i18n from "../../../Services/i18n";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../Services/DeviceService";

const CustomerServiceHeadlineItem: React.FC<ICustomerServiceItem> = (props) => {
  const { icon, mode, serviceName, onSelect } = props;

  const deviceMode = useDeviceMode();

  return (
    <div
      style={{
        ...styles.container,
        cursor: !mode.includes("Only Mobile") ? "pointer" : "text",
      }}
      onClick={() => {
        if (mode.includes("Only Mobile")) {
          if (deviceMode === StyleMode.mobile) {
            onSelect(props);
          }
        } else {
          onSelect(props);
        }
      }}
      role={`button`}
      tabIndex={!IsMobile() ? 0 : -1}
      aria-label={`${serviceName}`}
    >
      <img
      src={icon._url}
       
      ></img>
      <div style={styles.serviceName}>{serviceName}</div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
  },

  serviceName: {
    color: colors.nero,
    fontSize: fontSize.Paragraph,
    fontFamily: fonts.FbReformaLight,
    letterSpacing: "0.02125rem",
  },
};

export default CustomerServiceHeadlineItem;
