import FileSaver from "file-saver";
import JSZip from "jszip";
import { IFilesToDownload } from "../Interfaces/IFileViewer";
import { IUmbracoFile } from "../Interfaces/IUmbraco";

export const DownloadFilesAsZipFile = async (
  files: IUmbracoFile[],
  filename: string
) => {
  const zip = new JSZip();
  const folder = zip.folder(filename);
  if (folder) {
    const filesToDownload: IFilesToDownload[] = [];
    for (let i = 0; i < files.length; i++) {
      if (files[i].document === null) continue;
      const res = await fetch(files[i].document._url);
      const blob = await res.blob();
      const fileToDownload: IFilesToDownload = {
        file: files[i],
        blob,
      };
      filesToDownload.push(fileToDownload);
    }
    for (let i = 0; i < filesToDownload.length; i++) {
      let fileType;
      
      if(filesToDownload[i].file.filename.includes(".")) {
        fileType = filesToDownload[i].file.filename.split(".").pop();
      } else {
        const dotsLength = filesToDownload[i].file.document._url.split(".").length;
        fileType = filesToDownload[i].file.document._url.split(".")[dotsLength - 1];
      }
      
      switch (fileType) {
        case "vnd.openxmlformats-officedocument.wordprocessingml.document":
          fileType = "docx";
          break;
          case "msword":
          fileType = "doc";
          break;
        case "vnd.ms-excel":
          fileType = "xls";
          break;
          case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          fileType = "xlsx";
          break;
      }
      folder.file(
        `${filesToDownload[i].file.filename}.${fileType}`,
        filesToDownload[i].blob
      );
    }
    zip.generateAsync({ type: "blob" }).then(function (content) {
      FileSaver.saveAs(content, `${filename}.zip`);
    });
  }
};
