import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import Images from "../../Data/Images/Images";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { UmbracoStoreState } from "../../Redux/Slices/umbracoSlice";
import { GetDateAsTextFormat } from "../../Services/DateService";


const PrintControl = React.forwardRef((props:any, ref)=>{
  const {_routes } = props;

  let departureTime = _routes[0]?.stations[0]?.departureTime
  const umbracoStore = (
    useSelector((state: any) => state.UmbracoStoreReducer) as UmbracoStoreState
  ).umbracoStore;
  return (
    <div className={`printing show-print`}>
      <div className="top-banner">
        <div className="logo">
    
          <img src={umbracoStore.headerContent?.logo._url} 
            width={`auto`} height={`auto`} alt={``}   
          />
        </div>
      <div className="header-print">    
        {_routes.length > 0 &&
          <>
          <div className="data-time-print">
              {GetDateAsTextFormat(new Date(departureTime))}  {moment(departureTime).format('HH:mm')}
            </div>
          <div className="from-to-print">

              {_routes[0]?.stations[0]?.stationName}<img src={Images.switch} style={{paddingInline:".5rem"}} 
                width={`auto`} height={`auto`} alt={``}   
              /> {_routes[0]?.stations[_routes[0]?.stations.length - 1]?.stationName}
          </div>
          </>
          }
        </div>
      </div>
      <div className="print-content"/>
    </div >
    
  );
});


const styles: StyleSheet = {
  routeDetails: {
    display: "flex",
    flexDirection: "column",
  },
  routeDetailsHeader: {
    display: "flex",
    justifyContent: "space-between",
    marginInlineStart: "1rem",
    marginInlineEnd: "1rem",
    marginTop: "2rem",
    lineHeight: "4rem",
    borderBottom: `.1rem solid ${colors.pastelGray}`,
  },
  routeDetailsTitle: {
    fontWeight: "bold",
  },
  routeDetailsOptions: {
    display: "flex",
    gap: "1rem",
  },
  routeDetailsBody: {
    margin: "1rem",
    maxHeight: "80vh",
    overflow: "auto",
  },
  change: {
    paddingBottom: "10px",
    color: colors.darkBlue,
    display: "flex",
    alignItems: "center",
  },
  time: {
    height: "20px",
    padding: "0 10px",
    boxSizing: "border-box",
    fontWeight: "bold",
    minWidth: "100px",
    fontFamily: fonts.FbReformaRegular,
  },
};

export default PrintControl;
