import { Actions } from "../consts";

const init_state = "";

export const TreeReducer = (state = init_state, action: any) => {
  switch (action.type) {
    case Actions.setTree:
      state = action.payload;
      return state;
    default:
      return state;
  }
};