import { t } from "i18next";
import { useEffect, useState } from "react";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import TagName from "../../TagName";
import QuestionAndAnswerNew from "./QuestionAndAnswerNew";

interface IQuestionsAndAnswers {
  answer: string;
  question: string;
  categories: string[];
}
export interface IQuestionsAndAnswersCatagories {
  title: string;
  inTitle: string;
  questionsAndAnswers: IQuestionsAndAnswers[];
}
const CATEGORIES: string[] = [
  "schedules",
  "safetyModifier",
  "trainRide",
  "prices",
  "animals",
];

const QuestionsAndAnswersCategories: React.FC<
  IQuestionsAndAnswersCatagories
> = (props) => {
  const { inTitle, title, questionsAndAnswers } = props;
  const [_questionsAndAnswers, _setQuestionsAndAnswers] =
    useState<IQuestionsAndAnswers[]>();
  const [_categories, _setCategories] = useState<string[]>([]);
  const [_category, _setCategory] = useState<string>("");
  const [_activeAnswer, _setActiveAnswer] = useState<number>();
  useEffect(() => {
    let categories = questionsAndAnswers.reduce(
      (prev: string[], current: IQuestionsAndAnswers) => {
        prev.push(...current.categories);
        return prev;
      },
      []
    );
    let newCategories = categories.reduce((prev: string[], current: string) => {
      if (!prev.includes(current)) {
        prev.push(current);
      }
      return prev;
    }, []);

    _setCategories(newCategories);
    _setCategory(newCategories[0]);
    return () => {};
  }, []);

  return (
    <div className="max-width" style={{ padding: "2rem" }}>
      <div style={{ display: "flex", flexDirection: "column", gap: "2rem",   fontFamily:fonts.FbReformaRegular, }}>
        <h1
          style={{
            // fontSize:"3rem",
            flex: 1,
            color: colors.darkBlue,
            fontSize: "32px",
            fontWeight: "normal",
            letterSpacing: "2.46px",
            margin: 0,
            lineHeight: 1.2,
         

          }}
        >
          {title}{" "}
          {inTitle && (
            <span className="inTitle" style={{ fontWeight: "bold" ,fontFamily:fonts.FbReformaMedium}}>
              {inTitle}
            </span>
          )}
        </h1>
        <div
          className="questionsAndAnswers"
          style={{ display: "flex", flexDirection: "column", gap: "2rem" }}
        >
          <div className="row" style={{ gap: "1rem", flexWrap: "wrap" }}>
            {_categories.map((category, index) => (
              <TagName
                key={index}
                name={t(CATEGORIES[Number(category.split("^")[0])])}
                active={category === _category}
                onClick={() => {
                  _setActiveAnswer(-1);
                  _setCategory(category);
                }}
              />
            ))}
          </div>
          <div
            style={{ display: "flex", flexDirection: "column"}}
          >
            {questionsAndAnswers
              .filter((item) => item.categories?.includes(_category))
              .map((questionAndAnswer, index,{length}) => (
                <QuestionAndAnswerNew
                  firstItem={index === 0}
                 // lastItem={ index === length - 1  || length > 1}
                  key={index}
                  onClick={() => {
                    _setActiveAnswer(_activeAnswer !== index ? index : -1);
                  }}
                  question={questionAndAnswer.question}
                  active={_activeAnswer === index}
                  answer={questionAndAnswer.answer}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionsAndAnswersCategories;
