import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IAuctionsSystemTypes } from "../../Auctions/src/Interfaces_tenders/IAuctionsSystemDetails";

export interface AuctionState {
  tenderType: IAuctionsSystemTypes[];
}

const initialState: AuctionState = {
  tenderType: [],
};

export const tendersSlice = createSlice({
  name: "tender",
  initialState,
  reducers: {
    setAuctionsTypes: (state, action: any) => {
      state.tenderType = action.payload;
    },
    
  },
});

export const { setAuctionsTypes } = tendersSlice.actions;

export default tendersSlice.reducer;
