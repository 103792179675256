import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { ITravelMessage } from "../../../Interfaces/ITravel";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";

const TravelMessage: React.FC<ITravelMessage> = (props) => {
  const { message, sevirity, title, isLastMessage } = props;
  return (
    <li
      style={{
        ...styles.container,
        backgroundColor: sevirity === 2 ? colors.appleRed : "#00ABE8",
        borderBottomRightRadius: isLastMessage ? "1rem" : "",
        borderBottomLeftRadius: isLastMessage ? "1rem" : "",
      }}
    >
      <span
        style={{
          ...styles.title,
          color: sevirity === 2 ? colors.white : colors.black,
        }}
      >
        {title}
      </span>
      <span
        style={{
          ...styles.message,
          color: sevirity === 2 ? colors.white : colors.black,
        }}
      >
        {message}
      </span>
    </li>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "1rem",
    padding: "1rem",
  },
  title: {
    fontFamily: fonts.FbReformaMedium,
    color: colors.white,
    paddingInlineStart: "2rem",
  },
  message: {
    fontFamily: fonts.FbReformaRegular,
    color: colors.white,
    paddingInlineStart: "2rem",
  },
};

export default TravelMessage;
