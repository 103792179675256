import { t } from "i18next";
import QueryString from "qs";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import colors from "../Assets/Colors/Colors.json";
import fonts from "../Assets/Fonts/Fonts.json";
import Loading from "../Components/Loading/Loading";
import Breadcrumb from "../Components/UmbracoElements/Breadcrumb";
import SearchResultsMerged from "../Components/UmbracoElements/SearchResults/SearchResultsMerged";
import Images from "../Data/Images/Images";
import { IFilterItem } from "../Interfaces/IFilterItem";
import {
  ISearchResultsCategory,
  ISearchResultsPage,
} from "../Interfaces/ISearchResultsItems";
import { StyleSheet } from "../Interfaces/IStyleSheet";
import { ITreeData } from "../Interfaces/ITree";
import {
  ISearchResultsContent,
  IUmbracoSearchResults,
} from "../Interfaces/IUmbraco";
import { UmbracoStoreState } from "../Redux/Slices/umbracoSlice";
import { UmbracoSearch } from "../Services/ContentManagementService";
import i18n from "../Services/i18n";
import { ChangeTitle, StyleMode } from "../Utils/consts";
import { GetSearchedItems } from "../Utils/searchResultsHelper";
import useDeviceMode from "../Utils/useWindowDimensions";
import fontSize from "../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../Services/DeviceService";

const SearchResults: React.FC = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [_isLoading, _setIsLoading] = useState(true);
  const [_searchedItems, _setSearchedItems] = useState<ISearchResultsContent>();
  const [_categoriesAndPages, _setCategoriesAndPages] = useState<
    (ISearchResultsCategory | ISearchResultsPage)[]
  >([]);
  const [_umbracoResults, _setUmbracoResults] = useState<IUmbracoSearchResults>(
    {
      categoriesAndPages: [],
      exemptionNoticesDto: [],
      singleSupplierDto: [],
      planningAndRatesDto: [],
      tenderDocumentsDto: [],
      tendersDto: [],
    }
  );
  const [_allCategories, _setAllCategories] = useState<
    ISearchResultsCategory[]
  >([]);
  const [_filters, _setFilters] = useState<IFilterItem[]>([]);
  const [_defaultSearchValue, _setDefaultSearchValue] = useState("");

  const [_totalFilters, _setTotalFilters] = useState(0);

  const currentTree = (
    useSelector((state: any) => state.UmbracoStoreReducer) as UmbracoStoreState
  ).umbracoStore.treeData as ITreeData;

  const umbracoStore = (
    useSelector((state: any) => state.UmbracoStoreReducer) as UmbracoStoreState
  ).umbracoStore;

  let isCardDisplay = false;
  const location = useLocation();
  const navigate = useNavigate();
  const deviceMode = useDeviceMode();
  const titleRef = useRef<HTMLDivElement>(null);

  const handleTotalQuantity = (count: number) => {
    _setTotalFilters(count);
  };

  const isSafariOs = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.tabIndex = 0;
      titleRef.current.focus();
      titleRef.current.style.outline = "none";
      titleRef.current.tabIndex = -1;
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      const queryObj = QueryString.parse(location.search);
      const searchResultsContent = umbracoStore.searchResults;
      if (isMounted) {
        _setSearchedItems(searchResultsContent);
      }

      ChangeTitle(
        `${t("SearchResults")} ${queryObj.term ? `:${queryObj.term}` : ""}`
      );

      if (queryObj.term) {
        const term = queryObj.term as string;
        _setDefaultSearchValue(queryObj.term.toString() || "");
        if (term.length > 0) {
          const umbracoSearchData = (await UmbracoSearch(
            term,
            i18n.language
          )) as IUmbracoSearchResults;

          if (isMounted) {
            _setIsLoading(false);
            if (umbracoSearchData) {

              const umbracoResultsObj = {
                categoriesAndPages: [],
                exemptionNoticesDto:
                  umbracoSearchData.exemptionNoticesDto !== null
                    ? umbracoSearchData.exemptionNoticesDto
                    : [],
                singleSupplierDto:
                  umbracoSearchData.singleSupplierDto !== null
                    ? umbracoSearchData.singleSupplierDto
                    : [],
                planningAndRatesDto:
                  umbracoSearchData.planningAndRatesDto !== null
                    ? umbracoSearchData.planningAndRatesDto
                    : [],
                tenderDocumentsDto:
                  umbracoSearchData.tenderDocumentsDto !== null
                    ? umbracoSearchData.tenderDocumentsDto
                    : [],
                tendersDto:
                  umbracoSearchData.tendersDto !== null
                    ? umbracoSearchData.tendersDto
                    : [],
              }


              _setUmbracoResults({...umbracoResultsObj});
            }

            const inputValue = term;
            const categories = currentTree.categories;

            const searchResults = GetSearchedItems(inputValue, categories);
            _setCategoriesAndPages(searchResults);
          }
        }
      } else {
        _setIsLoading(false);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [location]);

  const handleClearInput = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      inputRef.current.focus();
    }
  };

  const handleSearch = () => {
    const textToSearch = inputRef.current?.value as string;
    if (textToSearch?.length > 0) {
      navigate(`?page=searchResults&term=${textToSearch}`);
    }
  };
  return (
    <div
      style={{
        ...styles.mainContainer,
      }}
    >
      <div
        ref={titleRef}
        style={{ position: "absolute", zIndex: -1, color: "transparent" }}
        tabIndex={!IsMobile() ? 0 : -1}
      >
        {t("CurrentPage", {
          page: t("SearchResults"),
        })}
      </div>
      <div
        style={{
          ...styles.cover,
          background: `30% 30% / cover url(${Images.coverCloseImage})`,
          height:
            deviceMode === StyleMode.desktop
              ? isCardDisplay
                ? "40vh"
                : "20.5vh"
              : "",
          minHeight:
            deviceMode === StyleMode.desktop
              ? isCardDisplay
                ? "17.18rem"
                : "14.25rem"
              : "",
        }}
      >
        <div className="cover-color-blur">
          <div
            style={{
              ...styles.coverElements,
              width: deviceMode === StyleMode.desktop ? "80%" : "75%",
            }}
            className="container"
          >
            <div
              style={{
                ...styles.breadcrumb,
                paddingTop: deviceMode === StyleMode.desktop ? "2rem" : "3rem",
              }}
            >
              <Breadcrumb
                items={[
                  { text: _searchedItems?.title || "", link: "searchResults" },
                ]}
              />
            </div>
            <h1
              style={{
                ...styles.title,
                paddingTop: deviceMode === StyleMode.desktop ? "2rem" : "3rem",
              }}
            >
              {_searchedItems?.title}
            </h1>
          </div>
        </div>
      </div>
      <div
        style={{
          ...styles.body,
          paddingTop: deviceMode !== StyleMode.desktop ? "2rem" : "",
        }}
      >
        <div style={styles.content}>
          <div
            style={{
              ...styles.searchArea,
              flexWrap: deviceMode !== StyleMode.desktop ? "wrap" : "nowrap",
            }}
          >
            <div style={{ ...styles.searchBox }}>
              <img
                src={_searchedItems?.searchIcon._url}
                style={{
                  ...styles.searchIcon,
                  marginInlineStart: isSafariOs ? "auto" : "1rem",
                }}
              />
              <input
                ref={inputRef}
                type={"text"}
                defaultValue={_defaultSearchValue}
                style={styles.input}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
              <img
                role={"button"}
                aria-label={t("ClearSearch")}
                style={styles.clearIcon}
                tabIndex={!IsMobile() ? 0 : -1}
                src={Images.close}
                onClick={handleClearInput}
                alt={``}
              />
            </div>
            <div>
              <button
                style={{
                  ...styles.searchButton,
                  width: isSafariOs ? "" : "10rem",
                  padding: isSafariOs ? "0px 2rem" : "",
                }}
                onClick={handleSearch}
                tabIndex={!IsMobile() ? 0 : -1}
              >
                {_searchedItems?.searchButtonValue}
              </button>
            </div>
          </div>

          <div
            style={{
              ...styles.totalResultsSummery,
              paddingRight: i18n.dir() === "rtl" ? "3rem" : "",
              paddingLeft: i18n.dir() === "ltr" ? "3rem" : "",
            }}
          >
            {t("weFound")} {_totalFilters} {t("foundResults")} "
            {_defaultSearchValue}"
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginInlineStart: "3rem",
              marginInlineEnd: "3rem",
              paddingTop: "2rem",
            }}
          >
            {_isLoading ? (
              <Loading
                message={t("SearchingResults")}
                containerStyles={{ justifyContent: "start" }}
              />
            ) : _categoriesAndPages.length === 0 &&
              _umbracoResults.exemptionNoticesDto.length === 0 &&
              _umbracoResults.singleSupplierDto.length === 0 &&
              _umbracoResults.tendersDto.length === 0 &&
              _umbracoResults.planningAndRatesDto.length === 0 ? (
              <h1 style={styles.noResults}>{t("NoResultsFound")}</h1>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    paddingBottom: "1rem",
                  }}
                >
                  {deviceMode === StyleMode.desktop && (
                    <div style={styles.filtersTitle}>{t("Filter-by")}:</div>
                  )}
                </div>
                <SearchResultsMerged
                  categoriesAndPages={_categoriesAndPages}
                  exemptionNoticesDto={_umbracoResults.exemptionNoticesDto}
                  singleSupplierDto={_umbracoResults.singleSupplierDto}
                  planningAndRatesDto={_umbracoResults.planningAndRatesDto}
                  tendersDto={_umbracoResults.tendersDto}
                  tenderDocumentsDto={_umbracoResults.tenderDocumentsDto}
                  term={_defaultSearchValue}
                  passCount={handleTotalQuantity}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  mainContainer: {
    alignItems: "center",
    flexDirection: "column",
    zIndex: "10",
    minHeight: "100vh",
  },
  cover: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "20.5vh",
    position: "relative",
    minHeight: "14.25rem",
  },
  title: {
    color: colors.white,
    fontSize: fontSize.PageTitle,
    lineHeight: "3rem",
    letterSpacing: "0.05625rem",
    textShadow: `2px 2px ${colors.black}`,
    fontFamily: fonts.FbReformaMedium,
  },
  breadcrumb: {
    display: "flex",
    color: colors.white,
    fontSize: fontSize.Text,
    letterSpacing: "0.05625rem",
    fontFamily: fonts.FbReformaRegular,
    position: "absolute",
  },
  coverElements: {
    gap: "3rem",
    maxWidth: "76rem",
    margin: "auto",
    lineHeight: "2.5rem",
    padding: "1rem",
  },
  body: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "90rem",
  },
  searchArea: {
    padding: "2.5rem",
    display: "flex",
    justifyContent: "center",
    gap: "1.5rem",
  },
  searchBox: {
    display: "flex",
    alignItems: "center",
    height: "2.5rem",
    borderRadius: "1.25rem",
    border: `1px solid ${colors.pastelGray}`,
    width: "90%",
  },
  searchIcon: {
    transform: "scale(.8)",
    // marginInline: "1rem",
  },
  clearIcon: {
    cursor: "pointer",
    margin: "0 .25rem",
    scale: ".5",
  },
  searchButton: {
    // width: "10rem",
    height: "2.5rem",
    backgroundColor: colors.darkBlue,
    border: `1px solid ${colors.darkBlue}`,
    borderRadius: "1.25rem",
    color: colors.white,
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.Text,
    letterSpacing: "0.029375rem",
    cursor: "pointer",
  },
  input: {
    flex: "10",
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Text,
  },
  filters: {
    display: "flex",
    gap: "2rem",
  },
  noResults: {
    color: colors.gray,
    fontSize: fontSize.H1,
    letterSpacing: "0.039375rem",
    fontFamily: fonts.FbReformaMedium,
    maxWidth: "35rem",
    lineHeight: "2rem",
    padding: "1.45rem",
  },

  totalResultsSummery: {
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.FooterItem,
  },
};

export default SearchResults;
