import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import Images from "../../../Data/Images/Images";
import { IPisInformation } from "../../../Interfaces/IPis";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import { StyleMode } from "../../../Utils/consts";

const PisInformation: React.FC<IPisInformation> = (props) => {
  const { text } = props;

  const deviceMode = useDeviceMode();

  return (
    <div style={styles.informationContainer}>
      <img src={Images.note} />
      <span
        style={{
          ...styles.information,
          fontSize:
            deviceMode === StyleMode.desktop
              ? fontSize.FooterItem
              : fontSize.Text,
        }}
      >
        {text}
      </span>
    </div>
  );
};

const styles: StyleSheet = {
  informationContainer: {
    display: "flex",
    gap: "1rem",
  },
  information: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.FooterItem,
  },
};

export default PisInformation;
