import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { IRow } from "../../../Interfaces/IUmbraco";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import ImageAndLink from "./ImageAndLink";
import fonts from "../../../Assets/Fonts/Fonts.json";


const Row: React.FC<IRow> = (props) => {
  const { imagesAndLinks ,direction ="Start" } = props;
  const deviceMode = useDeviceMode();

  return (
    <div
      style={{
        ...styles.container,
        flexDirection: deviceMode === StyleMode.desktop ? "row" : "column",
        justifyContent: direction?direction.toLowerCase()===`center`?`space-around`:direction.toLowerCase():'start',
        fontFamily: fonts.FbReformaMedium
      }}
    >
      {imagesAndLinks.map((imageAndLink, index) => (
        <ImageAndLink {...imageAndLink} key={index} />
      ))}
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    gap: "1.5rem",
    // backgroundColor: "#f9f9f9",
    width: "80%",
    maxWidth: "76rem",
  },
};

export default Row;
