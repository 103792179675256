import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import colors from "../Assets/Colors/Colors.json";
import fonts from "../Assets/Fonts/Fonts.json";
import Images from "../Data/Images/Images";
import { StyleSheet } from "../Interfaces/IStyleSheet";
import { ChangeTitle, StyleMode } from "../Utils/consts";
import useDeviceMode from "../Utils/useWindowDimensions";
import fontSize from "../Assets/Fonts/FontsSizes.json";
import Breadcrumb from "../Components/UmbracoElements/Breadcrumb";
import { PageState } from "../Redux/Slices/pageSlice";
import { useSelector } from "react-redux";
import { t } from "i18next";

const PaymentStatus: React.FC = () => {
  const [_isLoading, _setIsLoading] = useState(true);
  const [_paymentStatus, _setPaymentStatus] = useState("");
  const [_paymentStatusTitle, _setPaymentStatusTitle] = useState("");
  const [_paymentStatusDescription, _setPaymentStatusDescription] =
    useState("");

  let isCardDisplay = false;
  const location = useLocation();
  const deviceMode = useDeviceMode();
  const navigate = useNavigate();

  const titleRef = useRef<HTMLDivElement>(null);

  const redirectToPage = (page?: string) => {
    page && navigate(`?page=${page}`);
  };

  const currentPage = (
    useSelector((state: any) => state.PageReducer) as PageState
  ).page;

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.tabIndex = 0;
      titleRef.current.focus();
      titleRef.current.style.outline = "none";
      titleRef.current.tabIndex = -1;
    }
  }, []);

  useEffect(() => {
    if (currentPage === "paymentsucceed") {
      _setPaymentStatusTitle(t("PaymentSucceed"));
      _setPaymentStatusDescription(
        `<p>${t("PaymentSucceedDescription")}</p>
       `
      );

      ChangeTitle(t("PaymentSucceed"));
    } else if (currentPage === "paymenterror") {
      _setPaymentStatusTitle(t("PaymentFailed"));
      _setPaymentStatusDescription(
        `<p>${t("PaymentFailedDescription")}</p>
        `
      );
      ChangeTitle(t("PaymentFailed"));
    }
  }, [location]);

  return (
    <div
      style={{
        ...styles.mainContainer,
      }}
    >
      <div
        style={{
          ...styles.cover,
          background: `30% 30% / cover url(${Images.coverCloseImage})`,
          height:
            deviceMode === StyleMode.desktop
              ? isCardDisplay
                ? "40vh"
                : "20.5vh"
              : "",
          minHeight:
            deviceMode === StyleMode.desktop
              ? isCardDisplay
                ? "17.18rem"
                : "14.25rem"
              : "",
        }}
      >
        <div className="cover-color-blur">
          <div
            style={{
              ...styles.coverElements,
              width: deviceMode === StyleMode.desktop ? "80%" : "75%",
            }}
            className="container"
          >
            <div
              style={{
                ...styles.breadcrumb,
                paddingTop: deviceMode === StyleMode.desktop ? "2rem" : "3rem",
              }}
            >
              <Breadcrumb
                items={[{ text: _paymentStatusTitle || "", link: currentPage }]}
              />
            </div>
            <h1
              style={{
                ...styles.title,
                paddingTop: deviceMode === StyleMode.desktop ? "2rem" : "3rem",
              }}
            >
              {_paymentStatusTitle}
            </h1>
          </div>
        </div>
      </div>
      <div
        style={{
          ...styles.body,
          paddingTop: deviceMode !== StyleMode.desktop ? "2rem" : "",
        }}
      >
        <h1
          style={{
            fontFamily: fonts.FbReformaMedium,
            letterSpacing: "0.08375",
            color: currentPage === "paymentsucceed" ? colors.green : colors.red,
            fontSize: fontSize.PageTitle,
            lineHeight: "4rem",
            textAlign: "center",
          }}
        >
          {_paymentStatusTitle}.
        </h1>
        <p
          style={{
            fontFamily: fonts.FbReformaLight,
            letterSpacing: "0.038125rem",
            color: colors.nero,
            fontSize: fontSize.Paragraph,
            paddingBottom: "1rem",
            lineHeight: "2rem",
            textAlign: "center",
          }}
          dangerouslySetInnerHTML={{ __html: _paymentStatusDescription }}
        ></p>
        <div style={styles.buttonsContainer}>
          <button
            onClick={() => redirectToPage("homepage")}
            style={styles.button}
          >
            {t("Homepage")}
          </button>
          <button
            onClick={() => redirectToPage("increasedpayment")}
            style={{
              ...styles.button,
              backgroundColor: colors.koromiko,
              borderColor: colors.koromiko,
              color: colors.black,
            }}
          >
            {t("MoreCharges")}
          </button>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  mainContainer: {
    alignItems: "center",
    flexDirection: "column",
    zIndex: "10",
    minHeight: "100vh",
  },
  cover: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "20.5vh",
    position: "relative",
    minHeight: "14.25rem",
  },
  title: {
    color: colors.white,
    fontSize: fontSize.PageTitle,
    lineHeight: "3rem",
    letterSpacing: "0.05625rem",
    textShadow: `2px 2px ${colors.black}`,
    fontFamily: fonts.FbReformaMedium,
  },
  breadcrumb: {
    display: "flex",
    color: colors.white,
    fontSize: fontSize.Text,
    letterSpacing: "0.05625rem",
    fontFamily: fonts.FbReformaRegular,
    position: "absolute",
  },
  coverElements: {
    gap: "3rem",
    maxWidth: "76rem",
    margin: "auto",
    lineHeight: "2.5rem",
    padding: "1rem",
  },
  body: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "90rem",
  },
  buttonsContainer: {
    display: "flex",
    gap: "1rem",
    flexWrap: "wrap",
  },
  button: {
    backgroundColor: colors.darkBlue,
    color: colors.white,
    fontSize: fontSize.SubTitle,
    borderRadius: "1.5rem",
    minWidth: "12rem",
    padding: ".5rem 1rem",
    boxSizing: "border-box",
    fontFamily: fonts.FbReformaMedium,
    border: `1px solid ${colors.darkBlue}`,
    cursor: "pointer",
  },
};

export default PaymentStatus;
