import React, { useEffect } from "react";
import colors from "../../../Assets/Colors/Colors.json";
import { ICurrentService } from "../../../Interfaces/IForms";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import CustomAttachedFiles from "../CustomFileds/CustomAttachedFiles";
import CustomTextArea from "../CustomFileds/CustomTextArea";

const FormInquiryAboutWebAppScreen: React.FC<ICurrentService> = (props) => {
  const { formik } = props;

  useEffect(() => {
    document
      .getElementById("FormInquiryAboutWebAppScreen_container")
      ?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, []);

  return (
    <div style={styles.container} id="FormInquiryAboutWebAppScreen_container">
      <div style={styles.form}>
        <CustomTextArea
          label="תיאור האירוע"
          name="FormInquiryAboutWebAppScreen.comments"
          type="textarea"
          value={formik.values.FormInquiryAboutWebAppScreen.comments}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          errors={formik.errors.FormInquiryAboutWebAppScreen?.comments}
          touched={formik.touched.FormInquiryAboutWebAppScreen?.comments+''}
          placeholder="הזן תיאור האירוע"
        />
        <CustomAttachedFiles
          label="צירוף מסמכים
          (ניתן להוסיף יותר מקובץ אחד)"
          name="FormInquiryAboutWebAppScreen.attachFiles"
          value={formik.values.FormInquiryAboutWebAppScreen.attachFiles}
          onBlur={formik.handleBlur}
          onChange={(files: File[] | null) => {
            formik.setFieldValue(
              "FormInquiryAboutWebAppScreen.attachFiles",
              files
            );
          }}
          errors={formik.errors.FormInquiryAboutWebAppScreen?.attachFiles}
          touched={formik.touched.FormInquiryAboutWebAppScreen?.attachFiles+''}
          type="file"
          placeholder="צירוף קובץ"
        />
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    margin: "auto",
    paddingBottom: "2rem",
  },

  form: {
    display: "flex",
    flexWrap: "wrap",
    columnGap: "2rem",
    flexDirection: "column",
  },
  button: {
    width: "100%",
    padding: "0.65rem 0.5rem",
    fontSize: "1rem",
    color: colors.white,
    border: "2px solid",
    backgroundColor: colors.blue,
    borderRadius: "10px",
    outline: "none",
    margin: "1rem 0",
    fontWeight: "bold",
    cursor: "pointer",
  },
};

export default FormInquiryAboutWebAppScreen;
