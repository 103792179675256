import axios from "axios";
import Config from "../Configuration/server";
import { ISchedule } from "../Interfaces/ISchedule";
import { IRoutePlanSearchResults } from "../Interfaces/IRoutePlanSearchResults";
import { headers } from "./GlobalService";
import i18n from "./i18n";
import Store from "../Redux/Store/Store";
import { setSomethingWentWrong } from "../Redux/Slices/somethingWentWrongSlice";


export const RJPA = Config.RJPA_URL;

export const SearchTrainLuz = async (
  params: ISchedule,
  searchType:
    | "searchTrainLuzForDateTime"
    | "searchTrainLuzAfterDate"
    | "searchTrainLuzBeforeDate"
): Promise<IRoutePlanSearchResults | undefined> => {
  const { fromStation, toStation, scheduleType, date, hours, minutes } = params;
  try {
    const languages: { [locale: string]: string } = {
      he: "Hebrew",
      en: "English",
      ru: "Russian",
      ar: "Arabic",
    };

    let requestedDay = searchType;

    const language = languages[i18n.language];
    const params_string = `fromStation=${fromStation}&toStation=${toStation}&date=${date}&hour=${hours}:${minutes}&scheduleType=${scheduleType}&systemType=${Config.SYSTEM_TYPE}&languageId=${language}`;
    
    const response = await axios(
      `${Config.BASE_URL}/${RJPA}/timetable/${requestedDay}?${params_string}`,
      { method: "GET", headers }
    );

    // const response = await axios(
    //   `http://localhost:4000/${searchType}?${params_string}`,
    //   { method: "GET", headers }
    // );

    const data = await response.data;
    Store.dispatch(setSomethingWentWrong(false));
    return data.result as IRoutePlanSearchResults;
  } catch (error) {
    Store.dispatch(setSomethingWentWrong(true));
    // console.log(error);
  }
};


