import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { IPopMessageBackend } from "../../Interfaces/IPopMessages";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { removeAllTagHTML } from "../../Utils/Regex";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";

const PopMessageCard: React.FC<IPopMessageBackend> = (props) => {
  const { title, messageBody, supportRichText } = props;
  const deviceMode = useDeviceMode();

  return (
    <div style={styles.container}>
      <h1
        style={{
          ...styles.header,
          textAlign: "center",
        }}
      >
        {title}
      </h1>
      <div
        tabIndex={0}
        style={{
          ...styles.contentMessage,
         padding: "1rem",
          alignItems: deviceMode === StyleMode.desktop ? "center" : "",
        }}
        dangerouslySetInnerHTML={{
          __html: supportRichText
            ? messageBody
            : messageBody.replace(removeAllTagHTML, ""),
        }}
      ></div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  header: {
    flex: 1,
    lineHeight: "2.5rem",
  },
  contentMessage: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    maxHeight: "15rem",
    minHeight: "10rem",
    maxWidth:"25rem",
    boxSizing: "border-box",
    flex: 1,
    fontSize: fontSize.Paragraph,
    lineHeight: "2rem",
  },
};

export default PopMessageCard;
