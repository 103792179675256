import axios from "axios";
import {
  ICalcResult,
  IDestination,
  IFromTOProfile,
  listDis,
  trainPrice,
} from "../Components/PaymentAndDiscounts/FromToStation";
import { IProfileData } from "../Components/PaymentAndDiscounts/Profiles";
import Config from "../Configuration/server";
import { INotePrice } from "../Interfaces/IFaresCalculation";
import { IStation } from "../Interfaces/IStation";
import { IDynamicText, IDynamicTextLink } from "../Interfaces/IUmbraco";
import store from "../Redux/Store/Store";
import { GeoToKm } from "../Services/LocationService";
import { GetSortedStations } from "../Services/SearchStationsAutoCompleteService";
import { globalStorage } from "./utils";

const headers = {
  "Ocp-Apim-Subscription-Key": Config["OCP_APIM_SUBSCRIPTION_KEY"]!,
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
};

export interface IDictStation {
  [stationId: number]: IStation;
}

export interface allPricies {
  allProfileDiscount: IProfileData[];
  allSourceToDestination: IDestination[];
}
export const GetStationsDictionary = (stations: IStation[]) => {
  return stations
    ? stations.reduce((a: IDictStation, station: IStation) => {
        a[station.stationId] = station;
        return a;
      }, {})
    : {};
};

export interface IDictDynamicText {
  [textID: string]: IDynamicText;
}
export const GetDynamicTextsDictionary = (dynamicTexts: IDynamicText[]) => {
  return dynamicTexts
    ? dynamicTexts.reduce((a: IDictDynamicText, dynamicText: IDynamicText) => {
        a[dynamicText.textID] = dynamicText;
        return a;
      }, {})
    : {};
};

export interface IAllStationsLanguages {
  [language: string]: IStation[];
}

export interface IDictDynamicTextLink {
  [linkId: string]: IDynamicTextLink;
}
export const GetDynamicTextsLinksDictionary = (
  dynamicTextsLinks: IDynamicTextLink[]
) => {
  return dynamicTextsLinks
    ? dynamicTextsLinks.reduce(
        (a: IDictDynamicTextLink, dynamicTextLink: IDynamicTextLink) => {
          a[dynamicTextLink.linkId] = dynamicTextLink;
          return a;
        },
        {}
      )
    : {};
};

export const RemoveHtmlTags = (html: string) => {
  return html.replaceAll("<[^>]*>", "");
};

const calPercnt = (num: number, percentage: number) => {
  const result = num * (percentage / 100);
  return parseFloat(result.toFixed(2));
};

const reduceProfiles = (
  data: IProfileData[],
  currentFromTo?: IDestination | any
) => {
  const PRICE_NAME_KEY = ["s_Price", "d_Price", "m_Price"];
  return data.reduce((acc: any, curr: IProfileData) => {
    if (!acc[curr.profile_ID]) {
      acc[curr.profile_ID] = {
        statusFree: curr.discount_Rate === 1,
        profileId: curr.profile_ID,
        trainPricies: [],
      };
    }
    acc[curr.profile_ID].statusFree = curr.discount_Rate === 1;

    acc[curr.profile_ID].trainPricies.push({
      ...curr,
      finalPrice: currentFromTo
        ? roundPrice(
            currentFromTo[PRICE_NAME_KEY[curr.ticketType - 1]],
            curr.discount_Rate,
            curr.ticketType
          )
        : 0,
    });
    return acc;
  }, []);
};

const getPrice = async () => {
  const localData = globalStorage.get("allPrice");

  if (localData) return localData;
  let res = await axios(
    `${Config.BASE_URL}/taarif/api/v1/PriceEng/GetAllPrice?SystemType=Web`,
    {
      method: "GET",
      headers,
    }
  );
  globalStorage.set("allPrice", res.data.result);
  return res.data.result;
};

export const GetNotePrices = async (): Promise<INotePrice[]> => {
  const localData = globalStorage.get("notePrices");
  if (localData) return localData;

  let res = await axios(
    `${Config.BASE_URL}/taarif/api/v1/PriceEng/GetAllPriceWithNotes?SystemType=Web`,
    {
      method: "GET",
      headers,
    }
  );
  let notePrices = res.data.result.priceNotes;
  globalStorage.set("notePrices", notePrices);

  return notePrices;
};
const GetProfiles = async () => {
  const localData = globalStorage.get("listProfiles");
  if (localData) return localData;

  let res = await axios(
    `${Config.BASE_URL}/taarif/api/v1/PriceEng/GetProfiles?SystemType=Web&RequestId=2`,
    {
      method: "GET",
      headers,
    }
  );
  let listProfiles = res.data.result.map((item: any) => {
    return item.profile_Id;
  });
  globalStorage.set("listProfiles", listProfiles);

  return listProfiles;
};

const roundPrice = (price: number, Discount_Rate: number, type: 1 | 2 | 3) => {
  const systemParamsKey = store.getState().HomeReducer.systemParamsKey;
  let finalPrice = price * (1 - Discount_Rate);
  let spaichelRoundList = JSON.parse(
    systemParamsKey.SpaichelRoundProfilesRegexList.value
  );
  let GeneralRegex = systemParamsKey.GeneralRoundRegex.value.split(`/`);

  // //  /.[0-4]/gm

  // // if (type === 3) {
  if (systemParamsKey.IsRoundPricesActive.value === `true`) {
    let RegexRoundTest = new RegExp(`${GeneralRegex[0]}`, `${GeneralRegex[1]}`);
    if (spaichelRoundList[type]) {
      let spaichelRoundListCurrent =
        systemParamsKey[spaichelRoundList[type] + ""].value;
      RegexRoundTest = new RegExp(
        `${spaichelRoundListCurrent.split(`/`)[0]}`,
        `${spaichelRoundListCurrent.split(`/`)[1]}`
      );
    }
    if (finalPrice % 1 > 0 && RegexRoundTest.test(`${finalPrice}`)) {
      return finalPrice - (finalPrice % 1);
    }
  }
  return finalPrice;

  // if (type === 3) {
  //   if (finalPrice % 1 === 0.5) {
  //     return finalPrice;
  //   }
  //   return finalPrice % 1 < 0.5
  //     ? Math.floor(finalPrice)
  //     : Math.ceil(finalPrice);
  // }
  // return finalPrice % 1 < 0.25
  //   ? Math.round(finalPrice / 0.5) * 0.5
  //   : Math.ceil(finalPrice * 2) / 2;
};
export const getTravelPrice = async (item: IFromTOProfile) => {
  let res = GetSortedStations();
  let stationKey = GetStationsDictionary(res);
  let listsOfResult: ICalcResult = {};
  const From = [
    stationKey[item.fromStation].location.latitude,
    stationKey[item.fromStation].location.lontitude,
  ];
  const To = [
    stationKey[item.toStation].location.latitude,
    stationKey[item.toStation].location.lontitude,
  ];

  let allResult: allPricies = await getPrice();
  let profiles = await GetProfiles();
  const currentFromTo = allResult.allSourceToDestination.find(
    (source) =>
      source.from_Station_Code_ISR === item.fromStation &&
      source.to_Station_Code_ISR === item.toStation
  );
  const listProfiles = allResult.allProfileDiscount.filter((item) =>
    profiles.includes(item.profile_ID)
  );

  let distance = GeoToKm(From, To);
  if (currentFromTo) {
    let maxRange = listDis[currentFromTo.distance_Code - 1];
    listsOfResult.search = {
      distance,
      fromStation: stationKey[item.fromStation].stationName,
      toStation: stationKey[item.toStation].stationName,
      fromStationNumber: item.fromStation,
      toStationNumber: item.toStation,
      profile: item.profile,
      maxRange,
    };
  }
  let newRes = reduceProfiles(listProfiles, currentFromTo);

  listsOfResult.data = Object.values(newRes);
  return listsOfResult;
};

export const getTravelPriceLocal = async (item: any) => {
  let res: any = GetSortedStations();
  let stationKey = GetStationsDictionary(res);
  let listsOfResult: ICalcResult = {};
  const From = [
    stationKey[item.fromStation].location.latitude,
    stationKey[item.fromStation].location.lontitude,
  ];
  const To = [
    stationKey[item.toStation].location.latitude,
    stationKey[item.toStation].location.lontitude,
  ];
  let allResult: allPricies = await getPrice();
  const currentFromTo = allResult.allSourceToDestination.find(
    (source) =>
      source.from_Station_Code_ISR === item.fromStation &&
      source.to_Station_Code_ISR === item.toStation
  );
  let distance = GeoToKm(From, To);

  let maxRange: any = getMaxDistance(distance);
  listsOfResult.search = {
    distance,
    fromStation: stationKey[item.fromStation].stationName,
    toStation: stationKey[item.toStation].stationName,
    profile: item.profile,
    maxRange,
  };
  let trainPriceClone = { ...trainPrice[maxRange] };
  if (item.profile) {
    trainPriceClone.singleTrainPrice -= calPercnt(
      item.profile?.discountSingle,
      trainPriceClone.singleTrainPrice
    );
    trainPriceClone.dailyTrainAndBusPrice -= calPercnt(
      item.profile?.discountSingle,
      trainPriceClone.dailyTrainAndBusPrice
    );
    trainPriceClone.monthlyPrice -= calPercnt(
      item.profile?.discountMonthly,
      trainPriceClone.monthlyPrice
    );
    trainPriceClone.monthlyTrainAndBusPrice -= calPercnt(
      item.profile?.discountMonthly,
      trainPriceClone.monthlyTrainAndBusPrice
    );
  }
  // listsOfResult.data = allResult;
  return listsOfResult;
};
export const GetDistanceFromLatLonInKm = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) => {
  const R = 6371; // km

  const dLat = toRad(lat2 - lat1);

  const dLon = toRad(lon2 - lon1);

  const Lat1 = toRad(lat1);

  const Lat2 = toRad(lat2);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(Lat1) * Math.cos(Lat2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const d = R * c;

  return d;
};

function toRad(deg: number) {
  return deg * (Math.PI / 180);
}

export const GetTheRange = (distance: number) => {
  return listDis.find((dist) => {
    let distanceSplit = dist.split("-");
    if (distanceSplit.length > 1) {
      return (
        distance > Number(distanceSplit[0]) &&
        Number(distanceSplit[1]) > distance
      );
    }
    return dist;
  });
};

export const getMaxDistance = (distance: number) => {
  return listDis.find((dist) => {
    let DistSplit = dist.split("-");
    if (DistSplit.length > 1) {
      return distance > Number(DistSplit[0]) && Number(DistSplit[1]) > distance;
    }
    return dist;
  });
};

export const truncateText = (source: string, size: number) => {
  return source.length > size ? source.slice(0, size - 1) + "…" : source;
};
