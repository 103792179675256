import { useState } from "react";
import { ICareers } from "../../../Interfaces/ICareers";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { IsMobile } from "../../../Services/DeviceService";
import CustomModal from "../../CustomModal";
import InfoCareer, { IInfoCareer } from "../../Modal/InfoCareer";
import Career from "./Career";

const Careers: React.FC<ICareers> = (props) => {
  const { title, careers, careersText } = props;
  const [_currentCareerInfo, _setCurrentCareerInfo] = useState<IInfoCareer>();
  const [_isModalOpen, _setIsModalOpen] = useState(false);

  return careers.length > 0 ? (
    <div className="container">
      <h3 className="title" tabIndex={!IsMobile() ? 0 : -1}>
        {title}
      </h3>
      <div style={styles.careersContainer}>
        {careers.map((career: any, index: number) => (
          <Career
            {...career}
            onClick={() => {
              _setCurrentCareerInfo({ ...career, careersText })
              _setIsModalOpen(true)
            }}
            key={index}
          />
        ))}
      </div>
      <CustomModal visible={_isModalOpen}
        onClose={() => _setIsModalOpen(false)}
        showCloseButton="internal" typeStyle={"rectangle"}
        innerStyle={{
          width: "100%",
        }}
        contentStyle={{ padding: 0 }}
      >
        {_currentCareerInfo && (
          <InfoCareer
            {..._currentCareerInfo}

          />
        )}
      </CustomModal>
    </div>
  ) : null;
};

const styles: StyleSheet = {
  careersContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    gap: "4rem",
  },
};

export default Careers;
