import { IImageFile } from "../../Interfaces/IBaseElement";
import { StyleSheet } from "../../Interfaces/IStyleSheet";

const Image: React.FC<IImageFile> = (props) => {
  const { image, position } = props;

  return (
    <div
      style={{
        ...styles.container,
        width: position ? "100%" : "80%",
        justifyContent: position ? position : "start",
      }}
    >
      <img
        src={image._url}
        style={{
          width: "100%",
        }}
        height={`auto`}
        alt={``} 
      />
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    maxWidth: "76rem",
  },
};

export default Image;
