import React, { useEffect, useState } from "react";
import { StyleSheet } from "../../Interfaces/IStyleSheet";

type SortOption = {
  value: "ascending" | "descending" | "noSort";
  label: string;
  id: string;
};

const sortOptions: SortOption[] = [
  { value: "noSort", label: "בחירה", id: "noSort" },
  { value: "ascending", label: "סדר עולה", id: "ascending" },
  { value: "descending", label: "סדר יורד", id: "descending" },
];

type RouteBarSortBarProps = {
  onSortChange: (sortOrder: "ascending" | "descending" | "noSort") => void;
  sortOption: string;
};

const RouteBarSortBar: React.FC<RouteBarSortBarProps> = ({
  onSortChange,
  sortOption,
}) => {
  const [isSelectDisabled, setIsSelectDisabled] = useState(true);

  const [selectedSortOption, setSelectedSortOption] = useState<SortOption>(
    sortOptions[0] // Default to the first option (ascending)
  );

  const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    const selectedOption = sortOptions.find(
      (option) => option.value === selectedValue
    );
    if (selectedOption) {
      setSelectedSortOption(selectedOption);
      onSortChange(selectedOption.value);
    }
  };

  useEffect(() => {
    if (sortOption !== "choise" && sortOption !== "") {
      setIsSelectDisabled(false);
      setSelectedSortOption(sortOptions[0]);

    } else {
      setSelectedSortOption(sortOptions[0]);
      setIsSelectDisabled(true);
    }
  }, [sortOption]);

  //  debugger;
  

  return (
    <div>
      <select
        disabled={isSelectDisabled}
        defaultValue={sortOption}
        style={styles.select}
        id="sort-dropdown"
        value={selectedSortOption.value}
        onChange={handleSortChange}
      >
        {sortOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

const styles: StyleSheet = {
  select: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "18.6rem",
    width: "11.5625rem",
    height: "2.5rem",
    borderRadius: "1.25rem",
    gap: "0.3rem",
    padding: "0px 0.5rem",
    position: "relative",
    zIndex: 30,
    border: "0.0625rem solid rgb(205, 205, 205)",
  },
};

export default RouteBarSortBar;
