import { IQaAndIcon } from "../../Interfaces/IQaAndIcon";
import { replaceRichtextBoldWithFont } from "../../Utils/utils";
import fonts from "../../Assets/Fonts/Fonts.json";
import { RemoveHtmlTags } from "../../Utils/helpers";
import Images from "../../Data/Images/Images";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import colors from "../../Assets/Colors/Colors.json";
import { useState } from "react";

import { BsChevronDown } from "react-icons/bs";
import { BsChevronLeft } from "react-icons/bs";
import { BsChevronRight } from "react-icons/bs";
import i18n from "../../Services/i18n";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../Services/DeviceService";

const QaAndIcon: React.FC<IQaAndIcon> = (props) => {
  const { title, icon, description } = props;
  const [_isAnswerVisible, _setIsAnswerVisible] = useState(false);

  return (
    <>
      <div style={styles.questionAndAnswerWrapper}>
        <span
          onClick={() => {
            if (description.description !== "") {
              _setIsAnswerVisible(!_isAnswerVisible);
            }
          }}
          onKeyPress={() => {
            if (description.description !== "") {
              _setIsAnswerVisible(!_isAnswerVisible);
            }
          }}
        >
          <div style={styles.arrowContainer}>
            {!_isAnswerVisible ? (
              i18n.dir() === "rtl" ? (
                <BsChevronLeft />
              ) : (
                <BsChevronRight />
              )
            ) : (
              <BsChevronDown />
            )}
          </div>
        </span>
        {icon && (
          <div style={styles.iconContainer}>
            <img
            src={icon&&icon._url}
           
            ></img>
          </div>
        )}
        <div
          style={{ ...styles.question, cursor: "pointer" }}
          role={"button"}
          aria-expanded={_isAnswerVisible}
          tabIndex={!IsMobile() ? 0 : -1}
          aria-label={`${title}`}
          onClick={() => {
            if (description.description !== "") {
              _setIsAnswerVisible(!_isAnswerVisible);
            }
          }}
          onKeyPress={() => {
            if (description.description !== "") {
              _setIsAnswerVisible(!_isAnswerVisible);
            }
          }}
        >
          {title}
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ flex: "1.8" }}></div>
        <div style={{ flex: "17" }}>
          {_isAnswerVisible && (
            <div style={{ fontSize: fontSize.Paragraph }}
              dangerouslySetInnerHTML={{
                __html: replaceRichtextBoldWithFont(description.description),
              }}
              aria-label={`${RemoveHtmlTags(description.description)}`}
            ></div>
          )}
        </div>
      </div>
    </>
  );
};

const styles: StyleSheet = {
  questionAndAnswerWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    minHeight: "2.8rem",
  },

  iconDescription: {
    backgroundRepeat: "no-repeat",
    width: "2.2rem",
    height: "2.2rem",
  },
  question: {
    color: colors.nero,
    fontSize: fontSize.Paragraph,
    lineHeight: "1.75rem",
    fontFamily: fonts.FbReformaRegular,
    flex: "14",
  },
  answer: {
    flex: "17",
    fontFamily: fonts.FbReformaLight,
    color: colors.nero,
  },

  iconContainer: {
    display: "flex",
    padding: "0.3rem",

    alignItems: "center",
    justifyContent: "center",
    marginLeft: "1rem",
  },

  arrowContainer: {
    display: "flex",
    fontSize: fontSize.UmbracoElementTitle,
    paddingRight: "0.5rem",
    color: "rgb(0, 69, 138)",
    cursor: "pointer",
  },
};

export default QaAndIcon;
