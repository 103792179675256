import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import store from "store2";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { default as handicapIcon } from "../../Assets/Icons/handicap.svg";
import Config from "../../Configuration/server";
import Images from "../../Data/Images/Images";
import { IRouteItem } from "../../Interfaces/IRouteDetails";
import { IStation } from "../../Interfaces/IStation";
import { ITrainsDotData } from "../../Interfaces/IStationLine";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { AddSubtractTime, GetFormatedTime } from "../../Services/DateService";
import i18n from "../../Services/i18n";
import { StyleMode } from "../../Utils/consts";
import { GetStationsDictionary } from "../../Utils/helpers";
import useDeviceMode from "../../Utils/useWindowDimensions";
import StationLine from "./StationLine";

const RouteItem: React.FC<IRouteItem> = (props) => {
  const {
    travel,
    index,
    isFirstItem,
    isLastItem,
    train,
    onDisplayModal,
    destinationStationId,
  } = props;

  const [_trainsDotData, _setTrainsDotData] = useState<ITrainsDotData>();

  const allStations = store.session.get(
    `${Config.BASE_LOCALSTORAGE_NAME}stations`
  );
  const stations: IStation[] = allStations && allStations[i18n.language];
  const dictStations = stations && GetStationsDictionary(stations);
  const { t } = useTranslation();
  const deviceMode = useDeviceMode();
  const mode = deviceMode === StyleMode.desktop;
  let lateTime = train.trainPosition?.calcDiffMinutes
    ? train.trainPosition?.calcDiffMinutes
    : 0;
  let dateTime = train.arrivalTime || train.departureTime;
  let time = dateTime
    ? AddSubtractTime(new Date(dateTime), lateTime, "m")
    : null;
  const waiting = parseInt(train.waiting as string);

  let lateTimeAsDate = new Date();
  let dateTimeAsDate = new Date();
  if (dateTime) {
    dateTimeAsDate = new Date(dateTime);
  }
  if (time) {
    const hours = time.split(":")[0];
    const minutes = time.split(":")[1];
    lateTimeAsDate.setHours(+hours);
    lateTimeAsDate.setMinutes(+minutes);
  }

  const diff_minutes = (dt2: Date, dt1: Date) => {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  };

  const timeDifferent = train.trainPosition?.calcDiffMinutes; // diff_minutes(dateTimeAsDate, lateTimeAsDate);

  const routeData = travel?.trains[index];
  const currentStationId =
    routeData?.trainPosition?.currentLastStation || false;
  const getTrainsDotData = (): ITrainsDotData => {
    const res: ITrainsDotData = {
      nextStaitionId: {},
      times: [],
    };

    if (routeData) {
      for (let i = 0; routeData.routeStations.length > i; i++) {
        if (routeData.routeStations[i]?.stationId === currentStationId) {
          res.nextStaitionId = routeData.routeStations[i + 1];

          res.times.push(
            moment(routeData.routeStations[i].arrivalTime, "HH:mm").unix()
          );

          res.times.push(
            moment(routeData.routeStations[i + 1].arrivalTime, "HH:mm").unix()
          );
        }
      }
    }

    return res;
  };

  useEffect(() => {
    _setTrainsDotData(getTrainsDotData());
  }, [routeData]);

  let bulletStyle =
    !!train.trainNumber || train.irrelevantOrigin ? (
      // <img
      //   src={Images.train}
      //   className="img-bullet"
      //   style={{
      //     transform: `translateX(${i18n.dir() === "rtl" ? "50%" : "-50%"})`,
      //   }}
      //   width={`auto`}
      //   height={`auto`}
      //   alt={``}
      // />
      <div
        className="circle"
        style={{ background: colors.black, borderColor: colors.black }}
      />
    ) : (
      <div className="circle" />
    );
  bulletStyle = !!train.isDestination ? (
    // <img
    //   src={Images.location}
    //   className="img-bullet"
    //   style={{
    //     transform: `translateX(${i18n.dir() === "rtl" ? "50%" : "-50%"})`,
    //   }}
    //   width={`auto`}
    //   height={`auto`}
    //   alt={``}
    // />
    <div
      className="circle"
      style={{ background: colors.black, borderColor: colors.black }}
    />
  ) : (
    bulletStyle
  );
  bulletStyle = !!train.waiting ? (
    <div
      className="circle"
      style={{ background: colors.black, borderColor: colors.black }}
    />
  ) : (
    bulletStyle
  );

  return (
    <Fragment>
      <div style={styles.box}>
        <div
          style={{
            ...styles.time,
            padding: timeDifferent ? ".8rem 0 0 0" : ".8rem 10px",
          }}
          className="route-time"
        >
          {timeDifferent && mode ? (
            <>
              <div style={train.isStop ? styles.stopTime : styles.lateTime}>
                {!time && dateTime && GetFormatedTime(new Date(dateTime))}
                {time && dateTime && (
                  <div
                    style={{
                      display: "flex",
                      // margin: timeDifferent > 9 ? "0 -2.7rem" : "0 -2.2rem",
                      gap: ".2rem",
                    }}
                  >
                    <span style={{ fontFamily: fonts.FbReformaMedium }}>
                      {GetFormatedTime(new Date(dateTime))}
                    </span>
                  </div>
                )}
              </div>
              <div style={styles.delToolTip} onClick={onDisplayModal}>
                {/* <div title={t("lateToolTip")} style={{ cursor: "pointer" }}>
                  <FaRegQuestionCircle />
                </div> */}
                <span style={styles.del}>
                  {`(${
                    i18n.language === `en` || i18n.language === `ru`
                      ? `${t(`display_minutes`, {
                          minute: `+${timeDifferent}`,
                        })}`
                      : ""
                  }${
                    i18n.language === `en` || i18n.language === `ru`
                      ? ``
                      : `${t(`display_minutes`, {
                          minute: `${timeDifferent}+`,
                        })}`
                  })`}
                  {/* {` (${
                    i18n.language === `en` || i18n.language === `ru`
                      ? `${`+${timeDifferent}`}`
                      : ""
                  }${
                    i18n.language === `en` || i18n.language === `ru`
                      ? ``
                      : `${`${timeDifferent}+`}`
                  })`} */}
                </span>{" "}
              </div>
            </>
          ) : (
            <div style={train.isStop ? styles.stopTime : {}}>{dateTime&&GetFormatedTime(new Date(dateTime))}</div>
          )}
        </div>
        <div
          className="route-station"
          style={{
            ...styles.details,
            width: deviceMode === StyleMode.desktop ? "75%" : "100%",
            marginRight: i18n.dir() === "rtl" ? "-2rem" : "",
            marginLeft: i18n.dir() === "ltr" ? "-2rem" : "",
            borderInlineStart:
              !!waiting || train.isDestination || train.isIrrelevant
                ? "1px dashed #767676"
                : "1px solid #767676",
          }}
        >
          {currentStationId && (
            <StationLine
              type="Departure"
              trainsDotData={_trainsDotData}
              isCurrent={
                currentStationId &&
                _trainsDotData?.nextStaitionId?.stationId === train.stationId
              }
            />
          )}
          <div style={styles.bullet}>{bulletStyle}</div>

          <div
            style={{
              ...styles.station,
              width: "100%",
              paddingLeft: i18n.dir() === "rtl" ? "3rem" : "",
              paddingRight: i18n.dir() === "ltr" ? "3rem" : "",
              transform: train.trainNumber ? "translateY(-1.2rem)" : "",
            }}
          >

            {/* <div
              style={{
                ...styles.stationName,
                display: "flex",
                justifyContent: "space-between",
                width: `${deviceMode === StyleMode.desktop ? "80%" : "100%"}`,
                fontFamily:
                  !!train.trainNumber || !!train.isDestination
                    ? fonts.FbReformaMedium
                    : fonts.FbReformaRegular,
                color:
                  !!train.trainNumber || !!train.isDestination
                    ? colors.nero
                    : "#767676",
              }}
              className="route-station-name"
            >
              <span>{train.stationName}</span>
              <span
                style={{
                  fontFamily: fonts.FbReformaMedium,
                  color: "#767676",
                  whiteSpace: "pre",
                  fontSize: fontSize.timeDifferent,
                }}
              >
                {train.platform != null
                  ? train.platform !== 0
                    ? `${t("Platform")} ${train.platform}`
                    : t("No-platform-set")
                  : ``}
              </span>
            </div> */}
            
            {(!!train.platform || train.platform === 0) && (
              <aside
                className="route-station-name"
                style={{
                  whiteSpace: "pre",
                }}
              >
                {train.trainNumber && (
                  <>
                    <img
                      src={Images.trainInCard}
                      aria-label={`${t("Train-Number")} ,`}
                      style={{
                        filter: "brightness(.2)",
                        // marginInlineEnd: `.3rem`,
                        // marginInlineStart: `.3rem`,
                        transform: `${
                          i18n.dir() === "rtl" ? "scaleX(1)" : "scaleX(-1)"
                        }`,
                      }}
                    />
                    {`  ${train.trainNumber}`}
                    {` ${
                      i18n.language === "he"
                        ? t(`ToEndStation`).trimEnd()
                        : t(`ToEndStation`)
                    }${
                      destinationStationId &&
                      dictStations[destinationStationId].stationName
                    }`}
                  </>
                )}
                {/* {train.services?.handicap && (
                  <>
                    {" "}
                    |{" "}
                    <img
                      src={handicapIcon}
                      width={`auto`}
                      height={`auto`}
                      alt={``}
                      style={{
                        filter: "brightness(.2)",
                      }}
                    />
                  </>
                )} */}
                {train.services?.reservedSeats && (
                  <>
                    {" "}
                    |{" "}
                    <img
                      src={handicapIcon}
                      width={`auto`}
                      height={`auto`}
                      alt={``}
                      style={{
                        filter: "brightness(.2)",
                      }}
                    />
                  </>
                )}
              </aside>
            )}
            <div
              style={{
                ...styles.stationName,
                display: "flex",
                justifyContent: "space-between",
                width: `${deviceMode === StyleMode.desktop ? "80%" : "100%"}`,
                fontFamily:
                  !!train.trainNumber || !!train.isDestination
                    ? fonts.FbReformaMedium
                    : fonts.FbReformaRegular,
                color:
                  !!train.trainNumber || !!train.isDestination
                    ? colors.nero
                    : "#767676",
              }}
              className="route-station-name"
            >
              <span>{train.stationName}</span>
              <span
                style={{
                  fontFamily: fonts.FbReformaMedium,
                  color: "#767676",
                  whiteSpace: "pre",
                  fontSize: fontSize.timeDifferent,
                }}
              >
                {train.platform != null
                  ? train.platform !== 0
                    ? `${t("Platform")} ${train.platform}`
                    : t("No-platform-set")
                  : ``}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const styles: StyleSheet = {
  box: {
    display: "flex",
    boxSizing: "border-box",
    //border: "1px solid ",
  },
  circle: {
    height: "10px",
    width: "10px",
    border: "1px solid rgba(134, 133, 133, 1)",
    borderRadius: "5px",
    margin: "0px -5px",
    backgroundColor: colors.white,
    boxSizing: "border-box",
  },

  destination: {
    width: "1rem",
    height: "1rem",
    margin: "0px -10px",
    background: `url(${Images.location})`,
    backgroundRepeat: "no-repeat",
    transform: "translateY(3px)",
  },
  bullet: {
    width: "20px",
    height: "20px",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
  },
  time: {
    height: "20px",
    boxSizing: "border-box",
    minWidth: "100px",
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Text,
  },
  del: {
    color: colors.bittersweet,
    fontSize: fontSize.timeDifferent,
  },
  lateTime: {
    color: colors.black,
    fontSize: fonts.FbReformaMedium,
  },
  stopTime: {
    color: colors.gray,
    fontSize: fonts.FbReformaMedium,
  },
  details: {
    display: "flex",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  aside: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "pre",
    fontSize: fontSize.Text,
    fontFamily: fonts.FbReformaRegular,
  },
  change: {
    paddingBottom: "10px",
    color: colors.darkBlue,
    display: "flex",
    alignItems: "center",
  },
  station: {
    color: colors.gray,
    fontSize: fontSize.Text,
  },
  stationName: {
    paddingBottom: ".5rem",
    lineHeight: "calc(1rem + 2px)",
  },

  delToolTip: { display: "flex", alignItems: "baseline", gap: ".4rem" },
};

export default RouteItem;
