import { IPodcasts } from "../../../Interfaces/IUmbraco";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import { StyleMode } from "../../../Utils/consts";
import Podcasts from "./Podcasts";
import PodcastsMobile from "./Mobile/PodcastsMobile";

const PodcastsMain: React.FC<IPodcasts> = (props) => {
  const deviceMode = useDeviceMode();
  return deviceMode === StyleMode.desktop ? (
    <Podcasts {...props} />
  ) : (
    <PodcastsMobile {...props} />
  );
};

export default PodcastsMain;
