import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import store from "store2";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import Config from "../../Configuration/server";
import Images from "../../Data/Images/Images";
import { INoTrains } from "../../Interfaces/INoTrains";
import { IStation } from "../../Interfaces/IStation";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { ScheduleState } from "../../Redux/Slices/scheduleSlice";
import {
  GetDateAsTextFormat,
  GetFormatedTime,
  IsToday,
} from "../../Services/DateService";
import i18n from "../../Services/i18n";
import { StyleMode } from "../../Utils/consts";
import { GetStationsDictionary } from "../../Utils/helpers";
import useDeviceMode from "../../Utils/useWindowDimensions";
import { removeSpecialCharacters } from "../../Utils/utils";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../Services/DeviceService";
import { useNavigate } from "react-router";
import { UrlTranslationsState } from "../../Redux/Slices/urlTranslationsSlice";

const NoTrains: React.FC<INoTrains> = (props) => {
  const {
    clientMessageId,
    lastTrainDate,
    nextTrainDate,
    onClick,
    shareStatus,
    messageTitle,
  } = props;

  const noTrainsTitle = t(messageTitle ? messageTitle : "No-trains-message");
  const noTrainsTitleRef = useRef<HTMLHeadingElement>(null);

  const deviceMode = useDeviceMode();
  const navigate = useNavigate();

  const schedule = useSelector(
    (state: any) => state.ScheduleReducer
  ) as ScheduleState;

  const allStations = store.session.get(
    `${Config.BASE_LOCALSTORAGE_NAME}stations`
  );

  const urlTranslations = (
    useSelector(
      (state: any) => state.UrlTranslationsReducer
    ) as UrlTranslationsState
  ).urlTranslations;

  const [_nextTrainsVisible, _setNextTrainsVisible] = useState(false);
  const [_lastTrainVisible, _setLastTrainVisible] = useState(false);
  const [_lastTrainMessage, _setLastTrainMessage] = useState("");
  const [_nextTrainMessage, _setNextTrainMessage] = useState("");

  const stations: IStation[] = store.session.get(
    `${Config.BASE_LOCALSTORAGE_NAME}stations`
  )[i18n.language];
  const dictStations = GetStationsDictionary(stations);

  const originStationAria =
    schedule.fromStation &&
    removeSpecialCharacters(
      GetStationsDictionary(allStations[i18n.language])[schedule.fromStation]
        .stationName
    );

  const destinationStationAria =
    schedule.toStation &&
    removeSpecialCharacters(
      GetStationsDictionary(allStations[i18n.language])[schedule.toStation]
        .stationName
    );

  useEffect(() => {
    if (clientMessageId === 4) {
      _setNextTrainsVisible(true);
      _setLastTrainVisible(true);
    }
    if (clientMessageId === 2) {
      _setNextTrainsVisible(true);
    }

    if (lastTrainDate) {
      const lastTrainMessageDate = IsToday(lastTrainDate)
        ? GetDateAsTextFormat(lastTrainDate)
        : t("On-date", {
            formatedDate: GetDateAsTextFormat(lastTrainDate),
            interpolation: { escapeValue: false },
          });

      const lastTrainMessageTime = t("At-hour", {
        formatedTime: GetFormatedTime(lastTrainDate),
      });

      _setLastTrainMessage(
        `${t(
          "Last-train-depart"
        )} ${lastTrainMessageDate} ${lastTrainMessageTime}`
      );
    }
    if (nextTrainDate) {
      const nextTrainMessageDate = IsToday(nextTrainDate)
        ? GetDateAsTextFormat(nextTrainDate)
        : t("On-date", {
            formatedDate: GetDateAsTextFormat(nextTrainDate),
            interpolation: { escapeValue: false },
          });

      const nextTrainMessageTime = t("At-hour", {
        formatedTime: GetFormatedTime(nextTrainDate),
      });

      _setNextTrainMessage(
        `${t(
          "Next-train-depart"
        )} ${nextTrainMessageDate} ${nextTrainMessageTime}`
      );
    }

    if (noTrainsTitleRef.current) {
      noTrainsTitleRef.current.focus();
    }
  }, [clientMessageId, lastTrainDate, nextTrainDate]);

  const isSafariOs = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const noTrainsContainerMobile =
    deviceMode !== StyleMode.desktop
      ? isSafariOs
        ? { height: "", paddingTop: "4rem" }
        : {}
      : {};
  return (
    <div
      style={{
        ...styles.noTrainsContainer,
        minHeight: deviceMode === StyleMode.desktop ? "100vh" : "",
      }}
    >
      <img src={Images.noTrains} />
      <h1
        ref={noTrainsTitleRef}
        style={{
          ...styles.noTrainsTitle,
          maxWidth: deviceMode === StyleMode.desktop ? "30rem" : "20rem",
        }}
        tabIndex={!IsMobile() ? 0 : -1}
        aria-label={`${noTrainsTitle} ${t("From-station-to", {
          origin: schedule.fromStation && originStationAria,
          destination: destinationStationAria,
        })}`}
      >
        {noTrainsTitle}
      </h1>
      {_nextTrainsVisible && (
        <div style={styles.nextLastTrain}>
          <span
            style={styles.message}
            tabIndex={!IsMobile() ? 0 : -1}
            aria-label={`${_nextTrainMessage} ${t("From-station-to", {
              origin: schedule.fromStation && originStationAria,
              destination: schedule.toStation && destinationStationAria,
            })}`}
          >
            {_nextTrainMessage}
          </span>
          <button
            style={styles.nextTrainButton}
            onClick={() => {
              onClick("nextTrain");
            }}
            aria-label={`${t("View-next-train")} ${t("From-station-to", {
              origin: schedule.fromStation && originStationAria,
              destination: schedule.toStation && destinationStationAria,
            })} `}
          >
            {t("View-next-train")}
          </button>
        </div>
      )}
      {_lastTrainVisible && (
        <div style={styles.nextLastTrain}>
          <span
            style={styles.message}
            tabIndex={!IsMobile() ? 0 : -1}
            aria-label={` ${_lastTrainMessage} ${t("From-station-to", {
              origin: schedule.fromStation && originStationAria,
              destination: schedule.toStation && destinationStationAria,
            })}`}
          >
            {_lastTrainMessage}
          </span>
          <button
            style={styles.lastTrainButton}
            onClick={() => onClick("lastTrain")}
            aria-label={`${t("View-last-train")} ${t("From-station-to", {
              origin: schedule.fromStation && originStationAria,
              destination: schedule.toStation && destinationStationAria,
            })} `}
          >
            {t("View-last-train")}
          </button>
        </div>
      )}
      {shareStatus === "TimePast" && deviceMode !== StyleMode.desktop && (
        <button
          style={styles.nextTrainButton}
          onClick={() => {
            navigate(
              `?page=routePlan&step=when&fromStation=${schedule.toStation}&toStation=${schedule.fromStation}&date=${schedule.date}&hours=${schedule.hours}&minutes=${schedule.minutes}&scheduleType=${schedule.scheduleType}`
            );
          }}
          aria-label={`${t("NewSearch")}`}
        >
          {t("NewSearch")}
        </button>
      )}
      <a
        style={styles.alternativePublicTransport}
        href={urlTranslations[i18n.language]["alternativeTransportation"]}
        // target={"_blank"}
        rel={"noreferrer"}
      >
        {t("Alternative-public-transport")} {">>"}
      </a>
    </div>
  );
};

const styles: StyleSheet = {
  noTrainsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "1.5rem",
  },
  noTrainsTitle: {
    color: colors.darkBlue,
    fontSize: fontSize.Paragraph,
    fontFamily: fonts.FbReformaMedium,
    lineHeight: "1.5rem",
    textAlign: "center",
    outline: "none",
  },
  nextTrainButton: {
    backgroundColor: colors.darkBlue,
    color: colors.white,
    fontSize: fontSize.SubTitle,
    borderRadius: "1.5rem",
    minWidth: "12rem",
    padding: ".5rem 1rem",
    boxSizing: "border-box",
    fontFamily: fonts.FbReformaMedium,
    border: `1px solid ${colors.darkBlue}`,
    cursor: "pointer",
  },
  lastTrainButton: {
    backgroundColor: colors.white,
    color: colors.darkBlue,
    fontSize: fontSize.SubTitle,
    borderRadius: "1.5rem",
    minWidth: "12rem",
    padding: ".5rem 1rem",
    boxSizing: "border-box",
    fontFamily: fonts.FbReformaMedium,
    border: `1px solid ${colors.darkBlue}`,
    cursor: "pointer",
  },
  nextLastTrain: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: colors.darkBlue,
    gap: "1rem",
  },
  message: {
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Paragraph,
    lineHeight: "1.5rem",
    maxWidth: "20rem",
    textAlign: "center",
  },
  alternativePublicTransport: {
    color: colors.oldLavender,
    fontFamily: fonts.FbReformaMedium,
    textDecoration: "underline",
    fontSize: fontSize.SubTitle,
    lineHeight: "1.25rem",
  },
};

export default NoTrains;
