import colors from "../../../Assets/Colors/Colors.json";
import { Fragment } from "react";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import { StyleMode } from "../../../Utils/consts";
import SearchResultsExemptionNoticesAuctionItem from "./Auctions/SearchResultsExemptionNoticesAuctionItem";
import {
  IExemptionNoticesSearchResults,
  IGuidelinesAndRatesSearchResults,
  ISingleSupplierSearchResults,
  ITendersSearchResults,
  IUmbracoSearchResults,
} from "../../../Interfaces/IUmbraco";
import SearchResultsSingleSupplierAuctionItem from "./Auctions/SearchResultsSingleSupplierAuctionItem";
import SearchResultsGuidelinesAndRatesItem from "./GuidelinesAndRates/SearchResultsGuidelinesAndRatesItem";
import SearchResultsCategoryItem from "./Categories/SearchResultsCategoryItem";
import {
  ISearchResultsCategory,
  ISearchResultsPage,
} from "../../../Interfaces/ISearchResultsItems";
import { useState, useEffect } from "react";
import PaginationDots from "../../PaginationDots/PaginationDots";
import SearchResultsTenderAuctionItem from "./Auctions/SearchResultsTenderAuctionItem";
import FilterItem from "../FilterItem";
import fonts from "../../../Assets/Fonts/Fonts.json";
import SearchResultsPageItem from "./Categories/SearchResultsPageItem";
import { IFilterItem } from "../../../Interfaces/IFilterItem";
import { t } from "i18next";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";

const SearchResultsMerged: React.FC<IUmbracoSearchResults> = (props) => {
  const {
    categoriesAndPages,
    exemptionNoticesDto,
    singleSupplierDto,
    planningAndRatesDto,
    tendersDto,
    term = "",
    passCount,
  } = props;
  const deviceMode = useDeviceMode();
  const [_filters, _setFilters] = useState<IFilterItem[]>([]);
  const [_selectedFilter, _setSelectedFilter] = useState("All");
  const [_searchResultsMerged, _setSearchResultsMerged] = useState<
    (IExemptionNoticesSearchResults &
      ISearchResultsCategory &
      ISingleSupplierSearchResults &
      ITendersSearchResults &
      IGuidelinesAndRatesSearchResults)[]
  >([]);

  const [_totalItems, _setTotalItems] = useState(_searchResultsMerged);
  const [_currentPage, _setCurrentPage] = useState(1);
  const [_cardsPerPage, _setCardsPerPage] = useState(12);
  const [_indexOfLastCard, _setIndexOfLastCard] = useState(
    _currentPage * _cardsPerPage
  );
  const [_indexOfFirstCard, _setIndexOfFirstCard] = useState(
    _indexOfLastCard - _cardsPerPage
  );
  const [_currentCardsInPage, _setCurrentCardsInPage] = useState(
    _searchResultsMerged.slice(_indexOfFirstCard, _indexOfLastCard)
  );

  const mainScroll = document.getElementById("mainScroll")!;

  const getTenderType = (tender: ITendersSearchResults) => {
    if (tender.WinningSupplier) {
      return "AuctionsResults";
    }
    if (new Date() > new Date(tender.BiddingDate)) {
      return "ClosedAuctions";
    }

    return "OpenAuctions";
  };

  const handleSlice = (
    items: (IExemptionNoticesSearchResults &
      ISearchResultsCategory &
      ISingleSupplierSearchResults &
      ITendersSearchResults &
      IGuidelinesAndRatesSearchResults)[]
  ) => {
    const currentPage = 1;
    const indexLastCard = currentPage * _cardsPerPage;
    const indexFirstCard = indexLastCard - _cardsPerPage;

    _setCurrentPage(currentPage);
    _setIndexOfLastCard(indexLastCard);
    _setIndexOfFirstCard(indexFirstCard);
    _setSearchResultsMerged(items);
    _setCurrentCardsInPage(items.slice(indexFirstCard, indexLastCard));
  };

  const getDistinctedFilters = (filters: IFilterItem[]) => {
    var flags: any = {};
    const distinctedFilters = filters.filter((filter) => {
      if (flags[filter.id]) {
        return false;
      }
      flags[filter.id] = true;
      return true;
    });
    return distinctedFilters;
  };

  const handleFilterClick = (filterId: string) => {
    _setSelectedFilter(filterId);
    let items: any[] = [];
    for (let i = 0; i < _totalItems.length; i++) {
      if (filterId === "All") {
        items = _totalItems;
        break;
      } else if (
        filterId === "ExemptionNotices" ||
        filterId === "SingleSupplier" ||
        filterId === "ExemptionNotices" ||
        filterId === "OpenAuctions" ||
        filterId === "ClosedAuctions" ||
        filterId === "AuctionsResults" ||
        filterId === "GuidelinesAndRates"
      ) {
        items = _totalItems.filter(
          (item) => item.searchResultType === filterId
        );
        break;
      }
      if (_totalItems[i].searchResultType === "Page") {
        if (
          (_totalItems[i] as unknown as ISearchResultsPage).categoryId ===
          filterId
        ) {
          items.push(_totalItems[i]);
        }
      } else if (_totalItems[i].searchResultType === "Category") {
        if (
          (_totalItems[i] as unknown as ISearchResultsCategory).id === filterId
        ) {
          items.push(_totalItems[i]);
        }
      }
    }
    handleSlice(items);
  };

  const handlePagination = (selectedPage: number) => {
    const indexLastCard = selectedPage * _cardsPerPage;
    const indexFirstCard = indexLastCard - _cardsPerPage;

    _setIndexOfLastCard(indexLastCard);
    _setIndexOfFirstCard(indexFirstCard);
    _setCurrentCardsInPage(
      _searchResultsMerged.slice(indexFirstCard, indexLastCard)
    );
    _setCurrentPage(selectedPage);
    mainScroll.scrollTo({ top: 0, behavior: "smooth" });
  };

  const createFilters = () => {
    const categoriesCounts: { [key: string]: IFilterItem } = {};
    const pages = categoriesAndPages.filter(
      (item) => item.searchResultType === "Page"
    ) as ISearchResultsPage[];

    for (let i = 0; i < pages.length; i++) {
      if (!(pages[i].categoryName in categoriesCounts)) {
        categoriesCounts[pages[i].categoryName] = {
          id: pages[i].categoryId,
          value: pages[i].categoryName,
          count: 1,
        };
      } else {
        categoriesCounts[pages[i].categoryName].count++;
      }
    }

    const categoriesFilters = Object.values(categoriesCounts);

    const tendersFilters = [
      {
        id: "ExemptionNotices",
        value: t("ExemptionNotices"),
        count: exemptionNoticesDto.length,
      },
      {
        id: "SingleSupplier",
        value: t("SingleSupplier"),
        count: singleSupplierDto.length,
      },
      {
        id: "OpenAuctions",
        value: t("OpenAuctions"),
        count: tendersDto.filter(
          (tender) => new Date() < new Date(tender.BiddingDate)
        ).length,
      },
      {
        id: "ClosedAuctions",
        value: t("ClosedAuctions"),
        count: tendersDto.filter(
          (tender) => new Date() > new Date(tender.BiddingDate)
        ).length,
      },
      {
        id: "AuctionsResults",
        value: t("AuctionsResults"),
        count: tendersDto.filter((tender) => tender.WinningSupplier).length,
      },
    ];

    const guidelinesAndRatesFilters = [
      {
        id: "GuidelinesAndRates",
        value: t("GuidelinesAndRates"),
        count: planningAndRatesDto.length,
      },
    ];

    const filters = getDistinctedFilters([
      ...categoriesFilters,
      ...tendersFilters,
      ...guidelinesAndRatesFilters,
    ]).filter((filter) => filter.count > 0);
    const totalFilters = filters.reduce((pre, acc) => pre + acc.count, 0);

    const allFiter = [
      {
        id: "All",
        value: t("All"),
        count: totalFilters,
      },
    ];
    passCount && passCount(totalFilters);
    _setFilters([...allFiter, ...filters]);
  };

  useEffect(() => {
    const merged = [
      ...categoriesAndPages.map((categoriesAndPages) => {
        return { ...categoriesAndPages };
      }),
      ,
      ...exemptionNoticesDto.map((exemptionNotices) => {
        return { ...exemptionNotices, searchResultType: "ExemptionNotices" };
      }),
      ...singleSupplierDto.map((singleSupplier) => {
        return { ...singleSupplier, searchResultType: "SingleSupplier" };
      }),
      ...planningAndRatesDto.map((guidelinesAndRate) => {
        return { ...guidelinesAndRate, searchResultType: "GuidelinesAndRates" };
      }),
      ...tendersDto.map((tender) => {
        return { ...tender, searchResultType: getTenderType(tender) };
      }),
      ,
    ].filter((item) => item !== undefined) as (IExemptionNoticesSearchResults &
      ISearchResultsCategory &
      ISingleSupplierSearchResults &
      ITendersSearchResults &
      IGuidelinesAndRatesSearchResults)[];

    _setTotalItems(merged);
    handleSlice(merged);
    createFilters();
  }, [props]);

  return (
    <div style={styles.mainContainer}>
      <div
        id={"filters"}
        style={{
          ...styles.filters,
          flexWrap: deviceMode === StyleMode.desktop ? "wrap" : "unset",
          maxWidth: deviceMode !== StyleMode.desktop ? "100%" : "",
          overflow: deviceMode !== StyleMode.desktop ? "auto" : "unset",
          gap: deviceMode !== StyleMode.desktop ? "1.5rem" : "2rem",
        }}
      >
        {_filters.map((filter, index) => (
          <FilterItem
            {...filter}
            isSelected={filter.id === _selectedFilter}
            key={index}
            onClick={handleFilterClick}
          />
        ))}
      </div>
      <div>
        {_currentCardsInPage.map((item, index) => {
          return item?.searchResultType === "Category" ? (
            <Fragment key={index}>
              <SearchResultsCategoryItem
                {...(item as ISearchResultsCategory)}
                key={index}
              />
              {index < _searchResultsMerged.length - 1 &&
                deviceMode === StyleMode.desktop && (
                  <div style={styles.line}></div>
                )}
            </Fragment>
          ) : item?.searchResultType === "Page" ? (
            <Fragment key={index}>
              <SearchResultsPageItem
                {...(item as unknown as ISearchResultsPage)}
                term={term}
                key={index}
              />
              {index < _searchResultsMerged.length - 1 &&
                deviceMode === StyleMode.desktop && (
                  <div style={styles.line}></div>
                )}
            </Fragment>
          ) : item?.searchResultType === "OpenAuctions" ||
            item?.searchResultType === "ClosedAuctions" ||
            item?.searchResultType === "AuctionsResults" ? (
            <Fragment key={index}>
              <SearchResultsTenderAuctionItem
                {...(item as ITendersSearchResults)}
                term={term}
                key={index}
              />
              {index < _searchResultsMerged.length - 1 &&
                deviceMode === StyleMode.desktop && (
                  <div style={styles.line}></div>
                )}
            </Fragment>
          ) : item?.searchResultType === "ExemptionNotices" ? (
            <Fragment key={index}>
              <SearchResultsExemptionNoticesAuctionItem
                {...(item as IExemptionNoticesSearchResults)}
                term={term}
                key={index}
              />
              {index < _searchResultsMerged.length - 1 &&
                deviceMode === StyleMode.desktop && (
                  <div style={styles.line}></div>
                )}
            </Fragment>
          ) : item?.searchResultType === "SingleSupplier" ? (
            <Fragment key={index}>
              <SearchResultsSingleSupplierAuctionItem
                {...(item as ISingleSupplierSearchResults)}
                term={term}
                key={index}
              />
              {index < _searchResultsMerged.length - 1 &&
                deviceMode === StyleMode.desktop && (
                  <div style={styles.line}></div>
                )}
            </Fragment>
          ) : item?.searchResultType === "GuidelinesAndRates" ? (
            <Fragment key={index}>
              <SearchResultsGuidelinesAndRatesItem
                {...(item as IGuidelinesAndRatesSearchResults)}
                term={term}
                key={index}
              />
              {index < _searchResultsMerged.length - 1 &&
                deviceMode === StyleMode.desktop && (
                  <div style={styles.line}></div>
                )}
            </Fragment>
          ) : null;
        })}
        <div style={{ paddingBottom: "2rem" }}>
          {_currentCardsInPage.length > 0 && (
            <PaginationDots
              active={parseInt(_currentPage.toString())}
              itemsPerPage={_cardsPerPage}
              totalItems={_searchResultsMerged.length}
              step={2}
              onClickHandler={handlePagination}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
  },
  line: {
    height: "0.07rem",
    backgroundColor: colors.pastelGray,
  },
  filters: {
    display: "flex",
    // gap: "2rem",
    paddingBottom: "4rem",
  },
  filtersTitle: {
    fontFamily: fonts.FbReformaLight,
    letterSpacing: "0.024375rem",
    color: colors.nero,
    fontSize: fontSize.Text,
  },
};

export default SearchResultsMerged;
