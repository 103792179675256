import { t } from "i18next";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import Images from "../../Data/Images/Images";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { IsMobile } from "../../Services/DeviceService";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";
import CustomModal from "../CustomModal";
import InfoCareer, { IInfoCareer } from "../Modal/InfoCareer";
export interface IJob {
  contentTypeAlias: string;
  title: string;
  jobNumber: number;
  publishDate: Date;
  endPublishDate: Date;
  location: string | any;
  email: string;
  jobComments: string;
  jobDescription: string;
  jobRequirements: string;
  description: string;
  image: any;
  categories: string[];
  typeJob: string;
}
export interface ICareersText {
  contentTypeAlias: string;
  textID: string;
  text: string;
  anchor: string;
}
export interface IHotJobs {
  headTitle: string;
  headTitleIn: string;
  title: string;
  inTitle: string;
  elementName: string;
  more: string;
  anchor: string;
  careersText: ICareersText[];
  jobs: IJob[];
}
export interface ISearchJob {
  type: string | null;
  location: string | null;
  status: boolean;
}
const CITY: string[] = ["North", "South", "Coordinator"];
const HotJobs: React.FC<IHotJobs> = (props) => {
  const [_isMoreJobsLinkHover, _setIsMoreJobsLinkHober] = useState(false);
  const [_showAll, _setShowAll] = useState(false);
  const [_isModalOpen, _setIsModalOpen] = useState(false);
  const [_currentCareerInfo, _setCurrentCareerInfo] = useState<IInfoCareer>();

  const [_jobs, _setJobs] = useState<{ [key: string]: IJob[] }>({});
  const [_someOfJobs, _setSomeOfJobs] = useState<number>(0);
  const [_cities, _setCities] = useState<string[]>([]);
  const [_types, _setType] = useState<string[]>([]);
  let [_search, _setSearch] = useState<ISearchJob>({
    type: null,
    location: null,
    status: false,
  });
  const dispatch = useDispatch();
  const deviceMode = useDeviceMode();
  const navigate = useNavigate();

  const onOriginatioData = (jobs: IJob[]) => {
    let objKey: { [key: string]: IJob[] } = {};
    let objKeyCities: { [key: string]: string } = {};
    let totalOfJobs = 0;
    jobs
      .filter(
        (f: IJob) =>
          new Date() >= new Date(f.publishDate) &&
          new Date() <= new Date(f.endPublishDate)
      )
      .map((item: IJob) => {
        totalOfJobs++;
        item.categories.map((category: string) => {
          if (objKey[category]) {
            objKey[category] = [...objKey[category], item];
          } else {
            objKey[category] = [item];
          }
        });
        objKeyCities = { [item.location]: item.location };
      });
    _setSomeOfJobs(totalOfJobs);
    return { objKey, objKeyCities, totalOfJobs };
  };
  const init = () => {
    let { objKey, objKeyCities, totalOfJobs } = onOriginatioData(props.jobs);
    _setJobs(objKey);
    _setType(Object.keys(objKey));
    _setCities(Object.keys(objKeyCities));
  };

  const CATEGORY: string[] = t("CATEGORY_CAREER").split(",");
  useEffect(() => {
    init();
  }, []);
  const onSelect = (type: string, value: string) => {
    let _value = value === "*" ? null : value;
    _setSearch({ ..._search, [type]: _value });
  };

  const textLongThreeDot = (text: string, number: number) => {
    return `${text.slice(0, number)}${text.length > number ? "..." : ""}`;
  };
  return (
    <Fragment>
      <CustomModal visible={_isModalOpen}
        onClose={() => _setIsModalOpen(false)}
        showCloseButton="internal" typeStyle={"rectangle"}
        position={deviceMode === StyleMode.mobile ? "bottom" : undefined}
        innerStyle={{
          width: "100%", maxHeight: "90%",maxWidth: "unset",

          borderRadius: "0.8rem",
          overflow: "hidden"
        }}
        contentStyle={{ padding: 0 }}
      >
        {_currentCareerInfo && (
          <InfoCareer
            {..._currentCareerInfo}
         
          />
        )}
      </CustomModal>
      <div style={styles.bgGray}>
        <div
          style={{
            ...styles.column,
            backgroundColor: colors.darkBlue,
            color: "white",
            gap: "2rem",
            width: "100%",
          }}
        >
          <div
            style={{
              ...styles.maxWidth,
              ...styles.column,
              gap: "1.5rem",
              width: "100%",
            }}
            className="banner-search-job"
          >
            <div
              style={{
                fontSize: fontSize.UmbracoElementTitle,
                fontFamily: fonts.FbReformaLight,
              }}
            ></div>
            <h2 style={styles.title}>
              {props.headTitle}
              <br />
              <span style={styles.inTitle}>{props.headTitleIn}</span>
            </h2>
            <div
              style={{
                ...styles.row,
                gap: "2rem",
                paddingBottom: "2rem",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <select
                style={styles.selectBorder}
                className="select-border"
                aria-label={t("ChooseJobField")}
                disabled={
                  _cities.length === 0 || Object.keys(_jobs).length === 0
                }
                onChange={(e) => onSelect("type", e.target.value)}
              >
                <option hidden>{t("theFieldOfTheJob")}</option>
                <option value={"*"} style={{ color: colors.darkBlue }}>
                  {t(`AllOption`)}
                </option>

                {_types.map((item: string, index) => (
                  <option
                    key={index}
                    value={item}
                    style={{ color: colors.darkBlue }}
                  >
                    {t(CATEGORY[Number(item.split("^")[0])])}
                  </option>
                ))}
              </select>
              <select
                style={styles.selectBorder}
                disabled={
                  _cities.length === 0 || Object.keys(_jobs).length === 0
                }
                className="select-border"
                aria-label={t("ChooseJobLocation")}
                onChange={(e) => onSelect("location", e.target.value)}
              >
                <option hidden>{t("Location")}</option>
                <option value={"*"} style={{ color: colors.darkBlue }}>
                  {t("AllTheCountry")}
                </option>
                {_cities.map((item: any, index) => (
                  <option
                    value={item}
                    key={index}
                    style={{ color: colors.darkBlue }}
                  >
                    {t(CITY[item.split("^")[0]])}
                  </option>
                ))}
              </select>
              <button
                disabled={
                  _cities.length === 0 || Object.keys(_jobs).length === 0
                }
                style={styles.btn}
                role={"button"}
                aria-label={`${t("jobs")} ${t("Search")}`}
                onClick={async () => {
                  let data = props.jobs.filter(
                    (item: IJob) =>
                      !_search.type || item.categories.includes(_search.type)
                  );
                  data = data.filter(
                    (item: IJob) =>
                      !_search.location ||
                      item.location.includes(_search.location)
                  );
                  let { objKey, objKeyCities, totalOfJobs } =
                    onOriginatioData(data);
                  objKey = _search.type
                    ? { [_search.type]: objKey[_search.type] }
                    : objKey;
                  _setJobs({ ...objKey });
                }}
              >
                {t("Search")}
              </button>
            </div>
          </div>
        </div>
        <div style={styles.maxWidth}>
          <div
            style={{
              ...styles.row,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {Object.keys(_jobs).length > 0 ? (
              <div style={{ flex: 1, ...styles.column, gap: "1rem" }}>
                <div
                  style={{
                    paddingBottom: "2rem",
                    flex: 1,
                    ...styles.column,
                    gap: "1rem",
                  }}
                >
                  <h3
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      lineHeight: "3rem",
                    }}
                  >
                    <span
                      style={{
                        ...styles.subtitle,
                        fontSize: fontSize.H1,
                        lineHeight: 1,
                        fontFamily: fonts.FbReformaRegular,
                      }}
                    >
                      {props.title}
                    </span>
                    <span
                      style={{
                        ...styles.subtitle,
                        fontFamily: fonts.FbReformaMedium,
                      }}
                    >
                      {props.inTitle}
                    </span>
                  </h3>
                  {/* <p
                style={styles.subtitle}
              >{`${props.jobs.length} ${props.numberJobs} | ${props.location}`}</p> */}
                </div>
                {Object.keys(_jobs).length > 0 ? (
                  Object.keys(_jobs)
                    .slice(0, _showAll ? props.jobs.length : 3)
                    .map((item: any, index) => (
                      <div key={index}>
                        <div
                          style={{
                            borderBottom: "1px solid #00458A",
                            paddingBottom: "2rem",
                            flex: 1,
                            ...styles.column,
                            gap: "0.5rem",
                          }}
                        >
                          <h4
                            style={{
                              ...styles.subtitle,
                              fontSize: fontSize.H1,
                              fontFamily: fonts.FbReformaRegular,
                            }}
                          >
                            {t(CATEGORY[item.split("^")[0]])}
                          </h4>
                          {_jobs[item] && (
                            <span
                              style={{
                                ...styles.subtitle,
                                fontSize: fontSize.UmbracoElementTitle,
                              }}
                            >
                              {_jobs[item].length} {"משרות פתוחות"}
                            </span>
                          )}
                        </div>
                        <div
                          style={{
                            ...styles.wrap,
                            flex: 1.5,
                            gap: "2rem",
                            paddingBlock: "2rem",
                            // justifyContent: "space-evenly",
                          }}
                        >
                          {_jobs[item] &&
                            _jobs[item].map((item: IJob, index: number) => (
                              <div
                                style={styles.cardJob}
                                key={index}
                                tabIndex={!IsMobile() ? 0 : -1}
                                role={"link"}
                                aria-label={`${t("Location")} ${t(
                                  CITY[item.location.split("^")[0]]
                                )} ${item.title}  ${item.typeJob} ${t(
                                  "SubmitUntil"
                                )}
                              ${moment(item.endPublishDate).format(
                                  "DD/MM/yyyy"
                                )} ${t("More-info")}`}
                                onKeyPressCapture={() => {
                                  _setIsModalOpen(true);
                                  _setCurrentCareerInfo({
                                    ...item,
                                    careersText: props.careersText,
                                  });
                                }}
                                onClick={() => {
                                  if (deviceMode === StyleMode.desktop) {
                                    navigate(
                                      `?page=career_${item.jobNumber}`
                                    );
                                  } else {
                                    _setIsModalOpen(true);
                                    _setCurrentCareerInfo({
                                      ...item,
                                      careersText: props.careersText,
                                      customStyles: {
                                        width: "",
                                      },
                                    });
                                  }
                                }}
                              >
                                <div style={styles.tags}>
                                  {/* {item.categories.map(
                            (category: any, index: number) => (
                              <div style={styles.tag} key={index}>
                                {category}
                              </div>
                              // <Tag text={category}/>
                            )
                          )} */}
                                  <div style={styles.tag} key={index}>
                                    {t(CITY[item.location.split("^")[0]])}
                                  </div>
                                </div>
                                <h5
                                  style={{
                                    ...styles.subtitle,
                                    whiteSpace: "nowrap",
                                  }}
                                  title={
                                    item.title.length > 20 ? item.title : ""
                                  }
                                >
                                  {textLongThreeDot(item.title, 20)}
                                </h5>
                                <img
                                  src={
                                    item.image?._url
                                      ? item.image?._url
                                      : Images.logo
                                  }
                                  alt={``}
                                  style={{
                                    width: "100%",
                                    height: "10rem",
                                    objectFit: "cover",
                                  }}
                                />
                                <div style={styles.info}>
                                  <div>{item.typeJob}</div>
                                  {/* <div style={{width:1,backgroundColor:colors.darkBlue,height:"85%"}}/> */}
                                  {/* <div style={{display:"flex",alignItems:'center'}}>
                        <MdLocationOn size={16} />
                        {item.location}
                      </div> */}
                                  <div
                                    style={{
                                      width: 1,
                                      backgroundColor: colors.darkBlue,
                                      height: "85%",
                                    }}
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {t("SubmitUntil")}{" "}
                                    {moment(item.endPublishDate).format(
                                      "D.M.YY"
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    ))
                ) : (
                  <div
                    style={{
                      minHeight: "15rem",
                      display: "flex",
                      opacity: "0.5",
                      justifyContent: "center",
                      textAlign: "center",
                      alignItems: "center",
                    }}
                  >
                    <h2 style={{ ...styles.title, color: colors.darkBlue }}>
                      {t("NotFound")} <br />
                      <span style={styles.inTitle}>{t("jobs")}</span>
                    </h2>
                  </div>
                )}
                <div>
                  {Object.keys(_jobs).length > 3 && (
                    <div
                      onClick={() => _setShowAll(!_showAll)}
                      tabIndex={!IsMobile() ? 0 : -1}
                      onMouseOver={() => {
                        _setIsMoreJobsLinkHober(true);
                      }}
                      onMouseLeave={() => {
                        _setIsMoreJobsLinkHober(false);
                      }}
                      style={{
                        ...styles.linkMoreInfo,
                        textDecoration: _isMoreJobsLinkHover
                          ? "underline"
                          : "none",
                      }}
                    >
                      {props.more}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div
                style={{
                  minHeight: "15rem",
                  display: "flex",
                  opacity: "0.5",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <h2
                  style={{
                    ...styles.inTitle,
                    lineHeight: "3.1rem",
                    fontSize: fontSize.PageTitle,
                  }}
                >
                  {t(`NoJobBoldText`)} <br />
                  <span
                    style={{ ...styles.title, color: colors.darkBlue }}
                  >{`${t(`NoJobText`)} `}</span>
                  {deviceMode !== StyleMode.desktop ? (
                    <a
                      href={`${t(`NoJobLink`)}`}
                      target="_blank"
                      className={`mobileLink`}
                      rel="noreferrer"
                    >{`${t(`NoJobLinkText`)}`}</a>
                  ) : (
                    <a
                      href={`${t(`NoJobLink`)}`}
                      target="_blank"
                      rel="noreferrer"
                    >{`${t(`NoJobLinkText`)}`}</a>
                  )}
                </h2>
              </div>
            )}
          </div>
        </div>
      </div>

    </Fragment>
  );
};
const styles: StyleSheet = {
  wrap: {
    display: "grid",
    gridGap: "1rem",
    // flexWrap: "wrap",
    gridTemplateColumns: "repeat(auto-fill, minmax(17rem, 1fr))",
  },

  selectBorder: {
    border: "thin solid white",
    borderRadius: "25rem",
    padding: "0.75rem",
    minWidth: "15rem",
    backgroundColor: "transparent",
    fontFamily: fonts.FbReformaLight,
    color: "white",
    fontSize: fontSize.UmbracoElementTitle,
    flex: "1 0 0",
  },
  bgGray: {
    backgroundColor: "rgba(216,242,252,0.39)",
  },
  title: {
    fontSize: fontSize.PageTitle,
    color: colors.white,
    fontWeight: "normal",
    fontFamily: fonts.FbReformaRegular,
    lineHeight: "3.1rem",
    margin: 0,
  },
  inTitle: {
    fontFamily: fonts.FbReformaMedium,
  },
  subtitle: {
    fontSize: fontSize.UmbracoElementTitle,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaLight,
    margin: 0,
  },
  info: {
    fontSize: fontSize.FooterItem,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaLight,
    margin: 0,
    gap: ".5rem",
    alignItems: "center",
    whiteSpace: "nowrap",
    display: "flex",
  },
  maxWidth: {
    maxWidth: "68rem",
    margin: "auto",
    paddingBlock: "2rem",
    paddingInline: "1rem",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  btn: {
    cursor: "pointer",
    borderRadius: "3.75rem",
    backgroundColor: "rgb(249, 186, 77)",
    border: 0,
    fontSize: fontSize.UmbracoElementTitle,
    padding: "1rem",
    fontFamily: fonts.FbReformaMedium,
    minWidth: "10rem",
    flex: "1 0 0",
    // width: "16rem",
  },

  cardJob: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0 2px 23px 0 rgb(0 0 0 / 8%)",
    cursor: "pointer",
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    flex: "1 0 20rem",
    // flexBasis: "45%"
  },
  tags: {
    display: "flex",
    flexWrap: "wrap",
    gap: "0.5rem",
  },
  tag: {
    border: "0.06rem solid #00458a",
    borderRadius: "3.1rem",
    minWidth: "4.4rem",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#D8F2FC",
    textAlign: "center",
    display: "inline-flex",
  },
  linkMoreInfo: {
    color: colors.oldLavender,
    fontSize: fontSize.Paragraph,
    letterSpacing: "0.03rem",
    lineHeight: "1.5rem",
    cursor: "pointer",
  },
};

export default HotJobs;
