import { t } from "i18next";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IBreadcrumb, IBreadCrumbItem } from "../../Interfaces_tenders/IBreadcrumb";
import { StyleSheet } from "../../Interfaces_tenders/IStyleSheet";
import { setErrorStatus } from "../../Redux/Actions/Auctions/AuctionsActions";
import Store from "../../Redux/Store/Store";
import fonts from "../../../../Assets/Fonts/Fonts.json"
const Breadcrumb: React.FC<IBreadcrumb> = (props) => {
  const [_breadcrumbItems, _setbreadcrumbItems] = useState<IBreadCrumbItem[]>([]);
  const { items } = props;

  useEffect(() => {
    let Items: IBreadCrumbItem[] = [];
    if (
      items.length > 1 &&
      items[items.length - 1].text === items[items.length - 2].text
    ) {
      for (let i = 0; i < items.length - 1; i++) {
        Items.push(items[i]);
      }
      _setbreadcrumbItems(Items);
    } else {
      _setbreadcrumbItems(items);
    }
  }, [items]);

  const navigate = useNavigate();

  const currentPage = useSelector((state: any) => state.PageReducer);

  const handleRedirect = (link: string) => {
    Store.dispatch(setErrorStatus(false));
    const routeTo = `?page=${link}`;

    if (link !== "") {
      navigate(routeTo);
    }

    if (link === "/") {
      navigate("/?page=main&auctionsExtrenal=true");
    }
  };

  return (
    <div style={styles.mainContainer}  >
      <Fragment>
        <div
        tabIndex={0}
          style={styles.text}
          onClick={() => {
            handleRedirect("/");
          }}
        >
          <span
            style={{
              textDecoration: "underline",
            }}
          >
            {t("Main-page-breadcrumb")}
          </span>
        </div>
        <div>{">"} </div>
      </Fragment>
      {_breadcrumbItems.map((breadcrumbItem, index) => (
        <Fragment
          key = {index}
        >
          <div
          tabIndex={0}
            style={{  
              ...styles.text,
              textDecoration:
                index === _breadcrumbItems.length - 1 ? "none" : "underline",
              cursor: breadcrumbItem.link === currentPage ? "" : "pointer",
            }}
            onClick={() => {
              if (breadcrumbItem.link.toLowerCase() !== currentPage) {
                handleRedirect(breadcrumbItem.link);
              }
            }}
          >
            {breadcrumbItem.text}
          </div>
          {index < _breadcrumbItems.length - 1 && <div>{">"} </div>}
        </Fragment>
      ))}
    </div>
  );
};

const styles: StyleSheet = {
  mainContainer: {
    display: "flex",
    gap: ".5em",
  },
  text: {
    cursor: "pointer",
    fontFamily: fonts.FbReformaRegular,
    fontSize: "1rem",
  },
};

export default Breadcrumb;
