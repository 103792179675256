import { ITabs } from "../../Interfaces/ITabs";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import fontSize from "../../Assets/Fonts/FontsSizes.json";

const Tabs: React.FC<ITabs> = (props) => {
  const { tabs, onTabClick } = props;
  return (
    <div style={styles.container}>
      {tabs.map((tab, index) => (
        <div
          style={styles.tab}
          key={index}
          onClick={() => {
            onTabClick(tab);
          }}
        >
          <span
            style={{ ...styles.tabName, opacity: tab.isSelected ? 1 : 0.5 }}
          >
            {tab.name}
          </span>
          {tab.isSelected && <div style={styles.line}></div>}
        </div>
      ))}
    </div>
  );
};
const TabsColumn: React.FC<ITabs> = (props) => {
  const { tabs, onTabClick } = props;
  return (
    <div style={{display:"flex",flexDirection:"column"}}>
      {tabs.map((tab, index) => (
        <div
          style={{...styles.tab,backgroundColor:tab.isSelected ? colors.koromiko : "",padding:"2rem 1rem"}}
          key={index}
          onClick={() => {
            onTabClick(tab);
          }}
        >
          <span
            style={{ ...styles.tabName, opacity: tab.isSelected ? 1 : 0.5 }}
          >
            {tab.name}
          </span>
          {/* {tab.isSelected && <div style={styles.line}></div>} */}
        </div>
      ))}
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "6.75rem",
    boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 15px 0 rgba(0, 0, 0, 0.19)",
    gap: "1rem",
  },
  tab: {
    cursor: "pointer",
    position: "relative",
    minWidth: "17rem",
    textAlign: "center",
  },
  line: {
    position: "absolute",
    width: "100%",
    height: ".5rem",
    bottom: "-3rem",
    backgroundColor: colors.koromiko,
  },
  tabName: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.UmbracoElementTitle,
    letterSpacing: "0.039375rem",
  },
};

export {TabsColumn};
export default Tabs;
