import colors from "../../../../Assets/Colors/Colors.json";
import fonts from "../../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../../Assets/Fonts/FontsSizes.json";
import { IArticleWithMedia } from "../../../../Interfaces/IMagazinePage";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import { StyleMode } from "../../../../Utils/consts";
import useDeviceMode from "../../../../Utils/useWindowDimensions";
import ArticleTitleAndText from "../Components/ArticleTitleAndText";
import ImageCover from "../Components/ImageCover";
import RichDescription from "../Components/RichDescription";

const MediaAndTextArticle: React.FC<IArticleWithMedia> = (props) => {
  const {
    titleAndDescription,
    media,
    direction,
    videoThumbnail,
    anchor,
  } = props;

  const deviceMode = useDeviceMode();
  return (
    <div
      anchor-id={anchor}
      style={{
        ...styles.wapper,
        height: deviceMode === StyleMode.desktop ? "35rem" : "",
        borderRadius: deviceMode === StyleMode.desktop ? "0.5rem" : "0",
        flexDirection: deviceMode === StyleMode.desktop
          ? direction === "Right to Left"
            ? "row"
            : "row-reverse"
          : "column",
      }}
    >
      <div style={styles.container}>
        <ImageCover media={media} videoThumbnail={videoThumbnail} />
      </div>
      <div style={{ ...styles.information, padding: deviceMode === StyleMode.desktop ? "4rem" : "1rem 2rem" }}>
        <ArticleTitleAndText {...props} title={titleAndDescription.title} />
        <RichDescription text={titleAndDescription.description} />
      </div>

    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flex: "2.7"
  },
  wapper: {
    display: "flex",
    position: "relative",
    backgroundColor: colors.ghostWhite,
    borderRadius: "0.5rem",
    overflow: "hidden"

  },
  information: {
    padding: "4rem",
    flex: "1",
    boxSizing: "border-box"
  },
  title: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.UmbracoElementTitle,
    lineHeight: "1.8rem",
    margin: 0,
  },
};

export default MediaAndTextArticle;
