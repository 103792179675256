import { ITitle } from "../../Interfaces_tenders/IBaseElement";
import colors from "../../../../Assets/Colors/Colors.json";
import { StyleSheet } from "../../Interfaces_tenders/IStyleSheet";
import fonts from "../../../../Assets/Fonts/Fonts.json"

const Title: React.FC<ITitle> = (props) => {
  const {
    text,
    color,
    titleStyles,
    containerStyles,
    isUnderline = true,
  } = props;

  return (
    <div style={containerStyles ? containerStyles : styles.container}>
      <div style={titleStyles ? titleStyles : styles.title}>
        <label style={{lineHeight:`1.8rem`}}>
          <span style={isUnderline ? styles.titleUnderline : {}}>{text}</span>
        </label>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    maxWidth: "76rem",
    width: "80%",
  },
  title: {
    display: "flex",
    fontSize: "1.5625rem",
    fontFamily: fonts.FbReformaMedium,
    color: colors.darkBlue,
  },
  titleUnderline: {
    borderBottom: `1px solid ${colors.koromiko}`,
    borderBottomWidth: ".2rem",
    padding: ".2rem 0",
  },
};

export default Title;
