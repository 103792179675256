import store from "store2";
import Config from "../Configuration/server";
import { ILastTravelLocalStorage } from "../Interfaces/ILastTravel";
import { ScheduleState } from "../Redux/Slices/scheduleSlice";

export const AddTravelToLastTravels = (schedule: ScheduleState) => {
  const isTravelsHistorySavedLocalStorage = store.get("isTravelsHistorySaved");
  let isTravelsHistorySaved = true;
  if (isTravelsHistorySavedLocalStorage === null) {
    store.set("isTravelsHistorySaved", true);
    isTravelsHistorySaved = true;
  } else {
    isTravelsHistorySaved = isTravelsHistorySavedLocalStorage;
  }

  if (isTravelsHistorySaved) {
    const lastTravels: ILastTravelLocalStorage[] = store.get(
      `${Config.BASE_LOCALSTORAGE_NAME}lastTravels`
    );
    if (schedule.fromStation && schedule.toStation) {
      let travels = [...lastTravels];
      if (travels.length === 0) {
        const newTravel: ILastTravelLocalStorage = {
          from: schedule.fromStation,
          to: schedule.toStation,
          isFavorite: false,
        };
        travels.push(newTravel);
        store.set(`${Config.BASE_LOCALSTORAGE_NAME}lastTravels`, travels);
      } else {
        if (
          !lastTravels.some(
            (lastTravel) =>
              lastTravel.from === schedule.fromStation &&
              lastTravel.to === schedule.toStation
          )
        ) {
          const newTravel: ILastTravelLocalStorage = {
            from: schedule.fromStation,
            to: schedule.toStation,
            isFavorite: false,
          };

          const favorites = travels.filter((travel) => travel.isFavorite);
          const notFavorites = travels.filter((travel) => !travel.isFavorite);
          travels = [...favorites, newTravel, ...notFavorites];
          store.set(`${Config.BASE_LOCALSTORAGE_NAME}lastTravels`, travels);
        } else {
          SetTravelOnTopList(schedule.fromStation, schedule.toStation);
        }
      }
    }
  }
};

export const SetTravelOnTopList = (fromStation: number, toStation: number) => {
  const lastTravels: ILastTravelLocalStorage[] = store.get(
    `${Config.BASE_LOCALSTORAGE_NAME}lastTravels`
  );

  const selectedTravelIndex = lastTravels.findIndex(
    (travel) => travel.from === fromStation && travel.to === toStation
  );
  if (selectedTravelIndex !== -1) {
    const selectedTravel = { ...lastTravels[selectedTravelIndex] };
    lastTravels.splice(selectedTravelIndex, 1);
    const favorites = lastTravels.filter((travel) => travel.isFavorite);
    const notFavorites = lastTravels.filter((travel) => !travel.isFavorite);

    let travels: ILastTravelLocalStorage[] = [];
    if (!selectedTravel.isFavorite) {
      travels = [...favorites, selectedTravel, ...notFavorites];
    } else {
      travels = [selectedTravel, ...favorites, ...notFavorites];
    }
    store.set(`${Config.BASE_LOCALSTORAGE_NAME}lastTravels`, travels);
  }
};
