import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import colors from "../../../../../Assets/Colors/Colors.json";
import fonts from "../../../../../Assets/Fonts/Fonts.json";
import Images from "../../../../../Data/Images/Images";
import { IAuctionsSystemTypesAndDomains } from "../../../Interfaces_tenders/IAuctionsSystemDetails";
import { IAutoCompleteForm } from "../../../Interfaces_tenders/IForm";
import { StyleSheet } from "../../../Interfaces_tenders/IStyleSheet";
const emptyObject = {
  id: 0,
  type: 0,
  domainKey: "All",
  domainValue: t("All"),
  name: t("All"),
};
const AutoCompleteForm: React.FC<IAutoCompleteForm> = (props) => {
  const {
    placeholder,
    items,
    onItemClick,
    defaultSelection,
    isAllDisplayed = true,
    customStyles,
    onChange,
    id,
    width = "11.5625rem",
    isAllExists,
  } = props;

  const [_isDropDownVisible, _setIsDropDownVisible] = useState(false);
  const [_filteredItems, _setFilteredItems] = useState(items);
  const [_inputValue, _setInputValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const dropDownRef = useRef<HTMLDivElement>(null);

  const checkMouseClickOutside = (e: MouseEvent) => {
    if (dropDownRef.current && e.target instanceof HTMLElement) {
      if (!dropDownRef.current.contains(e.target)) {
        _setIsDropDownVisible(false);
      } else {
        _setIsDropDownVisible(true);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", checkMouseClickOutside);
    return () => {
      document.removeEventListener("click", checkMouseClickOutside);
    };
  }, []);

  useEffect(() => {
    if (defaultSelection) {
      const item = _filteredItems.find(
        (item) => item.domainValue === defaultSelection
      );
      if (item) {
        handleItemSelect(item);
      }
    }
  }, [defaultSelection]);

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    _setInputValue(text);

    const filteredItems = [
      ...items.filter((item) =>
        item.domainValue.toLowerCase().includes(text.toLowerCase())
      ),
    ];
    _setFilteredItems(filteredItems);
  };

  const handleItemSelect = (selectedItem: IAuctionsSystemTypesAndDomains) => {
    if (inputRef.current) {
      inputRef.current.value = selectedItem.domainValue;
    }

    onItemClick(selectedItem.domainKey);
    _setIsDropDownVisible(false);
  };

  return (
    <div
      ref={dropDownRef}
      style={{
        ...styles.container,
        width: width,
        border: `0.0625rem solid ${
          _isDropDownVisible ? colors.darkBlue : colors.pastelGray
        }`,
        ...customStyles,
      }}
    >
      <input
        type={"text"}
        placeholder={placeholder}
        style={{ ...styles.input, marginInlineStart: "1rem" }}
        onChange={(e) => {
          handleFilter(e);
          onChange && onChange(e);
        }}
        ref={inputRef}
        onClick={() => {
          _setIsDropDownVisible(!_isDropDownVisible);
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            _setIsDropDownVisible(true);
          }
        }}
        id={id}
      />

      <img src={Images.arrow} style={{ marginInlineEnd: "1rem" }} />

      {_isDropDownVisible && _filteredItems.length > 0 && (
        <div style={styles.dropDownContainer}>
          {isAllExists && (
            <div
              style={styles.item}
              onClick={() => handleItemSelect(emptyObject)}
              tabIndex={0}
              role={"navigation"}
              aria-label={`${emptyObject} ${t("MenuItemNumberOutOfNumber", {
                currentIndex: 0,
                total: _filteredItems.length,
              })}`}
            >
              {t("All")}
              <div style={styles.line}></div>
            </div>
          )}
          {_filteredItems.map((item, index) => (
            <div
              style={styles.item}
              key={index}
              onClick={() => handleItemSelect(item)}
              tabIndex={0}
              role={"navigation"}
              aria-label={`${item} ${t("MenuItemNumberOutOfNumber", {
                currentIndex: index + 1,
                total: _filteredItems.length,
              })}`}
            >
              {item.domainValue}
              {index < _filteredItems.length - 1 && (
                <div style={styles.line}></div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "18.6rem",
    width: "100%",
    height: "2.5rem",
    borderRadius: "1.25rem",
    gap: ".3rem",
    padding: "0 .5rem",
    position: "relative",
    zIndex: "30",
  },
  input: {
    width: "100%",
    fontSize: "1.125rem",
    color: colors.pasterlGrayDark,
    letterSpacing: "0.043125rem",
    fontFamily: fonts.FbReformaRegular,
  },

  dropDownContainer: {
    position: "absolute",

    top: "2.7rem",
    width: "100%",
    boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 15px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: "0.3125rem",
    backgroundColor: colors.white,
    zIndex: "1",
    overflow: "auto",
    maxHeight:"12rem"
  },
  item: {
    position: "relative",
    fontFamily: fonts.FbReformaRegular,
    fontSize: "1.125rem",
    color: colors.black,
    letterSpacing: "0.03125rem",
    margin: "1rem",
    paddingTop: ".3rem",
    cursor: "pointer",
  },
  line: {
    position: "absolute",
    width: "100%",
    height: "0.125rem",
    backgroundColor: colors.platinum,
    top: "2rem",
  },
};

export default AutoCompleteForm;
