import { t } from "i18next";
import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import Images from "../../Data/Images/Images";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { IDynamicText } from "../../Interfaces/IUmbraco";
import { PageState } from "../../Redux/Slices/pageSlice";
import { IsMobile } from "../../Services/DeviceService";
import useDeviceMode from "../../Utils/useWindowDimensions";
import { replaceRichtextBoldWithFont } from "../../Utils/utils";
import CustomModal from "../CustomModal";
import { linkBuilder, linkShareFormat } from "../SharePage/ShareOptions";
import SocialShare from "../SocialShare/SocialShare";

export interface IInfoCareer {
  email: string;
  endPublishDate: Date;
  jobComments: string;
  jobDescription: string;
  jobNumber: number | string;
  jobRequirements: string;
  location: string;
  publishDate: Date;
  careersText: IDynamicText[];
  title: string;
  customStyles?: React.CSSProperties;
}

const InfoCareer: React.FC<IInfoCareer> = (props) => {
  const {
    // contentTypeAlias: "career"
    email,
    endPublishDate,
    jobComments,
    jobDescription,
    jobNumber,
    jobRequirements,
    location,
    publishDate,
    careersText,
    // submissionDate: "2022-05-29T12:00:00",
    title,
    customStyles,
  } = props;

  const [_translate, _setTranslate] = useState<any>({});
  const [_isShareModalVisible, _setIsShareModalVisible] = useState(false);
  const currentPage = (
    useSelector((state: any) => state.PageReducer) as PageState
  ).page;

  const deviceMode = useDeviceMode();

  const closeButtonRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    var Translate = {};
    careersText?.map((item: any) => {
      Translate = {
        ...Translate,
        [item.textID]: item.text,
      };
    });
    _setTranslate(Translate);
  }, []);

  const PropertyInfo = (
    title: string,
    text: string | number,
    isBox: boolean,
    icon?: any
  ) => {
    return (
      <div
        style={{
          ...styles.propertyInfoBox,
          flexDirection: icon ? "row" : "column",
          boxShadow: isBox ? "0 2px 6px 0 rgba(0, 0, 0, 0.11)" : "",
          gap: icon ? "1rem" : "",
        }}
      >
        <div>
          {icon && <img src={icon} width={`auto`} height={`auto`} alt={``} />}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span style={{ ...styles.title, fontSize: fontSize.FooterItem }}>
            {title}
          </span>
          <span>{text}</span>
        </div>
      </div>
    );
  };
  const getShareUrl = (url: string) => {
    let link = `${window.location.href}&step=openauctions`;
    let title = t("ShareCareerNumber", {
      jobNumber: jobNumber,
    });
    linkShareFormat.title = title;
    linkShareFormat.subject = title;
    linkShareFormat.body = encodeURIComponent(title + "%0A" + link);
    linkShareFormat.summary = encodeURIComponent(title + "%0A" + link);
    linkShareFormat.text = encodeURIComponent(title + "%0A" + link);
    linkShareFormat.quote = title;
    linkShareFormat.url = encodeURIComponent(link);
    linkShareFormat.u = encodeURIComponent(link);
    // sharePagetext
    let newLink = linkBuilder(url, linkShareFormat);

    return decodeURI(newLink);
  };

  const socialNetworkOptions = [
    {
      name: t("Facebook"),
      icon: Images.facebookHeader,
      link: getShareUrl(`https://www.facebook.com/sharer/sharer.php?u=`),
      ariaLabel: t("ShareFacebook"),
    },
    {
      name: t("WhatsApp"),
      icon: Images.whatsapp,
      link: getShareUrl(`https://wa.me/?text=`),
      ariaLabel: t("ShareWhatsapp"),
    },
    {
      name: t("Mail"),
      icon: Images.shareMail,
      link: getShareUrl(`mailto:?subject=&body=`),
      ariaLabel: t("ShareEmail"),
    },
    {
      name: t("Linkedin"),
      icon: Images.linkedinHeader,
      link: getShareUrl(`https://www.linkedin.com/sharing/share-offsite/?url=`),
      ariaLabel: t("ShareLinkedin"),
    },
  ];

  return (
    <Fragment>
      <div
        id="pageMainContent"
        tabIndex={!IsMobile() ? 0 : -1}
        style={{
          ...styles.modalContainer,
          width: !currentPage.includes("career_") ? "100%" : "",
          ...customStyles,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
            }}
          >
            <div style={styles.propertyInfoContainer}>
              {PropertyInfo(_translate.jobNumber, jobNumber, true, false)}
              {/* {PropertyInfo(_translate.jobName, jobNumber, true, false)} */}
            </div>
            <div style={styles.propertyInfoContainer}>
              {PropertyInfo(
                _translate.publishDate,
                moment(publishDate).format("L"),
                false,
                Images.datePicker
              )}
              {PropertyInfo(
                _translate.jobEndPublishDate,
                moment(endPublishDate).format("L"),
                false,
                Images.datePicker
              )}
            </div>
            <div style={styles.propertyInfoContainer}>
              {PropertyInfo(
                _translate.jobLocation,
                location.replace(/.*\^/gm, ""),
                false,
                Images.location
              )}
            </div>
          </div>
          <h3 style={{ ...styles.title }}>{_translate.jobDescription}:</h3>
          <div
            style={styles.description}
            dangerouslySetInnerHTML={{
              __html: replaceRichtextBoldWithFont(jobDescription),
            }}
          // aria-label={RemoveHtmlTags(jobDescription)}
          ></div>
          <h3 style={styles.title}>{_translate.jobRequirements}:</h3>
          <div
            style={styles.description}
            dangerouslySetInnerHTML={{
              __html: replaceRichtextBoldWithFont(jobRequirements),
            }}
          // aria-label={RemoveHtmlTags(jobDescription)}
          ></div>
          {jobComments && (
            <>
              <h3 style={{ ...styles.title, paddingBottom: "1rem" }}>
                {_translate.jobComments}:
              </h3>
            </>
          )}
          <div
            style={styles.description}
            dangerouslySetInnerHTML={{
              __html: replaceRichtextBoldWithFont(jobComments),
            }}
          // aria-label={RemoveHtmlTags(jobDescription)}
          ></div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "1rem",
            }}
          >
            <button
              style={styles.button}
              onClick={() => {
                window.location.href = `mailto:${email}`;
              }}
            >
              {_translate.jobEmail}
            </button>
            <img
              style={styles.shareIcon}
              src={Images.sharePng}
              width={"30"}
              role={"button"}
              aria-label={t("ShareCareer")}
              tabIndex={!IsMobile() ? 0 : -1}
              onClick={() => {
                _setIsShareModalVisible(true);
              }}
              onKeyPressCapture={() => {
                _setIsShareModalVisible(true);
              }}
              onKeyDown={(e) => {
                if (e.key === "Tab") {
                  if (closeButtonRef.current) {
                    e.stopPropagation();
                    e.preventDefault();
                    closeButtonRef.current.focus();
                  }
                }
              }}
            />
          </div>
        </div>
      </div>

      <CustomModal
        visible={_isShareModalVisible}
        onClose={() => _setIsShareModalVisible(false)}
        typeStyle={"rectangle"}
        icon={Images.share}
        title={t("Share")}
        responsiveModal={"bottom"}
      >
        <SocialShare items={socialNetworkOptions} />
        <button
          className="btn-modal"
          onClick={() => _setIsShareModalVisible(false)}
          style={{ textAlign: "center", backgroundColor: "#F0F0F0" }}
        >
          {t("Cancel")}
        </button>
      </CustomModal>
    </Fragment>
  );
};

const styles: StyleSheet = {
  modalContainer: {
    padding: "2rem 3rem",
    maxHeight: "100%",
    overflow: "auto",
  },
  title: {
    color: colors.darkBlue,
    fontSize: fontSize.UmbracoElementTitle,
    fontFamily: fonts.FbReformaMedium,
  },
  propertyInfoContainer: {
    display: "flex",
    gap: "5rem",
    flexWrap: "wrap",
  },
  propertyInfoBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    width: "14rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  description: {
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.FooterItem,
    lineHeight: "2rem",
    maxWidth: "80%",
  },
  button: {
    backgroundColor: colors.darkBlue,
    color: colors.white,
    fontSize: fontSize.Text,
    borderRadius: "16rem",
    borderColor: colors.darkBlue,
    minWidth: "8rem",
    height: "2rem",
    fontWeight: "bold",
    textAlign: "center",
    cursor: "pointer",
  },
  shareIcon: {
    cursor: "pointer",
  },
};

export default InfoCareer;
