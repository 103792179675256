import React, { useState } from "react";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  pdf,
} from "@react-pdf/renderer";
import colors from "../../Assets/Colors/Colors.json";
import { FormatDateYYYYMMDD_Dots } from "../../Services/DateService";
import { IPdfDocument } from "../../Interfaces/IPdfDocument";
import saveAs from "file-saver";
import { t } from "i18next";
const fontHe = require("../../Assets/Fonts/Rubik/rubik.ttf");
Font.register({
  family: "HebrewFont",
  fonts: [
    {
      src: fontHe, // Replace with the path to your font file
      fontWeight: "normal",
    },
  ],
});

const PdfDocument: React.FC<IPdfDocument> = (props) => {

  const { details } = props;
  const resultTable: Record<string, any>[] = [];

  let serialNumber = 1;
  let groupedDetails: Record<string, any> = {};


  details.forEach((detail) => {
    const planningAreas = detail.planningAreas;

    if (!groupedDetails[planningAreas]) {
      groupedDetails[planningAreas] = [];
    }

    groupedDetails[planningAreas].push(detail);
  });

  const sortedGroups = Object.values(groupedDetails).map((group) => {
    return group.sort(
      (id: { serialNumber: string }, serialNumber: { serialNumber: any }) =>
        id.serialNumber?.localeCompare(serialNumber.serialNumber)
    );
  });


  for (let i = 0; i < sortedGroups.length; i++) {
    const group = sortedGroups[i];

    for (let j = 0; j < group.length; j++) {
      const detail = group[j];

      const item = {
        sn: j === 0 ? serialNumber : "",
        planningAreas: detail.planningAreas,

        // planningAreas:
        //   group[j]?.planningAreas !== group[j - 1]?.planningAreas
        //     ? detail.planningAreas
        //     : "",

        serialNumber: detail.serialNumber,
        subject: detail.subject as string,
        docDisplay: detail.documentation[0]?.docName,
        updateDate: FormatDateYYYYMMDD_Dots(detail.updateDate),
      };
      resultTable.push(item);
    }
    serialNumber++;
  }

  
  const MyDoc = () => {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.pdfHeaderContainer}>
            <Text style={styles.pdfHeaderDate}>
              {FormatDateYYYYMMDD_Dots(new Date())}
            </Text>
            <Text style={styles.pdfHeaderName}>
              {t("GuideLinesAndRatesTree")}
            </Text>
          </View>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableColHeaderS}>
                <Text>{t("idOrder")}</Text>
              </View>
              <View style={styles.tableColHeaderM}>
                <Text>{t("planningArea")}</Text>
              </View>
              <View style={styles.tableColHeaderS}>
                <Text>{t("directiveNumber")}</Text>
              </View>
              <View style={styles.tableColHeaderL}>
                <Text>{t("directiveName")}</Text>
              </View>
              <View style={styles.tableColHeaderS}>
                <Text>{t("fileType")}</Text>
              </View>
              <View style={styles.tableColHeaderM}>
                <Text>{t("updateDate")}</Text>
              </View>
            </View>
            {resultTable.map((row, index) => (
              <View
                key={index}
                style={{
                  ...styles.tableRow,
                  backgroundColor:
                    index % 2 === 0 ? colors.white : colors.lighterGray,
                  borderBottomWidth:
                    resultTable[index]?.planningAreas=== resultTable[index+1]?.planningAreas
                      ? 0
                      : 1,
                  borderBottomColor:
                    resultTable[index]?.planningAreas=== resultTable[index+1]?.planningAreas
                      ? "blue"
                      : "black",
                }}
              >
                <View style={styles.tableCellS}>
                  <Text>{row.sn}</Text>
                </View>
                <View style={styles.tableCellM}>
                  <Text>{row.planningAreas}</Text>
                </View>
                <View style={styles.tableCellS}>
                  <Text>{row.serialNumber}</Text>
                </View>
                <View style={styles.tableCellL} wrap fixed>
                  <Text id="Footnote">{row.subject}</Text>
                </View>
                <View style={{ ...styles.tableCellS, textAlign: "center" }}>
                  <Text>{row.docDisplay?.split(".").pop().toLowerCase()}</Text>
                </View>
                <View style={{ ...styles.tableCellM, textAlign: "center" }}>
                  <Text>{row.updateDate}</Text>
                </View>
              </View>
            ))}
          </View>
        </Page>
      </Document>
    );
  };

  const generatePdfDocument = async () => {
    const blob = await pdf(<MyDoc />).toBlob();
    saveAs(blob, t("GuideLinesAndRatesTree"));
  };

  return (
    <div>
      <div style={styles.curLink} onClick={() => generatePdfDocument()}>
        {t("GuideLinesAndRatesTreeDownload")}
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
    paddingTop: "16px",
  },
  table: {
    display: "flex",
    width: "95%",
    // borderStyle: "solid",
    // borderWidth: "1px",
    // // borderColor: "#000",
    marginBottom: 10,
    padding: "16px",

    // alignContent: "center",
  },
  tableRow: {
    flexDirection: "row-reverse",
    // borderBottomWidth: 1,
    // borderBottomColor: 'black',
  },
  tableColHeaderS: {
    width: "10%",
    // borderStyle: "solid",
    // borderWidth: "1px",
    // borderColor: "#000",
    padding: 5,
    backgroundColor: colors.gray,
    fontFamily: "HebrewFont",
    fontSize: "8px",
    textAlign: "center",
    color: colors.white,
  },
  tableCellS: {
    width: "10%",
    // borderStyle: "solid",
    // borderWidth: "1px",
    // borderColor: "#000",
    padding: 5,
    fontFamily: "HebrewFont",
    fontSize: "10px",
    textAlign: "right",
    color: colors.nero,
  },
  tableColHeaderM: {
    width: "20%",
    // borderStyle: "solid",
    // borderWidth: "1px",
    // borderColor: "#000",
    padding: 5,
    backgroundColor: colors.gray,

    fontFamily: "HebrewFont",
    fontSize: "12px",
    textAlign: "center",
    color: colors.white,
  },
  tableCellM: {
    width: "20%",
    // borderStyle: "solid",
    // borderWidth: "1px",
    // borderColor: "#000",
    padding: 5,
    fontFamily: "HebrewFont",
    fontSize: "10px",
    textAlign: "right",
    color: colors.nero,
  },
  tableColHeaderL: {
    width: "36%",
    // borderStyle: "solid",
    // borderWidth: "1px",
    // borderColor: "#000",
    padding: 5,
    backgroundColor: colors.gray,

    fontFamily: "HebrewFont",
    fontSize: "12px",
    textAlign: "center",
    color: colors.white,
  },
  tableCellL: {
    width: "36%",
    // borderStyle: "solid",
    // borderWidth: "1px",
    // borderColor: "#000",
    padding: 5,
    fontFamily: "HebrewFont",
    fontSize: "10px",
    textAlign: "right",
    color: colors.nero,
    direction: "rtl",
    flexWrap: "nowrap",
    display: "flex",
  },
  pdfHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
    padding: "12px",
  },
  pdfHeaderName: {
    textAlign: "right",
    fontFamily: "HebrewFont",
    color: colors.nero,
  },
  pdfHeaderDate: {
    textAlign: "right",
    fontFamily: "HebrewFont",
    color: colors.nero,
  },
  curLink: {
    color: colors.darkBlue,
    cursor: "pointer",
  },
});

export default PdfDocument;
