import { StyleSheet } from "../../../../../Interfaces_tenders/IStyleSheet";
import { FormatDateYYYYMMDD_Dots } from "../../../../../../../Services/DateService";
import colors from "../../../../../../../Assets/Colors/Colors.json";
import i18n from "../../../../../../../Services/i18n";
import { useEffect, useState } from "react";
import AuctionSuppliers from "./AuctionSuppliers";
import {
  IAuctionsSystemAuctions,
  IAuctionsSystemDomains,
  IAuctionsSystemTypes,
} from "../../../../../Interfaces_tenders/IAuctionsSystemDetails";
import { useSelector } from "react-redux";
import { truncateText } from "../../../../../../../Utils/helpers";
import { t } from "i18next";
import fonts from "../../../../../../../Assets/Fonts/Fonts.json";
const AuctionResultCard: React.FC<IAuctionsSystemAuctions> = (props) => {
  const {
    tenderName,
    tenderNumber,
    publishDate,
    filed,
    winningSupplier,
    winningDate,
    winningAmount,
    waitingSupplier,
    dynamicTextsDict,
    auctionSystem,
    currentIndex,
    numOfAuctions,
  } = props;

  const [winningSupplierArr, _setWinningSupplier] = useState<string[]>([]);
  const [waitingSupplierArr, _setWaitingSupplier] = useState<string[]>([]);


  useEffect(() => {
    if (winningSupplier) {
      winningSupplier.includes(",")
        ? _setWinningSupplier(winningSupplier.split(","))
        : _setWinningSupplier([winningSupplier]);
    } else {
      _setWinningSupplier([]);
    }
  }, [winningSupplier]);

  useEffect(() => {
    if (waitingSupplier) {
      waitingSupplier.includes(",")
        ? _setWaitingSupplier(waitingSupplier.split(","))
        : _setWaitingSupplier([waitingSupplier]);
    } else {
      _setWaitingSupplier([]);
    }
  }, [waitingSupplier]);

  const dynamicTexts =
    dynamicTextsDict && dynamicTextsDict.length > 0
      ? dynamicTextsDict[0]
      : undefined;
      const _currentTypes = (useSelector(
        (state: any) => state.TendersReducer
      ).tenderType) as IAuctionsSystemTypes[];

  return (
    <div
      style={styles.auctionCardContainer}
      role={"main"}
      tabIndex={0}
      aria-label={
        t("tenderIndexNumber") +
        (currentIndex && currentIndex) +
        t("fromTotal") +
        (numOfAuctions && numOfAuctions)
      }
    >
      <div style={styles.auctionHeader}>
        <div style={styles.domainNameAndTagWrapper}>
          <div style={styles.domainOptions}>
            {filed !== 0
              ? _currentTypes.length > 0 &&
                auctionSystem &&
                auctionSystem.domainOptions.filter(
                  (domain: IAuctionsSystemDomains) =>
                    domain.domainKey ===
                    _currentTypes.filter((field) => field.id === filed)[0].name
                )[0].domainValue
              : ""}
          </div>
        </div>
        <div style={styles.auctionName} title={tenderName}>
          {tenderName && truncateText(tenderName, 75)}
        </div>
        {tenderNumber !== undefined && (
          <div style={styles.auctionNumber}>
            {dynamicTexts && dynamicTexts["auctionNumber"].text} {tenderNumber}
          </div>
        )}
        {dynamicTextsDict && winningSupplierArr && waitingSupplierArr && (
          <AuctionSuppliers
            winningSupplierArr={winningSupplierArr}
            waitingSupplierArr={waitingSupplierArr}
            dynamicTextsDict={dynamicTextsDict}
          />
        )}
      </div>
      <div   style={{
              ...styles.dates,            
              paddingLeft:
                i18n.dir() === "ltr" ? `1rem` : "",
            }}>
        {publishDate &&
        publishDate.toString() !== "0001-01-01T00:00:00" &&
        publishDate.toString() !== "1900-01-01T00:00:00" &&
        publishDate.toString() !== "8900-12-31T00:00:00" ? (
          <div style={{
            ...styles.date,
            borderRight:
              i18n.dir() === "rtl" ? `.1rem solid ${colors.black}` : "",
            borderLeft:
              i18n.dir() === "ltr" ? `.1rem solid ${colors.black}` : "",
          }}>
            <div style={styles.dateTitle}>
              {dynamicTexts && dynamicTexts["publishDate"].text}
            </div>
            <div style={styles.dateFormat}>
              {FormatDateYYYYMMDD_Dots(new Date(publishDate))}
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {winningDate &&
        winningDate.toString() !== "0001-01-01T00:00:00" &&
        winningDate.toString() !== "1900-01-01T00:00:00" &&
        winningDate.toString() !== "8900-12-31T00:00:00" ? (
          <div style={{
            ...styles.date,
            borderRight:
              i18n.dir() === "rtl" ? `.1rem solid ${colors.black}` : "",
            borderLeft:
              i18n.dir() === "ltr" ? `.1rem solid ${colors.black}` : "",
          }}>
            <div style={styles.dateTitle}>
              {dynamicTexts && dynamicTexts["winningDate"].text}
            </div>
            <div style={styles.dateFormat}>
              {FormatDateYYYYMMDD_Dots(new Date(winningDate))}
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {winningAmount && dynamicTexts && (
          <div style={{
            ...styles.date,
            borderRight:
              i18n.dir() === "rtl" ? `.1rem solid ${colors.black}` : "",
            borderLeft:
              i18n.dir() === "ltr" ? `.1rem solid ${colors.black}` : "",
          }}>
            <div style={styles.dateTitle}>
              {dynamicTexts && dynamicTexts["winningAmount"].text}
            </div>
            <div
              style={styles.auctionPrice}
              title={
                winningAmount.toString() + " " + dynamicTexts["nisInYear"].text
              }
            >
              {winningAmount &&
                dynamicTexts &&
                truncateText(
                  winningAmount.toString() +
                    " " +
                    dynamicTexts["nisInYear"].text,
                  30
                )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  auctionCardContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "40.625rem",
    width: "80%",
    height: "23rem",
    boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 15px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: "0.625rem",
    position: "relative",
  },
  auctionHeader: {
    display: "flex",
    flexDirection: "column",
    padding: "1.45rem",
    gap: ".5rem",
    flex: 2,
  },
  auctionName: {
    color: colors.darkBlue,
    fontSize: "1.6rem",
    letterSpacing: "0.039375rem",
    fontFamily: fonts.FbReformaMedium,
    maxWidth: "35rem",
    height: "4rem",
    lineHeight: "2rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  domainNameAndTagWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  auctionNumber: {
    color: colors.darkBlue,
    fontSize: "1rem",
    letterSpacing: "0.0275rem",
    fontFamily: fonts.FbReformaLight,
  },
  referent: {
    display: "flex",
    gap: "1rem",
    width: "50%",
  },
  referentRow: {
    display: "flex",
    justifyContent: "space-between",
  },
  icon: {
    backgroundRepeat: "no-repeat",
    width: "1.25rem",
    height: "1.5rem",
  },
  dates: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    flex: 1,
  },
  date: {
    // borderRight: i18n.dir() === "rtl" ? `.1rem solid ${colors.black}` : "",
    // borderLeft: i18n.dir() === "ltr" ? `.1rem solid ${colors.black}` : "",
    padding: "0 1rem",
  },
  dateTitle: {
    fontFamily: fonts.FbReformaRegular,
    color: colors.pasterlGrayDark,
  },
  dateFormat: {
    fontFamily: fonts.FbReformaRegular,
    letterSpacing: "0.038125rem",
    color: colors.black,
  },
  auctionPrice: {
    fontFamily: fonts.FbReformaRegular,
    letterSpacing: "0.038125rem",
    color: colors.black,
  },
  moreDetails: {
    color: colors.white,
    fontFamily: fonts.FbReformaMedium,
    letterSpacing: "0.029375rem",
    fontSize: "1rem",
  },
  domainOptions: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaRegular,
    fontSize: "1rem",
    letterSpacing: "0.025rem",
  },

  auctionsWinningContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "right",
  } as React.CSSProperties,
  updateMe: {
    fontFamily: fonts.FbReformaRegular,
    fontSize: "1.125rem",
    color: colors.cyanBold,
  } as React.CSSProperties,
};

export default AuctionResultCard;
