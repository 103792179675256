import React from "react";
import ReactDatePicker, {
  registerLocale,
  setDefaultLocale,
} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import i18n from "../../../../Services/i18n";
import { he, ru, ar } from "date-fns/locale";
import { IDatePicker } from "../../Interfaces_tenders/IDatePicker";
import useDeviceMode from "../../../../Utils/useWindowDimensions";
import { StyleMode } from "../../../../Utils/consts";

const DatePicker: React.FC<IDatePicker> = (props) => {
  const {
    onChange,
    selectedDate,
    minDate,
    noMinDate,
    onClear,
    outRef,
    maxDate,
  } = props;
  const deviceMode = useDeviceMode();
  registerLocale("he", he);
  registerLocale("ru", ru);
  registerLocale("ar", ar);
  setDefaultLocale("he");

  return deviceMode !== StyleMode.mobile ? (
    <div style={styles.datePickerWrapper}>
      <ReactDatePicker
        selected={new Date(selectedDate)}
        onChange={onChange}
        dateFormat="Pp"
        inline
        locale={i18n.language}
        minDate={minDate && minDate}
        showMonthDropdown
        showYearDropdown
        showDisabledMonthNavigation
        maxDate={maxDate && maxDate}
      />
    </div>
  ) : (
    <div style={styles.datePickerWrapper}>
      <ReactDatePicker
        selected={new Date(selectedDate)}
        onChange={onChange}
        dateFormat="Pp"
        inline
        locale={i18n.language}
        minDate={minDate && minDate}
        showMonthDropdown
        showYearDropdown
        showDisabledMonthNavigation
        maxDate={maxDate && maxDate}
      />
    </div>
  );
};

const styles = {
  datePickerWrapper: {
    paddingTop: "2.5rem",
    
  },

  one: {
    with: "100%",
    background: "black",
    
  } as React.CSSProperties,
};

export default DatePicker;
