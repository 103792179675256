import { StyleSheet } from "../Interfaces/IStyleSheet";
import PisTrainCards from "../Components/Pis/PisTrainCard/PisTrainCards";
import { IPis, IPisTrainCard } from "../Interfaces/IPis";
import colors from "../Assets/Colors/Colors.json";
import fonts from "../Assets/Fonts/Fonts.json";
import fontSize from "../Assets/Fonts/FontsSizes.json";
import { useEffect, useRef, useState } from "react";
import { GetPisDataByStationId } from "../Services/PisService";
import { ConvertPisDetails } from "../Services/PisHelpers";
import PisInformation from "../Components/Pis/PisInformation/PisInformation";
import {
  FormatDateYYYYMMDD_Dots,
  FormatHoursMinutesSeconds,
} from "../Services/DateService";
import { t } from "i18next";
import PisRouteDetails from "../Components/Pis/PisRouteDetails/PisRouteDetails";
import useDeviceMode from "../Utils/useWindowDimensions";
import { StyleMode } from "../Utils/consts";
import PisTrainCardsMobile from "../Components/Pis/PisTrainCard/Mobile/PisTrainCardsMobile";
import Images from "../Data/Images/Images";
import store from "store2";
import Config from "../Configuration/server";
import i18n from "../Services/i18n";
import { GetStationsDictionary } from "../Utils/helpers";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../Components/Loading/Loading";
import StationsListGrouped from "../Components/StationsList/StationsListGrouped";
import {
  FilterAutoComplete,
  GetNearbyStations,
  GetSortedStations,
} from "../Services/SearchStationsAutoCompleteService";
import { IStation } from "../Interfaces/IStation";
import { GetSystemParams } from "../Services/GlobalService";
import { IsMobile } from "../Services/DeviceService";
import Title from "../Components/UmbracoElements/Title";

const Pis: React.FC<IPis> = (props) => {
  let { dynamicPart } = props;

  const stations = store.session.get(
    `${Config.BASE_LOCALSTORAGE_NAME}stations`
  )[i18n.language];
  const dictStations = GetStationsDictionary(stations);

  const deviceMode = useDeviceMode();
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const stationId = searchParams.get("stationId");
  const platformId = searchParams.get("platformId");
  const inputRef = useRef<HTMLInputElement>(null);
  const stationsListWrapperRef = useRef<HTMLDivElement | null>(null);

  const [_isSelectStationActive, _setIsSelectStationActive] = useState(
    stationId === undefined || stationId === null
  );

  const [_titleValue, _setTitleValue] = useState<string>(t("All-stations"));
  const [_isClearButtonVisible, _setIsClearButtonVisible] = useState(false);
  const [_pisDetails, _setPisDetails] = useState<IPisTrainCard[]>([]);
  const [_selectedPisCard, _setSelectedPisCard] = useState<IPisTrainCard>();
  const [_lastRefreshedDate, _setLastRefreshedDate] = useState(
    FormatHoursMinutesSeconds(new Date().toISOString())
  );
  const [_stations, _setStations] = useState<IStation[]>([]);
  const [_hideLastORNearbyStations, _setHideLastORNearbyStations] =
    useState<boolean>(false);
  const [_nearbyStations, _setNearbyStations] = useState<IStation[]>([]);
  const [_staionShowNumber, _setStaionShowNumber] = useState(3);
  const [_isLoading, _setIsLoading] = useState(true);
  const [_isDataLoading, _setIsDataLoading] = useState(true);

  const information_first = dynamicPart.dynamicElements.find(
    (dynamicElement) => dynamicElement.textID === "information_first"
  );
  const information_second = dynamicPart.dynamicElements.find(
    (dynamicElement) => dynamicElement.textID === "information_second"
  );

  const pageMainContent = document.getElementById("pageMainContent");
  if (pageMainContent) {
    pageMainContent.style.padding = "0";
  }

  const getPISData = async () => {
    if (stationId !== undefined && stationId !== null) {
      (async () => {
        const result = await GetPisDataByStationId(+stationId);
        if (result) {
          const pisDetails = filterStationsByPlatform(
            ConvertPisDetails(result)
          );
          _setPisDetails(pisDetails);
          _setIsLoading(false);
          _setIsDataLoading(false);
        }
      })();
    }
  };

  const filterStationsByPlatform = (pisDetails: IPisTrainCard[]) => {
    if (platformId === undefined || platformId === null) {
      return pisDetails;
    }
    return pisDetails.filter((pis) => pis.originPlatform === +platformId);
  };

  useEffect(() => {
    (async () => {
      await getPISData();
    })();
  }, [location, stationId]);

  useEffect(() => {
    const stations: IStation[] = store.session.get(
      `${Config.BASE_LOCALSTORAGE_NAME}stations`
    )[i18n.language];
    const dictStations = GetStationsDictionary(stations);
    if (inputRef.current) {
      const sortedStations = GetSortedStations();
      _setStations(sortedStations);
    }
  }, []);

  useEffect(() => {
    (async () => {
      const nearbyStations = await GetNearbyStations();
      _setNearbyStations(nearbyStations);
      let params = await GetSystemParams();
      params.map((param) => {
        if (param.key === `NumStationByGPS`) {
          _setStaionShowNumber(
            parseInt(param.value && param.value !== "" ? param.value : `3`)
          );
        }
      });
    })();
  }, []);

  const refreshData = async () => {
    _setLastRefreshedDate(FormatHoursMinutesSeconds(new Date().toISOString()));
    _setIsDataLoading(true);
    _setSelectedPisCard(undefined);
    await getPISData();
  };

  const handleSelectedStation = (station: IStation) => {
    navigate(`?page=pis&stationId=${station.stationId}`);
  };

  const handleClearButtonVisible = () => {
    if (inputRef.current) {
      if (inputRef.current.value.length > 0) {
        _setIsClearButtonVisible(true);
      } else {
        _setIsClearButtonVisible(false);
      }
    }
  };

  const resetAllStationsDefault = () => {
    const stations = GetSortedStations();
    _setStations(stations);
  };

  return (
    <div style={styles.container}>
      {_isSelectStationActive ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div
            style={styles.searchBoxWrapper}
            onClick={() => {
              if (inputRef.current) {
                inputRef.current.focus();
              }
            }}
          >
            <img
              src={Images.searchGray}
              width={`auto`}
              height={`auto`}
              alt={``}
              style={{ scale: deviceMode !== StyleMode.desktop ? ".8" : "1" }}
            />
            <input
              ref={inputRef}
              type={"text"}
              style={{
                ...styles.input,
              }}
              placeholder={t("PisSelectStation")}
              aria-label={""}
              onChange={(e) => {
                if (e.target.value !== "") {
                  const filteredStations = FilterAutoComplete(
                    e.currentTarget.value,
                    _stations
                  );
                  _setStations(filteredStations);
                } else {
                  resetAllStationsDefault();
                }
                _setHideLastORNearbyStations(e.currentTarget.value !== "");
                handleClearButtonVisible();
                if (inputRef.current) {
                  if (inputRef.current.value.length === 0) {
                    _setTitleValue(t("All-stations"));
                  } else {
                    _setTitleValue("");
                  }
                }
              }}
              onFocus={() => {
                handleClearButtonVisible();
                // handleInputFocus && handleInputFocus(true);
              }}
              onBlur={() => {
                handleClearButtonVisible();
                _setTitleValue(t("All-stations"));
                // handleInputFocus && handleInputFocus(false);
              }}
            />
            <div
              style={{ width: deviceMode !== StyleMode.desktop ? "20px" : "" }}
            >
              {_isClearButtonVisible && (
                <img
                  tabIndex={0}
                  role={"button"}
                  style={styles.clearButton}
                  src={Images.close}
                  onClick={() => {
                    if (inputRef.current) {
                      inputRef.current.value = "";
                      _setHideLastORNearbyStations(false);
                      resetAllStationsDefault();
                      _setTitleValue(t("All-stations"));
                    }
                  }}
                  alt={``}
                />
              )}
            </div>
          </div>
          <div
            id="stationList"
            style={{
              width: "100%", //deviceMode === StyleMode.desktop ? "100%" : "100%",
              display: "flex",
              justifyContent: "center",
              transform:
                deviceMode === StyleMode.desktop
                  ? "translateY(0rem)"
                  : "translateY(-2rem)",
              direction: i18n.dir() === "rtl" ? "ltr" : "rtl",
              overflow: "auto",
            }}
          >
            <div
              style={{
                ...styles.stationsList,
                paddingInlineStart:
                  deviceMode === StyleMode.desktop ? "1rem" : "1.5rem",
                paddingInlineEnd:
                  deviceMode === StyleMode.desktop ? "1rem" : "1.5rem",
                maxHeight: deviceMode === StyleMode.desktop ? "57vh" : "73vh",
                minHeight: deviceMode === StyleMode.desktop ? "57vh" : "73vh",
                overflowX: "hidden",
              }}
              ref={stationsListWrapperRef}
            >
              {_stations.length > 0 ? (
                <div
                  style={{
                    width: "100%",
                    direction: i18n.dir(),
                  }}
                >
                  <StationsListGrouped
                    title={_titleValue}
                    allStations={_stations}
                    recentLastStationsTitle={t("Closest-stations")}
                    recentLastStations={_nearbyStations}
                    stationsType={"stationsType"}
                    onClick={handleSelectedStation}
                    hideLastORNearbyStations={_hideLastORNearbyStations}
                    textValue={inputRef.current?.value || ""}
                    inputValue={inputRef.current?.value || ""}
                  />
                </div>
              ) : (
                <div style={styles.notFoundContainer}>
                  <img
                    src={Images.notFound}
                    width={`auto`}
                    height={`auto`}
                    alt={``}
                  />
                  <label
                    style={styles.notFoundText}
                    tabIndex={!IsMobile() ? 0 : -1}
                  >
                    {t("No-stations-message")}
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          {_isLoading ? (
            <Loading containerStyles={{ minHeight: "", height: "" }} />
          ) : (
            <>
              <div style={styles.rightDisplay}>
                <div style={styles.informationContainer}>
                  {information_first && information_first.text && (
                    <PisInformation text={information_first.text} />
                  )}
                  {deviceMode === StyleMode.desktop ? (
                    <div style={styles.refreshDataContainer}>
                      <Title text={t("PisTitle")} containerStyles={{}} />
                      {information_second && information_second.text && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                          }}
                        >
                          <span>
                            {information_second.text
                              .replace(
                                "{{date}}",
                                FormatDateYYYYMMDD_Dots(new Date())
                              )
                              .replace("{{second_date}}", _lastRefreshedDate)}
                          </span>
                          <div
                            tabIndex={0}
                            role="button"
                            style={styles.refresh}
                            onClick={refreshData}
                          >
                            <img src={Images.refresh} />
                            <span>{t("Refresh")}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <Title text={t("PisTitle")} />
                    </div>
                  )}
                </div>
                {_isDataLoading ? (
                  <Loading containerStyles={{ minHeight: "", height: "" }} />
                ) : (
                  <>
                    {_pisDetails.length > 0 ? (
                      <>
                        {deviceMode === StyleMode.desktop ? (
                          <PisTrainCards
                            pisTrainCards={_pisDetails}
                            onCardSelected={(selected) => {
                              _setSelectedPisCard(selected);
                            }}
                          />
                        ) : (
                          <>
                            <PisTrainCardsMobile
                              pisTrainCards={_pisDetails}
                              onCardSelected={(selected) => {
                                _setSelectedPisCard(selected);
                              }}
                            />
                            <div style={styles.mobileUpdatedTimeContainer}>
                              <span>
                                {information_second &&
                                  information_second.text
                                    .replace(
                                      "{{date}}",
                                      FormatDateYYYYMMDD_Dots(new Date())
                                    )
                                    .replace(
                                      "{{second_date}}",
                                      _lastRefreshedDate
                                    )}
                              </span>
                              <div
                                tabIndex={0}
                                role="button"
                                style={styles.refresh}
                                onClick={refreshData}
                              >
                                <img src={Images.refresh} />
                                <span>{t("Refresh")}</span>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <h1>{t("NoResultsFound")}</h1>
                    )}
                  </>
                )}
              </div>
              {deviceMode === StyleMode.desktop && (
                <div style={styles.leftDisplay}>
                  {_selectedPisCard && (
                    <div
                      style={{
                        ...styles.routeDetailsContainer,
                        borderInlineStart: `1px solid ${colors.black}`,
                      }}
                    >
                      <div style={styles.routeDetailsTopContainer}>
                        <div style={styles.routeDetailsHeaderContainer}>
                          <h1 style={styles.routeDetailsTitle}>
                            {t("PisRouteDetailsTitle")}
                          </h1>
                          <img
                            tabIndex={0}
                            role="button"
                            src={Images.closeBlue}
                            style={styles.routeDetailsClose}
                            onClick={() => _setSelectedPisCard(undefined)}
                          />
                        </div>
                        <hr style={{ width: "100%", color: colors.gray }} />
                      </div>
                      <PisRouteDetails
                        {..._selectedPisCard.intermediateDetails}
                        onClose={() => _setSelectedPisCard(undefined)}
                      />
                    </div>
                  )}
                </div>
              )}
              {_selectedPisCard && deviceMode !== StyleMode.desktop && (
                <div
                  style={styles.modelOverlay}
                  onClick={() => _setSelectedPisCard(undefined)}
                >
                  <div
                    style={{
                      ...styles.routeDetailsModal,
                      borderTopRightRadius: "2rem",
                      borderTopLeftRadius: "2rem",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <img
                      tabIndex={0}
                      role="button"
                      src={Images.closeButtonMob}
                      style={{
                        ...styles.routeDetailsClose,
                        position: "absolute",
                        top: "-3rem",
                        left: i18n.dir() === "rtl" ? "1rem" : "",
                        right: i18n.dir() === "ltr" ? "1rem" : "",
                      }}
                      onClick={() => _setSelectedPisCard(undefined)}
                    />
                    <div style={styles.routeDetailsTopContainer}>
                      <div
                        style={{
                          ...styles.routeDetailsHeaderContainer,
                          justifyContent: "center",
                        }}
                      >
                        <div style={styles.routeDetailsFirstStationContainer}>
                          <div
                            style={{
                              display: "flex",
                              gap: "1rem",
                              fontSize: fontSize.UmbracoElementTitle,
                            }}
                          >
                            <span>{_selectedPisCard.trainNumber}</span>
                            <img src={Images.trainInCard} />
                          </div>
                          <span
                            style={{
                              fontSize: fontSize.UmbracoElementTitle,
                              fontFamily: fonts.FbReformaMedium,
                            }}
                          >
                            {t("To")}
                            {
                              dictStations[
                                _selectedPisCard.destinationStationNumber
                              ].stationName
                            }
                          </span>
                        </div>
                      </div>
                      <hr style={{ width: "100%", color: colors.gray }} />
                    </div>
                    <PisRouteDetails
                      {..._selectedPisCard.intermediateDetails}
                      onClose={() => _setSelectedPisCard(undefined)}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    minHeight: "70vh",
    justifyContent: "center",
    gap: "2rem",
  },
  rightDisplay: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    padding: "0 3rem",
    flex: 1,
    alignItems: "center",
    marginTop: "2rem",
  },
  informationContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  refreshDataContainer: {
    display: "flex",
    justifyContent: "space-between",
    minWidth: "42rem",
  },
  refresh: {
    color: colors.cyanBold,
    fontSize: fontSize.FooterItem,
    fontFamily: fonts.FbReformaRegular,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${colors.cyanBold}`,
    gap: ".25rem",
  },
  leftDisplay: {
    minWidth: "39.9375rem",
  },
  modelOverlay: {
    position: "fixed",
    backgroundColor: colors.modal,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    zIndex: "999",
    display: "flex",
    flexDirection: "column",
  },
  routeDetailsModal: {
    position: "absolute",
    width: "100%",
    maxHeight: "80%",
    bottom: 0,
    backgroundColor: colors.white,
  },
  routeDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.white,
    boxShadow: `rgba(0, 0, 0, 0.24) 0 2px 20px 0px`,
    minWidth: "39.9375rem",
    maxWidth: "39.9375rem",
    width: "100%",
    left: 0,
    top: 0,
    height: "100%",
    marginRight: "auto",
  },
  routeDetailsTopContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "1.5rem",
    boxSizing: "border-box",
    width: "100%",
  },
  routeDetailsHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  routeDetailsClose: {
    cursor: "pointer",
  },
  routeDetailsTitle: {
    fontSize: fontSize.SubTitle,
    paddingInlineStart: "1rem",
  },
  routeDetailsFirstStationContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  routeDetailsFirstSideContainer: {
    display: "flex",
    gap: "1.5rem",
    alignItems: "center",
  },
  routeDetailsStationNameContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  searchBoxWrapper: {
    display: "flex",
    height: "4rem",
    maxWidth: "45rem",
    width: "90%",
    borderRadius: "4rem",
    border: "none",
    justifyContent: "space-around",
    alignItems: "center",
    boxShadow: "-1px 1px 33px 1px rgb(64 60 67 / 40%)",
    zIndex: "10",
    paddingInlineStart: "1.2rem",
    paddingInlineEnd: "1.2rem",
    boxSizing: "border-box",
    backgroundColor: colors.white,
    transform: "translateY(-50%)",
    cursor: "text",
  },
  clearButton: {
    cursor: "pointer",
    display: "flex",
  },
  input: {
    fontSize: fontSize.UmbracoElementTitle,
    textAlign: "center",
    fontFamily: fonts.FbReformaRegular,
    flex: 1,
  },
  stationsList: {
    width: "100%",
    maxWidth: "40rem",
  },
  notFoundContainer: {
    minWidth: `100%`,
    textAlign: "center",
    paddingTop: "25%",
    direction: "rtl",
  },
  notFoundText: {
    display: "block",
    alignSelf: "center",
    textAlign: "center",
    paddingTop: "33px",
    paddingBottom: "33px",
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.H1,
    color: colors.darkBlue,
  },
  searchIconContainer: {
    display: "flex",
    marginRight: "1rem",
  },
  mobileUpdatedTimeContainer: {
    width: "100%",
    height: "5rem",
    backgroundColor: colors.white,
    borderTopRightRadius: "1.5rem",
    borderTopLeftRadius: "1.5rem",
    position: "absolute",
    boxShadow: " 0 0 10px 0 rgba(0, 0, 0, 0.26)",
    bottom: "-1.7rem",
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    marginBottom: "1rem",
  },
};

export default Pis;
