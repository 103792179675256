import { useState } from "react";
import { IGallery } from "../../../../Interfaces/IGallery";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import { StyleMode } from "../../../../Utils/consts";
import useDeviceMode from "../../../../Utils/useWindowDimensions";
import CustomModal from "../../../CustomModal";
import ModalGallery from "../GalleryTypes/ModalGallery";
import DefaultGalleryWhatIsNew from "./DefaultGalleryWhatIsNew";
import DefaultGalleryWhatIsNewMobile from "./Mobile/DefaultGalleryWhatIsNewMobile";

const GalleryWhatIsNew: React.FC<IGallery> = (props) => {
  const { galleryType, onClick } = props;
  const [_currentItemIndex, _setCurrentItemIndex] = useState(0);

  const deviceMode = useDeviceMode();
  const GalleryDisplayedByType: React.FC = () => {
    switch (galleryType) {
      case "Default":
        if (deviceMode === StyleMode.desktop) {
          return <DefaultGalleryWhatIsNew {...props} />;
        } else {
          return <DefaultGalleryWhatIsNewMobile {...props} />;
        }
      case "Modal":
        return <ModalGallery {...props} />;
      default:
        return null;
    }
  };

  return (<CustomModal visible={true} innerStyle={{ padding: 0 }} contentStyle={{ padding: 0 }} onClose={onClick} typeStyle="rectangle">
    <div
      style={styles.galleryContainer}
    >
      <GalleryDisplayedByType />
    </div>
  </CustomModal>)

};

const styles: StyleSheet = {
  galleryContainer: {
    width: "100%",
    position: "relative",
  },
};

export default GalleryWhatIsNew;
