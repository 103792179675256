import React from "react";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import Images from "../../../Data/Images/Images";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { IUpdateCardIBackendItem } from "../../../Interfaces/IUpdates";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";

const UpdateCardBackendItem: React.FC<IUpdateCardIBackendItem> = (props) => {
  const { content, isLastItem } = props;
  return (
    <div style={styles.mainContainer}>
      <div style={styles.megaphoneIcon}></div>
      <div style={styles.updateContent}>
        <div style={styles.header}>{content.updateHeader}</div>
        <div
          style={styles.content}
          dangerouslySetInnerHTML={{ __html: content.updateContent }}
        ></div>
        <div style={styles.link}>
          <a href={content.updateLink} target={"_blank"}>
            לפרטים נוספים
          </a>
        </div>
        {!isLastItem && <div style={styles.line}></div>}
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  mainContainer: {
    display: "flex",
    gap: "2rem",
  },
  megaphoneIcon: {
    backgroundImage: `url(${Images.megaphone})`,
    backgroundRepeat: "no-repeat",
    height: "2.5rem",
    flex: "1.1",
  },
  updateContent: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    flex: "10",
  },
  header: {
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.UmbracoElementTitle,
    maxWidth: "58rem",
    color: colors.darkBlue,
  },
  content: {
    fontFamily: fonts.FbReformaLight,
    fontSize: fontSize.Paragraph,
    maxWidth: "58rem",
    color: colors.nero,
  },
  link: {
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Paragraph,
    color: colors.cyanBold,
    paddingBottom: "2rem",
  },
  line: {
    height: "0.07rem",
    width: "85%",
    backgroundColor: colors.pastelGray,
  },
};

export default UpdateCardBackendItem;
