import { useRef } from "react";
import QueryString from "qs";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import colors from "../Assets/Colors/Colors.json";
import fonts from "../Assets/Fonts/Fonts.json";
import { IRouteShared, IRouteSharedParams } from "../Interfaces/IRouteShared";
import { StyleSheet } from "../Interfaces/IStyleSheet";
import { StyleMode } from "../Utils/consts";
import useDeviceMode from "../Utils/useWindowDimensions";
import { ScheduleState } from "../Redux/Slices/scheduleSlice";
import fontSize from "../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../Services/DeviceService";
import store from "store2";

const RouteShared: React.FC<IRouteShared> = (props) => {
  const deviceMode = useDeviceMode();
  const { dynamicPart, name } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [_isButtonDisabled, _setIsButtonDisabled] = useState(true);
  const [_params, _setParams] = useState<IRouteSharedParams>();
  const [_schedule, _setSchedule] = useState<ScheduleState>();

  const getCurrentDevice = () => {
    const userAgent = navigator.userAgent;
    if (/android/i.test(userAgent)) {
      return "android";
    }
    if (/iPad|iPhone|iPod/i.test(userAgent)) {
      return "ios";
    }
    return "windows";
  };

  const [_currentDevice, _setCurrentDevice] = useState<
    "windows" | "android" | "ios"
  >(getCurrentDevice());
  const pageNameRef = useRef(name);

  useEffect(() => {
    const routeShareStorage: ScheduleState | null =
      store.session.get("routeShare");
    if (routeShareStorage !== null) {
      _setSchedule(routeShareStorage);
    }

    const urlParams = QueryString.parse(location.search.split("?")[1]);
    if (urlParams && Object.keys(urlParams).length === 6) {
      pageNameRef.current = urlParams.page as string;
      _setParams(urlParams as unknown as IRouteSharedParams);

      let year = "";
      let month = "";
      let day = "";

      if (urlParams.Date) {
        const dateString = urlParams.Date as string;
        if (dateString.includes("-")) {
          year = (urlParams.Date as string).split("-")[0];
          month = (urlParams.Date as string).split("-")[1];
          day = (urlParams.Date as string).split("-")[2];
        } else {
          year = (urlParams.Date as string).substring(0, 4);
          month = (urlParams.Date as string).substring(4, 6);
          day = (urlParams.Date as string).substring(6, 8);
        }
      }
      const date = `${year}-${month}-${day}`;
      const hours = (urlParams?.Hour as string).substr(0, 2) || "00";
      const minutes = (urlParams?.Hour as string).substr(2, 4) || "00";
      const fromStation = (urlParams?.FSID as string) || "";
      const toStation = (urlParams?.TSID as string) || "";
      const trainNumber = (urlParams?.Train as string) || "";

      store.session.set("routeShare", {
        date,
        hours,
        minutes,
        fromStation: parseInt(fromStation),
        toStation: parseInt(toStation),
        scheduleType: 1,
        trainNumber,
      });

      removeUrlParams();
    }
  }, []);

  useEffect(() => {
    if (deviceMode === StyleMode.desktop) {
      if (_schedule !== undefined) {
        redirectToPage("routePlanSearchResults");
      }
    }
  }, [_schedule]);

  const removeUrlParams = () => {
    navigate(`?page=${pageNameRef.current}`, {
      replace: true,
    });
  };

  const routeSharedTitle = dynamicPart.dynamicElements.find(
    (element) => element.textID === "routeSharedTitle"
  );
  const subTitle = dynamicPart.dynamicElements.find(
    (element) => element.textID === "subTitle"
  );
  const routePlanButton = dynamicPart.dynamicElements.find(
    (element) => element.textID === "routePlanButton"
  );

  const openExternalLink = (url: string) => {
    window.open(url, "_blank");
  };

  const redirectToPage = (page?: string) => {
    const url = `?page=${page}&fromStation=${_schedule?.fromStation}&toStation=${_schedule?.toStation}&date=${_schedule?.date}&hours=${_schedule?.hours}&minutes=${_schedule?.minutes}&scheduleType=${_schedule?.scheduleType}&trainNumber=${_schedule?.trainNumber}`;
    page && navigate(url);
    store.session.remove("routeShare");
  };

  return (
    <div
      style={{
        ...styles.mainContainer,
        paddingTop: deviceMode === StyleMode.desktop ? "3rem" : "",
      }}
    >
      <h1 style={styles.title}>{routeSharedTitle?.text}</h1>
      <h2
        style={{
          fontFamily: fonts.FbReformaRegular,
        }}
      >
        {subTitle?.text}
      </h2>
      <div style={styles.images}>
        {dynamicPart.dynamicElements.map(
          (dynamicElement, i) =>
            dynamicElement.contentTypeAlias === "dynamicImages" &&
            dynamicElement.imagesElements.map(
              (image, j) =>
                (image.imageName
                  .toLowerCase()
                  .includes(_currentDevice.toLowerCase()) ||
                  _currentDevice === "windows") && (
                  <div style={styles.imageText} key={(i + 1) * (j + 1)}>
                    <h2
                      style={{
                        fontFamily: fonts.FbReformaRegular,
                      }}
                    >
                      {image.imageName}
                    </h2>
                    <img
                      tabIndex={!IsMobile() ? 0 : -1}
                      role={"link"}
                      width="200"
                      style={styles.image}
                      src={image.image._url}
                      aria-label={image.imageName}
                      alt={``}
                      onClick={() => openExternalLink(image.link)}
                    />
                  </div>
                )
            )
        )}
      </div>
      <button
        onClick={() => redirectToPage("routePlanSearchResults")}
        style={{
          ...styles.searchRouteButton,
          cursor:
            _schedule?.fromStation === undefined ||
            _schedule?.toStation === undefined ||
            _schedule?.hours === undefined ||
            _schedule?.minutes === undefined
              ? "default"
              : "pointer",
        }}
        disabled={!_schedule}
      >
        {routePlanButton?.text}
      </button>
    </div>
  );
};

const styles: StyleSheet = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    minHeight: "100vh",
  },
  searchRouteButton: {
    backgroundColor: colors.koromiko,
    width: "24rem",
    height: "3.3rem",
    borderRadius: "24rem",
    border: "none",
    fontSize: fontSize.FooterItem,
    fontFamily: fonts.FbReformaMedium,
    boxShadow: `0 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 15px 0 rgba(0, 0, 0, 0.19)`,
    cursor: "pointer",
    alignSelf: "center",
    marginTop: "3rem",
  },
  title: {
    color: colors.darkBlue,
    fontSize: fontSize.H1,
    fontFamily: fonts.FbReformaMedium,
    lineHeight: "2.5rem",
  },
  images: {
    paddingTop: "5rem",
    display: "flex",
    justifyContent: "space-around",
    textAlign: "center",
  },
  image: {
    cursor: "pointer",
  },
  imageText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};

export default RouteShared;
