import React, { Fragment, useEffect, useRef, useState } from "react";
import { IWheelPicker } from "../../Interfaces/IWheelPicker";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import Images from "../../Data/Images/Images";
import useDeviceMode from "../../Utils/useWindowDimensions";
import { StyleMode } from "../../Utils/consts";
import { t } from "i18next";
import i18n from "../../Services/i18n";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../Services/DeviceService";

const WheelPicker: React.FC<IWheelPicker> = (props) => {
  const {
    onChange,
    data,
    defaultSelectedIndex,
    wheelId,
    isArrows = true,
    width,
    topArrowAriaLabel,
    bottomArrowAriaLabel,
    currentWheelTypeAria,
  } = props;

  const deviceMode = useDeviceMode();
  const isScrollingRef = useRef(false);
  const wheelPickerRef = useRef<HTMLUListElement>(null);
  const isWheelInitRef = useRef(false);
  const isSafariOs = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isSafariAndWebMode = isSafariOs && deviceMode === "DESKTOP";

  const [_itemHeight, _setItemHeight] = useState(37);
  let scrollTimeoutRef = useRef<any>(null);

  const [_isWheelPickerOver, _setIsWheelPickerOver] = useState(false);
  const [_isArrowUpOver, _setIsArrowUpOver] = useState(false);
  const [_isArrowDownOver, _setIsArrowDownOver] = useState(false);
  const [_isArrowDownDisabled, _setIsArrowDownDisabled] = useState(false);
  const [_isArrowUpDisabled, _setIsArrowUpDisabled] = useState(false);
  const [_isClicked, _setIsClicked] = useState(false);
  const [_currentIndex, _setCurrentIndex] = useState(-1);
  const wheelPickerItemRef = useRef<HTMLLIElement>(null);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (wheelPickerItemRef.current) {
        const height =
          wheelPickerItemRef.current.getBoundingClientRect().height;
        _setItemHeight(height);
      }
      clearTimeout(timeout);
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    handleDefaultItem();
    handleScroll();
    addAriaLabels();
  }, [_itemHeight]);

  const handleArrowsDisable = (itemId: string) => {
    _setIsArrowUpDisabled(parseInt(itemId) === 0);
    _setIsArrowDownDisabled(parseInt(itemId) === data.length - 1);
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      isWheelInitRef.current = true;
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const addAriaLabels = () => {
    if (wheelPickerRef.current) {
      const wheelPickerItems = Array.from(wheelPickerRef.current.children);

      if (currentWheelTypeAria) {
        wheelPickerItems.forEach((item) => {
          (item as HTMLElement).ariaLabel = `${t(currentWheelTypeAria, {
            time: (item as HTMLElement).innerText,
          })}`;
        });
      }
    }
  };

  const handleWheelColorsBySelectedIndex = (itemId: string) => {
    if (wheelPickerRef.current) {
      const selectedItem = document.getElementById(`${wheelId}_${itemId}`);
      if (selectedItem) {
        selectedItem.style.color = colors.black;
        selectedItem.setAttribute("aria-checked", "true");

        if (isWheelInitRef.current) {
          selectedItem.tabIndex = 0;
          selectedItem.focus();
        }

        selectedItem.style.fontFamily = fonts.FbReformaMedium;
      }

      const notSelectedItems = Array.from(
        wheelPickerRef.current.children
      ).filter((item) => item.className !== `${wheelId}_${itemId}`);
      notSelectedItems.forEach((item) => {
        (item as HTMLElement).style.color = colors.gray;
        (item as HTMLElement).tabIndex = -1;
        (item as HTMLElement).style.fontFamily = fonts.FbReformaRegular;
        (item as HTMLElement).setAttribute("aria-checked", "false");
      });
    }
  };

  const scrollToItem = (itemId: string, direction: string) => {
    const selectedItemElement = document.getElementById(`${wheelId}_${itemId}`);
    if (selectedItemElement) {
      const scrollToIndex = parseInt(itemId);
      const count =
        scrollToIndex > _currentIndex
          ? scrollToIndex - _currentIndex
          : _currentIndex - scrollToIndex;
      handleScrollMove(direction, count);

      // selectedItemElement.scrollIntoView({
      //   block: "center",
      //   behavior: "smooth",
      // });
    }
    handleArrowsDisable(itemId);
    handleWheelColorsBySelectedIndex(itemId);
  };

  const handleDefaultItem = () => {
    if (wheelPickerRef.current) {
      wheelPickerRef.current.scrollTo(
        0,
        parseInt(defaultSelectedIndex) * _itemHeight
      );
    }
    handleArrowsDisable(defaultSelectedIndex);
    handleWheelColorsBySelectedIndex(defaultSelectedIndex);
  };

  const handleScroll = (e?: React.UIEvent<HTMLUListElement, UIEvent>) => {
    e?.preventDefault();
    e?.stopPropagation();
    clearTimeout(scrollTimeoutRef.current);

    if (wheelPickerRef.current) {
      const currentIndex = Math.ceil(
        Math.round(wheelPickerRef.current.scrollTop / _itemHeight)
      );
      handleWheelColorsBySelectedIndex(currentIndex.toString());
    }

    if (!scrollTimeoutRef.current) {
      isScrollingRef.current = true;
    }
    scrollTimeoutRef.current = setTimeout(() => {
      isScrollingRef.current = false;
      // clearTimeout(scrollTimeoutRef.current);
      if (wheelPickerRef.current) {
        wheelPickerRef.current.scrollTo(
          0,
          Math.ceil(
            Math.round(wheelPickerRef.current.scrollTop / _itemHeight) *
              _itemHeight
          )
        );

        const currentIndex = Math.ceil(
          Math.round(wheelPickerRef.current.scrollTop / _itemHeight)
        );

        _setCurrentIndex(currentIndex);

        onChange({
          value: data[currentIndex].value,
          id: data[currentIndex].id,
        });
        handleArrowsDisable(currentIndex.toString());
        handleWheelColorsBySelectedIndex(currentIndex.toString());
      }
    }, 250);
  };

  const handleArrowNavigation = (direction: string) => {
    if (wheelPickerRef.current) {
      const wheelItems = [].slice.call(wheelPickerRef.current.children);
      const currentIndex = Math.round(
        wheelPickerRef.current.scrollTop / _itemHeight
      );
      const scrollCount =
        (direction === "up" ? currentIndex - 1 : currentIndex + 1) *
          _itemHeight +
        currentIndex / wheelItems.length +
        2;
      wheelPickerRef.current.scroll({ top: scrollCount, behavior: "smooth" });
    }
  };

  const handleScrollMove = (direction: string, count: number) => {
    if (wheelPickerRef.current) {
      const wheelItems = [].slice.call(wheelPickerRef.current.children);
      const currentIndex = Math.round(
        wheelPickerRef.current.scrollTop / _itemHeight
      );
      const scrollCount =
        (direction === "up" ? currentIndex - count : currentIndex + count) *
          _itemHeight +
        currentIndex / wheelItems.length +
        2;
      wheelPickerRef.current.scroll({ top: scrollCount, behavior: "smooth" });
    }
  };

  const getCurrentIndex = () => {
    if (wheelPickerRef.current) {
      const currentIndex = Math.round(
        wheelPickerRef.current.scrollTop / _itemHeight
      );
      return currentIndex;
    }
    return -1;
  };

  return (
    <Fragment>
      <div
        id="wheelPickerId"
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          justifyContent: "space-between",
        }}
      >
        <div style={styles.arrowContainer}>
          {isArrows && (
            <>
              <img
                tabIndex={!_isArrowUpDisabled ? 0 : -1}
                src={Images.arrowUp}
                onMouseOver={() => _setIsArrowUpOver(true)}
                onMouseLeave={() => _setIsArrowUpOver(false)}
                style={{
                  ...styles.arrow,
                  background: _isArrowUpOver ? colors.lighterGray : "",
                  borderRadius: "50%",
                }}
                onClick={() => handleArrowNavigation("up")}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    handleArrowNavigation("up");
                  }
                }}
                width={"50"}
                height={`auto`}
                alt={``}
                role={"button"}
                aria-label={`${t("WheelPickerTopArrow", {
                  time: parseInt(
                    data[
                      getCurrentIndex() > 0 ? getCurrentIndex() - 1 : 0
                    ].value.toString()
                  ),
                  wheelName:
                    currentWheelTypeAria && currentWheelTypeAria === "Hour"
                      ? t("HourText")
                      : t("MinutesText"),
                })}`}
              />
              {_isArrowUpDisabled && <div style={styles.arrowDisabled}></div>}
            </>
          )}
          <div
            style={{
              ...styles.topWheelGradient,
              background: !isSafari
                ? "linear-gradient(to top, #ffffffc4 50%, transparent 100%)"
                : "-webkit-linear-gradient(to top, #ffffffc4 50%, transparent 100%)",
              backgroundImage: !isSafari
                ? "linear-gradient(to top, #ffffffc4 50%, transparent 100%)"
                : "-webkit-linear-gradient(to top, #ffffffc4 50%, transparent 100%)",
              height: deviceMode !== StyleMode.desktop ? "160%" : "50%",
            }}
            onClick={() => handleArrowNavigation("up")}
          ></div>
        </div>
        <ul
          onMouseOver={() => _setIsWheelPickerOver(true)}
          onMouseLeave={() => _setIsWheelPickerOver(false)}
          ref={wheelPickerRef}
          id={wheelId}
          className="wheelPicker"
          style={{
            ...styles.wheelPicker,
            width: width
              ? `${width}rem`
              : !isSafariAndWebMode
              ? "5.2rem"
              : "6rem",
            background: _isWheelPickerOver
              ? "-webkit-linear-gradient(top, rgba(255,255,255,0) 0%,#f1f1f1 50%,rgba(125,185,232,0) 100%)"
              : "",
            marginBlockStart: "1em",
            marginBlockEnd: "1em",
            maxHeight: i18n.language !== "ru" ? "15rem" : "14rem",
          }}
          onScroll={handleScroll}
        >
          {data.map((item, index) => (
            <li
              ref={wheelPickerItemRef}
              role={"radio"}
              id={`${wheelId}_${index}`}
              className={`${wheelId}_${index}`}
              key={item.id}
              style={{ ...styles.item }}
              onClick={() => {
                const scrollToIndex = parseInt(item.id);
                const diff = scrollToIndex > _currentIndex ? "down" : "up";

                scrollToItem(item.id, diff);
              }}
              tabIndex={!IsMobile() ? 0 : -1}
            >
              {item.value}
            </li>
          ))}
          <img
            src={
              data[
                getCurrentIndex() < data.length - 1
                  ? getCurrentIndex() + 1
                  : data.length - 1
              ].value.toString().length === 2
                ? Images.wheelSelected
                : Images.wheelLongSelected
            }
            style={{
              ...styles.wheelLine,
              width:
                data[
                  getCurrentIndex() < data.length - 1
                    ? getCurrentIndex() + 1
                    : data.length - 1
                ].value.toString().length === 2
                  ? ""
                  : i18n.language === "ru"
                  ? "50%"
                  : "100%",
              top:
                data[
                  getCurrentIndex() < data.length - 1
                    ? getCurrentIndex() + 1
                    : data.length - 1
                ].value.toString().length === 2
                  ? i18n.language !== "ru"
                    ? "calc(50% + 1.1rem)"
                    : "calc(50% + 1.5rem)"
                  : i18n.language !== "ru"
                  ? "calc(50% + 1.1rem)"
                  : "calc(50% + 1.5rem)",
            }}
            width={`auto`}
            height={`auto`}
            alt={``}
          />
        </ul>
        <div style={styles.arrowContainer}>
          {isArrows && (
            <>
              <img
                tabIndex={!_isArrowDownDisabled ? 0 : -1}
                src={Images.arrowDown}
                onMouseOver={() => _setIsArrowDownOver(true)}
                onMouseLeave={() => _setIsArrowDownOver(false)}
                style={{
                  ...styles.arrow,
                  background: _isArrowDownOver ? colors.lighterGray : "",
                  borderRadius: "50%",
                }}
                width={"50"}
                onClick={() => handleArrowNavigation("down")}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    handleArrowNavigation("down");
                  }
                }}
                height={`auto`}
                alt={``}
                role={"button"}
                aria-label={`${t("WheelPickerBottomArrow", {
                  time: parseInt(
                    data[
                      getCurrentIndex() < data.length - 1
                        ? getCurrentIndex() + 1
                        : data.length - 1
                    ].value.toString()
                  ),
                  wheelName:
                    currentWheelTypeAria && currentWheelTypeAria === "Minute"
                      ? t("MinutesText")
                      : t("HourText"),
                })}`}
              />
              {_isArrowDownDisabled && <div style={styles.arrowDisabled}></div>}
            </>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const styles: StyleSheet = {
  wheelPicker: {
    listStyleImage: "none",
    maxHeight: "14rem",
    overflowY: "scroll",
    overflowX: "hidden",
    scrollbarWidth: "none",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",
    paddingTop: "6.5rem",
    paddingBottom: "6rem",
    boxSizing: "border-box",
    WebkitMaskImage: "linear-gradient(to bottom, black 90%, transparent 100%)",
    paddingInlineStart: "0",
  },
  arrowContainer: {
    position: "relative",
    alignSelf: "center",
    minWidth: "50px",
    minHeight: "59px",
  },
  arrow: {
    cursor: "pointer",
  },
  arrowDisabled: {
    position: "absolute",
    width: "100%",
    height: "100%",
    background: colors.whiteTransparent,
    top: "0",
  },
  item: {
    fontSize: fontSize.H1,
    fontFamily: fonts.FbReformaRegular,
    lineHeight: "2.5rem",
  },
  wheelLine: {
    position: "absolute",
    top: "calc(50% + 1.5rem)",
  },
  topWheelGradient: {
    position: "absolute",
    width: "100%",
    zIndex: "10",
    cursor: "pointer",
  },
};

export default WheelPicker;
