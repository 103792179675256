import { IVideo } from "../../Interfaces/IVideo";
import { IsMobile } from "../../Services/DeviceService";

const Video: React.FC<IVideo> = (props) => {
  const { header, _url, autoPlay = true } = props;
  return (
    <video
      width="100%"
      height="100%"
      controls
      autoPlay={autoPlay}
      aria-label={header}
      tabIndex={!IsMobile() ? 0 : -1}
    >
      <source src={_url} type="video/mp4"></source>
    </video>
  );
};

export default Video;
