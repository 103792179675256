import { IImageAndLink } from "../../Interfaces/IUmbraco";
import { Translations } from "../../Services/GlobalService";
import i18n from "../../Services/i18n";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";
interface IPaymentOptions {
  paymentOptions?: IImageAndLink[],
  chargingOptions?:IImageAndLink[]
}
const PaymentOptions: React.FC<IPaymentOptions> = (props) => {
  const deviceMode = useDeviceMode();

  const Translation = Translations[
    i18n.language as `he` | `en` | `ar` | `ru`
  ] as any;
  return (
    <div
      className="row-around"
      style={{
        flexDirection: deviceMode === StyleMode.mobile  ? "column": "row",
        gap: "2rem",
        paddingTop:"0.5rem",
        alignItems:"center"
      }}
    >
         <div
        style={{
          textAlign: "center",
          gap: "1rem",
          display: "flex",
          flexDirection: "column",
          maxWidth:"25rem"
        }}
      >
        <p style={{fontWeight:"500"}}>{Translation["PaymentTravel"]}</p>

        <div className="row" style={{ gap: "2rem", justifyContent: "center",flexWrap:"wrap" }}>
          {props?.paymentOptions?.map((item,index) => 
          <a href={item.link.redirectTo} key={index}>
            <img src={item.image._url} alt={item.imageAlt} style={{ width: "4rem" }} />
          </a>
          )}
         
        </div>
      </div>
      <div
        style={{
          textAlign: "center",
          gap: "1rem",
          display: "flex",
          flexDirection: "column",
          maxWidth:"15rem"
        }}
      >
        <p style={{fontWeight:"500"}}>{Translation["PriceRavKavChrge"]}</p>
        <div className="row" style={{ gap: "2rem", justifyContent: "center",flexWrap:"wrap" }}>
        {props?.chargingOptions?.map((item,index) => 
          <a href={item.link.redirectTo} key={index}>
            <img src={item.image._url} alt={item.imageAlt} style={{ width: "4rem" }} />
          </a>
          )}
         
        </div>
      </div>
   
    </div>
  );
};
export default PaymentOptions;
