import React from "react";
import { useNavigate } from "react-router-dom";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import Images from "../../../Data/Images/Images";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { IUpdateCardItem } from "../../../Interfaces/IUpdates";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import { t } from "i18next";
import i18n from "../../../Services/i18n";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../Services/DeviceService";

const UpdateCardItem: React.FC<IUpdateCardItem> = (props) => {
  const { content, isLastItem, clickAction, updatesNumber, index } = props;
  const navigate = useNavigate();
  const deviceMode = useDeviceMode();

  const redirectToPage = (page?: string) => {
    page && navigate(`?page=updates/${page}`);
  };

  return (
    <div
      style={{
        ...styles.mainContainer,
        flexDirection: deviceMode === StyleMode.desktop ? "row" : "column",
      }}
    >
      <div style={styles.megaphoneIconCon}>
        <img src={Images.megaphone} style={{...styles.megaphoneIcon ,transform: i18n.dir() === "ltr" ? "scaleX(-1)" : ""}}></img>
      </div>
      <div
        id="pageMainContent"
        tabIndex={!IsMobile() ? 0 : -1}
        style={{
          ...styles.updateContent,
          alignItems: deviceMode !== StyleMode.desktop ? "center" : "",
        }}
      >
        <h2 style={styles.header}>{content.header}</h2>
        <div
          style={styles.content}
          aria-label={
            `${t(`UpdateNumberOutOfNumber`, {
              currentIndex: `${index}`,
              total: `${updatesNumber}`,
            })}` + `${content.contentdata}`
          }
        >
          {content.contentdata}
        </div>
        <div style={styles.link}>
          {content.linkText && (
            <span
              style={{
                cursor: "pointer",
              }}
              onClick={clickAction}
              tabIndex={!IsMobile() ? 0 : -1}
              role={`link`}
            >
              {content.linkText}{">"}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  } as React.CSSProperties,


  megaphoneIconCon: {
    display: "flex",
    justifyContent: "center",
    flex: "2",
  } as React.CSSProperties,

  updateContent: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    flex: "10",
  },
  header: {
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.UmbracoElementTitle,
    maxWidth: "calc(75% - 2rem)",
    color: colors.darkBlue,
    marginBlockStart: "0",
    marginBlockEnd: "0",
  },
  content: {
    fontFamily: fonts.FbReformaLight,
    fontSize: fontSize.Paragraph,
    maxWidth: "calc(90% - 4rem)",
    color: colors.nero,
  },
  link: {
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Paragraph,
    color: colors.cyanBold,
    paddingBottom: "2rem",
  },
};

export default UpdateCardItem;
