import { equal } from "assert"
import { GetSystemParams } from "../Services/GlobalService"
import { checkStationsRedirect } from "./stationsInfoRedirect"


const Urls:{[key:string]:string} = {
   "/about/pages/default.aspx":"/?page=AboutIsraelTrain&lan=he",
   "/about/pages/directorate.aspx":"/?page=Directorate&lan=he",
   "/about/pages/management.aspx":"/?page=DirectorateMembers&lan=he",
   "/about/pages/eticcode.aspx":"/?page=eticcode&lan=he",
   "/about/pages/structure.aspx":"/?page=structure&lan=he",
   "/about/pages/development-plan.aspx":"/?page=eticcode&lan=he",
   "/about/pages/report.aspx":"/?page=report&lan=he",
   "/about/pages/internal-audit.aspx":"/?page=internal-audit&lan=he",
   "/about/innovation/pages/innovations.aspx":"/?page=innovations&lan=he",
  "/about/pages/ceo.aspx":"/?page=ISO&lan=he",
  "/about/pages/safety.aspx":"/?page=safety&lan=he",
  "/about/pages/history.aspx":"/?page=StationInTime&lan=he",
  "/about/pages/railway-fleet.aspx":"/?page=railway-fleet&lan=he",
  "/pages/accessibility-dec.aspx":"/?page=accessibility-dec&lan=he",
  "/pages/sitepolicy.aspx":"/?page=sitepolicy&lan=he",
  "/about/innovation/pages/information_voice_call.aspx":"/?page=information_voice_call&lan=he",
  "/about/innovation/pages/general_highlights.aspx":"/?page=general_highlights&lan=he",
  "/about/innovation/pages/frequently_asked_questions_(faq).aspx":"/?page=frequently_asked_questions_(faq)&lan=he",
  "/about/pages/diesel-locomotives.aspx":"/?page=diesel-locomotives&lan=he",
  "/about/pages/electric-locomotives.aspx":"/?page=electric-locomotives&lan=he",
  "/about/pages/passenger-cars.aspx":"/?page=passenger-cars&lan=he",
  "/about/pages/trailers.aspx":"/?page=trailers&lan=he",
  "/about/pages/freight-cars.aspx":"/?page=freight-cars&lan=he",
  "/support/pages/contact-us.aspx":"/?page=contact-us&lan=he",
  "/support/pages/infolaw.aspx":"/?page=infolaw&lan=he",
  "/support/pages/forms.aspx":"/?page=refund&lan=he",
  "/support/pages/train-accessibility.aspx":"/?page=trainAccessibility&lan=he",
  "/support/pages/bicycles.aspx":"/?page=bicycles&lan=he",
  "/support/pages/increasedpayment.aspx":"/?page=increasedpayment&lan=he",
  "/support/pages/delay.aspx":"/?page=delay&lan=he",
  "/support/pages/railway-closure-compansation.aspx":"/?page=railway-closure-compansation&lan=he",
  "/support/pages/reserved-seating-compansation.aspx":"/?page=reserved-seating-compansation&lan=he",
  "/support/pages/kalnoit.aspx":"/?page=kalnoit&lan=he",
  "/pages/safety-travel.aspx":"/?page=safety-travel&lan=he",
  "/pages/madad_diuk.aspx":"/?page=AccurecyIndex&lan=he",
  "/stationnlines":"/?page=linesmap&lan=he",
  "/tenders/pages/opentenders.aspx":"?page=generalAuctions&step=openauctions",
  "/cargotenders/pages/cargotender.aspx":"/?page=CargoAuctions&lan=he&step=openauctions",
  "/enjoytheroad/pages/stationbookslibrary.aspx":"/?page=stationbookslibrary&lan=he",
  "/enjoytheroad/pages/menagnim.aspx":"/?page=menagnim&lan=he",
  "/enjoytheroad/pages/stand-car.aspx":"/?page=stand-car&lan=he",
  "/enjoytheroad/pages/museum.aspx":"?page=museum&lan=he",
  "/enjoytheroad/pages/art-station-2022.aspx":"/?page=art-station-2022&lan=he",
  "/enjoytheroad/pages/creative-activities-for-children.aspx":"?page=creative-activities-for-children&lan=he",
  "/cargotenders/pages/cargo.aspx":"?page=cargo&lan=he",
  "/development/pages/railway-magazine.aspx":"/?page=railway-magazine&lan=he",
  "/development/pages/statutory_plan.aspx":"/?page=statutory_plan&lan=he",
  "/development/pages/magazine-articles-2022/electrification-project.aspx":"/?page=electrification-project&lan=he",
  "/development/pages/magazine-articles-2022/eastern-railroad-project.aspx":"/?page=eastern-railroad-project&lan=he",
  "/development/pages/magazine-articles-2022/modiin-arc.aspx":"/?page=modiin-arc&lan=he",
  "/development/pages/magazine-articles-2022/431-project.aspx":"/?page=431-project&lan=he",
  "/development/pages/magazine-articles-2022/etcs-upgrade.aspx":"/?page=etcs-upgrade&lan=he",
  "/pages/infrastructure.aspx":"/?page=infrastructure&lan=he",
  "/taarif/pages/travel-arrangements.aspx":"/?page=taarif&lan=he",
  "/about/career":"/?page=career&lan=he",
  "/development/pages/newplanninginstructions.aspx":"/?page=guidelinesAndRates&lan=he",
  "/taarif":"/?page=travel-arrangements",
  "/tickets":"/?page=travel-arrangements",
  "/updates":"/?page=updates",
  "/support":"/?page=contact-us",
  "/support/pages/qanda-customer-support.aspx":"/?page=contact-us",
  "/about/career/pages/jobs.aspx":"/?page=career",
  "/tickets/pages/students.aspx":"/?page=students",
  "/tenders":"?page=generalAuctions&step=openauctions",
  "/about/career/pages/searchpositions.aspx":"/?page=career",
  "/pages/stations-lines.aspx":"/?page=linesmap",
  "/about":"/?page=AboutIsraelTrain",
  "/enjoytheroad/pages/railway-podcast.aspx":"/?page=podcasts",
  "/development":"/?page=railway-magazine",
  "/tenders/pages/open.aspx":"/?page=generalAuctions&step=openauctions",
  "/cargotenders":"/?page=cargo",
  "/development/pages/technical-2.aspx":"/?page=technical-2",
  "/about/safety/pages/stationsafetyrules.aspx":"/?page=safety",
  "/stations/ben-gurion-airport?tabname=tabParking":"/?page=stationinfo&stationname=8600",
  "/tenders/pages/closed.aspx":"https://trail.rakevet.net/?page=generalAuctions&step=openauctions",
  "/support/pages/accessibility.aspx":"/?page=trainAccessibility",
  "/taarif/pages/compensationrequest.aspx":"/?page=delay",
  "/development/pages/planninginstructions.aspx":"/?page=guidelinesAndRates",
  "/support/pages/surf.aspx":"/?page=surf",
  "/en":"/?lan=en",
  "/en/about/pages/default.aspx":"/?page=AboutIsraelTrain&lan=en",
  "/en/about/pages/directorate.aspx":"/?page=Directorate&lan=en",
  "/en/about/pages/management.aspx":"/?page=DirectorateMembers&lan=en",
  "/en/about/pages/structure.aspx":"/?page=structure&lan=en",
  "/en/about/pages/eticcode.aspx":"/?page=eticcode&lan=en",
  "/en/about/pages/development-plan.aspx":"/?page=development-plan&lan=en",
  "/en/about/pages/report.aspx":"/?page=report&lan=en",
  "/en/about/pages/internal-audit.aspx":"/?page=internal-audit&lan=en",
  "/en/about/innovation/pages/innovations.asp":"/?page=innovations&lan=en",
  "/en/about/pages/ceo.aspx":"/?page=ISO&lan=en",
  "/en/about/pages/safety.aspx":"/?page=safety&lan=en",
  "/en/about/pages/history.aspx":"/?page=StationInTime&lan=en",
  "/en/about/pages/railway-fleet.aspx":"/?page=railway-fleet&lan=en",
  "/en/pages/accessibility-dec.aspx":"/?page=accessibility-dec&lan=en",
  "/en/pages/sitepolicy.aspx":"/?page=sitepolicy&lan=en",
  "/en/about/innovation/pages/information_voice_call.aspx":"/?page=information_voice_call&lan=en",
  "/en/about/innovation/pages/general_highlights.aspx":"/?page=general_highlights&lan=en",
  "/en/about/innovation/pages/frequently_asked_questions_(faq).aspx":"/?page=frequently_asked_questions_(faq)&lan=en",
  "/en/about/pages/diesel-locomotives.aspx":"/?page=diesel-locomotives&lan=en",
  "/en/about/pages/electric-locomotives.aspx":"/?page=electric-locomotives&lan=en",
  "/en/about/pages/passenger-cars.aspx":"/?page=passenger-cars&lan=en",
  "/en/about/pages/trailers.aspx":"/?page=trailers&lan=en",
  "/en/about/pages/freight-cars.aspx":"/?page=freight-cars&lan=en",
  "/en/support/pages/contact-us.aspx":"/?page=contact-us&lan=en",
  "/en/support/pages/infolaw.aspx":"/?page=infolaw&lan=en",
  "/en/support/pages/forms.aspx":"/?page=refund&lan=en",
  "/en/support/pages/train-accessibility.aspx":"/?page=trainAccessibility&lan=en",
  "/en/support/pages/bicycles.aspx":"/?page=bicycles&lan=en",
  "/en/support/pages/increasedpayment.aspx":"/?page=increasedpayment&lan=en",
  "/en/support/pages/delay.aspx":"/?page=delay&lan=en",
  "/en/support/pages/railway-closure-compansation.aspx":"/?page=railway-closure-compansation&lan=en",
  "/en/support/pages/reserved-seating-compansation.aspx":"/?page=reserved-seating-compansation&lan=en",
  "/en/support/pages/kalnoit.aspx":"/?page=kalnoit&lan=en",
  "/en/pages/safety-travel.aspx":"/?page=safety-travel&lan=en",
  "/en/pages/madad_diuk.aspx":"/?page=AccurecyIndex&lan=en",
  "/en/stationnlines":"/?page=linesmap&lan=en",
  "/en/tenders/pages/opentenders.aspx":"/?page=GeneralAuctions&lan=en",
  "/en/cargotenders/pages/cargotender.aspx":"/?page=CargoAuctions&lan=en",
  "/en/enjoytheroad/pages/stationbookslibrary.aspx":"/?page=stationbookslibrary&lan=en",
  "/en/enjoytheroad/pages/menagnim.aspx":"/?page=menagnim&lan=en",
  "/en/enjoytheroad/pages/stand-car.aspx":"/?page=stand-car&lan=en",
  "/en/enjoytheroad/pages/museum.aspx":"/?page=museum&lan=en",
  "/en/enjoytheroad/pages/art-station-2022.aspx":"/?page=art-station-2022&lan=en",
  "/en/enjoytheroad/pages/creative-activities-for-children.aspx":"/?page=creative-activities-for-children&lan=en",
  "/en/cargotenders/pages/cargo.aspx":"/?page=cargo&lan=en",
  "/en/development/pages/railway-magazine.aspx":"/?page=railway-magazine&lan=en",
  "/en/development/pages/statutory_plan.aspx":"/?page=statutory_plan&lan=en",
  "/en/development/pages/magazine-articles-2022/electrification-project.aspx":"/?page=electrification-project&lan=en",
  "/en/development/pages/magazine-articles-2022/eastern-railroad-project.aspx":"/?page=eastern-railroad-project&lan=en",
  "/en/development/pages/magazine-articles-2022/modiin-arc.aspx":"/?page=modiin-arc&lan=en",
  "/en/development/pages/magazine-articles-2022/431-project.aspx":"/?page=431-project&lan=en",
  "/en/development/pages/magazine-articles-2022/etcs-upgrade.aspx":"/?page=etcs-upgrade&lan=en",
  "/en/pages/infrastructure.aspx":"/?page=infrastructure&lan=en",
  "/en/taarif/pages/travel-arrangements.aspx":"/?page=taarif&lan=en",
  "/en/about/career":"/?page=career&lan=en",
  "/en/development/pages/newplanninginstructions.aspx":"/?page=guidelinesAndRates&lan=en",
  "/ar":"/?lan=ar",
  "/ar/about/pages/default.aspx":"/?page=AboutIsraelTrain&lan=ar",
  "/ar/about/pages/directorate.aspx":"/?page=Directorate&lan=ar",
  "/ar/about/pages/management.aspx":"/?page=DirectorateMembers&lan=ar",
  "/ar/about/pages/structure.aspx":"/?page=structure&lan=ar",
  "/ar/about/pages/eticcode.aspx":"/?page=eticcode&lan=ar",
  "/ar/about/pages/development-plan.aspx":"/?page=development-plan&lan=ar",
  "/ar/about/pages/report.aspx":"/?page=report&lan=ar",
  "/ar/about/pages/internal-audit.aspx":"/?page=internal-audit&lan=ar",
  "/ar/about/innovation/pages/innovations.aspx":"/?page=innovations&lan=ar",
  "/ar/about/pages/ceo.aspx":"/?page=ISO&lan=ar",
  "/ar/about/pages/safety.aspx":"/?page=safety&lan=ar",
  "/ar/about/pages/history.aspx":"/?page=StationInTime&lan=ar",
  "/ar/about/pages/railway-fleet.aspx":"/?page=railway-fleet&lan=ar",
  "/ar/pages/accessibility-dec.aspx":"/?page=accessibility-dec&lan=ar",
  "/ar/pages/sitepolicy.aspx":"/?page=sitepolicy&lan=ar",
  "/ar/about/innovation/pages/information_voice_call.aspx":"/?page=information_voice_call&lan=ar",
  "/ar/about/innovation/pages/general_highlights.aspx":"/?page=general_highlights&lan=ar",
  "/ar/about/innovation/pages/frequently_asked_questions_(faq).aspx":"/?page=frequently_asked_questions_(faq)&lan=ar",
  "/ar/about/pages/diesel-locomotives.aspx":"/?page=diesel-locomotives&lan=ar",
  "/ar/about/pages/electric-locomotives.aspx":"/?page=electric-locomotives&lan=ar",
  "/ar/about/pages/passenger-cars.aspx":"/?page=passenger-cars&lan=ar",
  "/ar/about/pages/trailers.aspx":"/?page=trailers&lan=ar",
  "/ar/about/pages/freight-cars.aspx":"/?page=freight-cars&lan=ar",
  "/ar/support/pages/contact-us.aspx":"/?page=contact-us&lan=ar",
  "/ar/support/pages/infolaw.aspx":"/?page=infolaw&lan=ar",
  "/ar/support/pages/forms.aspx":"/?page=refund&lan=ar",
  "/ar/support/pages/train-accessibility.aspx":"/?page=trainAccessibility&lan=ar",
  "/ar/support/pages/bicycles.aspx":"/?page=bicycles&lan=ar",
  "/ar/support/pages/increasedpayment.aspx":"/?page=increasedpayment&lan=ar",
  "/ar/support/pages/delay.aspx":"/?page=delay&lan=ar",
  "/ar/support/pages/railway-closure-compansation.aspx":"/?page=railway-closure-compansation&lan=ar",
  "/ar/support/pages/reserved-seating-compansation.aspx":"/?page=reserved-seating-compansation&lan=ar",
  "/ar/support/pages/kalnoit.aspx":"/?page=kalnoit&lan=ar",
  "/ar/pages/safety-travel.aspx":"/?page=safety-travel&lan=ar",
  "/ar/pages/madad_diuk.aspx":"/?page=AccurecyIndex&lan=ar",
  "/ar/stationnlines":"/?page=linesmap&lan=ar",
  "/ar/tenders/pages/opentenders.aspx":"/?page=GeneralAuctions&lan=ar",
  "/ar/cargotenders/pages/cargotender.aspx":"/?page=CargoAuctions&lan=ar",
  "/ar/enjoytheroad/pages/stationbookslibrary.aspx":"/?page=stationbookslibrary&lan=ar",
  "/ar/enjoytheroad/pages/menagnim.aspx":"/?page=menagnim&lan=ar",
  "/ar/enjoytheroad/pages/stand-car.aspx":"/?page=stand-car&lan=ar",
  "/ar/enjoytheroad/pages/museum.aspx":"/?page=museum&lan=ar",
  "/ar/enjoytheroad/pages/art-station-2022.aspx":"/?page=art-station-2022&lan=ar",
  "/ar/enjoytheroad/pages/creative-activities-for-children.aspx":"/?page=creative-activities-for-children&lan=ar",
  "/ar/cargotenders/pages/cargo.aspx":"/?page=cargo&lan=ar",
  "/ar/development/pages/railway-magazine.aspx":"/?page=railway-magazine&lan=ar",
  "/ar/development/pages/statutory_plan.aspx":"/?page=statutory_plan&lan=ar",
  "/ar/development/pages/magazine-articles-2022/electrification-project.aspx":"/?page=electrification-project&lan=ar",
  "/ar/development/pages/magazine-articles-2022/eastern-railroad-project.aspx":"/?page=eastern-railroad-project&lan=ar",
  "/ar/development/pages/magazine-articles-2022/modiin-arc.aspx":"/?page=modiin-arc&lan=ar",
  "/ar/development/pages/magazine-articles-2022/431-project.aspx":"/?page=431-project&lan=ar",
  "/ar/development/pages/magazine-articles-2022/etcs-upgrade.aspx":"/?page=etcs-upgrade&lan=ar",
  "/ar/pages/infrastructure.aspx":"/?page=infrastructure&lan=ar",
  "/ar/taarif/pages/travel-arrangements.aspx":"/?page=taarif&lan=ar",
  "/ar/about/career":"/?page=career&lan=ar",
  "/ar/development/pages/newplanninginstructions.aspx":"/?page=guidelinesAndRates&lan=ar",
  "/ru":"/?lan=ru",
  "/ru/about/pages/default.aspx":"/?page=AboutIsraelTrain&lan=ru",
  "/ru/about/pages/directorate.aspx":"/?page=Directorate&lan=ru",
  "/ru/about/pages/management.aspx":"/?page=DirectorateMembers&lan=ru",
  "/ru/about/pages/structure.aspx":"/?page=structure&lan=ru",
  "/ru/about/pages/eticcode.aspx":"/?page=eticcode&lan=ru",
  "/ru/about/pages/development-plan.aspx":"/?page=development-plan&lan=ru",
  "/ru/about/pages/report.aspx":"/?page=report&lan=ru",
  "/ru/about/pages/internal-audit.aspx":"/?page=internal-audit&lan=ru",
  "/ru/about/innovation/pages/innovations.aspx":"/?page=innovations&lan=ru",
  "/ru/about/pages/ceo.aspx":"/?page=ISO&lan=ru",
  "/ru/about/pages/safety.aspx":"/?page=safety&lan=ru",
  "/ru/about/pages/history.aspx":"/?page=StationInTime&lan=ru",
  "/ru/about/pages/railway-fleet.aspx":"/?page=railway-fleet&lan=ru",
  "/ru/pages/accessibility-dec.aspx":"/?page=accessibility-dec&lan=ru",
  "/ru/pages/sitepolicy.aspx":"/?page=sitepolicy&lan=ru",
  "/ru/about/innovation/pages/information_voice_call.aspx":"/?page=information_voice_call&lan=ru",
  "/ru/about/innovation/pages/general_highlights.aspx":"/?page=general_highlights&lan=ru",
  "/ru/about/innovation/pages/frequently_asked_questions_(faq).aspx":"/?page=frequently_asked_questions_(faq)&lan=ru",
  "/ru/about/pages/diesel-locomotives.aspx":"/?page=diesel-locomotives&lan=ru",
  "/ru/about/pages/electric-locomotives.aspx":"/?page=electric-locomotives&lan=ru",
  "/ru/about/pages/passenger-cars.aspx":"/?page=passenger-cars&lan=ru",
  "/ru/about/pages/trailers.aspx":"/?page=trailers&lan=ru",
  "/ru/about/pages/freight-cars.aspx":"/?page=freight-cars&lan=ru",
  "/ru/support/pages/contact-us.aspx":"/?page=contact-us&lan=ru",
  "/ru/support/pages/infolaw.aspx":"/?page=infolaw&lan=ru",
  "/ru/support/pages/forms.aspx":"/?page=refund&lan=ru",
  "/ru/support/pages/train-accessibility.aspx":"/?page=trainAccessibility&lan=ru",
  "/ru/support/pages/bicycles.aspx":"/?page=bicycles&lan=ru",
  "/ru/support/pages/increasedpayment.aspx":"/?page=increasedpayment&lan=ru",
  "/ru/support/pages/delay.aspx":"/?page=delay&lan=ru",
  "/ru/support/pages/railway-closure-compansation.aspx":"/?page=railway-closure-compansation&lan=ru",
  "/ru/support/pages/reserved-seating-compansation.aspx":"/?page=reserved-seating-compansation&lan=ru",
  "/ru/support/pages/kalnoit.aspx":"/?page=kalnoit&lan=ru",
  "/ru/pages/safety-travel.aspx":"/?page=safety-travel&lan=ru",
  "/ru/pages/madad_diuk.aspx":"/?page=AccurecyIndex&lan=ru",
  "/ru/stationnlines":"/?page=linesmap&lan=ru",
  "/ru/tenders/pages/opentenders.aspx":"/?page=GeneralAuctions&lan=ru",
  "/ru/cargotenders/pages/cargotender.aspx":"/?page=CargoAuctions&lan=ru",
  "/ru/enjoytheroad/pages/stationbookslibrary.aspx":"/?page=stationbookslibrary&lan=ru",
  "/ru/enjoytheroad/pages/menagnim.aspx":"/?page=menagnim&lan=ru",
  "/ru/enjoytheroad/pages/stand-car.aspx":"/?page=stand-car&lan=ru",
  "/ru/enjoytheroad/pages/museum.aspx":"/?page=museum&lan=ru",
  "/ru/enjoytheroad/pages/art-station-2022.aspx":"/?page=art-station-2022&lan=ru",
  "/ru/enjoytheroad/pages/creative-activities-for-children.aspx":"/?page=creative-activities-for-children&lan=ru",
  "/ru/cargotenders/pages/cargo.aspx":"/?page=cargo&lan=ru",
  "/ru/development/pages/railway-magazine.aspx":"/?page=railway-magazine&lan=ru",
  "/ru/development/pages/statutory_plan.aspx":"/?page=statutory_plan&lan=ru",
  "/ru/development/pages/newplanninginstructions.aspx":"/?page=guidelinesAndRates&lan=ru",
  "/ru/development/pages/magazine-articles-2022/electrification-project.aspx":"/?page=electrification-project&lan=ru",
  "/ru/development/pages/magazine-articles-2022/eastern-railroad-project.aspx":"/?page=eastern-railroad-project&lan=ru",
  "/ru/development/pages/magazine-articles-2022/modiin-arc.aspx":"/?page=modiin-arc&lan=ru",
  "/ru/development/pages/magazine-articles-2022/431-project.aspx":"/?page=431-project&lan=ru",
  "/ru/development/pages/magazine-articles-2022/etcs-upgrade.aspx":"/?page=etcs-upgrade&lan=ru",
  "/ru/pages/infrastructure.aspx":"/?page=infrastructure&lan=ru",
  "/ru/taarif/pages/travel-arrangements.aspx":"/?page=travel-arrangements&lan=ru",
  "/ru/about/career":"/?page=career&lan=ru"
}

export  const  check301redirects= (url:string)=>{

    let oldUrl = Urls[`${url}`]
  

    if(!oldUrl){
        if(url.includes(`/stations/`)){
           return  checkStationsRedirect(url)
        }
        else if(url.includes(`.aspx`))
        {
            oldUrl= url.includes(`/ar/`)?`/?lan=ar`:url.includes(`/en/`)?`/?lan=en`:url.includes(`/ru/`)?`/?lan=ru`:`/?lan=he`
        }
        else if(url.includes(`FSID`)&&url.includes(`TSID`)&&!url.includes(`page=`)){
            let URL = url
            oldUrl = `/?page=${`mldp&`+URL.replace(`?`,``)}`

        }
       
        
    }
    // else if(!url.includes(`?`)){
    //     if(url.length>1)
    //     oldUrl = `/?page=PageNotFound`
    //     else
    //     oldUrl =``
    // }
    // else if(!url.includes(`page=`)&&!url.includes(`lan=`)){
    //     oldUrl = `/?page=PageNotFound`
    // }
    return oldUrl
}

export const CheckWrongUrl =(url:string)=>{
    
    if(url!=`/`)
        return `/?page=PageNotFound`;
    
    return ``
}

export const CheckDynamicParams = async(params :string)=>{
    let newParams = params;
    let dynamicUrlFlag = false
    let dynamicUrls = await (await GetSystemParams()).filter(parametr=>{
        if(parametr.key.includes('UP_'))
        return parametr
    })
    // console.log(dynamicUrls)
    for(let i=0;i<dynamicUrls.length;i++){
        if(newParams.includes(dynamicUrls[i].key)){
            newParams = newParams.replaceAll(dynamicUrls[i].key,dynamicUrls[i].value)
            dynamicUrlFlag=true
        }
    }
    if(newParams===params)
        return '';

    return newParams;
}