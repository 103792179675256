import { t } from "i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import { IImageAndText } from "../../Interfaces/IImageAndText";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { StyleMode } from "../../Utils/consts";
import { RemoveHtmlTags } from "../../Utils/helpers";
import useDeviceMode from "../../Utils/useWindowDimensions";
import { replaceRichtextBoldWithFont } from "../../Utils/utils";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../Services/DeviceService";

const ImageAndText: React.FC<IImageAndText> = (props) => {
  const {
    title,
    description,
    continueRead,
    image,
    backgroundColor,
    direction,
  } = props;

  const navigate = useNavigate();
  const [_isContinueReadLinkOver, _setIsContinueReadLinkOver] = useState(false);

  const redirectToPage = (page?: string) => {
    page && navigate(`?page=${page}`);
  };

  const deviceMode = useDeviceMode();
  return (
    <div
      style={{
        ...styles.imageAndTextContainer,
        backgroundColor: `#${backgroundColor}`,
        flexDirection:
          deviceMode === StyleMode.desktop
            ? direction === "Right to Left"
              ? "row"
              : "row-reverse"
            : "column",
      }}
    >
      <div style={{ flex: "2.2" }}>
        {image && (
          <img src={image._url} width={"100%"} height={"100%"} alt={``} />
        )}
      </div>
      <div style={styles.informationContainer}>
        <div style={styles.information}>
          <h3 style={styles.title} aria-labelledby={title}>
            {title}
          </h3>
          <div
            style={{
              ...styles.description,
              maxWidth: deviceMode === StyleMode.desktop ? "20rem" : "",
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: replaceRichtextBoldWithFont(description),
              }}
              aria-label={`${RemoveHtmlTags(description)}`}
              role={`img`}
            ></div>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: ".25rem",
                textDecoration: _isContinueReadLinkOver ? "underline" : "",
              }}
              tabIndex={!IsMobile() ? 0 : -1}
              role={"link"}
              aria-label={t("ContinueReadAbout", { title })}
              onClick={() => {
                if(continueRead) {
                  redirectToPage(continueRead.redirectTo);
                }
              }}
              onMouseOver={() => _setIsContinueReadLinkOver(true)}
              onMouseLeave={() => _setIsContinueReadLinkOver(false)}
            >
              <div
                style={{
                  ...styles.continueRead,
                }}
              >
                {continueRead && `${continueRead.text}${continueRead.text.length>0?`>`:``}`}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  imageAndTextContainer: {
    display: "flex",
    width: "80%",
    maxWidth: "76rem",
    position: "relative",
  },
  informationContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "1",
  },
  information: {
    display: "flex",
    flexDirection: "column",
    margin: "0 1rem",
    gap: "1rem",
  },
  title: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.UmbracoElementTitle,
    maxWidth: "24rem",
    lineHeight: "1.7rem",
  },
  description: {
    fontFamily: fonts.FbReformaLight,
    fontSize: fontSize.Paragraph,
    lineHeight: "1.75rem",
    flex: "1",
  },
  continueRead: {
    color: colors.oldLavender,
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Paragraph,
    cursor: "pointer",
  },
  image: {
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    flex: "2",
    backgroundPosition: "center",
  },
};

export default ImageAndText;
