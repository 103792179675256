import store from "store2";
import Config from "../Configuration/server";
import i18n from "./i18n";
import { getMobileOS } from "../Utils/useDeviceOS";
import { log } from "console";
import { IDictStation } from "../Utils/helpers";

export const handleStationClick = (stationId: number) => {
  window.location.href = `?page=stationinfo&stationname=${stationId}`;
};

export const handleMapPin = (stationId: number) => {
  const animatedStations = Array.from(
    document.getElementsByClassName("currentlocation")
  );
  for (let i = 0; i < animatedStations.length; i++) {
    animatedStations[i].setAttribute("class", "activestation");
  }

  const station = document.getElementById(
    `stationgroup${stationId.toString()}`
  );
  if (station) {
    const activeStation = Array.from(
      station.getElementsByClassName("activestation")
    );
    if (activeStation && activeStation.length > 0) {
      for (let i = 0; i < activeStation.length; i++) {
        activeStation[i].setAttribute("class", "currentlocation");
      }
    }
  }
};

export const removeUnnecessaryThingsFromMap = () => {
  const sideHeaders = document.getElementById("side_headers");
  if (sideHeaders) {
    sideHeaders.remove();
  }
};

const removeLanguages = (stationsElements: HTMLCollectionOf<Element>) => {
  if (stationsElements) {
    Array.from(stationsElements).forEach(
      (station) => ((station as HTMLElement).style.display = "none")
    );
  }
};

const getRussianStationName = (stationId: number) => {
  const legendRu = store.session.get(
    `${Config.BASE_LOCALSTORAGE_NAME}stations`
  )["ru"];
  const legendEn = store.session.get(
    `${Config.BASE_LOCALSTORAGE_NAME}stations`
  )["en"];

  for (let i = 0; i < legendEn.length; i++) {
    if (legendEn[i].stationId === stationId) {
      return legendRu[i].stationName;
    }
  }
  return stationId;
};

export const handleLegendPosition = () => {
  const legendContainer = document.getElementById(
    "Left_side_menu"
  ) as HTMLElement;

  if (legendContainer) {
    // legendContainer.setAttribute("transform", "matrix(1, 0, 0, 1, 30, -170)");
    const legends_he = legendContainer.getElementsByClassName("smallheader_he");
    const legends_en = legendContainer.getElementsByClassName("smallheader_en");
    const legends_ar = legendContainer.getElementsByClassName("smallheader_ar");
    const legends_ru = legendContainer.getElementsByClassName("smallheader_ru");

    const currentLanguage = i18n.language;
    switch (currentLanguage) {
      case "he":
        removeLanguages(legends_en);
        removeLanguages(legends_ar);
        removeLanguages(legends_ru);
        break;
      case "en":
        removeLanguages(legends_he);
        removeLanguages(legends_ar);
        removeLanguages(legends_ru);
        break;
      case "ru":
        removeLanguages(legends_he);
        removeLanguages(legends_ar);
        removeLanguages(legends_en);

        break;
      case "ar":
        removeLanguages(legends_en);
        removeLanguages(legends_he);
        removeLanguages(legends_ru);
        break;
    }
  }
};

export const fixSafariStationsNamesDirections = (stationDictionary:IDictStation) => {

 

  const stations = Array.from(
    document.getElementsByClassName("smallstations_he")
  );

  const stationsAr = Array.from(
    document.getElementsByClassName("smallstations_ar")
  );

  const stationsEn = Array.from(
    document.getElementsByClassName("smallstations_en")
  );

  const stationsRu = Array.from(
    document.getElementsByClassName("smallstations_ru")
  );

  const legendsHeaders = Array.from(
    document.getElementsByClassName("smallheader_he")
  );

  const legendsHeadersAr = Array.from(
    document.getElementsByClassName("smallheader_ar")
  );
  if ((stationsEn && stationsEn.length > 0)) {
    const isIos = getMobileOS();
    const isSafariOs = /^((?!chrome|android).)*safari/i.test(
      navigator.userAgent
    )
  

    if (i18n.dir() === "ltr" && (isIos === "IOS" || isSafariOs)) {
   
     stationsEn.forEach((station)=>{
   
       (station as HTMLElement).style.direction="rtl";
   
     });
   
    }
  
  }

  if ((stationsRu && stationsRu.length > 0)) {
    const isIos = getMobileOS();
    const isSafariOs = /^((?!chrome|android).)*safari/i.test(
      navigator.userAgent
    )
  
    if (i18n.dir() === "ltr" && (isIos === "IOS" || isSafariOs)) {
   
     stationsRu.forEach((station)=>{
   
       (station as HTMLElement).style.direction="rtl";
   
     });
   
    }

  }

  if (stations && stations.length > 0) {
    const isIos = getMobileOS();
    const isSafariOs = /^((?!chrome|android).)*safari/i.test(
      navigator.userAgent
    );

    const lodGaneAvivStation = document.getElementById("stationgroup5150");



    if(lodGaneAvivStation){
  const lodName = lodGaneAvivStation.getElementsByTagName('text')[0];

  lodName.innerHTML = lodName.innerHTML.split("-").reverse().join("-");

    }


    if (i18n.dir() === "rtl" && (isIos === "IOS" || isSafariOs)) {
      
      const mainHeader  =  document.getElementById("title_heb");
      

      if(mainHeader){

        mainHeader.innerHTML = mainHeader.innerHTML.split("").reverse().join("");
      }

      

      legendsHeaders.forEach((header) => {
        const reversedText = header.innerHTML.split("").reverse().join("");
        header.textContent = reversedText;

      })
      
      stations.forEach((station) => {

        if (station.children.length === 0) {
          const reversedText = station.innerHTML.split("").reverse().join("");
          station.textContent = reversedText;
        } else {
         
          const stationName = station.innerHTML.split("<")[0];
          const reversedStationName = stationName.split("").reverse().join("");

          const xParent = (station as SVGTSpanElement).x.baseVal[0].value;
          const dyParent = (station as SVGTSpanElement).dy.baseVal[0]

          const x = (station.children[0] as SVGTSpanElement).x.baseVal[0].value;
          const dy = (station.children[0] as SVGTSpanElement).dy.baseVal[0]
            .value;
          const tspanTextReversed = station.children[0].innerHTML
            .split("")
            .reverse()
            .join("");

          const reveresedStationName = `${reversedStationName}<tspan x=${x} dy=${dy}>${tspanTextReversed}</tspan>`;

          const resultHTML = `
          <text class="smallstations_he" x=${xParent} dy=${dyParent}>${reversedStationName}</text>
          <text class="smallstations_he" x=${xParent} dy=${dyParent}><tspan x=${x} dy=${dy}>${tspanTextReversed}</tspan></text>
              `;
          //station.innerHTML = reveresedStationName;
          station.outerHTML = resultHTML;
        }
      });
    }
  }

  if (stationsAr && stationsAr.length > 0) {
    const isIos = getMobileOS();
    const isSafariOs = /^((?!chrome|android).)*safari/i.test(
      navigator.userAgent
    );


    if (i18n.dir() === "rtl" && (isIos === "IOS" || isSafariOs)) {
      
      // const mainHeader  =  document.getElementById("title_heb");
      

      // if(mainHeader){

      //   mainHeader.innerHTML = mainHeader.innerHTML.split("").reverse().join("");
      // }

      

      legendsHeadersAr.forEach((header) => {
        const reversedText = header.innerHTML.split("").reverse().join("");
        header.textContent = reversedText;

      })
      
      stationsAr.forEach((station) => {
        if (station.children.length === 0) {
          const reversedText = station.innerHTML.split("").reverse().join("");
          station.textContent = reversedText;
        } else {
         
          const stationName = station.innerHTML.split("<")[0];
          const reversedStationName = stationName.split("").reverse().join("");

          const xParent = (station as SVGTSpanElement).x.baseVal[0].value;
          const dyParent = (station as SVGTSpanElement).dy.baseVal[0]

          const x = (station.children[0] as SVGTSpanElement).x.baseVal[0].value;
          const dy = (station.children[0] as SVGTSpanElement).dy.baseVal[0]
            .value;
          const tspanTextReversed = station.children[0].innerHTML
            .split("")
            .reverse()
            .join("");

          const reveresedStationName = `${reversedStationName}<tspan x=${x} dy=${dy}>${tspanTextReversed}</tspan>`;

          const resultHTML = `
          <text class="smallstations_ar" x=${xParent} dy=${dyParent}>${reversedStationName}</text>
          <text class="smallstations_ar" x=${xParent} dy=${dyParent}><tspan x=${x} dy=${dy}>${tspanTextReversed}</tspan></text>
              `;
          //station.innerHTML = reveresedStationName;
          station.outerHTML = resultHTML;
        }
      });
    }
  }
};

export const findAndAddClicksToStations = (
  onStationMovement: (
    stationId?: number | string,
    mouseEvent?: MouseEvent
  ) => void,
  onStationLeave: () => void
) => {
  const stations_g = document.getElementById("stations");
  if (!stations_g) return;
  for (let i = 0; i < stations_g.children.length; i++) {
    const stationsCircles =
      stations_g.children[i].getElementsByTagName("circle");
    const stationConnectedCircles =
      stations_g.children[i].getElementsByTagName("path");
    const stationId = stations_g.children[i].id.split("stationgroup")[1];

    // add event listener for clicks on station connected circles:
    for (let j = 0; j < stationConnectedCircles.length; j++) {
      stationConnectedCircles[j].style.cursor = "pointer";
      stationConnectedCircles[j].addEventListener("click", () => {
        return handleStationClick(parseInt(stationId));
      });
      stationConnectedCircles[j].addEventListener("mouseover", (e) => {
        onStationMovement(stationId, e);
      });
      stationConnectedCircles[j].removeEventListener("mouseover", (e) => {
        onStationMovement(stationId, e);
      });
    }

    // add event listener for clicks on circles:
    for (let j = 0; j < stationsCircles.length; j++) {
      stationsCircles[j].style.cursor = "pointer";
      stationsCircles[j].addEventListener("click", () => {
        return handleStationClick(parseInt(stationId));
      });
      stationsCircles[j].removeEventListener("click", () => {
        return handleStationClick(parseInt(stationId));
      });

      stationsCircles[j].addEventListener("mouseover", (e) => {
        onStationMovement(stationId, e);
      });
      stationsCircles[j].removeEventListener("mouseover", (e) => {
        onStationMovement(stationId, e);
      });

      stationsCircles[j].addEventListener("mouseleave", (e) => {
        onStationLeave();
      });
      stationsCircles[j].removeEventListener("mouseleave", (e) => {
        onStationLeave();
      });
    }
  }
};

const GetMapPinSvg = (stationId: number, cx: number, cy: number) => {
  return `<circle class="mapPin" id="mapPin_${stationId}"  cx="${cx}" cy="${cy}" 
  style="cursor: pointer; fill: red; r: 2; opacity:0">
  </circle>`;
};
