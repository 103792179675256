import { useState } from "react";
import { FaCalendarAlt } from "react-icons/fa";
import colors from "../../../Assets/Colors/Colors.json";
import { ICustomInput } from "../../../Interfaces/IForms";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import DatePicker from "../../DatePicker/react-datepicker";
// import ReactDatePicker from "react-datepicker";

const CustomDatePicker: React.FC<ICustomInput> = (props) => {
  const {
    label,
    showTime,
    selectstyle,
    value,
    onChange,
    errors,
    touched,
  } = props;

  const datePickerFormat = showTime ? "dd/MM/yyyy - HH:mm" : "dd/MM/yyyy";

  const boolTouched = touched === "true" ? true : false;

  return (
    <div
      style={
        selectstyle
          ? {
              ...styles.inputContainer,
              flex: selectstyle.flex,
              width: selectstyle.width,
            }
          : styles.inputContainer
      }
    >
      <label style={styles.label}>{label}</label>
      <div style={styles.input}>
        <div style={styles.icon}>
          <FaCalendarAlt />
        </div>
        <DatePicker
          dateFormat={datePickerFormat}
          showTimeSelect={showTime}
          timeFormat="HH:mm"
          timeIntervals={1}
          // locale={i18n.language}
          selected={(value && new Date(value)) || null}
          onChange={onChange}
          className={
            errors && errors && boolTouched && boolTouched ? "input-error" : ""
          }

        />
        {errors && errors && boolTouched && boolTouched && (
          <p className="error">{errors && errors}</p>
        )}
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  label: {
    fontSize: "1rem",
    color: colors.black,
    fontWeight: "bold",
    display: "flex",
    margin: "1rem 0 0.2rem",
  },
  input: {
    width: "100%",
    padding: "0.65rem 0.5rem",
    fontSize: "1rem",
    color: colors.black,
    border: "1px solid",
    borderColor: colors.gray,
    borderRadius: "10px",
    outline: "none",
    background: "white",
    display: "flex",
    justifyContent: "center",
    gap: ".5rem",
  },
  inputContainer: {
    flex: "45%",
  },
  icon: {
    paddingRight: ".5rem",
  },
};

export default CustomDatePicker;
