import { ITag } from "../../Interfaces/ITag";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import fontSize from "../../Assets/Fonts/FontsSizes.json";

const Tag: React.FC<ITag> = (props) => {
  const { text } = props;

  return (
    <div style={styles.container}>
      <div style={styles.text}>{text}</div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.pattensBlue,
    borderRadius: "1rem",
    width: "5rem",
    height: "1.4rem",
  },
  text: {
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Text,
    letterSpacing: "0.029375rem",
    color: colors.dimGray,
  },
};

export default Tag;
