import { useState } from "react";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  IArticle,
  IArticleWithMedia,
  ISelectedArticles,
} from "../../../../Interfaces/IMagazinePage";
import { StyleMode } from "../../../../Utils/consts";
import useDeviceMode from "../../../../Utils/useWindowDimensions";
import LayoutMagazine from "../Components/LayoutMagazine";
import MediaAndTextArticle from "./MediaAndTextArticle";
import SelectedArticleGalleryItem from "./SelectedArticleGalleryItem";

const SelectedArticles: React.FC<ISelectedArticles> = (props) => {
  const { articleWithMedia, articlesWithMedia, title, anchor } = props;
  const [_articleOnModal, _setArticleOnModal] = useState<
    IArticle | IArticleWithMedia
  >();
  const deviceMode = useDeviceMode();
  return (<LayoutMagazine title={title} anchor={anchor} modalData={_articleOnModal}
    onCloseModal={() => _setArticleOnModal(undefined)}>
    <div style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      gap: "4rem"
    }}>
      <MediaAndTextArticle {...articleWithMedia} />
      <div style={{ paddingInlineStart: deviceMode !== StyleMode.desktop ? "2rem" : "0" }}>
        <Swiper
          slidesPerView={deviceMode === StyleMode.desktop ? 3 : 1.5}
          spaceBetween={deviceMode === StyleMode.desktop ? 120 : 30}

          navigation={false}
          modules={[Navigation]}
          className="mySwiper"
          role={"region"}
          slideToClickedSlide={true}
        >
          {articlesWithMedia.map((article, index) => (
            <SwiperSlide key={index}>
              <SelectedArticleGalleryItem
                {...article}
                onOpenModal={(selectedArticle) => {
                  _setArticleOnModal(selectedArticle);
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  </LayoutMagazine>)

};




export default SelectedArticles;