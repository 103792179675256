import { t } from "i18next";
import { useState } from "react";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import { IWorker } from "../../Interfaces/ICareers";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import useDeviceMode from "../../Utils/useWindowDimensions";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../Services/DeviceService";
import { StyleMode } from "../../Utils/consts";

const Worker: React.FC<IWorker> = (props) => {
  let [_active, _setActive] = useState(false);
  const deviceMode = useDeviceMode();
  const {
    index,
    firstName,
    lastName,
    description,
    descriptionTitle,
    note,
    noteTitle,
    positionJob,
    profile,
    onClick,
  } = props;


  const overWorkerEnter = (e: any) => {
    let parent = e.target.closest(".workers");
    let topBox = parent.querySelector(".top-box");
    let nameAndPosition = parent.querySelector(".name-and-position");

    nameAndPosition.classList.add("hide");
    topBox.classList.remove("hide");
    topBox.classList.add("show-box");
    topBox.tabIndex=0
    topBox.focus()
  };

  const outWorkerEnter = (e: any) => {
    let parent = e.target.closest(".workers");
    let topBox = parent.querySelector(".top-box");
    let nameAndPosition = parent.querySelector(".name-and-position");
    nameAndPosition.classList.remove("hide");
    topBox.classList.add("hide");
    topBox.classList.remove("show-box");
    topBox.tabIndex=-1
  };


  const overWorker = (e: any) => {
    let parent = e.target.closest(".workers");
    let topBox = parent.querySelector(".top-box");
    let nameAndPosition = parent.querySelector(".name-and-position");

    nameAndPosition.classList.add("hide");
    topBox.classList.remove("hide");
    topBox.classList.add("show-box");
  };

  const outWorker = (e: any) => {
    let parent = e.target.closest(".workers");
    let topBox = parent.querySelector(".top-box");
    let nameAndPosition = parent.querySelector(".name-and-position");
    nameAndPosition.classList.remove("hide");
    topBox.classList.add("hide");
    topBox.classList.remove("show-box");
  };

  const OnOverWorker = (e: any) => {
    deviceMode === "DESKTOP" && overWorker(e);
  };
  const OnoutWorker = (e: any) => {
    deviceMode === "DESKTOP" && outWorker(e);
  };
  
  const OnOverWorkerEnter = (e: any) => {
    deviceMode === "DESKTOP" && overWorkerEnter(e);
  };
  const OnoutWorkerEnter = (e: any) => {
    deviceMode === "DESKTOP" && outWorkerEnter(e);
  };
  return (
    <div
      className="workers"
      style={{ ...styles.column, gap: "2rem" }}
      onClick={() => onClick && onClick(props)}
    >
     
      <div
        tabIndex={!IsMobile() ? 0 : -1}
        id={`top-box-${index}`}
        // aria-labelledby={`top-box-${index}`}
        aria-label ={`${firstName +''+ lastName +''+ positionJob}`}
        className="boxss"
        onMouseOver={OnOverWorker}
        onFocusCapture={(e) => {
          const namesAndPositions = Array.from(
            document.getElementsByClassName("name-and-position")
          );

          namesAndPositions.forEach((nameAndPosition) => {
            if (nameAndPosition.id !== e.currentTarget.id) {
              nameAndPosition.classList.remove("hide");
            }
          });

          const topBoxes = Array.from(
            document.getElementsByClassName("top-box")
          );
          topBoxes.forEach((topBox) => {
            if (topBox.id !== e.currentTarget.id) {
              if (topBox.classList.length > 1) {
                topBox.classList.remove("show-box");
                topBox.classList.add("hide");
              }
            }
          });
        }}
        role={"button"}
        onMouseOut={OnoutWorker}
        onBlurCapture={(e) => {}}
        onKeyPressCapture={(e) => {
          OnOverWorkerEnter(e);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            OnOverWorkerEnter(e);
          }
        }}
        onKeyDownCapture={(e) => {
          if (e.key === "Enter") {
            OnOverWorkerEnter(e);
          }
        }}
        onClick={(e) => {
          _active ? outWorkerEnter(e) : overWorkerEnter(e);
          _setActive(!_active);
        }}
        style={styles.column}
      >
        {deviceMode === StyleMode.desktop && (
          <div className="line-show-in-over" />
        )}
        <div className="name-and-position">
          {firstName} {lastName},<br />
          {positionJob}
        </div>
        <img
          src={profile?._url}
          alt={``}
          style={{ width: "100%", height: "15.625rem", objectFit: "cover" }}
        />
      </div>
      <div
        id={`top-box-${index}`}
        tabIndex={-1}
        className="top-box"
        // onMouseOver={overMouseWorker}
        // onMouseOut={outMouseWorker}
        style={styles.topBox}
      >
        <div className="lets-talks" style={styles.letsTalks}>{`${t(
          "LetWorkWith"
        )} ${firstName}`}</div>
        <div className="lets-note" style={styles.letsNote}>
          {note}
          {/* {note.slice(0, 60)}
          {note.length >= 60 && "..."} */}
        </div>
        <div className="lets-description" style={styles.letsDescription}>
          {description}
          {/* {description.slice(0, 80)}
          {description.length >= 80 && "..."} */}
        </div>
      </div>
    </div>
  );
};
const styles: StyleSheet = {
  wrap: {
    display: "flex",
    flexWrap: "wrap",
  },
  bgGray: {
    backgroundColor: "#F9F9F9",
  },
  title: {
    fontSize: fontSize.PageTitle,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaLight,
    lineHeight: "3rem",
    margin: 0,
  },
  subtitle: {
    fontSize: fontSize.H1,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaLight,
    margin: 0,
  },
  info: {
    fontSize: fontSize.Paragraph,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaLight,
    margin: 0,
  },
  inTitle: {
    fontSize: fontSize.PageTitle,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
  },
  mainHeader: {
    minHeight: "15.6rem",
    display: "flex",
    height: "100vh",
    maxHeight: "28rem",
  },
  cover: {
    backgroundColor: "rgba(0, 70, 143, 0.5)",
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  maxWidth: {
    maxWidth: "68rem",
    margin: "auto",
    paddingBlock: "2rem",
    paddingInline: "1rem",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  btn: {
    borderRadius: "3.75rem",
    backgroundColor: "rgb(249, 186, 77)",
    border: 0,
    fontSize: fontSize.Text,
    padding: "1rem",
    fontFamily: fonts.FbReformaMedium,

    width: "16rem",
  },
  btnOutline: {
    backgroundColor: "transparent",
    color: colors.darkBlue,
    border: "0.06rem solid",
  },
  wrapperInfo: {
    flex: "1.5 1 0%",
    flexDirection: "column",
    gap: "1rem",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    textAlign: "center",
  },
  infoBlue: {
    fontSize: fontSize.CareerElements,
    fontFamily: fonts.FbReformaMedium,
    lineHeight: "normal",
    color: colors.darkBlue,
  },
  name: {
    fontSize: fontSize.H1,
    color: "rgb(249, 186, 77)",
  },
  lineDivider: {
    width: 1,

    backgroundColor: colors.darkBlue,
  },
  description: {
    fontFamily: fonts.FbReformaLight,
    color: colors.darkBlue,
    fontSize: fontSize.UmbracoElementTitle,
    letterSpacing: "0.023rem",
    lineHeight: "2rem",
  },
  cardJob: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0 2px 23px 0 rgb(0 0 0 / 8%)",

    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    flex: 1,
  },
  tags: {
    display: "flex",
    flexWrap: "wrap",
  },
  tag: {
    border: "0.06rem solid #00458a",
    borderRadius: "3.1rem",
    minWidth: "4.4rem",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#D8F2FC",
    textAlign: "center",
    display: "inline-flex",
  },
  linkMoreInfo: {
    color: "#009DD6",
    fontSize: fontSize.Paragraph,
    letterSpacing: "0.03rem",
    lineHeight: "1.5rem",
  },
  whyToWork: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  whyTitle: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.H1,
  },
  infoBlack: {
    color: "#282828",
    fontSize: fontSize.UmbracoElementTitle,
    letterSpacing: "0.042rem",
    fontFamily: fonts.FbReformaLight,

    lineHeight: "2rem",
  },
  // ".boxss": { backgroundColor: "#D8F3FE" },
  topBox: {
    backgroundColor: "#D8F3FE",
    padding: "1rem",
    flexDirection: "column",
    display: "flex",
    minHeight: "27rem",
    gap: "1.5rem",
  },
  letsTalks: {
    color: colors.darkBlue,
    fontSize: fontSize.FooterItem,
    fontFamily: fonts.FbReformaMedium,
    letterSpacing: "0.44px",
    lineHeight: "1.87rem",
  },
  letsNote: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.Text,
    letterSpacing: "0.047rem",
    lineHeight: "1.3rem",
  },
  letsDescription: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Text,
    letterSpacing: "0.57px",
    lineHeight: "1.4rem",
  },
};

export default Worker;
