import { useEffect, useState } from "react";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import Times from "./Times";
import arrow from "../../../Assets/Icons/arrow.svg";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { IGateActivityHours } from "../../../Interfaces/IActivityTime";
import { t } from "i18next";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import i18n from "../../../Services/i18n";
import moment from "moment";
import { Translations } from "../../../Services/GlobalService";
import { GiConsoleController } from "react-icons/gi";

const textGate = ["Entrance", "Cashiers", "RavkavPoint"];
const Enters: React.FC<IGateActivityHours> = (props) => {
  const {
    activityHoursType,
    hours,
    lastChild,
    isShow,
    type,
    isClosedShortText,
    onClick,
    index,
  } = props;
  let [_showDetail, _setShowDetail] = useState(isShow);
  let [_isOpen, _setIsOpen] = useState<string>(""); // 0  = Now is Close, 1 = Now is Open, 2 = Not Sure, 3 = Gate Service Close
  let [_serviceClose, _setServiceClose] = useState<boolean>(false);
  let date: any = new Date();
  let currentTime = moment(new Date(), "hh:mm");
  const Translation = Translations[
    i18n.language as `he` | `en` | `ar` | `ru`
  ] as any;

  const calcHours = (hour: any) => {
    //   `${date.getHours()}:${date.getMinutes()}`,
    //   "hh:mm"
    // );
    let startTime = moment(hour.startHour, "hh:mm");
    let endTime = moment(hour.endHour, "hh:mm");

    let inTime = moment(currentTime, "hh:mm").isBetween(startTime, endTime);

    if (hour.startHourReplaceTextKey || hour.endHourReplaceTextKey) {
      return 2;
    } else if (endTime < startTime) {
      if (currentTime < startTime && currentTime < endTime) {
        return 1;
      } else if (currentTime > startTime) {
        return 1;
      }
    }
    return inTime ? 1 : 0;
  };
  const checkIsOpen = () => {
    const statusOpen: string[] = [
      t("NotCurrentlyActive"),
      t("CurrentlyActive"),
      " ",
      isClosedShortText,
    ];
    let _statusOpen: number = 0;
    let _serviceClose: boolean = true;
    for (let index = 0; index < hours.length; index++) {
      const hour: IGateActivityHours = hours[index];
      statusOpen[0] =
        hour.activityHoursType === 1
          ? Translation.StationGateCloseNow
          : Translation.StatioTicketsSaleFacilitiesCloseNow;
      statusOpen[1] =
        hour.activityHoursType === 1
          ? Translation.StationGateOpenNow
          : Translation.StatioTicketsSaleFacilitiesOpenNow;

      if (hour?.isClosedShortText?.length > 0) {
        _statusOpen = 3;
        _serviceClose = true;
      }
      else if(!hour.isClosedLongText){
           if (hour.activityDaysNumbers.includes(date.getDay() + 1)) {
        //date.getDay() + 1
        if (
          hour.endHourPostfixTextKey ||
          hour.endHourReplaceTextKey ||
          hour.startHourReplaceTextKey ||
          hour.endHourReplaceTextKey ||
          hour.startHourTextKey
        ) {
          _statusOpen = 2;
        } else {
          if (hour.activityHoursReplaceTextKey) {
            _serviceClose = hour.endHour === "23:59" ? false : true
            _statusOpen = hour.endHour === "23:59" ? 1 : 0;
          } else {
            _statusOpen = calcHours(hour);
            if (currentTime.isBefore(moment(hour.startHour)) && _statusOpen === 0) {
              _statusOpen = calcHours(
                hours[index === 0 ? hours.length - 1 : index - 1]
              );

              _serviceClose = _statusOpen === 0;
            } else {
              _statusOpen = calcHours(hour);
              _serviceClose = _statusOpen === 0;
            }
            if (_statusOpen === 1) {
              break;
            }
          }
        }
      }
      }
   
    }
    _setIsOpen(statusOpen[_statusOpen]);
    _setServiceClose(_serviceClose);
  };
  useEffect(() => {
    checkIsOpen();
  }, []);
  return (
    <div
      style={
        type === "desktop"
          ? {
              lineHeight: "3rem",
              padding: "1rem",
            }
          : {
              ...styles.wrapper,
              borderBottom: lastChild ? "" : "thin solid #7575751a",
            }
      }
    >
      <div
        className="row-between centering"
        style={{
          cursor: "pointer",
          pointerEvents: type === "desktop" ? "none" : "auto",
        }}
        onClick={() => {
          _setShowDetail(!_showDetail);
          onClick && onClick(index);
        }}
      >
        <div
          style={type === "desktop" ? styles.subTitleDesktop : styles.subTitle}
        >
          {type !== "desktop" && (
            <img
              src={arrow}
              style={{
                transition: "0.2s",
                transform: `rotateZ(${_showDetail ? 0 : 90}deg)`,
                marginInlineEnd: "0.5rem",
              }}
              alt={``}
              width={`auto`}
              height={`auto`}
            />
          )}
          {t(textGate[activityHoursType - 1])}
        </div>
        <div
          className={`${!_serviceClose ? "is-open" : "is-close"}`}
          style={
            type === "desktop" ? styles.textMediumDesktop : styles.textMedium
          }
        >
          {_isOpen}
        </div>
      </div>
      {hours[0].isClosedShortText.length === 0 && _showDetail && (
        <Times data={hours} />
      )}
      {hours[0].isClosedShortText.length > 0 && (
        <div style={styles.text}>{hours[0].isClosedLongText}</div>
      )}
    </div>
  );
};

const styles: StyleSheet = {
  wrapper: {
    lineHeight: "1.6rem",
    padding: "1rem 0rem",
    borderBottom: "thin solid #75757580",
    paddingBottom: "0.4rem",
    marginInline: "1rem",
  },
  nameOption: {
    fontFamily: fonts.FbReformaRegular,
    color: "var(--darkBlue)",
    textAlign: "center",
    fontSize: fontSize.Text,
    whiteSpace: "nowrap",
  },

  circleWapper: {
    width: "6.25rem",
    height: "6.25rem",
    borderRadius: "50%",
    padding: "20px",
    border: "2px solid var(--darkBlue)",
    boxSizing: "border-box",
    position: "relative",
    backgroundColor: "var(--white)",
    boxShadow: "var(--shadowColor)",
    margin: "auto",
    marginBottom: "0.5rem",
  },
  topHeader: {
    backgroundColor: colors.pattensBlue,
    padding: "0.5rem 1rem",
  },
  itemDescription: {
    flex: "1 1 20rem",
  },
  itemOption: {
    position: "relative",
    textAlign: "center",
    padding: "0.19rem",
  },
  title: {
    display: "inline-block",
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Text,
    color: colors.darkBlue,
  },
  subTitleDesktop: {
    display: "inline-block",
    fontFamily: "FbReforma-Medium , HelveticaNeue-Bold",
    fontWeight: `${i18n.language === "ar" ? `600` : ``}`,
    fontSize: fontSize.UmbracoElementTitle,
    color: colors.darkBlue,
  },
  subTitle: {
    display: "inline-block",
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Text,
    color: colors.darkBlue,
  },
  wrapperTwoRow: {
    flex: "1 0 25rem",
    maxWidth: "50%",
  },
  stationOptions: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    rowGap: "3rem",
    columnGap: "1rem",
  },
  text: {
    fontFamily: fonts.FbReformaLight,
    fontSize: fontSize.FooterItem,
    lineHeight: "1.75rem",
  },
  note: {
    alignItems: "center",
    lineHeight: "3rem",
  },
  updates: {
    backgroundColor: "var(--lightGray)",
    padding: "2rem 3rem",
    lineHeight: "3rem",
    borderRadius: "1rem",
  },
  attention: {
    borderInlineStart: "4px solid var(--red)",
    paddingInlineStart: "2rem",
    lineHeight: "1.75rem",
  },
  cardAtivityTime: {
    boxShadow: "var(--shadowColor)",
    borderRadius: "1rem",
    backgroundColor: "var(--white)",
    minWidth: "15rem",
    overflow: "hidden",
    height: "100%",
  },
  textRegular: {
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Text,
  },
  textMediumDesktop: {
    fontFamily: "FbReforma-Medium",
    fontWeight: `${i18n.language === "ar" ? `600` : ``}`,
    fontSize: fontSize.Text,
  },
  textMedium: {
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Text,
  },
  container: {
    width: "100%",
  },
  spaceBetweenElement: {
    marginBlock: "1.5rem",
  },
  charts: {
    display: "flex",
    gap: "3rem",
    alignItems: "center",
    justifyContent: "center",
  },
  donutCounted: {
    display: "flex",
    gap: "2rem",
    alignItems: "center",
  },
  donutChart: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    width: "100%",
    top: "35%",
    justifyContent: "center",
  },
  donutChartLabel: {
    display: "flex",
    flexDirection: "column",
    fontFamily: fonts.FbReformaRegular,
    textAlign: "center",
    gap: "1rem",
    color: colors.darkBlue,
  },
  donutChartValueLabel: {
    fontSize: fontSize.PageTitle,
  },
  donutChartTextLabel: {
    fontSize: fontSize.Paragraph,
  },
  legend: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "5rem",
    fontSize: fontSize.Text,
    gap: ".5rem",
    letterSpacing: "0.0275rem",
    textAlign: "center",
    color: colors.nero,
  },
  legendSquare: {
    width: "1.2rem",
    height: "1.2rem",
    borderRadius: "6px",
  },
  line: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "3rem",
  },
  accurecyIndexTitle: {
    fontFamily: fonts.FbReformaMedium,
    letterSpacing: "0.08375",
    color: colors.darkBlue,
    fontSize: fontSize.PageTitle,
  },
  accurecyIndexDescription: {
    fontFamily: fonts.FbReformaLight,
    letterSpacing: "0.038125rem",
    color: colors.nero,
    fontSize: fontSize.Paragraph,
    maxWidth: "19rem",
    paddingBottom: "1rem",
  },
  dateUpdated: {
    display: "flex",
    justifyContent: "flex-end",
    fontFamily: fonts.FbReformaRegular,
    letterSpacing: "0.0275rem",
    fontSize: fontSize.Text,
    color: colors.nero,
  },
};
export default Enters;
