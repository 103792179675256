import { useFormik } from "formik";
import React, { ChangeEvent, Fragment, useEffect, useState } from "react";
import colors from "../../../Assets/Colors/Colors.json";
import { ICurrentService } from "../../../Interfaces/IForms";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import Title from "../../UmbracoElements/Title";
import CustomAttachedFiles from "../CustomFileds/CustomAttachedFiles";
import CustomCheckbox from "../CustomFileds/CustomCheckbox";
import CustomDatePicker from "../CustomFileds/CustomDatePicker";
import CustomInput from "../CustomFileds/CustomInput";
import CustomSelect from "../CustomFileds/CustomSelect";
import CustomTextArea from "../CustomFileds/CustomTextArea";
import FormsDB from "../db.json";
import FormTitles from "../FormTitles";

import CustomTimePicker from "../CustomFileds/CustomTimePicker";

import QuestionAndAnswer from "../../UmbracoElements/QuestionsAndAnswers/QuestionAndAnswer";

const FormVideoShootingRequestScreen: React.FC<ICurrentService> = (props) => {
  const deviceMode = useDeviceMode();

  const [
    _currentAncillaryEquipmentChecked,
    _setCurrentAncillaryEquipmentChecked,
  ] = useState<string[]>([]);
  const [_currentTermsChecked, _setCurrentTermsChecked] = useState<string[]>(
    []
  );

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      userId: "",
      phoneNumber: "",
      phoneNumber2: "",
      email: "",
      contentFilmimg: "",
      contentDetails: "",
      eventDateTime: new Date(),
      startingAt: "",
      untilAt: "",
      locationType: "",
      locationStationName: "",
      locationStationDeparture: "",
      locationStationDestination: "",
      attachFiles: [],
      AncillaryEquipment: [],
      equipmentDetails: "",
      numberPersons: "",
      workAtHeight: "",
      terms: [],
    },

    onSubmit: async (values: any, actions: { resetForm: () => void }) => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      actions.resetForm();
    },
  });

  useEffect(() => {
    formik.setFieldValue(
      "AncillaryEquipment",
      _currentAncillaryEquipmentChecked
    );
  }, [_currentAncillaryEquipmentChecked]);

  useEffect(() => {
    formik.setFieldValue("terms", _currentTermsChecked);
  }, [_currentTermsChecked]);


  return (
    <Fragment>
      <FormTitles header1="בקשה לעריכת צילומים במתחמי הרכבת (תחנות רכבת/ברכבות)" />
      <div style={styles.container}>
        <Title text="פרטים אישיים" />

        <div style={styles.explainHeader}>
          יש למלא את כל השדות בטופס, לצרף תסריט/סינופסיס ולפרט את מהות הצילומים.
          <b>
            {" "}
            שימו לב! לא ניתן לטפל בבקשות צילום שיישלחו ללא תסריט ופירוט מהות
            הצילומים במתחמי הרכבת
          </b>
        </div>

        <form style={styles.form} onSubmit={formik.handleSubmit}>
          <CustomInput
            label="שם פרטי"
            name="FormVideoShootingRequestScreen.firstName"
            type="text"
            value={formik.values.firstName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder="הזן שם משתמש"
          />
          <CustomInput
            label="שם משפחה"
            name="FormVideoShootingRequestScreen.lastName"
            type="text"
            value={formik.values.lastName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder="הזן שם משפחה"
          />
          <CustomInput
            label="תעודת זהות"
            name="FormVideoShootingRequestScreen.userId"
            type="number"
            value={formik.values.userId}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder="הזן מספר תעודת זהות"
          />
          <CustomInput
            label="טלפון ליצירת קשר"
            name="FormVideoShootingRequestScreen.phoneNumber"
            type="number"
            value={formik.values.phoneNumber}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder="הזן טלפון ליצירת קשר"
          />
          <CustomInput
            label="טלפון נוסף"
            name="FormVideoShootingRequestScreen.phoneNumber2"
            type="number"
            value={formik.values.phoneNumber2}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder="הזן טלפון נוסף ליצירת קשר"
          />
          <CustomInput
            label="דואר אלקטרוני"
            name="FormVideoShootingRequestScreen.email"
            type="text"
            value={formik.values.email}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder="הזן דואר אלקטרוני"
          />

          <Title text="מידע ופירוט על הצילומים" />

          <CustomSelect
            label="מטרת הצילומים"
            name="FormVideoShootingRequestScreen.photographsPurpose"
            placeholder="photographsPurpose"
            onChange={(photographsPurpose: ChangeEvent<HTMLSelectElement>) => {
              formik.setFieldValue(
                "photographsPurpose",
                photographsPurpose.target.value
              );
            }}
            selectstyle={{
              flex: "unset",
              width: deviceMode === StyleMode.mobile ? "100%" : "48%",
            }}
          >
            <option value="">בחירה</option>
            {FormsDB.photographsPurpose.map((purpose, index) => (
              <option key={index} value={purpose.PurposeKey}>
                {purpose.PurposeValue}
              </option>
            ))}
          </CustomSelect>

          <CustomTextArea
            label="יש לפרט"
            name="FormVideoShootingRequestScreen.contentDetails"
            type="textarea"
            value={formik.values.contentDetails}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder="הזן פירוט"
          />

          <CustomDatePicker
            label="מועד הצילומים"
            name="FormVideoShootingRequestScreen.eventDateTime"
            type="date"
            value={formik.values.eventDateTime}
            onBlur={formik.handleBlur}
            placeholder="aa"
            showTime={false}
            onChange={(date: Date) => {
              formik.setFieldValue("eventDateTime", date);
            }}
            selectstyle={{
              flex: "unset",
              width: deviceMode === StyleMode.mobile ? "100%" : "48%",
            }}
            showTimeSelectOnly
          />

          <Title text="באילו שעות יתקיימו הצילומים" />
          {/* <div> */}
          <CustomTimePicker
            label="החל מהשעה"
            name="FormVideoShootingRequestScreen.startingAt"
            type="date"
            value={formik.values.startingAt}
            onBlur={formik.handleBlur}
            onChange={(date: Date) => {
              formik.setFieldValue("startingAt", date);
            }}
            placeholder="aa"
            showTime={false}
            selectstyle={{
              flex: "unset",
              width: deviceMode === StyleMode.mobile ? "100%" : "48%",
            }}
            showTimeSelectOnly
          />
          <CustomTimePicker
            label="ועד השעה"
            name="FormVideoShootingRequestScreen.untilAt"
            type="date"
            value={formik.values.untilAt}
            onBlur={formik.handleBlur}
            onChange={(date: Date) => {
              formik.setFieldValue("untilAt", date);
            }}
            placeholder="aa"
            showTime={false}
            selectstyle={{
              flex: "unset",
              width: deviceMode === StyleMode.mobile ? "100%" : "48%",
            }}
          />
          {/* </div> */}
          <CustomSelect
            label="האם הצילומים יתקיימו בתחנת רכבת ו/או בתוך רכבת"
            name="FormVideoShootingRequestScreen.locationType"
            placeholder="locationType"
            onChange={(locationType: ChangeEvent<HTMLSelectElement>) => {
              formik.setFieldValue("locationType", locationType.target.value);
            }}
            // onChange={(e) => {
            //   handlePhotographsLocationChange(e);
            // }}
            selectstyle={{
              flex: "unset",
              width: deviceMode === StyleMode.mobile ? "100%" : "48%",
            }}
          >
            <option value="">בחירה</option>
            {FormsDB.photographsLocation.map((location, index) => (
              <option key={index} value={location.locationKey}>
                {location.locationValue}
              </option>
            ))}
          </CustomSelect>

          {(formik.values.locationType === "trainStation" ||
            formik.values.locationType === "insideTrainAndAtStation") && (
            <CustomSelect
              label="שם התחנה"
              name="FormVideoShootingRequestScreen.locationStationName"
              placeholder="locationStationName"
              onChange={(
                locationStationName: ChangeEvent<HTMLSelectElement>
              ) => {
                formik.setFieldValue(
                  "locationStationName",
                  locationStationName.target.value
                );
              }}
              selectstyle={{
                flex: "unset",
                width: deviceMode === StyleMode.mobile ? "100%" : "48%",
              }}
            >
              <option value="">יש לבחור את שם התחנה</option>
              {FormsDB.stations.map((station, index) => (
                <option key={index} value={station.stationId}>
                  {station.stationName}
                </option>
              ))}
            </CustomSelect>
          )}

          {(formik.values.locationType === "insideTrainAndAtStation" ||
            formik.values.locationType === "insideTrain") && (
            <>
              <CustomSelect
                label="תחנת מוצא"
                name="FormVideoShootingRequestScreen.locationStationDeparture"
                placeholder="locationStationDeparture"
                onChange={(
                  locationStationDeparture: ChangeEvent<HTMLSelectElement>
                ) => {
                  formik.setFieldValue(
                    "locationStationDeparture",
                    locationStationDeparture.target.value
                  );
                }}
                selectstyle={{
                  flex: "unset",
                  width: deviceMode === StyleMode.mobile ? "100%" : "48%",
                }}
              >
                <option value="">יש לבחור את שם התחנה</option>
                {FormsDB.stations.map((station, index) => (
                  <option key={index} value={station.stationId}>
                    {station.stationName}
                  </option>
                ))}
              </CustomSelect>
              <CustomSelect
                label="תחנת יעד"
                name="FormVideoShootingRequestScreen.locationStationDestination"
                placeholder="locationStationDestination"
                onChange={(
                  locationStationDestination: ChangeEvent<HTMLSelectElement>
                ) => {
                  formik.setFieldValue(
                    "locationStationDestination",
                    locationStationDestination.target.value
                  );
                }}
                selectstyle={{
                  flex: "unset",
                  width: deviceMode === StyleMode.mobile ? "100%" : "48%",
                }}
              >
                <option value="">יש לבחור את שם התחנה</option>
                {FormsDB.stations.map((station, index) => (
                  <option key={index} value={station.stationId}>
                    {station.stationName}
                  </option>
                ))}
              </CustomSelect>
            </>
          )}

          <CustomAttachedFiles
            label="צירוף קובץ סינופסיס/תקציר צילומים (במקרה של תסריט, יש לצרף רק את החלק המתייחס לצילום ברכבת)"
            name="FormVideoShootingRequestScreen.attachFiles"
            onBlur={formik.handleBlur}
            onChange={(files: File[] | null) => {
              formik.setFieldValue("attachFiles", files);
            }}
            type="file"
            placeholder="צירוף קובץ"
          />

          <div style={styles.checkBoxContainer}>
            <div style={styles.label}>{"האם יש ציוד נלווה? (בחירה מרובה)"}</div>
            <div
              style={{
                ...styles.checkBoxInputContainer,
                flexDirection:
                  deviceMode === StyleMode.mobile ? "column" : "row",
              }}
            >
              {FormsDB.ancillaryEquipment.map((equ, index) => (
                <CustomCheckbox
                  key={index}
                  label="בחירה"
                  name="FormVideoShootingRequestScreen.AncillaryEquipment"
                  type="checkbox"
                  value={equ.equipmentValue}
                  placeholder=""
                  onChange={(event: {
                    target: { value: any; checked: string };
                  }) => {
                    const groups: string[] = [
                      ..._currentAncillaryEquipmentChecked,
                    ];

                    if (event.target.checked) {
                      groups.push(equ.equipmentKey);
                      _setCurrentAncillaryEquipmentChecked(groups);
                    } else {
                      _setCurrentAncillaryEquipmentChecked(
                        groups.filter((val) => val !== equ.equipmentKey)
                      );
                    }
                  }}
                />
              ))}
            </div>
          </div>
          <CustomInput
            label="כמות האנשים שיגיעו לטובת הצילומים"
            name="FormVideoShootingRequestScreen.numberPersons"
            type="number"
            value={formik.values.numberPersons}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder="הזן את כמות האנשים שיגיעו לטובת הצילומים"
          />
          <CustomSelect
            label="האם העבודה תתבצע בגובה?"
            name="FormVideoShootingRequestScreen.workAtHeight"
            placeholder="workAtHeight"
            onChange={(workAtHeight: ChangeEvent<HTMLSelectElement>) => {
              formik.setFieldValue("workAtHeight", workAtHeight.target.value);
            }}
            selectstyle={{
              flex: "unset",
              width: deviceMode === StyleMode.mobile ? "100%" : "48%",
            }}
          >
            <option value="">בחירה</option>
            {FormsDB.booleanList.map((travelType, index) => (
              <option key={index} value={travelType.boolKey}>
                {travelType.boolValue}
              </option>
            ))}
          </CustomSelect>

          <Title
            text="הנחיות בטיחות לצילומים במתחמי הרכבת (תחנות רכבת/ברכבות)"
            containerStyles={{ width: "100%" }}
          />
          <div style={styles.questionAndAnswerContainer}>
            <div>
              <QuestionAndAnswer
                question={FormsDB.SafetyInstructionsStation[0].safetyQuestion}
                answer={FormsDB.SafetyInstructionsStation[0].safetyAnswer}
              />
            </div>
            <div>
              <QuestionAndAnswer
                question={FormsDB.SafetyInstructionsTrain[0].safetyQuestion}
                answer={FormsDB.SafetyInstructionsTrain[0].safetyAnswer}
              />
            </div>
          </div>

          <div style={styles.checkBoxContainer}>
            <div style={styles.label}>{"יש לסמן ולאשר את הפרטים הבאים"}</div>
            <div>
              {FormsDB.terms.map((term, index) => (
                <CustomCheckbox
                  key={index}
                  label="בחירה"
                  name="FormVideoShootingRequestScreen.terms"
                  type="checkbox"
                  value={term.termsValue}
                  placeholder=""
                  onChange={(event: {
                    target: { value: any; checked: string };
                  }) => {
                    const groups: string[] = [..._currentTermsChecked];

                    if (event.target.checked) {
                      groups.push(term.termsKey);
                      _setCurrentTermsChecked(groups);
                    } else {
                      _setCurrentTermsChecked(
                        groups.filter((val) => val !== term.termsKey)
                      );
                    }
                  }}
                />
              ))}
            </div>
          </div>
          <div style={styles.btnContainer}>
            <button style={styles.btn} type="submit">
              שליחה
            </button>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

const styles: StyleSheet = {
  container: {
    maxWidth: "60%",
    margin: "auto",
    paddingBottom: "2rem",
  },
  form: { display: "flex", flexWrap: "wrap", columnGap: "2rem" },
  button: {
    width: "100%",
    padding: "0.65rem 0.5rem",
    fontSize: "1rem",
    color: colors.white,
    border: "2px solid",
    backgroundColor: colors.blue,
    borderRadius: "10px",
    outline: "none",
    margin: "1rem 0",
    fontWeight: "bold",
    cursor: "pointer",
  },
  label: {
    fontSize: "1rem",
    color: colors.black,
    fontWeight: "bold",
    display: "flex",
    margin: "1rem 0 0.2rem",
    width: "100%",
  },
  checkBoxContainer: {
    flex: "45%",
  },
  checkBoxInputContainer: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  questionAndAnswerContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  explainHeader: {
    paddingBottom: "1rem",
  },

  btn: {
    backgroundColor: "rgb(0, 69, 138)",
    color: "rgb(255, 255, 255)",
    borderColor: "unset",

    fontSize: "1.2rem",
    borderRadius: "16rem",
    width: "8rem",
    height: "2.5rem",
    fontWeight: "bold",
    letterSpacing: "0.029375rem",
    textAlign: "center",

    cursor: "pointer",
  },

  btnContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    paddingTop: "2rem",
  },
};

export default FormVideoShootingRequestScreen;
