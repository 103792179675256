import { useEffect, useState } from 'react'

export const UseWindowScrollPositions = () => {

   const [_scrollPosition, _setPosition] = useState({ scrollX: 0, scrollY: 0 })

   useEffect(() => {
    const mainScroll = document.getElementById("mainScroll")!;

    function updatePosition() {
        _setPosition({ scrollX: mainScroll.scrollLeft, scrollY: mainScroll.scrollTop})
    }

    mainScroll.addEventListener('scroll', updatePosition)
    updatePosition()

    return () => mainScroll.removeEventListener('scroll', updatePosition)
   }, [])

   return _scrollPosition
}