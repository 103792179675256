import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import colors from "../../../Assets/Colors/Colors.json";
import {
  ICustomerServiceItem,
  ICustomerServices,
} from "../../../Interfaces/ICustomerServices";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { setCurrentService } from "../../../Redux/Slices/formsSlice";
import { IsMobile } from "../../../Services/DeviceService";
import { GetSystemParams } from "../../../Services/GlobalService";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import CustomModal from "../../CustomModal";
import FormsContainer from "../../Forms/FormsContainer";
import SharePage from "../../SharePage/SharePage";
import CustomerServiceItem from "./CustomerServiceItem";

const CustomerServices: React.FC<ICustomerServices> = (props) => {
  const { servicesList, rowPosition, anchor } = props;
  const [_isModalOpened, _setIsModalOpened] = useState(false);
  const [_displayModernform, _setDisplayModernform] = useState("false");
  const [_selectedCustomerServiceItem, _setSelectedCustomerServiceItem] =
    useState<ICustomerServiceItem>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const deviceMode = useDeviceMode();

  const redirectToPage = (page?: string) => {
    page && navigate(`?page=${page}`);
  };

  const handleCustomerServiceSelection = (
    customerServiceItem: ICustomerServiceItem
  ) => {
    _setSelectedCustomerServiceItem(customerServiceItem);

    switch (customerServiceItem.openMode) {
      case "Modal":
        _setIsModalOpened(true);
        break;
      case "New Window":
        if (customerServiceItem.openType === "External") {
          window.open(customerServiceItem.formUrl, "_blank");
        } else if (customerServiceItem.openType === "Internal") {
          redirectToPage(customerServiceItem.formUrl);
        }
        break;
    }
  };

  const handleCloseModal = () => {
    _setIsModalOpened(false);
    dispatch(setCurrentService(""));
  };

  useEffect(() => {
    // isMounted.current = true;

    (async () => {
      let systemParams = await GetSystemParams();

      const formDisplay = systemParams.find(
        (item) => item.key === "displayModernForm"
      )?.value;

      formDisplay && _setDisplayModernform(formDisplay);
    })();
  }, []);


  return (
    <Fragment>
      <div
        style={{
          ...styles.container,
          justifyContent:
            deviceMode === StyleMode.desktop ? rowPosition : "center",
        }}
        anchor-id={anchor}
      >
        {servicesList.map((service, index) => (
          <CustomerServiceItem
            {...service}
            key={index}
            onSelect={handleCustomerServiceSelection}
          />
        ))}
      </div>
    {_selectedCustomerServiceItem &&  <CustomModal visible={_isModalOpened}
        isCloseDialog={true}
        onClose={handleCloseModal}
        showCloseButton="internal" typeStyle={"rectangle"}
        innerStyle={{ width: "85%", maxHeight: "100%", padding: 0, maxWidth: "90.625rem", height: "50rem" }}
        contentStyle={{ padding: 0 }}
        closeStyle={{...styles.closeMainModalIcon,top:"-1rem",insetInlineEnd:"-1rem"}}
      >
        {_displayModernform === "true" &&
          (_selectedCustomerServiceItem.serviceName ===
            "טופס פנייה לשירות לקוחות" ||
            _selectedCustomerServiceItem.serviceName ===
              "בקשה לצילומים ברכבת") ? (
            <FormsContainer type={_selectedCustomerServiceItem.serviceName} />
          ) : (
            <div style={{ height: "100%" }}>
              <iframe
                src={_selectedCustomerServiceItem.formUrl}
                width={"100%"}
                height={"100%"}
                tabIndex={!IsMobile() ? 0 : -1}
                aria-label={`${_selectedCustomerServiceItem.serviceName} iframe`}
              >
                
              </iframe>
              <SharePage shareUrl={_selectedCustomerServiceItem.formUrl} />
            </div>
          )}
      </CustomModal>}
  
    </Fragment>
  );
};

const styles: StyleSheet = {
  container: {
    gap: "3rem",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    width: "80%",
    maxWidth: "76rem",
  },
 
  closeMainModalIcon: {
    borderRadius: "50%",
    backgroundColor: colors.white,
  },
};

export default CustomerServices;
