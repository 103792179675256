import { IGalleryImage } from "../../../../Interfaces/IGallery";

const GalleryImage: React.FC<IGalleryImage> = (props) => {
  const { media, imageStyles, header } = props;
  return media ? (
    <img
      width={"auto"}
      src={media._url}
      style={{
        ...imageStyles,
        maxHeight: `100%`,
      }}
      alt={`${header.text}`}
    />
  ) : null;
};

export default GalleryImage;
