import QueryString from "qs";
import { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import colors from "../Assets/Colors/Colors.json";
import fonts from "../Assets/Fonts/Fonts.json";
import fontSize from "../Assets/Fonts/FontsSizes.json";
import Loading from "../Components/Loading/Loading";
import Breadcrumb from "../Components/UmbracoElements/Breadcrumb";
import UpdateCardItemMobile from "../Components/UmbracoElements/Updates/Mobile/UpdateCardItemMobile";
import UpdateCardBackendItem from "../Components/UmbracoElements/Updates/UpdateCardBackendItem";
import UpdateCardItem from "../Components/UmbracoElements/Updates/UpdateCardItem";
import { IStaticPage } from "../Interfaces/IStaticPage";
import { StyleSheet } from "../Interfaces/IStyleSheet";
import {
  IUpdatesBackendContent,
  IUpdatesContent,
} from "../Interfaces/IUmbraco";
import { IUpdates } from "../Interfaces/IUpdates";
import { PageState } from "../Redux/Slices/pageSlice";
import { GetUpdatesBackend } from "../Services/CommonService";
import { IsMobile } from "../Services/DeviceService";
import i18n from "../Services/i18n";
import {
  ChangeTitle,
  StyleMode,
  TitlePage,
  UpdateSeo
} from "../Utils/consts";
import { GetRegularAndPermanentUpdates } from "../Utils/updates";
import useDeviceMode from "../Utils/useWindowDimensions";
import StaticPage from "./StaticPage";

const Updates: React.FC<IUpdates> = (props) => {
  const { breadcrumb } = props;
  const [_updates, _setUpdates] = useState<IUpdatesContent[]>([]);
  const [_coverImage, _setCoverImage] = useState(``);
  const [_updatesBackend, _setUpdatesBackend] = useState<
    IUpdatesBackendContent[]
  >([]);
  const isUmbraco = true;
  const [_isUpdateContentPage, _setIsUpdateContentPage] = useState(false);
  const [_updateContentPage, _setUpdateContentPage] = useState<IStaticPage>();
  const [_selectedUpdateItem, _setSelectedUpdateItem] = useState(false);
  const [_loading, _setLoading] = useState(true);
  const [_currentUpdate, _setCurrentUpdate] = useState<IStaticPage>();
  const [_isMoreInfoVisible, _setIsMoreInfoVisible] = useState(false);
  const location = useLocation();
  const deviceMode = useDeviceMode();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentPage = (
    useSelector((state: any) => state.PageReducer) as PageState
  ).page;

  let isCardDisplay = true;
  const titleRef = useRef<HTMLDivElement>(null);

  const redirectToPage = (page?: string) => {
    page && navigate(`?page=${page}`);
  };

  useEffect(() => {
    let isMounted = true;
    (async () => {
      ChangeTitle(t(`${TitlePage.Updates}`));

      if (isUmbraco) {
        const updates = await GetRegularAndPermanentUpdates();
        UpdateSeo(
          updates.seo?.seoTitle,
          updates.seo?.seo,
          updates.seo?.seoSocialNetworksTitle,
          updates.seo?.seoSocialNetworksDescription,
          updates.seo?.seoSocialNetworksImage,
          false
        );
        if (updates.seo?.coverImage?._url) {
          _setCoverImage(updates.seo.coverImage._url);
        }
        if (isMounted) {
          _setUpdates(updates.updates);
          _setLoading(false);
        }

        const urlParams = QueryString.parse(location.search.split("?")[1]);
        if (urlParams.page && urlParams.page.toString().includes("updates/")) {
          const updatePage = urlParams.page.toString().split("/")[1] as string;
          if (isMounted) {
            _setIsUpdateContentPage(true);
          }
          if (updates.updates.length > 0) {
            const updatePageContent = updates.updates.find(
              (update) => update.updatePage.id === updatePage
            );
            if (updatePageContent) {
              const contentPage = updatePageContent.updatePage;
              if (isMounted) {
                _setUpdateContentPage(contentPage);
              }
            }
          }
        } else {
          if (isMounted) {
            _setIsUpdateContentPage(false);
          }
        }
        // const updateContent = updates.find(
        //   (update) => update.updatePage[0].id === 'pesach-2022'
        // );
      } else {
        const updatesBackendContent = await GetUpdatesBackend(i18n.language);
        if (isMounted) {
          _setUpdatesBackend(updatesBackendContent);
        }
      }
    })();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.focus();
      titleRef.current.style.outline = "none";
      titleRef.current.tabIndex = -1;
    }
  }, [location, currentPage]);



  if (_loading) return <Loading />;
  else
    return (
      <Fragment>
        {!_isUpdateContentPage ? (
          <div
            style={{
              ...styles.mainContainer,
            }}
          >
            <div
              ref={titleRef}
              style={{ position: "absolute", zIndex: -1, color: "transparent" }}
              tabIndex={!IsMobile() ? 0 : -1}
            >
              {t("CurrentPage", {
                page: t("Menu-updates"),
              })}
            </div>
            <div
              style={{
                ...styles.cover,
                background: `30% 30% / cover url(${_coverImage})`,
                height:
                  deviceMode === StyleMode.desktop
                    ? isCardDisplay
                      ? "40vh"
                      : "20.5vh"
                    : "",
                minHeight:
                  deviceMode === StyleMode.desktop
                    ? isCardDisplay
                      ? "17.18rem"
                      : "14.25rem"
                    : "",
              }}
            >
              <div className="cover-color-blur">
                <div
                  style={{
                    ...styles.coverElements,
                    width: deviceMode === StyleMode.desktop ? "80%" : "75%",
                  }}
                  className="container"
                >
                  <div
                    style={{ position: "absolute", zIndex: -1 }}
                    aria-label={t("Menu-updates")}
                    tabIndex={!IsMobile() ? 0 : -1}
                  ></div>
                  <div
                    style={{
                      ...styles.breadcrumb,
                      paddingTop:
                        deviceMode === StyleMode.desktop ? "2rem" : "3rem",
                    }}
                  >
                    <Breadcrumb
                      items={[{ text: t("Menu-updates"), link: "updates" }]}
                    />
                  </div>
                  <h1
                    style={{
                      ...styles.title,
                      paddingTop:
                        deviceMode === StyleMode.desktop ? "2rem" : "3rem",
                    }}
                  >
                    {t("Menu-updates")}
                  </h1>
                </div>
              </div>
            </div>
            <div
              style={
                deviceMode === StyleMode.desktop
                  ? styles.bodyBorder
                  : styles.bodyBorderMobile
              }
            >
              <div
                style={
                  deviceMode === StyleMode.desktop
                    ? styles.mainCard
                    : styles.mainCardMobile
                }
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: deviceMode === StyleMode.desktop ? "3rem" : "1rem",
                    padding:
                      deviceMode === StyleMode.desktop ? "5rem 0" : "1rem 0",
                  }}
                >
                  {isUmbraco ? (
                    _updates.length > 0 ? (
                      _updates.map((update, index) =>
                        deviceMode === StyleMode.desktop ? (
                          <Fragment key={index}>
                            <UpdateCardItem
                              content={update}
                              isLastItem={index === _updates.length - 1}
                              index={index}
                              updatesNumber={_updates.length}
                              clickAction={() => {
                                redirectToPage(update.updatePage.pageId);
                              }}
                            />
                            {index !== _updates.length - 1 && (
                              <div style={styles.line}></div>
                            )}
                          </Fragment>
                        ) : (
                          <Fragment key={index}>
                            <UpdateCardItemMobile
                              content={update}
                              isLastItem={index === _updates.length - 1}
                              clickAction={() => {
                                redirectToPage(update.updatePage.pageId);
                              }}
                              elements={_currentUpdate?.elements!}
                            />
                            {index !== _updates.length - 1 && (
                              <div
                                style={{
                                  ...styles.line,
                                  maxWidth: "",
                                  margin: "",
                                  width: "100%",
                                }}
                              ></div>
                            )}
                          </Fragment>
                        )
                      )
                    ) : (
                      <div
                        id="pageMainContent"
                        tabIndex={!IsMobile() ? 0 : -1}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <h1 style={styles.noUpdates}>{t("No-updates")}</h1>
                      </div>
                    )
                  ) : (
                    _updatesBackend.map((update, index) => (
                      <UpdateCardBackendItem
                        content={update}
                        key={index}
                        isLastItem={index === _updates.length - 1}
                      />
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <StaticPage {..._updateContentPage!} />
        )}


      </Fragment>
    );
};

const styles: StyleSheet = {
  mainContainer: {
    alignItems: "center",
    flexDirection: "column",
    zIndex: "10",
  },
  cover: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "20.5vh",
    position: "relative",
    minHeight: "14.25rem",
  },
  title: {
    color: colors.white,
    fontSize: fontSize.PageTitle,
    lineHeight: "3rem",
    letterSpacing: "0.05625rem",
    textShadow: `2px 2px ${colors.black}`,
    fontFamily: fonts.FbReformaMedium,
  },
  breadcrumb: {
    display: "flex",
    color: colors.white,
    fontSize: fontSize.Text,
    letterSpacing: "0.05625rem",
    fontFamily: fonts.FbReformaRegular,
    position: "absolute",
  },
  coverElements: {
    gap: "3rem",
    maxWidth: "76rem",
    margin: "auto",
    lineHeight: "2.5rem",
    padding: "1rem",
  },
  bodyBorder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transform: "translateY(-7.8rem)",
    lineHeight: `1.6rem`,
  },
  bodyBorderMobile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: `1.6rem`,
  },
  mainCard: {
    width: "80%",
    backgroundColor: colors.white,
    borderRadius: "0.3125rem",
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.15)",
    minHeight: "40rem",
  },
  mainCardMobile: {
    width: "80%",
    marginTop: "1%",
    minHeight: "40rem",
  },
  noUpdates: {
    justifyContent: "center",
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.H1,
    color: colors.oldLavender,
  },
  line: {
    height: "0.07rem",
    width: "85%",
    maxWidth: "calc(100% - 6.5rem)",
    backgroundColor: colors.pastelGray,
    margin: "0px 5rem",
  },
};

export default Updates;
