import { IPisTrainCard } from "../../../Interfaces/IPis";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import colors from "../../../Assets/Colors/Colors.json";
import Images from "../../../Data/Images/Images";
import { t } from "i18next";
import store from "store2";
import Config from "../../../Configuration/server";
import i18n from "../../../Services/i18n";
import { GetStationsDictionary } from "../../../Utils/helpers";
import { FormatHoursMinutes } from "../../../Services/DateService";
import fonts from "../../../Assets/Fonts/Fonts.json";

const PisTrainCard: React.FC<
  IPisTrainCard & {
    onClick?: (selected: IPisTrainCard) => void;
    isSelected: boolean;
  }
> = (props) => {
  const {
    trainNumber,
    destinationStationNumber,
    arrivalTime,
    originPlatform,
    onClick,
    isSelected,
  } = props;

  const stations = store.session.get(
    `${Config.BASE_LOCALSTORAGE_NAME}stations`
  )[i18n.language];
  const dictStations = GetStationsDictionary(stations);

  return (
    <div
      style={{
        ...styles.container,
        backgroundColor: isSelected ? colors.lightCyan : colors.white,
      }}
      onClick={() => {
        onClick && onClick(props);
      }}
      role={"button"}
      tabIndex={0}
    >
      <div style={styles.trainNumberContainer}>
        <span>{trainNumber}</span>
        <img src={Images.trainInCard} />
      </div>
      <div style={styles.destinationStation}>
        <span>
          {`${t("To")}${i18n.language === "he" ? "" : " "}${
            dictStations[destinationStationNumber].stationName
          }`}
        </span>
        {destinationStationNumber === 8600 && (
          <img src={Images.plane} width={"15"} />
        )}
      </div>
      <span style={styles.arrivalTime}>{FormatHoursMinutes(arrivalTime)}</span>
      <div style={styles.platformContainer}>
        <span style={styles.platform}>
          {originPlatform !== -1 && `${t("Platform")} ${originPlatform}`}
        </span>
      </div>
      <div style={styles.intermediateStations}>
        <span>{t("IntermediateStations")}</span>
        <span>{">"}</span>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    minWidth: "52.5rem",
    maxWidth: "52.5rem",
    width: "100%",
    minHeight: "5rem",
    backgroundColor: colors.white,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    cursor: "pointer",
    borderBottom: `1px solid ${colors.pastelGray}`,
  },
  trainNumberContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: ".5rem",
    flex: 2,
  },
  destinationStation: {
    display: "flex",
    alignItems: "center",
    gap: ".5rem",
    fontFamily: fonts.FbReformaMedium,
    flex: 4,
  },
  arrivalTime: {
    fontFamily: fonts.FbReformaMedium,
    flex: 0,
  },
  platformContainer: {
    flex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  platform: {
    display: "flex",
    boxSizing: "border-box",
    color: colors.white,
    backgroundColor: colors.darkBlue,
    borderRadius: "0.1875rem",
    padding: "0 .5rem",
  },
  intermediateStations: {
    display: "flex",
    alignItems: "center",
    gap: ".5rem",
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    flex: 2,
  },
};

export default PisTrainCard;
