import { IBreadCrumbItem } from "../Interfaces/IBreadcrumb";
import { IMagazinePage } from "../Interfaces/IMagazinePage";
import { IPopMessages } from "../Interfaces/IPopMessages";
import { IAnyElement, IStaticPage } from "../Interfaces/IStaticPage";
import {
  ICategoryPages,
  IPages,
  IPaths,
  ITree,
  ITreeData,
  IUmbraco,
} from "../Interfaces/ITree";
import { UmbracoTypes } from "./consts";

export let PopMessages: IPopMessages = {} as IPopMessages;

export const CreateTree = (umbraco: IUmbraco | undefined) => {
  // let paths: IPaths = { categories: [] };
  // let Tree: ITree[] = [];
  // let categories: ICategoryPages[] = [];
  // let updates: { id: string; page: IStaticPage }[] = [];
  // // console.log(umbraco?.content)
  // umbraco?.contents.map((item) => {
  //   if (
  //     item.contentTypeAlias === UmbracoTypes.category ||
  //     item.contentTypeAlias === UmbracoTypes.updates ||
  //     item.contentTypeAlias === UmbracoTypes.magazine
  //   ) {
  //     let tree: ITree = { category: item.name, pages: [], ids: [] };
  //     let pages: IPages[] = [];
  //     let updatesPages: any[] = [];
  //     switch (item.contentTypeAlias) {
  //       case UmbracoTypes.updates:
  //         item.updates.map((update) => {
  //           update.updatePage.map((page) => {
  //             let Page = page as IStaticPage;

  //             pages.push({
  //               name: Page.id,
  //               contentTypeAlias: Page.contentTypeAlias,
  //             });
  //             tree.pages.push(Page.title.text);
  //             tree.ids.push(Page.pageId);
  //             updatesPages.push(Page);
  //             // updates.push({id:Page.id,page:Page})
  //           });
  //         });
  //         paths.categories.push({ name: item.name, pages: pages });
  //         Tree.push(tree);
  //         categories.push({
  //           contentTypeAlias: item.contentTypeAlias,
  //           id: item.id,
  //           name: item.name,
  //           pages: updatesPages,
  //         });
  //         break;
  //       case UmbracoTypes.magazine:
  //         paths.categories.push({
  //           name: item.name,
  //           pages: [
  //             {
  //               name: item.name,
  //               contentTypeAlias: item.contentTypeAlias,
  //             },
  //           ],
  //         });
  //         Tree.push(tree);
  //         categories.push({
  //           contentTypeAlias: item.contentTypeAlias,
  //           id: item.pageId,
  //           name: item.name,
  //           pages: [
  //             {
  //               contentTypeAlias: item.contentTypeAlias,
  //               id: item.pageId,
  //               pageId: item.pageId,
  //               title: { text: item.title, color: "black" },
  //               summary: item.summary,
  //               coverImage: item.cover,
  //               elements: [],
  //             },
  //           ],
  //         });
  //         tree.pages.push(item.title);
  //         tree.ids.push(item.pageId);
  //         break;
  //       default:
  //         let Item = item as ICategoryPages;
  //         // let tree:ITree = {category:Item.name,pages:[],ids:[]}
  //         categories.push(Item);
  //         // let pages:IPages[] = [];
  //         Item.pages.map((page) => {
  //           let Page = page as IStaticPage;

  //           pages.push({
  //             name: Page.pageId,
  //             contentTypeAlias: Page.contentTypeAlias,
  //           });
  //           tree.pages.push(Page.title.text);
  //           tree.ids.push(Page.pageId);
  //         });
  //         paths.categories.push({ name: Item.name, pages: pages });
  //         Tree.push(tree);
  //         break;
  //     }
  //   } else {
  //     if (item.contentTypeAlias === `popMessages`) {
  //       // PopMessages.contentTypeAlias = item.contentTypeAlias;
  //       // PopMessages.name = item.name;
  //       // PopMessages.messages = item.messages;
  //     }
  //   }
  // });

  // return {
  //   data: { paths, Tree, categories },
  //   updates: updates,
  // };
};

export const GetCurrentContentByTree = (
  urlPage: string,
  treeData: ITreeData
) => {
  let categoryIndex = -1;
  let pageIndex = -1;
  let contentTypeAlias = "";

  if (treeData.paths) {
    for (
      let category = 0;
      category < treeData.paths.categories.length;
      category++
    ) {
      const categoryObj = treeData.paths.categories[category];

      for (let page = 0; page < categoryObj.pages.length; page++) {
        const pageObj = categoryObj.pages[page];
        if (pageObj.name?.toLowerCase() === urlPage) {
          pageIndex = page;
          categoryIndex = category;
          contentTypeAlias = pageObj.contentTypeAlias;
          break;
        }
      }

      if (pageIndex > -1 || categoryIndex > -1) {
        break;
      }
    }
    const breadcrumbItems: IBreadCrumbItem[] = [];

    if (pageIndex > -1 && categoryIndex > -1) {
      // category link:
      breadcrumbItems.push({
        text: treeData.paths.categories[categoryIndex].name,
        link: "",
      });

      //current page link:
      breadcrumbItems.push({
        text: treeData.Tree[categoryIndex].pages[pageIndex],
        link: treeData.Tree[categoryIndex].ids[pageIndex].toLowerCase(),
      });

      const selectedContent =
        treeData.categories[categoryIndex].pages[pageIndex];
      if (contentTypeAlias === "staticPage") {
        const staticPage = { ...selectedContent } as unknown as IStaticPage;
        staticPage.breadcrumb = { items: breadcrumbItems };
        return staticPage;
      }
    }
  }
};
