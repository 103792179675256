import { useEffect, useState, useRef } from "react";
import colors from "../Assets/Colors/Colors.json";
import fonts from "../Assets/Fonts/Fonts.json";
import InfoCareer, { IInfoCareer } from "../Components/Modal/InfoCareer";
import Breadcrumb from "../Components/UmbracoElements/Breadcrumb";
import { IStaticPageJob } from "../Interfaces/IStaticPage";
import { StyleSheet } from "../Interfaces/IStyleSheet";
import { IDynamicText } from "../Interfaces/IUmbraco";
import { ChangeTitle, StyleMode } from "../Utils/consts";
import useDeviceMode from "../Utils/useWindowDimensions";
import fontSize from "../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../Services/DeviceService";
import { t } from "i18next";
import Loading from "../Components/Loading/Loading";
import { UmbracoStoreState } from "../Redux/Slices/umbracoSlice";
import { useSelector } from "react-redux";

const StaticPageJob: React.FC<IStaticPageJob> = (props) => {
  const { breadcrumb, jobNumber, isCard = true } = props;
  const deviceMode = useDeviceMode();
  const [_breadcrumbKey, _setBreadcrumbKey] = useState(0);
  const [_data, _setData] = useState<IInfoCareer>();
  const [_pageData, _setPageData] = useState<any>();
  const titleRef = useRef<HTMLDivElement>(null);
  const [_pageTitle, _setPageTitle] = useState("");

  const umbracoStore = (
    useSelector((state: any) => state.UmbracoStoreReducer) as UmbracoStoreState
  ).umbracoStore;

  let isCardDisplay = isCard;

  useEffect(() => {
    if (_data?.title) {
      _setPageTitle(_data.title);
      ChangeTitle(_data.title);
    }
  }, [_data]);
  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.tabIndex = 0;
      titleRef.current.focus();
      titleRef.current.style.outline = "none";
      titleRef.current.tabIndex = -1;
    }
  }, [_pageTitle]);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      // const allData = await GetCareerPage(i18n.language);
      const allData = umbracoStore.career;

      if (isMounted) {
        _setPageData(allData?.pages[0]);
      }
      const elements = allData?.pages[0]?.elements as any[];
      const data = elements.find((item) => {
        if (item.contentTypeAlias === "hotJobs") return item;
      });
      let Jobs = data.jobs as any[];
      let currentJob: any;
      Jobs.map((job) => {
        if (job.jobNumber === jobNumber) currentJob = job;
      });

      if (currentJob) {
        if (isMounted) {
          _setData({
            ...currentJob,
            careersText: data.careersText as IDynamicText[],
          });
        }
      }

      if (isMounted) {
        _setBreadcrumbKey(Math.floor(Math.random() * 100));
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [breadcrumb]);

  return _data ? (
    <>
      <div
        ref={titleRef}
        style={{ position: "absolute", zIndex: -1, color: "transparent" }}
        tabIndex={!IsMobile() ? 0 : -1}
      >
        {t("CurrentPage", {
          page: _pageTitle,
        })}
      </div>
      <div
        style={{
          ...styles.mainContainer,
        }}
      >
        <div
          style={{
            ...styles.cover,
            background:
              _pageData &&
              `30% 30% / cover url(${_pageData?.coverImage?._url!})`,
            height:
              deviceMode === StyleMode.desktop
                ? isCardDisplay
                  ? "40vh"
                  : "20.5vh"
                : "40vh",
            minHeight:
              deviceMode === StyleMode.desktop
                ? isCardDisplay
                  ? "17.18rem"
                  : "14.25rem"
                : "40vh",
          }}
        >
          <div className="cover-color-blur">
            <div
              style={{
                ...styles.coverElements,
                width: deviceMode === StyleMode.desktop ? "80%" : "75%",
              }}
              className="container"
            >
              <div
                style={{
                  ...styles.breadcrumb,
                  paddingTop:
                    deviceMode === StyleMode.desktop ? "2rem" : "4rem",
                }}
              >
                <Breadcrumb
                  items={[
                    {
                      text: _pageData?.title?.text || "",
                      link: _pageData?.id || "",
                    },
                    {
                      text: _data?.title || "",
                      link: "",
                    },
                  ]}
                  key={_breadcrumbKey}
                />
              </div>
              <h1
                style={{
                  ...styles.title,
                  paddingTop:
                    deviceMode === StyleMode.desktop ? "2rem" : "5rem",
                }}
              >
                {_data?.title}
              </h1>
            </div>
          </div>
        </div>
        <div
          // className="card-border"
          style={
            isCardDisplay && deviceMode === StyleMode.desktop
              ? styles.cardBorder
              : styles.cardBorderMobile
          }
        >
          <div
            className="card-body"
            style={
              isCardDisplay && deviceMode === StyleMode.desktop
                ? styles.cardBody
                : styles.cardBodyMobile
            }
          >
            {_data && <InfoCareer {...{ ..._data }} />}
          </div>
        </div>
      </div>
    </>
  ) : (
    <Loading />
  );
};

const styles: StyleSheet = {
  mainContainer: {
    alignItems: "center",
    flexDirection: "column",
    zIndex: "10",
  },
  cardBorder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transform: "translateY(-7.8rem)",
  },
  cardBorderMobile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "3rem",
    padding: "2rem 0",
    backgroundColor: colors.white,
    borderRadius: "0.3125rem",
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.15)",
    minWidth: "80%",
  },
  cardBodyMobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "3rem",
    padding: "2rem 0",
    width: "100%",
  },
  cover: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "40vh",
    minHeight: "17.18rem",
    position: "relative",
  },
  title: {
    color: colors.white,
    fontSize: fontSize.PageTitle,
    lineHeight: "3rem",
    letterSpacing: "0.05625rem",
    textShadow: `2px 2px ${colors.black}`,
    fontFamily: fonts.FbReformaMedium,
    paddingTop: "3.25rem",
  },
  breadcrumb: {
    display: "flex",
    color: colors.white,
    fontSize: fontSize.Text,
    letterSpacing: "0.05625rem",
    fontFamily: fonts.FbReformaRegular,
    position: "absolute",
  },
  coverElements: {
    gap: "3rem",
    maxWidth: "76rem",
    margin: "auto",
    lineHeight: "2.5rem",
    padding: "1rem",
  },
  content: {
    position: "relative",
    paddingTop: "5rem",
    right: "25%",
    width: "50%",
  },
  titlesAndDescriptionsItems: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },
  linkItems: {
    cursor: "pointer",
  },
};

export default StaticPageJob;
