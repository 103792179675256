import { useState } from "react";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import Images from "../../../Data/Images/Images";
import { IInputTextForm } from "../../../Interfaces/IForm";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../Services/DeviceService";

const InputTextSearchForm: React.FC<IInputTextForm> = (props) => {
  const { placeholder, onChange, maxWidth, accessibilityText } = props;
  const [_isFocus, _setIsFocus] = useState(false);

  return (
    <div
      style={{
        ...styles.container,
        border: `0.0625rem solid ${
          _isFocus ? colors.darkBlue : colors.pastelGray
        }`,
        maxWidth: maxWidth ? maxWidth : "18.6rem",
      }}
    >
      <img src={Images.siteSearch} style={styles.searchIcon}></img>
      <input
        type={"text"}
        placeholder={placeholder}
        style={styles.input}
        onClick={() => _setIsFocus(true)}
        onFocusCapture={() => _setIsFocus(true)}
        onBlur={() => _setIsFocus(false)}
        onChange={(e) => onChange(e.target.value)}
        onKeyPress={() => _setIsFocus(true)}
        tabIndex={!IsMobile() ? 0 : -1}
      />
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "2.5rem",
    borderRadius: "1.25rem",
    gap: ".3rem",
    padding: "0 .5rem",
    position: "relative",
  },
  input: {
    width: "80%",
    fontSize: fontSize.Text,
    color: colors.pasterlGrayDark,
    letterSpacing: "0.043125rem",
    fontFamily: fonts.FbReformaRegular,
  },
  searchIcon: {
    backgroundRepeat: "no-repeat",
  },
};

export default InputTextSearchForm;
