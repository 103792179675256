import { IArticleWithMedia } from "../../../../Interfaces/IMagazinePage";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import ArticleTitleAndText from "../Components/ArticleTitleAndText";
import ImageCover from "../Components/ImageCover";

const SelectedArticleGalleryItem: React.FC<IArticleWithMedia> = (props) => {
  const { media, videoThumbnail, onOpenModal, anchor } = props;

  return (<div style={styles.container}>
    <div style={styles.imageContainer}>
      <ImageCover media={media} videoThumbnail={videoThumbnail} onClick={() => {
        onOpenModal && onOpenModal(props);
      }} iconNotInMiddle={true} />
    </div>
    <ArticleTitleAndText {...props} title={props.titleAndDescription.title} onClick={() => onOpenModal && onOpenModal(props)} />
  </div>)
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    justifyContent: "center",
  },
  imageContainer: {
    position: "relative",
    borderRadius: "0.5rem",
    overflow: "hidden",
  },
};

export default SelectedArticleGalleryItem;
