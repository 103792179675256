import colors from "../../../../Assets/Colors/Colors.json";
import fonts from "../../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../../Assets/Fonts/FontsSizes.json";
import { IDirectorateMemberModal } from "../../../../Interfaces/IDirectorateMember";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";

const DirectorateMemberModal: React.FC<IDirectorateMemberModal> = (props) => {
  const {
    fullname,
    role,
    image,
    description,
    birthYear,
    education,
    workExperience,
  } = props;

  return (
    <div
      style={{
        ...styles.container,
        display: "flex",
      }}
    >
      <img src={image._url} style={styles.image} />
      <div style={styles.memberInfo}>
        <div style={styles.header}>
          <h2 style={styles.title}>{fullname}</h2>
          <h3 style={styles.role}>{role}</h3>
          <div
            style={styles.description}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
        <div style={styles.description}>
          <div style={styles.subTitle}>
            {birthYear.title}
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: birthYear.description }}
          ></div>
        </div>
        <div style={styles.description}>
          <div style={styles.subTitle}>
            {education.title}
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: education.description }}
          />
        </div>
        <div style={styles.description}>
          <div style={styles.subTitle}>
            {workExperience.title}
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: workExperience.description }}
          />
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    justifyContent: "center",
    height: "100%",
    position: "relative",
    width: "100%"
  },

  memberInfo: {
    padding: "2rem",
    maxHeight: "100%",
    overflow: "auto",
    flex: 1
  },
  header: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  title: {
    fontSize: fontSize.H1,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    letterSpacing: "0.065rem",
    marginBottom: "0",
    lineHeight: "2rem"
  },
  subTitle: {
    fontFamily: fonts.FbReformaMedium,
    marginBottom: `0.25rem`,
  },
  role: {
    fontSize: fontSize.UmbracoElementTitle,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    letterSpacing: "0.0425rem",
    marginBottom: "0",
  },
  description: {
    fontSize: fontSize.FooterItem,
    fontFamily: fonts.FbReformaLight,
    letterSpacing: "0.034375rem",
    maxWidth: "33rem",
    marginBottom: "1rem",
  },
  image: {
    flex: "1",
    objectFit: "cover"
  },
};

export default DirectorateMemberModal;
