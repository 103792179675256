import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import { StyleMode } from "../../../../Utils/consts";
import useDeviceMode from "../../../../Utils/useWindowDimensions";
import colors from "../../../../Assets/Colors/Colors.json";
import fonts from "../../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../../Assets/Fonts/FontsSizes.json";
type ILinkTo = {
    redirectTo: string,
    text: string,
}
const LinkTo: React.FC<ILinkTo> = ({ redirectTo, text }) => {
    const [_isLinkOver, _setIsLinkOver] = useState(false);
    const deviceMode = useDeviceMode();
    const navigate = useNavigate();

    const redirectToPage = () => { navigate(`?page=${redirectTo}`); }

    return (<div
        role={"link"}
        tabIndex={0}
        style={{
            ...styles.link,
            textDecoration: _isLinkOver ? "underline" : "",
            textAlign: deviceMode === StyleMode.desktop ? "end" : "start"
        }}
        onMouseOver={() => _setIsLinkOver(true)}
        onMouseLeave={() => _setIsLinkOver(false)}
        onClick={redirectToPage}>
        {text} <span aria-hidden={"true"}>{">"}</span>
    </div>);
};
const styles: StyleSheet = {
    link: {
        color: colors.darkCyan,
        cursor: "pointer",
        fontFamily: fonts.FbReformaRegular,
        fontSize: fontSize.Paragraph,
        paddingTop: "2rem"
    },
};

export default LinkTo;
