import { t } from "i18next";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import store from "store2";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import Config from "../../Configuration/server";
import Images from "../../Data/Images/Images";
import { IStation } from "../../Interfaces/IStation";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { ITrain } from "../../Interfaces/ITrain";
import { ScheduleState } from "../../Redux/Slices/scheduleSlice";
import { SystemParamsState } from "../../Redux/Slices/systemParamsSlice";
import { UmbracoStoreState } from "../../Redux/Slices/umbracoSlice";
import {
  FormatHoursMinutes,
  GetDurationFormatted,
  GetDurationTimeMS,
} from "../../Services/DateService";
import { IsMobile } from "../../Services/DeviceService";
import i18n from "../../Services/i18n";
import { StyleMode } from "../../Utils/consts";
import { GetStationsDictionary } from "../../Utils/helpers";
import useDeviceMode from "../../Utils/useWindowDimensions";
import CustomModal from "../CustomModal";
import Fare from "../PaymentAndDiscounts/Fare";
import TravelMessages from "./TravelMessages/TravelMessages";

const TrainCard: React.FC<ITrain> = (props) => {
  const {
    trainNumber,
    originPlatform,
    destPlatform,
    arrivalTime,
    departureTime,
    changes,
    isSelected,
    index,
    totalTrains,
    isPreviewOnly = false,
    trainPosition,
    travelMessages,
    onAddToCalendar,
  } = props;
  const timeDifferent = trainPosition?.calcDiffMinutes;
  const [_cardHight, _setCardHight] = useState(`9rem`);
  const paymentMassageRef = useRef<HTMLHeadingElement>(null);
  const umbracoStore = (
    useSelector((state: any) => state.UmbracoStoreReducer) as UmbracoStoreState
  ).umbracoStore;
  useEffect(() => {
    if (i18n.language === `ar`) {
      _setCardHight(`11rem`);
    } else if (i18n.language === `ru`) {
      _setCardHight(`11rem`);
    } else {
      _setCardHight(`9rem`);
    }
  }, [i18n.language]);

  const [_isPurchaseCardModalVisible, _setIsPurchaseCardModalVisible] =
    useState(false);

  const deviceMode = useDeviceMode();

  const [_isOver, _setIsOver] = useState(false);
  const [_notificationHover, _setNotificationHover] = useState(false);
  const trainCardRef = useRef<HTMLDivElement>(null);
  const purchaseButtonRef = useRef<HTMLButtonElement>(null);

  const [_arrowsDirections, _setArrowsDirections] = useState(
    Images.icon_route_way_rtl
  );
  const [_upperArrowConHeight, _setUpperArrowConHeight] = useState("");
  const [_lowerArrowConHeight, _setLowerArrowConHeight] = useState("");

  const upperContainerArrowRef = useRef<HTMLDivElement>(null);
  const lowerContainerArrowRef = useRef<HTMLDivElement>(null);

  const durationInMs = GetDurationTimeMS(
    new Date(departureTime),
    new Date(arrivalTime)
  );
  const durationFormated = GetDurationFormatted(durationInMs);
  const totalTimeFormated = `${durationFormated} ${t(
    durationFormated.includes(":") ? "Hrs" : "Min"
  )}`;

  const systemParams = (
    useSelector((state: any) => state.SystemParamsReducer) as SystemParamsState
  ).systemParams;
  const systemParamsDictionary = systemParams.reduce(
    (result: { [key: string]: string }, current) => {
      result[current.key] = current.value;
      return result;
    },
    {}
  );

  const stations: IStation[] = store.session.get(
    `${Config.BASE_LOCALSTORAGE_NAME}stations`
  )[i18n.language];

  const schedule = useSelector(
    (state: any) => state.ScheduleReducer
  ) as ScheduleState;

  const station = GetStationsDictionary(stations);

  useEffect(() => {
    if (_isPurchaseCardModalVisible) {
      setTimeout(() => {
        if (paymentMassageRef.current) {
          paymentMassageRef.current.focus();
        }
      }, 100);
    }
  }, [_isPurchaseCardModalVisible]);

  useEffect(() => {
    if (upperContainerArrowRef.current) {
      const height =
        upperContainerArrowRef.current.getBoundingClientRect().height;
      const heughtStringPx = height.toString() + "px";
      _setUpperArrowConHeight(heughtStringPx);
    }
    if (lowerContainerArrowRef.current) {
      const height =
        lowerContainerArrowRef.current.getBoundingClientRect().height;
      const heughtStringPx = height.toString() + "px";
      _setLowerArrowConHeight(heughtStringPx);
    }

    i18n.dir() === "rtl"
      ? _setArrowsDirections(Images.icon_route_way_rtl)
      : _setArrowsDirections(Images.icon_route_way_ltr);
  }, []);

  return (
    <Fragment>
      <div
        style={{
          ...styles.mainWrapper,
          maxWidth: "45rem",
        }}
      >
        <div
          ref={trainCardRef}
          className="train_card"
          id={`train_card_${index}`}
          onMouseOver={() => {
            _setIsOver(true);
          }}
          onMouseLeave={() => {
            _setIsOver(false);
          }}
          onKeyDown={(e) => {
            if (e.key === "ArrowDown") {
              if (index !== undefined) {
                const currentCardHeight = document
                  .getElementById(`train_card_${index}`)!
                  .getBoundingClientRect().height;

                const nextCard = document.getElementById(
                  `train_card_${index + 1}`
                );
                if (trainCardRef.current) {
                  trainCardRef.current.tabIndex = -1;
                }
                if (nextCard) {
                  nextCard.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                  nextCard.tabIndex = 0;
                  nextCard.focus();
                  nextCard.click();
                  if (purchaseButtonRef.current) {
                    purchaseButtonRef.current.tabIndex = -1;
                  }
                  const mainScroll = document.getElementById("mainScroll")!;
                  mainScroll.scrollTo(0, 0);
                  let timeout = setTimeout(() => {
                    nextCard.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                    clearTimeout(timeout);
                  }, 0);
                }
              }
            }
            if (e.key === "ArrowUp") {
              if (index !== undefined) {
                const currentCardHeight = document
                  .getElementById(`train_card_${index}`)!
                  .getBoundingClientRect().height;

                const previousCard = document.getElementById(
                  `train_card_${index - 1}`
                );
                if (trainCardRef.current) {
                  trainCardRef.current.tabIndex = -1;
                }
                if (previousCard) {
                  previousCard.tabIndex = 0;
                  previousCard.focus();
                  previousCard.click();
                  if (purchaseButtonRef.current) {
                    purchaseButtonRef.current.tabIndex = -1;
                  }
                  const mainScroll = document.getElementById("mainScroll")!;
                  mainScroll.scrollTo(0, 0);
                  let timeout = setTimeout(() => {
                    previousCard.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                    clearTimeout(timeout);
                  }, 0);
                }
              }
            }

            if (e.key === "Enter" || e.key === "Space") {
              const routeDetails = document.getElementById("route-details");
              if (routeDetails) {
                routeDetails.focus();
              }
            }
            if (e.key === "Tab" && !e.shiftKey) {
              if (purchaseButtonRef.current) {
                purchaseButtonRef.current.tabIndex = 0;
              }
              if (index !== undefined && totalTrains !== undefined) {
                if (index !== totalTrains - 1) {
                }
              }
            }
          }}
          style={{
            // ...styles.mainWrapper,
            cursor: isPreviewOnly ? "" : "pointer",
            height: `${_cardHight}`,
            minWidth: deviceMode === StyleMode.desktop ? "45rem" : "unset",
            border: !isSelected
              ? `.1px solid ${colors.pastelGray}`
              : `4px solid ${colors.darkBlueHalf}`,
            boxShadow: !isSelected
              ? `0 2px 10px 0 rgba(0, 0, 0, 0.15)`
              : `rgb(0 0 0 / 55%) 0px 2px 7px 3px`,
            // backgroundColor: !_isOver ? colors.white : colors.pattensBlueLighter,
          }}
          data-active={isSelected}
          aria-checked={isSelected}
          role="radio"
          aria-label={t("FullTrainDetails", {
            trainNumber,
            fromStation:
              schedule.fromStation && station[schedule.fromStation].stationName,
            toStation:
              schedule.toStation && station[schedule.toStation].stationName,
            departureTime: FormatHoursMinutes(departureTime),
            platform: originPlatform,
            changes,
          })}
          tabIndex={isPreviewOnly ? -1 : !IsMobile() ? 0 : -1}
        >
          <div style={styles.header}>
            <div style={styles.title}>
              <img
                src={Images.trainInCard}
                aria-label={`${t("Train-Number")} ,`}
                style={{
                  filter: "brightness(.2)",
                  transform: `${
                    i18n.dir() === "rtl" ? "scaleX(1)" : "scaleX(-1)"
                  }`,
                }}
              />{" "}
              {` ${trainNumber}`}
            </div>
            <div style={styles.icons}>
              {systemParamsDictionary["addCalendarDisplay"] !== undefined &&
                systemParamsDictionary["addCalendarDisplay"] === "true" && (
                  <img
                    src={Images.calendar}
                    width={"20"} //addCalendarDisplay
                    onClick={() => {
                      onAddToCalendar && onAddToCalendar(index!, { ...props });
                    }}
                  />
                )}
              {/* {t("AddToCalendarTitle")} */}
              <img
                src={Images.handicap}
                aria-label={`${t(`Accessible-train`)} ,`}
                style={{
                  filter: "brightness(.2)",
                }}
              />
            </div>
          </div>
          {trainPosition?.calcDiffMinutes &&
          trainPosition.calcDiffMinutes !== 0 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: ".7rem",
              }}
            >
              <div style={styles.delToolTip}>
                <span style={styles.del}>
                  {`(${
                    i18n.language === `en` || i18n.language === `ru`
                      ? `${t(`display_minutes`, {
                          minute: `+${timeDifferent}`,
                        })}`
                      : ""
                  }${
                    i18n.language === `en` || i18n.language === `ru`
                      ? ``
                      : `${t(`display_minutes`, {
                          minute: `${timeDifferent}+`,
                        })}`
                  })`}
                </span>
              </div>
              <img
                src={Images.information}
                width={"17"}
                style={{
                  marginBottom: "-0.25rem",
                }}
                title={t("delayInfo")}
              />
            </div>
          ) : null}
          <div style={styles.body}>
            <div style={styles.timeWrapper}>
              <div style={styles.departureArrivalInfo}>
                <div
                  ref={upperContainerArrowRef}
                  style={styles.departureArrivalTime}
                >
                  {FormatHoursMinutes(departureTime)}
                </div>
                <div ref={lowerContainerArrowRef} style={styles.platform}>
                  {`${
                    originPlatform === 0
                      ? t(`NoSetPlatformNumber`)
                      : `${t("Platform")} ${originPlatform}`
                  }`}
                </div>
              </div>

              <div>
                <div style={styles.timeLengthInfo}>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div style={styles.totalTime}>
                      <div style={styles.linearGradient}></div>
                      {totalTimeFormated}
                      <div
                        style={{
                          ...styles.linearGradient,
                          backgroundImage:
                            "linear-gradient(90deg,hsla(0,0%,91%,0),#cdcdcd)",
                          right: i18n.dir() === "rtl" ? "100%" : "",
                          left: i18n.dir() === "ltr" ? "-75%" : "",
                        }}
                      ></div>
                    </div>
                  </div>
                  <div style={styles.totalChanges}>
                    {t("Change", { count: changes })}
                  </div>
                </div>
              </div>

              <div style={styles.departureArrivalInfo}>
                <div style={styles.departureArrivalTime}>
                  {FormatHoursMinutes(arrivalTime)}
                </div>
                <div style={styles.platform}>
                  {`${
                    destPlatform === 0
                      ? t(`NoSetPlatformNumber`)
                      : `${t("Platform")} ${destPlatform}`
                  }`}
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: ".5rem",
              }}
            >
              <button
                style={{
                  ...styles.prices,
                  cursor: isPreviewOnly ? "" : "pointer",
                }}
                onClick={() => {
                  _setIsPurchaseCardModalVisible(true);
                }}
              >
                {t("prices")}
              </button>
            </div>
          </div>
          {!isPreviewOnly && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0 2rem",
                marginBottom: ".5rem",
              }}
            ></div>
          )}
        </div>
        {travelMessages && travelMessages.length > 0 && (
          <TravelMessages travelMessages={travelMessages} />
        )}
      </div>
      <CustomModal
        visible={_isPurchaseCardModalVisible}
        icon={Images.fares}
        onClose={() => {
          _setIsPurchaseCardModalVisible(false);
        }}
        showCloseButton="internal"
        contentStyle={{ padding: 0 }}
        innerStyle={{
          borderRadius: "13px 13px 300px 300px / 10px 10px 75px 75px",
          minHeight: "50vh",
          minWidth: "40%",
        }}
      >
        <Fare
          title={umbracoStore.popUpFares?.title}
          fromStation={props.orignStation}
          toStation={props.myDestanation ? props.myDestanation : 0}
        />
      </CustomModal>
    </Fragment>
  );
};

const styles: StyleSheet = {
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.white,
    justifyContent: "center",
    // maxWidth: Math.min(55*parseFloat(getComputedStyle(document.documentElement).fontSize),window.innerWidth*0.8),
    // width: "100%",
    borderRadius: "0.625rem",
    cursor: "pointer",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    // borderBottom: `.1rem solid ${colors.pastelGray}`,
    backgroundColor: colors.ghostWhite,
    padding: ".5rem",
  },
  title: {
    color: colors.gray,
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Text,
    display: "flex",
    alignItems: "center",
    gap: ".5rem",
  },
  body: {
    display: "flex",
    alignItems: "center",
    padding: "1rem",
    gap: "5rem",
    justifyContent: "space-around",
  },
  departureArrivalInfo: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    minWidth: "5rem",
    gap: ".5rem",
  },
  departureArrivalTime: {
    fontSize: fontSize.UmbracoElementTitle,
    fontFamily: fonts.FbReformaMedium,
  },
  timeLengthInfo: {
    display: "flex",
    flexDirection: "column",
    gap: ".5rem",
  },
  totalTime: {
    border: `.1rem solid ${colors.pastelGray}`,
    borderRadius: "2rem",
    padding: "0 1rem",
    minWidth: "4rem",
    textAlign: "center",
    position: "relative",
  },
  displayRoute: {
    display: "flex",
    gap: "1rem",
    color: colors.darkCyan,
    fontWeight: "bold",
    minWidth: "7rem",
  },
  totalChanges: {
    color: colors.oldLavender,
    display: "flex",
    justifyContent: "center",
  },
  timeWrapper: {
    display: "flex",
    gap: "5rem",
    alignItems: "center",
  },
  platform: {
    color: colors.oldLavender,
    fontFamily: fonts.FbReformaRegular,
  },
  icons: {
    display: "flex",
    marginInlineStart: "1rem",
    marginInlineEnd: "1rem",
    gap: "1rem",
  },
  handicapIcon: {
    backgroundRepeat: "no-repeat",

    transform: "scale(1)",
  },
  linearGradient: {
    height: "2px",
    width: "4.5rem",
    top: "50%",
    left: "100%",
    position: "absolute",
    backgroundImage: "linear-gradient(90deg, #cdcdcd, hsla(0,0%,91%,0))",
  },
  del: {
    color: colors.bittersweet,
    fontSize: fontSize.Text,
    marginInlineStart: `1rem`,
  },
  delToolTip: {
    display: "flex",
    alignItems: "baseline",
    gap: ".4rem",
  },
  prices: {
    borderRadius: "1rem",
    backgroundColor: colors.white,
    border: `.1rem solid ${colors.darkBlue}`,
    color: colors.darkBlue,
    fontSize: fontSize.Text,
    width: "10rem",
    height: "2.2rem",
    cursor: "pointer",
    fontFamily: fonts.FbReformaMedium,
  },
  calendar: {
    borderRadius: "1rem",
    backgroundColor: colors.white,
    border: `.1rem solid ${colors.darkBlue}`,
    color: colors.darkBlue,
    fontSize: fontSize.Text,
    minWidth: "10rem",
    cursor: "pointer",
    fontFamily: fonts.FbReformaMedium,
  },
  rightArrow: {
    gap: "0.5rem",
    display: "flex",
    flexDirection: "column",
  },
};

export default TrainCard;
