import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import Tag from "../../../Tag/Tag";
const Tags: React.FC<{ tags: string[], style?: React.CSSProperties }> = ({ tags, style }) => {
    return (
        <div style={{ ...styles.tags, ...style }}>
            {tags.map((tag, index) => (
                <Tag key={index} text={tag} />
            ))}
        </div>

    );
};
const styles: StyleSheet = {
    tags: {
        display: "flex",
        gap: "1rem",
        paddingBlock: "1rem",
    },
};

export default Tags;
