import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { ITitleSubjectAndDetails } from "../../Interfaces/IBaseElement";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { replaceRichtextBoldWithFont } from "../../Utils/utils";

const TitleSubjectAndDetails: React.FC<ITitleSubjectAndDetails> = (props) => {
  const { title, details, subject } = props;

  return (
    <div style={styles.wapper}>
      <div style={styles.contentTop}>
        <div style={styles.title}>{title}</div>
        {subject && <div style={styles.text}>{subject}</div>}
      </div>
      {details && <div
        style={styles.text}
        dangerouslySetInnerHTML={{ __html: replaceRichtextBoldWithFont(details) }}
      />}
    </div>
  );
};

const styles: StyleSheet = {
  wapper: {
    borderInlineStart: `medium solid ${colors.koromiko}`,
    paddingInlineStart: "0.5rem",
    lineHeight: "normal",
  },
  contentTop: {
    paddingBottom: "0.5rem"
  },
  title: {
    fontFamily: fonts.FbReformaMedium,
    color: colors.black,
    fontSize: fontSize.Text,
    lineHeight: "normal",

  },
  text: {
    fontFamily: fonts.FbReformaRegular,
    color: colors.black,
    lineHeight: "normal",
    fontSize: fontSize.timeDifferent,
  },
};

export default TitleSubjectAndDetails;
