import moment from "moment";
import QueryString from "qs";
import { Fragment, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import store from "store2";
import Config from "../../Configuration/server";
import { ITravel } from "../../Interfaces/ITravel";
import {
  ScheduleState,
  setDate,
  setHours,
  setMinutes
} from "../../Redux/Slices/scheduleSlice";
import {
  FormatDateYYYYMMDD,
  GetDateAsTextFormat,
  IsToday
} from "../../Services/DateService";
import i18n from "../../Services/i18n";
import { AddTravelToLastTravels } from "../../Services/LastTravelsService";
import { SearchTrainLuz } from "../../Services/RjpaService";
import { ChangeTitle, StyleMode, TitlePage } from "../../Utils/consts";
import { GetStationsDictionary } from "../../Utils/helpers";
import { GetSystemOS } from "../../Utils/useDeviceOS";
import useDeviceMode from "../../Utils/useWindowDimensions";
import { QueryStringWorking } from "../../Utils/utils";
import RoutePlanSearchResultsMobile from "./Mobile/RoutePlanSearchResultsMobile";
import RoutePlanSearchResults from "./RoutePlanSearchResults";

const RoutePlanSearchResultsMain: React.FC = () => {
  const deviceMode = useDeviceMode();

  const [_Alltravels, _setAllTravels] = useState<{ [key: string]: ITravel[] }>(
    {}
  );
  let [_tabSelected, _setTabSelected] = useState<string>("");

  const [_travels, _setTravels] = useState<ITravel[]>([]);
  const [_noTrains, _setNoTrains] = useState(false);
  const [_isLoading, _setIsLoading] = useState(true);
  const [_selectedTravel, _setSelectedTravel] = useState<ITravel>();
  const [_selectedTrainKey, _setSelectedTrainKey] = useState("");
  const [_dateTitle, _setDateTitle] = useState("");
  const [_isToday, _setIsToday] = useState(false);
  const isNextPreviousDayClickedRef = useRef(false);

  const numOfResultsToShow = useRef(0);
  const clientMessageId = useRef(1);
  const startFromIndex = useRef(0);
  const onFocusIndex = useRef(0);
  const noTrainsLastDate = useRef<Date>();
  const noTrainsNextDate = useRef<Date>();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const schedule = useSelector(
    (state: any) => state.ScheduleReducer
  ) as ScheduleState;

  const scheduleBeforeChanges = useRef(schedule);
  const isMounted = useRef(false);

  const SearchBarParamsConvertToArray = async (object: any[]) => {
    let _Schedules: any = {};
    for (let index = 0; index < object.length; index++) {
      for (let j = 0; j < Object.keys(object[index]).length; j++) {
        const element: any = Object.keys(object[index])[j];
        if (_Schedules[element]) {
          _Schedules[element] =
            _Schedules[element] + "," + object[index][element];
        } else {
          _Schedules = {
            ..._Schedules,
            [element]: object[index][element],
          };
        }
      }
    }
    navigate(`?page=routePlanSearchResultsMulti&${QueryStringWorking(_Schedules)}`, {
      replace: true,
    });
  };
  const SearchBarParams = async () => {
    let url = new URLSearchParams(window.location.search);
    let params: any = {
      fromStation: url.get("fromStation")?.split(","),
      toStation: url.get("toStation")?.split(","),
      date: url.get("date")?.split(","),
      hours: url.get("hours")?.split(","),
      minutes: url.get("minutes")?.split(","),
      scheduleType: url.get("scheduleType")?.split(","),
    };
    let _Schedules = [];
    // AllTravels = {
    //   ...AllTravels,
    //   [`${element.fromStation}-${element.toStation}-${index}`]: res,
    // };
    let AllTravels = {}
    for (let index = 0; index < params.fromStation.length; index++) {
      let schedule = {
        fromStation: params.fromStation[index],
        toStation: params.toStation[index],
        date: params.date[index],
        hours: params.hours[index],
        minutes: params.minutes[index],
        scheduleType: params.scheduleType[index],
      };
      let res = await getSearchResults("searchTrainLuzForDateTime", schedule);
      AllTravels = {
        ...AllTravels,
        [`${schedule.fromStation}-${schedule.toStation}-${index}`]: res,
      };
      let stations = GetStationsDictionary(
        store.session.get(`${Config.BASE_LOCALSTORAGE_NAME}stations`)[
          i18n.language
        ]
      );
      let title = t(`${TitlePage.Resultes}`)
        ?.replace(`$`, `${stations[schedule.fromStation].stationName}`)
        .replace(`#`, `${stations[schedule.toStation].stationName}`)
        .replace(`&`, `${schedule.date} ${schedule.hours}:${schedule.minutes}`);
      ChangeTitle(title);
      _setAllTravels(AllTravels)
      _Schedules.push(schedule);
    }
    return _Schedules;
  };
  useEffect(() => {
    isMounted.current = true;
    (async () => {
      SearchBarParams();
    })();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const setTheResult = (result: any, currentIndex?: number) => {
    let _currentIndex = currentIndex ? currentIndex : 0
    // const {currentIndex} = 
    numOfResultsToShow.current = result.numOfResultsToShow;
    clientMessageId.current = result.clientMessageId;
    startFromIndex.current = result.startFromIndex;
    onFocusIndex.current = result.onFocusIndex;

    if (isMounted.current) {
      _setTravels(result.travels);
    }
    scheduleBeforeChanges.current = schedule;

    // Check if No Trains:
    if (result.clientMessageId > 1) {
      if (isMounted.current) {
        _setNoTrains(true);
      }

      if (result.travels.length > 0) {
        //Set the no trains last date, for example (הרכבת האחרונה להיום בשעה 20:51)
        noTrainsLastDate.current = new Date(
          schedule.scheduleType === 1
            ? result.travels[result.onFocusIndex].departureTime
            : result.travels[result.onFocusIndex].arrivalTime
        );

        //Set the no trains Next date, for example (הרכבת הבאה תצא מחר בשעה 05:45)
        noTrainsNextDate.current = new Date(
          schedule.scheduleType === 1
            ? result.travels[result.onFocusIndex + 1].departureTime
            : result.travels[result.onFocusIndex + 1].arrivalTime
        );
      }
    } else {
      if (isMounted.current) {
        _setNoTrains(false);
      }
    }

    if (result.travels.length > 0) {
      // update store with updated time from server:
      const departureArrivalDate = new Date(
        schedule.scheduleType === 1
          ? result.travels[result.onFocusIndex].departureTime
          : result.travels[result.onFocusIndex].arrivalTime
      );
      dispatch(setDate(FormatDateYYYYMMDD(departureArrivalDate)));
      // dispatch(setMinutes(departureArrivalDate.getMinutes().toString()));
      // dispatch(setHours(departureArrivalDate.getHours().toString()));

      // Set the title text: for example (רכבות מתאימות 24.3.2022, בסביבות 10:45)
      if (isMounted.current) {
        if (!isNextPreviousDayClickedRef.current) {
          _setDateTitle(
            `${GetDateAsTextFormat(departureArrivalDate)}, ${t("Around")} ${
              schedule.hours.split(",")[_currentIndex]
            }:${schedule.minutes.split(",")[_currentIndex]}`
          );
        } else {
          _setDateTitle(`${GetDateAsTextFormat(departureArrivalDate)}`);
        }

        _setIsToday(IsToday(departureArrivalDate));
      }
      const selectedTravel = result.travels[result.onFocusIndex];
      handleSelectedTrainCard(selectedTravel);
      scrollToSelectedTravelCard();
    }
    _setIsLoading(false);
  };
  const getSearchResults = async (
    dayToSearch:
      | "searchTrainLuzForDateTime"
      | "searchTrainLuzAfterDate"
      | "searchTrainLuzBeforeDate",
    scheduleParams: ScheduleState
  ) => {
    const result = await SearchTrainLuz(scheduleParams, dayToSearch);

    if (isMounted.current) {
      _setIsLoading(false);
    }
    if (result) {
      setTheResult(result);
    }
    return result
  };

  const scrollToSelectedTravelCard = () => {
    // if(!IsMobile()){
    let timeout = setTimeout(() => {
      const selectedTravelCard = document.querySelector("[data-active='true']");
      const travelsContainer = document.getElementById("travelsContainer");

      if (selectedTravelCard) {
        const OS = GetSystemOS();
        const browserZoom =
          Math.round(window.devicePixelRatio * 100) / (OS !== `Mac` ? 1 : 2);
        selectedTravelCard.scrollIntoView({ block: "center" });
        if (browserZoom === 200 || isMobile) {
          (selectedTravelCard as any).scrollIntoViewIfNeeded(true);
          window.scrollTo(0, 0);
        } else {
          selectedTravelCard.scrollIntoView({ block: "center" });
          const mainScroll = document.getElementById("mainScroll")!;
          mainScroll.scrollTo(0, 0);
        }
      }
    }, 250);
    return () => {
      clearTimeout(timeout);
    };
    // }
  };

  // when = nextDay or previousDay
  const getTrainsNextOrPrevious = async (
    searchType: "searchTrainLuzAfterDate" | "searchTrainLuzBeforeDate"
  ) => {
    const newDate = FormatDateYYYYMMDD(moment(schedule.date).add(1).toDate());
    dispatch(setDate(newDate));
    const updatedSchedule = { ...schedule };
    _setIsLoading(true);

    await getSearchResults(searchType, updatedSchedule);
  };

  const handleSelectedTrainCard = (travel: ITravel) => {
    if (isMounted.current) {
      _setSelectedTrainKey(
        `${travel.trains[0].arrivalTime}-${travel.trains[0].departureTime}`
      );
      _setSelectedTravel(travel);
    }
  };

  const handleNoTrainsButtonClick = (buttonType: string) => {
    /* buttonType can be lastTrain or nextTrain  */
    _setNoTrains(false);

    if (buttonType === "lastTrain") {
      let travels = [..._travels];
      travels = travels.slice(0, onFocusIndex.current + 1);
      _setTravels(travels);
      scrollToSelectedTravelCard();
    } else if (buttonType === "nextTrain") {
      let travels = [..._travels];
      travels = travels.slice(onFocusIndex.current + 1, travels.length);
      if (noTrainsNextDate.current) {
        dispatch(setDate(FormatDateYYYYMMDD(noTrainsNextDate.current)));
        dispatch(setHours(noTrainsNextDate.current.getHours().toString()));
        dispatch(setMinutes(noTrainsNextDate.current.getMinutes().toString()));
        _setDateTitle(
          `${GetDateAsTextFormat(noTrainsNextDate.current)}, ${t("Around")} ${
            schedule.hours
          }:${schedule.minutes}`
        );
      }
      _setTravels(travels);
    }
  };

  return (
    <Fragment>
      {deviceMode === StyleMode.desktop ? (
        <RoutePlanSearchResults
          multisearch={true}
          travels={_travels}
          isNoTrains={_noTrains}
          isLoading={_isLoading}
          selectedTravel={_selectedTravel}
          noTrainsInfo={{
            clientMessageId: clientMessageId.current,
            lastTrainDate: noTrainsLastDate.current,
            nextTrainDate: noTrainsNextDate.current,
            onClick: handleNoTrainsButtonClick,
          }}
          selectedTrainKey={_selectedTrainKey}
          dateTitle={_dateTitle}
          isToday={_isToday}
          onClickTravelCard={handleSelectedTrainCard}
          getDay={(searchType) => getTrainsNextOrPrevious(searchType)}
          onSearchMulti={async (listSearch) => SearchBarParamsConvertToArray(listSearch)}
          onTabClick={(id) => {
            _setTabSelected(id);
            setTheResult(_Alltravels[id],Number(id.split("-").pop()));
          }}
          onClickRoutePlanBarSearch={() => {
            _setIsLoading(true);
            isNextPreviousDayClickedRef.current = false;
            const params = QueryString.stringify(schedule);
            navigate(`?page=routePlanSearchResults&${params}`, {
              replace: true,
            });
            AddTravelToLastTravels(schedule);
            // getSearchResults("currentDay", schedule);
          }}
        />
      ) : (
        <RoutePlanSearchResultsMobile
          travels={_travels}
          isNoTrains={_noTrains}
          isLoading={_isLoading}
          selectedTravel={_selectedTravel}
          noTrainsInfo={{
            clientMessageId: clientMessageId.current,
            lastTrainDate: noTrainsLastDate.current,
            nextTrainDate: noTrainsNextDate.current,
            onClick: handleNoTrainsButtonClick,
          }}
          selectedTrainKey={_selectedTrainKey}
          onClickTravelCard={handleSelectedTrainCard}
          isToday={_isToday}
          getDay={(when) => getTrainsNextOrPrevious(when)}
        />
      )}
    </Fragment>
  );
};

export default RoutePlanSearchResultsMain;
