import { t } from "i18next";
import { Fragment, useEffect, useRef, useState } from "react";
import Images from "../../../Data/Images/Images";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { IPodcast, IPodcasts } from "../../../Interfaces/IUmbraco";
import CustomModal from "../../CustomModal";
import InputTextSearchForm from "../../Form/InputTextSearchForm/InputTextSearchForm";
import SocialShare from "../../SocialShare/SocialShare";
import FileElement from "../FileElement/FileElement";
import Title from "../Title";
import PlayingPodcast from "./PlayingPodcast";
import Podcast from "./Podcast";

const Podcasts: React.FC<IPodcasts> = (props) => {
  const { title, podcastsList, searchPlaceholder } = props;
  const [_filteredPodcasts, _setFilteredPodcasts] = useState<IPodcast[]>([
    ...podcastsList,
  ]);
  const [_selectedPodcast, _setSelectedPodcast] = useState<IPodcast>();
  const [_currentPodcastIndex, _setCurrentPodcastIndex] = useState(0);
  const selectedPodcastRef = useRef<HTMLDivElement>(null);
  const PodcastPlayerRef = useRef<HTMLDivElement>(null);
  const [_isSharePodcastVisible, _setIsSharePodcastVisible] = useState(false);
  const [_podcastShareLink, _setPodcastShareLink] = useState("");

  useEffect(() => {
    if (selectedPodcastRef.current) {
      selectedPodcastRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [_selectedPodcast]);

  useEffect(() => {
    if (selectedPodcastRef.current) {
      selectedPodcastRef.current.setAttribute("tabindex", "0");
      selectedPodcastRef.current.focus();
    }
  }, [_currentPodcastIndex]);

  const handleSearchPodcasts = (text: string) => {
    const filteredPodcasts = [
      ...podcastsList.filter(
        (podcast) =>
          podcast.titleAndDescription.description.includes(text) ||
          podcast.titleAndDescription.title.includes(text)
      ),
    ];
    _setFilteredPodcasts(filteredPodcasts);
  };

  const handleLoadPoscast = (podcast: IPodcast) => {
    _setSelectedPodcast(podcast);
  };

  const handlePreviousPodcast = () => {
    const previousPodcastIndex =
      _currentPodcastIndex - 1 >= 0
        ? _currentPodcastIndex - 1
        : podcastsList.length - 1;
    _setCurrentPodcastIndex(previousPodcastIndex);
    handleLoadPoscast(podcastsList[previousPodcastIndex]);
  };
  const handleNextPodcast = () => {
    const nextPodcastIndex =
      _currentPodcastIndex + 1 <= podcastsList.length - 1
        ? _currentPodcastIndex + 1
        : 0;
    _setCurrentPodcastIndex(nextPodcastIndex);
    handleLoadPoscast(podcastsList[nextPodcastIndex]);
  };

  return (
    <Fragment>
      <CustomModal
        visible={_isSharePodcastVisible}
        onClose={() => _setIsSharePodcastVisible(false)}
        typeStyle={"rectangle"}
        icon={Images.share}
        title={t("Share")}
      >
        <SocialShare
          items={[
            {
              name: t("Facebook"),
              icon: Images.facebookHeader,
              link: decodeURI(
                `https://www.facebook.com/sharer/sharer.php?u=${_podcastShareLink}`
              ),
              ariaLabel: t("ShareFacebook"),
            },
            {
              name: t("WhatsApp"),
              icon: Images.whatsapp,
              link: decodeURI(`https://wa.me/?text=${_podcastShareLink}`),
              ariaLabel: t("ShareWhatsapp"),
            },
            {
              name: t("Mail"),
              icon: Images.shareMail,
              link: decodeURI(
                `mailto:?subject=${t("SharePodcast")}&body=${_podcastShareLink}`
              ),
              ariaLabel: t("ShareEmail"),
            },
          ]}
        />
        <button
          className="btn-modal"
          onClick={() => _setIsSharePodcastVisible(false)}
          style={{ textAlign: "center", backgroundColor: "#F0F0F0" }}
        >
          {t("Cancel")}
        </button>
      </CustomModal>

      <div style={styles.container}>
        <Title {...title} />
        <InputTextSearchForm
          placeholder={searchPlaceholder}
          onChange={handleSearchPodcasts}
          maxWidth="32rem"
          accessibilityText={`${t(`AccessibilitySearchPodcastText`)}`}
        />
        {_selectedPodcast && (
          <div style={styles.selectedPodcastContainer}>
            <div ref={selectedPodcastRef} style={{ position: "relative" }}>
              <PlayingPodcast
                {..._selectedPodcast}
                onClickPrevious={handlePreviousPodcast}
                onClickNext={handleNextPodcast}
                onShareClick={(podcastLink) => {
                  _setPodcastShareLink(podcastLink);
                  _setIsSharePodcastVisible(true);
                }}
              />
            </div>
            <div style={{ position: "absolute", left: 0, top: 0 }}>
              <FileElement
                file={_selectedPodcast.transcribedEpisode}
                fileName={_selectedPodcast.titleAndDescription.title}
                openType="preview"
              />
            </div>
          </div>
        )}
        <div style={styles.podcasts}>
          {_filteredPodcasts.map((podcast, index) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
              key={index}
            >
              <Podcast
                {...podcast}
                onClick={() => {
                  _setSelectedPodcast(podcast);
                  _setCurrentPodcastIndex(index);
                }}
                isSelected={
                  JSON.stringify(podcast) === JSON.stringify(_selectedPodcast)
                }
              />
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    maxWidth: "76rem",
    gap: "2rem",
  },
  podcasts: {
    display: "flex",
    flexDirection: "column",
    gap: "3rem",
  },
  selectedPodcastContainer: {
    position: "relative",
  },
};

export default Podcasts;
