import { t } from "i18next";
import { useState } from "react";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import Images from "../../Data/Images/Images";
import { ILanguageSelection } from "../../Interfaces/ILanguage";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import i18n from "../../Services/i18n";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../Services/DeviceService";

const LanguageSelection: React.FC<ILanguageSelection> = (props) => {
  const { languages, containerStyles, isOver } = props;
  const handleLanguageSelect = (selectedLanguage: string) => {
    i18n.changeLanguage(selectedLanguage);
  };
  const [_languageLocaleOver, _setLanguageLocaleOver] = useState("");

  return (
    <div
      style={!containerStyles ? styles.languages : containerStyles}
 
    >
      {languages.map((language, index) => (
        <div
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              const languagesSelection =
                document.getElementById("languagesSelection");
              if (languagesSelection) {
                languagesSelection.focus();
              }
            }
          }}
          onMouseOver={() => {
            _setLanguageLocaleOver(language.languageLocale);
          }}
          onMouseLeave={() => {
            _setLanguageLocaleOver("");
          }}
          onFocusCapture={() => {
            _setLanguageLocaleOver(language.languageLocale);
          }}
          onBlurCapture={(e) => {
            _setLanguageLocaleOver("");
          }}
          style={{
            ...styles.languageItem,
            borderBottom:
              index < languages.length - 1
                ? `.1rem solid ${colors.platinum}`
                : "",
            backgroundColor:
              _languageLocaleOver === language.languageLocale
                ? colors.pattensBlue
                : "",
          }}
          role={"button"}
          tabIndex={!IsMobile() ? 0 : -1}
          aria-label={t("ChangeLanguageTo", {
            newLanguage: t(language.languageLocale),
          })}
          onClick={() => handleLanguageSelect(language.languageLocale)}
          onKeyPressCapture={() =>
            handleLanguageSelect(language.languageLocale)
          }
          key={index}
        >
          <div>{language.languageName}</div>
          <div style={styles.approveIconCon}>
            {i18n.language === language.languageLocale && (
              <img src={Images.approve} style={styles.approveIcon}></img>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

const styles: StyleSheet = {
  languages: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.white,
    lineHeight: "4rem",
    position: "absolute",
    width: "12rem",
    height: `${6 * LanguageSelection.length}`,
    boxShadow: `0 15px 30px 0 rgba(0, 0, 0, 0.15)`,
    borderRadius: "0.6rem",
    marginInlineStart: "-5rem",
    marginInlineEnd: "5rem",
    overflow: "hidden",
  },
  languageItem: {
    display: "flex",
    padding: ".5rem 1.5rem",
    justifyContent: "space-between",
    fontSize: fontSize.Text,
    alignItems: "center",
    cursor: "pointer",
    color: colors.darkBlue,
    fontWeight: "400",
    fontFamily: fonts.FbReformaRegular,
  },
  approveIcon: {

    backgroundRepeat: "no-repeat",
 

  },

  approveIconCon: {

    display:"flex"

  },
};

export default LanguageSelection;
