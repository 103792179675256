import { MetaHTMLAttributes } from "react";
import store from "store2";
import { IMedia } from "../Interfaces/IBaseElement";
import Config from "../Configuration/server";

export const StyleMode = {
  desktop: `DESKTOP`,
  tablet: `TABLET`,
  mobile: `MOBILE`,
};

interface Dictionery<T> {
  [key: string]: T;
}
export const getDesktopMode = () => {
  let DesktopMode: Dictionery<number> = {};
  DesktopMode["100"] = 1;
  DesktopMode["110"] = 0.95;
  DesktopMode["125"] = 0.9;
  DesktopMode["150"] = 0.8;
  DesktopMode["175"] = 1;
  DesktopMode["200"] = 1;
  return DesktopMode;
};

export const CheckIsEnable = (is_enable: string) => {
  return is_enable === `Yes`;
};

export const UmbracoTypes = {
  category: "category",
  updates: "updates",
  magazine: "magazine",
};

export const AuctionPages = {
  GeneralAuctions: "generalauctions",
  CargoAuctions: "cargoauctions",
};

export const UpdatesTypes = {
  regular: `Regular`,
  special: `Special`,
  permanent: "Permanent",
};

export const TitlePage = {
  HomePage: `HomePageTitle`,
  Route: `RouteTitle`,
  Resultes: `ResultesTitle`,
  Updates: `UpdatesTitle`,
};

export const titleId = "SiteTitle";
export const titleSeoId = "SeoTitle";
export const descriptionSeoId = "SeoDescription";
export const seoTitleOGID = "SeoTitleOG";
export const seoDescriptionOGID = "SeoDescriptionOG";
export const seoImageOGID = "SeoImageOG";
export const SeoImageAltOGID = "SeoImageAltOG";
export const SeoImageScureOGID = "SeoImageScureOGID";
export const SeoImageTwitterID = "SeoImageTwitterID";
export const IndexStatus = "IndexStatus";

export const ChangeTitle = (name: string) => {
  let title = document.getElementById(titleId);
  if (title) {
    document.head.removeChild(title!);
  }
  let newTitle = document.createElement("title");
  newTitle.id = titleId;
  newTitle.innerText = name;
  document.head.appendChild(newTitle);
};

export const UpdateSeo = (
  title: string = "",
  description: string = "",
  titleOG: string = "",
  descriptionOG: string = "",
  imageOG?: IMedia,
  noIndex:boolean = false
) => {
  const seoTitle = document.getElementById(titleSeoId);
  const seoDescription = document.getElementById(descriptionSeoId);
  const seoTitleOG = document.getElementById(seoTitleOGID);
  const seoDescriptionOG = document.getElementById(seoDescriptionOGID);
  const seoImageOG = document.getElementById(seoImageOGID);
  const SeoImageAltOG = document.getElementById(SeoImageAltOGID);
  const SeoImageScureOG = document.getElementById(SeoImageScureOGID);
  const SeoImageTwitterOG = document.getElementById(SeoImageTwitterID);
  const IndexStatusMeta = document.getElementById(IndexStatus);

  if (seoTitle) {
    seoTitle.setAttribute("content", title);
  }
  if (seoDescription) {
    seoDescription.setAttribute("content", description);
  }
  if (seoTitleOG) {
    seoTitleOG.setAttribute("content", titleOG);
  }
  if (seoDescriptionOG) {
    seoDescriptionOG.setAttribute("content", descriptionOG);
  }
  if (seoImageOG) {
    seoImageOG.setAttribute("content", imageOG?._url!);
    SeoImageScureOG?.setAttribute("content", imageOG?._url!);
    SeoImageAltOG?.setAttribute("content", titleOG);
    SeoImageTwitterOG?.setAttribute("content", imageOG?._url!)
  }
  if(noIndex){
    IndexStatusMeta?.setAttribute("content", "noindex,nofollow");
  }
  else{
    IndexStatusMeta?.setAttribute("content", "index,follow");
  }
  //   <meta property="og:image:secure_url" content="https://secure.example.com/ogp.jpg" />
  // <meta property="og:image:type" content="image/jpeg" />
  // <meta property="og:image:width" content="400" />
  // <meta property="og:image:height" content="300" />
  // <meta property="og:image:alt" content="A shiny red apple with a bite taken out" />
};

export const GetGlassixPages = () => {
  return GlassixPages;
};

export const setGlassixPages = (pages: string[]) => {
  GlassixPages = pages;
  store.set(`GlassixPages`, JSON.stringify(pages));
};
let GlassixPages: string[] = [];

export const SoftUpdateInterval = `Soft Updates Replace Interval`;

export const HESpecialPages = `דפים מיוחדים`;
export const SpecialPages = `Special Pages`;
export const allStations = store.session.get(`${Config.BASE_LOCALSTORAGE_NAME}stations`);


export const AuctionSteps = {
  openAuctions: "openauctions",
  singleSupplier: "singlesupplier",
  exemptionsNotices: "exemptionsnotices",
  closedAuctions: "closedauctions",
  auctionResults: "auctionresults",
};