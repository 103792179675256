import { IPisTrainCard } from "../../../../Interfaces/IPis";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import colors from "../../../../Assets/Colors/Colors.json";
import Images from "../../../../Data/Images/Images";
import { t } from "i18next";
import store from "store2";
import Config from "../../../../Configuration/server";
import i18n from "../../../../Services/i18n";
import { GetStationsDictionary } from "../../../../Utils/helpers";
import { FormatHoursMinutes } from "../../../../Services/DateService";
import fonts from "../../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../../Assets/Fonts/FontsSizes.json";

const PisTrainCardMobile: React.FC<
  IPisTrainCard & {
    onClick?: (selected: IPisTrainCard) => void;
    isSelected: boolean;
    isBottomLineVisible: boolean;
  }
> = (props) => {
  const {
    trainNumber,
    destinationStationNumber,
    arrivalTime,
    originPlatform,
    onClick,
    isSelected,
    isBottomLineVisible,
  } = props;

  const stations = store.session.get(
    `${Config.BASE_LOCALSTORAGE_NAME}stations`
  )[i18n.language];
  const dictStations = GetStationsDictionary(stations);

  return (
    <div
      style={{
        ...styles.container,
        borderBottom: isBottomLineVisible
          ? `1px solid ${colors.pastelGray}`
          : "",
      }}
      onClick={() => {
        onClick && onClick(props);
      }}
    >
      <div style={styles.firstSideContainer}>
        <div style={styles.trainNumberContainer}>
          <span>{trainNumber}</span>
          <img src={Images.trainInCard} />
        </div>
        <div style={styles.destinationStationIntermidiate}>
          <span style={styles.destinationStation}>
            {`${t("To")}${i18n.language === "he" ? "" : " "}${
              dictStations[destinationStationNumber].stationName
            }`}
          </span>
          {destinationStationNumber === 8600 && (
            <img src={Images.plane} width={"15"} />
          )}
          <div style={styles.intermediateStations}>
            <span>{t("IntermediateStations")}</span>
            <span>{">"}</span>
          </div>
        </div>
      </div>
      <div style={styles.platformArrivalContainer}>
        <span style={styles.arrivalTime}>
          {FormatHoursMinutes(arrivalTime)}
        </span>
        <div style={styles.platformContainer}>
          <span
            style={{
              ...styles.platform,
              backgroundColor:
                originPlatform !== -1 ? colors.darkBlue : colors.white,
            }}
          >
            {originPlatform !== -1 && `${t("Platform")} ${originPlatform}`}
          </span>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    width: "100%",
    minHeight: "5rem",
    backgroundColor: colors.white,
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  firstSideContainer: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    justifyContent: "center",
  },
  trainNumberContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: ".5rem",
  },
  destinationStation: {
    fontFamily: fonts.FbReformaMedium,
  },
  arrivalTime: {
    fontFamily: fonts.FbReformaMedium,
  },
  platformContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "5rem",
  },
  platform: {
    display: "flex",
    boxSizing: "border-box",
    color: colors.white,
    backgroundColor: colors.darkBlue,
    borderRadius: "0.1875rem",
    padding: ".1rem .5rem",
  },
  intermediateStations: {
    display: "flex",
    alignItems: "center",
    gap: ".5rem",
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
  },
  platformArrivalContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  destinationStationIntermidiate: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
};

export default PisTrainCardMobile;
