import { t } from "i18next";
import Images from "../../../../../../Data/Images/Images";

import { Fragment, useEffect, useRef, useState } from "react";
import colors from "../../../../../../Assets/Colors/Colors.json";
import i18n from "../../../../../../Services/i18n";
import { StyleSheet } from "../../../../Interfaces_tenders/IStyleSheet";
import Title from "../../Title";

import { FormatDateYYYYMMDD_Dots } from "../../../../../../Services/DateService";
import { IServerFile } from "../../../../Interfaces_tenders/IUmbraco";
import RichTextEditor from "../../RichTextEditor";

import { ChangeTitle, StyleMode, TitlePage } from "../../../../../../Utils/consts";
import useDeviceMode from "../../../../../../Utils/useWindowDimensions";

import { FilesToZipServiceServer } from "../../../../../../Services/FilesToZipServiceServer";
import {
  IAuctionsSystemAuctions,
  IAuctionsSystemMeetings,
} from "../../../../Interfaces_tenders/IAuctionsSystemDetails";
import ServerFile from "../../File/ServerFile";
import MeetingCard from "../Meetings/MeetingCard";

import { useSelector } from "react-redux";
import fonts from "../../../../../../Assets/Fonts/Fonts.json";
import CustomModal from "../../../../../../Components/CustomModal";
import { linkBuilder, linkShareFormat } from "../../../../../../Components/SharePage/ShareOptions";
import SocialShare from "../../../../../../Components/SocialShare/SocialShare";
import { SystemParamsState } from "../../../../../../Redux/Slices/systemParamsSlice";
import { UseWindowScrollPositions } from "../../../../../../Utils/useWindowScrollPositions";

const AuctionDetailsBottomModal: React.FC<IAuctionsSystemAuctions> = (
  props
) => {
  const {
    tenderName,
    tenderNumber,
    referentMail,
    publishDate,
    clarifyingDate,
    biddingDate,
    meetings,
    documentation,
    biddings,
    description,
  } = props;

  const [_currentFileName, _setCurrentFileName] = useState<string>("");
  const appInfoRef = useRef<HTMLDivElement | null>(null);
  const [_mailingFormDisplay, _setMailingFormDisplay] = useState(true);
  const [_emailAdressValue, _setEmailAdressValue] = useState<string>("");
  const [_nameAdressValue, _setNameAdressValue] = useState<string>("");
  const [_isArrowAnimation, _setIsArrowAnimation] = useState(true);

  const deviceMode = useDeviceMode();

  const { scrollY } = UseWindowScrollPositions();
  const serverFiles: IServerFile[] =
    documentation &&
    documentation.map((file, index) => {
      return {
        document: file.docName,
        filename: file.docDisplay ? file.docDisplay : t("defaultFileNeme"),
      };
    });
  const [_isDetailsModalOpen, _setIsDetailsModalOpen] = useState(false);
  const [_currentMeetingsDetails, _seCcurrentMeetingsDetails] =
    useState<IAuctionsSystemMeetings[]>();
  const [_isUpdateMeModalOpen, _setIsUpdateMeModalOpen] = useState(false);

  const [_isShareCardModalVisible, _setIsShareCardModalVisible] =
    useState(false);

  useEffect(() => {
    ChangeTitle(t(`${TitlePage.HomePage}`));
  }, []);


  const handleShareModal = () => {
    _setIsShareCardModalVisible(true);
  };


  const getShareUrl = (url: string) => {
    let link = `${window.location.href}&step=openauctions`
    linkShareFormat.title = description;
    linkShareFormat.subject = t("shareButtonAria") + " " + tenderNumber;
    linkShareFormat.body = encodeURIComponent(t("shareButtonAria") + " " + tenderNumber + "%0A" + link);
    linkShareFormat.summary = encodeURIComponent(description + "%0A" + link);
    linkShareFormat.text = encodeURIComponent(description + "%0A" + link);
    linkShareFormat.quote = description;
    linkShareFormat.url = encodeURIComponent(link);
    linkShareFormat.u = encodeURIComponent(link);
    // sharePagetext
    let newLink = linkBuilder(url, linkShareFormat);


    return decodeURI(newLink);
  };
  const systemParams = (
    useSelector((state: any) => state.SystemParamsReducer) as SystemParamsState
  ).systemParams;
  const systemParamsDictionary = systemParams.reduce(
    (result: { [key: string]: string }, current) => {
      result[current.key] = current.value;
      return result;
    },
    {}
  );
  return (
    <Fragment>
      <div style={styles.containerStyles}>
        <div style={styles.container}>
          <div style={{
            paddingTop: "3rem",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}>
            <Title text={t("AuctionNumber", { tenderNumber }).slice(0, 35)} />
            {new Date(biddingDate) > new Date() && systemParamsDictionary["updateSpecificTender"] === "true" && (
              <div style={styles.updateMeContainer}>
                <img src={Images.bellIcon} style={styles.bellIcon} onClick={() => {
                  _setIsUpdateMeModalOpen(true);
                }} />
                {/* <div style={styles.updateMe}>
                    <span
                      tabIndex={0}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        _setIsUpdateMeModalOpen(true);
                      }}
                    >
                      {t("updateMeOnChange")}
                    </span>
                  </div> */}
              </div>
            )}
          </div>
          <div>
            <Title
              titleStyles={{
                display: "flex",
                fontSize: "1.5625rem",
                fontFamily: fonts.FbReformaMedium,
                color: colors.darkBlue,
                lineHeight: "1.8rem",
              }}
              text={tenderName}
              isUnderline={false}
            />
          </div>
          <div style={styles.datesContainer}>
            <div style={styles.dateItem}>
              <img src={Images.datePicker}></img>
              <div style={styles.datePublishContainer}>
                <div>{t("AuctionPublishDate")}</div>
                <div style={{ fontWeight: "bold" }}>
                  {FormatDateYYYYMMDD_Dots(new Date(publishDate))}
                </div>
              </div>
            </div>
            {clarifyingDate &&
              clarifyingDate.toString() !== "0001-01-01T00:00:00" && (
                <div style={styles.dateItem}>
                  <img src={Images.datePicker}></img>
                  <div style={styles.datePublishContainer}>
                    <div>{t("AuctionDeadlineForSubmissionOfQuestions")}</div>
                    <div style={{ fontWeight: "bold" }}>
                      {FormatDateYYYYMMDD_Dots(new Date(clarifyingDate))}
                    </div>
                  </div>
                </div>
              )}
            <div style={styles.dateItem}>
              <img src={Images.datePicker}></img>

              <div style={styles.datePublishContainer}>
                <div>{t("AuctionDeadlineForSubmissionOfBids")}</div>
                <div style={{ fontWeight: "bold" }}>
                  {FormatDateYYYYMMDD_Dots(new Date(biddingDate))}
                </div>
              </div>
            </div>
          </div>
          {meetings && meetings.length > 0 && (
            <div style={styles.meetingContainer}>
              <Title text={t("MeetingOffers")} />

              {meetings &&
                meetings.map((meeting, index) => (
                  <MeetingCard
                    key={index}
                    meetingsId={meeting.meetingsId}
                    meetingDate={meeting.meetingDate}
                    registretionLink={meeting.registretionLink}
                    location={meeting.location}
                  />
                ))}
            </div>
          )}
          {biddings && biddings.length > 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
              }}
            >
              <Title text={t("HowAndWhenSendOffer")} />
              <div>
                <RichTextEditor text={biddings} />
              </div>
            </div>
          )}
          {
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
                paddingBottom: "2rem",
                paddingLeft: "3rem",
              }}
            >
              {documentation && documentation.length > 0 && (
                <div>
                  <Title text={t("AuctionDocuments")} />
                </div>
              )}
              {serverFiles && serverFiles.length > 0 && (
                <div
                  style={
                    deviceMode === StyleMode.mobile
                      ? {
                        ...styles.auctionDocumentsContainer,
                        flexDirection: "column",
                      }
                      : styles.auctionDocumentsContainer
                  }
                >
                  {serverFiles &&
                    serverFiles.map((document, index) => (
                      <div
                        key={index}
                        style={styles.auctionDocumentFile}
                        onClick={() => {

                          const filesToDownload = [
                            ...serverFiles.filter(
                              (file) =>
                                file.document !== null && file === document
                            ),
                          ];
                          //const filesToDownload = [document];
                          FilesToZipServiceServer(
                            filesToDownload,
                            document.filename
                          );

                        }}
                      >
                        <ServerFile
                          document={document.document}
                          filename={document.filename}
                          onChangeFileName={(text) => _setCurrentFileName(text)}
                          filenameStyles={{
                            display: "flex",
                            gap: ".5rem",
                            justifyContent: "start",
                            alignItems: "center",
                            color: colors.darkBlue,
                            fontSize: "1.2rem",
                            cursor: "pointer",
                            marginBottom: "1%",
                            textDecoration: "none",
                          }}
                        />
                      </div>
                    ))}
                </div>
              )}

              {description && description.length > 0 && (
                <div>
                  <Title text={t("AuctionDescription")} />
                </div>
              )}

              {description && description.length > 0 && (
                <div>
                  <div
                    style={styles.descriptionText}
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </div>
              )}

              <div
                style={{
                  display: "flex",
                }}
              ></div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <div>
                  <div
                    tabIndex={0}
                    style={styles.requestClarification}
                    onClick={() => {
                      window.location.href = `mailto:${referentMail}`;
                    }}
                  >
                    {t("RequestForClarification")}
                  </div>
                </div>
                <div>
                  <span>
                    <img
                      src={
                        appInfoRef.current &&
                          appInfoRef.current.getBoundingClientRect().top +
                          appInfoRef.current.getBoundingClientRect().height <
                          scrollY
                          ? Images.sharePng
                          : Images.sharePng
                      }
                      style={
                        deviceMode === StyleMode.mobile
                          ? {
                            ...styles.arrowIcon,
                            left: i18n.dir() === "rtl" ? "14%" : "",
                            right: i18n.dir() === "ltr" ? "14%" : "",
                            animationName: _isArrowAnimation
                              ? "moveUpDown"
                              : "",
                            width: "3rem",
                            cursor: "pointer",
                          }
                          : {
                            ...styles.arrowIcon,
                            left: i18n.dir() === "rtl" ? "4%" : "",
                            right: i18n.dir() === "ltr" ? "4%" : "",

                            animationName: _isArrowAnimation
                              ? "moveUpDown"
                              : "",
                            width: "3rem",
                            //   borderRadius: "100%",
                            cursor: "pointer",
                          }
                      }
                      tabIndex={0}
                      role={"button"}
                      aria-label={t("shareButtonAria")}
                      onClick={handleShareModal}
                    />
                  </span>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      <CustomModal
        visible={_isShareCardModalVisible}
        onClose={() => _setIsShareCardModalVisible(false)}
        typeStyle={"rectangle"}
        icon={Images.share}
        title={t("Share")}
        responsiveModal={"bottom"}
      >
        <SocialShare
          items={[
            {
              name: t("Facebook"),
              icon: Images.facebookHeader,
              link: getShareUrl(
                `https://www.facebook.com/sharer/sharer.php?u=`
              ),
              ariaLabel: t("ShareFacebook"),
            },
            {
              name: t("WhatsApp"),
              icon: Images.whatsapp,
              link: getShareUrl(`https://wa.me/?text=`),
              ariaLabel: t("ShareWhatsapp"),
            },
            {
              name: t("Mail"),
              icon: Images.shareMail,
              link: getShareUrl(
                `mailto:?subject=&body=`
              ),
              ariaLabel: t("ShareEmail"),
            },
            {
              name: t("Linkedin"),
              icon: Images.linkedinHeader,
              link: getShareUrl(
                `https://www.linkedin.com/sharing/share-offsite/?url=`
              ),
              ariaLabel: t("ShareLinkedin"),
            },
          ]}
        />
        <button
          className="btn-modal"
          onClick={() => _setIsShareCardModalVisible(false)}
          style={{ textAlign: "center", backgroundColor: "#F0F0F0" }}
        >
          {t("Cancel")}
        </button>
      </CustomModal>


    </Fragment>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "4rem",
    height: "100%",
    overflow: "auto",
    // padding:"1rem"
  },
  datesContainer: {
    display: "flex",
    gap: "3rem",
    flexWrap: "wrap",
    alignItems: "center",
  },
  dateItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: colors.darkBlue,
    fontSize: "1.2rem",
    gap: "1rem",
  },
  auctionDocumentFile: {
    display: "flex",
    gap: ".5rem",
    justifyContent: "start",
    alignItems: "center",
    color: colors.darkBlue,
    fontSize: "1.2rem",
    cursor: "pointer",
    marginBottom: "1%",
    flexBasis: "45%",
  },
  auctionDocumentsContainer: {
    display: "flex",
    gap: "1.5rem",
    flexDirection: "column",
    //flexWrap: "wrap",
  },
  requestClarification: {
    backgroundColor: colors.darkBlue,
    color: colors.white,
    fontSize: "1rem",
    borderRadius: "16rem",
    justifyContent: "center",
    paddingTop: ".5rem",
    alignItems: "center",
    maxWidth: "12rem",
    minHeight: "1.8rem",
    textAlign: "center",
    cursor: "pointer",
    paddingInline: "1rem",
  },
  datePublishContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  meetingContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },
  descriptionText: {
    color: colors.darkBlue,
    fontSize: "1.2rem",
  } as React.CSSProperties,


  containerStyles: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.white,
    //  lineHeight: "19rem",
    width: "100%",
    // height: `${6 * languageSelection.length}`,
    paddingRight: "3rem",
    boxSizing: "border-box",
    borderTopLeftRadius: "2.6rem",
    borderTopRightRadius: "2.6rem",
    height: "100%",
    paddingTop: "3rem",
    paddingBottom: "5rem",
  },
};

export default AuctionDetailsBottomModal;
