import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IUmbracoStore } from "../../Interfaces/IUmbraco";

export interface UmbracoStoreState {
  umbracoStore: IUmbracoStore;
}

const initialState: UmbracoStoreState = {
  umbracoStore: {},
};

export const umbracoStoreSlice = createSlice({
  name: "umbracoStore",
  initialState,
  reducers: {
    setUmbracoStore: (
      state: UmbracoStoreState,
      action: PayloadAction<IUmbracoStore>
    ) => {
      state.umbracoStore = action.payload;
    },
  },
});

export const { setUmbracoStore } = umbracoStoreSlice.actions;

export default umbracoStoreSlice.reducer;