import { t } from "i18next";
import { Fragment, useEffect, useRef, useState } from "react";
import colors from "../../../../Assets/Colors/Colors.json";
import Images from "../../../../Data/Images/Images";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import { IPodcast, IPodcasts } from "../../../../Interfaces/IUmbraco";
import CustomModal from "../../../CustomModal";
import InputTextSearchForm from "../../../Form/InputTextSearchForm/InputTextSearchForm";
import SocialShare from "../../../SocialShare/SocialShare";
import PodcastMobile from "./PodcastMobile";

const PodcastsMobile: React.FC<IPodcasts> = (props) => {
  const { title, podcastsList, searchPlaceholder } = props;
  const [_filteredPodcasts, _setFilteredPodcasts] = useState<IPodcast[]>([
    ...podcastsList,
  ]);
  const [_selectedPodcast, _setSelectedPodcast] = useState<IPodcast>();
  const [_currentPodcastIndex, _setCurrentPodcastIndex] = useState(0);
  const [_isShareModalVisible, _setIsShareModalVisible] = useState(false);
  const [_podcastShareLink, _setPodcastShareLink] = useState("");
  const selectedPodcastRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedPodcastRef.current) {
      selectedPodcastRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [_selectedPodcast]);

  const handleSearchPodcasts = (text: string) => {
    const filteredPodcasts = [
      ...podcastsList.filter(
        (podcast) =>
          podcast.titleAndDescription.description.includes(text) ||
          podcast.titleAndDescription.title.includes(text)
      ),
    ];
    _setFilteredPodcasts(filteredPodcasts);
  };

  return (
    <Fragment>
      <div style={styles.container}>
        {/* <Title {...title} /> */}
        <InputTextSearchForm
          placeholder={searchPlaceholder}
          onChange={handleSearchPodcasts}
        />
        <div style={styles.podcasts}>
          {_filteredPodcasts.map((podcast, index) => (
            <Fragment key={index}>
              <PodcastMobile
                {...podcast}
                onClick={() => {
                  _setSelectedPodcast(podcast);
                  _setCurrentPodcastIndex(index);
                }}
                onShareClick={(podcastLink) => {
                  _setPodcastShareLink(podcastLink);
                  _setIsShareModalVisible(true);
                }}
                isSelected={
                  JSON.stringify(podcast) === JSON.stringify(_selectedPodcast)
                }
              />
              {index < _filteredPodcasts.length - 1 && (
                <div style={styles.line}></div>
              )}
            </Fragment>
          ))}
        </div>
      </div>
      <CustomModal
        visible={_isShareModalVisible}
        onClose={() => _setIsShareModalVisible(false)}
        typeStyle={"rectangle"}
        icon={Images.share}
        title={t("Share")}
        responsiveModal={"bottom"}
      >
        <SocialShare items={[
            {
              name: t("Facebook"),
              icon: Images.facebookHeader,
              link: decodeURI(
                `https://www.facebook.com/sharer/sharer.php?u=${_podcastShareLink}`
              ),
              ariaLabel: t("ShareFacebook"),
            },
            {
              name: t("WhatsApp"),
              icon: Images.whatsapp,
              link: decodeURI(`https://wa.me/?text=${_podcastShareLink}`),
              ariaLabel: t("ShareWhatsapp"),
            },
            {
              name: t("Mail"),
              icon: Images.shareMail,
              link: decodeURI(
                `mailto:?subject=${t("SharePodcast")}&body=${_podcastShareLink}`
              ),
              ariaLabel: t("ShareEmail"),
            },
          ]} />
        <button
          className="btn-modal"
          onClick={() => _setIsShareModalVisible(false)}
          style={{ textAlign: "center", backgroundColor: "#F0F0F0" }}
        >
          {t("Cancel")}
        </button>
      </CustomModal>

    </Fragment>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    maxWidth: "76rem",
    gap: "5rem",
  },
  podcasts: {
    display: "flex",
    flexDirection: "column",
    gap: "3rem",
  },
  line: {
    width: "100%",
    height: ".01rem",
    backgroundColor: colors.pastelGray,
  }
};

export default PodcastsMobile;
