import { IGalleryYoutube } from "../../../../Interfaces/IGallery";
import { StyleMode } from "../../../../Utils/consts";
import useDeviceMode from "../../../../Utils/useWindowDimensions";

const GalleryYoutube: React.FC<IGalleryYoutube> = (props) => {
  const { sourceURL, header, description, size, isStandAlone, customStyles } =
    props;

  const deviceMode = useDeviceMode();

  const getEmbedUrl = () => {
    if (sourceURL.includes("watch?v=")) {
      return sourceURL
        .replace("watch?v=", "embed/")
        .replace("youtube", "youtube-nocookie");
    }
    return sourceURL.replace("youtube", "youtube-nocookie");
  };

  return (
    <iframe
      width="560"
      height="315"
      src={getEmbedUrl()}
      title={header.text}
      allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      style={{
        width: "100%",
        height: deviceMode === StyleMode.desktop ? "42rem" : "28rem",
        border: "none",
        ...customStyles,
      }}
    ></iframe>
  );
};

export default GalleryYoutube;
