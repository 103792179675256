import { useNavigate } from "react-router-dom";
import Images from "../../../../Data/Images/Images";
import { ISearchResultsCategory } from "../../../../Interfaces/ISearchResultsItems";
import { StyleMode } from "../../../../Utils/consts";
import useDeviceMode from "../../../../Utils/useWindowDimensions";
import colors from "../../../../Assets/Colors/Colors.json";
import fonts from "../../../../Assets/Fonts/Fonts.json";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import fontSize from "../../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../../Services/DeviceService";

const SearchResultsCategoryItem: React.FC<ISearchResultsCategory> = (props) => {
  const { id, title, pages } = props;
  const navigate = useNavigate();
  const deviceMode = useDeviceMode();

  const fullLink = `${window.location.href.split("/?")[0]}/?page=${id}`;

  const redirectToPage = (page?: string) => {
    page && navigate(`?page=${page}`);
  };

  return (
    <div style={styles.searchResultItem}>
      {deviceMode === StyleMode.desktop && (
        <div style={styles.iconContainer}>
          <img src={Images.misraIcon} />
          <div style={styles.iconText}>{Images.michrazIcon.iconText}</div>
        </div>
      )}
      <div style={styles.body}>
        <div>
          <span style={styles.title}>{title}</span>
        </div>
        <ul>
          {pages.map((page, key) => (
            <div
              style={{
                ...styles.content,
                paddingBottom: ".5rem",
              }}
              key={key}
              role={"link"}
              tabIndex={!IsMobile() ? 0 : -1}
              onClick={() => redirectToPage(page.id)}
            >
              <li>
                <span
                  style={{
                    cursor: "pointer",
                    color: colors.darkBlue,
                    fontFamily: fonts.FbReformaMedium,
                  }}
                >
                  {page.title.text}
                </span>
              </li>
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },

  title: {
    lineHeight: "1.75rem",
    letterSpacing: "0.039375rem",
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.UmbracoElementTitle,
    maxWidth: "55rem",
  },
  link: {
    color: colors.nero,
    fontSize: fontSize.Text,
    letterSpacing: "0.029375rem",
  },
  iconText: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaRegular,
  },
  content: {
    fontFamily: fonts.FbReformaLight,
    lineHeight: "1.6875rem",
    fontSize: fontSize.Paragraph,
    letterSpacing: "0.038125rem",
  },
  searchResultItem: {
    display: "flex",
    gap: "2rem",
    paddingBottom: "1rem",
    paddingTop: "1.5rem",
  },
  iconContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "2.5rem",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    maxWidth: "56rem",
    width: "100%",
  },
};

export default SearchResultsCategoryItem;
