import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IErrorPage } from "../Interfaces/IErrors";
import { IStaticPage } from "../Interfaces/IStaticPage";
import { UmbracoStoreState } from "../Redux/Slices/umbracoSlice";
import StaticPageError from "./StaticPageError";

const ErrorPageMain: React.FC<IErrorPage> = (props) => {
  const { errorName } = props;
  const [_errorPageContent, _setErrorPageContent] = useState<IStaticPage>();
  const umbracoStore = (
    useSelector((state: any) => state.UmbracoStoreReducer) as UmbracoStoreState
  ).umbracoStore;

  useEffect(() => {
    const errorPageCategory = umbracoStore.treeData?.categories.find(
      (category) =>
        category.id && category.id.toLowerCase() === "Errors".toLowerCase()
    );
    if (errorPageCategory) {
      let currentErrorPageContent = undefined;
      switch (errorName) {
        case "PageNotFound":
          currentErrorPageContent = errorPageCategory.pages.find(
            (errorPage) => errorPage.id === "pageNotFound"
          );
          break;
        case "SomethingWentWrong":
          currentErrorPageContent = errorPageCategory.pages.find(
            (errorPage) => errorPage.id === "somethingWentWrong"
          );
          break;
        case "Maintenance":
          currentErrorPageContent = errorPageCategory.pages.find(
            (errorPage) => errorPage.id === "maintenance"
          );
          break;
      }
      _setErrorPageContent(currentErrorPageContent);
    }
  }, [umbracoStore]);

  return _errorPageContent ? (
    <Fragment>
      <StaticPageError {..._errorPageContent} isCard={false} />
    </Fragment>
  ) : null;
};

export default ErrorPageMain;
