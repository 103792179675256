import { IFormsContainer } from "../../Interfaces/IForms";
import FormCustomerServiceContactForm from "./Screens/FormCustomerServiceContactForm";
import FormVideoShootingRequestScreen from "./Screens/FormVideoShootingRequestScreen";

const FormNavigation: React.FC<IFormsContainer> = (props) => {
  const { type } = props;

  switch (type) {
    case "טופס פנייה לשירות לקוחות": //case "CustomerServiceContactForm":
      return <FormCustomerServiceContactForm />;
    case "תיאום נסיעה נגישה": //  case "AccessibleTravelCoordination":
      return <FormCustomerServiceContactForm />;
    case "הסדרי נגישות לנוסעים": //case "AccessibilityArrangementsForPassengers":
      return <FormCustomerServiceContactForm />;
    case "פיצוי נוסעים": //case "passengerCompensation":
      return <FormCustomerServiceContactForm />;
    case "תשלום כפל דמי נסיעה": //case "DoublePaymentOfTravelFees":
      return <FormCustomerServiceContactForm />;
    case "בקשה לצילומים ברכבת": //case "VideoShootingRequestScreen":
      return <FormVideoShootingRequestScreen serviceValue="VideoShootingRequestScreen"/>;
    case "איתור אבדה": // case "LocatorLost":
      return <FormCustomerServiceContactForm />;
    default:
      return <FormCustomerServiceContactForm />;
  }
};


export default FormNavigation;
