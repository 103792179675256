import { t } from "i18next";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import Images from "../../Data/Images/Images";
import { ILastTravel } from "../../Interfaces/ILastTravel";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { setPage } from "../../Redux/Slices/pageSlice";
import {
  setDestinationStation,
  setOriginStation,
} from "../../Redux/Slices/scheduleSlice";
import i18n from "../../Services/i18n";
import { GetLongTextWithDots } from "../../Utils/utils";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../Services/DeviceService";
import { SetTravelOnTopList } from "../../Services/LastTravelsService";

const LastTravel: React.FC<ILastTravel> = (props) => {
  const {
    fromStation,
    toStation,
    isFavorite,
    onClickFavorite,
    isUnderline,
    isFavoriteAnimation,
    isFromStationClosed,
    isToStationClosed,
    onTravelClosed,
  } = props;
  const [_isOver, _setIsOver] = useState(false);
  const [_isFavoriteOver, _setIsFavoriteOver] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();



  const handleLastTravelClick = () => {
    SetTravelOnTopList(fromStation.stationId, toStation.stationId);
    dispatch(setOriginStation(fromStation.stationId));
    dispatch(setDestinationStation(toStation.stationId));
    dispatch(setPage("routePlan"));
    navigate(
      `?page=routePlan&step=when&fromStation=${fromStation.stationId}&toStation=${toStation.stationId}`
    );
  };

  const addDotsToLongText = (text: string) => {
    if (text.length > 22) {
      return GetLongTextWithDots(text, 22);
    }
    return text;
  };

  return (
    <div
      style={{
        ...styles.rowContainer,
        borderBottom: isUnderline ? `.1rem solid ${colors.pastelGray}` : "",
      }}
    >
      <div
        id={`lastTravel-${fromStation.stationId}-${toStation.stationId}`}
        style={{
          ...styles.row,
          gap: "1rem",
          backgroundColor: _isOver
            ? isFromStationClosed || isToStationClosed
              ? colors.disabled
              : colors.pattensBlueLighter
            : colors.white,
        }}
        onMouseMove={() => _setIsOver(true)}
        onMouseLeave={() => _setIsOver(false)}
        tabIndex={!IsMobile() ? 0 : -1}
        role={`link`}
        aria-label={` ${t(`FromStation`)} ${fromStation.stationName} ${t(
          `ToStation`
        )} ${toStation.stationName}`}
        onClick={() => {
          if (!isFromStationClosed && !isToStationClosed) {
            handleLastTravelClick();
          } else {
            onTravelClosed &&
              onTravelClosed(
                isFromStationClosed,
                isToStationClosed,
                fromStation,
                toStation
              );
          }
        }}
      >
        <div style={styles.from} title={fromStation.stationName}>
          {addDotsToLongText(fromStation.stationName)}
        </div>
        <img
          alt={``}
          src={Images.fromTo}
          style={{
            transform: i18n.dir() === "ltr" ? "scaleX(-1)" : "",
            filter: "brightness(0.7)",
          }}
        />
        <div title={toStation.stationName} style={styles.from}>
          {addDotsToLongText(toStation.stationName)}
        </div>
        <div
          style={styles.favoriteContainer}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            onClickFavorite &&
              onClickFavorite(fromStation.stationId, toStation.stationId);
          }}
        >
          <img
            src={!isFavorite ? Images.favorite : Images.favoriteFilled}
            onMouseOver={() => {
              _setIsFavoriteOver(true);
            }}
            onMouseLeave={() => {
              _setIsFavoriteOver(false);
            }}
            style={{
              ...styles.favoriteIcon,
              filter: `brightness(${_isFavoriteOver ? ".1" : ""})`,
              width: i18n.language === "ru" ? "1.7rem" : "1.4rem",
              height: i18n.language === "ru" ? "1.7rem" : "1.4rem",
            }}
          />
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  rowContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "3.099609375rem",
  },

  row: {
    display: "flex",
    justifyContent: "space-between",
    lineHeight: "1.2rem",
    alignItems: "center",
    cursor: "pointer",
    height: "2.5rem",
  },
  from: {
    fontFamily: fonts.FbReformaRegular,
    letterSpacing: "0.07rem",
    color: colors.darkGrayGreen,
    fontSize: fontSize.Text,
    flex: "1.2",
    // whiteSpace: "nowrap",
    // textOverflow: "ellipsis",
    // overflow: "hidden"
  },
  to: {
    fontFamily: fonts.FbReformaRegular,
    marginInlineStart: "3rem",
    letterSpacing: "0.07rem",
    color: colors.darkGrayGreen,
    fontSize: fontSize.Text,
    flex: "1",
  },
  favoriteContainer: {
    width: "3rem",
    height: "3rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  favoriteIcon: {
    backgroundRepeat: "no-repeat",

    animationDuration: ".8s",
  },
};

export default LastTravel;
