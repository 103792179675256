import { t } from "i18next";
import { useState } from "react";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import { IWorker } from "../../Interfaces/ICareers";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { useRef, useEffect } from "react";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../Services/DeviceService";

const WorkerMobile: React.FC<IWorker> = (props) => {
  let [_active, _setActive] = useState(false);
  const workerRef = useRef<HTMLDivElement>(null);
  const {
    index,
    firstName,
    lastName,
    description,
    note,
    positionJob,
    profile,
  } = props;

  const checkMouseClickOutside = (e: any) => {
    if (workerRef.current && !workerRef.current.contains(e.relatedTarget)) {
      _setActive(false);
    } else {
      _setActive(true);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", checkMouseClickOutside);
    return () => {
      document.removeEventListener("mousedown", checkMouseClickOutside);
    };
  }, []);

  return (
    <div
      ref={workerRef}
      className="workers"
      style={{ ...styles.column, gap: "2rem" }}
      onClick={(e) => {
        _setActive(true);
      }}
    >
      {_active ? (
        <div style={{ ...styles.topBox, animation: "slidershow 1s forwards" }}>
          <div className="lets-talks" style={styles.letsTalks}>{`${t(
            "LetWorkWith"
          )} ${firstName}`}</div>
          <div className="lets-note" style={styles.letsNote}>
            {note}
          </div>
          <div className="lets-description" style={styles.letsDescription}>
            {description}
          </div>
        </div>
      ) : (
        <div
          style={{ ...styles.topBox, animation: "sliderhidden 0.5s forwards" }}
        >
          <div className="lets-talks" style={styles.letsTalks}>{`${t(
            "LetWorkWith"
          )} ${firstName}`}</div>
          <div className="lets-note" style={styles.letsNote}>
            {note}
          </div>
          <div className="lets-description" style={styles.letsDescription}>
            {description}
          </div>
        </div>
      )}
      <div
        tabIndex={!IsMobile() ? 0 : -1}
        aria-labelledby={`top-box-${index}`}
        className="boxss"
        style={styles.column}
      >
        <div className="name-and-position">
          {firstName} {lastName},<br />
          {positionJob}
        </div>
        <img
          src={profile?._url}
          alt={``}
          style={{ width: "100%", height: "15.625rem", objectFit: "cover" }}
        />
      </div>
    </div>
  );
};
const styles: StyleSheet = {
  wrap: {
    display: "flex",
    flexWrap: "wrap",
  },
  bgGray: {
    backgroundColor: "#F9F9F9",
  },
  title: {
    fontSize: fontSize.PageTitle,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaLight,
    lineHeight: "3rem",
    margin: 0,
  },
  subtitle: {
    fontSize: fontSize.H1,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaLight,
    margin: 0,
  },
  info: {
    fontSize: fontSize.Paragraph,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaLight,
    margin: 0,
  },
  inTitle: {
    fontSize: fontSize.PageTitle,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
  },
  mainHeader: {
    minHeight: "15.6rem",
    display: "flex",
    height: "100vh",
    maxHeight: "28rem",
  },
  cover: {
    backgroundColor: "rgba(0, 70, 143, 0.5)",
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  maxWidth: {
    maxWidth: "68rem",
    margin: "auto",
    paddingBlock: "2rem",
    paddingInline: "1rem",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  btn: {
    borderRadius: "3.75rem",
    backgroundColor: "rgb(249, 186, 77)",
    border: 0,
    fontSize: fontSize.Text,
    padding: "1rem",
    fontFamily: fonts.FbReformaMedium,

    width: "16rem",
  },
  btnOutline: {
    backgroundColor: "transparent",
    color: colors.darkBlue,
    border: "0.06rem solid",
  },
  wrapperInfo: {
    flex: "1.5 1 0%",
    flexDirection: "column",
    gap: "1rem",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    textAlign: "center",
  },
  infoBlue: {
    fontSize: fontSize.CareerElements,
    fontFamily: fonts.FbReformaMedium,
    lineHeight: "normal",
    color: colors.darkBlue,
  },
  name: {
    fontSize: fontSize.H1,
    color: "rgb(249, 186, 77)",
  },
  lineDivider: {
    width: 1,

    backgroundColor: colors.darkBlue,
  },
  description: {
    fontFamily: fonts.FbReformaLight,
    color: colors.darkBlue,
    fontSize: fontSize.UmbracoElementTitle,
    letterSpacing: "0.023rem",
    lineHeight: "2rem",
  },
  cardJob: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0 2px 23px 0 rgb(0 0 0 / 8%)",

    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    flex: 1,
  },
  tags: {
    display: "flex",
    flexWrap: "wrap",
  },
  tag: {
    border: "0.06rem solid #00458a",
    borderRadius: "3.1rem",
    minWidth: "4.4rem",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#D8F2FC",
    textAlign: "center",
    display: "inline-flex",
  },
  linkMoreInfo: {
    color: "#009DD6",
    fontSize: fontSize.Paragraph,
    letterSpacing: "0.03rem",
    lineHeight: "1.5rem",
  },
  whyToWork: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  whyTitle: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.H1,
  },
  infoBlack: {
    color: "#282828",
    fontSize: fontSize.UmbracoElementTitle,
    letterSpacing: "0.042rem",
    fontFamily: fonts.FbReformaLight,

    lineHeight: "2rem",
  },
  // ".boxss": { backgroundColor: "#D8F3FE" },
  topBox: {
    backgroundColor: "#D8F3FE",
    padding: "1rem",
    flexDirection: "column",
    display: "flex",
    minHeight: "27rem",
    gap: "1.5rem",
    position: "absolute",
    opacity: 0,
  },
  letsTalks: {
    color: colors.darkBlue,
    fontSize: fontSize.FooterItem,
    fontFamily: fonts.FbReformaMedium,
    letterSpacing: "0.44px",
    lineHeight: "1.87rem",
  },
  letsNote: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.Text,
    letterSpacing: "0.047rem",
    lineHeight: "1.3rem",
  },
  letsDescription: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Text,
    letterSpacing: "0.57px",
    lineHeight: "1.4rem",
  },
};

export default WorkerMobile;
