
const InfoPayment: React.FC<any> = (props) => {

  return (
    <div className="modal-content">
      <pre>{JSON.stringify(props)}</pre>
    </div>
  );
};

export default InfoPayment;
