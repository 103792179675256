import colors from "../../../../Assets/Colors/Colors.json";
import fonts from "../../../../Assets/Fonts/Fonts.json";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import Button from "../Button/Button";
import fontSize from "../../../../Assets/Fonts/FontsSizes.json";

interface ICarrerButtons {
  buttons: ICarrerButtons[];
}

const CareerButtons: React.FC<ICarrerButtons> = (props) => {
  const { buttons } = props;
  return buttons.length > 0 ? (
    <div
      className="wapper-btns"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      {buttons.map((button: any, index: number) => (
        <Button
          {...button}
          key={index}
          style={
            index === 0 ? styles.careerBoardButton : styles.trackStatusButton
          }
        />
      ))}
    </div>
  ) : null;
};

const styles: StyleSheet = {
  careerBoardButton: {
    backgroundColor: colors.koromiko,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "18rem",
    height: "3.4rem",
    fontSize: fontSize.Paragraph,
    textAlign: "center",
    fontFamily: fonts.FbReformaMedium,
  },
  trackStatusButton: {
    fontSize: fontSize.Paragraph,
    fontFamily: fonts.FbReformaRegular,
    color: colors.cyanBold,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    width: "18rem",
  },
};

export default CareerButtons;
