import i18next, { t } from "i18next";
import moment from "moment";
import fonts from "../../../Assets/Fonts/Fonts.json";
import { IGateActivityHours, ITime } from "../../../Interfaces/IActivityTime";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import {days} from "../../../Data/Languages/days";
import fontSize from "../../../Assets/Fonts/FontsSizes.json"

const startText = ["from-", "Until"];
const Times: React.FC<ITime> = (props) => {
  const { data } = props;

  const renderTime = (item: IGateActivityHours) => {
    const {
      activityDaysNumbers,
      activityHoursReplaceTextKey,
      startHourReplaceTextKey,
      endHourReplaceTextKey,
      startHourPrefixTextKey,
      endHour,
      startHour,
      startHourTextKey,
    } = item;
    const HourEndTimeStart = startHour;
    const HourEndTimeEnd = endHour;

    let daysFromData: any[] = activityDaysNumbers.split(",");
    let day: string =
      daysFromData.length > 1
        ? `${days[i18next.language].shortDay[daysFromData[0] -1 ]}-${days[i18next.language].shortDay[daysFromData.pop() - 1]}`
        : days[i18next.language].day[daysFromData[0] - 1]
    const textTimeInfo = activityHoursReplaceTextKey
      ? t(activityHoursReplaceTextKey)
      : startHourPrefixTextKey
        ? `${t(startHourPrefixTextKey)} ${HourEndTimeStart}`
        :endHourReplaceTextKey && startHourReplaceTextKey
      ? `${t(startHourReplaceTextKey)} - ${t(endHourReplaceTextKey)}`
      : endHourReplaceTextKey
      ? `${HourEndTimeStart} - ${t(endHourReplaceTextKey)}`
      : startHourReplaceTextKey
      ? `${t(startHourReplaceTextKey)} - ${HourEndTimeEnd}`
      : `${
          startHourTextKey ? startHourTextKey : t(startText[0])
        } ${HourEndTimeStart} ${t(startText[1])} ${HourEndTimeEnd}`;
    // : ;

    const text = ` ${textTimeInfo}`;
    return (
      <div style={{ display: "flex", textAlign: "start" }}>
        <div
          style={{ whiteSpace: "nowrap", paddingInlineEnd: "5px" }}
        >{`${day} : `}</div>
        <div>{text}</div>
      </div>
    );
  };
  return (
    <>
      {" "}
      {data
        .sort((item: any, itemB: any) =>
          item.activityDaysNumbers < itemB.activityDaysNumbers ? -1 : 1
        )
        .map((time: any, index: number) => (
          <div style={styles.text} key={index}>
            {renderTime(time)}
          </div>
        ))}
    </>
  );
};

const styles: StyleSheet = {
  text: {
    fontFamily: fonts.FbReformaLight,
    fontSize: fontSize.FooterItem,
    lineHeight: "1.75rem",
  },
};
export default Times;
