import fonts from "../Assets/Fonts/Fonts.json";
import Images from "../Data/Images/Images";
import { IContact } from "../Interfaces/IBaseElement";
import { StyleSheet } from "../Interfaces/IStyleSheet";
import i18n from "../Services/i18n";
import { StyleMode } from "../Utils/consts";
import useDeviceMode from "../Utils/useWindowDimensions";
import { replaceRichtextBoldWithFont } from "../Utils/utils";
import Card from "./Card";


const Contact: React.FC<IContact> = (props) => {
  const { textLink, icon, link, title, info, moreDetails, onShowDetails } = props;
  const deviceMode = useDeviceMode();

  const onOpenDetails = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (moreDetails && moreDetails.title) {
      e.preventDefault()
      onShowDetails && onShowDetails(moreDetails)
    }
  }
  return (
    <Card
      className="contact"
      style={{
        borderRadius: "0.75rem",
        textAlign: "center",
        position: "relative",
        padding: "1rem",
        paddingTop: icon && deviceMode === StyleMode.desktop ? "3rem" : "1rem",
        height: "16rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        paddingBottom: "1.5rem",
        maxWidth: "19rem",
      }}
    >
      {icon && (
        <img
          src={icon._url}
          style={{
            position: "absolute",
            top: 0,
            left: "50%",
            translate: "-50% -50%",
            width: "3.5rem",
            backgroundColor: "white",
            borderRadius: "50rem",
          }}
        />
      )}
      <div style={deviceMode === StyleMode.desktop ? {} : styles.cardMob}>
        <div
          style={{
            color: "var(--darkBlue)",
            fontWeight: "bold",
            fontSize: "1.25rem",
            paddingBlock: "1rem",
            fontFamily: fonts.FbReformaMedium
          }}
        >
          {title}
        </div>
        <div
          style={{
            fontFamily: fonts.FbReformaRegular
          }}
          dangerouslySetInnerHTML={{
            __html: replaceRichtextBoldWithFont(info),

          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "center",
          color: "#1465a9",

        }}
      >
        <a href={link} onClick={onOpenDetails}>{textLink}</a>
        <img
          style={{
            rotate: i18n.dir() !== "ltr" ? "90deg" : "-90deg",
          }}
          src={Images.arrowDown}
          alt="arrow"
        />
      </div>
    </Card>
  );
};

const styles: StyleSheet = {
  cardMob: {
    flex: 1,
    justifyContent: "center",
    display: "flex",
    paddingBlock: "1rem",
    flexDirection: "column",
    alignItems: "center",
  },
};

export default Contact;
