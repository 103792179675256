//import { setAuctionsTypes } from "../../../../../Redux/Slices/tendersSlice";
import { IAuctionsSystem, IAuctionSystem } from "../../../Interfaces_tenders/IAuctionsSystem";
import { IAuctionsSystemTypes } from "../../../Interfaces_tenders/IAuctionsSystemDetails";
import { Actions  } from "../../consts";

export const setAuctions = (auctions: IAuctionsSystem[]) => {
    return (dispatch: any) => {
      dispatch({
        type: Actions.Auctions,
        payload: auctions,
      });
    };
  };

  /*export const setAuctionsTypes = (auctions: IAuctionsSystemTypes[]) => {
    return (dispatch: any) => {
      dispatch(setAuctionsTypesRedux(auctions));
    };
  };*/

  export const setErrorStatus = (auctions: boolean) => {
    return (dispatch: any) => {
      dispatch({
        type: Actions.setErrorStatus,
        payload: auctions,
      });
    };
  };

  export const setErrorStatusUpdateClientNewTenders = (auctions: boolean) => {
    return (dispatch: any) => {
      dispatch({
        type: Actions.setErrorStatusUpdateClientNewTenders,
        payload: auctions,
      });
    };
  };

  
  
  