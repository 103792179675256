import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import store from "store2";
import colors from "../Assets/Colors/Colors.json";
import fonts from "../Assets/Fonts/Fonts.json";
import fontSize from "../Assets/Fonts/FontsSizes.json";
import AllElements from "../Components/UmbracoElements/AllElements";
import BaseElement from "../Components/UmbracoElements/BaseElement";
import Breadcrumb from "../Components/UmbracoElements/Breadcrumb";
import { IAnyElement, IStaticPage } from "../Interfaces/IStaticPage";
import { StyleSheet } from "../Interfaces/IStyleSheet";
import { PageState } from "../Redux/Slices/pageSlice";
import { IsMobile } from "../Services/DeviceService";
import { ChangeTitle, StyleMode, UpdateSeo } from "../Utils/consts";
import useDeviceMode from "../Utils/useWindowDimensions";

const StaticPage: React.FC<IStaticPage> = (props) => {
  const {
    id,
    pageId,
    title,
    coverImage,
    publishDate,
    elements,
    breadcrumb,
    isCard = true,
    seo,
    seoTitle,
    seoSocialNetworksTitle,
    seoSocialNetworksDescription,
    seoSocialNetworksImage,
    isNotPublishedPage,
  } = props;

  const deviceMode = useDeviceMode();
  const [_breadcrumbKey, _setBreadcrumbKey] = useState(0);
  const [_isPreview] = useState(store.get(`isPreview`) === `true`);
  let isBreadcrumbDisplay = true;
  let isCardDisplay = isCard;
  const titleRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const currentPage = (
    useSelector((state: any) => state.PageReducer) as PageState
  ).page;

  useEffect(() => {
    let isMounted = true;

    ChangeTitle(title.text);
    UpdateSeo(
      seoTitle,
      seo,
      seoSocialNetworksTitle,
      seoSocialNetworksDescription,
      seoSocialNetworksImage,
      isNotPublishedPage
    );

    // when breadcrumb updates, the breadcrumb's property is changed
    // and the component not aware of it, so when we catch the change we force
    // the breadcrumb component to render by changing the key:

    if (isMounted) {
      _setBreadcrumbKey(Math.floor(Math.random() * 100));
    }

    return () => {
      isMounted = false;
    };
  }, [breadcrumb]);

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.focus();
      titleRef.current.style.outline = "none";
      titleRef.current.tabIndex = -1;
    }
  }, [location, currentPage]);

  const handlePagesWithoutCard = () => {
    switch (_isPreview ? pageId.toLowerCase() : id.toLowerCase()) {
      case "accurecyindex":
      case "pis":
      case "mldp":
      case "mldpa":
      case "career":
      case "linesmap":
      case "generalauctions":
      case "cargoauctions":
      case "guidelinesandrates":
      case "pagenotfound":
      case "somethingwentwrong":
      case "paymentsucceed":
      case "paymenterror":
        isCardDisplay = false;
        break;
    }
  };

  const handlePagesWithoutBreadcrumb = () => {
    switch (_isPreview ? pageId.toLowerCase() : id.toLowerCase()) {
      case "pagenotfound":
      case "somethingwentwrong":
      case "mldp":
      case "mldpa":
        isBreadcrumbDisplay = false;
        break;
    }
  };

  handlePagesWithoutCard();
  handlePagesWithoutBreadcrumb();

  const GetUmbracoElement: React.FC<IAnyElement & any> = (props) => {
    switch (props.contentTypeAlias) {
      case "baseElement":
        return (
          <BaseElement
            {...props}
            containerStyle={{
              maxWidth: "76rem",
              width: "80%",
              fontSize: fontSize.Paragraph,
              fontFamily: fonts.FbReformaRegular,
            }}
          />
        );
      case "titleAndDescription":
        return (
          <BaseElement
            titleAndDescription={props}
            isHeaderUnderline={true}
            headerStyle={
              {
                display: "flex",
                fontSize: fontSize.UmbracoElementTitle,
                fontFamily: fonts.FbReformaMedium,
                color: colors.darkBlue,
              } as StyleSheet
            }
            containerStyle={
              {
                maxWidth: "76rem",
                width: "80%",
                fontSize: fontSize.Paragraph,
              } as StyleSheet
            }
          />
        );
      case "link":
        return (
          <BaseElement
            link={props}
            containerStyle={
              {
                maxWidth: "76rem",
                width: "80%",
              } as StyleSheet
            }
          />
        );
      default:
        return <AllElements elementName={props.contentTypeAlias} {...props} />;
    }
  };
  return (
    <div
      style={{
        ...styles.mainContainer,
        // paddingTop: deviceMode === StyleMode.desktop ? "3rem" : "",
      }}
    >
      <div
        ref={titleRef}
        style={{ position: "absolute", zIndex: -1, color: "transparent" }}
        tabIndex={!IsMobile() ? 0 : -1}
      >
        {t("CurrentPage", {
          page: title.text,
        })}
      </div>
      <div
        id={"coverImage"}
        style={{
          ...styles.cover,
          background: coverImage && `30% 30% / cover url(${coverImage._url})`,
          height:
            deviceMode === StyleMode.desktop
              ? isCardDisplay
                ? "40vh"
                : "20.5vh"
              : "",
          minHeight:
            deviceMode === StyleMode.desktop
              ? isCardDisplay
                ? "17.18rem"
                : "14.25rem"
              : "",
        }}
      >
        <div className="cover-color-blur">
          <div
            style={{
              ...styles.coverElements,
              width: deviceMode === StyleMode.desktop ? "80%" : "75%",
            }}
            className="container"
          >
            <div
              style={{ position: "absolute", zIndex: -1 }}
              aria-label={title.text}
              tabIndex={!IsMobile() ? 0 : -1}
            ></div>
            {isBreadcrumbDisplay && (
              <div
                style={{
                  ...styles.breadcrumb,
                  paddingTop:
                    deviceMode === StyleMode.desktop ? "2rem" : "3rem",
                }}
              >
                {breadcrumb && (
                  <Breadcrumb {...breadcrumb} key={_breadcrumbKey} />
                )}
              </div>
            )}
            <h1
              style={{
                ...styles.title,
                paddingTop: deviceMode === StyleMode.desktop ? "2rem" : "5rem",
                fontSize:
                  deviceMode === StyleMode.desktop ? "2.8rem" : "1.8rem",
                lineHeight:
                  deviceMode === StyleMode.desktop ? `3rem` : `2.2rem`,
              }}
            >
              {title && title.text}
            </h1>
          </div>
        </div>
      </div>
      <div
        // className="card-border"
        style={
          isCardDisplay && deviceMode === StyleMode.desktop
            ? styles.cardBorder
            : styles.cardBorderMobile
        }
      >
        <div
          id="pageMainContent"
          tabIndex={!IsMobile() ? 0 : -1}
          className="card-body"
          style={
            isCardDisplay && deviceMode === StyleMode.desktop
              ? {
                  ...styles.cardBody,
                  lineHeight: `1.6rem`,
                  padding: _isPreview
                    ? "2rem 0"
                    : "0"
                    ? pageId.toLowerCase()
                    : id.toLowerCase() !== "generalauctions" && _isPreview
                    ? pageId.toLowerCase()
                    : id.toLowerCase() !== "cargoauctions"
                    ? "2rem 0"
                    : "0",
                }
              : {
                  ...styles.cardBodyMobile,
                  lineHeight: `1.6rem`,
                  padding: _isPreview
                    ? pageId.toLowerCase()
                    : id.toLowerCase() !== "generalauctions" && _isPreview
                    ? pageId.toLowerCase()
                    : id.toLowerCase() !== "cargoauctions"
                    ? "2rem 0"
                    : "0",
                }
          }
        >
          {publishDate && (
            <span
              style={styles.publishDate}
              dangerouslySetInnerHTML={{
                __html: t("pagePublishDate", {
                  publishDate,
                }),
              }}
            ></span>
          )}
          {elements &&
            elements.map((element, index) => (
              <GetUmbracoElement {...element} key={index} />
            ))}
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  mainContainer: {
    alignItems: "center",
    flexDirection: "column",
    zIndex: "10",
  },
  cardBorder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transform: "translateY(-7.8rem)",
  },
  cardBorderMobile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "3rem",
    padding: "2rem 0",
    backgroundColor: colors.white,
    borderRadius: "0.3125rem",
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.15)",
    minWidth: "80%",
  },
  cardBodyMobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "3rem",
    padding: "2rem 0",
    width: "100%",
  },
  publishDate: {
    color: colors.darkBlue,
    display: "flex",
    alignSelf: "end",
    marginInlineEnd: "3rem",
    fontSize: fontSize.Text,
  },
  cover: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "40vh",
    minHeight: "17.18rem",
    position: "relative",
  },
  title: {
    color: colors.white,
    fontSize: fontSize.PageTitle,
    lineHeight: "3rem",
    letterSpacing: "0.05625rem",
    textShadow: `2px 2px ${colors.black}`,
    fontFamily: fonts.FbReformaMedium,
    paddingTop: "3.25rem",
  },
  breadcrumb: {
    display: "flex",
    color: colors.white,
    fontSize: fontSize.Text,
    letterSpacing: "0.05625rem",
    fontFamily: fonts.FbReformaRegular,
    position: "absolute",
  },
  coverElements: {
    gap: "3rem",
    maxWidth: "76rem",
    margin: "auto",
    lineHeight: "2.5rem",
    padding: "1rem",
  },
  content: {
    position: "relative",
    paddingTop: "5rem",
    right: "25%",
    width: "50%",
  },
  titlesAndDescriptionsItems: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },
  linkItems: {
    cursor: "pointer",
  },
};

export default StaticPage;
