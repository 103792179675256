import { t } from "i18next";
import { useEffect, useState } from "react";
import colors from "../../Assets/Colors/Colors.json";
import Images from "../../Data/Images/Images";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import CircleInfoMobile from "./CircleInfoMobile";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import i18n from "../../Services/i18n";

const allObject = {
  environments: [
    {
      icon: Images.food,
      title: "Food",
      width: 30.4,
      tagName: "Restaurants-and-Fast-Food",
    },
    {
      icon: Images.outofhome,
      title: "ActivitiesRecreation",
      width: 25.6,
      tagName: "Activities-&-Recreation",
    },
    {
      icon: Images.shoping,
      title: "Shopping",
      width: 19.2,
      tagName: "Shopping",
    },
    {
      icon: Images.info_mobile,
      title: "AllInstitutes",
      width: 16,
      tagName: "All-Institutes",
    },
    {
      icon: Images.services,
      title: "HelpServices",
      width: 20.8,
      tagName: "Help-&-Services",
    },
    {
      icon: Images.tickets,
      title: "Events",
      width: 25.6,
      tagName: "Events",
    },
    {
      icon: Images.trip,
      title: "Travel",
      width: 23.5,
      tagName: "Tourism-&-Travel",
    },
  ],
};

const StationInfoEnvironmentMobile: React.FC<any> = (props) => {
  const { location, stationDetails } = props;
  let [_scale, _setScale] = useState(1);

  let [dirRightLeft, _setDirRightLeft] = useState<"left" | "right">("right");
  let [dirLeftRight, _setDirLeftRight] = useState<"left" | "right">("right");
  useEffect(() => {
    scale();
  }, [window.innerWidth]);
  useEffect(() => {
    _setDirRightLeft(i18n.dir() === "ltr" ? "left" : "right");
    _setDirLeftRight(i18n.dir() === "ltr" ? "right" : "left");
  }, []);

  const scale = () => {
    const BASE_WIDTH = 375;
    const board: any = document.querySelector(".board");
    const boardWidth = board?.offsetWidth;
    _setScale(boardWidth / BASE_WIDTH);
  };
  const styles: StyleSheet = {
    board: {
      overflow: "hidden",
      background: colors.white,
      width: "100%",
      aspectRatio: "0.67 / 1",
    },
    dots: {
      position: "relative",
      height: "100%",
    },
    floatBubble: {
      position: "absolute",
      aspectRatio: "1 / 1",
      zIndex: 1,
    },
    imgEasy: {
      position: "absolute",
      width: "3.125rem",
      translate: " -50% 0",
      left: "50%",
      top: "75%",
    },
    dotEmpty: {
      position: "absolute",
      width: "10rem",
      /* height: 10rem */
      background: colors.koromiko,
      borderRadius: "50%",
      aspectRatio: "1 / 1",
    },

    dotEmpty1: {
      top: "43%",
      width: "13.6%",
      [dirLeftRight]: 0,
      transform: `translate(${i18n.dir() !== "ltr" ? "-50%,-50%" : "50%,-50%" })`,
      background: colors.pattensBlue,
    },

    dotEmpty2: {
      top: "66.7%",
      [dirLeftRight]: 0,
      width: "31.2%",
      transform: `translateX(${i18n.dir() !== "ltr" ? "-60%" : "60%" })`,
    },
    dotEmpty3: {
      [dirRightLeft]: 0,
      transform: `translateX(${i18n.dir() === "ltr" ? "-50%" : "50%" })`,

      top: "75.8%",
      width: "15.36%",
      background: "#009dd6",
    },
    dotEmpty4: {
      top: "80.5%",
      [dirRightLeft]: 0,
      width: "22.4%",
      transform: `translateX(${i18n.dir() === "ltr" ? "-50%" : "50%" })`,
    },
    dotEmpty5: {
      top: "60.3%",
      [dirLeftRight]: "42.34%",
      width: "3.5%",
    },
    dotEmpty6: {
      top: "57.9%",
      [dirLeftRight]: "30.8%",
      width: "8.5%",
      background: colors.pattensBlue,
    },
    dotEmpty7: {
      top: "16.8%",
      [dirLeftRight]: "72.4%",
      width: "11.2%",
      background: colors.pattensBlue,
    },
    container: {
      width: "100%",
    },
  };
  return (
    <div style={styles.container}>
      <div style={styles.board} className="board">
        <div className="shadow" />
        <div style={styles.dots}>
          <div
            style={{
              position: "absolute",
              top: "7%",
              [dirRightLeft]:"10%",
              zIndex: 22,
              lineHeight: "19px",
              // transform: `scale(${_scale})`,
            }}
          >
            <h1
              style={{
                fontFamily: "FbReforma-Light",
                fontSize: 16,
                color: "rgba(0, 69, 138, 1)",
                margin: 0,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            >
              {t("PointsOfinterest")}
              <img
                src={Images.plusasstar}
                style={{
                  width: 30,
                  translate: "0 -25%",
                }}
                alt={``}
              />
            </h1>
            <h1
              style={{
                fontFamily: "FbReforma-Medium",
                fontWeight: `${i18n.language === "ar" ? `600` : ``}`,
                fontSize: 16,
                color: "rgba(0, 69, 138, 1)",
                margin: 0,
              }}
            >
              {t("EnvironmentTheTrain")}
            </h1>
          </div>
          {allObject.environments.map((environment: any, index) => (
            <CircleInfoMobile
              {...props}
              {...environment}
              tagName={environment.tagName}
              index={index}
              scale={_scale}
            />
          ))}
          {Array.from(Array(4).keys()).map((item, index: number) => (
            <div
              key={index}
              style={{ ...styles.dotEmpty, ...styles[`dotEmpty${item + 1}`] }}
            />
          ))}
          <a
            href={`https://easy.co.il/?lat=${location?.latitude}&lng=${location?.lontitude}&addressname=${stationDetails.stationName}`}
            style={styles.imgEasy}
            target={"_blank"}
          >
            <img src={Images.easy} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default StationInfoEnvironmentMobile;
