import { StyleSheet } from "../Interfaces/IStyleSheet";
interface ICard {
  className?: string;
  style?: React.CSSProperties;
    children: React.ReactNode;
    onClick?:()=> void
}
const Card: React.FC<ICard> = (props) => {
  const { children, style, className = "",onClick } = props;
  return (
      <div
      onClick={onClick}
      className={`card ${className}`}
      style={{ ...styles.cardCircle, ...style }}
    >
      {children}
    </div>
  );
};

const styles: StyleSheet = {
  cardCircle: {
    boxShadow: "0 2px 28px 0 rgba(0,0,0,0.25)",
    backgroundColor: "white",
  },
};
export default Card;
