import { ILanguageSelectionMobile } from "../../../Interfaces/ILanguage";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import i18n from "../../../Services/i18n";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import Images from "../../../Data/Images/Images";
import { t } from "i18next";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../Services/DeviceService";

const LanguageSelectionMobile: React.FC<ILanguageSelectionMobile> = (props) => {
  const { languages } = props;
  const handleLanguageSelect = (selectedLanguage: string) => {
    i18n.changeLanguage(selectedLanguage);
  };
  return (
    <div style={styles.container}>
      <span
        style={{
          ...styles.title,
          alignSelf: "center",
        }}
      >
        {t("Choose-language")}
      </span>
      <div style={styles.languages}>
        {languages.map((language, index) => (
          <div
            style={{
              ...styles.languageItem,
              borderTop:
                index < languages.length
                  ? `.1rem solid ${colors.platinum}`
                  : "",
            }}
            role={"button"}
            tabIndex={!IsMobile() ? 0 : -1}
            aria-label={`change language`}
            onClick={(e) => {
              e.stopPropagation();
              handleLanguageSelect(language.languageLocale);
            }}
            onKeyPressCapture={(e) => {
              e.stopPropagation();
              handleLanguageSelect(language.languageLocale);
            }}
            key={index}
          >
            <div>{language.languageName}</div>
            {i18n.language === language.languageLocale && (
              <img src={Images.approve} style={styles.approveIcon}></img>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    paddingTop: "1rem",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  title: {
    fontFamily: fonts.FbReformaRegular,
    color: colors.darkBlue,
    fontSize: fontSize.UmbracoElementTitle,
    paddingBottom: "2rem",
  },
  languages: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "4rem",
    overflow: "auto"
  },
  languageItem: {
    display: "flex",
    padding: "1rem 1.5rem",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    cursor: "pointer",
    color: colors.darkBlue,
    fontSize: fontSize.Paragraph,
    fontFamily: fonts.FbReformaRegular,
  },
  approveIcon: {
    backgroundRepeat: "no-repeat",
  },
};

export default LanguageSelectionMobile;
