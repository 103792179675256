import store from "store2";
import Config from "../Configuration/server";
import { IStation } from "../Interfaces/IStation";
import { IAllStationsLanguages } from "../Utils/helpers";
import { TranslateToSiteLanguage } from "../Utils/translation";
import { GetSystemParams } from "./GlobalService";
import i18n from "./i18n";
import { GeoToKm, GetLocation } from "./LocationService";

export const SearchStationsAutoCompleteService = () => {
  return null;
};

export const GetNearbyStations = async () => {
  const allStations = store.session.get(
    `${Config.BASE_LOCALSTORAGE_NAME}stations`
  ) as IAllStationsLanguages;
  if (!allStations) return [];

  return await GetLocation()
    ?.then(async (pos) => {
      let stations = allStations[i18n.language];

      let listOFStations = stations.reduce(
        (acc: IStation[], next: IStation) => {
          const km = GeoToKm(
            [next.location.latitude, next.location.lontitude],
            [pos.coords.latitude, pos.coords.longitude]
          );
          if (next.stationName) {
            acc.push({ ...next, distance: km, category: "all" });
          }
          return acc;
        },
        []
      );

      let distanceStation = 10;
      let params = await GetSystemParams()
      params.map(param=>{
        if(param.key===`StationsRange`){
          distanceStation = parseInt(param.value&&param.value!==''?param.value:`10`)
        }
      })

      let nearbyStationsNumber= 3
      params.map(param=>{
        if(param.key===`NumStationByGPS`){
          nearbyStationsNumber=parseInt(param.value&&param.value!==''?param.value:`3`)
        }
      })

      let nearbyStations: IStation[] = [];
      listOFStations.map((item: IStation) => {
        if (item.distance <= distanceStation) {
          nearbyStations = [...nearbyStations, item];
        }
      });

      nearbyStations = nearbyStations.sort((item1: IStation, item2: IStation) =>
        item1.distance > item2.distance
          ? 1
          : item2.distance > item1.distance
          ? -1
          : 0
      );
      return nearbyStations.slice(0, nearbyStationsNumber);
    })
    .catch((e) => {
      return [];
    });
};

export const GetCurrentLocation = async ()=>{
  let position = {let:0,lon:0}
  let currntPsition = await GetLocation()?.then(
    async(pos)=>{
      position.let= pos.coords.latitude
      position.lon=pos.coords.longitude
    }
  )
  return `${position.let!==0?position.let:``},${position.lon!==0?position.lon:``}`
}


export const FilterAutoComplete = (text: string, stations: IStation[]) => {
  const allStations = store.session.get(
    `${Config.BASE_LOCALSTORAGE_NAME}stations`
  ) as IAllStationsLanguages;

  if (!allStations) return [];

  if (text.trim().length > 1) {
    let word = TranslateToSiteLanguage(text.toLowerCase(), i18n.language as `he`|`en`|`ar`|`ru`).trim();
    let allStation = allStations[i18n.language];
    let startStaion: IStation[] = [];
    let utilStation: IStation[] = [];
    let newStationList: IStation[] = [];

    allStation.map((item, index) => {
      if (item.stationName.toLowerCase().trim().startsWith(word)) {
        startStaion = [...startStaion, item];
      } else {
        utilStation = [...utilStation, item];
      }
    });

    startStaion = startStaion.sort((item1: IStation, item2: IStation) =>
      item1.stationName > item2.stationName
        ? 1
        : item2.stationName > item1.stationName
        ? -1
        : 0
    );

    allStation = [];
    utilStation.map((item, index) => {
      if (item.stationName.toLowerCase().trim().includes(word)) {
        newStationList = [...newStationList, item];
      } else {
        allStation = [...allStation, item];
      }
    });

    newStationList = newStationList.sort((item1: IStation, item2: IStation) =>
      item1.stationName > item2.stationName
        ? 1
        : item2.stationName > item1.stationName
        ? -1
        : 0
    );

    newStationList = [...startStaion, ...newStationList];
    startStaion = [];

    allStation.map((item) => {
      if (
        item.synonyms
          .toString()
          .toLowerCase()
          .trim()
          .includes(word.toLowerCase().trim())
      ) {
        startStaion = [...startStaion, item];
      }
    });

    startStaion = startStaion.sort((item1: IStation, item2: IStation) =>
      item1.stationName > item2.stationName
        ? 1
        : item2.stationName > item1.stationName
        ? -1
        : 0
    );
    newStationList = [...newStationList, ...startStaion];

    return newStationList;
  } else {
    return allStations[i18n.language];
  }
};

export const ResetStations = () => {
  const allStations = store.session.get(
    `${Config.BASE_LOCALSTORAGE_NAME}stations`
  ) as IAllStationsLanguages;

  if (!allStations) return [];

  return allStations[i18n.language].sort((item1: IStation, item2: IStation) =>
    item1.stationName > item2.stationName
      ? 1
      : item2.stationName > item1.stationName
      ? -1
      : 0
  );
};

export const GetLastStations = () => {
  const allStations = store.session.get(
    `${Config.BASE_LOCALSTORAGE_NAME}stations`
  ) as IAllStationsLanguages;

  if (!allStations) return [];

  const lastStationList = store.get(
    `${Config.BASE_LOCALSTORAGE_NAME}lastTravels`
  ) as [{ from: number; to: number }];
  let lastTravels: IStation[] = [];
  for (let i = 0; i < lastStationList.length; i++) {
    allStations[i18n.language].map((item: IStation) => {
      if (item.stationId === lastStationList[i].to) {
        if (!lastTravels.includes(item)) lastTravels = [...lastTravels, item];
      }
    });
  }
  return lastTravels.reverse().slice(-3);
};

export const GetSortedStations = () => {
  const allStations = store.session.get(
    `${Config.BASE_LOCALSTORAGE_NAME}stations`
  ) as IAllStationsLanguages;

  if (!allStations) return [];

  return allStations[i18n.language].sort((item1: IStation, item2: IStation) =>
    item1.stationName > item2.stationName
      ? 1
      : item2.stationName > item1.stationName
      ? -1
      : 0
  );
};
