import { ITable } from "../../../../Interfaces/IUmbraco";
import colors from "../../../../Assets/Colors/Colors.json";
import fonts from "../../../../Assets/Fonts/Fonts.json";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import { Fragment, useEffect, useRef, useState } from "react";
import Title from "../../Title";
import fontSize from "../../../../Assets/Fonts/FontsSizes.json";

const TableMobile: React.FC<ITable> = (props) => {
  const { title, table } = props;
  const tableRef = useRef<HTMLDivElement>(null);
  const [_cards, _setCards] = useState<any[]>([]);
  const [_headers, _setHeaders] = useState<any[]>([]);

  const getRowsAsObject = (element: Element, headers: any[]) => {
    const tableObjects = [];
    for (let i = 1; i < element.children.length; i++) {
      let rowObject: any = {};
      for (let j = 0; j < element.children[i].children.length; j++) {
        rowObject[`item_${j}`] = element.children[i].children[j].innerHTML;
      }
      tableObjects.push(rowObject);
    }
    return tableObjects;
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (tableRef.current) {
        const tableHeaders = [];
        const tableContainer = tableRef.current.children;
        if (tableContainer.length > 0) {
          const table = tableContainer[0];
          if (table.children.length > 0) {
            const tBody = table.children[0];
            if (tBody.children.length > 0) {
              const tr = tBody.children[0];
              for (let i = 0; i < tr.children.length; i++)
                tableHeaders.push(tr.children[i].textContent);
            }
            const rowsAsObject = getRowsAsObject(tBody, tableHeaders);
            _setHeaders(tableHeaders);
            _setCards(rowsAsObject);
          }
        }
      }
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div style={styles.tableContainer}>
      <div>
        {_cards.length === 0 ? (
          <div
            ref={tableRef}
            dangerouslySetInnerHTML={{
              __html: table,
            }}
          ></div>
        ) : (
          <Fragment>
            <Title text={title} />
            <div style={styles.cardsContainer}>
              {_cards.map((card, key) => (
                <div style={styles.card} key={key}>
                  {_headers.map((header, index) => (
                    <div key={`item_${index}`}>
                      <div
                        style={{
                          color: colors.darkBlue,
                          fontFamily: fonts.FbReformaMedium,
                          fontSize: fontSize.UmbracoElementTitle,
                        }}
                      >
                        {_headers[index]}
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: card[`item_${index}`],
                        }}
                      ></div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    width: "80%",
    paddingBottom: "3rem",
  },
  cardsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "2.5rem",
  },
  title: {
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.UmbracoElementTitle,
    borderBottom: `1px solid ${colors.koromiko}`,
    color: colors.darkBlue,
    borderBottomWidth: ".2rem",
    padding: ".5rem 0",
    alignSelf: "flex-start",
  },
  card: {
    fontSize: fontSize.UmbracoElementTitle,
    width: "100%",
    // minHeight: "23rem",
    boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 15px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: "0.625rem",
    padding: "2rem",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    gap: "1rem",
    lineHeight: "1.8rem",
  },
};

export default TableMobile;
