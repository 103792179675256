import { IWheelPickerData } from "../../Interfaces/IWheelPicker";

export const hoursData = [
  { value: "00", id: "0" },
  { value: "01", id: "1" },
  { value: "02", id: "2" },
  { value: "03", id: "3" },
  { value: "04", id: "4" },
  { value: "05", id: "5" },
  { value: "06", id: "6" },
  { value: "07", id: "7" },
  { value: "08", id: "8" },
  { value: "09", id: "9" },
  { value: "10", id: "10" },
  { value: "11", id: "11" },
  { value: "12", id: "12" },
  { value: "13", id: "13" },
  { value: "14", id: "14" },
  { value: "15", id: "15" },
  { value: "16", id: "16" },
  { value: "17", id: "17" },
  { value: "18", id: "18" },
  { value: "19", id: "19" },
  { value: "20", id: "20" },
  { value: "21", id: "21" },
  { value: "22", id: "22" },
  { value: "23", id: "23" },
];

export const minutesData = [
  { value: "00", id: "0" },
  { value: "15", id: "1" },
  { value: "30", id: "2" },
  { value: "45", id: "3" },
];

/** */
export const daysData = [
  { value: "01", id: "0" },
  { value: "02", id: "1" },
  { value: "03", id: "2" },
  { value: "04", id: "3" },
  { value: "05", id: "4" },
  { value: "06", id: "5" },
  { value: "07", id: "6" },
  { value: "08", id: "7" },
  { value: "09", id: "8" },
  { value: "10", id: "9" },
  { value: "11", id: "10" },
  { value: "12", id: "11" },
  { value: "13", id: "12" },
  { value: "14", id: "13" },
  { value: "15", id: "14" },
  { value: "16", id: "15" },
  { value: "17", id: "16" },
  { value: "18", id: "17" },
  { value: "19", id: "18" },
  { value: "20", id: "19" },
  { value: "21", id: "20" },
  { value: "22", id: "21" },
  { value: "23", id: "22" },
  { value: "24", id: "23" },
  { value: "25", id: "24" },
  { value: "26", id: "25" },
  { value: "27", id: "26" },
  { value: "28", id: "27" },
  { value: "29", id: "28" },
  { value: "30", id: "29" },
  { value: "31", id: "30" },
];

export const monthData = [
  { value: "jan", id: "00" },
  { value: "feb", id: "01" },
  { value: "mar", id: "02" },
  { value: "apr", id: "03" },
  { value: "may", id: "04" },
  { value: "jun", id: "05" },
  { value: "jul", id: "06" },
  { value: "aug", id: "07" },
  { value: "sep", id: "08" },
  { value: "oct", id: "09" },
  { value: "nov", id: "10" },
  { value: "dec", id: "11" },
];

export const yearData: IWheelPickerData[] = [];

for (let i = 0, j = new Date().getFullYear() - 100; i < 200; i++, j++) {
  yearData.push({ value: j.toString(), id: i.toString() });
}

//export arrayOfYears
/*
export const yearData = [
  { value: "2020", id: "0" },
  { value: "2021", id: "1" },
  { value: "2022", id: "2" },
  { value: "2023", id: "3" },
  { value: "2024", id: "4" },
];
*/
