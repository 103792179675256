import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface FormState {
  currentService: string;

  // FormAccessibilityOrCleaningIssueScreen
  accessibilityOrCleaning_issueLocation: string;
  accessibilityOrCleaning_stationDeparture: string;
  accessibilityOrCleaning_stationDestination: string;
  // FormAppealRequirementPaymentScreen
  appealRequirementPayment_isRavKav: string;
  appealRequirementPayment_stationDeparture: string;
  appealRequirementPayment_stationDestination: string;
  // FormCheckEntranceStationScreen
  checkEntranceStation_stationDeparture: string;
  // FormClaimRailsWorkerOrThanksAppreciationScreen
  claimRailsWorkerOrThanksAppreciation_role: string;
  claimRailsWorkerOrThanksAppreciation_stationDeparture: string;
  claimRailsWorkerOrThanksAppreciation_stationDestination: string;
  // FormClaimTicketPurchaseScreen
  claimTicketPurchase_currentDate: string;
  // FormCompensationRequestScreen
  compensationRequest_isRavKav: string;
  compensationRequest_stationDeparture: string;
  compensationRequest_stationDestination: string;
}

const initialState: FormState = {
  currentService: "",
  accessibilityOrCleaning_issueLocation: "",
  accessibilityOrCleaning_stationDeparture: "",
  accessibilityOrCleaning_stationDestination: "",
  appealRequirementPayment_isRavKav: "",
  appealRequirementPayment_stationDeparture: "",
  appealRequirementPayment_stationDestination: "",
  checkEntranceStation_stationDeparture: "",
  claimRailsWorkerOrThanksAppreciation_role: "",
  claimRailsWorkerOrThanksAppreciation_stationDeparture: "",
  claimRailsWorkerOrThanksAppreciation_stationDestination: "",
  claimTicketPurchase_currentDate: "",
  compensationRequest_isRavKav: "",
  compensationRequest_stationDeparture: "",
  compensationRequest_stationDestination: "",
};

export const formsSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setCurrentService: (state, action: PayloadAction<string>) => {
      state.currentService = action.payload;
    },
    setAccessibilityOrCleaning_issueLocation: (
      state,
      action: PayloadAction<string>
    ) => {
      state.accessibilityOrCleaning_issueLocation = action.payload;
    },
    setAccessibilityOrCleaning_stationDeparture: (
      state,
      action: PayloadAction<string>
    ) => {
      state.accessibilityOrCleaning_stationDeparture = action.payload;
    },
    setAccessibilityOrCleaning_stationDestination: (
      state,
      action: PayloadAction<string>
    ) => {
      state.accessibilityOrCleaning_stationDestination = action.payload;
    },
    setAppealRequirementPayment_isRavKav: (
      state,
      action: PayloadAction<string>
    ) => {
      state.appealRequirementPayment_isRavKav = action.payload;
    },
    setAppealRequirementPayment_stationDeparture: (
      state,
      action: PayloadAction<string>
    ) => {
      state.appealRequirementPayment_stationDeparture = action.payload;
    },
    setAppealRequirementPayment_stationDestination: (
      state,
      action: PayloadAction<string>
    ) => {
      state.appealRequirementPayment_stationDestination = action.payload;
    },
    setCheckEntranceStation_stationDeparture: (
      state,
      action: PayloadAction<string>
    ) => {
      state.checkEntranceStation_stationDeparture = action.payload;
    },
    setClaimRailsWorkerOrThanksAppreciation_role: (
      state,
      action: PayloadAction<string>
    ) => {
      state.claimRailsWorkerOrThanksAppreciation_role = action.payload;
    },
    setClaimRailsWorkerOrThanksAppreciation_stationDeparture: (
      state,
      action: PayloadAction<string>
    ) => {
      state.claimRailsWorkerOrThanksAppreciation_stationDeparture =
        action.payload;
    },
    setClaimRailsWorkerOrThanksAppreciation_stationDestination: (
      state,
      action: PayloadAction<string>
    ) => {
      state.claimRailsWorkerOrThanksAppreciation_stationDestination =
        action.payload;
    },
    setClaimTicketPurchase_currentDate: (
      state,
      action: PayloadAction<string>
    ) => {
      state.claimTicketPurchase_currentDate = action.payload;
    },
    setCompensationRequest_isRavKav: (state, action: PayloadAction<string>) => {
      state.compensationRequest_isRavKav = action.payload;
    },
    setCompensationRequest_stationDeparture: (
      state,
      action: PayloadAction<string>
    ) => {
      state.compensationRequest_stationDeparture = action.payload;
    },
    setcompensationRequest_stationDestination: (
      state,
      action: PayloadAction<string>
    ) => {
      state.compensationRequest_stationDestination = action.payload;
    },
  },
});

export const {
  setCurrentService,
  setAccessibilityOrCleaning_issueLocation,
  setAccessibilityOrCleaning_stationDeparture,
  setAccessibilityOrCleaning_stationDestination,
  setAppealRequirementPayment_isRavKav,
  setAppealRequirementPayment_stationDeparture,
  setAppealRequirementPayment_stationDestination,
  setCheckEntranceStation_stationDeparture,
  setClaimRailsWorkerOrThanksAppreciation_role,
  setClaimRailsWorkerOrThanksAppreciation_stationDeparture,
  setClaimRailsWorkerOrThanksAppreciation_stationDestination,
  setClaimTicketPurchase_currentDate,
  setCompensationRequest_isRavKav,
  setCompensationRequest_stationDeparture,
  setcompensationRequest_stationDestination,
} = formsSlice.actions;

export default formsSlice.reducer;
