import { t } from "i18next";
import moment from "moment";
import React from "react";
import colors from "../../Assets/Colors/Colors.json";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { ITimePickers } from "../../Interfaces/ITimePickers";
import {
  GetHoursByMinutesDivider,
  GetMinutesByDivider,
} from "../../Services/DateService";
import i18n from "../../Services/i18n";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";
import { hoursData, minutesData } from "../WheelPicker/WheelDatas";
import WheelPicker from "../WheelPicker/WheelPicker";
import fontSize from "../../Assets/Fonts/FontsSizes.json";

const TimePickers: React.FC<ITimePickers> = (props) => {
  const { date, hours, minutes, divider, onMinutesSelected, onHoursSelected } =
    props;

  const fullDate = moment(date)
    .set("hour", parseInt(hours))
    .set("minute", parseInt(minutes))
    .toDate();

  const minutesIndex = minutesData
    .findIndex(
      (minute) =>
        minute.value === GetMinutesByDivider({ divider, date: fullDate })
    )
    .toString();
  const hoursIndex = hoursData
    .findIndex(
      (hour) =>
        hour.value ===
        GetHoursByMinutesDivider({ minutesDivider: divider, date: fullDate })
    )
    .toString();

  const deviceMode = useDeviceMode();

  return (
    <div
      style={{
        ...styles.mainWrapper,
        flexDirection: i18n.dir() === "rtl" ? "row-reverse" : "row",
      }}
    >
      <WheelPicker
        data={hoursData}
        onChange={onHoursSelected}
        defaultSelectedIndex={hoursIndex}
        wheelId={"hours-wheel"}
        isArrows={deviceMode === StyleMode.desktop}
        topArrowAriaLabel={t("Hours")}
        bottomArrowAriaLabel={t("Hours")}
        currentWheelTypeAria={"Hour"}
      /> 
      <div style={styles.doubleDots}>:</div>
      <WheelPicker
        data={minutesData}
        onChange={onMinutesSelected}
        defaultSelectedIndex={minutesIndex}
        wheelId={"minutes-wheel"}
        isArrows={deviceMode === StyleMode.desktop}
        topArrowAriaLabel={t("Minutes")}
        bottomArrowAriaLabel={t("Minutes")}
        currentWheelTypeAria={"Minute"}
      />   
    </div>
  );
};

const styles: StyleSheet = {
  mainWrapper: {
    display: "flex",
  },
  doubleDots: {
    fontSize: fontSize.H1,
    display: "flex",
    alignItems: "center",
    paddingTop: ".5rem",
  },
};

export default TimePickers;
