import { t } from "i18next";
import { PieChart } from "react-minimal-pie-chart";
import colors from "../../Assets/Colors/Colors.json";
import { IAccurecyIndex } from "../../Interfaces/IAccurecyIndex";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import {
  IDynamicRichText,
  IDynamicSplittedTitle,
} from "../../Interfaces/IUmbraco";
import { FormatDateYYYYMMDD_Dots } from "../../Services/DateService";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";
import {
  GetLongTextWithDots,
  replaceRichtextBoldWithFont,
} from "../../Utils/utils";
import Loading from "../Loading/Loading";
import SplittedTitle from "../UmbracoElements/SplittedTitle/SplittedTitle";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";

const AccurecyIndex: React.FC<IAccurecyIndex> = (props) => {
  let { dynamicPart, dynamicData } = props;
  const deviceMode = useDeviceMode();

  const poal = dynamicPart.dynamicElements.find(
    (dynamicElement) => dynamicElement.textID === "poal"
  );
  const diuk = dynamicPart.dynamicElements.find(
    (dynamicElement) => dynamicElement.textID === "diuk"
  );
  const inTime = dynamicPart.dynamicElements.find(
    (dynamicElement) => dynamicElement.textID === "inTime"
  );
  const late = dynamicPart.dynamicElements.find(
    (dynamicElement) => dynamicElement.textID === "late"
  );
  const cancelled = dynamicPart.dynamicElements.find(
    (dynamicElement) => dynamicElement.textID === "cancelled"
  );
  const thdiuk = dynamicPart.dynamicElements.find(
    (dynamicElement) => dynamicElement.textID === "thdiuk"
  );
  const description = dynamicPart.dynamicElements.find(
    (dynamicElement) => dynamicElement.textId === "description"
  ) as IDynamicRichText;

  const richText = dynamicPart.dynamicElements.find(
    (dynamicElement) => dynamicElement.textId === "richText"
  ) as IDynamicRichText;

  const splittedTitle = dynamicPart.dynamicElements.find(
    (dynamicElement) =>
      dynamicElement.contentTypeAlias === "dynamicSplittedTitle" &&
      dynamicElement.splittedTitleId === "splittedTitle"
  ) as IDynamicSplittedTitle;
  const data = [
    { value: dynamicData ? +dynamicData.poal : 0, color: "#00CC45" },
    { value: dynamicData ? +dynamicData.ihru : 0, color: "#FEB019" },
    { value: dynamicData ? +dynamicData.cancel : 0, color: "#FF4560" },
  ];

  const getTotalTrains = () => {
    return data.reduce(
      (acc, data) => acc + (parseInt(data.value.toString()) || 0),
      0
    );
  };

  const getPercent = (value?: string) => {
    if (!value) {
      return "0";
    }

    if (value.indexOf(".") === -1 || value === "100.0" || value === "100") {
      return parseInt(value).toString();
    }

    return (+value).toFixed(1).toString();
  };

  return !dynamicData ? (
    <div
      style={{
        ...styles.charts,
        flexDirection: deviceMode === StyleMode.desktop ? "row" : "column",
      }}
    >
      <Loading
        message=""
        containerStyles={{
          height: "",
          // transform: "scale(1.3)",
          // minHeight: deviceMode !== StyleMode.desktop ? "100vh" : "70vh",
          width: "80%",
        }}
      />
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        paddingBottom: "10rem",
        gap: "2rem",
      }}
    >
      <div
        style={{
          ...styles.container,
          flexDirection: deviceMode === StyleMode.desktop ? "row" : "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {splittedTitle && (
            <SplittedTitle
              topText={splittedTitle.topText}
              bottomText={splittedTitle.bottomText}
              style={{
                height: "3rem",
              }}
            />
          )}
          <div
            style={{
              maxWidth: "20rem",
              fontFamily: fonts.FbReformaLight,
              fontSize: fontSize.Paragraph,
            }}
            dangerouslySetInnerHTML={{
              __html: replaceRichtextBoldWithFont(description?.text) || "",
            }}
          ></div>
          <div style={styles.dateUpdated}>
            <div>{t("Date-updated", { date: FormatDateYYYYMMDD_Dots() })}</div>
          </div>
        </div>
        {deviceMode === StyleMode.desktop && <div style={styles.line}></div>}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              ...styles.charts,
              flexDirection:
                deviceMode === StyleMode.desktop ? "row" : "column",
            }}
          >
            <div style={styles.donutCounted}>
              <div
                style={{
                  position: "relative",
                }}
              >
                <div style={styles.donutChart}>
                  <div style={styles.donutChartLabel}>
                    <h2 style={styles.donutChartValueLabel}>
                      {`${getPercent(dynamicData.thdiuk)}%`}
                    </h2>
                    <div
                      style={styles.donutChartTextLabel}
                      title={thdiuk?.text}
                    >
                      {thdiuk && thdiuk?.text.length > 19
                        ? GetLongTextWithDots(thdiuk?.text, 19)
                        : thdiuk?.text}
                    </div>
                  </div>
                </div>
                <PieChart
                  startAngle={-90}
                  data={[
                    {
                      value: dynamicData ? +dynamicData.thdiuk : 0,
                      color: colors.darkCyan,
                    },
                  ]}
                  totalValue={100}
                  lineWidth={25}
                  labelPosition={0}
                  animate
                />
              </div>
            </div>
            <div style={styles.donutCounted}>
              <div
                style={{
                  position: "relative",
                }}
              >
                <div style={styles.donutChart}>
                  <div style={styles.donutChartLabel}>
                    <h2 style={styles.donutChartValueLabel}>
                      {`${getPercent(dynamicData.diuk)}%`}
                    </h2>
                    <div style={styles.donutChartTextLabel} title={diuk?.text}>
                      {" "}
                      {diuk && diuk?.text.length > 19
                        ? GetLongTextWithDots(diuk?.text, 19)
                        : diuk?.text}
                    </div>
                  </div>
                </div>
                <PieChart
                  startAngle={-90}
                  data={[
                    {
                      value: dynamicData ? +dynamicData.diuk : 0,
                      color: colors.darkCyan,
                    },
                  ]}
                  totalValue={100}
                  lineWidth={25}
                  labelPosition={0}
                  animate
                />
              </div>
            </div>
            <div
              style={{
                ...styles.donutCounted,
                flexDirection:
                  deviceMode === StyleMode.desktop ? "row" : "column",
              }}
            >
              <div
                style={{
                  position: "relative",
                }}
              >
                <div style={styles.donutChart}>
                  <div style={styles.donutChartLabel}>
                    <h2 style={styles.donutChartValueLabel}>
                      {getTotalTrains()}
                    </h2>
                    <div style={styles.donutChartTextLabel} title={poal?.text}>
                      {" "}
                      {poal && poal?.text.length > 19
                        ? GetLongTextWithDots(poal?.text, 19)
                        : poal?.text}
                    </div>
                  </div>
                </div>
                <PieChart
                  startAngle={-90}
                  data={data}
                  lineWidth={25}
                  paddingAngle={
                    Number(dynamicData.ihru) === 0 &&
                    Number(dynamicData.cancel) === 0
                      ? 0
                      : 4
                  }
                  animate
                  labelPosition={0}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              gap: "2rem",
              paddingTop: "2rem",
              alignSelf:
                deviceMode === StyleMode.desktop ? "flex-end" : "center",
            }}
          >
            <div style={styles.legend}>
              <div
                style={{
                  ...styles.legendSquare,
                  backgroundColor: data[0].color,
                }}
              ></div>
              <div>
                {dynamicData?.poal} {inTime?.text}
              </div>
            </div>
            <div style={styles.legend}>
              <div
                style={{
                  ...styles.legendSquare,
                  backgroundColor: data[1].color,
                }}
              ></div>
              <div>
                {dynamicData?.ihru} {late?.text}
              </div>
            </div>

            <div style={styles.legend}>
              <div
                style={{
                  ...styles.legendSquare,
                  backgroundColor: data[2].color,
                }}
              ></div>
              <div>
                {dynamicData?.cancel} {cancelled?.text}
              </div>
            </div>
          </div>
          <div
            style={{
              fontFamily: fonts.FbReformaLight,
              fontSize: fontSize.Paragraph,
            }}
            dangerouslySetInnerHTML={{
              __html: replaceRichtextBoldWithFont(richText.text),
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5rem",
  },
  charts: {
    display: "flex",
    gap: "3rem",
    alignItems: "center",
    justifyContent: "center",
  },
  donutCounted: {
    display: "flex",
    gap: "2rem",
    alignItems: "center",
  },
  donutChart: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    width: "100%",
    top: "33%",
    justifyContent: "center",
  },
  donutChartLabel: {
    display: "flex",
    flexDirection: "column",
    fontFamily: fonts.FbReformaRegular,
    textAlign: "center",
    maxWidth: "8rem",
    justifyContent: "center",
    alignItems: "center",
    color: colors.darkBlue,
    gap: ".5rem",
  },
  donutChartValueLabel: {
    fontSize: fontSize.PageTitle,
    marginBlockStart: "0",
    marginBlockEnd: "0",
  },
  donutChartTextLabel: {
    fontSize: fontSize.Paragraph,
  },
  legend: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "5rem",
    fontSize: fontSize.Text,
    gap: ".5rem",
    letterSpacing: "0.0275rem",
    textAlign: "center",
    color: colors.nero,
  },
  legendSquare: {
    width: "1.2rem",
    height: "1.2rem",
    borderRadius: "6px",
  },
  line: {
    width: ".1rem",
    height: "34rem",
    backgroundColor: colors.oldLavender,
  },
  dateUpdated: {
    display: "flex",
    fontFamily: fonts.FbReformaRegular,
    letterSpacing: "0.0275rem",
    fontSize: fontSize.Text,
    color: colors.nero,
    fontWeight: "bold",
    paddingTop: "2rem",
  },
};

export default AccurecyIndex;
