import FileSaver from "file-saver";
import JSZip from "jszip";
import Config from "../Configuration/server";

import {
  IFilesToDownload,
  IFilesToDownloadServer,
} from "../Interfaces/IFileViewer";
import { IServerFile, IUmbracoFile } from "../Interfaces/IUmbraco";

export const FilesToZipServiceServer = async (
  files: IServerFile[],
  filename: string
) => {
  const URL = Config.TENDERS_FILES;
  const zip = new JSZip();
  const folder = zip.folder(filename);
  if (folder) {
    const filesToDownload: IFilesToDownloadServer[] = [];
    for (let i = 0; i < files.length; i++) {
      if (filesToDownload[i] === null) continue;
      const res = await fetch(URL + files[i].document);
      const blob = await res.blob();
      const fileToDownload: IFilesToDownloadServer = {
        file: {
          document: files[i].document,
          filename: files[i].filename,
          displayName: files[i].filename,
        },
        blob,
      };
      filesToDownload.push(fileToDownload);
    }
    for (let i = 0; i < filesToDownload.length; i++) {
      let fileType = filesToDownload[i].blob.type.split("/")[1].split("+")[0];
      switch (fileType) {
        case "vnd.openxmlformats-officedocument.wordprocessingml.document":
          fileType = "docx";
          break;
          case "msword":
          fileType = "doc";
          break;
        case "vnd.ms-excel":
          fileType = "xls";
          break;
          case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          fileType = "xlsx";
          break;
      }
      folder.file(
        `${filesToDownload[i].file.filename}.${fileType}`,
        filesToDownload[i].blob
      );
    }
    zip.generateAsync({ type: "blob" }).then(function (content) {
      FileSaver.saveAs(content, `${filename}.zip`);
    });
  }
};
