import { t } from "i18next";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Images from "../../../Data/Images/Images";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { setPage } from "../../../Redux/Slices/pageSlice";
import {
  ScheduleState,
  setDestinationStation,
  setOriginStation,
} from "../../../Redux/Slices/scheduleSlice";
import { IsMobile } from "../../../Services/DeviceService";
import i18n from "../../../Services/i18n";

const SearchResultsButtonsMobile: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const schedule = useSelector(
    (state: any) => state.ScheduleReducer
  ) as ScheduleState;

  const handleBack = () => {
    dispatch(setPage("routePlan"));
    navigate(
      `?page=routePlan&step=when&fromStation=${schedule.fromStation}&toStation=${schedule.toStation}&date=${schedule.date}&hours=${schedule.hours}&minutes=${schedule.minutes}&scheduleType=${schedule.scheduleType}`
    );
  };

  const handleClose = () => {
    dispatch(setOriginStation(undefined));
    dispatch(setDestinationStation(undefined));
    navigate("/");
  };

  return (
    <div style={styles.mainWrapper}>
      <img
        tabIndex={!IsMobile() ? 0 : -1}
        role={"button"}
        style={{
          ...styles.button,
          rotate: i18n.dir() === "ltr" ? "180deg" : "0deg",
          scale: 1.5,
          transform: `translateX(${
            i18n.dir() === "rtl" ? "-.5rem" : "-.5rem"
          })`,
        }}
        src={Images.backButtonMob}
        onClick={handleBack}
        width={`auto`}
        height={`auto`}
        alt={`${t(`BackTo`, {
          step: `${t(`When`)}`,
        })}`}
      />
      <img
        style={{
          ...styles.button,
          scale: 1.5,
          transform: `translateX(${i18n.dir() === "rtl" ? ".5rem" : "-.5rem"})`,
        }}
        src={Images.closeButtonMob}
        onClick={handleClose}
        width={`auto`}
        height={`auto`}
        tabIndex={!IsMobile() ? 0 : -1}
        alt={`${t(`ExistWizard`)}`}
      />
    </div>
  );
};

const styles: StyleSheet = {
  mainWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  button: {
    cursor: "pointer",
  },
};

export default SearchResultsButtonsMobile;
