import { t } from "i18next";
import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import store from "store2";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import Loading from "../../Components/Loading/Loading";
import NoTrains from "../../Components/NoTrains/NoTrains";
import RouteDetails from "../../Components/RouteDetails/RouteDetails";
import RoutePlanBar from "../../Components/RoutePlanBar/RoutePlanBar";
import RoutePlanMultiProps from "../../Components/RoutePlanBar/RoutePlanMultiProps";
import { TabsColumn } from "../../Components/Tabs/Tabs";
import TrainCard from "../../Components/TrainCard/TrainCard";
import Config from "../../Configuration/server";
import Images from "../../Data/Images/Images";
import { IRoutePlanSearchResultsDesktop } from "../../Interfaces/IRoutePlanSearchResults";
import { IStation } from "../../Interfaces/IStation";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { ITab } from "../../Interfaces/ITabs";
import { ITrain } from "../../Interfaces/ITrain";
import { PageState } from "../../Redux/Slices/pageSlice";
import {
  ScheduleState,
  ScheduleStateBuild,
} from "../../Redux/Slices/scheduleSlice";
import { IsMobile } from "../../Services/DeviceService";
import { GetSortedStations } from "../../Services/SearchStationsAutoCompleteService";
import i18n from "../../Services/i18n";
import {
  GetStationsDictionary,
  IDictStation,
  getTravelPrice
} from "../../Utils/helpers";
// import { createEvent, EventAttributes } from "ics";
import RoutePlanFilterBar from "../../Components/RoutePlanBar/RoutePlanFilterBar";
import {
  linkBuilder,
  linkShareFormat,
} from "../../Components/SharePage/ShareOptions";
import { SystemParamsState } from "../../Redux/Slices/systemParamsSlice";

import CustomModal from "../../Components/CustomModal";
import { Translations } from "../../Services/GlobalService";
import { GeoToKm } from "../../Services/LocationService";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";
import {
  maxChagnes,
  rangeDurationCalc,
} from "./Mobile/RoutePlanSearchResultsMobile";
import RouteToCalendar from "./RouteToCalendar";

const RoutePlanSearchResults: React.FC<IRoutePlanSearchResultsDesktop> = (
  props
) => {
  const {
    travels,
    allTravels,
    isLoading,
    isNoTrains,
    selectedTravel,
    noTrainsInfo,
    selectedTrainKey,
    dateTitle,
    onClickTravelCard,
    getDay,
    onClickRoutePlanBarSearch,
    onSearchMulti,
    onTabClick,
    isToday,
    multisearch,
    onSortClick,
    onFilterClick,
    onFilterClear,
  } = props;

  const [_stationKey, _setStationKey] = useState<IDictStation>({});
  const [_station, _setStation] = useState<IStation[]>([]);
  const [_tabs, _setTabs] = useState<ITab[]>([]);
  const [_tabSelected, _setTabSelected] = useState<string>("");
  const [_selectedTrainToShare, _setSelectedTrainToShare] = useState<ITrain>();
  const [_isShareModalVisible, _setIsShareModalVisible] = useState(false);
  const [_travelKm, _setTravelKm] = useState("");
  const [_isAddCalendarModalVisible, _setIsAddCalendarModalVisible] =
    useState(false);
  const [_selectedTrainToCalendar, _setSelectedTrainToCalendar] =
    useState<ITrain>();
  const systemParams = (
    useSelector((state: any) => state.SystemParamsReducer) as SystemParamsState
  ).systemParams;
  const [_isNotificationListVisible, _setIsNotificationListVisible] =
    useState(false);

  const [_currentNotificationTrainIndex, _setCurrentNotificationTrainIndex] =
    useState(-1);

  const titleRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const deviceMode = useDeviceMode();
  const Translation = Translations[
    i18n.language as `he` | `en` | `ar` | `ru`
  ] as any;

  const schedule = useSelector(
    (state: any) => state.ScheduleReducer
  ) as ScheduleState;

  const currentPage = (
    useSelector((state: any) => state.PageReducer) as PageState
  ).page;

  const getTotalTaarif = async () => {
    const stations: IStation[] = store.session.get(
      `${Config.BASE_LOCALSTORAGE_NAME}stations`
    )[i18n.language];
    const dictStations = GetStationsDictionary(stations);

    if (
      schedule.fromStation !== undefined &&
      schedule.toStation !== undefined
    ) {
      const fromStationLongLant = [
        dictStations[schedule.fromStation].location.latitude,
        dictStations[schedule.fromStation].location.lontitude,
      ];
      const toStationLongLant = [
        dictStations[schedule.toStation].location.latitude,
        dictStations[schedule.toStation].location.lontitude,
      ];
      const travelPriceInfo = await getTravelPrice({ fromStation: schedule.fromStation, toStation: schedule.toStation });

      let distance = GeoToKm(fromStationLongLant, toStationLongLant);
      const maxDistance = travelPriceInfo.search?.maxRange;
      if (maxDistance) {
        const maxKm = maxDistance.split("-").pop();
        if (maxKm) {
          _setTravelKm(maxKm);
        }
      }
    }
  };

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.focus();
      titleRef.current.style.outline = "none";
      titleRef.current.tabIndex = -1;
    }
  }, [location, currentPage]);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    multisearch && SearchBarParams();
  }, [_stationKey]);

  const SearchBarParams = () => {
    let url = new URLSearchParams(window.location.search);
    let params: any = {
      fromStation: url.get("fromStation")?.split(","),
      toStation: url.get("toStation")?.split(","),
      date: url.get("date")?.split(","),
      hours: url.get("hours")?.split(","),
      minutes: url.get("minutes")?.split(","),
      scheduleType: url.get("scheduleType")?.split(","),
    };
    let _schedules: ScheduleStateBuild[] = [];
    for (let index = 0; index < params.fromStation.length; index++) {
      let obj = {
        fromStation: params.fromStation[index],
        toStation: params.toStation[index],
        date: params.date[index],
        hours: params.hours[index],
        minutes: params.minutes[index],
        scheduleType: params.scheduleType[index],
      };
      _schedules.push(obj);
    }
    SetTab(_schedules);
  };
  const init = () => {
    let res = GetSortedStations();
    let resKey = GetStationsDictionary(res);
    _setStationKey(resKey);
    _setStation(res);
    getTotalTaarif();
  };

  const SetTab = (schedules: ScheduleStateBuild[]) => {
    let Tabs: ITab[] = [];
    for (let index = 0; index < schedules.length; index++) {
      const element = schedules[index];
      if (element.fromStation && element.toStation) {
        Tabs.push({
          id: `${element.fromStation}-${element.toStation}-${index}`,
          name: `${_stationKey[element.fromStation]?.stationName}-${_stationKey[element.toStation]?.stationName
            }`,
          isSelected: index === 0,
        });
      }
      index === 0 &&
        _setTabSelected(`${element.fromStation}-${element.toStation}-${index}`);
    }

    _setTabs(Tabs);
  };
  const getShareTravelUrl = (url: string) => {
    let allStations = store.session.get(
      `${Config.BASE_LOCALSTORAGE_NAME}stations`
    );
    let Stations: any = GetStationsDictionary(
      allStations ? allStations[i18n.language] : []
    );
    let fromStation = schedule.fromStation
      ? Stations[schedule.fromStation].stationName
      : "";
    let toStation = schedule.toStation
      ? Stations[schedule.toStation].stationName
      : "";
    if (_selectedTrainToShare) {
      const date = moment(_selectedTrainToShare.departureTime).toDate();
      const dateFormat = moment(date).format("yyyyMMDD");

      const hourNumber = date.getHours();
      const minuteNumber = date.getMinutes();
      const hours = hourNumber <= 9 ? `0${hourNumber}` : hourNumber.toString();
      const minutes =
        minuteNumber <= 9 ? `0${minuteNumber}` : minuteNumber.toString();

      const description = `${Translation["travel by train"]} ${Translation["from station"]} ${fromStation} ${Translation["to station"]} ${toStation}%0A`;

      const andTime = `${t("date")}: ${dateFormat}%0A${t(
        "HourText"
      )}: ${hours}:${minutes}%0A`;

      const navigateUrl = `${window.location.href.split("/?")[0]}/mldp?FSID=${schedule.fromStation
        }&TSID=${schedule.toStation
        }&Date=${dateFormat}&Hour=${hours}${minutes}&Train=${_selectedTrainToShare}`.replaceAll(
          "&",
          "%26"
        );

      linkShareFormat.title = description;
      linkShareFormat.subject = t("sharedWithYou") + " " + description;
      linkShareFormat.body = encodeURIComponent(
        description + andTime + " " + navigateUrl
      );
      linkShareFormat.summary = encodeURIComponent(description + " " + andTime);
      linkShareFormat.text = encodeURIComponent(
        description + andTime + " " + navigateUrl
      );
      linkShareFormat.quote = description;
      linkShareFormat.url = encodeURIComponent(navigateUrl);
      linkShareFormat.u = encodeURIComponent(navigateUrl);
      // sharePagetext
      let newLink = linkBuilder(url, linkShareFormat);
      return decodeURI(newLink);
    }
    return "";
  };

  // window.innerWidth-10 10 pixels, to make sure the title does not exceed the scroll
  const [isFilterBarExpanded, setIsFilterBarExpanded] = useState(false);
  const handleFilterBarToggle = () => {
    setIsFilterBarExpanded((prevState) => !prevState);
  };
  return (
    <Fragment>
      <div style={styles.mainWrapper}>
        <div
          className="route-plan-bar"
          // style={{ width: window.innerWidth - 10 }}
        >
          <div
            ref={titleRef}
            style={{ position: "absolute", zIndex: -1, color: "transparent" }}
            tabIndex={!IsMobile() ? 0 : -1}
          >
            {t("CurrentPage", {
              page: t("SearchResults"),
            })}
          </div>

          {multisearch ? (
            <RoutePlanMultiProps
              multisearch={multisearch}
              onSearchMulti={onSearchMulti}
              onTabClick={onTabClick}
              onSearchTrainsClick={onClickRoutePlanBarSearch}
              isLoading={isLoading}
            />
          ) : (
            <div style={styles.filterWrapper}>
              <RoutePlanBar
                onSearchTrainsClick={onClickRoutePlanBarSearch}
                isLoading={isLoading}
                onFilterBarExpanded={handleFilterBarToggle}
              />

              {systemParams.find((item) => item.key === "showRouteResultFilter")
                ?.value === "true" && (
                  <div
                    style={{
                      display: isFilterBarExpanded ? "block" : "none",
                    }}
                  >
                    {(allTravels && allTravels.length > 0) && (
                      <RoutePlanFilterBar
                        maxChanges={maxChagnes(allTravels ? allTravels : [])}
                        rangeDuration={rangeDurationCalc(
                          allTravels ? allTravels : []
                        )}
                        onSortClick={onSortClick}
                        onFilterClick={onFilterClick}
                        onFilterClear={onFilterClear}
                        isMobile={false}
                      />
                    )}
                  </div>
                )}
            </div>
          )}
        </div>

        <div style={{ display: "flex", width: "100%" }}>
          {multisearch && _tabs.length > 0 && (
            <TabsColumn
              tabs={_tabs}
              onTabClick={(select: ITab) => {
                let indexOf = _tabs.findIndex((item) => item.id === select.id);
                let indexOfSelect = _tabs.findIndex((item) => item.isSelected);
                _tabs[indexOf].isSelected = true;
                _tabs[indexOfSelect].isSelected = false;
                _setTabs([..._tabs]);
                _setTabSelected(select.id);
                props.onTabClick && props.onTabClick(select.id);
              }}
            />
          )}
          {isLoading ? (
            <Loading
              message="Loading-schedule"
              containerStyles={{ backgroundColor: colors.ghostWhite }}
            />
          ) : (
            <Fragment>
              {!isNoTrains ? (
                <div style={styles.body} className="body-list-trains">
                  <div
                    style={styles.trainsCardsWrapper}
                    id="trainsCardsWrapper"
                    className="list-trains"
                  >
                    <h1
                      style={{
                        ...styles.availableTrains,
                        ...styles.dateTitle,
                        flexDirection: "column",
                        gap: "1rem",
                        // width: "50%",
                        padding: "0 15%",
                        marginInlineEnd: "2rem",
                        alignItems: "unset",
                      }}
                      tabIndex={!IsMobile() ? 0 : -1}
                    >
                      <span
                        style={{
                          fontFamily: fonts.FbReformaMedium,
                          fontSize: fontSize.Paragraph,
                        }}
                      >
                        {t("Available-trains")}{" "}
                      </span>
                      <span
                        style={{
                          fontWeight: "300",
                          fontFamily: fonts.FbReformaLight,
                          fontSize: fontSize.SubTitle,
                        }}
                      >
                        <b>{dateTitle}</b> |{" "}
                        {t("TaarifKmDistance", {
                          km: _travelKm,
                        })}
                      </span>
                      <span></span>
                    </h1>
                    <div style={styles.trainsCards} className="trains-cards">
                      <div style={styles.dateTitle}>
                        <div></div>
                        <div>
                          {!isToday && (
                            <div
                              style={{
                                ...styles.nextPreviousContainer,
                                paddingInlineStart:
                                  deviceMode === StyleMode.desktop
                                    ? "15rem"
                                    : "",
                              }}
                              onClick={() => {
                                getDay("searchTrainLuzBeforeDate");
                              }}
                            >
                              <button
                                style={styles.nextPrevious}
                                role={"button"}
                                tabIndex={!IsMobile() ? 0 : -1}
                                aria-label={t("View-previous-day")}
                              >
                                {t("View-previous-day")}
                              </button>
                              <img
                                src={Images.arrowUp}
                                style={styles.arrowIcon}
                                width={`auto`}
                                height={`auto`}
                                alt={``}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      {travels.map((train, key) => (
                        <div
                          id={`trainNumber_${train.trains[0].trainNumber}`}
                          key={train.arrivalTime + train.departureTime + key}
                          className="train-card"
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            onClickTravelCard(train);
                          }}
                        >
                          <TrainCard
                            {...train.trains[0]}
                            myDestanation={
                              train.trains[train.trains.length - 1]
                                .destinationStation
                            }
                            travelMessages={train.travelMessages}
                            arrivalTime={train.arrivalTime}
                            departureTime={train.departureTime}
                            changes={train.trains.length - 1}
                            lastDestanation={
                              train.trains[train.trains.length - 1]
                                .routeStations[
                                train.trains[train.trains.length - 1]
                                  .routeStations.length - 1
                              ].stationId
                            }
                            isSelected={
                              `${train.trains[0].arrivalTime}-${train.trains[0].departureTime}` ===
                              selectedTrainKey
                            }
                            key={key}
                            index={key}
                            totalTrains={travels.length}
                            onNotificationButtonClick={(
                              trainIndex,
                              selectedTrain
                            ) => {
                              _setSelectedTrainToShare(selectedTrain);

                              _setIsNotificationListVisible(
                                !_isNotificationListVisible
                              );
                              if (!_isNotificationListVisible) {
                                _setCurrentNotificationTrainIndex(trainIndex);
                              } else {
                                _setCurrentNotificationTrainIndex(-1);
                              }
                            }}
                            trainPosition={train.trains[0].trainPosition}
                            onAddToCalendar={(trainIndex, train) => {
                              _setIsAddCalendarModalVisible(true);
                              _setSelectedTrainToCalendar(train);
                            }}
                          />
                          <div>
                            {`${train.trains[0].arrivalTime}-${train.trains[0].departureTime}` ===
                              selectedTrainKey && (
                                <img
                                  src={Images.arrowSide}
                                  style={{
                                    filter: `brightness(0.3)`,
                                    transform: `rotate(${i18n.dir() === "rtl" ? "180deg" : ""
                                      })`,
                                  }}
                                  alt={``}
                                  width={"20"}
                                  height={"20"}
                                />
                              )}
                          </div>
                        </div>
                      ))}
                      <div style={styles.bottomCards}>
                        <div></div>
                        <div
                          style={styles.nextPreviousContainer}
                          onClick={() => {
                            getDay("searchTrainLuzAfterDate");
                          }}
                        >
                          <button
                            id="nextTrainsButton"
                            style={styles.nextPrevious}
                            role={"button"}
                            tabIndex={-1}
                            aria-label={t("View-next-day")}
                          >
                            {t("View-next-day")}
                          </button>
                          <img
                            src={Images.arrowDown}
                            style={styles.arrowIcon}
                            width={`auto`}
                            height={`auto`}
                            alt={``}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={styles.routeDetailsContainer}
                    id={"routeDetailsContainer"}
                  >
                    <RouteDetails travel={selectedTravel} />
                  </div>
                </div>
              ) : (
                <div style={styles.noTrainsContainer}>
                  <NoTrains
                    clientMessageId={noTrainsInfo.clientMessageId}
                    lastTrainDate={noTrainsInfo.lastTrainDate}
                    nextTrainDate={noTrainsInfo.nextTrainDate}
                    messageTitle={noTrainsInfo.messageTitle}
                    shareStatus={noTrainsInfo.shareStatus}
                    onClick={noTrainsInfo.onClick}
                  />
                </div>
              )}
            </Fragment>
          )}
        </div>
      </div>
      <CustomModal visible={_isAddCalendarModalVisible}
        contentStyle={{ padding: 0 }}
        innerStyle={{ width: "85%", minHeight: "27rem", padding: 0, lineHeight: "1rem",
        maxWidth: "45rem",
        borderRadius: "13px 13px 288px 288px / 13px 13px 43px 43px",
          overflow:"visible"}}
        onClose={() => {
          _setIsAddCalendarModalVisible(false);
        }} showCloseButton="external"  >
        {_selectedTrainToCalendar && (
          <>
            <RouteToCalendar
              {..._selectedTrainToCalendar}
              toStation={schedule.toStation}
              fromStation={schedule.fromStation}
            />
          </>
        )}
      </CustomModal>

    </Fragment>
  );
};

const styles: StyleSheet = {
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100vh",
  },
  body: {
    display: "flex",
    width: "100%",
    // paddingTop: "6rem",
    backgroundColor: colors.ghostWhite,
  },
  bottomCards: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "1rem",
    marginInlineStart: "15%",
    marginInlineEnd: "15%",
    marginBottom: "1rem",
  },
  dateTitle: {
    display: "flex",
    justifyContent: "space-around",
    gap: "5rem",
    alignItems: "center",
    color: colors.darkBlue,
    fontSize: fontSize.UmbracoElementTitle,
  },
  availableTrains: {
    fontSize: fontSize.UmbracoElementTitle,
  },
  nextPreviousContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  nextPrevious: {
    color: colors.oldLavender,
    cursor: "pointer",
    border: "none",
    backgroundColor: "transparent",
    fontSize: fontSize.Text,
    fontFamily: fonts.FbReformaMedium,
  },
  trainsCardsWrapper: {
    display: "flex",
    flexDirection: "column",
    flex: "2",
    maxHeight: "90vh",
    marginTop: "1rem",
  },
  trainsCards: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    maxHeight: "100%",
    overflow: "auto",
  },
  noTrainsContainer: {
    width: "100%",
    height: "100vh",
    backgroundColor: colors.ghostWhite,
  },
  routeDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.white,
    border: `.1rem solid ${colors.pastelGray}`,
    // maxHeight: "90vh",
    flex: "1",
  },
  arrowIcon: {
    cursor: "pointer",
  },
};

export default RoutePlanSearchResults;
