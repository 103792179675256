import { useState } from "react";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import {
  IFaresCalculation
} from "../../Interfaces/IFaresCalculation";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import Title from "../UmbracoElements/Title";
import CardPrices from "./CardPrices";
import FromToStation, { ICalcResult } from "./FromToStation";

const Fares: React.FC<IFaresCalculation> = (props) => {
  const { dynamicPart } = props;
  const [_data, _setData] = useState<ICalcResult[]>([]);
  const elements = dynamicPart.dynamicElements;

  return (
    <div style={styles.container}>
      <Title
        text={
          elements.find(
            (dynamicElement) => dynamicElement.textID === "FaresTitle"
          )?.text || ""
        }
        titleStyles={{
          display: "flex",
          fontSize: fontSize.H1,
          fontFamily: fonts.FbReformaMedium,
          color: colors.darkBlue,
        }}
        color=""
      />
      <FromToStation
        onSearch={(data: ICalcResult[]) => {
      
          _setData(data);
        }}
      />
     

      <CardPrices cardPrices={_data} />
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    minHeight: "100vh",
    width: "80%",
    maxWidth: "76rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    flexWrap: "wrap",
  }
};

export default Fares;
