import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IURLTranslation } from "../../Interfaces/IConfig";

export interface UrlTranslationsState {
  urlTranslations: IURLTranslation;
}

const initialState: UrlTranslationsState = {
  urlTranslations: {},
};

export const urlTranslationsSlice = createSlice({
  name: "urlTranslations",
  initialState,
  reducers: {
    setUrlTranslations: (state, action: PayloadAction<IURLTranslation>) => {
      state.urlTranslations = action.payload;
    },
  },
});

export const { setUrlTranslations } = urlTranslationsSlice.actions;

export default urlTranslationsSlice.reducer;
