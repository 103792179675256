import moment from "moment";
import { IUpdates } from "../Interfaces/ITree";
import { IUpdatesContent } from "../Interfaces/IUmbraco";
import { ISeo } from "../Interfaces/IUpdates";
import Store from "../Redux/Store/Store";
import { UpdatesTypes } from "./consts";
import { CheckTimeActive } from "./utils";

export const GetUpdatesByType = (
  type:
    | typeof UpdatesTypes.regular
    | typeof UpdatesTypes.special
    | typeof UpdatesTypes.permanent,
  stationId = 0
) => {
  // const updatesContent = await GetUpdates(i18n.language);
  const updatesContent =
    Store.getState().UmbracoStoreReducer.umbracoStore.updates ||
    ({} as IUpdates);
  let activeUpdates: IUpdatesContent[] = [];
  let currentUpdates: IUpdatesContent[] = updatesContent.updates as [];

  currentUpdates &&
    currentUpdates.map((update) => {
      if (
        CheckTimeActive(update?.startDate, update?.endDate) &&
        update.updateType === type
      ) {
        if (type === UpdatesTypes.special) {
          update.stations.map((station) => {
            let station_id = station.split(`^`)[1];
            if (station_id === stationId.toString()) {
              activeUpdates.push(update);
            }
          });
        } else {
          activeUpdates.push(update);
        }
      }
    });

  let sortUpdates = activeUpdates.sort(
    (item1: IUpdatesContent, item2: IUpdatesContent) =>
      moment(item1.startDate).isBefore(item2.startDate)
        ? 1
        : moment(item2.startDate).isBefore(item1.startDate)
        ? -1
        : 0
  );

  let resultSeo: ISeo = updatesContent;
  let resultUpdate = { updates: sortUpdates, seo: resultSeo };
  return resultUpdate;
};

export const GetRegularAndPermanentUpdates = () => {
  // const updatesContent = await GetUpdates(i18n.language);
  const updatesContent =
    Store.getState().UmbracoStoreReducer.umbracoStore.updates ||
    ({} as IUpdates);
  let activeUpdates: IUpdatesContent[] = [];
  let currentUpdates: IUpdatesContent[] = updatesContent.updates as [];

  currentUpdates &&
    currentUpdates.map((update) => {
      if (
        CheckTimeActive(update?.startDate, update?.endDate) &&
        (update.updateType === "Permanent" ||
          (update.updateType === "Regular" && update.stations.length === 0))
      ) {
        activeUpdates.push(update);
      }
    });

  let sortUpdates = activeUpdates.sort(
    (item1: IUpdatesContent, item2: IUpdatesContent) =>
      moment(item1.startDate).isBefore(item2.startDate)
        ? 1
        : moment(item2.startDate).isBefore(item1.startDate)
        ? -1
        : 0
  );

  let resultSeo: ISeo = updatesContent;
  let resultUpdate = { updates: sortUpdates, seo: resultSeo };
  return resultUpdate;
};
