import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IFooter } from "../../Interfaces/IFooter";

export interface FooterState {
  footer: IFooter;
}

const initialState: FooterState = {
  footer: {},
};

export const footerSlice = createSlice({
  name: "footer",
  initialState,
  reducers: {
    setFooter: (state, action: PayloadAction<IFooter>) => {
      state.footer = action.payload;
    },
  },
});

export const { setFooter } = footerSlice.actions;

export default footerSlice.reducer;
