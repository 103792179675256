import colors from "../../../../../Assets/Colors/Colors.json";
import Images from "../../../../../Data/Images/Images";
import { StyleSheet } from "../../../Interfaces_tenders/IStyleSheet";
import { IServerFile } from "../../../Interfaces_tenders/IUmbraco";
import fonts from "../../../../../Assets/Fonts/Fonts.json";
import { truncateText } from "../../../../../Utils/helpers";
import { useState } from "react";
import { HiDownload } from "react-icons/hi";
import Config from "../../../../../Configuration/server";

const ServerFile: React.FC<IServerFile> = (props) => {
  const {
    filename,
    onClick,
    filenameStyles,
    onChangeFileName,
    displayName,
    isMobile,
    isSingleDownload = false,
  } = props;

  const iconExtenstion = props.document.split(".").pop();
  const [_isDownloadHover, _setIsDownloadHover] = useState(false);

  const downloadFileFromURL = (url: string, filename: string) => {
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = filename;

        a.style.display = "none";
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        window.URL.revokeObjectURL(a.href);
      })
      .catch((error) => {
        console.error("Error while downloading the file:", error);
      });
  };

  return (
    <div style={styles.fileContainer}>
      <img
        src={
          Images[
            `${iconExtenstion?.toLowerCase()}Icon` as keyof {
              [key: string]: any;
            }
          ]
        }
        style={{
          ...styles.fileIcon,
        }}
      ></img>
      <div
        tabIndex={0}
        style={{
          ...styles.filename,
          ...filenameStyles,
          cursor: props.document ? "pointer" : "",
          flex: 1,
        }}
        onClick={() => {
          onClick && onClick();
          onChangeFileName && onChangeFileName(filename);
        }}
      >
        {isMobile
          ? displayName
            ? truncateText(displayName, 25)
            : truncateText(filename, 15)
          : displayName
          ? truncateText(displayName, 25)
          : truncateText(filename, 15)}
      </div>
      {isSingleDownload && (
        <div
          style={styles.download}
          onMouseOver={() => _setIsDownloadHover(true)}
          onMouseLeave={() => _setIsDownloadHover(false)}
          onClick={() =>
            downloadFileFromURL(Config.TENDERS_FILES + props.document, filename)
          }
        >
          <HiDownload
            size={"20"}
            color={_isDownloadHover ? colors.cyanBold : colors.black}
          />
        </div>
      )}
    </div>
  );
};

const styles: StyleSheet = {
  fileContainer: {
    display: "flex",
    gap: ".5rem",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  fileIcon: {
    backgroundRepeat: "no-repeat",
    width: "1.6rem",
    height: "1.6rem",
  },
  filename: {
    color: colors.cyanBold,
    fontFamily: fonts.FbReformaRegular,
    letterSpacing: "0.0275rem",
  },
  download: {
    marginInlineEnd: "1rem",
    cursor: "pointer",
  },
};

export default ServerFile;
