import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import { IFilterItem } from "../../Interfaces/IFilterItem";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { IsMobile } from "../../Services/DeviceService";

const FilterItem: React.FC<IFilterItem> = (props) => {
  const { value, count, isSelected = false, onClick, id } = props;

  return (
    <div
      tabIndex={!IsMobile() ? 0 : -1}
      role={"button"}
      style={{
        ...styles.container,
        backgroundColor: isSelected ? colors.pattensBlue : colors.white,
        // color: isSelected ? colors.darkBlue : colors.dimGray,
        color: colors.darkBlue,
      }}
      onClick={() => {
        if (!isSelected) {
          onClick && onClick(id);
        }
      }}
    >
      <div>{value}</div>
      <div>({count})</div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: colors.pattensBlue,
    fontFamily: fonts.FbReformaRegular,
    borderRadius: "1rem",
    height: "1.5rem",
    gap: ".2rem",
    cursor: "pointer",
    letterSpacing: "0.05625rem",
    border: `1px solid ${colors.darkBlue}`,
    whiteSpace: "pre",
    paddingInline: "1rem",
    boxSizing: "border-box",
  },
};

export default FilterItem;
