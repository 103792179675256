import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { ISteps } from "../../../Interfaces/IUmbraco";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import Title from "../Title";
import Step from "./Step";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";

const Steps: React.FC<ISteps> = (props) => {
  const { title, steps, anchor } = props;

  const deviceMode = useDeviceMode();
  return (
    <div className="container" style={styles.mainContainer} anchor-id={anchor}>
      <Title text={title} />
      <div
        className="wapper"
        style={{
          ...styles.stepsContainer,
          flexWrap: deviceMode === StyleMode.tablet ? "wrap" : "nowrap",
        }}
      >
        {steps.map((step, index) => (
          <Step {...step} key={index} />
        ))}
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "6rem",
  },
  title: {
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.UmbracoElementTitle,
    borderBottom: `1px solid ${colors.koromiko}`,
    color: colors.darkBlue,
    borderBottomWidth: ".2rem",
    padding: ".5rem 0",
  },
  stepsContainer: {
    justifyContent: "space-between",
    display: "flex",
    gap: "5rem",
  },
};

export default Steps;
