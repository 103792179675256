export const IsMobile = () => {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true;
    // true for mobile device
    // console.log("mobile device");
  } else {
    return false;
    // false for not mobile device
    // console.log("not mobile device");
  }
};
