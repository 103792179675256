import { t } from "i18next";
import ReactModal from "react-modal";
import colors from "../../../Assets/Colors/Colors.json";
import Images from "../../../Data/Images/Images";
import {
  IArticleModal
} from "../../../Interfaces/IMagazinePage";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { IsMobile } from "../../../Services/DeviceService";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import Article from "./Article";

const ArticleModal: React.FC<IArticleModal> = (props) => {
  const { article, onClose, anchor } = props;
  const deviceMode = useDeviceMode();

  return (
    <ReactModal
      anchor-id={anchor}
      isOpen={true}
      ariaHideApp={false}
      onRequestClose={() => onClose()}
      style={{
        content: deviceMode === StyleMode.desktop ? styles.content : styles.contentMobile,
        overlay: {
          backgroundColor: colors.modal,
          zIndex: "99999",
          overflow: "hidden",
        },
      }}
      parentSelector={() => document.querySelector("#root") || document.body}
      preventScroll={true}
      appElement={document.getElementById("#root") || document.body}
    >
      <img
        src={Images.closeButtonMob}
        style={styles.closeIcon}
        alt={t(`Close`)}
        aria-label={t(`Close`)}
        tabIndex={!IsMobile() ? 0 : -1}
        onClick={onClose}
        width={`auto`}
        role={"button"}
        height={`auto`}
      />
      <div style={styles.iconContainer}>
        <img src={Images.bellBigIcon} style={styles.bellIcon} />
      </div>
      <div style={styles.modalBody}>
        <Article
          {...article}
          containerStyles={{ minHeight: "50%" }}
        />
      </div>
    </ReactModal>
  );
};

const styles: StyleSheet = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    width: "100%",
    maxWidth: "50rem",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "none",
    position: "absolute",
    maxHeight: "88vh",
    overflow: "visible",
    borderRadius: "13px 13px 288px 288px / 13px 13px 43px 43px",
  },
  contentMobile: {
    top: "auto",
    left: 0,
    right: 0,
    bottom: 0,

    maxWidth: "50rem",
    border: "none",
    padding: "none",
    position: "absolute",
    maxHeight: "88vh",
    overflow: "visible",
    borderRadius: "0.5rem 0.5rem 0 0",
  },
  modalBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    maxHeight: "80vh",
    overflow: "auto",
    paddingTop: "2rem",
    paddingInline: "2rem",
    boxSizing: "border-box"
  },
  iconContainer: {
    width: "4.3rem",
    height: "4.3rem",
    boxShadow: "0 5px 11px 0 rgba(0, 0, 0, 0.21)",
    borderRadius: "50%",
    backgroundColor: "white",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  bellIcon: {
    transform: "scale(.8)",
  },

  closeIcon: {
    position: "absolute",
    top: "-2rem",
    insetInlineEnd: "1rem",
    cursor: "pointer",
  },
};

export default ArticleModal;
