import QueryString from "qs";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import coverImage from "../../../Assets/Backgrounds/rail-close.png";
import colors from "../../../Assets/Colors/Colors.json";
import i18n from "../../../Services/i18n";
import {
  IAuctionIconAndDescriptionGallery,
  IAuctionIconAndDescriptionItem,
  IAuctionsSystem,
  IAuctionsSystemContainer,
} from "../Interfaces_tenders/IAuctionsSystem";

import { t } from "i18next";
import fonts from "../../../Assets/Fonts/Fonts.json";
import {
  GetAuctionsByType,
  GetTypes,
} from "../../../Services/tenderServerServices";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import Breadcrumb from "../Components/UmbracoElements/Breadcrumb";
import IconAndDescriptionGallery from "../Components/UmbracoElements/IconAndDescriptionGallery";
import {
  IAuctionsSystemAuctions,
  IAuctionsSystemTypes,
} from "../Interfaces_tenders/IAuctionsSystemDetails";
import AuctionPage from "./AuctionPage";
import AuctionsSystem from "./AuctionsSystem";

import { useLocation } from "react-router-dom";
import { PageState } from "../../../Redux/Slices/pageSlice";
import { setAuctionsTypes } from "../../../Redux/Slices/tendersSlice";
import { UmbracoStoreState } from "../../../Redux/Slices/umbracoSlice";

const AuctionsContainer: React.FC<IAuctionsSystemContainer> = (props) => {
  const { step, auctionsExtrenal, auctionId } = props;
  const currentPage = (
    useSelector((state: any) => state.PageReducer) as PageState
  ).page;
  const pageRef = useRef<string>(currentPage);

  const [_isRemoveMailingListUrl, _setIsRemoveMailingListUrl] = useState(false);

  const [_auctionSystem, _setAuctionSystem] = useState<IAuctionsSystem>();

  const [_currentAuctionsServer, _setCurrentAuctionsServer] =
    useState<IAuctionsSystemAuctions[]>();

  const _currentTypes = useSelector((state: any) => state.TendersReducer)
    .tenderType as IAuctionsSystemTypes[];

  const dispatch = useDispatch();
  const location = useLocation();

  const umbracoStore = (
    useSelector((state: any) => state.UmbracoStoreReducer) as UmbracoStoreState
  ).umbracoStore;

  /* const { setAuctionsTypes } = bindActionCreators(
    AuctionsActions,

    dispatch
  );*/

  //const location = useLocation();

  const _currentTypesTransformed =
    _currentTypes.length > 0 &&
    _currentTypes
      .filter((x) => x.type === 3)
      .map((file: IAuctionsSystemTypes) => {
        let name = "";
        if (file.name === "generalauctions") {
          name = t("generalAuctions");
        } else if (file.name === "cargoauctions") {
          name = t("cargoAuctions");
        }

        return {
          title: name,
          typeOfPage: file.name,
          icon: "https://media.umbraco.io/stage-israel-railways-heartcore-project/bepnox5h/%D7%A2%D7%A8%D7%A2%D7%95%D7%A8.svg",
        };
      });

  const [_auctionMainDetails, _setAuctionMainDetails] =
    useState<IAuctionIconAndDescriptionGallery>();
  const [_auctionItems, _setAuctionItems] =
    useState<IAuctionIconAndDescriptionItem[]>();
  const deviceMode = useDeviceMode();

  const currentPageIsAuctionSystem =
    _auctionItems &&
    _auctionItems.map((x) => x.typeOfPage).includes(currentPage.toLowerCase());

  useEffect(() => {
    const urlParams = QueryString.parse(location.search.split("?")[1]);

    if (urlParams) {
      if (urlParams.mailingListValue && urlParams.mailingListValue !== "") {
        _setIsRemoveMailingListUrl(true);
      }
    }
  }, [location.search]);

  useEffect(() => {
    if (_auctionItems) {
      const auctions = umbracoStore.tenders?.find(
        (auctionItem) =>
          auctionItem.id.toLowerCase() === currentPage.toLowerCase()
      );

      if (auctions) {
        _setAuctionSystem(auctions);
      }
    }
  }, [_auctionItems, currentPage, location]);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      const typesMainDetails = await GetTypes();
      if (typesMainDetails) {
        const auctionsData: IAuctionsSystemTypes[] = typesMainDetails.result; //.data;
        const currenttypes: IAuctionsSystemTypes[] = auctionsData.map(
          (auction) => ({
            id: auction.id,
            type: auction.type,
            name: auction.name,
          })
        );

        if (isMounted) {
          dispatch(setAuctionsTypes(currenttypes));
        }
      }
    })();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;

    (async () => {
      const autionsDetails = umbracoStore.tenders;

      const currentAvailableAuctions:
        | IAuctionIconAndDescriptionItem[]
        | undefined = autionsDetails?.map((auction) => ({
          icon: auction.auctionsSystem[0].icon,
          title: auction.name,
          typeOfPage: auction.id.toLocaleLowerCase(),
          displayAuction: auction.auctionsSystem[0].displayAuction,
          auctionId: auction._id,
        }));

      if (isMounted) {
        _setAuctionItems(currentAvailableAuctions);
      }
    })();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (
      (step === "openauctions" ||
        step === "closedauctions" ||
        step === "auctionresults") &&
      !_currentAuctionsServer &&
      _currentTypes.length > 0
    ) {
      (async () => {
        const auctionsMainDetails = await GetAuctionsByType(10);

        if (auctionsMainDetails && _auctionSystem) {
          const auctionsData: IAuctionsSystemAuctions[] = auctionsMainDetails;

          const currentAuctionsMainDetails: IAuctionsSystemAuctions[] =
            auctionsData.map((auction) => ({
              ...auction,
              auctionSystem: _auctionSystem.auctionsSystem[0],
            }));
          _setCurrentAuctionsServer(currentAuctionsMainDetails);
        }
      })();
    }
  }, [step, _currentTypes.length, currentPage, i18n.language, _auctionSystem]);
  const currentAuction =
    auctionId &&
    _currentAuctionsServer &&
    _currentAuctionsServer.find(
      (auction) => auction.id === auctionId
    );

  useEffect(() => {
    if (pageRef.current.toLowerCase() !== currentPage.toLowerCase()) {
      window.location.reload();
    }

    pageRef.current = currentPage;
  }, [location, currentPage]);


  return (
    <div
      style={{
        ...styles.mainContainer,
        paddingTop:
          !auctionsExtrenal || deviceMode !== StyleMode.desktop ? 0 : "3rem",
      }}
    >
      {auctionsExtrenal && !_isRemoveMailingListUrl && (
        <div
          style={{
            ...styles.cover,
            background:
              coverImage &&
                currentPageIsAuctionSystem &&
                _auctionSystem &&
                auctionsExtrenal
                ? `30% 30% / cover url(${_auctionSystem &&
                _auctionSystem.auctionsSystem[0].coverImage &&
                _auctionSystem.auctionsSystem[0].coverImage._url
                })`
                : `30% 30% / cover url(${_auctionMainDetails && _auctionMainDetails.mainImage._url
                })`,
            height: "20.5vh",
          }}
        >
          <div
            style={{
              ...styles.coverElements,
              right: i18n.dir() === "rtl" ? "15%" : "0",
              left: i18n.dir() === "rtl" ? "0" : "15%",
              top:
                !auctionsExtrenal || deviceMode !== StyleMode.desktop
                  ? "3.5rem"
                  : "2rem",
            }}
          >
            {_auctionSystem && auctionsExtrenal && (
              <div style={styles.breadcrumb}>
                {!auctionId && currentPageIsAuctionSystem && (
                  <Breadcrumb
                    items={[
                      {
                        text: _auctionSystem.auctionsSystem[0].title.text,
                        link: "",
                      },
                    ]}
                    key={1}
                  />
                )}
                {_auctionSystem && auctionId && currentAuction && (
                  <Breadcrumb
                    items={[
                      {
                        text: _auctionSystem.auctionsSystem[0].title.text,
                        link: `${_auctionSystem?.id.toLocaleLowerCase()}&step=${step}&auctionsExtrenal=${auctionsExtrenal}`,
                      },
                      {
                        text: t("", currentAuction.tenderNumber.toString()),
                        link: "",
                      },
                    ]}
                    key={1}
                  />
                )}
              </div>
            )}
            <div
              tabIndex={0}
              style={{
                display: "flex",
              }}
            >
              <div style={styles.title}>
                {!currentPageIsAuctionSystem &&
                  _auctionMainDetails &&
                  _auctionMainDetails.mainTitle}
                {currentPageIsAuctionSystem &&
                  _auctionSystem &&
                  _auctionSystem.auctionsSystem[0].title &&
                  _auctionSystem.auctionsSystem[0].title.text}
              </div>
            </div>
          </div>
        </div>
      )}

      {currentPageIsAuctionSystem &&
        _auctionSystem &&
        !_isRemoveMailingListUrl &&
        (!auctionId ? (
          <AuctionsSystem
            step={step}
            auctionsExtrenal={auctionsExtrenal}
            auctionsSystem={_auctionSystem}
          />
        ) : (
          _currentAuctionsServer && (
            <AuctionPage
              auctionId={auctionId}
              auctionsSystem={_auctionSystem}
              auctionsSystemServer={_currentAuctionsServer}
            />
          )
        ))}

      {!currentPageIsAuctionSystem &&
        _auctionMainDetails &&
        _auctionItems &&
        _currentTypesTransformed &&
        !_isRemoveMailingListUrl && (
          <IconAndDescriptionGallery
            title={_auctionMainDetails.title}
            page={currentPage}
            step={step}
            isExtrenal={auctionsExtrenal}
            qAandIcons={_currentTypesTransformed.map((auctionItem) => ({
              title: auctionItem.title,
              page: currentPage,
              step: step,
              isExtrenal: auctionsExtrenal,
              typeOfPage: auctionItem.typeOfPage,
              icon: { _url: auctionItem.icon },
            }))}
          />
        )}

    </div>
  );
};

const styles: any = {
  cover: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "40vh",
    position: "relative",
  } as React.CSSProperties,
  title: {
    color: colors.white,
    fontSize: "2.8125rem",
    lineHeight: "3rem",
    letterSpacing: "0.05625rem",
    textShadow: `2px 2px ${colors.black}`,
    fontFamily: fonts.FbReformaMedium,
  } as React.CSSProperties,
  breadcrumb: {
    display: "flex",
    color: colors.white,
    fontSize: "1rem",
    letterSpacing: "0.05625rem",
    fontFamily: fonts.FbReformaRegular,
    gap: ".5rem",
  } as React.CSSProperties,
  coverElements: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    right: "15%",
    top: "2rem",
    gap: "1.2rem",
  } as React.CSSProperties,
};

export default AuctionsContainer;
