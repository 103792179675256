import { useNavigate } from "react-router-dom";
import { ILinkOpenType, ISubMenuItem } from "../../../Interfaces/IMenu";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import fonts from "../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import colors from "../../../Assets/Colors/Colors.json";
import { useSelector } from "react-redux";
import { PageState } from "../../../Redux/Slices/pageSlice";
import { useState } from "react";

const SubMenuItem: React.FC<ISubMenuItem> = (props) => {
  const { label, redirectTo, image, linkTo, itemIndex, totalItems, isEnabled } =
    props;

  const navigate = useNavigate();
  const currentPage = (
    useSelector((state: any) => state.PageReducer) as PageState
  ).page;

  const [_isItemOver, _setIsItemOver] = useState(false);

  const redirectToPage = (page?: string, open?: ILinkOpenType[`Type`]) => {
    let currentOpenType = open ? open : "Internal";
    if (page) {
      switch (currentOpenType) {
        case "Internal":
          navigate(`?page=${page}`);
          break;
        case "External":
          window.open(page, "_blank");
          break;
      }
    }
  };

  

  const handleOver = () => {
    _setIsItemOver(true);
  };

  const handleLeave = () => {
    _setIsItemOver(false);
  };

  return (
    <li
      role={"link"}
      tabIndex={0}
      key={label + redirectTo}
      onMouseOver={() => {
        handleOver();
      }}
      onMouseLeave={() => {
        handleLeave();
      }}
      onFocusCapture={() => {
        handleOver();
      }}
      onBlurCapture={() => {
        handleLeave();
      }}
      onKeyPress={(e) => {
        redirectToPage(redirectTo, linkTo);
      }}
      style={{
        ...styles.subMenuItem,
        cursor: redirectTo !== "" ? "pointer" : "default",
        borderBottom:
          itemIndex < totalItems - 1 ? `.1rem solid ${colors.platinum}` : "",
        color:
          currentPage.toLowerCase() === redirectTo.toLowerCase()
            ? colors.darkBlue
            : _isItemOver
            ? colors.dimGray
            : colors.dimGray,
        fontFamily: _isItemOver
          ? currentPage.toLowerCase() === redirectTo.toLowerCase()
            ? fonts.FbReformaMedium
            : fonts.FbReformaRegular
          : currentPage.toLowerCase() === redirectTo.toLowerCase()
          ? fonts.FbReformaMedium
          : fonts.FbReformaRegular,
        textDecoration:
          _isItemOver && currentPage.toLowerCase() !== redirectTo.toLowerCase()
            ? "underline"
            : "",
      }}
      onClick={(e) => {
        redirectToPage(redirectTo, linkTo);
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          redirectToPage(redirectTo, linkTo);
        }
      }}
    >
      {label}
    </li>
  );
};

const styles: StyleSheet = {
  subMenuItem: {
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Text,
    color: colors.dimGray,
    paddingBlock: "0.75rem",
    lineHeight: "1rem",
  },
};

export default SubMenuItem;
