import AuctionDetailsModal from "../Components/UmbracoElements/Auctions/AuctionDetailsModal/AuctionDetailsModal";
import { IAuctionPage } from "../Interfaces_tenders/IAuctionsSystem";
import { StyleSheet } from "../Interfaces_tenders/IStyleSheet";

import { IAuctionsSystemAuctions } from "../Interfaces_tenders/IAuctionsSystemDetails";

const AuctionPage: React.FC<IAuctionPage> = (props) => {
  const { auctionsSystemServer,auctionId } = props;

  const currentAuction = auctionsSystemServer.find(
    (auction) => auction.id.toString() === auctionId
  );

  return (
    <div style={styles.mainContainer}>
      {currentAuction && (
        <AuctionDetailsModal {...(currentAuction as IAuctionsSystemAuctions)} />
      )}
    </div>
  );
};

const styles: StyleSheet = {
  mainContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    boxSizing: "border-box",
    padding:"1rem"
  }
};

export default AuctionPage;
