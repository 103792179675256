import { useEffect } from "react";
import { IQaAndIcon, IQaAndIcons } from "../../Interfaces/IQaAndIcon";
import { CreateQaAndIconSchema } from "../../Utils/seo";
import QaAndIcon from "./QaAndIcon";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import colors from "../../Assets/Colors/Colors.json";
import Title from "./Title";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";

const QaAndIcons: React.FC<IQaAndIcons> = (props) => {
  const { title, qAandIcons } = props;

  
  useEffect(() => {
    CreateQaAndIconSchema(qAandIcons)
  }, [qAandIcons])

  return (
    <div style={styles.container}>
    <div style={styles.content}>
      {qAandIcons.map((qAandIcon, index) => (
        <div
          key={index}
          style={{
            borderBottom:
              index < qAandIcons.length - 1
                ? `1px solid ${colors.gray}`
                : ``,
            paddingTop: ".5rem",
            paddingBottom: ".5rem",
          }}
        >
          <QaAndIcon {...qAandIcon} />
        </div>
      ))}
    </div>
  </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    maxWidth: "76rem",
    width: "80%",
  },
  title: {
    position: "relative",
    color: colors.darkBlue,
    fontSize: fontSize.UmbracoElementTitle,
    fontFamily: fonts.FbReformaMedium,
    borderBottom: `1px solid ${colors.koromiko}`,
    borderBottomWidth: ".2rem",
  },
  content: {
    display: "flex",
    maxWidth: "52.5rem",
    flexDirection: "column",
  },
};

export default QaAndIcons;
