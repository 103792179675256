import { StyleSheet } from "../../Interfaces/IStyleSheet";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import Images from "../../Data/Images/Images";
import { useEffect, useRef, useState } from "react";
import { IRoutePlanSearchStations } from "../../Interfaces/ISearchStationsAutoComplete";
import StationsListGrouped from "../StationsList/StationsListGrouped";
import { t } from "i18next";
import { IStation } from "../../Interfaces/IStation";
import {
  FilterAutoComplete,
  GetLastStations,
  GetNearbyStations,
  GetSortedStations,
} from "../../Services/SearchStationsAutoCompleteService";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import store from "store2";
import Config from "../../Configuration/server";
import i18n from "../../Services/i18n";
import { GetStationsDictionary } from "../../Utils/helpers";
import QueryString from "qs";
import useDeviceMode from "../../Utils/useWindowDimensions";
import { StyleMode } from "../../Utils/consts";
import {
  ScheduleState,
  setDestinationStation,
  setOriginStation,
} from "../../Redux/Slices/scheduleSlice";
import { PageState } from "../../Redux/Slices/pageSlice";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../Services/DeviceService";
import { GetSystemParams } from "../../Services/GlobalService";

const RoutePlanSearchStations: React.FC<IRoutePlanSearchStations> = (props) => {
  const { placeHolder, stationsType, handleInputFocus } = props;
  const [_stations, _setStations] = useState<IStation[]>([]);
  const [_nearbyStations, _setNearbyStations] = useState<IStation[]>([]);
  const [_lastStations, _setLastStations] = useState<IStation[]>([]);
  const [_isClearButtonVisible, _setIsClearButtonVisible] = useState(false);
  const [_titleValue, _setTitleValue] = useState<string>(t("All-stations"));
  const [_staionShowNumber, _setStaionShowNumber] = useState(3);
  const [_hideLastORNearbyStations, _setHideLastORNearbyStations] =
    useState<boolean>(false);
  const [_searchFiledAria, _setSearchFiledAria] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const originStationInputRef = useRef<HTMLInputElement>(null);
  const destinationStationInputRef = useRef<HTMLInputElement>(null);
  const stationsListWrapperRef = useRef<HTMLDivElement | null>(null);

  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const schedule = useSelector(
    (state: any) => state.ScheduleReducer
  ) as ScheduleState;
  const currentPage = (
    useSelector((state: any) => state.PageReducer) as PageState
  ).page;

  const deviceMode = useDeviceMode();

  useEffect(() => {
    const lastStations = GetLastStations();
    _setLastStations(lastStations);
  }, []);

  useEffect(() => {
    (async () => {
      const nearbyStations = await GetNearbyStations();
      _setNearbyStations(nearbyStations);
      let params = await GetSystemParams();
      params.map((param) => {
        if (param.key === `NumStationByGPS`) {
          _setStaionShowNumber(
            parseInt(param.value && param.value !== "" ? param.value : `3`)
          );
        }
      });
    })();
  }, []);

  useEffect(() => {
    const stations: IStation[] = store.session.get(
      `${Config.BASE_LOCALSTORAGE_NAME}stations`
    )[i18n.language];
    const dictStations = GetStationsDictionary(stations);
    if (inputRef.current) {
      const step = QueryString.parse(location.search.split("?")[1])?.step;
      if (step && (step as string).toLowerCase() === stationsType) {
        if (stationsType === "origin" && schedule.fromStation) {
          // inputRef.current.value =
          //   dictStations[schedule.fromStation].stationName;
          const filtered = stations.find(
            (station) => station.stationId === schedule.fromStation
          );
          const sortedStations = GetSortedStations();
          _setStations(sortedStations);

          // if (filtered) {
          //   _setStations([filtered]);
          // }
          // _setHideLastORNearbyStations(inputRef.current.value !== "");
          // _setTitleValue("");
          handleClearButtonVisible();
        } else if (stationsType === "destination" && schedule.toStation) {
          // inputRef.current.value = dictStations[schedule.toStation].stationName;
          const filtered = stations.find(
            (station) => station.stationId === schedule.toStation
          );
          const sortedStations = GetSortedStations();
          _setStations(sortedStations);

          // if (filtered) {
          //   _setStations([filtered]);
          // }
          // _setHideLastORNearbyStations(inputRef.current.value !== "");
          // _setTitleValue("");
          handleClearButtonVisible();
        } else {
          const sortedStations = GetSortedStations();
          _setStations(sortedStations);
        }
      }
    }
  }, [location, schedule]);

  useEffect(() => {
    // scroll stations list to the top when page is changed (for example from 'origin page' to 'destination page'):
    if (stationsListWrapperRef.current) {
      stationsListWrapperRef.current.scrollTo(0, 0);
    }

    _setSearchFiledAria(
      `${
        stationsType === "origin"
          ? t(`SearchOrigenAccessibility`)
          : t(`SearchDestinationAccessibility`)
      }`
    );
  }, [location]);

  const handleSelectedStation = (station: IStation) => {
    if (stationsType === "origin") {
      dispatch(setOriginStation(station.stationId));
      navigate(
        `?page=routePlan&step=destination&fromStation=${station.stationId}`
      );
    } else if (stationsType === "destination") {
      dispatch(setDestinationStation(station.stationId));
      navigate(
        `?page=routePlan&step=when&fromStation=${schedule.fromStation}&toStation=${station.stationId}`
      );
    }
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const changeAriaSearchFiled = (resultsNumber: number) => {
    _setSearchFiledAria(`${t(`StationResults`, { number: resultsNumber })}`);
  };

  const handleClearButtonVisible = () => {
    if (inputRef.current) {
      if (inputRef.current.value.length > 0) {
        _setIsClearButtonVisible(true);
      } else {
        _setIsClearButtonVisible(false);
      }
    }
  };

  const resetAllStationsDefault = () => {
    const stations = GetSortedStations();
    _setStations(stations);
  };

  const isIos =
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
    deviceMode === StyleMode.mobile;

  return (
    <div
      style={{
        ...styles.container,
        // height:
        //   deviceMode !== StyleMode.desktop
        //     ? Math.round(window.devicePixelRatio * 100) / 1 < 175
        //       ? "33rem"
        //       : window.innerHeight -
        //         14 *
        //           parseFloat(
        //             getComputedStyle(document.documentElement).fontSize
        //           )
        //     : "",
      }}
    >
      <div
        style={styles.searchBoxWrapper}
        onClick={() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}
      >
        <img
          src={Images.searchGray}
          width={`auto`}
          height={`auto`}
          alt={``}
          style={{ scale: deviceMode !== StyleMode.desktop ? ".8" : "1" }}
        />
        <input
          ref={inputRef}
          type={"text"}
          style={{
            ...styles.input,
          }}
          placeholder={placeHolder}
          aria-label={_searchFiledAria}
          onChange={(e) => {
            if (e.target.value !== "") {
              const filteredStations = FilterAutoComplete(
                e.currentTarget.value,
                _stations
              );
              changeAriaSearchFiled(filteredStations.length);
              _setStations(filteredStations);
            } else {
              resetAllStationsDefault();
            }
            _setHideLastORNearbyStations(e.currentTarget.value !== "");
            handleClearButtonVisible();
            if (inputRef.current) {
              if (inputRef.current.value.length === 0) {
                _setTitleValue(t("All-stations"));
              } else {
                _setTitleValue("");
              }
            }
          }}
          onFocus={() => {
            handleClearButtonVisible();
            handleInputFocus && handleInputFocus(true);
          }}
          // onBlur={() => {
          //   handleClearButtonVisible();
          //   _setTitleValue(t("All-stations"));
          //   handleInputFocus && handleInputFocus(false);
          // }}
        />
        <div style={{ width: deviceMode !== StyleMode.desktop ? "20px" : "" }}>
          {_isClearButtonVisible && (
            <img
              tabIndex={0}
              role={"button"}
              style={styles.clearButton}
              src={Images.close}
              onClick={() => {
                if (inputRef.current) {
                  inputRef.current.value = "";
                  _setHideLastORNearbyStations(false);
                  resetAllStationsDefault();
                  _setTitleValue(t("All-stations"));
                }
              }}
              alt={``}
            />
          )}
        </div>
      </div>
      <div
        id="stationList"
        style={{
          width: "100%", //deviceMode === StyleMode.desktop ? "100%" : "100%",
          display: "flex",
          justifyContent: "center",
          transform:
            deviceMode === StyleMode.desktop
              ? "translateY(0rem)"
              : "translateY(-2rem)",
          direction: i18n.dir() === "rtl" ? "ltr" : "rtl",
          overflow: "auto",
        }}
      >
        <div
          style={{
            ...styles.stationsList,
            paddingInlineStart:
              deviceMode === StyleMode.desktop ? "1rem" : "1.5rem",
            paddingInlineEnd:
              deviceMode === StyleMode.desktop ? "1rem" : "1.5rem",
            maxHeight: deviceMode === StyleMode.desktop ? "57vh" : "73vh",
            minHeight: deviceMode === StyleMode.desktop ? "57vh" : "73vh",
            overflowX: "hidden",
          }}
          ref={stationsListWrapperRef}
        >
          {_stations.length > 0 ? (
            <div
              style={{
                width: "100%",
                direction: i18n.dir(),
              }}
            >
              <StationsListGrouped
                title={_titleValue}
                allStations={_stations}
                recentLastStationsTitle={t(
                  stationsType === "origin"
                    ? "Closest-stations"
                    : "Recent-destinations"
                )}
                recentLastStations={
                  stationsType === "origin"
                    ? _nearbyStations
                    : _lastStations.slice(0, _staionShowNumber)
                }
                stationsType={stationsType}
                onClick={handleSelectedStation}
                hideLastORNearbyStations={_hideLastORNearbyStations}
                textValue={inputRef.current?.value || ""}
                inputValue={inputRef.current?.value || ""}
              />
            </div>
          ) : (
            <div style={styles.notFoundContainer}>
              <img
                src={Images.notFound}
                width={`auto`}
                height={`auto`}
                alt={``}
              />
              <label
                style={styles.notFoundText}
                tabIndex={!IsMobile() ? 0 : -1}
              >
                {t("No-stations-message")}
              </label>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  searchBoxWrapper: {
    display: "flex",
    height: "4rem",
    maxWidth: "45rem",
    width: "90%",
    borderRadius: "4rem",
    border: "none",
    justifyContent: "space-around",
    alignItems: "center",
    boxShadow: "-1px 1px 33px 1px rgb(64 60 67 / 40%)",
    zIndex: "10",
    paddingInlineStart: "1.2rem",
    paddingInlineEnd: "1.2rem",
    boxSizing: "border-box",
    backgroundColor: colors.white,
    transform: "translateY(-50%)",
    cursor: "text",
  },

  clearButton: {
    cursor: "pointer",
    display: "flex",
  },
  input: {
    fontSize: fontSize.UmbracoElementTitle,
    textAlign: "center",
    fontFamily: fonts.FbReformaRegular,
    flex: 1,
  },
  stationsList: {
    width: "100%",
    maxWidth: "40rem",
  },
  notFoundContainer: {
    minWidth: `100%`,
    textAlign: "center",
    paddingTop: "25%",
    direction: "rtl",
  },
  notFoundText: {
    display: "block",
    alignSelf: "center",
    textAlign: "center",
    paddingTop: "33px",
    paddingBottom: "33px",
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.H1,
    color: colors.darkBlue,
  },

  searchIconContainer: {
    display: "flex",
    marginRight: "1rem",
  },
};

export default RoutePlanSearchStations;
