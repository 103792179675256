import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { IsMobile } from "../../Services/DeviceService";
import Title from "../UmbracoElements/Title";

export interface IInfoCareer {
  title: string;
  description: string;
  source?:string
}

const TextAndDescriptionsVideoModal: React.FC<IInfoCareer> = (props) => {
  const { title, description,source } = props;

  return (
    <div style={styles.modalContainer}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
        tabIndex={!IsMobile() ? 0 : -1}
      >
        <Title text={title} />
        <p>{description}</p>
      </div>
      <video
        controls
          width={"100%"}
          tabIndex={!IsMobile() ? 0 : -1}
        >
          <source src={source} type="video/mp4"></source>
        </video>
    </div>
  );
};

const styles: StyleSheet = {
  modalContainer: {
    padding: "2rem 3rem",
    maxHeight: "100%",
    overflow: "auto",
  }
};

export default TextAndDescriptionsVideoModal;
