import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { IImageAndLink } from "../../../Interfaces/IUmbraco";
import colors from "../../../Assets/Colors/Colors.json";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import { StyleMode } from "../../../Utils/consts";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../Services/DeviceService";

const ImageAndLink: React.FC<IImageAndLink> = (props) => {
  const { image, imageAlt, link, anchor } = props;
  const navigate = useNavigate();
  const deviceMode = useDeviceMode();

  const [_isMouseOverLink, _setIsMouseOverLink] = useState(false);

  const redirectToPage = (page?: string) => {
    page && navigate(`?page=${page}`);
  };

  return (
    <div
      anchor-id={anchor}
      style={{
        ...styles.container,
        width: deviceMode === StyleMode.desktop ? "33%" : "",
      }}
    >
      {image && image._url && (
        <img
          src={image._url}
          style={{
            width: "100%",
          }}
          alt={imageAlt ? imageAlt : ``}
        />
      )}
      {link && link.redirectTo ? (
        <span
          onMouseOver={() => _setIsMouseOverLink(true)}
          onMouseLeave={() => _setIsMouseOverLink(false)}
          style={{
            ...styles.link,
            textDecoration: _isMouseOverLink ? "underline" : "",
          }}
          onClick={() => {
            link.redirectTo.includes(`http://`) ||
            link.redirectTo.includes(`https://`)
              ? window.open(`${link.redirectTo}`, "_blank")
              : redirectToPage(link.redirectTo);
          }}
          tabIndex={!IsMobile() ? 0 : -1}
          role={"link"}
        >
          {link.text}
        </span>
      ) : (
        <h3
          style={{
            ...styles.text,
          }}
          tabIndex={!IsMobile() ? 0 : -1}
        >
          {link && link.text}
        </h3>
      )}
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1.5rem",
  },
  link: {
    fontSize: fontSize.UmbracoElementTitle,
    color: colors.oldLavender,
    cursor: "pointer",
  },
  text: {
    fontSize: fontSize.UmbracoElementTitle,
    color: colors.oldLavender,
    cursor: "normal",
    display: "contents",
    fontWeight: "normal",
  },
};

export default ImageAndLink;
