import { IMoreDetails } from "../../Interfaces/IBaseElement";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";
import TitleSubjectAndDetails from "./TitleSubjectAndDetails";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import TextAndLink from "./TextAndLink";

const MoreDetails: React.FC<IMoreDetails> = (props) => {
  const { options, title, textLink, subTitle } = props;
  const deviceMode = useDeviceMode();

  return (
    <div style={{ ...styles.wapper, paddingInline: deviceMode !== StyleMode.desktop ? "2rem" : "5rem" }}>
      <div style={{ textAlign: "center",lineHeight:"1.5" }}>
        <div style={styles.title}>{title}</div>
        <div style={styles.subTitle}>
          {subTitle}{" "}
          {textLink && <TextAndLink {...textLink} disabled={deviceMode === StyleMode.desktop} />}
        </div>
      </div>
      {options.map((item, index) => <TitleSubjectAndDetails key={index} {...item} />)}
    </div>
  );
};

const styles: StyleSheet = {
  wapper: {
    boxSizing: "border-box",
    paddingBlock: "1rem",
    paddingTop: "2rem",
    paddingInline: "5rem",
    display: "flex",
    lineHeight: "normal",
    flexDirection: "column",
    gap: "1rem",
    overflow: "auto",
    maxHeight: "60vh",
    width: "100%"
  },
  title: {
    color: colors.black,
    fontFamily: fonts.FbReformaMedium,
    fontSize: "1.25rem"
  },
  subTitle: {
    color: colors.black,
    fontFamily: fonts.FbReformaRegular,
    fontSize: "0.9rem",
  }

};

export default MoreDetails;
