import { t } from "i18next";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import Images from "../../Data/Images/Images";
import { IHeader } from "../../Interfaces/IHeader";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { CookiesSettings } from "../../Redux/Slices/cookiesSettingsSlice";
import { PageState } from "../../Redux/Slices/pageSlice";
import {
  setDate,
  setDestinationStation,
  setHours,
  setMinutes,
  setOriginStation,
  setScheduleType,
} from "../../Redux/Slices/scheduleSlice";
import { FormatDateYYYYMMDD } from "../../Services/DateService";
import { IsMobile } from "../../Services/DeviceService";
import i18n from "../../Services/i18n";
import { CreateLogoSchema } from "../../Utils/seo";
import useDeviceMode from "../../Utils/useWindowDimensions";
import LanguageSelection from "../LanguageSelection/LanguageSelection";
import MenuItem from "../UmbracoElements/MenuItem/MenuItem";
import Config from "../../Configuration/server";

const Header: React.FC<IHeader> = (props) => {
  const { headerContent } = props;
  const deviceMode = useDeviceMode();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const currentPage = (
    useSelector((state: any) => state.PageReducer) as PageState
  ).page;

  const isCookiesModalVisible = (
    useSelector((state: any) => state.CookiesSettingsSlice) as CookiesSettings
  ).isCookiesModalVisible;

  const [_isOver, _setIsOver] = useState(false);
  const [_isSearchVisible, _setIsSearchVisible] = useState(false);
  const [_skipBtnDisplay, _setSkipBtnDisplay] = useState(false);
  const [_mouseClick, _setMouseClick] = useState(false);
  const [_search, _setSearch] = useState<string>("");

  const inputRef = useRef<HTMLInputElement>(null);
  const languagesRef = useRef<HTMLDivElement>(null);
  const isAccessabilityEnterPressRef = useRef(false);
  const closeSearchRef = useRef<HTMLDivElement>(null);
  const curPathRef = useRef(currentPage);
  const logoRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    handleLogoBlurCaptureClick();
    handleCloseSearch();
    CreateLogoSchema(headerContent);
  }, [location, headerContent]);

  useEffect(() => {
    if (_isSearchVisible) {
      if (inputRef.current) {
        if (isAccessabilityEnterPressRef.current) {
          if (closeSearchRef.current) {
            closeSearchRef.current.focus();
          }
        } else {
          inputRef.current.focus();
        }
      }
    }
  }, [_isSearchVisible]);

  useEffect(() => {
    const handleTab = (event: MouseEvent) => {
      if (event) {
        _setMouseClick(true);
      }
    };
    window.addEventListener("click", handleTab);

    return () => {
      window.removeEventListener("click", handleTab);
    };
  }, []);

  useEffect(() => {
    const handleTab = (event: KeyboardEvent) => {
      if (
        event.key === "Tab" &&
        currentPage === curPathRef.current &&
        !isCookiesModalVisible &&
        currentPage.toLocaleLowerCase() !== "routeplansearchresults" &&
        !_mouseClick
      ) {
        document.getElementById("skipBlocksBtn")?.focus();
        curPathRef.current = "other";

        _setSkipBtnDisplay(true);
        // document.getElementById("skipBlocksBtn")?.focus();
      }
    };
    window.addEventListener("keydown", handleTab);

    return () => {
      window.removeEventListener("keydown", handleTab);
    };
  }, [currentPage, _mouseClick]);

  useEffect(() => {
    _skipBtnDisplay && document.getElementById("skipBlocksBtn")?.focus();
  }, [_skipBtnDisplay]);

  const handleSkipBlocksClick = () => {
    const focusArea = document.getElementById("pageMainContent");
    if (focusArea) {
      focusArea.focus();
    }
  };

  const handleLogoBlurCaptureClick = () => {
    const logo = logoRef.current;

    const handleShiftTab = (event: KeyboardEvent) => {
      if (event.shiftKey && event.key === "Tab") {
        event.preventDefault();

        _setSkipBtnDisplay(true);
      }
    };

    logo && logo.addEventListener("keydown", handleShiftTab);
    return () => {
      logo && logo.removeEventListener("keydown", handleShiftTab);
    };
  };

  const handleSkipBlockBlurCapture = () => {
    _setSkipBtnDisplay(false);
  };

  const handleLogoClick = () => {
    dispatch(setOriginStation(undefined));
    dispatch(setDestinationStation(undefined));
    dispatch(setDate(FormatDateYYYYMMDD(moment().toDate())));
    dispatch(setHours("00"));
    dispatch(setMinutes("00"));
    dispatch(setScheduleType(1));
    navigate("/");
  };

  const handleOpenSearch = () => {
    _setIsSearchVisible(true);
  };

  const handleCloseSearch = () => {
    _setIsSearchVisible(false);
  };

  const handleSearch = () => {
    const searchBoxText = inputRef.current?.value as string;
    if (searchBoxText?.length > 0) {
      _setIsSearchVisible(false);
      navigate(`?page=searchResults&term=${searchBoxText}`);
      _setSearch(searchBoxText);
    }
  };

  const handleClearInput = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      inputRef.current.focus();
    }
  };

  return currentPage !== "routePlan".toLowerCase() ? (
    <div style={styles.mainHeaderWrapper} className="header">
      <div style={styles.menuContainer} onKeyDown={(e) => {}}>
        {_skipBtnDisplay && (
          <button
            style={styles.skipBtn}
            id="skipBlocksBtn"
            onClick={handleSkipBlocksClick}
            // onBlurCapture={handleSkipBlockBlurCapture}
            onBlur={handleSkipBlockBlurCapture}
          >
            {t(`SkipToMainContent`)}
          </button>
        )}

        <div
          style={{
            display: "flex",
          }}
        >
          <nav
            role={"navigation"}
            aria-label={t("Navigation")}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={styles.logo}
              ref={logoRef}
              onBlurCapture={handleLogoBlurCaptureClick}
            >
              {Config.IS_TENDERS === "false" && (
                <img
                  src={headerContent?.logo._url}
                  onClick={handleLogoClick}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleLogoClick();
                    }
                  }}
                  alt={`${headerContent?.logoDescription || t(`GoToHomePage`)}`}
                  tabIndex={!IsMobile() ? 0 : -1}
                  style={{
                    cursor: "pointer",
                    width: "12rem",
                    maxWidth: "10vw",
                  }}
                  role={"link"}
                  max-width={`100%`}
                  height={`auto`}
                />
              )}
            </div>
            <ul
              style={{
                ...styles.menuItemsContainer,

                marginBlockStart: "0",
              }}
            >
              {Config.IS_TENDERS === "false" &&
                headerContent?.menuCategories.map((item, key) => (
                  <MenuItem
                    {...item}
                    key={key}
                    totalItems={headerContent.menuCategories.length}
                    currentIndex={key}
                  />
                ))}
            </ul>
          </nav>
        </div>
        <div style={styles.menuUtils}>
          <div
            style={{
              display: "flex",
              gap: "1rem",
            }}
          >
            {Config.IS_TENDERS === "false" && (
              <>
                {!_isSearchVisible ? (
                  <img
                    style={styles.siteSearchIcon}
                    src={headerContent?.searchIcon._url}
                    onClick={() => {
                      handleOpenSearch();
                      isAccessabilityEnterPressRef.current = false;
                    }}
                    onKeyPress={() => {
                      handleOpenSearch();
                      isAccessabilityEnterPressRef.current = true;
                    }}
                    role={`button`}
                    tabIndex={!IsMobile() ? 0 : -1}
                    aria-label={t(`OpenSearch`)}
                  />
                ) : (
                  <div
                    style={styles.closeSearchContainer}
                    onClick={handleCloseSearch}
                    onKeyPress={handleCloseSearch}
                    role={`button`}
                    tabIndex={!IsMobile() ? 0 : -1}
                    aria-label={`${headerContent?.closeSearch}`}
                    ref={closeSearchRef}
                  >
                    <img src={Images.closeBG} alt={``} />
                    <label style={{ cursor: "pointer", whiteSpace: "pre" }}>
                      {headerContent?.closeSearch}
                    </label>
                  </div>
                )}
                <div tabIndex={-1} style={styles.line}></div>
              </>
            )}
            <div
              ref={languagesRef}
              onMouseOver={() => {
                _setIsOver(true);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  _setIsOver(true);
                }
                if (e.key === "Escape") {
                  _setIsOver(false);
                  languagesRef.current?.focus();
                }
              }}
              onMouseLeave={() => {
                _setIsOver(false);
              }}
              // onFocusCapture={() => {
              //   _setIsOver(true);
              // }}
              onBlurCapture={(e) => {
                _setIsOver(false);
                if (languagesRef.current) {
                  if (languagesRef.current.contains(e.relatedTarget)) {
                    _setIsOver(true);
                  } else {
                    _setIsOver(false);
                  }
                }
              }}
            >
              <div style={styles.selectedLanguage}>
                <span
                  style={{
                    fontFamily: fonts.FbReformaRegular,
                    fontSize: fontSize.Text,
                  }}
                  id={"languagesSelection"}
                  role={"button"}
                  aria-expanded={_isOver ? `true` : `false`}
                  tabIndex={!IsMobile() ? 0 : -1}
                  aria-label={t("ChangeLanguage", {
                    currentLanguage: headerContent?.languages.find(
                      (language) => language.languageLocale === i18n.language
                    )?.languageName,
                  })}
                  onKeyPressCapture={() => {
                    _setIsOver(true);
                  }}
                  onClickCapture={() => {
                    _setIsOver(true);
                  }}
                  onKeyDownCapture={(e) => {
                    if (e.key === "Enter") {
                      _setIsOver(true);
                    }
                    if (e.key === "Escape") {
                      _setIsOver(false);
                      languagesRef.current?.focus();
                    }
                  }}
                >
                  {i18n.language.toUpperCase()}
                </span>
                <img
                  src={Images.arrowDown}
                  style={styles.arrowDownIcon}
                  alt={``}
                />
              </div>
              {_isOver && (
                <LanguageSelection
                  isOver={_isOver}
                  languages={headerContent?.languages || []}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {_isSearchVisible && (
        <div style={styles.searchContainer}>
          <div style={styles.searchBoxContainer}>
            <img
              src={headerContent?.searchIcon._url}
              style={{
                ...styles.siteSearchIcon,
                padding: "0 .5rem",
                cursor: "default",
              }}
              alt={``}
            />
            <input
              type={"text"}
              style={styles.searchBox}
              value={_search}
              placeholder={headerContent?.searchPlaceholder}
              ref={inputRef}
              onChange={(e: any) => _setSearch(e.target.value)}
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
                if (e.key === "Escape") {
                  _setIsSearchVisible(false);
                }
              }}
            />
            {_search.length > 0 && (
              <img
                src={Images.close}
                onClick={handleClearInput}
                style={styles.siteClearSearchIcon}
                tabIndex={!IsMobile() ? 0 : -1}
                role={`button`}
                aria-label={t(`ClearSearch`)}
                onKeyDown={(e) => {
                  if (e.key === "Escape") {
                    _setIsSearchVisible(false);
                  }
                }}
              />
            )}
          </div>

          <button
            style={styles.searchButton}
            onClick={handleSearch}
            onKeyDown={(e) => {
              if (e.key === "Escape") {
                _setIsSearchVisible(false);
              }
            }}
          >
            {headerContent?.searchButton}
          </button>
        </div>
      )}
    </div>
  ) : null;
};

const styles: StyleSheet = {
  mainHeaderWrapper: {
    display: "flex",
    flexDirection: "column",
    // position: "fixed",
    top: "0",
    left: "0",
    // zIndex: "99999",
    width: "100%",
  },
  menuContainer: {
    minHeight: "3rem",
    width: "100%",
    backgroundColor: colors.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "0 2px 20px 0 rgba(0, 0, 0, 0.1)",
    // boxShadow: "0px -1px 5px 0px black",
    zIndex: "20",
  },
  menuItemsContainer: {
    display: "flex",
    gap: "2rem",
    flex: 2,
    color: colors.darkBlue,
    maxWidth: "75vw",
    flexWrap: "wrap",
  },
  logo: {
    paddingInlineStart: "2rem",
    paddingInlineEnd: "2rem",
    display: "flex",
    alignItems: "center",
  },
  menuUtils: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
  },
  line: {
    boxSizing: "border-box",
    height: "1.5rem",
    width: "0.0063125rem",
    border: `0.01875rem solid ${colors.koromiko}`,
  },
  siteSearchIcon: {
    cursor: "pointer",
    transform: "scale(.8)",
    marginInlineStart: ".5rem",
  },
  siteClearSearchIcon: {
    cursor: "pointer",
    transform: "scale(0.8)",
    margin: "0 .8rem",
  },

  closeSearchContainer: {
    display: "flex",
    fontSize: fontSize.Text,
    fontFamily: fonts.FbReformaMedium,
    color: colors.darkBlue,
    cursor: "pointer",
    gap: ".25rem",
    minWidth: "8rem",
  },
  searchContainer: {
    display: "flex",
    backgroundColor: colors.blackTransparent,
    height: "12rem",
    alignItems: "center",
    justifyContent: "center",
    gap: "1.5rem",
  },
  searchBoxContainer: {
    display: "flex",
    backgroundColor: colors.white,
    height: "2.5rem",
    borderRadius: "1.25rem",
    border: `1px solid ${colors.pastelGray}`,
    maxWidth: "35rem",
    width: "100%",
    alignItems: "center",
    overflow: "hidden",
    gap: "1rem",
  },
  searchBox: {
    flex: "1",
    fontSize: fontSize.Text,
    fontFamily: fonts.FbReformaRegular,
    color: colors.pasterlGrayDark,
    letterSpacing: "0.043125rem",
  },
  searchButton: {
    width: "10rem",
    height: "2.5rem",
    backgroundColor: colors.darkBlue,
    border: `1px solid ${colors.darkBlue}`,
    borderRadius: "1.25rem",
    color: colors.white,
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.Text,
    letterSpacing: "0.029375rem",
    cursor: "pointer",
  },
  arrowDownIcon: {},
  selectedLanguage: {
    display: "flex",
    gap: ".5rem",
    justifyContent: "center",
    color: colors.darkBlue,
    cursor: "pointer",
    padding: "0 2.5rem",
    position: "relative",
  },
  skipBtn: {
    position: "absolute",
    zIndex: "9",
    backgroundColor: "rgb(238, 238, 238)",
    left: "0.25rem",
    top: "10%",
    minWidth: "15%",
    height: "auto",
    overflow: "auto",
    margin: "auto",
    padding: "5px",
    border: "0.3rem dashed",
    textAlign: "center",
    fontSize: fontSize.FooterItem,
  },
};

export default Header;
