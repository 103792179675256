import { useState } from "react";
import {
  IArticle,
  ILatestArticles,
} from "../../../Interfaces/IMagazinePage";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import ArticleTitleAndText from "./Components/ArticleTitleAndText";
import LayoutMagazine from "./Components/LayoutMagazine";
import MediaArticle from "./MediaArticle";


const LatestArticles: React.FC<ILatestArticles> = (props) => {
  const { title, articles, articleWithMedia, anchor } = props;
  const [_articleOnModal, _setArticleOnModal] = useState<IArticle>();
  const deviceMode = useDeviceMode();

  return (<LayoutMagazine title={title} anchor={anchor} modalData={_articleOnModal}
    onCloseModal={() => _setArticleOnModal(undefined)}>
    <div
      style={{
        ...styles.container,
        flexDirection: deviceMode === StyleMode.desktop ? "row" : "column",
      }}
    >
      <div style={{ ...styles.articlesList, padding: deviceMode !== StyleMode.desktop ? "1rem 2rem " : "0" }}>
        {articles.map((article, index, { length }) => (
          <ArticleTitleAndText showBorderLine={deviceMode === StyleMode.desktop || index !== length - 1}
            tagsStyle={{ paddingTop: 0 }}
            key={index} {...article}
            title={article.titleAndDescription.title} onClick={() => {
              _setArticleOnModal(article);
            }} />
        ))}
      </div>
      <MediaArticle {...articleWithMedia} />
    </div>
  </LayoutMagazine>)

};

const styles: StyleSheet = {
  container: {
    display: "flex",
    justifyContent: "space-between",
  },
  articlesList: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    flex: 1,
    justifyContent: "space-between",
  },
};

export default LatestArticles;
