import { t } from "i18next";
import { useRef, useState, useEffect } from "react";
import Swiper, { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper as ReactSwiper, SwiperSlide } from "swiper/react";
import colors from "../../../../../Assets/Colors/Colors.json";
import fonts from "../../../../../Assets/Fonts/Fonts.json";
import Images from "../../../../../Data/Images/Images";
import { IGallery, IGalleryYoutube } from "../../../../../Interfaces/IGallery";
import { StyleSheet } from "../../../../../Interfaces/IStyleSheet";
import { IVideo } from "../../../../../Interfaces/IVideo";
import { StyleMode } from "../../../../../Utils/consts";
import useDeviceMode from "../../../../../Utils/useWindowDimensions";
import { GetLongTextWithDots } from "../../../../../Utils/utils";
import GalleryImage from "../../GalleryItems/GalleryImage";
import GalleryVideo from "../../GalleryItems/GalleryVideo";
import GalleryYoutube from "../../GalleryItems/GalleryYoutube";
import fontSize from "../../../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../../../Services/DeviceService";

const DefaultGallery: React.FC<IGallery> = (props) => {
  const [_currentItemIndex, _setCurrentItemIndex] = useState(0);
  const [_gallery] = useState(props.gallery);
  const { replaceTimeInSecond, anchor } = props;
  const galleryRef = useRef<HTMLDivElement>(null);
  const [_isPaused, _setIsPaused] = useState(false);
  const deviceMode = useDeviceMode();
  const [_swiper, _setSwiper] = useState<Swiper>();
  const galleryContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const bullets = galleryContainerRef.current?.getElementsByClassName(
      "swiper-pagination-bullet"
    );
    if (bullets) {
      Array.from(bullets).forEach((bullet, index) => {
        bullet.setAttribute("tabIndex", "0");
        bullet.setAttribute(
          "aria-label",
          t("GalleryItemNumberOutOfNumber", {
            currentIndex: index + 1,
            total: bullets.length,
          })
        );
      });
    }

    const prevButton =
      galleryContainerRef.current?.getElementsByClassName("swiper-button-prev");

    if (prevButton && prevButton.length > 0) {
      prevButton[0].setAttribute("tabIndex", "0");
      prevButton[0].setAttribute("role", "button");
      prevButton[0].setAttribute("aria-label", t("PreviousGalleryItem"));
    }

    const nextButton =
      galleryContainerRef.current?.getElementsByClassName("swiper-button-next");

    if (nextButton && nextButton.length > 0) {
      nextButton[0].setAttribute("tabIndex", "0");
      nextButton[0].setAttribute("role", "button");
      nextButton[0].setAttribute("aria-label", t("NextGalleryItem"));
    }
  }, []);

  const handleHeaderText = (text: string) => {
    if (deviceMode === StyleMode.desktop) {
      if (text.length < 158) {
        return text;
      } else {
        return GetLongTextWithDots(text, 158);
      }
    }
  };

  const handlePlayPause = (isPaused: boolean) => {
    if (!isPaused) {
      _swiper && _swiper.autoplay.stop();
    } else {
      _swiper && _swiper.autoplay.start();
    }

    _setIsPaused(!isPaused);
  };

  return (
    <div
      style={styles.galleryContainer}
      id={"defaultGallery"}
      ref={galleryContainerRef}
      anchor-id={anchor}
    >
      <ReactSwiper
        onSwiper={(swiper) => _setSwiper(swiper)}
        className={"mySwiper"}
        slideToClickedSlide={true}
        role={"region"}
        slidesPerView={1}
        navigation={true}
        modules={[Pagination, Navigation, Autoplay]}
        pagination={{ clickable: true }}
        loop={true}
        autoplay={
          replaceTimeInSecond !== 0
            ? {
                delay: replaceTimeInSecond * 1000,
                disableOnInteraction: false,
              }
            : false
        }
      >
        {_gallery.map((gallery, index) => (
          <SwiperSlide key={index}>
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                zIndex: "-1",
              }}
              ref={galleryRef}
              tabIndex={!IsMobile() ? 0 : -1}
              aria-label={`${t(
                gallery.contentTypeAlias === "imageGalleryItem"
                  ? "Image"
                  : "Video"
              )} ${gallery.header.text}`}
            ></div>
            {gallery.contentTypeAlias === "imageGalleryItem" && (
              <GalleryImage
                {...gallery}
                imageStyles={{
                  backgroundRepeat: "no-repeat",
                  height: "42rem",
                  width: "100%",
                  objectFit: "fill",
                }}
              />
            )}
            {gallery.contentTypeAlias === "videoGalleryItem" && (
              <GalleryVideo
                media={gallery.media as IVideo}
                header={gallery.header}
                videoStyles={{
                  backgroundRepeat: "no-repeat",
                  height: "42rem",
                  width: "100%",
                }}
                thumbnail={gallery.thumbnail}
                isPlayButtonEnabled={true}
                onClick={() => {
                  handlePlayPause(false);
                }}
              />
            )}
            {gallery.contentTypeAlias === "youtubeGalleryItem" && (
              <GalleryYoutube {...(gallery as IGalleryYoutube)} />
            )}
            <div
              style={
                gallery.contentTypeAlias === "imageGalleryItem"
                  ? {
                      ...styles.headerContainer,
                      bottom: "0",
                    }
                  : {
                      ...styles.headerContainer,
                      top: "0",
                    }
              }
            >
              <span
                aria-level={3}
                style={{
                  ...styles.header,
                  right: "2rem",
                }}
                aria-label={t("CurrentGalleryItem", {
                  currentGalleryTitle: gallery.header.text,
                })}
                title={gallery.header.text}
              >
                {handleHeaderText(gallery.header.text)}
              </span>
            </div>
          </SwiperSlide>
        ))}
      </ReactSwiper>
      {replaceTimeInSecond !== 0 && (
        <div
          style={{
            position: "absolute",
            left: "1rem",
            top: "1rem",
            zIndex: 99999,
            backgroundColor: colors.white,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            padding: ".5rem",
            width: "2rem",
            height: "2rem",
            scale: ".8",
            cursor: "pointer",
          }}
          tabIndex={!IsMobile() ? 0 : -1}
          role={"button"}
          aria-label={t(_isPaused ? "PlayGallery" : "PauseGallery")}
          onClick={() => handlePlayPause(_isPaused)}
          onKeyPress={() => handlePlayPause(_isPaused)}
          aria-pressed={_isPaused}
        >
          <img
            src={!_isPaused ? Images.audioPauseIcon : Images.audioPlay}
            style={{
              scale: _isPaused ? ".7" : "",
              width: "100%",
              height: "100%",
            }}
            alt={``}
          />
        </div>
      )}
    </div>
  );
};

const styles: StyleSheet = {
  galleryContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  currentDisplayed: {
    position: "relative",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "85.5rem",
    height: "48rem",
  },
  arrows: {
    position: "absolute",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    top: "40%",
  },
  arrowIcon: {
    backgroundImage: `url(${Images.galleryArrow})`,
    backgroundRepeat: "no-repeat",
    width: "4.1rem",
    height: "4.3rem",
    cursor: "pointer",
  },
  headerContainer: {
    position: "absolute",
    backgroundColor: colors.whiteTransparent,
    width: "100%",
    minHeight: "5rem",
    maxHeight: "8rem",
    display: "flex",
  },
  header: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.UmbracoElementTitle,
    position: "absolute",
    top: "35%",
  },
  galleryBullets: {
    display: "flex",
    position: "absolute",
    gap: "1rem",
    bottom: "15%",
  },
  galleryItemBullet: {
    width: ".75rem",
    height: ".75rem",
    border: `1px solid ${colors.ghostWhite}`,
    borderRadius: "0.75rem",
    cursor: "pointer",
  },
};

export default DefaultGallery;
