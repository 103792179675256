import { IGalleryYoutube } from "../../../Interfaces/IGallery";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import GalleryYoutube from "../../UmbracoElements/Gallery/GalleryItems/GalleryYoutube";

const YoutubeStandalone: React.FC<IGalleryYoutube> = (props) => {
  const { size } = props;
  const deviceMode = useDeviceMode();

  const getStyles = (): React.CSSProperties => {
    if (!size) return {};

    if (deviceMode !== StyleMode.desktop) {
      return {
        width: "100%",
        height: "28rem",
      };
    }

    switch (size) {
      case "small":
        return {
          width: "14rem",
          height: "10rem",
        };
      case "medium":
        return {
          width: "52rem",
          height: "28rem",
        };
      case "large":
        return {
          width: "75rem",
          height: "40rem",
        };
    }
    return {};
  };

  return <GalleryYoutube {...props} customStyles={getStyles()} />;
};

export default YoutubeStandalone;
