import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ITranslations } from "../../Interfaces/IConfig";

export interface TranslationsState {
  translations: ITranslations;
}

const initialState: TranslationsState = {
  translations: { he: {}, en: {}, ru: {}, ar: {} },
};

export const translationsSlice = createSlice({
  name: "translations",
  initialState,
  reducers: {
    setTranslations: (state, action: PayloadAction<ITranslations>) => {
      state.translations = action.payload;
    },
  },
});

export const { setTranslations } = translationsSlice.actions;

export default translationsSlice.reducer;
