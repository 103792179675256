import { t } from "i18next";
import { Fragment, useEffect, useState } from "react";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import location2 from "../../../Assets/Icons/location-2.svg";
import {
  IActivityTime,
  IGateActivityHours
} from "../../../Interfaces/IActivityTime";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { IsMobile } from "../../../Services/DeviceService";
import { GetCurrentLocation } from "../../../Services/SearchStationsAutoCompleteService";
import i18n from "../../../Services/i18n";
import Enters from "./Enters";

const ActivityTime: React.FC<IActivityTime> = (props) => {
  const { gateAddress, gateActivityHours, gateName, index, type } = props;
  let _subTitle = type === "desktop" ? styles.subTitleDesktop : styles.subTitle;
  const [_selectedIndex, _setSelectedIndex] = useState(0);
  const [_currentPosition, _setCurrentPosition] = useState(`0,0`)
  const [_entersKey, _setEntersKey] = useState(0);

  let topHeader =
    type === "desktop" ? styles.topHeaderDesktop : styles.topHeader;
  let [_hours, _setHours] = useState([]);

  useEffect(() => {
    let resData = gateActivityHours.reduce((r: any, a: any) => {
      if (r[a?.activityHoursType]) {
        r[a?.activityHoursType].hours.push({ ...a });
      } else {
        a.hours = [{ ...a }];
        r = { ...r, [a.activityHoursType]: a };
      }
      return r;
    }, Object.create(null));

    _setHours(Object.values(resData));
  }, []);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      const position = await GetCurrentLocation();
      _setCurrentPosition(position)
    })();
    return () => {
      isMounted = false;
    };
  }, []);


  return (

    <div style={styles.cardAtivityTime}>
      <div
        style={{
          ...topHeader,
          display: "flex",
          flexDirection: type === "desktop" ? "column" : "row",
          alignItems: type !== "desktop" ? "center" : "",
          justifyContent: type !== "desktop" ? "space-between" : "",
        }}
      >
        <h3 style={{ ..._subTitle, margin: 0 }}>{gateName}</h3>
        <a
          href={IsMobile() ?
            `geo:${props.gateLatitude},${props.gateLontitude}?q=${props.gateName} ${t(`stationNavigation`)} ${props.stationName}`
            : `https://www.google.com/maps/dir/'${_currentPosition}'/'${props.gateLatitude},${props.gateLontitude}'/`}
          target="_blank"
          style={{
            color: colors.darkBlue,
            fontSize: fontSize.Text,
            cursor: "pointer",
          }}
        >
          <img
            src={location2}
            style={{ marginInlineEnd: "0.5rem" }}
            width={`auto`}
            height={`auto`}
            alt={``}
          />
          {t("NavigationStation")}
        </a>
      </div>
      <Fragment key={_entersKey}>
        {_hours.map((option: IGateActivityHours, i: number) => (
          <Enters
            {...option}
            type={type}
            isShow={type === "desktop" ? true : i === _selectedIndex}
            lastChild={_hours.length === i + 1}
            key={i}
            index={i}
            onClick={(selectedIndex) => {
              if (selectedIndex !== _selectedIndex) {
                _setSelectedIndex(selectedIndex);
              } else {
                _setSelectedIndex(-1);
              }
              _setEntersKey(Math.random() * 1000);
            }}
          />
        ))}
      </Fragment>
    </div>
  );
};

const styles: StyleSheet = {
  topHeaderDesktop: {
    backgroundColor: colors.pattensBlue,
    padding: "1rem",
    lineHeight: 1
  },
  topHeader: {
    backgroundColor: colors.ghostWhite,
    padding: "0.5rem 1rem",
  },
  subTitleDesktop: {
    display: "inline-block",
    fontFamily: "FbReforma-Medium , HelveticaNeue-Bold",
    fontWeight: `${i18n.language === "ar" ? `600` : ``}`,
    fontSize: fontSize.H1,
    color: colors.darkBlue,
  },
  subTitle: {
    display: "inline-block",
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.Text,
  },
  cardAtivityTime: {
    boxShadow: "var(--shadowColor)",
    borderRadius: "1rem",
    backgroundColor: "var(--white)",
    minWidth: "15rem",
    overflow: "hidden",
    height: "100%",
  },

};
export default ActivityTime;
