import {
  IIntermediateStation,
  IPisResult,
  IPisResultItem,
  IPisTrainCard,
} from "../Interfaces/IPis";

const getArrivalTimeByVehicleAtStop = (currentValue: IPisResultItem) => {
  if (currentValue.monitoredVehicleJourney.monitoredCall.vehicleAtStop) {
    return currentValue.monitoredVehicleJourney.monitoredCall.actualArrivalTime;
  }

  if (currentValue.monitoredVehicleJourney.onwardCalls.onwardCall.length > 0) {
    return currentValue.monitoredVehicleJourney.onwardCalls.onwardCall[0]
      .expectedArrivalTime;
  }

  return "";
};

const getOriginPlatformByVehicleAtStop = (currentValue: IPisResultItem) => {
  if (currentValue.monitoredVehicleJourney.monitoredCall.vehicleAtStop) {
    return -1;
  }

  if (currentValue.monitoredVehicleJourney.onwardCalls.onwardCall.length > 0) {
    return currentValue.monitoredVehicleJourney.onwardCalls.onwardCall[0]
      .arrivalPlatformName;
  }

  return -1;
};

const getTravelDetailsAndStations = (
  currentValue: IPisResultItem,
  index: number
): IIntermediateStation => {
  let result: IIntermediateStation = {
    arrivalTime: "",
    stationNumber: -1,
    originPlatform: -1,
    order: -1,
    arrivalStatus: "onTime",
  };

  if (index === 0) {
    /** arrivalTime*/
    if (currentValue.monitoredVehicleJourney.monitoredCall.vehicleAtStop) {
      result.arrivalTime =
        currentValue.monitoredVehicleJourney.monitoredCall.actualArrivalTime;
    } else {
      result.arrivalTime =
        currentValue.monitoredVehicleJourney.onwardCalls.onwardCall[
          index
        ].expectedArrivalTime;
    }

    /** stationNumber*/
    if (currentValue.monitoredVehicleJourney.monitoredCall.vehicleAtStop) {
      result.stationNumber =
        currentValue.monitoredVehicleJourney.monitoredCall.stopPointRef;
    } else {
      result.stationNumber =
        currentValue.monitoredVehicleJourney.onwardCalls.onwardCall[
          index
        ].stopPointRef;
    }

    /**originPlatform */

    if (
      currentValue.monitoredVehicleJourney.onwardCalls.onwardCall[index]
        .arrivalStatus === "cancelled"
    )
      result.originPlatform = -1;
    else if (currentValue.monitoredVehicleJourney.monitoredCall.vehicleAtStop) {
      result.originPlatform = -1;
    } else {
      result.originPlatform =
        currentValue.monitoredVehicleJourney.onwardCalls.onwardCall[
          index
        ].arrivalPlatformName;
    }
  } else {
    /** arrivalTime*/

    result.arrivalTime =
      currentValue.monitoredVehicleJourney.onwardCalls.onwardCall[
        index
      ].expectedArrivalTime;

    /** stationNumber*/

    result.stationNumber =
      currentValue.monitoredVehicleJourney.onwardCalls.onwardCall[
        index
      ].stopPointRef;

    /**originPlatform */

    if (
      currentValue.monitoredVehicleJourney.onwardCalls.onwardCall[index]
        .arrivalStatus === "cancelled"
    )
      result.originPlatform = -1;
    else {
      result.originPlatform =
        currentValue.monitoredVehicleJourney.onwardCalls.onwardCall[
          index
        ].arrivalPlatformName;
    }
  }

  result.order =
    currentValue.monitoredVehicleJourney.onwardCalls.onwardCall[index].order;

  result.arrivalStatus =
    currentValue.monitoredVehicleJourney.onwardCalls.onwardCall[
      index
    ].arrivalStatus;

  return result;
};

export const ConvertPisDetails = (
  pisBasicData: IPisResult
): IPisTrainCard[] => {
  const convertedData = pisBasicData.result.reduce(
    (result: IPisTrainCard[], currentValue) => {
      const obj: IPisTrainCard = {
        trainNumber: currentValue.monitoredVehicleJourney.lineRef,
        destinationStationNumber:
          currentValue.monitoredVehicleJourney.destinationRef,
        arrivalTime: getArrivalTimeByVehicleAtStop(currentValue),
        originPlatform: getOriginPlatformByVehicleAtStop(currentValue),
        intermediateDetails: {
          currentTrainBasicDetails: {
            trainNumber: currentValue.monitoredVehicleJourney.lineRef,
            destinationStationNumber:
              currentValue.monitoredVehicleJourney.destinationRef,
          },

          intermediateStations:
            currentValue.monitoredVehicleJourney.onwardCalls.onwardCall.map(
              (travel, index) => {
                return getTravelDetailsAndStations(currentValue, index);
              }
            ),
        },
      };

      result.push(obj);
      return result;
    },
    []
  );
  return convertedData;
};
