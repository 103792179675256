import { isTablet } from "react-device-detect";
const { innerHeight } = window;
const guidelineBaseWidth = 480;
const guidelineBaseHeight = 640;
const pixelRatio = window.devicePixelRatio;

export const Scale = (size: number, innerWidth:number,factor = 0.5 ) => {
  return isTablet
    ? size + ((innerWidth / guidelineBaseWidth) * size - size) * factor
    : (innerWidth / guidelineBaseWidth) * size;
};

export const ScaleY = (size: number, factor = 0.5) => {
  return isTablet
    ? size + ((innerHeight / guidelineBaseHeight) * size - size) * factor
    : (innerHeight / guidelineBaseHeight) * size;
};

export const VerticalScale = (size: number) =>
  (innerHeight / guidelineBaseHeight) * size;

// const moderateScale = (size, factor = 0.5) => size + ( scale(size) - size ) * factor;

export const ModerateScale = (size: number, innerWidth:number,factor = pixelRatio / 3) =>
  size + ((innerWidth / guidelineBaseWidth) * size - size) * factor;
