import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import store from "store2";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import Config from "../../Configuration/server";
import Images from "../../Data/Images/Images";
import { IStation } from "../../Interfaces/IStation";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { IsMobile } from "../../Services/DeviceService";
import i18n from "../../Services/i18n";
import { GetStationsDictionary } from "../../Utils/helpers";
import { removeSpecialCharacters } from "../../Utils/utils";
import SearchStationsAutoCompleteDropdown from "../SearchStationsAutoComplete/SearchStationsAutoCompleteDropdown";

interface IFromTO {
  fromStation?: number;
  toStation?: number;
}

interface IFromTOFull extends IFromTO {
  onSearchTrainsClick: (key: any) => void;
  onSelectStation: (key: IFromTO) => void;
}
const SearchStationsAutoComplete: React.FC<IFromTOFull> = (props) => {
  const { fromStation, toStation } = props;
  const allStations = store.session.get(
    `${Config.BASE_LOCALSTORAGE_NAME}stations`
  );
  const [_isSearchButtonDisabled, _setIsSearchButtonDisabled] = useState(false);
  const [_data, _setData] = useState<IFromTO>({ fromStation, toStation });
  const [_isSwitchButtonOver, _setIsSwitchButtonOver] = useState(false);

  const originStationInputRef = useRef<HTMLInputElement>(null);
  const destinationStationInputRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();
  const originStationAria =
    _data.fromStation &&
    removeSpecialCharacters(
      GetStationsDictionary(allStations[i18n.language])[_data.fromStation]
        .stationName
    );

  const destinationStationAria =
    _data.toStation &&
    removeSpecialCharacters(
      GetStationsDictionary(allStations[i18n.language])[_data.toStation]
        .stationName
    );

  useEffect(() => {
    if (props.fromStation || props.toStation) {
      const dictStations = GetStationsDictionary(allStations[i18n.language]);
      const originStationName = props.fromStation
        ? dictStations[props.fromStation].stationName
        : "";
      if (originStationInputRef.current) {
        originStationInputRef.current.placeholder = originStationName;
        originStationInputRef.current.value = originStationName;
      }

      const destinationStationName = props.toStation
        ? dictStations[props.toStation].stationName
        : "";
      if (destinationStationInputRef.current) {
        destinationStationInputRef.current.placeholder = destinationStationName;
        destinationStationInputRef.current.value = destinationStationName;
      }
    } else {
      if (originStationInputRef.current) {
        originStationInputRef.current.placeholder = "";
        originStationInputRef.current.value = "";
      }

      if (destinationStationInputRef.current) {
        destinationStationInputRef.current.placeholder = "";
        destinationStationInputRef.current.value = "";
      }
    }
    _setData({ fromStation, toStation });
  }, [props]);

  const handleSelectedStation = (station: IStation, stationsType?: string) => {
    const dictStations = GetStationsDictionary(allStations[i18n.language]);
    if (stationsType) {
      if (stationsType === "origin") {
        _data.fromStation = station.stationId;
        props.onSelectStation && props.onSelectStation(_data);
        if (originStationInputRef.current) {
          originStationInputRef.current.value =
            dictStations[station.stationId].stationName;
        }
      } else if (stationsType === "destination") {
        _data.toStation = station.stationId;
        props.onSelectStation && props.onSelectStation(_data);
        if (destinationStationInputRef.current) {
          destinationStationInputRef.current.value =
            dictStations[station.stationId].stationName;
        }
      }
      _setData({ ..._data });
    }
  };

  const handleSwitchStations = () => {
    if (_data.fromStation && _data.toStation) {
      const dictStations = GetStationsDictionary(allStations[i18n.language]);

      [_data.fromStation, _data.toStation] = [
        _data.toStation,
        _data.fromStation,
      ];
      if (originStationInputRef.current) {
        originStationInputRef.current.value =
          dictStations[_data.fromStation].stationName;
      }

      if (destinationStationInputRef.current) {
        destinationStationInputRef.current.value =
          dictStations[_data.toStation].stationName;
      }
      props.onSelectStation && props.onSelectStation(_data);
    }
  };

  const redirectToStationPage = (stationId: number) => {
    navigate(`?page=stationinfo&stationname=${stationId}`);
  };

  const handleOriginInfoClick = () => {
    if (_data.fromStation) {
      redirectToStationPage(_data.fromStation);
    }
  };

  const handleDestinationInfoClick = () => {
    if (_data.toStation) {
      redirectToStationPage(_data.toStation);
    }
  };

  return (
    <div style={styles.mainStationsWrapper}>
      <div style={styles.searchBoxWrapper}>
        <img src={Images.origin} style={styles.originIcon} />
        <SearchStationsAutoCompleteDropdown
          stationsType="origin"
          placeHolder={t("Origin-station")}
          inputRef={originStationInputRef}
          onClick={handleSelectedStation}
          handleVisible={(isVisible) => _setIsSearchButtonDisabled(isVisible)}
          inputStyle={styles.searchStationsAutoComplete}
          stationsListStyle={{
            ...styles.stationsListStyle,
            right: i18n.dir() === "rtl" ? "0" : "",
            left: i18n.dir() === "ltr" ? "0" : "",
          }}
        />
        <img
          src={Images.info}
          style={styles.info}
          width={"8"}
          onClick={handleOriginInfoClick}
          height={`auto`}
          alt={``}
          aria-label={`${t("InfoStationPageAria", {
            stationName: originStationAria,
          })}`}
          tabIndex={!IsMobile() ? 0 : -1}
          role={"button"}
        />
      </div>
      <div style={styles.switchStations}>
        <div style={styles.switchStationsLine}></div>
        <img
          onMouseOver={() => {
            _setIsSwitchButtonOver(true);
          }}
          onMouseLeave={() => {
            _setIsSwitchButtonOver(false);
          }}
          src={Images.switch}
          style={{
            ...styles.switchIcon,
            filter: `brightness(${_isSwitchButtonOver ? ".95" : ""})`,
          }}
          aria-label={`${t("CurrentRoute")} ${t("From-station-to", {
            origin: originStationAria,
            destination: destinationStationAria,
          })}
            
            ${t("ChangeStationsAria", {
              fromStation: destinationStationAria,
              toStation: originStationAria,
            })}`}
          role={"button"}
          tabIndex={!IsMobile() ? 0 : -1}
          onClick={handleSwitchStations}
          width={`auto`}
          height={`auto`}
          alt={`switch stations`}
        />
      </div>
      <div style={styles.searchBoxWrapper}>
        <img src={Images.destination} style={styles.destinationIcon} />
        <SearchStationsAutoCompleteDropdown
          isSelectedStationId={_data.fromStation}
          stationsType="destination"
          placeHolder={t("Destination-station")}
          inputRef={destinationStationInputRef}
          onClick={handleSelectedStation}
          handleVisible={(isVisible) => _setIsSearchButtonDisabled(isVisible)}
          inputStyle={styles.searchStationsAutoComplete}
          stationsListStyle={{
            ...styles.stationsListStyle,
            right: i18n.dir() === "rtl" ? "-1rem" : "",
            left: i18n.dir() === "ltr" ? "-1rem" : "",
          }}
        />
        <img
          src={Images.info}
          style={styles.info}
          width={"8"}
          onClick={handleDestinationInfoClick}
          height={`auto`}
          tabIndex={!IsMobile() ? 0 : -1}
          role={"button"}
          alt={``}
          aria-label={`${t("InfoStationPageAria", {
            stationName: destinationStationAria,
          })}`}
        />
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  mainWrapper: {
    display: "flex",
    gap: "2rem",
    //marginInlineStart: "4rem",
    //marginInlineEnd: "4rem",
    //zIndex: "10",
    margin: "2rem 4rem",
    flexWrap: "wrap",
    justifyContent: "space-around",
    position: "relative",
  },
  mainStationsWrapper: {
    display: "flex",
    border: `.1rem solid ${colors.pastelGray}`,
    borderRadius: "2rem",

    // marginTop: "3rem",
  },
  timeCapsuleWrapper: {
    display: "flex",
    padding: "0 2rem ",
    // marginTop: "3rem",
  },
  searchBoxWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    minWidth: "25rem",
    gap: "0.5rem",
    position: "relative",
  },
  searchBox: {
    textAlign: "center",
    lineHeight: "2rem",
    fontSize: fontSize.FooterItem,
  },
  info: {
    cursor: "pointer",
    filter: `brightness(0.5)`,
  },
  originIcon: {
    backgroundRepeat: "no-repeat",
    width: "1rem",
    height: "1rem",
    scale: "1.25",
    filter: "brightness(.5)",
  },
  destinationIcon: {
    filter: "brightness(.6)",
    backgroundRepeat: "no-repeat",
    width: "1rem",
    height: "1rem",
    scale: "1.25",
  },
  switchStations: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  switchStationsLine: {
    position: "absolute",
    width: ".1rem",
    height: "100%",
    backgroundColor: colors.pastelGray,
    zIndex: "-1",
  },
  switchIcon: {
    cursor: "pointer",
    scale: "1.1",
  },
  searchTrainsButton: {
    backgroundColor: colors.koromiko,
    fontFamily: fonts.FbReformaMedium,
    width: "16rem",
    height: "2.5rem",
    borderRadius: "24rem",
    border: "none",
    fontSize: fontSize.Text,
    color: colors.nero,
  },
  searchTrainsButtonWrapper: {
    display: "flex",
    // marginTop: "3rem",
  },
  searchStationsAutoComplete: {
    color: colors.nero,
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Text,
    width: "13rem",
  },
  stationsListStyle: {
    height: "38rem",
    width: "26rem",
    position: "absolute",
    overflow: "auto",
    top: "3.7rem",
    backgroundColor: colors.white,
    boxShadow: `0 15px 30px 0 rgba(0, 0, 0, 0.15)`,
    borderRadius: "0.6rem",
    padding: "1rem",
    boxSizing: "border-box",
  },
  blockRoutePlan: {
    width: "100%",
    height: "100%",
    position: "absolute",
    left: "0",
    top: "0",
    backgroundColor: colors.whiteTransparent,
    zIndex: "9999999999",
  },
  buttonDisabledWrapper: {
    position: "absolute",
    top: "0",
    width: "100%",
    height: "100%",
    backgroundColor: colors.whiteTransparent,
  },
};

export default SearchStationsAutoComplete;
