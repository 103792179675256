import { IIframe } from "../../Interfaces/IUmbraco";

const Iframe: React.FC<IIframe> = (props) => {
  const { url, height, width, position } = props;
  return (
    <div style={{ display: "flex", flexDirection: "row", justifyContent: position || "center" }}>

      <iframe className="iframe" src={url} style={{
        width: width + "%",
        height: height,

      }} />
    </div>
  );
};

export default Iframe;
