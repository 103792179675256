import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { SystemParamsState } from "../Redux/Slices/systemParamsSlice";

const NewAddress: React.FC<any> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const systemParams = (
    useSelector((state: any) => state.SystemParamsReducer) as SystemParamsState
  ).systemParams;

  const systemParamsDictionary = systemParams.reduce(
    (result: { [key: string]: string }, current) => {
      result[current.key] = current.value;
      return result;
    },
    {}
  );

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const params = new URLSearchParams(location.search);
    const newAddress: any = params.get("newAddress");

    const systemParamsDictionarySystemParams = Object.keys(
      systemParamsDictionary
    ).reduce((result: any, key) => {
      const newKey = key.charAt(0).toLowerCase() + key.slice(1);

      result[newKey] = systemParamsDictionary[key];

      return result;
    }, {});

    const currentValue = systemParamsDictionarySystemParams[newAddress];

    if (newAddress && currentValue) {
      if (currentValue.includes("http")) {
        window.location.href = currentValue;
      } else {
        navigate(`?page=${currentValue}`);
      }
    } else {
      navigate(`/`);
    }
  };

  return <div></div>;
};

export default NewAddress;
