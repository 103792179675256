const days: any = {
  he: {
    day: ["ראשון", "שני", "שלישי", "רביעי", "חמישי", "שישי", 'מוצ"ש'],
    shortDay: ["א'", "ב'", "ג'", "ד'", "ה'", "שישי", 'מוצ"ש'],
  },
  en: {
    day: [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday' Night"],
    shortDay: [ "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat' Night"]
  },
  ru: {
    day: ['воскресенье', 'понедельник', 'вторник', 'среду', 'четверг', 'пятницу', 'субботу'],
    shortDay:['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб']
  },
  ar: {
    day: ['الأحد', 'الإثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت'],
    shortDay:['أحد', 'إثنين', 'ثلاثاء', 'أربعاء', 'خميس', 'جمعة', 'سبت']
  },
};

export { days };
