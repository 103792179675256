import { t } from "i18next";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import i18n from "../../Services/i18n";
import { IDefaultLayout, IDialogLayout, IListTypeLayout } from "./interface";
import "./modal.scss";

type IOmitTypOfLayout<T> = Omit<T, "typeOfLayout">

export const DefaultLayout: React.FC<IOmitTypOfLayout<IDefaultLayout>> = (props) => {
    const { description, title, subTextLink, textButton, onClickButton, onClickLink, children, ignoreBaseProps } = props;
    if (ignoreBaseProps) {
        return <>{children}</>
    }
    return (<>
        {title && <div className={"modal-title"} style={styles.title}>{title}</div>}
        {description && <p className={"modal-description text-balance"} style={styles.description}>{description}</p>}
        {children}
        {textButton && <button
            tabIndex={0}
            style={styles.button}
            aria-label={textButton}
            onClick={onClickButton}
        >
            {textButton}
        </button>}
        {subTextLink && <div
            tabIndex={0}
            style={styles.buttonLink}
            aria-label={subTextLink}
            onClick={onClickLink}
        >
            {subTextLink}
        </div>}
    </>
    );
};

export const DialogLayout: React.FC<IOmitTypOfLayout<IDialogLayout>> = (props) => {
    const { buttonA = t("Yes"), buttonB = t("No"), onSelect, title = t("Close-window-question"), description } = props;
    const _onSelect = (status: boolean) => {
        onSelect && onSelect(status)
    }
    return (
        <>
            {title && <div className={"modal-title"} style={styles.title}>{title}</div>}
            {description && <p className={"modal-description text-balance"} style={styles.description}>{description}</p>}
            <div style={{ display: "flex", flexDirection: "row", gap: "1rem", justifyContent: "center", width: "35rem" }}>
                <button
                    style={styles.button}
                    aria-label={buttonA}
                    onClick={() => _onSelect(true)}
                >
                    {buttonA}
                </button>
                <button
                    style={styles.buttonOutline}
                    aria-label={buttonB}
                    onClick={() => _onSelect(false)}

                >
                    {buttonB}
                </button>
            </div>
        </>
    );
};

export const CustomLayout: React.FC<any> = (props) => {

    return (
        <div >
            {props.children}
        </div>
    );
};

export const listTypeLayout:IListTypeLayout = {
    dialog: DialogLayout,
    default: DefaultLayout,
    custom: CustomLayout,
} as const


const styles: StyleSheet = {
    title: {
        fontFamily: fonts.FbReformaMedium,
        fontSize: "1.5rem",
        textAlign: "center"
    },
    description: {
        fontFamily: fonts.FbReformaRegular,
        fontSize: "1rem",
        textAlign: "center"

    },
    button: {
        borderRadius: "3.75rem",
        backgroundColor: colors.darkBlue,
        fontFamily: fonts.FbReformaMedium,
        fontSize: fontSize.timeDifferent,
        fontWeight: `${i18n.language === "ar" ? `600` : ``}`,
        textAlign: "center",
        lineHeight: 1,
        padding: "0.5rem",
        color: colors.white,
        cursor: "pointer",
        border: 0,
        minWidth: "6rem",
    },
    buttonOutline: {
        borderRadius: "3.75rem",
        backgroundColor: colors.white,
        fontFamily: fonts.FbReformaMedium,
        fontSize: fontSize.timeDifferent,
        fontWeight: `${i18n.language === "ar" ? `600` : ``}`,
        textAlign: "center",
        lineHeight: 1,
        padding: "0.5rem",
        color: colors.darkBlue,
        cursor: "pointer",
        border: `1px solid`,
        minWidth: "6rem",
    },
    buttonLink: {
        fontFamily: fonts.FbReformaRegular,
        fontSize: fontSize.timeDifferent,
        lineHeight: 1,
        color: colors.blueLink,
        textDecoration: "underline",
        cursor: "pointer",
    },

};

