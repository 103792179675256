import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import { StyleMode } from "../../../../Utils/consts";
import useDeviceMode from "../../../../Utils/useWindowDimensions";

const PisRouteItemEmptyLine: React.FC = () => {
  const deviceMode = useDeviceMode();
  return (
    <div style={styles.container}>
      <div style={styles.row}>
        <div style={styles.firstSideContainer}>
          <div
            style={{
              width: deviceMode === StyleMode.desktop ? "51px" : "41px",
            }}
          >
            {" "}
          </div>
          <div
            style={{ height: "3rem", width: "1px", backgroundColor: "black" }}
          ></div>
          <span></span>
        </div>
        <div style={styles.firstSideContainer}></div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  firstSideContainer: {
    display: "flex",
    gap: "1.5rem",
    justifyContent: "space-between",
    alignItems: "center",
  },
};

export default PisRouteItemEmptyLine;
