import { useState } from "react";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import Images from "../../../Data/Images/Images";
import { IDirectorateMemberShort } from "../../../Interfaces/IDirectorateMember";
import i18n from "../../../Services/i18n";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { t } from "i18next";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../Services/DeviceService";

const DirectorateMemberShort: React.FC<IDirectorateMemberShort> = (props) => {
  const { fullname, role, image, mailTo, onSelect } = props;
  const [_isOver, _setIsOver] = useState(false);

  const events = {
    onClick: () => {
      onSelect && onSelect(props);
    },
    onMouseOver: () => {
      _setIsOver(true);
    },
    onMouseLeave: () => {
      _setIsOver(false);
    },
    onFocusCapture:()=>{
      _setIsOver(true);
    },
    onBlurCapture:()=>{
      _setIsOver(false);
    }
  };
  

  return (
    <div 
        style={styles.container} 
      >
      <div
        className="avatar"
        style={{
          border: `.3rem solid ${_isOver ? colors.darkBlue : colors.white}`,
          cursor: "pointer",
         
        }}
      >
        <div
          style={{
            backgroundImage: `url(${image ? image._url : ""})`,
            ...styles.image,
            backgroundPosition:`center`, 
            backgroundPositionY:`0.1rem` ,
          }}
          onMouseOver={()=>{_setIsOver(true)}}
          onMouseLeave={()=>{_setIsOver(false)}}
          // onKeyPress={()=>onSelect && onSelect(props)}
          onClick={()=>onSelect && onSelect(props)}
        >
          <img
            className="arrows"
            {...events}
            src={_isOver ? Images.arrowOver : Images.directorateArrow}
            style={{
             
              cursor: "pointer",
              left: `${i18n.dir() === "rtl" ? "2rem" : ""}`,
              right: `${i18n.dir() === "rtl" ? "" : "2rem"}`,
              transform: `scale(1.5) rotate(${
                i18n.dir() === "rtl" ? "180deg" : "0"
              })`,
            }}
            tabIndex={!IsMobile() ? 0 : -1}
            role={'button'}
            aria-label={`${fullname}, ${role}`}
            id={`${fullname}${role}`}
            onKeyPress={()=>onSelect && onSelect(props)}
          ></img>
        </div>
      </div>
      <div style={styles.shortInfo}>
        <div style={styles.title} {...events}> 
          {fullname}
        </div>
        <div style={styles.role} {...events}>
          {role}
        </div>
        <div
          style={styles.email}
          role={`link`}
          tabIndex={!IsMobile() ? 0 : -1}
          aria-label={`${t(`sendEmailTo`)} ${role} ${fullname} ${mailTo.text}`}
          onClick={() => (window.location.href = `mailto:${mailTo.mailTo}`)}
          onKeyPress={() => (window.location.href = `mailto:${mailTo.mailTo}`)}
        >
          {mailTo.text}
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "2rem",
    flex: "1",
  },
  title: {
    fontSize: fontSize.Paragraph,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
  },
  description: {
    flex: "1",
    fontSize: fontSize.FooterItem,
    fontFamily: fonts.FbReformaRegular,
  },
  shortInfo: {
    display: "flex",
    alignItems: "center",
    textAlign:'center',
    flexDirection: "column",
    gap: ".2rem",
  },
  border: {
    position: "relative",
    borderColor: colors.darkBlue,
    backgroundColor: "white",
    width: "15rem",
    height: "15rem",
    borderRadius: "50%",
  },
  image: {
    backgroundRepeat: "no-repeat",
    position: "absolute",
    backgroundSize: "cover",
    borderRadius: "50%",
    width: "95%",
    height: "95%",
  },
  role:{
    textAlign:'center'
  },
  email:{
    color:colors.oldLavender,
    fontFamily:'FbReforma-Medium, HelveticaNeue-Bold',
    fontWeight:`${i18n.language==='ar'?`600`:``}`,
    textDecoration:'underline',
    cursor:"pointer"
  },
};

export default DirectorateMemberShort;
