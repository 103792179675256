import { Actions } from "../../consts";

export const setOriginStation = (fromStation: number | undefined) => {
  return (dispatch: any) => {
    dispatch({
      type: Actions.setOrigin,
      payload: fromStation,
    });
  };
};
export const setDestinationStation = (toStation: number | undefined) => {
  return (dispatch: any) => {
    dispatch({
      type: Actions.setDestination,
      payload: toStation,
    });
  };
};
export const setDate = (date: string) => {
  return (dispatch: any) => {
    dispatch({
      type: Actions.setDate,
      payload: date,
    });
  };
};
export const setHours = (hours: string) => {
  return (dispatch: any) => {
    dispatch({
      type: Actions.setHours,
      payload: hours,
    });
  };
};
export const setMinutes = (minutes: string) => {
  return (dispatch: any) => {
    dispatch({
      type: Actions.setMinutes,
      payload: minutes,
    });
  };
};

export const setScheduleType = (scheduleType: number) => {
  return (dispatch: any) => {
    dispatch({
      type: Actions.setScheduleType,
      payload: scheduleType,
    });
  };
};
