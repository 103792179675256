import { useState } from "react";
import { ITwoSidesLayout } from "../../../Interfaces/IUmbraco";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import { components } from "../AllElements";

const TwoSidesLayout: React.FC<ITwoSidesLayout> = (props) => {
  const { careerItems } = props;
  const [_dynamicData, _setDynamicData] = useState<any>();

  const deviceMode = useDeviceMode();
  return (
    <>
      <div
        className="container wapper"
        style={{
          flexWrap: deviceMode === StyleMode.tablet ? "wrap" : "nowrap",
        }}
      >
        {careerItems.map((item, index) => {
          const Components = components[item.contentTypeAlias];
          return (
            <div key={index}>
              <Components {...item} />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default TwoSidesLayout;
